import { CellClassParams, IAggFuncParams, ICellRendererParams, ValueGetterParams } from '@ag-grid-community/core'

import { CustomCellRendererProps } from '@ag-grid-community/react'

import { ReportingRRTradeOutBasicRow } from '@/api/reportingData/reportingRentRollTradeOutBasic'
import { AgGridTableProps, DEFAULT_TOOLTIP_VALUE_GETTER } from '@/components/tables'
import { FEB25_COL_TYPES } from '@/components/tables/AgGridTable/themes/feb25/feb25.constants'
import { Feb25Classes, Feb25ColDef, Feb25ColGroupDef } from '@/components/tables/AgGridTable/themes/feb25/feb25.types'
import { ExcelClasses, excelStyles, getExcelGroupClasses, NAME_COLUMN_MAX_WIDTH, NAME_COLUMN_WIDTH } from '@/constants/reportTable'

import { ConditionalFormatRule } from '../../ConditionalRuleEditor/ConditionalRuleEditor.types'
import { GroupByRRField, GROUP_BY_RR_FIELD_LABLES } from '../../TableBuilderFilters'
import { getAggFuncWithIntermediateCalcs, getWeightedAvgAggregation, grandTotalAverageRenderer, refreshAggregationAfterFiltering } from '../TableBuilderTables.utils'

import { cellRenderStyledFromConditionalFormatRules, getHeaderTemplate } from './RRTradeOutBasicTable.utils'

const emptyColDef: Feb25ColDef = {
    headerClass: [Feb25Classes.RightBorder1],
    cellClass: [Feb25Classes.RightBorder1],
    minWidth: 16,
    maxWidth: 16,
    width: 16,
    resizable: false,
}

const occupiedTotalUnitsRatio = (params: IAggFuncParams) => {
    const { occupied_units, total_units } = params.rowNode?.aggData ?? {}
    if (!occupied_units?.sum || !total_units?.sum) { return 0 }
    return occupied_units.sum / total_units.sum * 100
}

const totalCellRenderer = (props: CustomCellRendererProps) => {
    const footer = props.node.footer
    const isRootLevel = props.node.level === -1

    if (footer) {
        if (isRootLevel) {
            return 'Total / Average'
        } else {
            return `${props.node.key} Total`
        }
    } else {
        return props.node.key
    }
}

const getClickableClass = (params: CellClassParams) => {
    const { node } = params
    return node.group ? [] : [Feb25Classes.Clickable]
}

export const BASE_COLS = [
    {
        field: 'total_units',
        title: 'Total~Units',
        type: 'money',
    },
    {
        field: 'occupied_units',
        title: 'Occupied~Units',
        type: 'money',
    },
    {
        field: 'ending_occupancy',
        title: 'Ending~Occupancy',
        type: 'percentage',
        fractionDigits: 1,
    },
    {
        field: 'market_rent',
        title: 'Ending~Market Rent',
        type: 'money',
    },
    {
        field: 'in_place_rent',
        title: 'Ending~In-Place Rent',
        type: 'money',
    },
]

export const NEW_LEASE_COLS = [
    {
        field: 'new_lease_count',
        title: '~Count',
        type: 'money',
    },
    {
        field: 'new_lease_value',
        title: 'New Lease~Trade Out($)',
        type: 'money',
    },
    {
        field: 'new_lease_percent',
        title: 'New Lease~Trade Out(%)',
        type: 'percentage',
        fractionDigits: 1,
    },
]

export const rRTradeOutBasicTableGridOptions: Omit<AgGridTableProps<'feb25'>, 'items'> = {
    defaultColDef: {
        suppressMovable: true,
        lockPinned: true,
        sortable: true,
        unSortIcon: false,
        filterParams: {
            cellHeight: 18,
        },
    },
    suppressFitToWidth: true,
    suppressAutoResize: true,
    suppressRowDrag: true,
    suppressMovableColumns: true,
    groupDefaultExpanded: 1,
    groupDisplayType: 'custom',
    groupTotalRow: 'bottom',
    grandTotalRow: 'bottom',
    suppressAggFuncInHeader: true,
    getRowId: ({ data }: { data: ReportingRRTradeOutBasicRow }) => `${data.asset_id}-${data.floorplan}-${data.bedrooms}`,
    theme: 'feb25',
    columnTypes: FEB25_COL_TYPES,
    cellSelection: true,
    columnDefs: [],
    excelStyles,
    onFilterChanged: refreshAggregationAfterFiltering,
}

const getGroupCol = (groupByRRField: GroupByRRField): Feb25ColDef => {
    const colName = GROUP_BY_RR_FIELD_LABLES[groupByRRField]
    return {
        headerName: colName,
        headerClass: [Feb25Classes.HeaderL2],
        headerTooltip: colName,
        field: groupByRRField,
        rowGroup: true,
        cellRenderer: 'agGroupCellRenderer',
        cellRendererParams: {
            suppressCount: true,
            innerRenderer: totalCellRenderer,
        },
        type: 'string',
        minWidth: 140,
        maxWidth: 300,
        sortable: false,
        filter: 'agSetColumnFilter',
        cellClass: (params: CellClassParams) => [Feb25Classes.HighlightTotalCell, ...getExcelGroupClasses(params)],
    }
}

export const getRRTradeOutBasicColDefs = (
    {
        conditionalFormatRules = [],
        groupByRRField,
        periodAsString,
        showNewLeasesSection,
    }:
    {
        conditionalFormatRules?: ConditionalFormatRule[]
        groupByRRField?: GroupByRRField | null
        periodAsString: string
        showNewLeasesSection: boolean
    },
): Array<Feb25ColGroupDef | Feb25ColDef> => {
    if (!groupByRRField) return []

    const asOfDate = periodAsString.split(' - ')[1] ?? ''
    const hasNoGroup = groupByRRField === GroupByRRField.None
    const headerGroupL0Classes = [Feb25Classes.RightBorder1, Feb25Classes.HeaderL0, ExcelClasses.AlignCenter, ExcelClasses.HeaderGroupL0]

    const assetGroupCol: Feb25ColDef = {
        headerName: 'Asset Name',
        headerTooltip: 'Asset Name',
        headerClass: [Feb25Classes.HeaderL2],
        field: 'asset_name',
        rowGroup: true,
        cellRenderer: 'agGroupCellRenderer',
        cellRendererParams: {
            suppressCount: true,
            innerRenderer: totalCellRenderer,
        },
        type: 'string',
        minWidth: NAME_COLUMN_WIDTH - 100,
        maxWidth: NAME_COLUMN_MAX_WIDTH,
        cellClass: (params: CellClassParams) => [Feb25Classes.HighlightTotalCell, ...getExcelGroupClasses(params)],
    }

    const assetCol: Feb25ColDef = {
        headerName: 'Asset Name',
        headerTooltip: 'Asset Name',
        headerClass: [Feb25Classes.HeaderL2],
        field: 'asset_name',
        type: 'string',
        minWidth: NAME_COLUMN_WIDTH - 100,
        maxWidth: NAME_COLUMN_MAX_WIDTH,
        rowGroup: false,
        // Show Total / Average in the GrandTotal row of the first column if no grouping
        aggFunc: hasNoGroup ? grandTotalAverageRenderer : null,
        cellClass: (params: CellClassParams) => [Feb25Classes.HighlightTotalCell, ...getExcelGroupClasses(params)],
    }

    const floorplanCol: Feb25ColDef = {
        headerName: 'Floorplan',
        headerTooltip: 'Floorplan',
        headerClass: [Feb25Classes.HeaderL2],
        field: 'floorplan',
        type: 'string',
        flex: 1,
        cellClass: (params: CellClassParams) => [Feb25Classes.HighlightTotalCell, ...getExcelGroupClasses(params)],
        filter: 'agSetColumnFilter',
        cellRenderer: (params: ICellRendererParams<ReportingRRTradeOutBasicRow>) => cellRenderStyledFromConditionalFormatRules(conditionalFormatRules, params, 'floorplan'),
    }

    const bedroomsCol: Feb25ColDef = {
        headerName: 'Bedrooms',
        headerTooltip: 'Bedrooms',
        headerClass: [Feb25Classes.HeaderL2],
        field: 'bedrooms',
        type: 'number',
        flex: 1,
        cellClass: (params: CellClassParams) => [Feb25Classes.HighlightTotalCell, ...getExcelGroupClasses(params)],
        filter: 'agNumberColumnFilter',
        cellRenderer: (params: ICellRendererParams<ReportingRRTradeOutBasicRow>) => cellRenderStyledFromConditionalFormatRules(conditionalFormatRules, params, 'bedrooms'),
    }

    const baseCols: Array<Feb25ColGroupDef | Feb25ColDef> = BASE_COLS
        .map(({ field, title, type, fractionDigits }) => {
            const excelTypeClass = field === 'ending_occupancy' ? ExcelClasses.Percentage1Decimal : ExcelClasses.Currency

            const borderClass = field === 'in_place_rent' ? Feb25Classes.RightBorder1 : ''

            const getAggFunc = () => {
                switch (field) {
                    case 'ending_occupancy':
                        return occupiedTotalUnitsRatio
                    case 'market_rent':
                        return getWeightedAvgAggregation('total_units')
                    case 'in_place_rent':
                        return getWeightedAvgAggregation('occupied_units')
                    case 'total_units':
                    case 'occupied_units':
                    default:
                        return getAggFuncWithIntermediateCalcs('sum')
                }
            }

            const cleanTitle = title.replace(/~/g, ' ')

            return ({
                headerName: cleanTitle,
                headerTooltip: cleanTitle,
                headerClass: [borderClass, Feb25Classes.RightAlignedHeader, Feb25Classes.HeaderL2],
                autoHeaderHeight: true,
                headerComponentParams: {
                    template: getHeaderTemplate(title),
                },
                field,
                type,
                fractionDigits,
                flex: 1,
                filter: 'agNumberColumnFilter',
                aggFunc: getAggFunc(),
                valueGetter: (params: ValueGetterParams<ReportingRRTradeOutBasicRow>) => params.data?.[field] ?? 0,
                cellClass: (params: CellClassParams) => [...getClickableClass(params), Feb25Classes.RightAlignedCell, Feb25Classes.HighlightTotalCell, excelTypeClass, borderClass, ...getExcelGroupClasses(params)],
                tooltipValueGetter: DEFAULT_TOOLTIP_VALUE_GETTER,
                cellRenderer: (params: ICellRendererParams<ReportingRRTradeOutBasicRow>) => cellRenderStyledFromConditionalFormatRules(conditionalFormatRules, params, field),
            })
        })

    const newLeaseCols: Feb25ColDef[] = NEW_LEASE_COLS
        .map(({ field, title, type, fractionDigits }) => {
            const excelTypeClass = field === 'new_lease_percent' ? ExcelClasses.Percentage1Decimal : ExcelClasses.Currency
            const borderClass = field === 'new_lease_percent' ? Feb25Classes.RightBorder1 : ''

            const getAggFunc = () => {
                switch (field) {
                    case 'new_lease_value':
                    case 'new_lease_percent':
                        return getWeightedAvgAggregation('new_lease_count')

                    case 'new_lease_count':
                    default:
                        return getAggFuncWithIntermediateCalcs('sum')
                }
            }

            const cleanTitle = title.replace(/~/g, ' ')

            return ({
                headerName: cleanTitle,
                headerTooltip: cleanTitle,
                headerClass: [borderClass, Feb25Classes.RightAlignedHeader, Feb25Classes.HeaderL2],
                headerComponentParams: {
                    template: getHeaderTemplate(title),
                },
                field,
                type,
                fractionDigits,
                flex: 1,
                filter: 'agNumberColumnFilter',
                aggFunc: getAggFunc(),
                valueGetter: (params: ValueGetterParams<ReportingRRTradeOutBasicRow>) => params.data?.[field] ?? 0,
                cellClass: (params: CellClassParams) => [...getClickableClass(params), Feb25Classes.RightAlignedCell, Feb25Classes.HighlightTotalCell, excelTypeClass, borderClass, ...getExcelGroupClasses(params)],
                tooltipValueGetter: DEFAULT_TOOLTIP_VALUE_GETTER,
                cellRenderer: (params: ICellRendererParams<ReportingRRTradeOutBasicRow>) => cellRenderStyledFromConditionalFormatRules(conditionalFormatRules, params, field),
            })
        })

    const colDefs: Array<Feb25ColGroupDef | Feb25ColDef> = []
    const baseSectionCol: Feb25ColGroupDef = {
        headerName: `As of ${asOfDate}`,
        headerTooltip: `As of ${asOfDate}`,
        headerClass: headerGroupL0Classes,
        autoHeaderHeight: true,
        children: [],
    }

    switch (groupByRRField) {
        case GroupByRRField.Floorplan:
            baseSectionCol.children.push(assetGroupCol, floorplanCol)
            break
        case GroupByRRField.Bedrooms:
            baseSectionCol.children.push(assetGroupCol, bedroomsCol)
            break
        case GroupByRRField.City:
        case GroupByRRField.State:
        case GroupByRRField.PropertyManager:
        case GroupByRRField.AssetManager:
        case GroupByRRField.Fund:
        case GroupByRRField.CustomId1:
        case GroupByRRField.CustomId2:
        case GroupByRRField.Software:
            baseSectionCol.children.push(getGroupCol(groupByRRField), assetCol)
            break
        case GroupByRRField.None:
        default:
            baseSectionCol.children.push(assetCol)
    }

    baseSectionCol.children.push(...baseCols)
    colDefs.push(baseSectionCol)

    if (showNewLeasesSection) {
        colDefs.push(emptyColDef,
            {
                headerName: `New Leases (${periodAsString})`,
                headerTooltip: `New Leases (${periodAsString})`,
                suppressStickyLabel: true,
                headerClass: headerGroupL0Classes,
                children: newLeaseCols,
            })
    }

    return colDefs
}
