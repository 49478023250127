import { RoutesConfigValue } from '@/core/routes'

export type ExcelRouteConfigKeys =
    'EXCEL' |
    'NEW_TABLE' |
    'REFRESH_ALL' |
    'VIEW_EXISTING'

export type ExcelRoutesConfig = {
    [key in ExcelRouteConfigKeys]: RoutesConfigValue
}

export const EXCEL_ROUTES_CONFIG: ExcelRoutesConfig = {
    EXCEL: {
        title: 'Excel',
        path: '/excel',
    },
    NEW_TABLE: {
        title: 'New Table',
        path: 'new-table',
    },
    REFRESH_ALL: {
        title: 'Refresh All Tables',
        path: 'refresh',
    },
    VIEW_EXISTING: {
        title: 'View Existing Table',
        path: 'view',
    },
}
