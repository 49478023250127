/**
 * ColDefs & Grid Options for the Portfolio Overview table (1.1)
 */
import { CellClassParams, ColDef, ColSpanParams, ColumnEvent, ValueFormatterParams, ValueGetterParams } from '@ag-grid-community/core'
import get from 'lodash/get'

import { ReportingPortfolioOverviewItem, ReportingPortfolioOverviewRow, ReportingPortfolioOverviewSubheader, ReportingPortfolioOverviewValueKeys } from '@/api/reportingData/reportingPortfolioOverview'
import { ReportingTableSpacer } from '@/api/reportingTable/reportingTable'
import { AgGridTableProps } from '@/components/tables'
import { formatMoney, formatPercentageFraction } from '@/components/tables/AgGridTable/AgGridTable.utils'
import { excelStyles } from '@/constants/reportTable'
import { generatedColors } from '@/styles/tokens/Tokens.constants'

import { TableDataType } from '../../../tableType/tableType.types'
import { TreeFilterToolPanel } from '../../TableBuilder/TreeFilterToolPanel'

const isOneValueDataType = (dataType?: `${TableDataType}`) =>
    dataType === TableDataType.AssetDetails || dataType === TableDataType.RentRollMetric

const valueGetter = (params: ValueGetterParams<ReportingPortfolioOverviewRow>) => {
    const { field } = params.colDef
    const { data } = params
    let value = get(data, field ?? '') as string | number | null | undefined
    const { value_type, data_type } = data ?? {}

    // Special case for AssetDetails and RentRollMetric rows, which have only one value in actual column.
    // It must be shown if even the column is hidden
    // When it's hidden, we need to show it in the first visible column
    if (isOneValueDataType(data_type)) {
        const [firstCol] = params.column.getParent()?.getChildren() ?? []
        const currentCol = params.column

        if (firstCol === currentCol && !currentCol.getColId().match(/values\.\d+\.mtd_actual/)) {
            const [, assetId] = currentCol.getColId().split('.')
            value = get(data, `values.${assetId}.mtd_actual` ?? '')
        }
    }

    // return 0 for empty values of type 'percentage' & 'money' for proper formatting
    if (!value && (value_type === 'percentage' || value_type === 'money')) {
        value = 0
    }

    return value
}

const valueFormatter = (params: ValueFormatterParams<ReportingPortfolioOverviewRow>) => {
    const { value, data, column } = params
    const { value_type } = data ?? {}
    const colId = column.getColId()

    switch (value_type) {
        case 'money':
            return colId?.endsWith('_percent')
                ? formatPercentageFraction({ value } as ValueFormatterParams)
                : formatMoney({ value } as ValueFormatterParams)
        case 'percentage':
            return formatPercentageFraction({ value } as ValueFormatterParams)
        case 'number':
            return formatMoney({ value } as ValueFormatterParams)
        case 'string':
        default:
            return value ?? ''
    }
}

const colSpan = (params: ColSpanParams<ReportingPortfolioOverviewRow>): number => {
    const { data } = params
    const { data_type } = data ?? {}

    // Special case for AssetDetails and RentRollMetric rows: span to parent's width.
    if (isOneValueDataType(data_type)) {
        const children = params.column.getParent()?.getChildren() ?? []
        const [firstCol] = children
        const currentCol = params.column

        if (firstCol === currentCol) {
            return children.length
        }
    }

    return 1
}

const cellStyle = (params: CellClassParams<ReportingPortfolioOverviewRow>) => {
    //  style for right border
    const borderRightColor = generatedColors.colorsSecondaryGrey100
    const { data, context } = params

    if (context?.editable) {
        return null
    }

    const { data_type } = data ?? {}

    const children = params.column.getParent()?.getChildren() ?? []
    const [firstCol] = children
    const lastCol = children[children.length - 1]
    const currentCol = params.column

    // Special case for AssetDetails and RentRollMetric rows: span to parent's width.
    if (isOneValueDataType(data_type)) {
        return firstCol === currentCol ? { borderRightColor } : null
    } else {
        return lastCol === currentCol ? { borderRightColor } : null
    }
}

const cellClass = (params: CellClassParams<ReportingPortfolioOverviewRow>) => {
    const { data, column } = params
    const { value_type, data_type } = data ?? {}
    const colId = column.getColId()

    const getExcelExportClass = () => {
        switch (value_type) {
            case 'money':
                return colId?.endsWith('_percent') ? 'excelPercentageFraction' : 'excelCurrency'
            case 'percentage':
                return 'excelPercentageFraction'
            case 'number':
                return 'excelCurrency'
            default:
                return ''
        }
    }

    const alignmentClass = isOneValueDataType(data_type) ? 'ag-center-aligned-cell' : 'ag-right-aligned-cell'
    return [alignmentClass, getExcelExportClass()]
}

const columnSorter = (
    a: ReportingPortfolioOverviewSubheader,
    b: ReportingPortfolioOverviewSubheader,
) => ReportingPortfolioOverviewValueKeys.indexOf(a.key) - ReportingPortfolioOverviewValueKeys.indexOf(b.key)

// update cell values on column visibility change
const onColumnVisible = (e: ColumnEvent) => e.api.refreshCells()

const firstColDef: ColDef = {
    rowDrag: true,
    field: 'name',
    headerName: 'Metrics',
    pinned: 'left',
    lockPosition: 'left',
    cellClass: 'locked-col',
    minWidth: 220,
    filter: 'agSetColumnFilter',
    filterValueGetter: (params) => params.data.id, // filter by id
    filterParams: {
        newRowsAction: 'keep',
    },
    sortable: false,
    suppressMovable: true,
    type: 'string',
}

export const getPortfolioOverviewColDefs = (
    { headers, columns }:
    { headers: ReportingPortfolioOverviewItem['header'], columns: ReportingPortfolioOverviewItem['subheader'] },
): ColDef[] => {
    if (!headers.length) {
        return []
    }

    return [
        firstColDef,
        ...headers.map(({ id, name }) => ({
            headerName: name,
            headerClass: ['rightBorder1'],
            lockPinned: true,
            children: columns.sort(columnSorter)
                .map(({ key, name }) => ({
                    headerName: name,
                    headerClass: ['rightBorder1', 'ag-right-aligned-header'],
                    field: `values.${id}.${key}`,
                    minWidth: 98,
                    valueGetter,
                    valueFormatter,
                    colSpan,
                    cellStyle,
                    lockPinned: true,
                    cellClass,
                })),
        })),
    ]
}

export const porfolioOverviewGridOptons: Omit<AgGridTableProps, 'items' | 'columnDefs'> = {
    defaultColDef: {
        sortable: false,
        suppressMovable: false,
    },
    sideBar: {
        toolPanels: [
            {
                id: 'columns',
                labelDefault: 'Columns',
                labelKey: 'columns',
                iconKey: 'columns',
                toolPanel: 'agColumnsToolPanel',
                toolPanelParams: {
                    suppressPivotMode: true,
                    suppressRowGroups: true,
                    suppressValues: true,
                },
            },
            {
                id: 'treeRowFilter',
                labelDefault: 'Rows',
                labelKey: 'treeRowFilter',
                iconKey: 'filter',
                toolPanel: TreeFilterToolPanel,
                minWidth: 150,
                maxWidth: 400,
            },
        ],
        defaultToolPanel: 'treeRowFilter',
    },
    getRowId: ({ data }: { data: ReportingPortfolioOverviewRow }) => data.id,
    suppressCellFocus: true,
    suppressFitToWidth: true,
    suppressAutoResize: true,
    suppressRowDrag: false,
    suppressMovableColumns: false,
    reactiveCustomComponents: true,
    maintainColumnOrder: true,
    onColumnVisible,
    excelStyles,
}

export const getPortfolioOverviewSpacerItems = (spacers: ReportingTableSpacer[]) =>
    spacers.map((spacer) => ({
        id: spacer.id,
        name: spacer.value,
        value_type: 'string',
        data_type: TableDataType.Spacer,
        values: {},
    }))
