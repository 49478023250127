import React, { useState } from 'react'

import cn from 'classnames'

import { FilesTreeActions, FilesTreeItem, Icon, Text, TextColor } from '@/components/base'
import { FIRST_LEVEL_PADDING } from '@/components/base/FilesTree/FilesTree.constants'
import { Layout } from '@/components/containers'

import { FilesTreeProps } from './FilesTree.types'
import { TreeLevelIndent } from './TreeLevelIndent'

import styles from './FilesTree.module.scss'

export const FilesTree = (props: FilesTreeProps) => {
    const {
        items,
        isItemActive,
        activeId,
        getFolderActions,
        onItemClick,
        defaultFoldersState = false,
        fileActions = [],
    } = props

    const [foldersState, setFoldersState] = useState<Record<string, boolean>>({})
    const [hoveredItem, setHoveredItem] = useState<string | undefined>()

    const handleClick = (item: FilesTreeItem, isFolder: boolean) => {
        if (!isFolder) {
            onItemClick?.(item)
        } else {
            const prevState = foldersState[item.id] ?? defaultFoldersState
            const newState = !prevState
            setFoldersState((state) => ({
                ...state,
                [item.id]: newState,
            }))
        }
    }

    // Find the level at which the active element is located
    const findActiveItemLevel = (
        items: FilesTreeItem[],
        currentLevel = 0,
    ): number | null => {
        for (const item of items) {
            if (item.id === activeId || isItemActive?.(item)) {
                return currentLevel
            }

            if (item.children) {
                const levelInChildren = findActiveItemLevel(item.children, currentLevel + 1)
                if (levelInChildren !== null) {
                    return levelInChildren
                }
            }
        }

        return null
    }

    const activeItemLevel = findActiveItemLevel(items) || 0

    const getItems = (level: number, items: FilesTreeItem[], isInActivePath = false) => {
        return items.map((item, index) => {
            const isFolder = Boolean(item.children) // It's a folder if has children even if they are empty
            const isFolderOpen = foldersState[item.id] ?? defaultFoldersState

            const isCurrentItemActive = item.id === activeId || isItemActive?.(item)
            const hasFolderActiveChild = item.children?.some((child) =>
                (child.id === activeId) || isItemActive?.(child))
            const isActive = isFolder ? (hasFolderActiveChild) : isCurrentItemActive

            // Determine if the current element is an active folder
            const isActiveFolder = isFolder && isActive

            const isItemInActivePath = isInActivePath || (isActiveFolder && isFolderOpen) || false

            const itemColor: TextColor = isActive ? 'colorsSecondaryPurple400' : 'colorsSecondaryGrey400'
            const itemStyle = cn(styles.item, {
                [styles.item_active]: isActive && !isFolder,
                [styles.item_firstLevel]: level === 0 && isActive && !isFolder,
            })
            const isFile = !isFolder

            // Create indicators for all previous levels
            const levelIndents: React.ReactNode[] = []
            for (let i = 0; i < level; i++) {
                levelIndents.push(
                    <TreeLevelIndent
                        key={`indent-${i}`}
                        level={i + 1}
                        activeLevel={activeItemLevel}
                        isActivePath={isItemInActivePath}
                        focused={isActive}
                    />,
                )
            }

            const icon = isFolder ? <Icon size='l' name='folder2' color={itemColor}/>
                : item.customIcon ? <Icon size='l' name={item.customIcon} color={itemColor}/>
                    : null

            const folderActions = getFolderActions(level).filter(el => !el.hide)

            return (
                <React.Fragment key={`item-${item.id || index}`}>
                    <Layout
                        justify='space-between'
                        boxSizing='border-box'
                        className={itemStyle}
                        align='center'
                        onClick={() => handleClick(item, isFolder)}
                        onMouseEnter={() => setHoveredItem(item.id?.toString())}
                        onMouseLeave={() => setHoveredItem(undefined)}
                        pr={4}
                        style={{
                            paddingLeft: FIRST_LEVEL_PADDING,
                        }}
                        gap={4}
                    >
                        <Layout
                            py={12}
                            gap={4}
                            align='center'
                            className={styles.itemContent}
                        >
                            {levelIndents}
                            {icon}

                            <Text
                                maxLines={1}
                                color={isActive ? 'colorsSecondaryGrey800' : 'colorsSecondaryGrey600'}
                                variant='smallTextMediumDefault'
                                style={{
                                    userSelect: isFolder ? 'none' : 'auto',
                                    paddingLeft: (isFile ? 2 : 0),
                                }}
                                dataTestId={item.testId}
                            >{item.name}</Text>
                        </Layout>
                        {(isFolder && (hoveredItem !== item.id?.toString())) && (
                            <Icon
                                name={isFolderOpen ? 'arrowDown' : 'arrowUp'}
                                color={itemColor}
                                size='l'
                                style={{ marginLeft: 'auto' }}
                            />
                        )}
                        {Boolean(isFolder && folderActions.length && hoveredItem === item.id?.toString() && !item.hideContextMenu) && (
                            <FilesTreeActions
                                item={item}
                                fileActions={folderActions}
                            />
                        )}
                        {Boolean(isFile && fileActions.length && !item.hideContextMenu) && (
                            <FilesTreeActions item={item} fileActions={fileActions} className={styles.actions}/>
                        )}
                    </Layout>
                    {isFolderOpen && item.children && (
                        getItems(
                            level + 1,
                            item.children,
                            isItemInActivePath,
                        )
                    )}
                </React.Fragment>
            )
        })
    }

    return (
        <Layout direction='column' width='100%' gap={2}>
            {getItems(0, items, false)}
        </Layout>
    )
}
