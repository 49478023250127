import { LabelColors } from '@/components/base'

export enum PackageStatus {
    /**
     * No files added yet
     */
    INACTIVE = 'I',
    /**
     * Not used yet
     */
    FILES_PENDING = 'P',
    /**
     * All files loaded and ready for review
     */
    UNDER_REVIEW = 'R',
    /**
     * Approved by the user
     */
    APPROVED = 'A',
    /**
     * Not used yet
     */
    LOCKED = 'L',
}

export const packageStatusTitles = new Map<PackageStatus, string>([
    [PackageStatus.INACTIVE, 'Empty'],
    [PackageStatus.FILES_PENDING, 'Missing Files'],
    [PackageStatus.UNDER_REVIEW, 'Data Received'],
    [PackageStatus.APPROVED, 'Approved'],
    [PackageStatus.LOCKED, 'Locked'],
])

export const packageStatusColors = new Map<PackageStatus, LabelColors>([
    [PackageStatus.INACTIVE, 'gray'],
    [PackageStatus.FILES_PENDING, 'orange'],
    [PackageStatus.UNDER_REVIEW, 'blue'],
    [PackageStatus.APPROVED, 'green'],
    [PackageStatus.LOCKED, 'gray'],
])

export const getPackageStatusTitle = (status: PackageStatus) => packageStatusTitles.get(status)
