import { RefObject, useCallback, useMemo } from 'react'

import format from 'date-fns/format'
import { AND, EQ } from 'mobx-orm'

import { AgGridReact } from '@ag-grid-community/react'
import zonedTimeToUtc from 'date-fns-tz/zonedTimeToUtc'

import { useAssetsQuery } from '@/api/asset/asset'
import { useTrialBalanceLedgerQuery } from '@/api/ledger/trialBalanceLedger'
import { useReportingPortfolioMetricsMetricsQuery } from '@/api/reportingData/reportingPortfolioMetricsMetrics'
import {
    useReportingPortfolioSelectedValueMetricsQuery,
} from '@/api/reportingData/reportingPortfolioSelectedValueMetrics'
import { useReportingProfitAndLossMetricsQuery } from '@/api/reportingData/reportingProfitAndLossMetrics'
import { useReportingProfitAndLossVarianceMetricsQuery } from '@/api/reportingData/reportingProfitAndLossVarianceMetrics'
import { useUnderwritingReportDatesQuery } from '@/api/reportingData/underwritingReportDates'
import { Button, FiltersContainer, SelectDefaultActiveOptions, TableSearchInput } from '@/components/base'
import {
    MonthInput,
    MonthRangeInput,
    ScenarioInput,
    SelectInput,
    SelectInputWithModal,
    TimeComparisonInput,
} from '@/components/baseInputs'
import {
    AssetDetailsInput,
    AssetSelectWithSideModalInput,
} from '@/components/models/asset'
import { COMPANY_SELECT_INPUT_QUERY_PARAM } from '@/components/models/company'
import { LedgerSelectInput } from '@/components/models/ledger'
import {
    GLOBAL_REPORT_MONTH_QUERY_PARAM,
    GLOBAL_REPORT_MONTH_RANGE_END_QUERY_PARAM,
    GLOBAL_REPORT_MONTH_RANGE_START_QUERY_PARAM,
    GLOBAL_REPORT_MULTY_ASSET_QUERY_PARAM,
} from '@/components/widgets/reports/reports.constants'
import { AssetDetails } from '@/constants/assetDetails'
import { DATE_FNS_DEFAULT_FORMAT_MONTH_SHORT } from '@/constants/dates'
import { BALANCE_SHEET_SCENARIO_LABLES, BSReportScenario } from '@/constants/reportScenario'
import { TimeInterval } from '@/constants/timeInterval'
import { useInputState } from '@/hooks'
import { useMe } from '@/hooks/core/useMe'
import { useTableChartToggle } from '@/hooks/useTableChartToggle'
import { AssetType } from '@/models/core'
import {
    getMetricInputQueryParam,
    getPnlMetricInputQueryParam, getReportAssetDetailsInputQueryParam,
    getReportBalanceSheetScenarioInputQueryParam,
    getReportGroupByAssetDetailInputQueryParam,
    getReportGroupByRRFieldInputQueryParam,
    getReportPinTotalColInputQueryParam,
    getReportShowCodesInputQueryParam,
    getReportShowEmptyRowsInputQueryParam,
    getReportTableAssetTypeParam,
    getReportTableIdRowsInputQueryParam,
    getReportTableRrLedgerInputQueryParam,
    getReportTableTbLedgerInputQueryParam,
    getReportTableTypeInputQueryParam,
    getReportTimeIntervalInputQueryParam,
    getReportTotalRowInputQueryParam, getRpMetricInputQueryParam, getUnderwritingDateInputQueryParam,
    TABLE_ASSET_TYPE_PARAM,
    TABLE_BUILDER_EDIT_PAGE_HEADER_SPACER_INPUT,
    TABLE_RR_LEDGER_INPUT_QUERY_PARAM,
    TABLE_TB_LEDGER_INPUT_QUERY_PARAM,
    TABLE_TYPE_INPUT_QUERY_PARAM,
} from '@/pages/TablesPage/TableBuilderPage/TableBuilderPage.constants'
import { dateToMonthInd } from '@/utils/date/monthInd'

import { ASSET_SIDE_MODAL_INPUT_QUERY_PARAM } from '../../../../components/models/asset/AssetSideModalInput/AssetSideModalInput.constants'
import { TableType } from '../../tableType'
import { TableChartButton } from '../TableChartButton'

import { PortfolioSelectedValueTableTimeFilters } from './PortfolioSelectedValueTableTimeFilters'
import {
    ACCOUNT_CODES_INPUT_QUERY_PARAM,
    GROUP_BY_ASSET_DETAIL_INPUT_QUERY_PARAM,
    METRIC_INPUT_QUERY_PARAM,
    PIN_TOTAL_COL_INPUT_OPTONS,
    PIN_TOTAL_COL_INPUT_QUERY_PARAM,
    PNL_METRIC_INPUT_QUERY_PARAM,
    RP_METRIC_INPUT_QUERY_PARAM,
    SHOW_HIDE_INPUT_OPTIONS,
    TABLE_ID_ROWS_INPUT_QUERY_PARAM,
    TABLE_IDS_LABLES,
    TableIdRows,
    TABLES_WITH_METRICS,
    TOTAL_ROW_INPUT_QUERY_PARAM,
    GROUP_BY_RR_FIELD_OPTIONS,
    GROUP_BY_RR_FIELD_INPUT_QUERY_PARAM,
    BALANCE_SHEET_SCENARIO_INPUT_QUERY_PARAM,
    EMPTY_ROWS_INPUT_QUERY_PARAM,
    UNDERWRITING_DATE_INPUT_QUERY_PARAM,
    GroupByRRField,
    TB_AGGREGATE_INPUT_QUERY_PARAM,
    TB_AGGREGATE_INPUT_OPTIONS,
} from './TableBuilderFilters.constants'
import { TableBuilderFiltersProps } from './TableBuilderFilters.types'
import { TimeIntervalAndRangeFilters } from './TimeIntervalAndRangeFilters'

const getTableIdsFilter = (exclude: TableIdRows[] = [], insideReport: boolean, tableId: number | undefined) => {
    const options = Object.entries(TABLE_IDS_LABLES)
        .filter(([value]) => !exclude.includes(value as TableIdRows))
        .map(([value, label]) => ({
            value,
            label,
        }))

    return (
        <SelectInput
            label='Table IDs'
            options={options}
            paramName={TABLE_ID_ROWS_INPUT_QUERY_PARAM.name}
            paramType={TABLE_ID_ROWS_INPUT_QUERY_PARAM.type}
            placeholder='None'
            multiSelect
            multiSelectPlaceholder='All'
            width='136px'
            optionsWidth='auto'
            optionsMinWidth='120px'
            inputParams={insideReport ? getReportTableIdRowsInputQueryParam(tableId) : undefined}
        />
    )
}

export const TableBuilderFilters = (props: TableBuilderFiltersProps) => {
    const { tableRef, insideReport = false, tableId, tablesListRouteConfigKey } = props
    const { me } = useMe()
    const tableChartToggle = useTableChartToggle(tableRef)

    const maxDate = useMemo(() => new Date(), [])
    const defaultMonth = dateToMonthInd(maxDate) - 1 // previous month

    const [tableType] = useInputState(
        insideReport ? getReportTableTypeInputQueryParam(tableId) : TABLE_TYPE_INPUT_QUERY_PARAM,
    )
    const [companyId] = useInputState(COMPANY_SELECT_INPUT_QUERY_PARAM)
    const [tBLedgerId] = useInputState(
        insideReport ? getReportTableTbLedgerInputQueryParam(tableId) : TABLE_TB_LEDGER_INPUT_QUERY_PARAM,
    )
    const [rRLedgerId] = useInputState(
        insideReport ? getReportTableRrLedgerInputQueryParam(tableId) : TABLE_RR_LEDGER_INPUT_QUERY_PARAM,
    )
    const [assetIds] = useInputState(
        insideReport ? GLOBAL_REPORT_MULTY_ASSET_QUERY_PARAM : ASSET_SIDE_MODAL_INPUT_QUERY_PARAM,
    )
    const [assetTypeParam] = useInputState(
        insideReport ? getReportTableAssetTypeParam(tableId) : TABLE_ASSET_TYPE_PARAM,
    )
    const [bsScenario] = useInputState(
        insideReport ? getReportBalanceSheetScenarioInputQueryParam(tableId) : BALANCE_SHEET_SCENARIO_INPUT_QUERY_PARAM,
    )

    const hasMetrics = tableType && TABLES_WITH_METRICS.includes(tableType)
    const hasPnLMetrics = tableType === TableType.PnLTimeSeries
    const hasPnLVAMetrics = tableType === TableType.PnLVarianceAnalysis

    const bsScenarioFilter = (
        <SelectInput
            label='Show Activity'
            options={Object.entries(BALANCE_SHEET_SCENARIO_LABLES).map(([value, label]) => ({
                value,
                label,
            }))}
            paramName={BALANCE_SHEET_SCENARIO_INPUT_QUERY_PARAM.name}
            paramType={BALANCE_SHEET_SCENARIO_INPUT_QUERY_PARAM.type}
            required
            width='96px'
            optionsWidth='auto'
            optionsMinWidth='96px'
            defaultActiveOptions='first'
            inputParams={insideReport ? getReportBalanceSheetScenarioInputQueryParam(tableId) : BALANCE_SHEET_SCENARIO_INPUT_QUERY_PARAM}
        />
    )

    const groupByAssetDetailFilter = (
        <AssetDetailsInput
            paramName={GROUP_BY_ASSET_DETAIL_INPUT_QUERY_PARAM.name}
            label='Group By'
            placeholder='None'
            exclude={[AssetDetails.NAME, AssetDetails.UNITS, AssetDetails.SQUARE_FEET]}
            inputParams={insideReport ? getReportGroupByAssetDetailInputQueryParam(tableId) : undefined}
        />
    )

    const getGroupByRRFieldFilter = (tableType: TableType.RRTradeOutBasic | TableType.RRMetricsCommercial) => {
        const options = tableType === TableType.RRTradeOutBasic
            ? GROUP_BY_RR_FIELD_OPTIONS
            : GROUP_BY_RR_FIELD_OPTIONS.filter(option => (option.value !== GroupByRRField.Floorplan && option.value !== GroupByRRField.Bedrooms))

        return (
            <SelectInput
                label='Group By'
                options={options}
                paramName={GROUP_BY_RR_FIELD_INPUT_QUERY_PARAM.name}
                paramType={GROUP_BY_RR_FIELD_INPUT_QUERY_PARAM.type}
                placeholder='Select'
                defaultActiveOptions='first'
                width='124px'
                optionsWidth='auto'
                optionsMinWidth='124px'
                required
                inputParams={insideReport ? getReportGroupByRRFieldInputQueryParam(tableId) : undefined}
            />
        )
    }

    const totalRowFilter = (
        <SelectInput
            label='Total Row'
            options={[...SHOW_HIDE_INPUT_OPTIONS].reverse()}
            paramName={TOTAL_ROW_INPUT_QUERY_PARAM.name}
            paramType={TOTAL_ROW_INPUT_QUERY_PARAM.type}
            placeholder='Select'
            defaultActiveOptions='first'
            width='100px'
            optionsWidth='auto'
            optionsMinWidth='100px'
            required
            inputParams={insideReport ? getReportTotalRowInputQueryParam(tableId) : undefined}
        />
    )

    const accountCodesFilter = (
        <SelectInput
            label='Account Codes'
            options={SHOW_HIDE_INPUT_OPTIONS}
            paramName={ACCOUNT_CODES_INPUT_QUERY_PARAM.name}
            paramType={ACCOUNT_CODES_INPUT_QUERY_PARAM.type}
            placeholder='Select'
            defaultActiveOptions='first'
            width='100px'
            optionsWidth='auto'
            optionsMinWidth='100px'
            required
            inputParams={insideReport ? getReportShowCodesInputQueryParam(tableId) : undefined}
        />
    )

    const totalColumnFilter = (
        <SelectInput
            label='Total Column'
            options={PIN_TOTAL_COL_INPUT_OPTONS}
            paramName={PIN_TOTAL_COL_INPUT_QUERY_PARAM.name}
            paramType={PIN_TOTAL_COL_INPUT_QUERY_PARAM.type}
            placeholder='Position'
            width='120px'
            optionsWidth='auto'
            optionsMinWidth='120px'
            required
            inputParams={insideReport ? getReportPinTotalColInputQueryParam(tableId) : undefined}
        />
    )

    const hideEmptyRowsFilter = (
        <SelectInput
            label='Empty Rows'
            options={SHOW_HIDE_INPUT_OPTIONS}
            paramName={EMPTY_ROWS_INPUT_QUERY_PARAM.name}
            paramType={EMPTY_ROWS_INPUT_QUERY_PARAM.type}
            placeholder='Select'
            width='100px'
            optionsWidth='auto'
            optionsMinWidth='100px'
            required
            inputParams={insideReport ? getReportShowEmptyRowsInputQueryParam(tableId) : undefined}
        />
    )

    const { data: assets } = useAssetsQuery(
        {
            filter: AND(
                EQ('__order_by', 'name'),
                EQ('__relations', 'groups'),
                EQ('company_id', me.isAdministratorMode ? companyId : undefined),
                EQ('type', assetTypeParam || undefined),
            ),
        },
        { enabled: Boolean(companyId || !me.isAdministratorMode) },
    )

    // Set asset type from first selected asset if no quary param from previous step
    const assetType = assetTypeParam || (assets ?? []).find(asset => asset.id === assetIds?.[0])?.type || undefined

    const { data: underwritingData } = useUnderwritingReportDatesQuery(
        { filter: EQ('asset_ids', assetIds) },
        { enabled: Boolean(assetIds) && tableType === TableType.PnLVarianceAnalysis },
    )

    const underwritingDateOptions = useMemo(() => {
        const underwritingDates = underwritingData?.[0]?.dates ?? []
        return underwritingDates.map((date) => ({
            value: date,
            label: format(zonedTimeToUtc(date, 'UTC'), DATE_FNS_DEFAULT_FORMAT_MONTH_SHORT),
        }))
    }, [underwritingData])

    const { data: metrics, isFetched: isMetricsFetched } = useReportingPortfolioSelectedValueMetricsQuery(
        {
            filter: AND(
                EQ('asset_ids', assetIds),
                EQ('trial_balance_ledger_id', tBLedgerId),
                EQ('rent_roll_ledger_id', rRLedgerId),
            ),
        },
        { enabled: Boolean(hasMetrics && assetIds) },
    )

    const { data: pnLMetrics } = useReportingProfitAndLossMetricsQuery(
        {
            filter: AND(
                EQ('asset_ids', assetIds),
                EQ('trial_balance_ledger_id', tBLedgerId),
                EQ('rent_roll_ledger_id', rRLedgerId),
            ),
        },
        { enabled: Boolean(hasPnLMetrics && assetIds) },
    )

    const { data: pnLVAMetrics } = useReportingProfitAndLossVarianceMetricsQuery(
        {
            filter: AND(
                EQ('asset_ids', assetIds),
                EQ('trial_balance_ledger_id', tBLedgerId),
                EQ('rent_roll_ledger_id', rRLedgerId),
            ),
        },
        { enabled: Boolean(hasPnLVAMetrics && assetIds) },
    )

    const { data: reportingPortfolioMetrics } = useReportingPortfolioMetricsMetricsQuery(
        {
            filter: AND(
                EQ('asset_ids', assetIds),
                EQ('trial_balance_ledger_id', tBLedgerId),
                EQ('rent_roll_ledger_id', rRLedgerId),
            ),
        },
        { enabled: Boolean(assetIds && (tableType === TableType.MetricSideBySide)) },
    )

    const { data: tBLedgers } = useTrialBalanceLedgerQuery(
        {
            filter: AND(
                EQ('company_id', me.isAdministratorMode ? companyId : undefined),
                EQ('type', assetType || undefined),
            ),
        },
        { enabled: Boolean(companyId || !me.isAdministratorMode) && tableType === TableType.TrialBalance },
    )

    const getAssetFilter = useCallback((defaultOptions: SelectDefaultActiveOptions = 'all') => (
        <AssetSelectWithSideModalInput
            label='Asset(s)'
            options={assets}
            placeholder='Select asset'
            required
            multiSelect
            defaultActiveOptions={defaultOptions}
            inputParams={insideReport ? GLOBAL_REPORT_MULTY_ASSET_QUERY_PARAM : undefined}
        />
    ), [assets])

    const monthFilter = useMemo(() => (
        <MonthInput
            label='Time Period'
            defaultMonth={defaultMonth}
            maxDate={maxDate}
            required
            inputParams={insideReport ? GLOBAL_REPORT_MONTH_QUERY_PARAM : undefined}
            syncLocalStorage={false}
        />
    ), [defaultMonth, maxDate])

    const metricFilter = useMemo(() => (
        <SelectInput
            label='Metric'
            options={(metrics ?? []).map((metric) => ({
                value: metric.id,
                label: metric.name,
            }))}
            paramName={METRIC_INPUT_QUERY_PARAM.name}
            paramType={METRIC_INPUT_QUERY_PARAM.type}
            placeholder='Select metric'
            required
            search
            dataReady={isMetricsFetched}
            width='162px' // the same width as other time filters
            optionsMinWidth='360px' // account names can be long
            optionsMaxHeight='640px' // long list of accounts
            inputParams={insideReport ? getMetricInputQueryParam(tableId) : METRIC_INPUT_QUERY_PARAM}
        />
    ), [metrics])

    const pnLMetricFilter = useMemo(() => (
        <SelectInputWithModal
            label='Metric'
            options={((hasPnLMetrics ? pnLMetrics : pnLVAMetrics) ?? []).map((metric) => ({
                value: metric.id,
                label: metric.name,
            }))}
            paramName={PNL_METRIC_INPUT_QUERY_PARAM.name}
            paramType={PNL_METRIC_INPUT_QUERY_PARAM.type}
            placeholder='Select metric'
            multiSelect
            multiSelectPlaceholder='Metrics'
            width='162px' // the same width as other time filters
            inputParams={insideReport ? getPnlMetricInputQueryParam(tableId) : PNL_METRIC_INPUT_QUERY_PARAM}
        />
    ), [hasPnLMetrics, pnLMetrics, pnLVAMetrics])

    const getReportingPortfolioMetricsFilter = useCallback(() => {
        const metrics = reportingPortfolioMetrics ?? []
        const defaultMetrics = metrics.filter(metric => metric.is_default)
        const slicedMetrics = [...defaultMetrics, ...metrics.filter(metric => !metric.is_default)].slice(0, 1000)

        return (
            <SelectInputWithModal
                label='Metrics'
                // Temporary limitation to 1000 metrics until we can support in in UI
                options={slicedMetrics.map((metric) => ({
                    value: metric.id,
                    label: metric.name,
                }))}
                // Select all can break get request because of too much metrics
                hideSelectAll
                paramName={RP_METRIC_INPUT_QUERY_PARAM.name}
                paramType={RP_METRIC_INPUT_QUERY_PARAM.type}
                inputParams={insideReport ? getRpMetricInputQueryParam(tableId) : undefined}
                placeholder='Select metrics'
                multiSelect
                multiSelectPlaceholder='Metrics'
                required
                width='162px'
                defaultActiveOptions={defaultMetrics.map(el => el.id) || 'all'}
            />
        )
    }, [reportingPortfolioMetrics])

    const getFilters = () => {
        switch (tableType) {
            case TableType.PortfolioOverview:
                return (
                    <>
                        {getAssetFilter()}
                        {monthFilter}
                        <TimeComparisonInput
                            tableId={tableId}
                            insideReport={insideReport}
                            syncLocalStorage={false}
                            required
                        />
                    </>
                )

            case TableType.PortfolioRentRoll:
                return (
                    <>
                        {getAssetFilter()}
                        {monthFilter}
                    </>
                )

            case TableType.PortfolioSelectedValue:
                return (
                    <>
                        {getAssetFilter()}
                        <PortfolioSelectedValueTableTimeFilters
                            insideReport={insideReport}
                            tableId={tableId}
                        />
                        {metricFilter}
                    </>
                )

            case TableType.MetricTimeSeries:
                return (
                    <>
                        {getAssetFilter()}
                        <TimeIntervalAndRangeFilters
                            insideReport={insideReport}
                            tableId={tableId}
                            inputParams={insideReport ? getReportTimeIntervalInputQueryParam(tableId) : undefined}
                        />
                        <AssetDetailsInput
                            exclude={[AssetDetails.NAME]}
                            defaultActiveOptions='first'
                            multiSelect
                            required
                            inputParams={insideReport ? getReportAssetDetailsInputQueryParam(tableId) : undefined}
                        />
                        <ScenarioInput
                            defaultActiveOptions='first'
                            assetType={assetType}
                            insideReport={insideReport}
                            tableId={tableId}
                        />
                        {metricFilter}
                        {groupByAssetDetailFilter}
                        {totalRowFilter}
                        {totalColumnFilter}
                    </>
                )

            case TableType.PnLTimeSeries:
                return (
                    <>
                        {getAssetFilter('first')}
                        <TimeIntervalAndRangeFilters
                            insideReport={insideReport}
                            tableId={tableId}
                            inputParams={insideReport ? getReportTimeIntervalInputQueryParam(tableId) : undefined}
                        />
                        <AssetDetailsInput
                            defaultActiveOptions='none'
                            multiSelect
                            inputParams={insideReport ? getReportAssetDetailsInputQueryParam(tableId) : undefined}
                        />
                        <ScenarioInput
                            defaultActiveOptions='first'
                            assetType={assetType}
                            insideReport={insideReport}
                            tableId={tableId}
                        />
                        {pnLMetricFilter}
                        {accountCodesFilter}
                        {totalColumnFilter}
                        {getTableIdsFilter(undefined, insideReport, tableId)}
                        {hideEmptyRowsFilter}
                    </>
                )

            case TableType.PnLVarianceAnalysis:
                return (
                    <>
                        {getAssetFilter('all')}
                        <TimeComparisonInput
                            label='Periods'
                            insideReport={insideReport}
                            tableId={tableId}
                        />
                        <MonthInput
                            label='Date'
                            defaultMonth={defaultMonth}
                            inputParams={insideReport ? GLOBAL_REPORT_MONTH_QUERY_PARAM : undefined}
                            required
                            width='130px'
                        />
                        <SelectInput
                            options={underwritingDateOptions}
                            label='Underwriting date'
                            paramName={UNDERWRITING_DATE_INPUT_QUERY_PARAM.name}
                            paramType={UNDERWRITING_DATE_INPUT_QUERY_PARAM.type}
                            placeholder='None'
                            width='130px'
                            inputParams={
                                insideReport ? getUnderwritingDateInputQueryParam(tableId) : undefined
                            }
                        />
                        <ScenarioInput
                            defaultActiveOptions='first'
                            assetType={assetType}
                            multiselect
                            insideReport={insideReport}
                            tableId={tableId}
                        />
                        <AssetDetailsInput
                            defaultActiveOptions='none'
                            multiSelect
                            inputParams={insideReport ? getReportAssetDetailsInputQueryParam(tableId) : undefined}
                        />
                        {pnLMetricFilter}
                        {accountCodesFilter}
                        {getTableIdsFilter([TableIdRows.Scenario, TableIdRows.Measure], insideReport, tableId)}
                        {hideEmptyRowsFilter}
                    </>
                )

            case TableType.MetricSideBySide:
                return (
                    <>
                        {getAssetFilter()}
                        <TimeIntervalAndRangeFilters
                            insideReport={insideReport}
                            tableId={tableId}
                            timeInterval={TimeInterval.MONTH}
                            inputParams={insideReport ? getReportTimeIntervalInputQueryParam(tableId) : undefined}
                        />
                        <AssetDetailsInput
                            defaultActiveOptions={[AssetDetails.NAME, AssetDetails.MANAGER_COMPANY, assetTypeParam === AssetType.MULTIFAMILY ? AssetDetails.UNITS : AssetDetails.SQUARE_FEET]}
                            multiSelect
                            required
                            inputParams={insideReport ? getReportAssetDetailsInputQueryParam(tableId) : undefined}
                        />
                        {getReportingPortfolioMetricsFilter()}
                        {groupByAssetDetailFilter}
                    </>
                )

            case TableType.RRTradeOutBasic:
                return (
                    <>
                        {getAssetFilter()}
                        <MonthRangeInput
                            label='Time Period'
                            defaultStart={defaultMonth}
                            defaultEnd={defaultMonth}
                            startRangeInputParams={insideReport ? GLOBAL_REPORT_MONTH_RANGE_START_QUERY_PARAM : undefined}
                            endRangeInputParams={insideReport ? GLOBAL_REPORT_MONTH_RANGE_END_QUERY_PARAM : undefined}
                            required
                        />
                        {getGroupByRRFieldFilter(TableType.RRTradeOutBasic)}
                    </>
                )

            case TableType.RRMetricsCommercial:
                return (
                    <>
                        {getAssetFilter()}
                        <MonthInput
                            label='Date'
                            defaultMonth={defaultMonth}
                            inputParams={insideReport ? GLOBAL_REPORT_MONTH_QUERY_PARAM : undefined}
                            required
                            width='130px'
                        />
                        {getGroupByRRFieldFilter(TableType.RRMetricsCommercial)}
                    </>
                )

            case TableType.BSTimeSeries:
                return (
                    <>
                        {getAssetFilter('first')}
                        <TimeIntervalAndRangeFilters
                            insideReport={insideReport}
                            tableId={tableId}
                            inputParams={insideReport ? getReportTimeIntervalInputQueryParam(tableId) : undefined}
                        />
                        {bsScenarioFilter}
                        <AssetDetailsInput
                            defaultActiveOptions='none'
                            multiSelect
                            inputParams={insideReport ? getReportAssetDetailsInputQueryParam(tableId) : undefined}
                        />
                        {accountCodesFilter}
                        {getTableIdsFilter([TableIdRows.Scenario, TableIdRows.Measure], insideReport, tableId)}
                        {bsScenario === BSReportScenario.ACTIVITY && totalColumnFilter}
                    </>
                )

            case TableType.LeaseExpiration:
                return (
                    <>
                        {getAssetFilter()}
                        {monthFilter}
                    </>
                )

            case TableType.TrialBalance:
                return (
                    <>
                        {getAssetFilter()}
                        <MonthRangeInput
                            label='Time Period'
                            defaultStart={defaultMonth}
                            defaultEnd={defaultMonth}
                            startRangeInputParams={insideReport ? GLOBAL_REPORT_MONTH_RANGE_START_QUERY_PARAM : undefined}
                            endRangeInputParams={insideReport ? GLOBAL_REPORT_MONTH_RANGE_END_QUERY_PARAM : undefined}
                            required
                        />
                        <LedgerSelectInput
                            label='Ledger'
                            placeholder='Select'
                            options={tBLedgers}
                            inputParams={insideReport ? getReportTableTbLedgerInputQueryParam(tableId) : TABLE_TB_LEDGER_INPUT_QUERY_PARAM}
                            width='186px'
                            required
                        />
                        <SelectInput
                            paramName={TB_AGGREGATE_INPUT_QUERY_PARAM.name}
                            paramType={TB_AGGREGATE_INPUT_QUERY_PARAM.type}
                            options={TB_AGGREGATE_INPUT_OPTIONS}
                            label='Group By'
                            multiSelectPlaceholder='Group By'
                            placeholder='None'
                            width='186px'
                            multiSelect
                            noCurrentMark
                        />
                        {totalRowFilter}
                        <TableSearchInput
                            label='Search'
                            tableRef={tableRef as RefObject<AgGridReact<any> | undefined>}
                            placeholder='Search'
                            maxWidth='400px'
                            width='176px'
                            flexGrow={1}
                        />
                    </>
                )

            case TableType.DebtData:
                return (
                    <>
                        {getAssetFilter()}
                        {monthFilter}
                    </>
                )

            default:
                return null
        }
    }

    return (
        <FiltersContainer gap={16} width='100%'>
            {getFilters()}
            {tableRef && (
                <>
                    <Button
                        icon='download'
                        theme='secondary'
                        size='s'
                        onClick={() => tableRef.current?.api?.exportDataAsExcel()}
                        title='Download table as Excel'
                    />
                    {/* Do not show button in filters inside Report */}
                    {!insideReport && (
                        <TableChartButton
                            tableChartToggle={tableChartToggle}
                            tablesListRouteConfigKey={tablesListRouteConfigKey}
                        />
                    )}
                </>
            )}
            <div id={TABLE_BUILDER_EDIT_PAGE_HEADER_SPACER_INPUT} style={{ flexGrow: 1 }}/>
        </FiltersContainer>
    )
}
