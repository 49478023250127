import React from 'react'

import { useVeltClient } from '@veltdev/react'

import { VeltReportNote } from '@/components/widgets/reports/VeltReportNote'
import {
    usePnlReportNotesDocumentId,
} from '@/pages/TablesPage/TableBuilderPage/TableBuilderTables/PnLVarianceAnalysisTable/PnlVeltReportNote/PnlVeltReportNote.utils'

import { PnlVeltReportNoteProps } from './PnlVeltReportNote.types'

export const PnlVeltReportNote = (props: PnlVeltReportNoteProps) => {
    const {
        data,
        insideReport,
        tableId,
    } = props

    const { client } = useVeltClient()

    const documentId = usePnlReportNotesDocumentId(insideReport, tableId)
    const noteLocationId = `${data.entity_id.replace('_', '-')}`

    const handleClick = () => {
        if (documentId) {
            client.setDocumentId(documentId)
        }

        const locationName = data.name
        const locationId = noteLocationId

        const locationConfig = {
            id: locationId,
            locationName,
        }

        client.setLocation(locationConfig)

        // Activate filter in Sidebar by account
        const filters = {
            location: [
                { id: locationId },
            ],
        }
        const commentElement = client.getCommentElement()
        commentElement.setCommentSidebarFilters(filters)
    }

    return (
        <VeltReportNote
            handleClick={handleClick}
            documentId={documentId}
            noteLocationId={noteLocationId}
        />
    )
}
