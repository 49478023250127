import { ColumnState } from '@ag-grid-community/core'

export const getSortString = (colId: string, isDesc = false) => {
    return isDesc ? `-${colId}` : colId
}

export const parseSortString = (sortString: string): ColumnState => {
    const sort = sortString.startsWith('-') ? 'desc' : 'asc'
    const colId = sortString.replace(/^-/, '')
    return ({
        colId,
        sort,
    })
}
