import { getRequestQueries } from '@/api/utils/getRequestQueries'

export interface ReportingRentRollTradeOutBasicDrillDownItem {
    rows: ReportingRentRollTradeOutBasicDrillDownRow[]
}

export interface ReportingRentRollTradeOutBasicDrillDownRow {
    id: number
    asset_name: string
    base_rent?: number
    end_date?: string
    move_in_date?: string
    previous_base_rent?: number
    start_date?: string
    trade_out?: number
    trade_out_percent?: number
    unit_floor?: string
    unit_name?: string
}

export const REPORTING_RENT_ROLL_TRADE_OUT_BASIC_DRILL_DOWN_URL = 'reporting/rent-roll-trade-out-basic/drill-down'

export const {
    useItemsQuery: useReportingRentRollTradeOutBasicDrillDownQuery,
} = getRequestQueries<ReportingRentRollTradeOutBasicDrillDownRow, Record<string, unknown>>({
    url: REPORTING_RENT_ROLL_TRADE_OUT_BASIC_DRILL_DOWN_URL,
    extractItemsFn: (data: { rows: ReportingRentRollTradeOutBasicDrillDownRow[] }) => data.rows,
})
