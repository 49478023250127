import cn from 'classnames'

import { observer } from 'mobx-react-lite'

import { CompanyItem } from '@/api/company/company'
import { FormBox, Label } from '@/components/base'
import { useMe } from '@/hooks/core/useMe'
import { ProfileMiniTable } from '@/pages/MainPage/UserPages/CompanyProfilePage'

import { CompanyProfileDetailsProps } from './CompanyProfileDetails.types'

import styles from './CompanyProfileDetails.module.scss'

/**
 * Common layout for 'FormBox'-es with 'UserProfilePage'
 */
export const CompanyProfileDetails = observer((props: CompanyProfileDetailsProps) => {
    const { className } = props
    const { me } = useMe()
    const company = me.company

    const getActiveLabel = (company: CompanyItem) => (
        company.is_active ? (<Label text='Active' color='green'/>) : (<Label text='Inactive' color='red'/>)
    )

    return (
        <div className={cn(styles.companyProfileDetails, className)}>
            <FormBox
                className={styles.table}
                body={(
                    <ProfileMiniTable
                        rows={[
                            {
                                title: 'Company Name:',
                                value: company.name,
                            },
                            {
                                title: 'Contract:',
                                value: getActiveLabel(company),
                            },
                            {
                                title: 'Location:',
                                // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
                                value: company.location.length ? company.location : '—',
                            },
                        ]}
                    />
                )}
                title='Company Info'
            />
        </div>
    )
})
