import { useEffect, useState } from 'react'

import { Module } from '@ag-grid-community/core'

export const useTableChartModules = () => {
    const [modules, setModules] = useState<Promise<Module[]>>(new Promise(() => []))

    useEffect(() => {
        (async () => {
            const modules = Promise.all([
                (await import('@ag-grid-enterprise/charts-enterprise')).GridChartsModule,
            ])
            setModules(modules)
        })()
    }, [])

    return modules
}
