import { getRequestQueries } from '@/api/utils/getRequestQueries'

export interface ReportingTrialBalanceItem {
    rows: ReportingTrialBalanceRow[]
}

export interface ReportingTrialBalanceRow {
    asset_name: string
    period: string
    source_account_code: string
    source_account_name: string
    prior_period_closing_balance?: number
    opening_balance?: number
    debit?: number
    net_debit?: number
    credit?: number
    net_credit?: number
    activity?: number
    closing_balance?: number
    target_account_code: string
    target_account_name: string
}

export const REPORTING_TRIAL_BALANCE_URL = 'reporting/trial-balance'

export const {
    useItemsQuery: useReportingTrialBalanceQuery,
} = getRequestQueries<ReportingTrialBalanceRow, Record<string, unknown>>({
    url: REPORTING_TRIAL_BALANCE_URL,
    extractItemsFn: (data: ReportingTrialBalanceItem) => data.rows,
})
