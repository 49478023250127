import { AssetTypeSelect } from '@/components/models/asset'
import { useInputState } from '@/hooks/useInputState'
import { ASSET_TYPE_LABEL } from '@/models/core'

import { ASSET_TYPE_SELECT_INPUT_QUERY_PARAM } from './AssetTypeSelectInput.constants'
import { AssetTypeSelectInputProps } from './AssetTypeSelectInput.types'

const { name: queryParamName, type: paramType } = ASSET_TYPE_SELECT_INPUT_QUERY_PARAM

export const AssetTypeSelectInput = (props: AssetTypeSelectInputProps) => {
    const {
        availableTypes,
        paramName = queryParamName,
        required = false,
        defaultActiveOptions = 'first',
        ...rest
    } = props

    const [param, setParam] = useInputState({
        name: paramName,
        type: paramType,
    })

    const options = availableTypes
        .map((value) => ({
            value,
            label: ASSET_TYPE_LABEL.get(value) || '',
        }))
        .sort((a, b) => a.label?.localeCompare(b.label || '') || 0)

    const selectedOption = options.find((option) => option.value === param)

    return (
        <AssetTypeSelect
            options={options}
            selected={selectedOption ? [selectedOption] : []}
            onChange={([option]) => setParam(option.value)}
            error={required && Boolean(selectedOption)}
            defaultActiveOptions={defaultActiveOptions}
            {...rest}
        />
    )
}
