import { useCallback, useEffect } from 'react'

import { QueryParamConfig, useQueryParam } from 'use-query-params'

import { useLocalStorage, UseLocalStorageOptions, useRouteKey, useTabStorage, UseTabStorageOptions } from '@/hooks'
import { useMe } from '@/hooks/core/useMe'

export interface UseInputStateParams<T> {
    name: string
    type: QueryParamConfig<T, T>
    syncLocalStorage?: boolean
    /**
     * this hook is not compatible with withDefaults provided by use-query-params
     */
    defaultValue?: T
    /**
     * Options for 'use-local-storage' library
     */
    localStorageOptions?: UseLocalStorageOptions<T>
    /**
     * Recover values by key from local storage scoped by tab id
     */
    syncTabStorage?: boolean
    /**
     * Custom key for tab storage. If not provided, will use default pattern:
     * ${companyId}:${routeKey}:${name}:tab
     */
    tabStorageKey?: string
    /**
     * Options for useTabStorage hook
     */
    tabStorageOptions?: UseTabStorageOptions<T>
    /**
     * Sync state with query param
     */
    syncQueryParam?: boolean
    /**
     * Optional prefix for storage keys
     */
    prefix?: string | number
}

/**
 * Hook to manage input state and sync it with query params and local storage
 * Only one storage type is supported
 *
 * @todo Support prefix for query param
 * @todo Parse Date valuef from local storage here
 * @todo Type validation for all storages
 *  now you need to use localStorageOptions: {parser: parseJSON } every time with DateParam
 */
export const useInputState = <T = unknown>(params: UseInputStateParams<T>) => {
    const {
        name,
        type,
        syncLocalStorage = false,
        syncQueryParam = true,
        syncTabStorage = false,
        tabStorageKey: customTabKey,
        localStorageOptions,
        tabStorageOptions,
        defaultValue,
        prefix = '',
    } = params

    if (!syncQueryParam && !syncLocalStorage && !syncTabStorage) {
        console.error('At least one of syncQueryParam, syncLocalStorage, syncTabStorage must be true')
    }

    const { me } = useMe()
    const routeKey = useRouteKey()

    const localStorageKey = `${prefix ? prefix + ':' : ''}${me?.companyUser?.company_id}:${routeKey || ''}:${name}`
    const tabStorageKey = customTabKey || localStorageKey

    const [valueInLocalStorage, setValueInLocalStorage] = useLocalStorage<T | undefined>(localStorageKey, undefined, localStorageOptions)
    const [valueInTabStorage, setValueInTabStorage] = useTabStorage<T | undefined>(tabStorageKey, undefined, tabStorageOptions)
    const [value, setValue] = useQueryParam<T>(prefix ? prefix + '_' : '' + name, type)

    /**
     * Return state
     */
    const inputValue =
        syncLocalStorage
            ? valueInLocalStorage
            : syncTabStorage
                ? valueInTabStorage
                : syncQueryParam
                    ? value : undefined

    /**
     * Default value handling
     */
    useEffect(() => {
        if (defaultValue && !inputValue) {
            if (!syncLocalStorage) {
                setValueInLocalStorage(defaultValue)
            } else if (syncTabStorage) {
                setValueInTabStorage(defaultValue)
            } else if (syncQueryParam) {
                setValue(defaultValue)
            }
        }
    }, [])

    /**
     * Handle value chance
     */
    const handleSetValue = useCallback((newValue: T) => {
        if (syncLocalStorage) {
            setValueInLocalStorage(newValue)
        } else if (syncTabStorage) {
            setValueInTabStorage(newValue)
        } else if (syncQueryParam) {
            setValue(newValue)
        }
    }, [syncTabStorage, syncLocalStorage, syncQueryParam])

    return [
        inputValue || defaultValue,
        handleSetValue,
    ] as const
}
