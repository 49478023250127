import { useMemo } from 'react'

import { SelectInput } from '@/components/baseInputs'
import { TimeComparisonPeriod, TIME_COMPARISON_PERIOD_LABELS } from '@/constants/timeComparisonPeriods'
import { useInputState } from '@/hooks'
import { getReportTimeComparisonInputQueryParam } from '@/pages/TablesPage/TableBuilderPage/TableBuilderPage.constants'

import { TIME_COMPARISON_INPUT_QUERY_PARAM } from './TimeComparisonInput.constants'
import { TimeComparisonInputProps } from './TimeComparisonInput.types'

const OPTIONS = Object.entries(TIME_COMPARISON_PERIOD_LABELS).map(([value, label]) => ({
    value,
    label,
}))

export const TimeComparisonInput = (props: TimeComparisonInputProps) => {
    const {
        label = 'Time Comparison',
        multiSelect = true,
        syncLocalStorage,
        includeOptions = 'all',
        insideReport = false,
        tableId,
        ...rest
    } = props

    useInputState({
        ...TIME_COMPARISON_INPUT_QUERY_PARAM,
        syncLocalStorage,
        defaultValue: [TimeComparisonPeriod.MTD],
    })

    const availableOption = useMemo(() => {
        if (includeOptions === 'all') {
            return OPTIONS
        }

        const optionsSet = new Set(includeOptions)
        return OPTIONS.filter(o => optionsSet.has(o.value as TimeComparisonPeriod))
    }, [includeOptions])

    return (
        <SelectInput
            paramName={TIME_COMPARISON_INPUT_QUERY_PARAM.name}
            paramType={TIME_COMPARISON_INPUT_QUERY_PARAM.type}
            options={availableOption}
            label={label}
            multiSelect={multiSelect}
            syncLocalStorage={syncLocalStorage}
            width='154px' // 154px is the width of the longest option
            placeholder='Select time comparison period'
            multiSelectPlaceholder='Periods'
            required
            inputParams={insideReport ? getReportTimeComparisonInputQueryParam(tableId) : undefined}
            {...rest}
        />
    )
}
