import { generatePath } from 'react-router'

import { ReportingFolderItem } from '@/api/reportingData/reportingFolder'
import { ReportingReportItem } from '@/api/reportingData/reportingReport'
import { FilesTreeItem } from '@/components/base'
import { OWNER_ROUTES_CONFIG } from '@/core/routes'
import { ExtendedFilesTreeItem, ReportTypes } from '@/pages/MainPage/UserPages/ReportsPage/ReportPage.types'

/**
 * Home icon that appears at the top of reports list
 */
export const getReportsHomeItem = (reportType: ReportTypes): ExtendedFilesTreeItem => {
    const homeRouteConfig = reportType === 'my' ? OWNER_ROUTES_CONFIG.REPORTS_CUSTOM_MY : OWNER_ROUTES_CONFIG.REPORTS_CUSTOM_COMPANY

    return {
        name: 'Reports Home',
        customIcon: 'home',
        path: homeRouteConfig.path,
        id: homeRouteConfig.path,
        hideContextMenu: true,
        strictUrlMatch: true,
    }
}

export const getReportsTree = (folders: ReportingFolderItem[], reports: ReportingReportItem[], parent: number | null, reportType?: ReportTypes): FilesTreeItem[] => {
    const normalizedFolders = folders
        .filter((folder) => folder.parent_id === parent)
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((folder) => ({
            id: folder.id,
            name: folder.name,
            children: getReportsTree(folders, reports, folder.id, reportType),
        }))

    const normalizedFiles: FilesTreeItem[] = reports
        .filter((report) => report.parent_id === parent)
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((report) => {
            const path = generatePath(
                reportType === 'company' ? OWNER_ROUTES_CONFIG.REPORTS_CUSTOM_COMPANY_BY_ID.path : OWNER_ROUTES_CONFIG.REPORTS_CUSTOM_MY_BY_ID.path
                , { id: report.id })

            return {
                // Use path here to keep logic of 'starndard' and 'company' reports compatible
                //  Can be replaced to id when 'standard' reports will be removed or replaced with custom reports functionality
                id: path,
                name: report.name,
                path,
            }
        })

    return [...normalizedFolders, ...normalizedFiles]
}

export const DEFAUILT_REPORT_NAME = 'New Report'
export const DEFAULT_REPORT_FOLDER_NAME = 'New Folder'
