import { AgGridTableProps } from '@/components/tables'
import { NEO_COL_TYPES } from '@/components/tables/AgGridTable/themes/neo/neo.constants'
import { NeoColDef } from '@/components/tables/AgGridTable/themes/neo/neo.types'

export const rRTOBDrillDownGridOptions: Omit<AgGridTableProps<'neo'>, 'items'> = {
    defaultColDef: {
        suppressMovable: true,
    },
    suppressRowDrag: true,
    suppressMovableColumns: true,
    columnTypes: NEO_COL_TYPES,
    cellSelection: true,
    theme: 'neo',
    columnDefs: [],
}

const COLS = {
    unit: {
        headerName: 'Unit Number',
        field: 'unit_name',
        type: 'string',
    },
    unitfloor: {
        headerName: 'Floor Plan',
        field: 'unit_floor',
        type: 'string',
    },
    moveIn: {
        headerName: 'Move In Date',
        field: 'move_in_date',
        type: 'date',
    },
    leaseStart: {
        headerName: 'Lease Start Date',
        field: 'start_date',
        type: 'date',
        width: 150,
    },
    leaseEnd: {
        headerName: 'Lease End Date',
        field: 'end_date',
        type: 'date',
        width: 150,
    },
    baseRent: {
        headerName: 'New Base Rent',
        field: 'base_rent',
        type: 'money',
        width: 150,
    },
    prevBaseRent: {
        headerName: 'Previous Base Rent',
        field: 'previous_base_rent',
        type: 'money',
        width: 150,
    },
    tradeoutMoney: {
        headerName: 'Trade Out ($)',
        field: 'trade_out',
        type: 'money',
    },
    tradeoutPercent: {
        headerName: 'Trade Out (%)',
        field: 'trade_out_percent',
        type: 'percentage',
        fractionDigits: 1,
    },
    status: {
        headerName: 'Unit Status',
        field: 'unit_status',
        type: 'string',
    },
    marketRent: {
        headerName: 'Market Rent',
        field: 'market_rent',
        type: 'number',
    },
    tenant: {
        headerName: 'Tenant Name',
        field: 'tenant_name',
        type: 'string',
    },
    isOccupied: {
        headerName: 'Is Occupied',
        field: 'is_occupied',
        type: 'string',
    },
}

export const getRRTOBDrillDown = (isNewLeasesSection: boolean): NeoColDef[] => {
    return isNewLeasesSection
        ? [
            COLS.unit,
            COLS.unitfloor,
            COLS.moveIn,
            COLS.leaseStart,
            COLS.leaseEnd,
            COLS.baseRent,
            COLS.prevBaseRent,
            COLS.tradeoutMoney,
            COLS.tradeoutPercent,
        ]

        : [
            COLS.unit,
            COLS.unitfloor,
            COLS.status,
            COLS.marketRent,
            COLS.tenant,
            COLS.leaseEnd,
            COLS.isOccupied,
            COLS.baseRent,
        ]
}
