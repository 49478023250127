import { useMemo } from 'react'

import { AND, EQ, IN } from 'mobx-orm'

import { useQueryParams } from 'use-query-params'

import { UseQueryCustomParams } from '@/api/api.types'
import { useAssetsQuery } from '@/api/asset/asset'
import { PackageItem } from '@/api/package/package'
import { AG_GRID_DEFAULT_QUERY_PARAMS } from '@/components/tables/AgGridTable/AgGridTable.constants'
import { useInputState } from '@/hooks'
import { Package } from '@/models/package'
import {
    PACKAGES_DATE_QUERY_PARAM,
    PACKAGES_LIST_ASSET_QUERY_PARAM,
    PACKAGES_MANAGER_QUERY_PARAM,
    PACKAGES_STATUS_QUERY_PARAM,
} from '@/pages/MainPage/CommonPages/PackagesPage/PackagesListTable/PackageListTable.constants'
import { dateToMonthInd } from '@/utils/date/monthInd'

/**
 * Generate from package obj
 */
export const getPackageId = (packageData: Package): string => {
    return concatPackageId(packageData.month_ind, packageData.asset_id)
}

/**
 * Generate based on string params
 */
export const concatPackageId = (monthInd?: number | null, assetId?: number | null): string => {
    return (monthInd && assetId) ? (monthInd.toString() + '~' + assetId.toString()) : ''
}

export const parsePackageId = (packageId: string): { assetId: number | null, monthInd: number | null } => {
    if (!packageId) {
        return {
            assetId: null,
            monthInd: null,
        }
    }

    const [monthInd, assetId] = packageId.split('~')

    return {
        assetId: Number(assetId),
        monthInd: Number(monthInd),
    }
}

/**
 * Util for sync filters between Alerts table and Packages Table
 */
export const usePackagesParams = (): UseQueryCustomParams<unknown> => {
    const [queryParams] = useQueryParams<any>({
        ...AG_GRID_DEFAULT_QUERY_PARAMS,
    })

    const assetsQuery = useAssetsQuery()

    const [managerCompany] = useInputState(PACKAGES_MANAGER_QUERY_PARAM)
    const [assetIds] = useInputState(PACKAGES_LIST_ASSET_QUERY_PARAM)
    const [packageStatus] = useInputState(PACKAGES_STATUS_QUERY_PARAM)

    const [packageMonth] = useInputState(PACKAGES_DATE_QUERY_PARAM)

    const assetIdsByManager = useMemo(() => (
        assetsQuery.data?.filter((asset) => asset.property_manager_company === managerCompany).map((asset) => asset.id)
    ), [assetsQuery.data, managerCompany])

    const filters = [EQ('month_ind', dateToMonthInd(packageMonth as Date))]

    if (assetIdsByManager?.length && assetIds?.length) {
        const commonIds = assetIdsByManager.filter((id: number) => assetIds.includes(id))

        if (!commonIds.length) {
            // Hack because empty array works same as no filter
            commonIds.push(-1)
        }

        filters.push(IN('asset_id', commonIds))
    } else if (assetIdsByManager?.length) {
        filters.push(IN('asset_id', assetIdsByManager))
    } else if (assetIds?.length) {
        filters.push(IN('asset_id', assetIds))
    }

    if (packageStatus) {
        filters.push(IN('status', packageStatus))
    }

    return {
        pageOffset: queryParams.tablePageOffset,
        pageLimit: queryParams.tablePageLimit,
        filter: AND(...filters),
    }
}

/**
 * Document id for Velt
 */
export const getPackageDocumentId = (packageData: PackageItem | undefined, packageAlias: string) => (packageData?.asset_comments_id && packageAlias) ? packageData.asset_comments_id + '_' + packageAlias : null

/**
 * Document id for insights inside package
 */
export const getPackageInsightDocumentId = (asset_comments_id: string | undefined | null, packageAlias: string) => (asset_comments_id && packageAlias) ? asset_comments_id + '_' + packageAlias + '_insights' : null

export const getPackageNoteDocumentId = (asset_comments_id: string | undefined | null, packageAlias: string | undefined | null, ledgerId: string | number | null | undefined) => (asset_comments_id && packageAlias && ledgerId) ? asset_comments_id + '_' + packageAlias + '_' + ledgerId + '_notes' : null
