import { AgGridTableProps } from '@/components/tables'
import { Feb25DetailsClasses as ThemeClasses, Feb25DetailsColDef, Feb25DetailsColGroupDef } from '@/components/tables/AgGridTable/themes/feb25Details/feb25Details.types'
import { monthIndToString } from '@/utils/date/monthInd'

const noAgg = () => ' '

export enum DrillDownState {
    Assets = 'assets', // many assets
    Months = 'months', // one asset, many months
    SourceAccounts = 'source accounts', // one asset, one month
    LedgerDetail = 'ledger detail',
}

export const pnlVaDrillDownGridOptions: Omit<AgGridTableProps<'feb25Details'>, 'items'> = {
    defaultColDef: {
        resizable: true,
        suppressHeaderMenuButton: true,
        unSortIcon: false,
    },
    cellSelection: true,
    theme: 'feb25Details',
    columnDefs: [],
    noBorders: false,
}

const assetColNameCol: Feb25DetailsColDef = {
    field: 'asset_name',
    headerName: 'Asset',
    minWidth: 182,
    type: 'string',
    unSortIcon: false,
    aggFunc: () => 'Total',
}
const getAccountCodeCol = (accountCode: string): Feb25DetailsColDef => {
    return {
        field: 'account_code',
        headerName: 'Code',
        minWidth: 122,
        type: 'string',
        aggFunc: noAgg,
        unSortIcon: false,
        valueGetter: () => accountCode,
    }
}
const getAccountNameCol = (accountName: string): Feb25DetailsColDef => {
    return {
        field: 'account_name',
        headerName: 'Name',
        minWidth: 182,
        type: 'string',
        aggFunc: noAgg,
        unSortIcon: false,
        valueGetter: () => accountName,
    }
}

const actualCol: Feb25DetailsColDef = {
    field: 'values.actual',
    headerName: 'Actual $',
    minWidth: 110,
    type: 'money',
    aggFunc: 'sum',
}
const budgetCol: Feb25DetailsColDef = {
    field: 'values.budget',
    headerName: 'Budget $',
    minWidth: 110,
    type: 'money',
    aggFunc: 'sum',
    headerClass: [ThemeClasses.RightAlignedHeader, ThemeClasses.RightBorder1],
    cellClass: [ThemeClasses.RightAlignedCell, ThemeClasses.RightBorder1],
}

const varianceCol: Feb25DetailsColGroupDef = {
    headerName: 'Actual vs Budget',
    headerClass: [ThemeClasses.RightBorder1, ThemeClasses.HeaderL1],
    suppressStickyLabel: true,
    children: [
        {
            field: 'values.variance',
            headerName: '$',
            minWidth: 110,
            type: 'money',
            aggFunc: 'sum',
        },
        {
            field: 'values.variance_percent',
            headerName: '%',
            minWidth: 110,
            aggFunc: 'avg',
            type: 'percentageFraction',
            // @ts-expect-error custom prop
            fractionDigits: 1,
        },
    ],
}

const monthCol: Feb25DetailsColDef = {
    field: 'month_ind',
    valueGetter: (params) => params.data?.month_ind ? monthIndToString(params.data.month_ind) : '',
    headerName: 'Period',
    minWidth: 182,
    type: 'string',
    aggFunc: () => 'Total',
    unSortIcon: false,
}

const sourceAccountNameCol: Feb25DetailsColDef = {
    field: 'account_name',
    headerName: 'Name',
    minWidth: 182,
    type: 'string',
    aggFunc: () => 'Total',
    unSortIcon: false,
}
const sourceAccountCodeCol: Feb25DetailsColDef = {
    field: 'account_code',
    headerName: 'Code',
    minWidth: 182,
    type: 'string',
    aggFunc: noAgg,
    unSortIcon: false,
}

const glDateCol: Feb25DetailsColDef = {
    field: 'transaction_date',
    headerName: 'GL Date',
    minWidth: 110,
    type: 'date',
}

const vendorCol: Feb25DetailsColDef = {
    field: 'vendor',
    headerName: 'Vendor',
    minWidth: 182,
    type: 'string',
    unSortIcon: false,
}

const debitCol: Feb25DetailsColDef = {
    field: 'debit',
    headerName: 'Debit',
    minWidth: 85,
    type: 'money',
    fractionDigits: 2,
}

const creditCol: Feb25DetailsColDef = {
    field: 'credit',
    headerName: 'Credit',
    minWidth: 85,
    type: 'money',
    fractionDigits: 2,
}

const remarksCol: Feb25DetailsColDef = {
    field: 'remarks',
    headerName: 'Remarks',
    minWidth: 182,
    type: 'string',
    unSortIcon: false,
}
const referenceCol: Feb25DetailsColDef = {
    field: 'description',
    headerName: 'Reference',
    minWidth: 182,
    type: 'string',
    unSortIcon: false,
}

export const getPnlVaDrillDownColDefs = ({ drilldownState, accountCode, accountName }: { drilldownState: DrillDownState, accountCode: string, accountName: string },
): Feb25DetailsColDef[] | Feb25DetailsColGroupDef[] => {
    switch (drilldownState) {
        case DrillDownState.Assets:
            return [
                assetColNameCol,
                getAccountCodeCol(accountCode),
                getAccountNameCol(accountName),
                actualCol,
                budgetCol,
                varianceCol,
            ]
        case DrillDownState.Months:
            return [
                monthCol,
                getAccountCodeCol(accountCode),
                getAccountNameCol(accountName),
                actualCol,
                budgetCol,
                varianceCol,
            ]
        case DrillDownState.SourceAccounts:
            return [
                sourceAccountNameCol,
                sourceAccountCodeCol,
                actualCol,
                budgetCol,
                varianceCol,
            ]
        case DrillDownState.LedgerDetail:
            return [
                glDateCol,
                vendorCol,
                debitCol,
                creditCol,
                remarksCol,
                referenceCol,
            ]
        default:
            return []
    }
}
