import { getRequestQueries } from '@/api/utils/getRequestQueries'

export interface NewLeaseRow {
    id: number
    asset_name?: string
    unit_name?: string
    unit_floor?: string
    move_in_date?: string
    start_date?: string
    end_date?: string
    base_rent?: number
    previous_base_rent?: number
    trade_out?: number
    trade_out_percent?: number
}

export interface ReportingRRTradeOutBasicRow {
    asset_id: number
    asset_name: string
    floorplan?: string | null
    bedrooms?: number | null
    total_units?: number
    occupied_units?: number
    ending_occupancy?: number
    market_rent?: number
    in_place_rent?: number | null
    new_lease_count?: number | null
    new_lease_value?: number | null
    new_lease_percent?: number | null
}

export interface ReportingRRTradeOutBasicItem {
    rows: ReportingRRTradeOutBasicRow[]
    columns?: Record<string, {
        key: string
        metric_type: number
        time_period: string
    }>
}

export const REPORTING_RENT_ROLL_TRADE_OUT_BASIC_URL = 'reporting/rent-roll-trade-out-basic'

export const {
    useItemsQuery: useReportingRentRollTradeOutBasicQuery,
} = getRequestQueries<ReportingRRTradeOutBasicItem, Record<string, unknown>>({
    url: REPORTING_RENT_ROLL_TRADE_OUT_BASIC_URL,
    extractItemsFn: (data: ReportingRRTradeOutBasicItem) => [data],
})
