import { getRequestQueries } from '@/api/utils/getRequestQueries'

export interface ReportingPortfolioSelectedValueMetricsItem {
    id: string
    name: string
}

export const REPORTING_PORTFOLIO_SELECTED_VALUE_METRICS_URL = 'reporting/portfolio-selected-value/metrics'

export const {
    useItemsQuery: useReportingPortfolioSelectedValueMetricsQuery,
    useItemUpdateMutation: useReportingPortfolioSelectedValueMetricsUpdateMutation,
    useItemsCountQuery: useReportingPortfolioSelectedValueMetricsCountQuery,
    useItemCreateMutation: useReportingPortfolioSelectedValueMetricsCreateMutation,
    useItemDeleteMutation: useReportingPortfolioSelectedValueMetricsDeleteMutation,
} = getRequestQueries<ReportingPortfolioSelectedValueMetricsItem, Record<string, unknown>>({
    url: REPORTING_PORTFOLIO_SELECTED_VALUE_METRICS_URL,
})
