import { useReportingTableCreateMutation, useReportingTableUpdateMutation } from '@/api/reportingTable/reportingTable'
import { useMe } from '@/hooks/core/useMe'

export type ReportingTableSaveMutation = ReturnType<typeof useReportingTableSaveMutation>

export const useReportingTableSaveMutation = () => {
    const { me } = useMe()
    const updateTableMutation = useReportingTableUpdateMutation()
    const createTableMutation = useReportingTableCreateMutation()

    return {
        update: updateTableMutation,
        create: createTableMutation,
        isPending: updateTableMutation.isPending || createTableMutation.isPending,
        me,
    }
}
