import { AgGridTableProps } from '@/components/tables'

export const rRDetailDrillDownGridOptions: Omit<AgGridTableProps<'feb25Details'>, 'items'> = {
    defaultColDef: {
        resizable: true,
        suppressHeaderMenuButton: true,
        unSortIcon: false,
    },
    theme: 'feb25Details',
    headerHeight: 60,
    columnDefs: [
        {
            field: 'source_charge_code',
            headerName: 'Charge Code',
            headerTooltip: 'Charge Code',
            minWidth: 182,
            type: 'string',
            unSortIcon: false,
        },
        {
            field: 'monthly_price',
            headerName: 'Value',
            headerTooltip: 'Value',
            minWidth: 182,
            type: 'money',
        },
    ],
    noBorders: false,
}
