import { getRequestQueries } from '@/api/utils/getRequestQueries'

export interface ReportingPortfolioMetricsMetricsItem {
    id: string
    name: string
    is_default: boolean
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FetchReportingPortfolioMetricsMetricsQueryParams {}

export const REPORTING_PORTFOLIO_METRICS_METRICS_URL = 'reporting/portfolio-metrics/metrics'

export const {
    useItemsQuery: useReportingPortfolioMetricsMetricsQuery,
    useItemUpdateMutation: useReportingPortfolioMetricsMetricsUpdateMutation,
    useItemsCountQuery: useReportingPortfolioMetricsMetricsCountQuery,
    useItemCreateMutation: useReportingPortfolioMetricsMetricsCreateMutation,
    useItemDeleteMutation: useReportingPortfolioMetricsMetricsDeleteMutation,
} = getRequestQueries<ReportingPortfolioMetricsMetricsItem, FetchReportingPortfolioMetricsMetricsQueryParams>({
    url: REPORTING_PORTFOLIO_METRICS_METRICS_URL,
})
