import { MonthInput, MonthRangeInput, QuarterInput, TimeComparisonInput, YearInput, YearRangeInput } from '@/components/baseInputs'
import { QuarterRangeInput } from '@/components/baseInputs/QuarterRangeInput'
import { TimeIntervalInput, TIME_INTERVAL_INPUT_QUERY_PARAM } from '@/components/baseInputs/TimeIntervalInput'
import { TimeSettingsInput, TIME_SETTINGS_INPUT_QUERY_PARAM } from '@/components/baseInputs/TimeSettingsInput'
import {
    GLOBAL_REPORT_MONTH_QUERY_PARAM, GLOBAL_REPORT_MONTH_RANGE_END_QUERY_PARAM,
    GLOBAL_REPORT_MONTH_RANGE_START_QUERY_PARAM,
} from '@/components/widgets/reports/reports.constants'
import { TimeInterval } from '@/constants/timeInterval'
import { TimeSettings } from '@/constants/timeSettings'
import { useInputState } from '@/hooks'
import {
    PortfolioSelectedValueTableTimeFiltersProps,
} from '@/pages/TablesPage/TableBuilderPage/TableBuilderFilters/PortfolioSelectedValueTableTimeFilters/PortfolioSelectedValueTableTimeFilters.types'
import {
    getQuarterRangeEndQueryParam,
    getReportQuarterRangeStartQueryParam,
    getReportQuerterInputQueryParam,
    getReportTimeIntervalInputQueryParam,
    getReportTimeSettingsInputQueryParam,
    getReportYearInputQueryParam,
    getReportYearRangeEndQueryParam,
    getReportYearRangeStartQueryParam,
} from '@/pages/TablesPage/TableBuilderPage/TableBuilderPage.constants'

export const PortfolioSelectedValueTableTimeFilters = (props: PortfolioSelectedValueTableTimeFiltersProps) => {
    const { insideReport = false, tableId } = props

    const [timeSettings] = useInputState(insideReport ? getReportTimeSettingsInputQueryParam(tableId) : TIME_SETTINGS_INPUT_QUERY_PARAM)
    const [timeInterval] = useInputState(insideReport ? getReportTimeIntervalInputQueryParam(tableId) : TIME_INTERVAL_INPUT_QUERY_PARAM)

    return (
        <>
            <TimeSettingsInput
                insideReport={insideReport}
                tableId={tableId}
            />

            {/* Time Interval input */}
            {timeSettings === TimeSettings.SINGLE || timeSettings === TimeSettings.SERIES ? (
                <TimeIntervalInput
                    inputParams={insideReport ? getReportTimeIntervalInputQueryParam(tableId) : undefined}
                />
            ) : (
                <>
                    <TimeComparisonInput tableId={tableId} insideReport={insideReport}/>
                    <MonthInput
                        inputParams={insideReport ? GLOBAL_REPORT_MONTH_QUERY_PARAM : undefined}
                        label='Time Period'
                        required
                    />
                </>
            )}

            {/* Time Settings: SINGlE */}
            {timeSettings === TimeSettings.SINGLE && timeInterval === TimeInterval.MONTH && (
                <MonthInput
                    inputParams={insideReport ? GLOBAL_REPORT_MONTH_QUERY_PARAM : undefined}
                    label='Time Period'
                    required
                />
            )}
            {timeSettings === TimeSettings.SINGLE && timeInterval === TimeInterval.QUARTER && (
                <QuarterInput
                    label='Time Period'
                    required
                    inputParams={insideReport ? getReportQuerterInputQueryParam(tableId) : undefined}
                />
            )}
            {timeSettings === TimeSettings.SINGLE && timeInterval === TimeInterval.YEAR && (
                <YearInput
                    label='Time Period'
                    required
                    inputParams={insideReport ? getReportYearInputQueryParam(tableId) : undefined}
                />
            )}

            {/* Time Settings: SERIES */}
            {timeSettings === TimeSettings.SERIES && timeInterval === TimeInterval.MONTH && (
                <MonthRangeInput
                    startRangeInputParams={insideReport ? GLOBAL_REPORT_MONTH_RANGE_START_QUERY_PARAM : undefined}
                    endRangeInputParams={insideReport ? GLOBAL_REPORT_MONTH_RANGE_END_QUERY_PARAM : undefined}
                    label='Time Period'
                    required
                />
            )}
            {timeSettings === TimeSettings.SERIES && timeInterval === TimeInterval.QUARTER && (
                <QuarterRangeInput
                    label='Time Period'
                    required
                    endInputParams={insideReport ? getReportQuarterRangeStartQueryParam(tableId) : undefined}
                    startInputParams={insideReport ? getQuarterRangeEndQueryParam(tableId) : undefined}
                />
            )}
            {timeSettings === TimeSettings.SERIES && timeInterval === TimeInterval.YEAR && (
                <YearRangeInput
                    label='Time Period'
                    required
                    endInputParams={insideReport ? getReportYearRangeEndQueryParam(tableId) : undefined}
                    startInputParams={insideReport ? getReportYearRangeStartQueryParam(tableId) : undefined}
                />
            )}
        </>
    )
}
