import { RoutesConfigValue } from '@/core/routes'

export type CustomReportsRouteConfigKeys =
    'REPORTS_CUSTOM_COMPANY' |
    'REPORTS_CUSTOM_COMPANY_BY_ID' |
    'REPORTS_CUSTOM_MY' |
    'REPORTS_CUSTOM_MY_BY_ID' |
    'REPORTS_CUSTOM_COMPANY_DEFAULT' |
    'REPORTS_CUSTOM_COMPANY_HOME' |
    'REPORTS_CUSTOM_COMPANY_TABLES' |
    'REPORTS_CUSTOM_COMPANY_TABLE_BUILDER' |
    'REPORTS_CUSTOM_MY_DEFAULT' |
    'REPORTS_CUSTOM_MY_HOME' |
    'REPORTS_CUSTOM_MY_TABLES' |
    'REPORTS_CUSTOM_MY_TABLE_BUILDER'

export type CustomReportsRoutesConfig = {
    [key in CustomReportsRouteConfigKeys]: RoutesConfigValue
}

export const CUSTOM_REPORTS_ROUTES_CONFIG: CustomReportsRoutesConfig = {
    REPORTS_CUSTOM_COMPANY: {
        title: 'Company',
        path: '/reports/custom/company',
        metricData: {
            pageViewEventName: 'Report View - Custom',
            pageName: 'Custom Report',
        },
    },
    REPORTS_CUSTOM_COMPANY_BY_ID: {
        title: 'Company',
        path: '/reports/custom/company/:id',
        metricData: {
            pageViewEventName: 'Report View - Custom',
            pageName: 'Custom Report',
        },
    },
    REPORTS_CUSTOM_MY: {
        title: 'My Reports',
        path: '/reports/custom/my',
        metricData: {
            pageViewEventName: 'Report View - Custom',
            pageName: 'Custom Report',
        },
    },
    REPORTS_CUSTOM_MY_BY_ID: {
        title: 'My Reports',
        path: '/reports/custom/my/:id',
        metricData: {
            pageViewEventName: 'Report View - Custom',
            pageName: 'Custom Report',
        },
    },
    REPORTS_CUSTOM_COMPANY_DEFAULT: {
        title: '',
        path: '/reports/custom/company/default',
    },
    REPORTS_CUSTOM_COMPANY_HOME: {
        title: 'Reports Home',
        path: '/reports/custom/company/home',
        metricData: {
            pageViewEventName: 'Report View - Home',
            pageName: 'Custom Report Home',
        },
    },
    REPORTS_CUSTOM_COMPANY_TABLES: {
        title: 'Tables',
        path: '/reports/custom/company/tables',
        metricData: {
            pageViewEventName: 'Report View - Tables',
            pageName: 'Custom Report Home',
        },
    },
    REPORTS_CUSTOM_COMPANY_TABLE_BUILDER: {
        title: 'Table Builder',
        path: '/reports/custom/company/table_builder/:id',
        metricData: {
            pageViewEventName: 'Report View - Table Builder',
            pageName: 'Custom Report Table Builder',
        },
    },
    REPORTS_CUSTOM_MY_DEFAULT: {
        title: '',
        path: '/reports/custom/my/default',
    },
    REPORTS_CUSTOM_MY_HOME: {
        title: 'Reports Home',
        path: '/reports/custom/my/home',
        metricData: {
            pageViewEventName: 'Report View - Home',
            pageName: 'Custom Report Home',
        },
    },
    REPORTS_CUSTOM_MY_TABLES: {
        title: 'Tables',
        path: '/reports/custom/my/tables',
        metricData: {
            pageViewEventName: 'Report View - Tables',
            pageName: 'Custom Report Home',
        },
    },
    REPORTS_CUSTOM_MY_TABLE_BUILDER: {
        title: 'Table Builder',
        path: '/reports/custom/my/table_builder/:id',
        metricData: {
            pageViewEventName: 'Report View - Table Builder',
            pageName: 'Custom Report Table Builder',
        },
    },
}
