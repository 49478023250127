import { getRequestQueries } from '@/api/utils/getRequestQueries'
import { ReportingDataValueType } from '@/constants/reportingDataValueTypes'
import { AssetType } from '@/models/core'

export interface ReportingPortfolioMetricsItemRow {
    id: string
    name: string
    city: string
    state: string
    type: AssetType
    property_manager_company: string
    units_count: number
    square_feet: number
    fund_name: string
    custom_id1?: string
    custom_id2?: string
    software?: string
    asset_manager_name?: string
    values: Record<string, number>
}

export type ReportingPortfolioMetricsAggregation = 'M' | 'S' | 'A' | 'N' | 'L' | 'W'

export interface ReportingPortfolioMetricsItemColumn {
    id: string
    name: string
    scenario: string
    value_type: `${ReportingDataValueType}`
    aggregation: ReportingPortfolioMetricsAggregation
}

export interface ReportingPortfolioMetricsItem {
    columns: ReportingPortfolioMetricsItemColumn[]
    rows: ReportingPortfolioMetricsItemRow[]
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FetchReportingPortfolioMetricsQueryParams {}

export const REPORTING_PORTFOLIO_METRICS_URL = 'reporting/portfolio-metrics'

export const {
    useItemsQuery: useReportingPortfolioMetricsQuery,
    useItemUpdateMutation: useReportingPortfolioMetricsUpdateMutation,
    useItemsCountQuery: useReportingPortfolioMetricsCountQuery,
    useItemCreateMutation: useReportingPortfolioMetricsCreateMutation,
    useItemDeleteMutation: useReportingPortfolioMetricsDeleteMutation,
} = getRequestQueries<ReportingPortfolioMetricsItem, FetchReportingPortfolioMetricsQueryParams>({
    url: REPORTING_PORTFOLIO_METRICS_URL,
    extractItemsFn: (data: ReportingPortfolioMetricsItem) => [data],
})
