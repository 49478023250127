import { getRequestQueries } from '@/api/utils/getRequestQueries'

export interface ReportingReportItemTable {
    table_id: number
    /**
     * Description
     */
    outline?: string
    title?: string
    position?: {
        x: number
        y: number
        width: number
        height: number
    }
}

export interface ReportingReportItem {
    id: number
    name: string
    /**
     * If 'parent_id' exists, 'company_id' is not required
     * Need to be declared if there is no parent
     */
    company_id?: number
    parent_id?: number
    items: ReportingReportItemTable[]
    updated_at: string
    order: number
}

export interface FetchReportingReportQueryParams {
    is_private?: boolean
}

export const REPORTING_REPORT_URL = '/reporting/report'

export const {
    useItemsQuery: useReportingReportQuery,
    useItemUpdateMutation: useReportingReportUpdateMutation,
    useItemCreateMutation: useReportingReportCreateMutation,
    useItemsCountQuery: useReportingReportCountQuery,
    useItemDeleteMutation: useReportingReportDeleteMutation,
    useItemByIdQuery: useReportingReportByIdQuery,
} = getRequestQueries<ReportingReportItem, FetchReportingReportQueryParams>({
    url: REPORTING_REPORT_URL,
    errorMessageUpdate: 'Failed to update report',
    errorMessageDelete: 'Failed to delete report',
    errorMessageCreate: 'Failed to create report',
})
