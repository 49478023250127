import addMonths from 'date-fns/addMonths'
import parseJSON from 'date-fns/parseJSON'

import { createEnumArrayParam, DateParam, StringParam } from 'use-query-params'

import { Label } from '@/components/base'
import { ASSET_SIDE_MODAL_INPUT_QUERY_PARAM } from '@/components/models/asset'
import { AgGridTableProps } from '@/components/tables'
import { PackageStatus, packageStatusColors, packageStatusTitles } from '@/models/package'

export const PACKAGES_LAST_DASHBOARD_ASSET_FILTER_LS_KEY = 'packagesLastDashboardAssetFilter'

export const PACKAGES_LIST_ASSET_QUERY_PARAM = {
    name: 'assetId',
    type: ASSET_SIDE_MODAL_INPUT_QUERY_PARAM.type,
}

export const PACKAGES_DATE_QUERY_PARAM = {
    name: 'packageMonth',
    type: DateParam,
    defaultValue: addMonths(new Date(), -1),
    localStorageOptions: {
        parser: parseJSON,
    },
}

export const PACKAGES_STATUS_QUERY_PARAM = {
    name: 'packageStatus',
    type: createEnumArrayParam(Object.values(PackageStatus)),
}

export const PACKAGES_MANAGER_QUERY_PARAM = {
    name: 'manager',
    type: StringParam,
}

export const PACKAGES_LIST_TABLE_COLUMNS: AgGridTableProps['columnDefs'] = [
    {
        headerName: 'Asset',
        field: 'asset.name',
        type: 'string',
        sortable: true,
    },
    {
        headerName: 'Period',
        field: 'month_ind',
        type: 'monthInd',
        unSortIcon: true,
    },
    {
        headerName: 'Manager',
        field: 'asset.property_manager_company',
        type: 'string',
    },
    {
        headerName: 'Alerts',
        field: 'error_count',
        type: 'string',
        maxWidth: 100,
        sortable: true,
    },
    {
        headerName: 'Files',
        type: 'string',
        field: 'files',
        valueGetter: ({ data }) => {
            return data.files?.length || 0
        },
        sortable: true,
        maxWidth: 100,
    },
    {
        headerName: 'Status',
        field: 'status',
        unSortIcon: true,
        sortable: true,
        cellRenderer: ({ value }) => {
            return (
                <Label
                    // @ts-expect-error TS(2322) FIXME: Type '"green" | "orange" | "red" | "blue" | "gray"... Remove this comment to see the full error message
                    color={packageStatusColors.get(value)}
                    text={packageStatusTitles.get(value)}
                />
            )
        },
    },
]
