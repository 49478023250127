import { CompanyItem } from '@/api/company/company'
import { Select } from '@/components/base'
import { useInputState } from '@/hooks'

import { COMPANY_SELECT_INPUT_QUERY_PARAM } from './CompanySelectInput.constants'
import { CompanySelectInputProps } from './CompanySelectInput.types'

const { name: paramName, type: paramType } = COMPANY_SELECT_INPUT_QUERY_PARAM

export const CompanySelectInput = (props: CompanySelectInputProps) => {
    const {
        queryParamName = paramName,
        queryParamType = paramType,
        syncLocalStorage,
        placeholder = 'Select Company',
        required = false,
        options,
        searchPlaceholder = 'Search company name',
        defaultActiveOptions = 'first',
        hide = false,
        ...rest
    } = props

    const [param, setParam] = useInputState({
        name: queryParamName,
        type: queryParamType,
        syncLocalStorage,
    })

    const selectedOption = options.find((option) => option.id === param)

    if (hide) return null

    // Sort options by company name
    const sortedOptions = options.slice().sort((a, b) => a.name?.localeCompare(b?.name ?? '') || 0)

    return (
        <Select<CompanyItem>
            options={sortedOptions}
            selected={selectedOption ? [selectedOption] : []}
            placeholder={placeholder}
            onChange={(options) => options.length ? setParam(options[0].id) : setParam(undefined)}
            labelFn={(obj) => obj.name ?? ''}
            idFn={(obj) => obj?.id?.toString() ?? ''}
            error={required && !selectedOption}
            searchPlaceholder={searchPlaceholder}
            optionsWidth='unset'
            defaultActiveOptions={defaultActiveOptions}
            {...rest}
        />
    )
}
