import { CSSProperties } from 'react'

import { ICellRendererParams } from '@ag-grid-community/core'

import { ReportingRentRollColumn, ReportingRentRollRow } from '../../../../../api/reportingData/reportingRentRoll'
import { ReportingDataValueType } from '../../../../../constants/reportingDataValueTypes'
import { applyFunction, ConditionalFormatRule, ConditionalFormatStyle } from '../../ConditionalRuleEditor/ConditionalRuleEditor.types'
import { applyConditionalFormattingRuleToStyle } from '../../ConditionalRuleEditor/ConditionalRuleEditor.utils'

export const cellRenderStyledFromConditionalFormatRules = (
    conditionalFormatRules: ConditionalFormatRule[],
    params: ICellRendererParams<ReportingRentRollRow>,
    key: string,
    columns: ReportingRentRollColumn[],
) => {
    if ((params.node.group && params.node.expanded) || params.value === undefined || params.value === null || !params.data || params.value === '') {
        return params.valueFormatted
    }

    let newStyle: ConditionalFormatStyle = {}

    // Apply conditional formatting rules. Reverse the array to apply the rules in priority order
    // First rule gets applied last and therefore has the highest priority since styles can overwrite each other
    for (const rule of conditionalFormatRules.reverse()) {
        // Determine if condition(s) are met to apply a rule's styling to the cell
        let conditionMet = true

        // Determine reference value for primary column and check then primary condition
        const primaryConditionColumnValue = params.data[rule.primaryConditionColumn]
        if (!primaryConditionColumnValue) continue
        let primaryConditionColumnValueFormatted
        switch (columns.filter(c => c.key === rule.primaryConditionColumn)[0].value_type) {
            case ReportingDataValueType.MONEY:
            case ReportingDataValueType.NUMBER:
                primaryConditionColumnValueFormatted = parseFloat((primaryConditionColumnValue as number).toFixed(0))
                break
            case ReportingDataValueType.PERCENTAGE:
                primaryConditionColumnValueFormatted = Math.round((primaryConditionColumnValue as number) * 1000) / 1000 // represent percentage as decimal with 3 decimal places
                break
            default:
                primaryConditionColumnValueFormatted = primaryConditionColumnValue
                break
        }

        const firstConditionResult = applyFunction(rule.primaryConditionFunction, primaryConditionColumnValueFormatted, rule.primaryConditionValue)

        // Check Secondary Condition (may not be present)
        const secondaryConditionColumnValue = rule.secondaryConditionColumn && params.data[rule.secondaryConditionColumn]
        let secondaryConditionColumnValueFormatted
        if (rule.secondaryConditionColumn && columns.filter(c => c.key === rule.secondaryConditionColumn)[0]) {
            switch (columns.filter(c => c.key === rule.secondaryConditionColumn)[0].value_type) {
                case ReportingDataValueType.MONEY:
                case ReportingDataValueType.NUMBER:
                    secondaryConditionColumnValueFormatted = parseFloat((secondaryConditionColumnValue as number).toFixed(0))
                    break
                case ReportingDataValueType.PERCENTAGE:
                    secondaryConditionColumnValueFormatted = Math.round((secondaryConditionColumnValue as number) * 1000) / 1000 // represent percentage as decimal with 3 decimal places
                    break
                default:
                    secondaryConditionColumnValueFormatted = secondaryConditionColumnValue
                    break
            }
        }

        const secondaryConditionResult = secondaryConditionColumnValueFormatted ? (rule.secondaryConditionFunction && applyFunction(rule.secondaryConditionFunction, secondaryConditionColumnValueFormatted, rule.secondaryConditionValue)) ?? false : false

        const applySecondaryCondition = secondaryConditionColumnValue && rule.secondaryConditionFunction && rule.secondaryConditionValue && rule.secondaryConditionColumn
        if (applySecondaryCondition) {
            if (rule.conditionCombinationOperator === 'AND') {
                conditionMet = firstConditionResult && secondaryConditionResult
            } else {
                conditionMet = firstConditionResult || secondaryConditionResult
            }
        } else {
            conditionMet = firstConditionResult
        }

        // Apply the rule's styling to the cell if the condition(s) are met
        if (conditionMet && rule.columnsToBeFormatted.includes(key)) {
            newStyle = applyConditionalFormattingRuleToStyle(rule, newStyle)
        }
    }

    const styledIcon = newStyle.cellIconStyle ? <span className={newStyle.cellIconStyle?.iconClass} style={newStyle?.cellIconStyle ? newStyle.cellIconStyle as CSSProperties : {}}/> : <span/>

    return (
        <div style={newStyle as CSSProperties}>{styledIcon}<span>{params.valueFormatted}</span></div>
    )
}
