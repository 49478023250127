import { CellClassParams, GetContextMenuItemsParams, ICellRendererParams, MenuItemDef, RowHeightParams } from '@ag-grid-community/core'

import { ReportingPnLRow, ReportingPnLRowType } from '@/api/reportingData/reportingProfitAndLoss'
import { ReportingPnLVarianceRow } from '@/api/reportingData/reportingProfitAndLossVariance'
import { AgGridTableProps, DEFAULT_TOOLTIP_VALUE_GETTER } from '@/components/tables'
import { NEO_COL_TYPES } from '@/components/tables/AgGridTable/themes/neo/neo.constants'
import { NeoClasses, NeoColDef, NeoColGroupDef } from '@/components/tables/AgGridTable/themes/neo/neo.types'
import { AssetDetails } from '@/constants/assetDetails'
import { ReportingDataValueType } from '@/constants/reportingDataValueTypes'
import { ExcelClasses, excelStyles, getIndentClassForGroupNode, NAME_COLUMN_MAX_WIDTH, NAME_COLUMN_WIDTH } from '@/constants/reportTable'
import { TimeInterval } from '@/constants/timeInterval'
import { AssetType } from '@/models/core'

import { ConditionalFormatRule } from '../../ConditionalRuleEditor/ConditionalRuleEditor.types'
import { PinTotalColumn, TableIdRows } from '../../TableBuilderFilters'
import { PinnedPositionByTotalCol } from '../TableBuilderTables.constants'
import { formatterByValueType, getHeaderNameForValueCols } from '../TableBuilderTables.utils'

import { cellRenderStyledFromConditionalFormatRules, getExcelGroupClasses } from './PnLTimeSeriesTable.utils'

export const ASSET_DETAIL_TO_ENTITY_ID: Record<AssetDetails, string> = {
    [AssetDetails.NAME]: 'asset_name',
    [AssetDetails.CITY]: 'asset_city',
    [AssetDetails.STATE]: 'asset_state',
    [AssetDetails.ASSET_CLASS]: 'asset_type',
    [AssetDetails.MANAGER_COMPANY]: 'asset_property_manager_company',
    [AssetDetails.FUND]: 'asset_fund_name',
    [AssetDetails.UNITS]: 'asset_units_count',
    [AssetDetails.SQUARE_FEET]: 'asset_square_feet',
    // Rows below are not in the table
    [AssetDetails.CUSTOM_ID1]: 'placeholder_id1',
    [AssetDetails.CUSTOM_ID2]: 'placeholder_id2',
    [AssetDetails.ASSET_MANAGER]: 'placeholder_id1',
    [AssetDetails.SOFTWARE]: 'placeholder_software',
}

export const TABLE_ID_ROW_TO_ENTITY_ID: Record<TableIdRows, string> = {
    [TableIdRows.PeriodStart]: 'report_period_start',
    [TableIdRows.PeriodEnd]: 'report_period_end',
    [TableIdRows.Scenario]: 'report_scenario',
    [TableIdRows.Measure]: 'report_measure',
}

const EXCEL_CLASS_BY_VALUE_TYPE: Record<`${ReportingDataValueType}`, ExcelClasses> = {
    M: ExcelClasses.Currency,
    P: ExcelClasses.Percentage1Decimal,
    N: ExcelClasses.Currency,
    S: ExcelClasses.String,
    D: ExcelClasses.Date,
}

export const getExcelTypeClass = (params: CellClassParams<ReportingPnLRow | ReportingPnLVarianceRow>): ExcelClasses | string => {
    return params?.data?.value_type ? EXCEL_CLASS_BY_VALUE_TYPE[params.data.value_type] : ''
}

export const pnLTimeSeriesTableGridOptions: Omit<AgGridTableProps<'neo'>, 'items'> = {
    defaultColDef: {
        suppressMovable: true,
        lockPinned: true,
        sortable: false,
        filterParams: {
            cellHeight: 18,
        },
    },
    suppressFitToWidth: true,
    suppressAutoResize: true,
    suppressRowDrag: true,
    suppressMovableColumns: true,
    treeData: true,
    groupDefaultExpanded: 1,
    suppressStickyTotalRow: true,
    groupDisplayType: 'custom',
    groupTotalRow: 'bottom',
    getDataPath: (data: ReportingPnLRow) => data.tree_path,
    getRowId: ({ data }: { data: ReportingPnLRow | ReportingPnLVarianceRow }) => data.entity_id?.toString(),
    theme: 'neo',
    columnTypes: NEO_COL_TYPES,
    cellSelection: true,
    columnDefs: [],
    excelStyles,
    getRowClass: ({ data }: { data: ReportingPnLRow }) => {
        if (data.type === ReportingPnLRowType.SubCategoryMetric) {
            return [NeoClasses.ItalicRow, NeoClasses.SmallFontRow]
        }
        if (data.type === ReportingPnLRowType.Separator) {
            return [NeoClasses.SeparatorRow]
        }
        if (data.type === ReportingPnLRowType.CategoryMetric) {
            return [NeoClasses.GrandTotalRow]
        }
    },
    getRowHeight: (params: RowHeightParams) => {
        // separator row height
        if (params.node.data?.type === ReportingPnLRowType.Separator) { return 12 }
    },
}

export const getPnLTimeSeriesColDefs = ({ conditionalFormatRules = [], columns, tableTitles, totalCol, assetType, showAccountCodes, timeInterval }: {
    conditionalFormatRules?: ConditionalFormatRule[]
    columns: Array<{ id: string | number, name: string }>
    tableTitles: string[]
    totalCol?: PinTotalColumn | null
    assetType?: AssetType | null
    showAccountCodes: boolean
    timeInterval?: TimeInterval | null
}): NeoColGroupDef[] => {
    if (!assetType) return []

    const colDefs: NeoColGroupDef[] = [
        {
            // Metric section
            headerName: tableTitles[0],
            headerTooltip: tableTitles[0],
            headerClass: [NeoClasses.RightBorder1],
            children: [
                {
                    headerName: tableTitles[1],
                    headerTooltip: tableTitles[1],
                    headerClass: [NeoClasses.RightBorder1],
                    field: 'name', // need this to have colId and fix total rows in excel export
                    cellClass: (params: CellClassParams) => [
                        NeoClasses.RightBorder1,
                        getIndentClassForGroupNode(params),
                        ...getExcelGroupClasses(params),
                        params.data?.type === ReportingPnLRowType.SubCategoryMetric ? ExcelClasses.Indent2 : '',
                    ],
                    cellStyle: (params: CellClassParams<ReportingPnLRow>) => {
                        // shift sub-category metrics to the right
                        if (params.data?.type === ReportingPnLRowType.SubCategoryMetric) {
                            return { '--ag-indentation-level': 1 }
                        }
                    },
                    showRowGroup: true,
                    cellRenderer: 'agGroupCellRenderer',
                    cellRendererParams: {
                        suppressCount: true,
                    },
                    lockPosition: 'left',
                    pinned: 'left',
                    type: 'string',
                    minWidth: NAME_COLUMN_WIDTH,
                    maxWidth: NAME_COLUMN_MAX_WIDTH,
                    valueGetter: ({ data }: { data: ReportingPnLRow }) => {
                        if (data.type === ReportingPnLRowType.Separator) { return '' }
                        return showAccountCodes && data.code ? `(${data.code}) ${data.name}` : data.name
                    },
                },
            ],

        },
        {
            // Time series section
            children: [
                ...columns.map(({ name, id }) => {
                    return ({
                        headerName: getHeaderNameForValueCols(id?.toString() ?? '', timeInterval),
                        headerTooltip: name,
                        headerClass: [NeoClasses.RightAlignedHeader],
                        cellClass: (params: CellClassParams) => [NeoClasses.RightAlignedCell, NeoClasses.HighlightTotalCell, getExcelTypeClass(params), ...getExcelGroupClasses(params)],
                        field: `values.${id}`,
                        flex: 1,
                        valueGetter: ({ data }: { data: ReportingPnLRow }) => {
                            if (data.type === ReportingPnLRowType.Separator) { return '' }
                            return data.values?.[id] ?? (data.value_type === ReportingDataValueType.STRING ? '' : 0)
                        },
                        valueFormatter: (params) => formatterByValueType(params.data.value_type, params),
                        tooltipValueGetter: DEFAULT_TOOLTIP_VALUE_GETTER,
                        cellRenderer: (params: ICellRendererParams<ReportingPnLRow>) => cellRenderStyledFromConditionalFormatRules(conditionalFormatRules, params, 'all-data'),
                    })
                }),
            ],
        },
    ]

    // Total column
    if (totalCol === PinTotalColumn.Right || totalCol === PinTotalColumn.Left) {
        const totalDataCol: NeoColDef = {
            headerName: 'Total',
            headerTooltip: 'Total',
            headerClass: [NeoClasses.RightAlignedHeader, NeoClasses.GreyBG],
            cellClass: (params: CellClassParams) => [NeoClasses.RightAlignedCell, NeoClasses.GreyBG, NeoClasses.HighlightTotalCell, getExcelTypeClass(params), ...getExcelGroupClasses(params)],
            field: 'values.total',
            pinned: PinnedPositionByTotalCol[totalCol],
            maxWidth: 150,
            minWidth: 80,
            valueGetter: ({ data }: { data: ReportingPnLRow }) => {
                if (data.type === ReportingPnLRowType.Separator) { return '' }
                return data.values.total ?? (data.value_type === ReportingDataValueType.STRING ? '' : 0)
            },
            valueFormatter: (params) => formatterByValueType(params.data.value_type, params),
            tooltipValueGetter: DEFAULT_TOOLTIP_VALUE_GETTER,
            cellRenderer: (params: ICellRendererParams<ReportingPnLRow>) => cellRenderStyledFromConditionalFormatRules(conditionalFormatRules, params, 'total'),
        }

        colDefs.push({
            headerClass: [NeoClasses.GreyBG],
            children: [totalDataCol],
        })
    }

    return colDefs
}

export const getContextMenuItems = (params: GetContextMenuItemsParams): Array<string | MenuItemDef> => {
    const result: Array<string | MenuItemDef> = [
        'copy',
        'copyWithHeaders',
        'copyWithGroupHeaders',
        'separator',
        'export',
        'separator',
        {
            name: 'Expand All Row Groups',
            action: () => params.api.expandAll(),
        },
        {
            name: 'Collapse All Row Groups',
            action: () => params.api.collapseAll(),
        },
    ]
    return result
}
