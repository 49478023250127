import { CellClassParams, GetContextMenuItemsParams, ICellRendererParams, MenuItemDef, ValueFormatterParams } from '@ag-grid-community/core'
import format from 'date-fns/format'

import zonedTimeToUtc from 'date-fns-tz/zonedTimeToUtc'

import { ReportingPnLRow, ReportingPnLRowType } from '@/api/reportingData/reportingProfitAndLoss'
import { ReportingPnLVarianceRow } from '@/api/reportingData/reportingProfitAndLossVariance'
import { AgGridTableProps, DEFAULT_TOOLTIP_VALUE_GETTER } from '@/components/tables'
import { Feb25Classes, Feb25ColDef, Feb25ColGroupDef } from '@/components/tables/AgGridTable/themes/feb25/feb25.types'
import { AssetDetails } from '@/constants/assetDetails'
import { DATE_FNS_DEFAULT_FORMAT_MONTH_SHORT } from '@/constants/dates'
import { ReportingDataValueType } from '@/constants/reportingDataValueTypes'
import { REPORT_METRICS_BY_ASSET_TYPE } from '@/constants/reportMetrics'
import { ReportScenario } from '@/constants/reportScenario'
import { ExcelClasses, getIndentClassForGroupNode, NAME_COLUMN_MAX_WIDTH, NAME_COLUMN_WIDTH } from '@/constants/reportTable'
import { TimeComparisonPeriod } from '@/constants/timeComparisonPeriods'
import { AssetType } from '@/models/core'
import {
    PnlVeltReportNote,
} from '@/pages/TablesPage/TableBuilderPage/TableBuilderTables/PnLVarianceAnalysisTable/PnlVeltReportNote'
import { generatedColors } from '@/styles/tokens/Tokens.constants'
import { monthIndToString } from '@/utils/date/monthInd'

import { FEB25_COL_TYPES } from '../../../../../components/tables/AgGridTable/themes/feb25/feb25.constants'
import { ConditionalFormatRule, ConditionalFormatRuleFunction } from '../../ConditionalRuleEditor/ConditionalRuleEditor.types'
import { getExcelTypeClass, pnLTimeSeriesTableGridOptions } from '../PnLTimeSeriesTable/PnLTimeSeriesTable.constants'
import { getExcelGroupClasses } from '../PnLTimeSeriesTable/PnLTimeSeriesTable.utils'
import { FORMATTER_BY_VALUE_TYPE } from '../TableBuilderTables.utils'

import { cellRenderStyledFromConditionalFormatRules } from './PnLVarianceAnalysisTable.utils'

export const CLICKABLE_TIME_COMPARISONS = [TimeComparisonPeriod.MTD, TimeComparisonPeriod.YTD]

export const FILTER_DEFAULTS_ON_CREATION = {
    TimeComparison: [TimeComparisonPeriod.MTD, TimeComparisonPeriod.YTD],
    ScenarioIds: [ReportScenario.ACTUAL, ReportScenario.BUDGET, ReportScenario.VARIANCE, ReportScenario.VARIANCE_PERCENT],
    AssetDetails: [AssetDetails.NAME, AssetDetails.MANAGER_COMPANY, AssetDetails.UNITS],
    ShowEmptyRows: '0',
}

const emptyColDef: Feb25ColDef = {
    headerClass: [Feb25Classes.RightBorder1],
    cellClass: [Feb25Classes.RightBorder1],
    minWidth: 16,
    maxWidth: 16,
    width: 16,
    resizable: false,
}

const DATA_COL_MAX_WIDTH = 200
export const DEFAULT_COLOR_DOLLAR_VARIANCE_THRESHOLD = 3_000
export const DEFAULT_COLOR_PERCENT_VARIANCE_THRESHOLD = 10

export type ColScenario = 'actual' | 'budget' | 'variance' | 'underwriting' | 'variance_underwriting' | 'variance_budget_underwriting'

const formatterByValueType = (valueType: `${ReportingDataValueType}`, params: ValueFormatterParams, assetType?: AssetType) => {
    const isPSFCol = params.column.getColId().endsWith('_psf')
    const isPSFAsset = assetType !== AssetType.MULTIFAMILY

    const fractionDigits = valueType === ReportingDataValueType.PERCENTAGE
        ? 1
        : isPSFCol && isPSFAsset
            ? 2
            : 0
    return FORMATTER_BY_VALUE_TYPE[valueType]({
        ...params,
        // @ts-expect-error custom property fractionDigits
        fractionDigits,
    })
}

const getScenarioCols = (
    conditionalFormatRules: ConditionalFormatRule[],
    colScenario: ColScenario,
    timeComparison: TimeComparisonPeriod,
    scenarios: Set<`${ReportScenario}`>,
    psfTitle: string,
    assetType?: AssetType,
): Feb25ColDef[] => {
    const hasPercentageSubcol =
        colScenario === 'variance' ||
        colScenario === 'variance_underwriting' ||
        colScenario === 'variance_budget_underwriting'

    const mainColBorderClass = hasPercentageSubcol
        ? !scenarios.has(`${colScenario}_psf`) && !scenarios.has(`${colScenario}_percent`) ? Feb25Classes.RightBorder1 : ''
        : !scenarios.has(`${colScenario}_psf`) ? Feb25Classes.RightBorder1 : ''

    const psfColBorderClass = hasPercentageSubcol
        ? !scenarios.has(`${colScenario}_percent`) ? Feb25Classes.RightBorder1 : ''
        : Feb25Classes.RightBorder1

    const isClickableTimePeriod = CLICKABLE_TIME_COMPARISONS.includes(timeComparison)

    const coDefs: Feb25ColDef[] = [
        // Actual, Budget, Underwriting, Variance, Var to UW columns
        {
            flex: 1,
            maxWidth: DATA_COL_MAX_WIDTH,
            headerName: '$',
            headerTooltip: '$',
            field: `values.${timeComparison}.${colScenario}`,
            hide: !scenarios.has(colScenario),
            headerClass: [Feb25Classes.HeaderL2, Feb25Classes.RightAlignedHeader, mainColBorderClass],
            cellClass: (params: CellClassParams) => [
                Feb25Classes.RightAlignedCell,
                Feb25Classes.HighlightTotalCell,
                mainColBorderClass,
                params.data.type === ReportingPnLRowType.Account && isClickableTimePeriod ? Feb25Classes.Clickable : '',
                getExcelTypeClass(params),
                ...getExcelGroupClasses(params),
            ],
            valueGetter: ({ data }: { data: ReportingPnLVarianceRow }) => {
                if (data.type === ReportingPnLRowType.Separator) { return '' }
                if (data.type === ReportingPnLRowType.ReportDetails) {
                    return (colScenario === ReportScenario.ACTUAL || colScenario === ReportScenario.BUDGET) ? (data.values?.[timeComparison] ?? '') : ''
                }
                if (data.type === ReportingPnLRowType.AssetDetails) {
                    return (colScenario === ReportScenario.ACTUAL) ? (data.values?.[timeComparison] ?? '') : ''
                }

                return data.values?.[timeComparison]?.[colScenario] ?? (data.value_type === ReportingDataValueType.STRING ? '' : 0)
            },
            valueFormatter: (params: ValueFormatterParams<ReportingPnLVarianceRow>) => {
                const { data } = params
                if (data?.type === ReportingPnLRowType.ReportDetails) {
                    return (colScenario === ReportScenario.ACTUAL || colScenario === ReportScenario.BUDGET)
                        ? formatterByValueType(params.data?.value_type ?? ReportingDataValueType.STRING, params) : ''
                }

                if (data?.type === ReportingPnLRowType.AssetDetails) {
                    return (colScenario === ReportScenario.ACTUAL)
                        ? formatterByValueType(params.data?.value_type ?? ReportingDataValueType.STRING, params) : ''
                }

                return formatterByValueType(params.data?.value_type ?? ReportingDataValueType.STRING, params)
            },
            cellStyle: (params: CellClassParams<ReportingPnLVarianceRow>) => {
                let newStyle = {}
                if (params.data?.type === ReportingPnLRowType.ReportDetails && params.data?.value_type === ReportingDataValueType.STRING) {
                    newStyle = {
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                    }
                }
                return newStyle
            },
            cellRenderer: (params: ICellRendererParams<ReportingPnLVarianceRow>) => {
                const reportScenario = (colScenario === 'actual') ? ReportScenario.ACTUAL : (colScenario === 'budget') ? ReportScenario.BUDGET : ReportScenario.VARIANCE
                return cellRenderStyledFromConditionalFormatRules(conditionalFormatRules, params, timeComparison, reportScenario)
            },
            tooltipValueGetter: DEFAULT_TOOLTIP_VALUE_GETTER,
        },
        // PSF column
        {
            flex: 1,
            headerName: psfTitle,
            headerTooltip: psfTitle,
            field: `values.${timeComparison}.${colScenario}_psf`,
            hide: !scenarios.has(`${colScenario}_psf`),
            headerClass: [Feb25Classes.HeaderL2, Feb25Classes.RightAlignedHeader, psfColBorderClass],
            cellClass: (params: CellClassParams) => [
                Feb25Classes.RightAlignedCell,
                Feb25Classes.HighlightTotalCell,
                psfColBorderClass,
                params.data.type === ReportingPnLRowType.Account && isClickableTimePeriod ? Feb25Classes.Clickable : '',
                getExcelTypeClass(params),
                ...getExcelGroupClasses(params),
            ],
            valueGetter: ({ data }: { data: ReportingPnLVarianceRow }) => {
                if (data.type === ReportingPnLRowType.Separator || data.type === ReportingPnLRowType.AssetDetails) { return '' }
                if (data.type === ReportingPnLRowType.ReportDetails) {
                    return (colScenario === ReportScenario.ACTUAL || colScenario === ReportScenario.BUDGET) ? (data.values?.[timeComparison] ?? '') : ''
                }

                return data.values?.[timeComparison]?.[`${colScenario}_psf`] ?? (data.value_type === ReportingDataValueType.STRING ? '' : 0)
            },
            valueFormatter: (params: ValueFormatterParams<ReportingPnLVarianceRow>) => {
                const { data } = params
                if (data?.type === ReportingPnLRowType.ReportDetails) {
                    return (colScenario === ReportScenario.ACTUAL || colScenario === ReportScenario.BUDGET)
                        ? formatterByValueType(params.data?.value_type ?? ReportingDataValueType.STRING, params) : ''
                }

                if (data?.type === ReportingPnLRowType.AssetDetails) { return '' }

                return formatterByValueType(params.data?.value_type ?? ReportingDataValueType.STRING, params, assetType)
            },
            cellRenderer: (params: ICellRendererParams<ReportingPnLVarianceRow>) => {
                const reportScenario = (colScenario === 'actual') ? ReportScenario.ACTUAL_PSF : (colScenario === 'budget') ? ReportScenario.BUDGET_PSF : ReportScenario.VARIANCE_PSF
                return cellRenderStyledFromConditionalFormatRules(conditionalFormatRules, params, timeComparison, reportScenario)
            },
            tooltipValueGetter: DEFAULT_TOOLTIP_VALUE_GETTER,
        },
    ]

    if (hasPercentageSubcol) {
        // % column
        coDefs.push({
            flex: 1,
            headerName: '%',
            headerTooltip: '%',
            field: `values.${timeComparison}.${colScenario}_percent`,
            hide: !scenarios.has(`${colScenario}_percent`),
            headerClass: [Feb25Classes.HeaderL2, Feb25Classes.RightAlignedHeader, Feb25Classes.RightBorder1],
            cellClass: (params: CellClassParams) => [
                Feb25Classes.RightAlignedCell,
                Feb25Classes.HighlightTotalCell,
                Feb25Classes.RightBorder1,
                params.data.type === ReportingPnLRowType.Account && isClickableTimePeriod ? Feb25Classes.Clickable : '',
                ExcelClasses.Percentage1Decimal,
                ...getExcelGroupClasses(params),
            ],
            valueGetter: ({ data }: { data: ReportingPnLVarianceRow }) => {
                if (data.type === ReportingPnLRowType.Separator || data.type === ReportingPnLRowType.ReportDetails || data.type === ReportingPnLRowType.AssetDetails) { return '' }
                return data.values?.[timeComparison]?.[`${colScenario}_percent`] ?? (data.value_type === ReportingDataValueType.STRING ? '' : 0)
            },
            valueFormatter: (params: ValueFormatterParams<ReportingPnLVarianceRow>) => {
                const { data } = params
                if (data?.type === ReportingPnLRowType.AssetDetails || data?.type === ReportingPnLRowType.ReportDetails) { return '' }

                return formatterByValueType(ReportingDataValueType.PERCENTAGE, params, assetType)
            },
            cellRenderer: (params: ICellRendererParams<ReportingPnLVarianceRow>) => {
                return cellRenderStyledFromConditionalFormatRules(conditionalFormatRules, params, timeComparison, ReportScenario.VARIANCE_PERCENT)
            },
            tooltipValueGetter: DEFAULT_TOOLTIP_VALUE_GETTER,
        })
    }

    return coDefs
}

export const getPnLVAColDefs = ({ conditionalFormatRules = [], month, timeComparison, scenarioIds, showAccountCodes, assetType, hideNotes = false, insideReport, tableId, underwritingDate }: {
    conditionalFormatRules?: ConditionalFormatRule[]
    month?: number | null
    timeComparison?: TimeComparisonPeriod[] | null
    scenarioIds?: ReportScenario[] | null
    showAccountCodes: boolean
    assetType?: AssetType | null
    hideNotes?: boolean
    insideReport?: boolean
    tableId?: string | number
    underwritingDate?: string | null
},
): Feb25ColDef[] => {
    if (!month || !timeComparison || !scenarioIds) {
        return []
    }

    const underwritingMonth = underwritingDate ? format(zonedTimeToUtc(underwritingDate, 'UTC'), DATE_FNS_DEFAULT_FORMAT_MONTH_SHORT) : null
    const monthString = monthIndToString(month)
    const psfTitle = REPORT_METRICS_BY_ASSET_TYPE[assetType ?? AssetType.MULTIFAMILY]
    const aType = assetType ?? AssetType.MULTIFAMILY
    const timeComparisonSet = new Set(timeComparison)
    const scenarioIdsSet = new Set(scenarioIds)

    const headerGroupL0Classes = [Feb25Classes.RightBorder1, Feb25Classes.HeaderL0, ExcelClasses.AlignCenter, ExcelClasses.HeaderGroupL0]
    const headerGroupL1Classes = [Feb25Classes.RightBorder1, Feb25Classes.HeaderL1, ExcelClasses.AlignCenter, ExcelClasses.HeaderGroupL1]

    const colDefs: Array<Feb25ColGroupDef | Feb25ColDef> = [
        {
            // Metric section
            headerClass: [Feb25Classes.RightBorder1],
            children: [
                {
                    headerClass: [Feb25Classes.RightBorder1, Feb25Classes.WhiteBG],
                    headerName: monthString,
                    headerTooltip: monthString,
                    children: [
                        {
                            headerClass: [Feb25Classes.HeaderHidden],
                            field: 'name', // need this to have colId and fix total rows in excel export
                            cellClass: (params: CellClassParams) => [
                                Feb25Classes.RightBorder1,
                                getIndentClassForGroupNode(params),
                                ...getExcelGroupClasses(params),
                                params.data?.type === ReportingPnLRowType.SubCategoryMetric ? ExcelClasses.Indent2 : '',
                            ],
                            cellStyle: (params: CellClassParams<ReportingPnLVarianceRow>) => {
                                // shift sub-category metrics to the right
                                if (params.data?.type === ReportingPnLRowType.SubCategoryMetric) {
                                    return { '--ag-indentation-level': 1 }
                                }
                            },
                            showRowGroup: true,
                            cellRenderer: 'agGroupCellRenderer',
                            cellRendererParams: {
                                suppressCount: true,
                            },
                            lockPosition: 'left',
                            pinned: 'left',
                            type: 'string',
                            minWidth: NAME_COLUMN_WIDTH,
                            maxWidth: NAME_COLUMN_MAX_WIDTH,
                            valueGetter: ({ data }: { data: ReportingPnLVarianceRow }) => {
                                if (data.type === ReportingPnLRowType.Separator) { return '' }
                                return showAccountCodes && data.code ? `(${data.code}) ${data.name}` : data.name
                            },
                        },
                        {
                            hide: hideNotes,
                            headerName: 'Notes',
                            field: 'note',
                            maxWidth: 70,
                            pinned: 'left',
                            suppressNavigable: true,
                            headerClass: [Feb25Classes.RightBorder1, Feb25Classes.HeaderHidden],
                            cellClass: [Feb25Classes.RightBorder1],
                            cellRenderer: ({ data }: { data: ReportingPnLVarianceRow }) => {
                                return (
                                    data.type !== ReportingPnLRowType.Separator ? <PnlVeltReportNote data={data} insideReport={insideReport} tableId={tableId}/> : null
                                )
                            },
                        },
                        // Hidden column for exporting notes list
                        {
                            headerName: 'Notes',
                            field: 'notes',
                            hide: true,
                        },
                    ],
                },
            ],
        },
    ]

    // Data section
    if (timeComparisonSet.has(TimeComparisonPeriod.MTD)) {
        colDefs.push(
            {
                headerName: 'MTD',
                headerTooltip: 'MTD',
                suppressStickyLabel: true,
                headerClass: headerGroupL0Classes,
                children: [
                    {
                        headerName: 'Actual',
                        headerTooltip: 'Actual',
                        suppressStickyLabel: true,
                        headerClass: headerGroupL1Classes,
                        children: getScenarioCols(conditionalFormatRules, 'actual', TimeComparisonPeriod.MTD, scenarioIdsSet, psfTitle, aType),
                    },
                    {
                        headerName: 'Budget',
                        headerTooltip: 'Budget',
                        suppressStickyLabel: true,
                        headerClass: headerGroupL1Classes,
                        children: getScenarioCols(conditionalFormatRules, 'budget', TimeComparisonPeriod.MTD, scenarioIdsSet, psfTitle, aType),
                    },
                    {
                        headerName: `U/W (${underwritingMonth})`,
                        headerTooltip: `U/W (${underwritingMonth})`,
                        suppressStickyLabel: true,
                        headerClass: headerGroupL1Classes,
                        children: getScenarioCols(conditionalFormatRules, 'underwriting', TimeComparisonPeriod.MTD, scenarioIdsSet, psfTitle, aType),
                    },
                    {
                        headerName: 'Var. To Budget',
                        headerTooltip: 'Variance To Budget',
                        suppressStickyLabel: true,
                        headerClass: headerGroupL1Classes,
                        children: getScenarioCols(conditionalFormatRules, 'variance', TimeComparisonPeriod.MTD, scenarioIdsSet, psfTitle, aType),
                    },
                    {
                        headerName: 'Var. To U/W',
                        headerTooltip: 'Var. To U/W',
                        suppressStickyLabel: true,
                        headerClass: headerGroupL1Classes,
                        children: getScenarioCols(conditionalFormatRules, 'variance_underwriting', TimeComparisonPeriod.MTD, scenarioIdsSet, psfTitle, aType),
                    },
                    {
                        headerName: 'Budget To U/W Var.',
                        headerTooltip: 'Budget To U/W Var.',
                        suppressStickyLabel: true,
                        headerClass: headerGroupL1Classes,
                        children: getScenarioCols(conditionalFormatRules, 'variance_budget_underwriting', TimeComparisonPeriod.MTD, scenarioIdsSet, psfTitle, aType),
                    },
                ],
            },
        )
    }

    if (timeComparisonSet.has(TimeComparisonPeriod.YTD)) {
        if (timeComparisonSet.has(TimeComparisonPeriod.MTD)) {
            // add empty column between MTD and YTD
            colDefs.push(emptyColDef)
        }

        colDefs.push(
            {
                headerName: 'YTD',
                headerTooltip: 'YTD',
                suppressStickyLabel: true,
                headerClass: headerGroupL0Classes,
                children: [
                    {
                        headerName: 'Actual',
                        headerTooltip: 'Actual',
                        suppressStickyLabel: true,
                        headerClass: headerGroupL1Classes,
                        children: getScenarioCols(conditionalFormatRules, 'actual', TimeComparisonPeriod.YTD, scenarioIdsSet, psfTitle, aType),
                    },
                    {
                        headerName: 'Budget',
                        headerTooltip: 'Budget',
                        suppressStickyLabel: true,
                        headerClass: headerGroupL1Classes,
                        children: getScenarioCols(conditionalFormatRules, 'budget', TimeComparisonPeriod.YTD, scenarioIdsSet, psfTitle, aType),
                    },
                    {
                        headerName: `U/W (${underwritingMonth})`,
                        headerTooltip: `U/W (${underwritingMonth})`,
                        suppressStickyLabel: true,
                        headerClass: headerGroupL1Classes,
                        children: getScenarioCols(conditionalFormatRules, 'underwriting', TimeComparisonPeriod.YTD, scenarioIdsSet, psfTitle, aType),
                    },
                    {
                        headerName: 'Var. To Budget',
                        headerTooltip: 'Variance To Budget',
                        suppressStickyLabel: true,
                        headerClass: headerGroupL1Classes,
                        children: getScenarioCols(conditionalFormatRules, 'variance', TimeComparisonPeriod.YTD, scenarioIdsSet, psfTitle, aType),
                    },
                    {
                        headerName: 'Var. To U/W',
                        headerTooltip: 'Var. To U/W',
                        suppressStickyLabel: true,
                        headerClass: headerGroupL1Classes,
                        children: getScenarioCols(conditionalFormatRules, 'variance_underwriting', TimeComparisonPeriod.YTD, scenarioIdsSet, psfTitle, aType),
                    },
                    {
                        headerName: 'Budget To U/W Var.',
                        headerTooltip: 'Budget To U/W Var.',
                        suppressStickyLabel: true,
                        headerClass: headerGroupL1Classes,
                        children: getScenarioCols(conditionalFormatRules, 'variance_budget_underwriting', TimeComparisonPeriod.YTD, scenarioIdsSet, psfTitle, aType),
                    },
                ],
            },
        )
    }

    if (timeComparisonSet.has(TimeComparisonPeriod.PMMTD)) {
        if (timeComparisonSet.has(TimeComparisonPeriod.YTD) || timeComparisonSet.has(TimeComparisonPeriod.MTD)) {
            // add empty column between MTD, YTD and PMMTD
            colDefs.push(emptyColDef)
        }

        colDefs.push(
            {
                headerName: 'MTD Prior Month',
                headerTooltip: 'MTD Prior Month',
                suppressStickyLabel: true,
                headerClass: headerGroupL0Classes,
                children: [
                    {
                        headerName: 'Actual',
                        headerTooltip: 'Actual',
                        suppressStickyLabel: true,
                        headerClass: headerGroupL1Classes,
                        children: getScenarioCols(conditionalFormatRules, 'actual', TimeComparisonPeriod.PMMTD, scenarioIdsSet, psfTitle, aType),
                    },
                    {
                        headerName: `U/W (${underwritingMonth})`,
                        headerTooltip: `U/W (${underwritingMonth})`,
                        suppressStickyLabel: true,
                        headerClass: headerGroupL1Classes,
                        children: getScenarioCols(conditionalFormatRules, 'underwriting', TimeComparisonPeriod.PMMTD, scenarioIdsSet, psfTitle, aType),
                    },
                    {
                        headerName: 'Var. To MTD',
                        headerTooltip: 'Variance To MTD',
                        suppressStickyLabel: true,
                        headerClass: headerGroupL1Classes,
                        children: getScenarioCols(conditionalFormatRules, 'variance', TimeComparisonPeriod.PMMTD, scenarioIdsSet, psfTitle, aType),
                    },
                    {
                        headerName: 'Var. To U/W',
                        headerTooltip: 'Var. To U/W',
                        suppressStickyLabel: true,
                        headerClass: headerGroupL1Classes,
                        children: getScenarioCols(conditionalFormatRules, 'variance_underwriting', TimeComparisonPeriod.PMMTD, scenarioIdsSet, psfTitle, aType),
                    },
                    {
                        headerName: 'Budget To U/W Var.',
                        headerTooltip: 'Budget To U/W Var.',
                        suppressStickyLabel: true,
                        headerClass: headerGroupL1Classes,
                        children: getScenarioCols(conditionalFormatRules, 'variance_budget_underwriting', TimeComparisonPeriod.PMMTD, scenarioIdsSet, psfTitle, aType),
                    },
                ],
            },
        )
    }

    if (timeComparisonSet.has(TimeComparisonPeriod.PYYTD)) {
        if (timeComparisonSet.has(TimeComparisonPeriod.PMMTD) ||
            timeComparisonSet.has(TimeComparisonPeriod.YTD) ||
            timeComparisonSet.has(TimeComparisonPeriod.MTD)) {
            // add empty column between MTD, YTD and PMMTD
            colDefs.push(emptyColDef)
        }
        colDefs.push(
            {
                headerName: 'YTD Prior Year',
                headerTooltip: 'YTD Prior Year',
                suppressStickyLabel: true,
                headerClass: headerGroupL0Classes,
                children: [
                    {
                        headerName: 'Actual',
                        headerTooltip: 'Actual',
                        suppressStickyLabel: true,
                        headerClass: headerGroupL1Classes,
                        children: getScenarioCols(conditionalFormatRules, 'actual', TimeComparisonPeriod.PYYTD, scenarioIdsSet, psfTitle, aType),
                    },
                    {
                        headerName: `U/W (${underwritingMonth})`,
                        headerTooltip: `U/W (${underwritingMonth})`,
                        suppressStickyLabel: true,
                        headerClass: headerGroupL1Classes,
                        children: getScenarioCols(conditionalFormatRules, 'underwriting', TimeComparisonPeriod.PYYTD, scenarioIdsSet, psfTitle, aType),
                    },
                    {
                        headerName: 'Var. To YTD',
                        headerTooltip: 'Variance To YTD',
                        suppressStickyLabel: true,
                        headerClass: headerGroupL1Classes,
                        children: getScenarioCols(conditionalFormatRules, 'variance', TimeComparisonPeriod.PYYTD, scenarioIdsSet, psfTitle, aType),
                    },
                    {
                        headerName: 'Var. To U/W',
                        headerTooltip: 'Var. To U/W',
                        suppressStickyLabel: true,
                        headerClass: headerGroupL1Classes,
                        children: getScenarioCols(conditionalFormatRules, 'variance_underwriting', TimeComparisonPeriod.PYYTD, scenarioIdsSet, psfTitle, aType),
                    },
                    {
                        headerName: 'Budget To U/W Var.',
                        headerTooltip: 'Budget To U/W Var.',
                        suppressStickyLabel: true,
                        headerClass: headerGroupL1Classes,
                        children: getScenarioCols(conditionalFormatRules, 'variance_budget_underwriting', TimeComparisonPeriod.PYYTD, scenarioIdsSet, psfTitle, aType),
                    },
                ],
            },
        )
    }

    if (timeComparisonSet.has(TimeComparisonPeriod.PYMTD)) {
        if (timeComparisonSet.has(TimeComparisonPeriod.PYYTD) ||
            timeComparisonSet.has(TimeComparisonPeriod.PMMTD) ||
            timeComparisonSet.has(TimeComparisonPeriod.YTD) ||
            timeComparisonSet.has(TimeComparisonPeriod.MTD)) {
            // add empty column between MTD, YTD, PMMTD, PYYTD and PYMTD
            colDefs.push(emptyColDef)
        }

        colDefs.push(
            {
                headerName: 'MTD Prior Year',
                headerTooltip: 'MTD Prior Year',
                suppressStickyLabel: true,
                headerClass: headerGroupL0Classes,
                children: [
                    {
                        headerName: 'Actual',
                        headerTooltip: 'Actual',
                        suppressStickyLabel: true,
                        headerClass: headerGroupL1Classes,
                        children: getScenarioCols(conditionalFormatRules, 'actual', TimeComparisonPeriod.PYMTD, scenarioIdsSet, psfTitle, aType),
                    },
                    {
                        headerName: `U/W (${underwritingMonth})`,
                        headerTooltip: `U/W (${underwritingMonth})`,
                        suppressStickyLabel: true,
                        headerClass: headerGroupL1Classes,
                        children: getScenarioCols(conditionalFormatRules, 'underwriting', TimeComparisonPeriod.PYMTD, scenarioIdsSet, psfTitle, aType),
                    },
                    {
                        headerName: 'Var. To MTD',
                        headerTooltip: 'Variance To MTD',
                        suppressStickyLabel: true,
                        headerClass: headerGroupL1Classes,
                        children: getScenarioCols(conditionalFormatRules, 'variance', TimeComparisonPeriod.PYMTD, scenarioIdsSet, psfTitle, aType),
                    },
                    {
                        headerName: 'Var. To U/W',
                        headerTooltip: 'Var. To U/W',
                        suppressStickyLabel: true,
                        headerClass: headerGroupL1Classes,
                        children: getScenarioCols(conditionalFormatRules, 'variance_underwriting', TimeComparisonPeriod.PYMTD, scenarioIdsSet, psfTitle, aType),
                    },
                    {
                        headerName: 'Budget To U/W Var.',
                        headerTooltip: 'Budget To U/W Var.',
                        suppressStickyLabel: true,
                        headerClass: headerGroupL1Classes,
                        children: getScenarioCols(conditionalFormatRules, 'variance_budget_underwriting', TimeComparisonPeriod.PYMTD, scenarioIdsSet, psfTitle, aType),
                    },
                ],
            },
        )
    }

    return colDefs
}

export const getContextMenuItems = (openTableSettings: () => void) => (params: GetContextMenuItemsParams): Array<string | MenuItemDef> => {
    const result: Array<string | MenuItemDef> = [
        'copy',
        'copyWithHeaders',
        'copyWithGroupHeaders',
        'separator',
        'export',
        'separator',
        {
            name: 'Expand All Row Groups',
            action: () => params.api.expandAll(),
        },
        {
            name: 'Collapse All Row Groups',
            action: () => params.api.collapseAll(),
        },
        {
            name: 'Table Settings',
            action: openTableSettings,
        },
    ]
    return result
}

export const DEFAULT_PNL_VA_CONDITIONAL_FORMAT_RULES: ConditionalFormatRule[] = [
    {
        id: crypto.randomUUID(),
        ruleName: 'Highlight Variance (Green)',
        columnsToBeFormatted: [ReportScenario.VARIANCE, ReportScenario.VARIANCE_PERCENT, ReportScenario.VARIANCE_PSF],
        primaryConditionColumn: ReportScenario.VARIANCE,
        primaryConditionFunction: ConditionalFormatRuleFunction.GREATER_THAN,
        primaryConditionValue: '3000',
        conditionCombinationOperator: 'AND',
        secondaryConditionColumn: ReportScenario.VARIANCE_PERCENT,
        secondaryConditionFunction: ConditionalFormatRuleFunction.GREATER_THAN,
        secondaryConditionValue: '10%',
        styleBackColor: generatedColors.colorsTetrialGreen50,
        styleForeColor: generatedColors.colorsTetrialGreen400,
        styleIcon: 'up',
        styleFont: [],
        styleFontSize: 0,
        newRule: true,
        dirty: true,
    },
    {
        id: crypto.randomUUID(),
        ruleName: 'Highlight Variance (Red)',
        columnsToBeFormatted: [ReportScenario.VARIANCE, ReportScenario.VARIANCE_PERCENT, ReportScenario.VARIANCE_PSF],
        primaryConditionColumn: ReportScenario.VARIANCE,
        primaryConditionFunction: ConditionalFormatRuleFunction.LESS_THAN,
        primaryConditionValue: '-3000',
        conditionCombinationOperator: 'AND',
        secondaryConditionColumn: ReportScenario.VARIANCE_PERCENT,
        secondaryConditionFunction: ConditionalFormatRuleFunction.LESS_THAN,
        secondaryConditionValue: '-10%',
        styleBackColor: generatedColors.colorsTetrialRed100,
        styleForeColor: generatedColors.colorsTetrialRed600,
        styleIcon: 'down',
        styleFont: [],
        styleFontSize: 0,
        newRule: true,
        dirty: true,
    },
]

export const pnLVarianceTableGridOptions: Omit<AgGridTableProps<'feb25'>, 'items'> = {
    ...pnLTimeSeriesTableGridOptions,
    theme: 'feb25',
    columnTypes: FEB25_COL_TYPES,
    getRowClass: ({ data }: { data: ReportingPnLRow }) => {
        if (data.type === ReportingPnLRowType.SubCategoryMetric) {
            return [Feb25Classes.ItalicRow, Feb25Classes.SmallFontRow]
        }
        if (data.type === ReportingPnLRowType.Separator) {
            return [Feb25Classes.SeparatorRow]
        }
        if (data.type === ReportingPnLRowType.CategoryMetric) {
            return [Feb25Classes.GrandTotalRow]
        }
    },
}
