import { TimeInterval } from '@/constants/timeInterval'
import { TimeSettings } from '@/constants/timeSettings'

import { getTimePeriodParam } from '../TableBuilderTables.utils'

export function getTimePeriodParamForSettings ({
    timeSettings,
    timeInterval,
    month,
    quarter,
    year,
    ...rest
}: {
    timeSettings?: TimeSettings | null
    timeInterval?: TimeInterval | null
    month?: number | null
    quarter?: string | null
    year?: number | null
} & Parameters<typeof getTimePeriodParam>[0]) {
    switch (timeSettings) {
        case TimeSettings.SINGLE:
            switch (timeInterval) {
                case TimeInterval.MONTH:
                    return month
                case TimeInterval.QUARTER:
                    return quarter
                case TimeInterval.YEAR:
                    return year
            }
            return undefined

        case TimeSettings.SERIES:
            return getTimePeriodParam({
                timeInterval,
                ...rest,
            })

        default:
            return month
    }
}
