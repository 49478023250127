import { ConditionalFormatRule, ConditionalFormatStyle } from './ConditionalRuleEditor.types'

export const applyConditionalFormattingRuleToStyle = (rule: ConditionalFormatRule, oldStyle: ConditionalFormatStyle = {}) => {
    let newStyle: ConditionalFormatStyle = { ...oldStyle }
    if (rule.styleForeColor) {
        newStyle = {
            ...newStyle,
            color: rule.styleForeColor,
        }
    }
    if (rule.styleBackColor) {
        newStyle = {
            ...newStyle,
            backgroundColor: rule.styleBackColor,
        }
    }
    if (rule.styleFontSize) {
        newStyle = {
            ...newStyle,
            fontSize: rule.styleFontSize,
        }
    }
    if (rule.styleFont.includes('BOLD')) {
        newStyle = {
            ...newStyle,
            fontWeight: '700',
        }
    }
    if (rule.styleFont.includes('ITALIC')) {
        newStyle = {
            ...newStyle,
            fontStyle: 'italic',
        }
    }
    if (rule.styleFont.includes('UNDERLINE')) {
        newStyle = {
            ...newStyle,
            textDecoration: 'underline',
        }
    }
    if (rule.styleFont.includes('STRIKETHROUGH')) {
        newStyle = {
            ...newStyle,
            textDecoration: 'line-through',
        }
    }
    if (rule.styleFont.includes('STRIKETHROUGH') && rule.styleFont.includes('UNDERLINE')) {
        newStyle = {
            ...newStyle,
            textDecoration: 'line-through underline',
        }
    }
    if (rule.styleIcon) {
        newStyle = {
            ...newStyle,
            cellIconStyle: {
                iconClass: rule.styleIcon === 'up' ? 'ag-icon-small-up' : 'ag-icon-small-down',
                color: newStyle?.color,
                fontSize: '27px',
                textAlign: 'left',
            },
        }
    }

    newStyle = {
        ...newStyle,
        borderRadius: '5px',
        paddingRight: '8px',
        marginRight: '-2px',
        marginLeft: '-2px',
        textAlign: 'right',
        display: 'flex',
        justifyContent: 'space-between',
    }

    return newStyle
}
