import { SideMenuItemType } from '@/components/widgets'
import { ADMIN_ROUTES_CONFIG, MANAGER_ROUTES_CONFIG, OWNER_ROUTES_CONFIG } from '@/core/routes/routes.constants'

export const OWNER_SIDE_MENU_ITEMS: SideMenuItemType[] = [
    {
        ...OWNER_ROUTES_CONFIG.PACKAGES,
        testId: 'sideMenuPackages',
        icon: 'grid',
    },
    {
        ...OWNER_ROUTES_CONFIG.REPORTS,
        testId: 'sideMenuReports',
        icon: 'document',
    },
    {
        ...OWNER_ROUTES_CONFIG.CSV_BUILDER,
        testId: 'sideMenuCSVBuilder',
        icon: 'csv',
    },
    {
        title: 'Admin',
        icon: 'userSettings',
        testId: 'sideMenuAdmin',
        children: [
            {
                ...OWNER_ROUTES_CONFIG.ADMIN_CENTER_ASSETS,
                testId: 'sideMenuAssets',
            },
            {
                ...OWNER_ROUTES_CONFIG.LEDGERS,
                testId: 'sideMenuLedgers',
            },
            {
                ...OWNER_ROUTES_CONFIG.ASSET_MAPPING,
                testId: 'sideMenuMapping',
            },
            {
                ...OWNER_ROUTES_CONFIG.TABLES,
                title: 'Table Builder (Beta)',
            },
            {
                title: 'Help',
                externalLink: 'https://admin.intelas.com/faq',
            },
        ],
    },
]

export const ADMIN_SIDE_MENU_ITEMS: SideMenuItemType[] = [
    {
        title: ADMIN_ROUTES_CONFIG.COMPANIES.title,
        path: ADMIN_ROUTES_CONFIG.COMPANIES.path,
        icon: 'users',
    },
    {
        title: ADMIN_ROUTES_CONFIG.ASSETS.title,
        path: ADMIN_ROUTES_CONFIG.ASSETS.path,
        icon: 'asset',
    },
    {
        title: ADMIN_ROUTES_CONFIG.VALIDATION_TRACKER.title,
        path: ADMIN_ROUTES_CONFIG.VALIDATION_TRACKER.path,
        icon: 'document',
    },
    {
        title: ADMIN_ROUTES_CONFIG.CSV_BUILDER.title,
        path: ADMIN_ROUTES_CONFIG.CSV_BUILDER.path,
        icon: 'csv',
    },
    {
        title: ADMIN_ROUTES_CONFIG.SETTINGS.title,
        path: ADMIN_ROUTES_CONFIG.SETTINGS.path,
        icon: 'gear',
    },
    {
        title: ADMIN_ROUTES_CONFIG.METRIC_BUILDER.title,
        path: ADMIN_ROUTES_CONFIG.METRIC_BUILDER.path,
        icon: 'grid',
    },
    {
        title: ADMIN_ROUTES_CONFIG.TABLES.title,
        path: ADMIN_ROUTES_CONFIG.TABLES.path,
        icon: 'csv',
    },
    {
        title: ADMIN_ROUTES_CONFIG.DATA_SUBMISSION_UPLOADS.title,
        path: ADMIN_ROUTES_CONFIG.DATA_SUBMISSION_UPLOADS.path,
        icon: 'folder',
    },
    {
        title: ADMIN_ROUTES_CONFIG.ACCOUNTS_GROUPS.title,
        path: ADMIN_ROUTES_CONFIG.ACCOUNTS_GROUPS.path,
        dev: true,
        icon: 'oneCol',
    },
    {
        title: ADMIN_ROUTES_CONFIG.AI_CHAT.title,
        path: ADMIN_ROUTES_CONFIG.AI_CHAT.path,
        dev: true,
        icon: 'oneCol',
    },
    {
        title: ADMIN_ROUTES_CONFIG.EMAIL_TRACKER.title,
        path: ADMIN_ROUTES_CONFIG.EMAIL_TRACKER.path,
        icon: 'mail',
    },
]

export const MANAGER_SIDE_MENU_ITEMS: SideMenuItemType[] = [
    {
        ...MANAGER_ROUTES_CONFIG.PACKAGES,
        testId: 'sideMenuPackages',
        icon: 'grid',
    },
    {
        ...MANAGER_ROUTES_CONFIG.ASSETS,
        testId: 'sideMenuAssets',
        icon: 'asset',
    },
    {
        ...MANAGER_ROUTES_CONFIG.UPLOADS_SYNC,
        icon: 'refresh',
    },
]
