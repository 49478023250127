import {
    MonthRangeInput,
    QuarterRangeInput,
    YearRangeInput,
} from '@/components/baseInputs'
import { TimeIntervalInput, TIME_INTERVAL_INPUT_QUERY_PARAM } from '@/components/baseInputs/TimeIntervalInput'
import { GLOBAL_REPORT_MONTH_RANGE_END_QUERY_PARAM, GLOBAL_REPORT_MONTH_RANGE_START_QUERY_PARAM } from '@/components/widgets/reports/reports.constants'
import { TimeInterval } from '@/constants/timeInterval'
import { useInputState } from '@/hooks'
import {
    getQuarterRangeEndQueryParam,
    getReportQuarterRangeStartQueryParam, getReportYearRangeEndQueryParam, getReportYearRangeStartQueryParam,
} from '@/pages/TablesPage/TableBuilderPage/TableBuilderPage.constants'
import { dateToMonthInd } from '@/utils/date/monthInd'

import { TimeIntervalAndRangeFiltersProps } from './TimeIntervalAndRangeFilters.types'

export const TimeIntervalAndRangeFilters = (props: TimeIntervalAndRangeFiltersProps) => {
    const {
        timeInterval: timeIntervalProps,
        inputParams = {},
        insideReport = false,
        tableId,
    } = props

    const defaultEndMonth = dateToMonthInd(new Date()) - 1 // previous month
    const defaultStartMonth = defaultEndMonth - 11 // 12-month period by default

    const [timeIntervalParam] = useInputState({
        ...TIME_INTERVAL_INPUT_QUERY_PARAM,
        ...inputParams,
    })

    const timeInterval = timeIntervalProps || timeIntervalParam

    return (
        <>
            {!timeIntervalProps && <TimeIntervalInput label='Interval' inputParams={inputParams}/>}

            {timeInterval === TimeInterval.MONTH && (
                <MonthRangeInput
                    label='Time Period'
                    defaultStart={defaultStartMonth}
                    defaultEnd={defaultEndMonth}
                    startRangeInputParams={insideReport ? GLOBAL_REPORT_MONTH_RANGE_START_QUERY_PARAM : undefined}
                    endRangeInputParams={insideReport ? GLOBAL_REPORT_MONTH_RANGE_END_QUERY_PARAM : undefined}
                    required
                />
            )}
            {timeInterval === TimeInterval.QUARTER && (
                <QuarterRangeInput
                    label='Time Period'
                    required
                    endInputParams={insideReport ? getReportQuarterRangeStartQueryParam(tableId) : undefined}
                    startInputParams={insideReport ? getQuarterRangeEndQueryParam(tableId) : undefined}
                />
            )}
            {timeInterval === TimeInterval.YEAR && (
                <YearRangeInput
                    endInputParams={insideReport ? getReportYearRangeEndQueryParam(tableId) : undefined}
                    startInputParams={insideReport ? getReportYearRangeStartQueryParam(tableId) : undefined}
                    label='Time Period'
                    required
                />
            )}
        </>
    )
}
