import { CellClassParams, ExcelStyle } from '@ag-grid-community/core'

import { PropertyComparisonReportItem } from '@/api/report/propertyComparison'
import { VarianceReportItem } from '@/api/report/variance'
import { AgGridTableProps } from '@/components/tables'
import { NeoClasses } from '@/components/tables/AgGridTable/themes/neo/neo.types'
import { colorsPrimaryWhite, colorsSecondaryGrey500, colorsSecondaryPurple50, colorsSecondaryPurple900 } from '@/styles/tokens/generatedColors'

type ReportItem = PropertyComparisonReportItem | VarianceReportItem

export const NAME_COLUMN_WIDTH = 380
export const NAME_COLUMN_MAX_WIDTH = 560
const TREE_ROW_HEIGHT = 24 // tree reports
const TREE_CALC_ROW_HEIGHT = 32 // tree reports
export const ROW_HEIGHT = 32 // grid reports with totals row
export const TOTAL_ROW_HEIGHT = 48 // grid reports with totals row
export const NO_SEARCH = () => ''

export const CELL_CLASS_ALIGN_RIGHT = 'ag-right-aligned-cell'
export const HEADER_GROUP_CLASS_ALIGN_LEFT = ['headerGroupLeftAlinged']
export const HEADER_CLASS_RIGHT_BORDER = ['ag-right-aligned-header', 'rightBorder1']
export const CELL_CLASS_RIGHT_BORDER = [CELL_CLASS_ALIGN_RIGHT, 'rightBorder1']
export const HEADER_CLASS_RIGHT_BORDER_FAT = ['ag-right-aligned-header', 'rightBorder4']
export const CELL_CLASS_RIGHT_BORDER_FAT = [CELL_CLASS_ALIGN_RIGHT, 'rightBorder4']
export const CELL_CLASS_CLICKABLE = ['clickableCell']

export enum ExcelClasses {
    String = 'excelString',
    Percentage1Decimal = 'excelPercentage1Decimal',
    PercentageFraction = 'excelPercentageFraction',
    Currency = 'excelCurrency',
    Number = 'excelNumber',
    Number1Decimal = 'excelNumber1Decimal',
    Date = 'excelDate',
    Total = 'excelTotal',
    GrandTotal = 'excelGrandTotal',
    Group = 'excelGroup',
    Indent1 = 'indent-1',
    Indent2 = 'indent-2',
    Indent3 = 'indent-3',
    Indent4 = 'indent-4',
    Indent5 = 'indent-5',
    Indent6 = 'indent-6',
    Italic = 'excelItalic',
    HeaderGroupL0 = 'excelHeaderGroupLev0',
    HeaderGroupL1 = 'excelHeaderGroupLev1',
    AlignCenter = 'excelAlignCenter',
}

// Styles for proper formatting in Excel export, binded to ag-grid cellClass
export const excelStyles: ExcelStyle[] = [
    {
        id: 'indent-1',
        alignment: {
            indent: 1,
        },
        // note, dataType: 'string' required to ensure that numeric values aren't right-aligned
        dataType: 'String',
    },
    {
        id: 'indent-2',
        alignment: {
            indent: 2,
        },
        dataType: 'String',
    },
    {
        id: 'indent-3',
        alignment: {
            indent: 3,
        },
        dataType: 'String',
    },
    {
        id: 'indent-4',
        alignment: {
            indent: 4,
        },
        dataType: 'String',
    },
    {
        id: 'indent-5',
        alignment: {
            indent: 5,
        },
        dataType: 'String',
    },
    {
        id: 'indent-6',
        alignment: {
            indent: 6,
        },
        dataType: 'String',
    },
    // TODO: --- remove @deprecated classes after refactoring Standard report excel export ---
    // @deprecated: Use excelStyles enum instead
    {
        id: 'currency',
        numberFormat: {
            format: '#,##0;(#,##0);"—"',
        },
    },
    // @deprecated: Use excelStyles enum instead
    {
        id: 'currency2Decimals',
        numberFormat: {
            format: '#,##0.00;(#,##0.00);"—"',
        },
    },
    // @deprecated: Use excelStyles enum instead
    {
        id: 'percentage',
        numberFormat: {
            format: '#,##0"%";-#,##0"%";"—"',
        },
    },
    // @deprecated: Use excelStyles enum instead
    {
        id: 'percentage1Decimal',
        numberFormat: {
            format: '#,##0.0"%";-#,##0.0"%";"—"',
        },
    },
    // @deprecated: Use excelStyles enum instead
    {
        id: 'stringType',
        dataType: 'String',
    },
    // --- remove @deprecated classes after refactoring Standard report excel export ---
    // excel formatting styles
    {
        id: ExcelClasses.String,
        dataType: 'String',
    },
    {
        id: ExcelClasses.Percentage1Decimal,
        numberFormat: {
            format: '#,##0.0"%";-#,##0.0"%";"0.0%"',
        },
    },
    {
        id: ExcelClasses.PercentageFraction,
        numberFormat: {
            format: '#,##0%;-#,##0%;"0%"',
        },
    },
    {
        id: ExcelClasses.Currency,
        numberFormat: {
            format: '#,##0;-#,##0;"0"',
        },
    },
    {
        id: ExcelClasses.Number, // rounded number
        numberFormat: {
            format: '0;-0;"0"',
        },
    },
    {
        id: ExcelClasses.Number1Decimal,
        numberFormat: {
            format: '0.0;-0.0;"0.0"',
        },
    },
    {
        id: ExcelClasses.Date,
        dataType: 'DateTime',
        numberFormat: {
            format: 'mm/dd/yy',
        },
    },
    // header styles
    {
        id: 'header', // automatically applied to header cells
        font: {
            bold: true,
            size: 13,
        },
        interior: {
            pattern: 'Solid',
            color: '#f2f2f2',
        },
        borders: {
            borderBottom: {
                lineStyle: 'Continuous',
                weight: 1,
            },
        },
    },
    {
        id: NeoClasses.RightAlignedHeader,
        alignment: {
            horizontal: 'Right',
        },
    },
    // cells with right border
    {
        id: NeoClasses.RightBorder1,
        borders: {
            borderRight: {
                lineStyle: 'Continuous',
                weight: 1,
            },
        },
    },
    // highlighted column: borders + grey background
    {
        id: NeoClasses.GreyBG,
        borders: {
            borderRight: {
                lineStyle: 'Continuous',
                weight: 1,
            },
            borderLeft: {
                lineStyle: 'Continuous',
                weight: 1,
            },
        },
        interior: {
            pattern: 'Solid',
            color: '#f2f2f2', // light grey, out of our color palette
        },
    },
    {
        id: ExcelClasses.Group,
        font: {
            bold: true,
        },
    },
    // footer
    {
        id: ExcelClasses.Total,
        borders: {
            borderTop: {
                lineStyle: 'Continuous',
                weight: 1,
            },
        },
        interior: {
            pattern: 'Solid',
            color: colorsSecondaryPurple50,
        },
    },
    {
        id: ExcelClasses.GrandTotal,
        font: {
            bold: true,
        },
        borders: {
            borderTop: {
                lineStyle: 'Double',
                weight: 1,
            },
            borderBottom: {
                lineStyle: 'Double',
                weight: 1,
            },
        },
    },
    {
        id: ExcelClasses.Italic,
        font: {
            italic: true,
        },
    },
    {
        id: ExcelClasses.HeaderGroupL0,
        borders: {
            borderRight: {
                lineStyle: 'Continuous',
                weight: 1,
            },
            borderLeft: {
                lineStyle: 'Continuous',
                weight: 1,
            },
        },
        font: {
            color: colorsPrimaryWhite,
        },
        interior: {
            pattern: 'Solid',
            color: colorsSecondaryPurple900,
        },
    },
    {
        id: ExcelClasses.HeaderGroupL1,
        borders: {
            borderRight: {
                lineStyle: 'Continuous',
                weight: 1,
            },
            borderLeft: {
                lineStyle: 'Continuous',
                weight: 1,
            },
        },
        font: {
            color: colorsPrimaryWhite,
        },
        interior: {
            pattern: 'Solid',
            color: colorsSecondaryGrey500,
        },
    },
    {
        id: ExcelClasses.AlignCenter,
        alignment: {
            horizontal: 'Center',
        },
    },
]

// Get indent class for tree reports
export const getIndentClassForGroupNode = (params: CellClassParams): string => {
    let node = params.node

    let indent = 0
    while (node?.parent) {
        indent++
        node = node.parent
    }

    return `indent-${indent}`
}

export const REPORT_COLUMN_DEFAULT = {
    headerName: '',
    minWidth: 100,
    maxWidth: 200,
    type: 'money',
    getQuickFilterText: NO_SEARCH,
}

export const REPORT_GRID_OPTIONS_BASE: Omit<AgGridTableProps<'variance'>, 'items'> = {
    defaultColDef: {
        resizable: false,
        suppressHeaderMenuButton: true,
        sortable: false,
    },
    theme: 'variance',
    columnDefs: [],
    autoGroupColumnDef: {
        headerName: 'Account (target)',
        field: 'account_name',
        width: NAME_COLUMN_WIDTH,
        minWidth: NAME_COLUMN_WIDTH,
        maxWidth: NAME_COLUMN_MAX_WIDTH,
        pinned: 'left',
        cellRendererParams: {
            suppressCount: true,
        },
        cellClass: getIndentClassForGroupNode,
    },
    rowHeight: TREE_ROW_HEIGHT,
    treeData: true,
    groupDefaultExpanded: -1,
    suppressCellFocus: true,
    suppressAggFilteredOnly: true,
    groupIncludeFooter: true,
    getDataPath: (data: ReportItem) => data.tree_path,
    onGridColumnsChanged: (params) => params.api.sizeColumnsToFit(),
    getRowHeight: ({ data }: { data: ReportItem }) => data?.type === 'calculation' ? TREE_CALC_ROW_HEIGHT : TREE_ROW_HEIGHT,
    getRowClass: ({ data }: { data: ReportItem }) => data?.type === 'calculation'
        ? 'calculation'
        : data?.type === 'account' ? 'account' : '',
    suppressMaintainUnsortedOrder: true,
    excelStyles,
}

export const getExcelGroupClasses = (params: CellClassParams): string[] | string => {
    const classes: ExcelClasses[] = []
    if (params.node.group && params.node.footer && params.node.level === -1) {
        classes.push(ExcelClasses.GrandTotal)
    }

    if (params.node.group && params.node.footer) {
        classes.push(ExcelClasses.Total)
    }

    if (params.node.group) {
        classes.push(ExcelClasses.Group)
    }

    return classes
}
