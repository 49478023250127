import { forwardRef, RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import {
    ChartRef,
    Column,
    ExcelExportParams,
    GetContextMenuItemsParams,
    GridState,
    MenuItemDef,
} from '@ag-grid-community/core'
import cn from 'classnames'
import format from 'date-fns/format'
import isEqual from 'lodash/isEqual'
import { AND, EQ } from 'mobx-orm'
import { createPortal } from 'react-dom'

import { AgGridReact } from '@ag-grid-community/react'
import { useNavigate } from 'react-router'

import { useReportingPortfolioMetricsQuery } from '@/api/reportingData/reportingPortfolioMetrics'
import { ReportingTableFilters, useReportingTableByIdQuery } from '@/api/reportingTable/reportingTable'
import { Loader } from '@/components/base'
import {
    MONTH_RANGE_END_QUERY_PARAM,
    MONTH_RANGE_START_QUERY_PARAM,
    YEAR_RANGE_END_QUERY_PARAM,
    YEAR_RANGE_START_QUERY_PARAM,
} from '@/components/baseInputs'
import {
    QUARTER_RANGE_END_QUERY_PARAM,
    QUARTER_RANGE_START_QUERY_PARAM,
} from '@/components/baseInputs/QuarterRangeInput'
import { Layout, useTableSettings } from '@/components/containers'
import { ASSET_DETAILS_QUERY_PARAM, ASSET_SIDE_MODAL_INPUT_QUERY_PARAM } from '@/components/models/asset'
import { AgGridTable } from '@/components/tables'
import { useTableChartModules } from '@/components/tables/utils/useTableChartModules'
import {
    GLOBAL_REPORT_MONTH_RANGE_END_QUERY_PARAM,
    GLOBAL_REPORT_MONTH_RANGE_START_QUERY_PARAM,
    GLOBAL_REPORT_MULTY_ASSET_QUERY_PARAM,
} from '@/components/widgets/reports/reports.constants'
import { DATE_FNS_DEFAULT_FORMAT_MONTH_FULL } from '@/constants/dates'
import { TimeInterval } from '@/constants/timeInterval'
import { TimeSettings } from '@/constants/timeSettings'
import { useInputState } from '@/hooks'
import { NoDataMessage } from '@/pages/TablesPage/TableBuilderPage/NoDataMessage'
import { CancelSaveButtons } from '@/pages/TablesPage/TableBuilderPage/TableBuilder/CancelSaveButtons'
import {
    GROUP_BY_ASSET_DETAIL_INPUT_QUERY_PARAM,
    RP_METRIC_INPUT_QUERY_PARAM,
} from '@/pages/TablesPage/TableBuilderPage/TableBuilderFilters'
import {
    getRpMetricInputQueryParam,
    TABLE_ASSET_TYPE_PARAM,
    TABLE_RR_LEDGER_INPUT_QUERY_PARAM,
    TABLE_TB_LEDGER_INPUT_QUERY_PARAM,

    getReportTableTbLedgerInputQueryParam,
    getReportTableRrLedgerInputQueryParam,
    getReportTableAssetTypeParam,
    getReportQuarterRangeStartQueryParam,
    getQuarterRangeEndQueryParam,
    getReportYearRangeStartQueryParam,
    getReportYearRangeEndQueryParam,
    getReportAssetDetailsInputQueryParam,
    getReportGroupByAssetDetailInputQueryParam,
} from '@/pages/TablesPage/TableBuilderPage/TableBuilderPage.constants'
import {
    defaultColSizingModelForAssetDetails,
    metricTimeSeriesTableGridOptions as gridOptions,
} from '@/pages/TablesPage/TableBuilderPage/TableBuilderTables'
import { statusBar } from '@/pages/TablesPage/TableBuilderPage/TableBuilderTables/TableBuilderTables.constants'
import { TableBuilderTableProps } from '@/pages/TablesPage/TableBuilderPage/TableBuilderTables/TableBuilderTables.types'
import {
    getGridPropsToSave,
    getTimePeriodAsString,
    getTimePeriodParam,
    saveReportingTable,
} from '@/pages/TablesPage/TableBuilderPage/TableBuilderTables/TableBuilderTables.utils'
import {
    useReportingTableSaveMutation,
} from '@/pages/TablesPage/TableBuilderPage/TableBuilderTables/useReportingTableSaveMutation'
import { TableMode, TableType } from '@/pages/TablesPage/tableType'
import { monthIndToDate } from '@/utils/date/monthInd'
import { getRoute } from '@/utils/routing/getRoute'

import { CustomSettingsFilterModal } from '../../CustomSettingsFilterModal'
import { processMetricTSTotalCells } from '../MetricTimeSeriesTable/MetricTimeSeriesTable.utils'

import {
    getMetricSbsColDefs,
} from './MetricSideBySideTable.constants'
import { checkColumnOrderAndSaveGridStateToRef, createMetricChart } from './MetricSideBySideTable.utils'

import chartStyles from '../TableChart/TableChart.module.scss'

export const MetricSideBySideTable = forwardRef((props: TableBuilderTableProps, ref: RefObject<AgGridReact>) => {
    const {
        tableMeta = {},
        buttonContainer,
        onCancel,
        onGridReady,
        id,
        insideReport = false,
        onFirstDataRendered,
        tablesListRouteConfigKey,
        builderRouteConfigKey,
    } = props

    const navigate = useNavigate()
    const isCreationMode = id === 'new'
    const fallbackTableRef = useRef<AgGridReact>(null)
    const tableRef = ref ?? fallbackTableRef
    const gridStateRef = useRef<GridState>() // Retain grid state between renders
    const chartRef = useRef<ChartRef>()
    const chartContainerRef = useRef<HTMLDivElement>(null)
    const chartModules = useTableChartModules()

    const { showTableSettingsModal, setShowTableSettingsModal, getTableSetting, setTableSetting } = useTableSettings()

    const [tableMode, setTableMode] = useState<TableMode | null>(null)
    // useTableChartToggle hook uses this grid context to toggle between table and chart modes
    const context = useRef<{ hasChart: boolean, tableMode: TableMode | null, toggleTableMode: () => void }>(
        {
            hasChart: true,
            tableMode,
            toggleTableMode: () => setTableMode(prev => prev === TableMode.Table ? TableMode.Chart : TableMode.Table),
        })

    useEffect(() => { context.current.tableMode = tableMode }, [tableMode])

    const [isTableDirty, setIsTableDirty] = useState(false)
    const saveMutation = useReportingTableSaveMutation()
    const { data: loadedTableData } = useReportingTableByIdQuery(id, { enabled: !isCreationMode })

    //  default column sizes on creation
    if (!gridStateRef.current && isCreationMode) { gridStateRef.current = { columnSizing: defaultColSizingModelForAssetDetails } }
    // load grid state once
    if (!gridStateRef.current && loadedTableData?.aggrid_state) { gridStateRef.current = loadedTableData.aggrid_state }

    const [tBLedgerId] = useInputState(
        insideReport ? getReportTableTbLedgerInputQueryParam(id) : TABLE_TB_LEDGER_INPUT_QUERY_PARAM,
    )
    const [rRLedgerId] = useInputState(
        insideReport ? getReportTableRrLedgerInputQueryParam(id) : TABLE_RR_LEDGER_INPUT_QUERY_PARAM,
    )
    const [assetType] = useInputState(
        insideReport ? getReportTableAssetTypeParam(id) : TABLE_ASSET_TYPE_PARAM,
    )
    const [assetIds, setAssetIds] = useInputState(
        insideReport ? GLOBAL_REPORT_MULTY_ASSET_QUERY_PARAM : ASSET_SIDE_MODAL_INPUT_QUERY_PARAM,
    )
    const [metricIds, setMetricIds] = useInputState(
        insideReport ? getRpMetricInputQueryParam(id) : RP_METRIC_INPUT_QUERY_PARAM,
    )
    const [startMonth, setStartMonth] = useInputState(
        insideReport ? GLOBAL_REPORT_MONTH_RANGE_START_QUERY_PARAM : MONTH_RANGE_START_QUERY_PARAM,
    )
    const [endMonth, setEndMonth] = useInputState(
        insideReport ? GLOBAL_REPORT_MONTH_RANGE_END_QUERY_PARAM : MONTH_RANGE_END_QUERY_PARAM,
    )
    const [startQuarter, setStartQuarter] = useInputState(
        insideReport ? getReportQuarterRangeStartQueryParam(id) : QUARTER_RANGE_START_QUERY_PARAM,
    )
    const [endQuarter, setEndQuarter] = useInputState(
        insideReport ? getQuarterRangeEndQueryParam(id) : QUARTER_RANGE_END_QUERY_PARAM,
    )
    const [startYear, setStartYear] = useInputState(
        insideReport ? getReportYearRangeStartQueryParam(id) : YEAR_RANGE_START_QUERY_PARAM,
    )
    const [endYear, setEndYear] = useInputState(
        insideReport ? getReportYearRangeEndQueryParam(id) : YEAR_RANGE_END_QUERY_PARAM,
    )
    const [assetDetails, setAssetDetails] = useInputState(
        insideReport ? getReportAssetDetailsInputQueryParam(id) : ASSET_DETAILS_QUERY_PARAM,
    )
    const [groupBy, setGroupBy] = useInputState(
        insideReport ? getReportGroupByAssetDetailInputQueryParam(id) : GROUP_BY_ASSET_DETAIL_INPUT_QUERY_PARAM,
    )

    const conditionalFormatRules = loadedTableData?.id ? (getTableSetting(loadedTableData.id)?.conditionalRules ?? undefined) : undefined

    const showTotalRow = '1'
    const timeInterval = TimeInterval.MONTH

    const timePeriod = useMemo(
        () => getTimePeriodParam({
            startMonth,
            endMonth,
            startQuarter,
            endQuarter,
            startYear,
            endYear,
            timeInterval,
        }),
        [startMonth, endMonth, startQuarter, endQuarter, startYear, endYear, timeInterval],
    )

    const areFiltersSet = Boolean(assetIds && metricIds && timePeriod)

    const { data: reportData, isLoading, isFetched, isSuccess } = useReportingPortfolioMetricsQuery(
        {
            filter: AND(
                EQ('asset_ids', assetIds),
                EQ('trial_balance_ledger_id', tBLedgerId),
                EQ('rent_roll_ledger_id', rRLedgerId),
                EQ('metric_ids', metricIds),
                EQ('time_settings', TimeSettings.SERIES),
                EQ('time_period', timePeriod),
                EQ('time_interval', timeInterval),
            ),
        },
        {
            enabled: areFiltersSet,
        },
    )

    const [rows, columns] = useMemo(() => [
        reportData?.[0]?.rows ?? [],
        reportData?.[0]?.columns ?? [],
    ], [reportData])

    const tableTitle = useMemo(() => {
        const period = timePeriod ? timeInterval === TimeInterval.MONTH && startMonth && endMonth ? `${format(monthIndToDate(startMonth), DATE_FNS_DEFAULT_FORMAT_MONTH_FULL)} - ${format(monthIndToDate(endMonth), DATE_FNS_DEFAULT_FORMAT_MONTH_FULL)}` : timePeriod : ''
        return period
    }, [timePeriod, timeInterval, startMonth, endMonth])

    const columnDefs = useMemo(() => {
        const columnOrder = (gridStateRef.current?.columnOrder?.orderedColIds ?? [])
            .filter(colId => colId.startsWith('values.') && !colId.endsWith('.budget'))
            .map(colId => colId.replace('values.', ''))
        const sortedColumns = columns.sort((a, b) => columnOrder.indexOf(a.id) - columnOrder.indexOf(b.id))

        return getMetricSbsColDefs({
            conditionalFormatRules,
            columns: sortedColumns,
            tableTitle,
            assetDetails,
            groupBy,
            assetType,
        })
    }, [conditionalFormatRules, columns, tableTitle, assetDetails, groupBy, assetType])

    // table config
    const config = useMemo(() => {
        const filters: ReportingTableFilters = { asset_ids: assetIds as number[] }
        if (timeInterval) { filters.timeInterval = timeInterval }
        if (startMonth) { filters.startMonth = startMonth }
        if (endMonth) { filters.endMonth = endMonth }
        if (startQuarter) { filters.startQuarter = startQuarter }
        if (endQuarter) { filters.endQuarter = endQuarter }
        if (startYear) { filters.startYear = startYear }
        if (endYear) { filters.endYear = endYear }
        if (assetDetails) { filters.assetDetails = assetDetails }
        if (metricIds) { filters.rpMetricIds = metricIds }
        if (groupBy) { filters.groupBy = groupBy }
        if (showTotalRow) { filters.showTotalRow = showTotalRow }
        return ({
            filters,
            conditional_format_rules: conditionalFormatRules && { rules: conditionalFormatRules },
            charts: { isChartMode: tableMode === TableMode.Chart },
        })
    }, [conditionalFormatRules, assetDetails, assetIds, endMonth, endQuarter, endYear, groupBy, metricIds, showTotalRow, startMonth, startQuarter, startYear, timeInterval, tableMode])

    // auto resize Asset details & Total columns
    useEffect(() => {
        const colsToResize = tableRef.current?.api?.getColumns()?.filter((c: Column) => c.isVisible)
            .filter(c => c.getColId().startsWith('values.total.') || !c.getColId().startsWith('values'))
            .map(c => c.getColId())
        tableRef.current?.api?.autoSizeColumns([...colsToResize ?? []])
    }, [assetDetails, groupBy, tableRef])

    const checkIfTableIsDirty = useCallback(() => {
        const loadedState = loadedTableData?.aggrid_state ?? {}
        const currentState = getGridPropsToSave(tableRef.current?.api?.getState())
        const currentConfig = config
        const loadedConfig = loadedTableData?.config ?? {}
        const currentName = tableMeta.name
        const loadedName = loadedTableData?.name

        if (
            isEqual(currentConfig, loadedConfig) &&
            isEqual(currentState, loadedState) &&
            currentName === loadedName
        ) {
            setIsTableDirty(false)
        } else {
            setIsTableDirty(true)
        }
    }, [config, loadedTableData?.aggrid_state, loadedTableData?.config, loadedTableData?.name, tableMeta.name, tableRef])

    useEffect(() => checkIfTableIsDirty(), [checkIfTableIsDirty])

    // set defaults on creation
    useEffect(() => {
        if (!isCreationMode) {
            return
        }
        setTableMode(TableMode.Table)
    }, [])

    // set filters from loaded table data
    useEffect(() => {
        if (!loadedTableData) {
            return
        }

        const { filters, conditional_format_rules, charts } = loadedTableData.config
        if (filters) {
            if ((insideReport && !assetIds) || !insideReport) {
                filters.asset_ids && setAssetIds(filters.asset_ids)
            }
            if ((insideReport && !startMonth) || !insideReport) {
                filters.startMonth && setStartMonth(filters.startMonth)
                filters.endMonth && setEndMonth(filters.endMonth)
            }
            filters.startQuarter && setStartQuarter(filters.startQuarter)
            filters.endQuarter && setEndQuarter(filters.endQuarter)
            filters.startYear && setStartYear(filters.startYear)
            filters.endYear && setEndYear(filters.endYear)
            filters.assetDetails && setAssetDetails(filters.assetDetails)
            filters.rpMetricIds && setMetricIds(filters.rpMetricIds)
            filters.groupBy && setGroupBy(filters.groupBy)
        }
        setTableSetting(loadedTableData.id, conditional_format_rules ? conditional_format_rules.rules : [])

        if (!tableMode) {
            setTableMode(charts?.isChartMode ? TableMode.Chart : TableMode.Table)
        }
    }, [assetIds, insideReport, loadedTableData, setAssetDetails, setAssetIds, setEndMonth, setEndQuarter, setEndYear, setGroupBy, setMetricIds, setStartMonth, setStartQuarter, setStartYear])

    const onTableSave = useCallback(async () => {
        const currentState = tableRef.current?.api?.getState() ?? {}
        const response = await saveReportingTable({
            tableMeta,
            currentState,
            isCreationMode,
            saveMutation,
            id,
            tableType: TableType.MetricSideBySide,
            config,
            assetType,
        })

        if (!response) {
            return
        }

        // redirect after saving
        if (isCreationMode) {
            navigate(getRoute(builderRouteConfigKey, { id: response.id }))
        } else {
            setTimeout(() => navigate(getRoute(tablesListRouteConfigKey)), 500)
        }
    }, [assetType, config, id, isCreationMode, navigate, saveMutation, tableMeta, tableRef])

    const excelExportParams = useMemo((): ExcelExportParams => {
        const periodAsString = getTimePeriodAsString(TimeSettings.SERIES, timeInterval, timePeriod)
        return ({
            fileName: `Metric - Side-by-Side. ${tableTitle}.xlsx`,
            sheetName: periodAsString,
            processCellCallback: processMetricTSTotalCells,
            freezeColumns: 'pinned',
            suppressRowOutline: true,
        })
    }, [tableTitle, timeInterval, timePeriod])

    const getContextMenuItems = useCallback(
        (params: GetContextMenuItemsParams): Array<string | MenuItemDef> => {
            const result: Array<string | MenuItemDef> = [
                'copy',
                'copyWithHeaders',
                'copyWithGroupHeaders',
                'separator',
                'export',
                'separator',
                {
                    name: 'Expand All Row Groups',
                    disabled: !groupBy,
                    action: () => params.api.expandAll(),
                },
                {
                    name: 'Collapse All Row Groups',
                    disabled: !groupBy,
                    action: () => params.api.collapseAll(),
                },
                {
                    name: 'Table Settings',
                    action: () => setShowTableSettingsModal(true),
                },
            ]
            return result
        }, [groupBy, showTotalRow])

    // destroy chart on unmount
    useEffect(() => () => chartRef.current?.destroyChart(), [])

    const onModelUpdated = useCallback(() => {
        if (!isLoading && isFetched && isSuccess) {
            onFirstDataRendered()

            // recreate chart because filters may have changed
            chartRef.current?.destroyChart()
            if (tableMode === TableMode.Chart) {
                chartRef.current = createMetricChart(tableRef.current?.api, chartContainerRef.current, (showTotalRow === '1' ? 1 : 0))
            }
        }
    }, [isLoading, isFetched, isSuccess, onFirstDataRendered, tableMode, showTotalRow])

    return (
        <>
            <Layout gap={16} flexGrow={1} direction='column'>
                {areFiltersSet
                    ? (
                        <>
                            {/* @ts-expect-error TODO: make AgGridTable generic to be able to pass theme */}
                            <AgGridTable
                                className={cn({ [chartStyles.hidden]: tableMode === TableMode.Chart })}
                                ref={tableRef}
                                items={rows}
                                {...gridOptions}
                                grandTotalRow='bottom'
                                columnDefs={columnDefs}
                                loading={isLoading}
                                initialState={gridStateRef.current}
                                onStateUpdated={(e) => {
                                    checkColumnOrderAndSaveGridStateToRef(e, gridStateRef)
                                    checkIfTableIsDirty()
                                }}
                                lazyModules={chartModules}
                                statusBar={statusBar}
                                defaultExcelExportParams={excelExportParams}
                                enableCharts={!groupBy}
                                getContextMenuItems={getContextMenuItems}
                                onGridReady={onGridReady}
                                onModelUpdated={onModelUpdated}
                                context={context.current}
                            />

                            {tableMode === TableMode.Chart && isLoading ? (
                                <Loader/>
                            ) : (
                                <div
                                    ref={chartContainerRef}
                                    className={cn(chartStyles.chartContainer, { [chartStyles.hidden]: tableMode === TableMode.Table })}
                                />
                            )}
                        </>
                    )
                    : (<NoDataMessage text='Choose required fields'/>)}
            </Layout>

            {showTableSettingsModal && (
                <CustomSettingsFilterModal
                    tableId={id}
                    tableRef={tableRef}
                    tableType={TableType.MetricSideBySide}
                    metricSideBySideColumns={columns}
                    onClose={() => setShowTableSettingsModal(false)}
                    onSave={() => setIsTableDirty(true)}
                />
            )}

            {buttonContainer && createPortal(
                <CancelSaveButtons
                    onCancel={onCancel}
                    onSave={onTableSave}
                    isLoading={saveMutation.isPending}
                    isDisabled={!isTableDirty || !tableMeta.name}
                />,
                buttonContainer,
            )}
        </>
    )
})
