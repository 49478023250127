import { getRequestQueries } from '@/api/utils/getRequestQueries'
import { ReportingDataValueType } from '@/constants/reportingDataValueTypes'
import { ReportScenario } from '@/constants/reportScenario'
import { TimeComparisonPeriod } from '@/constants/timeComparisonPeriods'

import { ReportingPnLRowType } from './reportingProfitAndLoss'

export type ReportingPnLVarianceValueEntry = Record<ReportScenario, string | number | undefined | null>

export type ReportingPnLVarianceRow = {
    name: string
    code?: string
    entity_id: string
    tree_path: string[]
    type: ReportingPnLRowType.TopMetric | ReportingPnLRowType.Category | ReportingPnLRowType.Account | ReportingPnLRowType.SubCategoryMetric | ReportingPnLRowType.CategoryMetric
    value_type: `${ReportingDataValueType}`
    values: Record<TimeComparisonPeriod, ReportingPnLVarianceValueEntry>
} | {
    name: string
    code?: string
    entity_id: string
    tree_path: string[]
    type: ReportingPnLRowType.AssetDetails | ReportingPnLRowType.ReportDetails | ReportingPnLRowType.Separator
    value_type: `${ReportingDataValueType}`
    values?: Record<TimeComparisonPeriod, string | number | undefined | null>
}

export interface ReportingPnLVarianceColumn {
    end_month: number
    id: string
    name: string
    start_month: number
    subheaders: string[]
    variance_name: string
}
export interface ReportingPnLVarianceItem {
    rows: ReportingPnLVarianceRow[]
    columns: ReportingPnLVarianceColumn[]
}

export const REPORTING_PROFIT_AND_LOSS_VARIANCE_URL = 'reporting/profit-and-loss-variance'

export const {
    useItemsQuery: useReportingProfitAndLossVarianceQuery,
} = getRequestQueries<ReportingPnLVarianceItem, Record<string, unknown>>({
    url: REPORTING_PROFIT_AND_LOSS_VARIANCE_URL,
    extractItemsFn: (data: ReportingPnLVarianceItem) => [data],
})
