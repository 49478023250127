import { getRequestQueries } from '@/api/utils/getRequestQueries'
import { ReportingDataValueType } from '@/constants/reportingDataValueTypes'

export enum ReportingPnLRowType {
    ReportDetails = 'report_details',
    AssetDetails = 'asset_details',
    TopMetric = 'top_metric',
    Category = 'category',
    Account = 'account',
    SubCategoryMetric = 'sub_category_metric',
    CategoryMetric = 'category_metric',
    Separator = 'separator',
}

export interface ReportingPnLRow {
    name: string
    code?: string
    entity_id: string
    tree_path: string[]
    type: `${ReportingPnLRowType}`
    value_type: `${ReportingDataValueType}`
    values: Record<string, string | number | undefined | null>
}

export interface ReportingPnLItem {
    columns: Array<{ id: string | number, name: string }>
    rows: ReportingPnLRow[]
}

export const REPORTING_PROFIT_AND_LOSS_URL = 'reporting/profit-and-loss'

export const {
    useItemsQuery: useReportingProfitAndLossQuery,
} = getRequestQueries<ReportingPnLItem, Record<string, unknown>>({
    url: REPORTING_PROFIT_AND_LOSS_URL,
    extractItemsFn: (data: ReportingPnLItem) => [data],
})
