import { getRequestQueries } from '@/api/utils/getRequestQueries'

export interface TrialBalanceTemplateBindingItem {
    asset_id: number
    id: number
    is_active: boolean
    template_id: number
}

export interface TrialBalanceTemplateItem {
    id?: number

    company_id: number
    ledger_id: string
    name: string
    bindings: TrialBalanceTemplateBindingItem[]
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FetchTrialBalanceTemplateQueryParams {}

export const {
    useItemsQuery: useTrialBalanceTemplateQuery,
    useItemUpdateMutation: useTrialBalanceTemplateUpdateMutation,
    useItemsCountQuery: useTrialBalanceTemplateCountQuery,
    useItemByIdQuery: useTrialBalanceTemplateByIdQuery,
} = getRequestQueries<TrialBalanceTemplateItem, FetchTrialBalanceTemplateQueryParams>({
    url: 'trial-balance-template',
})
