import { Button } from '@/components/base'
import { Layout } from '@/components/containers'

import { CancelSaveButtonsProps } from './CancelSaveButtons.types'

export const CancelSaveButtons = (props: CancelSaveButtonsProps) => {
    const { onCancel, onSave, isLoading = false, isDisabled = true } = props

    return (
        <Layout gap={8}>
            <Button
                text='Cancel'
                theme='secondary'
                onClick={onCancel}
                disabled={isLoading}
            />
            <Button
                text='Save'
                theme='primary'
                onClick={onSave}
                loading={isLoading}
                disabled={isDisabled}
            />
        </Layout>
    )
}
