import { generatePath, useNavigate } from 'react-router'

import { Button, Text } from '@/components/base'
import { Layout, PageLayout } from '@/components/containers'
import { OWNER_ROUTES_CONFIG } from '@/core/routes'
import { useMe } from '@/hooks/core/useMe'

import { ReportsHomeProps } from './ReportsHome.types'

import styles from './ReportsHome.module.scss'

export const ReportsHome = (props: ReportsHomeProps) => {
    const { context } = props

    const { me } = useMe()

    const navigate = useNavigate()

    return (
        <PageLayout className={styles.reportsHome}>
            <Layout align='flex-end' justify='flex-end' gap={16}>
                <Button
                    text='View all Tables'
                    theme='secondary'
                    onClick={() => {
                        const path = context === 'my' ? OWNER_ROUTES_CONFIG.REPORTS_CUSTOM_MY_TABLES.path : context === 'company' ? OWNER_ROUTES_CONFIG.REPORTS_CUSTOM_COMPANY_TABLES.path : undefined
                        path && navigate(path)
                    }}
                />
                <Button
                    icon='plus'
                    text='Build New Table'
                    theme='primary'
                    onClick={() => {
                        const path =
                            context === 'my'
                                ? OWNER_ROUTES_CONFIG.REPORTS_CUSTOM_MY_TABLE_BUILDER.path
                                : context === 'company'
                                    ? OWNER_ROUTES_CONFIG.REPORTS_CUSTOM_COMPANY_TABLE_BUILDER.path
                                    : undefined
                        path && navigate(generatePath(path, { id: 'new' }))
                    }}
                />
            </Layout>
            <Layout flexGrow={1} align='center' justify='center' direction='column'>
                <Layout direction='column' align='center' justify='center' gap={20}>
                    <Layout className={styles.reportsHomeImage}/>
                    <Layout gap={8} direction='column' align='center'>
                        <Text variant='header' text={`Welcome to Intelas Reporting, ${me.user.first_name}`}/>
                        <Text variant='bodyMedium' text='Select a report to begin or build your own'/>
                    </Layout>
                </Layout>
            </Layout>

        </PageLayout>
    )
}
