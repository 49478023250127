import { useState, useEffect } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { useQueryClient } from '@tanstack/react-query'

import { TRIAL_BALANCE_MAP_URL } from '@/api/trialBalance/trialBalanceMap'
import { TrialBalanceTemplateBindingItem } from '@/api/trialBalance/trialBalanceTemplate'
import http from '@/http.service'
import { AsyncTaskStatus } from '@/models/AsyncTaskModel'
import { TrialBalanceTemplateApplyingTask } from '@/models/trial_balance_template'
import { notify } from '@/utils/notify'
import { setIntervalUntilAuthenticated } from '@/utils/setInterval'

import { APPLY_TEMPLATE_TASK_ENDPOINT, SUCCESS_TEXT, ERROR_TEXT } from './ApplyAllTemplatesModal/ApplyAllTemplatesModal.constants'
import { TBTemplateApplyTaskGroupResponse } from './ApplyAllTemplatesModal/ApplyAllTemplatesModal.types'

interface UseApplyTemplatesProps {
    onClose?: () => void
    onDone?: (taskStatuses: TrialBalanceTemplateApplyingTask[]) => void
    requestAllFields?: boolean
    suppressSuccessMessage?: boolean
}

export const useApplyTemplates = ({ onClose, onDone, requestAllFields = false, suppressSuccessMessage = false }: UseApplyTemplatesProps) => {
    const { isAuthenticated } = useAuth0()
    const [isLoading, setIsLoading] = useState(false)
    const [taskGroupId, setTaskGroupId] = useState<string | null>(null)
    const [taskStatuses, setTaskStatuses] = useState<TrialBalanceTemplateApplyingTask[] | null>(null)

    const queryClient = useQueryClient()

    useEffect(() => {
        if (!taskGroupId) return
        return startPolling()
    }, [taskGroupId])

    useEffect(() => {
        checkTaskStatuses()
    }, [taskStatuses, taskGroupId])

    const applyTemplates = async (bindings: TrialBalanceTemplateBindingItem[]) => {
        setIsLoading(true)
        try {
            const { data } = await http.post!<TBTemplateApplyTaskGroupResponse>(
                `${APPLY_TEMPLATE_TASK_ENDPOINT}group/`,
                bindings.map(binding => (
                    new TrialBalanceTemplateApplyingTask({
                        binding_id: binding.id,
                        template_id: binding.template_id,
                        asset_id: binding.asset_id,
                        apply: true,
                        replace: true,
                        delete_excess: false,
                    })
                ).raw_data),
                { meta: { errorToast: ERROR_TEXT } },
            )

            setTaskGroupId(data.group_id)
        } catch {
            setIsLoading(false)
        }
    }

    const checkTaskStatuses = () => {
        if (!taskStatuses || !taskGroupId) { return }

        if (!taskStatuses?.length) {
            console.error('Applying tasks not found')
            return
        }

        const isAllDone = taskStatuses.every((task) => !task.inProgress)
        if (!isAllDone) { return }

        const isAnyFailed = taskStatuses.some(({ status }) => status === AsyncTaskStatus.FAILURE)
        if (isAnyFailed) {
            resetState()
            notify.error(ERROR_TEXT)
            onClose?.()
            onDone?.(taskStatuses)
            return
        }

        const isAllSuccess = taskStatuses.every(({ status }) => status === AsyncTaskStatus.SUCCESS)
        if (isAllSuccess) {
            resetState()
            queryClient.invalidateQueries({ queryKey: [TRIAL_BALANCE_MAP_URL] })
            onClose?.()
            onDone?.(taskStatuses)

            !suppressSuccessMessage && notify.success(SUCCESS_TEXT)
        }
    }

    const resetState = () => {
        setIsLoading(false)
        setTaskGroupId(null)
        setTaskStatuses(null)
    }

    const startPolling = () => {
        if (!taskGroupId) { return }

        const intervalId = setIntervalUntilAuthenticated(async () => {
            try {
                const { data } = await http.get!<TrialBalanceTemplateApplyingTask[]>(
                    `${APPLY_TEMPLATE_TASK_ENDPOINT}?${requestAllFields ? '' : '__fields&'}group_id=${taskGroupId}`,
                    { meta: { errorToast: ERROR_TEXT } },
                )
                setTaskStatuses(data)
            } catch {
                clearInterval(intervalId)
                resetState()
            }
        }, 2000, isAuthenticated)

        return () => clearInterval(intervalId)
    }

    return {
        isLoading,
        taskGroupId,
        taskStatuses,
        applyTemplates,
        checkTaskStatuses,
        startPolling,
    }
}
