import { useState, useEffect, useRef } from 'react'

import cn from 'classnames'

import { TabsProps } from './Tabs.types'

import styles from './Tabs.module.scss'

export const Tabs = (props: TabsProps) => {
    const { className, value, noTopPadding, options, px, onChange, noPadding, noBorderBottom } = props
    const ref = useRef<HTMLDivElement>(null)
    const [underlineStyle, setUnderlineStyle] = useState({
        width: 0,
        left: 0,
    })

    useEffect(() => {
        const el = ref.current?.querySelector<HTMLDivElement>('.' + styles.active)

        if (!el) return

        // hack for fixing the problem with positioning of underline
        for (let i = 2; i > 0; i--) {
            setTimeout(() => {
                if (underlineStyle.width !== el.clientWidth || underlineStyle.left !== el.offsetLeft) {
                    setUnderlineStyle({
                        width: el.clientWidth,
                        left: el.offsetLeft,
                    })
                }
            }, i * 100)
        }

        setUnderlineStyle({
            width: el.clientWidth,
            left: el.offsetLeft,
        })
    }, [value, options, ref])

    return (
        <div
            className={cn(styles.tabs, className, {
                [styles.noPadding]: noPadding,
                [styles.noBorderBottom]: noBorderBottom,
            })}
            ref={ref}
            style={{
                paddingRight: px,
                paddingLeft: px,
            }}
        >
            {
                Array.from(options.entries()).map(([key, label]) => (
                    <a
                        onClick={() => { onChange(key) }}
                        key={key}
                        className={cn(styles.tab, {
                            [styles.active]: value === key,
                            [styles.tab_noTopPadding]: noTopPadding,
                        })}
                    >{label}</a>
                ))
            }
            <div className={cn(styles.underline)} style={underlineStyle}> </div>
        </div>
    )
}
