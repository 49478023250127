import { useEffect, useState } from 'react'

import { SelectSelectedItemsBase } from '@/components/base/Select/selectedItems'
import { SelectTriggerBase } from '@/components/base/Select/triggers'
import { AssetSideModalInput } from '@/components/models/asset'
import { ASSET_SIDE_MODAL_INPUT_QUERY_PARAM } from '@/components/models/asset/AssetSideModalInput'
import { useInputState } from '@/hooks'
import { Asset } from '@/models/asset'

import { DEFAULT_WIDTH } from './AssetSelectWithSideModalInput.constants'
import { AssetSelectWithSideModalInputProps } from './AssetSelectWithSideModalInput.types'

const { name: assetParamName, type: assetParamType } = ASSET_SIDE_MODAL_INPUT_QUERY_PARAM

export const AssetSelectWithSideModalInput = (props: AssetSelectWithSideModalInputProps) => {
    const {
        label,
        width = DEFAULT_WIDTH,
        options = [],
        paramName = assetParamName,
        multiSelect,
        required,
        placeholder = multiSelect ? 'Select assets' : 'Select asset',
        multiSelectPlaceholder = 'Assets',
        labelInside = false,
        onClose,
        syncLocalStorage,
        defaultActiveOptions,
        inputParams = {},
    } = props

    const [assetIds] = useInputState({
        name: paramName,
        type: assetParamType,
        syncLocalStorage,
        ...inputParams,
    })
    const [isAssetSelectOpen, setIsAssetSelectOpen] = useState(false)
    const [selectedAssets, setSelectedAssets] = useState<Asset[]>([])

    useEffect(() => {
        if (!options.length) {
            setSelectedAssets([])
        } else {
            const selected = options.filter((asset) => (assetIds ?? []).includes(asset.id ?? null))
            setSelectedAssets(selected)
        }
    }, [options, assetIds])

    return (
        <>
            <SelectTriggerBase
                label={label}
                width={width}
                open={isAssetSelectOpen}
                isActive={isAssetSelectOpen}
                onClick={() => { setIsAssetSelectOpen(true) }}
                error={required && !selectedAssets.length}
                labelInside={labelInside}
            >
                <SelectSelectedItemsBase<Asset>
                    selected={selectedAssets}
                    labelFn={(option) => option.name ?? ''}
                    idFn={(option) => option.id?.toString() ?? ''}
                    placeholder={placeholder}
                    multiSelectPlaceholder={multiSelectPlaceholder}
                    active={isAssetSelectOpen}
                />
            </SelectTriggerBase>

            <AssetSideModalInput
                options={options}
                onClose={() => {
                    setIsAssetSelectOpen(false)
                    onClose?.()
                }}
                open={isAssetSelectOpen}
                multiSelect={multiSelect}
                paramName={paramName}
                defaultActiveOptions={defaultActiveOptions}
                required={required}
                syncLocalStorage={syncLocalStorage}
                inputParams={inputParams}
            />
        </>
    )
}
