import { usePackageByIdQuery } from '@/api/package/package'
import { MONTH_INPUT_QUERY_PARAM } from '@/components/baseInputs'
import { ASSET_SIDE_MODAL_INPUT_QUERY_PARAM } from '@/components/models/asset'
import {
    GLOBAL_REPORT_MONTH_QUERY_PARAM,
    GLOBAL_REPORT_MULTY_ASSET_QUERY_PARAM,
} from '@/components/widgets/reports/reports.constants'
import { useInputState } from '@/hooks'
import {
    concatPackageId,
    getPackageInsightDocumentId,
    getPackageNoteDocumentId,
} from '@/pages/MainPage/CommonPages/PackagesPage/PackagesPage.utils'
import {
    getReportTableTbLedgerInputQueryParam,
    TABLE_TB_LEDGER_INPUT_QUERY_PARAM,
} from '@/pages/TablesPage/TableBuilderPage/TableBuilderPage.constants'

export const usePnlReportNotesDocumentId = (insideReport = false, tableId?: number | string | undefined) => {
    const [assetIds] = useInputState(insideReport ? GLOBAL_REPORT_MULTY_ASSET_QUERY_PARAM : ASSET_SIDE_MODAL_INPUT_QUERY_PARAM)
    const [month] = useInputState(insideReport ? GLOBAL_REPORT_MONTH_QUERY_PARAM : MONTH_INPUT_QUERY_PARAM)
    const [ledgerIdParam] = useInputState((insideReport && tableId) ? getReportTableTbLedgerInputQueryParam(tableId) : TABLE_TB_LEDGER_INPUT_QUERY_PARAM)

    const packageId = concatPackageId(month, assetIds?.[0])

    const packageQuery = usePackageByIdQuery(packageId, {
        enabled: Boolean(packageId?.length),
    })
    const documentId = getPackageNoteDocumentId(packageQuery.data?.asset_comments_id, packageId, ledgerIdParam)

    return documentId
}

export const usePnlReportInsightsDocumentId = (insideReport = false) => {
    const [assetIds] = useInputState(insideReport ? GLOBAL_REPORT_MULTY_ASSET_QUERY_PARAM : ASSET_SIDE_MODAL_INPUT_QUERY_PARAM)
    const [month] = useInputState(insideReport ? GLOBAL_REPORT_MONTH_QUERY_PARAM : MONTH_INPUT_QUERY_PARAM)

    const packageId = concatPackageId(month, assetIds?.[0])

    const packageQuery = usePackageByIdQuery(packageId, {
        enabled: Boolean(packageId?.length),
    })

    if (!packageQuery.data?.asset_comments_id) return null

    const documentId = getPackageInsightDocumentId(packageQuery.data.asset_comments_id, packageId)

    return documentId
}
