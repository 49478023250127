import { useEffect, useState } from 'react'

import { observer } from 'mobx-react-lite'

import { BuiltInSearchInput, LegacySelectItem, LegacySelectItemData, LegacyPopover, Text } from '@/components/base'

import { LegacySelectPopoverProps } from './LegacySelectPopover.types' // Height of 10 items

import styles from './LegacySelectPopover.module.scss'

const MAX_HEIGHT = 480

export const LegacySelectPopover = observer((props: LegacySelectPopoverProps) => {
    const {
        onClose,
        onChange,
        onMultiChange,
        anchorEl,
        options,
        customValue,
        multiSelectCustomValues = [],
        testId = 'select',
        noItemsPlaceholder = 'No items found',
        selectAllPlaceholder = 'All',
        searchPlaceholder = 'Search',
        multiSelect = false,
        selectAll = false,
        unselectOnClick = false,
        search,
        sorted = false,
        headerComponent = null,
        selectAllItem = {
            value: 'all',
            label: selectAllPlaceholder,
        },
        anchorOrigin,
        transformOrigin,
    } = props

    const selectAllInner = multiSelect && selectAll
    const open = Boolean(anchorEl)
    const [selectedItems, setSelectedItems] = useState<LegacySelectItemData[]>([])
    const [filterValue, setFilterValue] = useState(null)
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    const filterRegex = new RegExp(filterValue, 'i')

    const getFilteredOptions = () => {
        const fullOptions = options.slice()
        if (sorted) {
            fullOptions.sort((a, b) => a.label.toString().localeCompare(b.label.toString()))
        }
        if (selectAllInner) {
            fullOptions.unshift(selectAllItem)
        }
        return fullOptions
    }

    const filteredOptions = getFilteredOptions()
        .filter((item) => !filterValue || filterRegex.test(item.label.toString()))

    useEffect(() => {
        const customValues = new Set([...multiSelectCustomValues, customValue])
        const selectedOptions = options.filter((option) => {
            return customValues.has(option.value)
        })

        if (selectAllInner && selectedOptions.length === options.length) {
            selectedOptions.unshift(selectAllItem)
        }
        setSelectedItems(selectedOptions)

        // Hack. multiSelectCustomValues turned to string to prevent infinite loop because of array links changed
    }, [customValue, options, multiSelectCustomValues.join(',')])

    return (
        <LegacyPopover
            maxHeight={MAX_HEIGHT}
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
        >
            {headerComponent}

            {search && (
                <div className={styles.searchInput}>
                    <BuiltInSearchInput
                        // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                        onChange={(value) => { setFilterValue(value) }}
                        value={filterValue}
                        placeholder={searchPlaceholder}
                        dataTestId='search'
                    />
                </div>
            )}

            {!filteredOptions.length && (
                <Text
                    text={noItemsPlaceholder}
                    color='colorsSecondaryGrey600'
                    variant='smallTextMediumDefault'
                    className={styles.noItemsPlaceholder}
                    block
                    whiteSpace='nowrap'
                    alignCenter
                    dataTestId='no-items-placeholder'
                />
            )}

            {filteredOptions
                .map((option, i) => (
                    <LegacySelectItem
                        key={i}
                        data={option}
                        onClick={() => {
                            let newSelectedItems: LegacySelectItemData[] = []
                            if ((unselectOnClick || multiSelect) && selectedItems.find(item => item.value === option.value)) {
                                newSelectedItems = (option.value !== 'all') ? selectedItems.filter((item) => item.value !== option.value && item.value !== 'all') : []
                                setSelectedItems(newSelectedItems)
                                onChange?.({
                                    // @ts-expect-error TS(2322) FIXME: Type 'null' is not assignable to type 'string | nu... Remove this comment to see the full error message
                                    value: null,
                                    // @ts-expect-error TS(2322) FIXME: Type 'null' is not assignable to type 'string | nu... Remove this comment to see the full error message
                                    label: null,
                                })
                            } else {
                                if (!multiSelect) {
                                    newSelectedItems = [option]
                                    onChange?.(option)
                                } else {
                                    newSelectedItems = (option.value !== 'all') ? [...selectedItems, option] : filteredOptions
                                }
                                setSelectedItems(newSelectedItems)
                            }
                            if (!multiSelect) {
                                onClose?.()
                            } else {
                                onMultiChange?.(newSelectedItems)
                            }
                        }}
                        active={Boolean(selectedItems.find(item => item.value === option.value))}
                        dataTestId={`${testId}-item`}
                    />
                ))}
        </LegacyPopover>
    )
})
