import { CellClassParams, RowHeightParams, ValueGetterParams } from '@ag-grid-community/core'

import { AgGridTableProps } from '@/components/tables'
import { FEB25_COL_TYPES } from '@/components/tables/AgGridTable/themes/feb25/feb25.constants'
import { Feb25Classes, Feb25ColDef } from '@/components/tables/AgGridTable/themes/feb25/feb25.types'
import { ExcelClasses, getExcelGroupClasses, excelStyles } from '@/constants/reportTable'
import { Feb25ChartTheme } from '@/pages/TablesPage/TableBuilderPage/TableBuilderTables/TableChart'

import {
    getAggFuncWithIntermediateCalcs, getWeightedAvgAggregation,
    grandTotalAverageRenderer,
    refreshAggregationAfterFiltering,
} from '../TableBuilderTables.utils'

export const getLeaseExpirationColDefs = (): Feb25ColDef[] => {
    const colDefs: Feb25ColDef[] = [
        {
            headerName: 'Year',
            field: 'year',
            headerTooltip: 'Year',
            minWidth: 70,
            headerClass: [Feb25Classes.HeaderL2],
            aggFunc: grandTotalAverageRenderer,
            cellClass: (params: CellClassParams) => [Feb25Classes.HighlightTotalCell, ExcelClasses.Number, ...getExcelGroupClasses(params)],
        },
        {
            headerName: '# Leases Expiring',
            field: 'leases',
            headerTooltip: '# Leases Expiring',
            minWidth: 150,
            headerClass: [Feb25Classes.HeaderL2, Feb25Classes.RightAlignedHeader],
            cellClass: (params: CellClassParams) => [Feb25Classes.HighlightTotalCell, Feb25Classes.RightAlignedCell, ExcelClasses.Number, ...getExcelGroupClasses(params)],
            type: 'money',
            aggFunc: getAggFuncWithIntermediateCalcs('sum'),
            valueGetter: (params: ValueGetterParams) => params.data?.leases ?? 0,
        },
        {
            headerName: 'SF Expiring',
            field: 'sf',
            headerTooltip: 'SF Expiring',
            minWidth: 150,
            headerClass: [Feb25Classes.HeaderL2, Feb25Classes.RightAlignedHeader],
            cellClass: (params: CellClassParams) => [Feb25Classes.HighlightTotalCell, Feb25Classes.RightAlignedCell, ExcelClasses.Number, ...getExcelGroupClasses(params)],
            type: 'money',
            aggFunc: getAggFuncWithIntermediateCalcs('sum'),
            valueGetter: (params: ValueGetterParams) => params.data?.sf ?? 0,
        },
        {
            headerName: '% of Total SF Expiring',
            field: 'sf_percentage',
            headerTooltip: '% of Total SF Expiring',
            minWidth: 180,
            headerClass: [Feb25Classes.HeaderL2, Feb25Classes.RightAlignedHeader],
            cellClass: (params: CellClassParams) => [Feb25Classes.HighlightTotalCell, Feb25Classes.RightAlignedCell, ExcelClasses.Percentage1Decimal, ...getExcelGroupClasses(params)],
            type: 'percentageFraction',
            aggFunc: getAggFuncWithIntermediateCalcs('sum'),
            valueGetter: (params: ValueGetterParams) => params.data?.sf_percentage ?? 0,
        },
        {
            headerName: 'Expiring Rent Annual',
            field: 'rent_annual',
            headerTooltip: 'Expiring Rent Annual',
            minWidth: 180,
            headerClass: [Feb25Classes.HeaderL2, Feb25Classes.RightAlignedHeader],
            cellClass: (params: CellClassParams) => [Feb25Classes.HighlightTotalCell, Feb25Classes.RightAlignedCell, ExcelClasses.Currency, ...getExcelGroupClasses(params)],
            type: 'money',
            aggFunc: getAggFuncWithIntermediateCalcs('sum'),
            valueGetter: (params: ValueGetterParams) => params.data?.rent_annual ?? 0,
        },
        {
            headerName: 'Expiring Rent PSF',
            field: 'rent_psf',
            headerTooltip: 'Expiring Rent PSF',
            minWidth: 150,
            headerClass: [Feb25Classes.HeaderL2, Feb25Classes.RightAlignedHeader],
            cellClass: (params: CellClassParams) => [Feb25Classes.HighlightTotalCell, Feb25Classes.RightAlignedCell, ExcelClasses.Currency, ...getExcelGroupClasses(params)],
            type: 'money',
            aggFunc: getWeightedAvgAggregation('sf'),
            valueGetter: (params: ValueGetterParams) => params.data?.rent_psf ?? 0,
        },
        {
            headerClass: [Feb25Classes.HeaderL2, Feb25Classes.RightAlignedHeader],
            headerName: '% of Annual Rent',
            field: 'rent_annual_percentage',
            headerTooltip: '% of Annual Rent',
            minWidth: 150,
            cellClass: (params: CellClassParams) => [Feb25Classes.HighlightTotalCell, Feb25Classes.RightAlignedCell, ExcelClasses.Percentage1Decimal, ...getExcelGroupClasses(params)],
            type: 'percentageFraction',
            aggFunc: getAggFuncWithIntermediateCalcs('sum'),
            valueGetter: (params: ValueGetterParams) => params.data?.rent_annual_percentage ?? 0,
        },
    ]

    return colDefs
}

export const leaseExpirationTableGridOptions: Omit<AgGridTableProps<'feb25'>, 'items' | 'columnDefs'> = {
    customChartThemes: { Feb25ChartTheme },
    chartThemes: ['Feb25ChartTheme'],
    defaultColDef: {
        suppressMovable: true,
        lockPinned: true,
        sortable: true,
        unSortIcon: false,
        suppressMenu: true,
        suppressHeaderMenuButton: true,
        filterParams: {
            cellHeight: 18,
        },
    },
    suppressFitToWidth: true,
    suppressAutoResize: true,
    suppressRowDrag: true,
    suppressMovableColumns: false,
    groupDefaultExpanded: 1,
    groupDisplayType: 'custom',
    suppressDragLeaveHidesColumns: true,
    groupTotalRow: 'bottom',
    grandTotalRow: 'bottom',
    suppressAggFuncInHeader: true,
    columnTypes: FEB25_COL_TYPES,
    theme: 'feb25',
    getRowHeight: (params: RowHeightParams) => {
        // grand total row height must be greater than default to separate it from other rows
        return (params.node.footer && params.node.level === -1) ? 30 : undefined
    },
    cellSelection: true,
    popupParent: document.body, // chart container
    excelStyles,
    onFilterChanged: refreshAggregationAfterFiltering,
}
