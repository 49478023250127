import { TableType } from './tableType.types'

export const TABLE_TYPE_LABELS = new Map<TableType, string>([
    [TableType.PortfolioOverview, 'P&L - Custom Rows'],
    [TableType.PortfolioRentRoll, 'Rent Roll - Detail'],
    [TableType.PortfolioSelectedValue, 'Portfolio - Asset Rows'],
    [TableType.MetricSideBySide, 'Metric - Side-by-Side'],
    [TableType.MetricTimeSeries, 'Metric - Time Series'],
    [TableType.PnLTimeSeries, 'P&L - Time Series'],
    [TableType.PnLVarianceAnalysis, 'P&L - Variance Analysis'],
    [TableType.RRTradeOutBasic, 'Rent Roll - Metrics (Multifamily)'],
    [TableType.RRMetricsCommercial, 'Rent Roll - Metrics (Commercial)'],
    [TableType.BSTimeSeries, 'Balance Sheet - Time Series'],
    [TableType.LeaseExpiration, 'Rent Roll - Lease Expiration (Commercial)'],
    [TableType.TrialBalance, 'Trial Balance'],
    [TableType.DebtData, 'Debt Data'],
])

export interface TableTypeConfiDescription {
    text: string
    isBold: boolean
}

export interface TableTypeConfig {
    description: TableTypeConfiDescription[]
    title: string
    img: string
    hide?: boolean
}

const TABLES_FOLDER = '/tableImages/'

export const TABLE_TYPE_CONFIG: Record<TableType, TableTypeConfig> =
    {
        [TableType.MetricSideBySide]: {
            description: [
                {
                    text: 'Compare multiple assets and metrics',
                    isBold: true,
                },
                {
                    text: ' side by side with actuals, budgets, per unit/SF, and other scenarios.',
                    isBold: false,
                },
            ],
            title: TABLE_TYPE_LABELS.get(TableType.MetricSideBySide) as string,
            img: TABLES_FOLDER + 'MetricSideBySide.png',
        },
        [TableType.MetricTimeSeries]: {
            description: [
                {
                    text: 'Track a single metric over time',
                    isBold: true,
                },
                {
                    text: ' for multiple assets with customizable monthly/quarterly intervals and scenario options.',
                    isBold: false,
                },
            ],
            title: TABLE_TYPE_LABELS.get(TableType.MetricTimeSeries) as string,
            img: TABLES_FOLDER + 'MetricTimeSeries.png',
        },
        [TableType.PnLTimeSeries]: {
            description: [
                {
                    text: 'View P&L over time',
                    isBold: true,
                },
                {
                    text: ' with monthly/quarterly intervals, NOI Margin, and scenario options (actuals, budgets, per unit/SF).',
                    isBold: false,
                },
            ],
            title: TABLE_TYPE_LABELS.get(TableType.PnLTimeSeries) as string,
            img: TABLES_FOLDER + 'PnLTimeSeries.png',
        },
        [TableType.PnLVarianceAnalysis]: {
            description: [
                {
                    text: 'Analyze P&L variances',
                    isBold: true,
                },
                {
                    text: ' across actuals, budgets, prior year, MTD, YTD, and more.',
                    isBold: false,
                },
            ],
            title: TABLE_TYPE_LABELS.get(TableType.PnLVarianceAnalysis) as string,
            img: TABLES_FOLDER + 'PnLVarianceAnalysis.png',
        },
        [TableType.PortfolioOverview]: {
            description: [
                {
                    text: 'Customize a P&L view',
                    isBold: true,
                },
                {
                    text: ' by selecting specific categories to display. Typically used for export purposes.',
                    isBold: false,
                },
            ],
            title: TABLE_TYPE_LABELS.get(TableType.PortfolioOverview) as string,
            img: TABLES_FOLDER + 'PortfolioOverview.png',
        },
        [TableType.PortfolioRentRoll]: {
            description: [
                {
                    text: 'View rent roll details',
                    isBold: true,
                },
                {
                    text: ' for any asset with selectable columns, including tenant name, floorplan, rent, key dates, and more.',
                    isBold: false,
                },
            ],
            title: TABLE_TYPE_LABELS.get(TableType.PortfolioRentRoll) as string,
            img: TABLES_FOLDER + 'PortfolioRentRoll.png',
        },
        [TableType.RRTradeOutBasic]: {
            description: [
                {
                    text: 'Compare leasing performance',
                    isBold: true,
                },
                {
                    text: ' with key metrics like new leases, trade-out $ & %, occupancy, and average rents.',
                    isBold: false,
                },
            ],
            title: TABLE_TYPE_LABELS.get(TableType.RRTradeOutBasic) as string,
            img: TABLES_FOLDER + 'RRTradeOutBasic.png',
        },
        [TableType.RRMetricsCommercial]: {
            description: [
                {
                    text: 'Choose multiple assets',
                    isBold: true,
                },
                {
                    text: ' to be displayed on the y-axis (left side) with occupancy metrics across the x-axis (top).',
                    isBold: false,
                },
            ],
            title: TABLE_TYPE_LABELS.get(TableType.RRMetricsCommercial) as string,
            img: TABLES_FOLDER + 'RRTradeOutBasic.png',
        },
        [TableType.DebtData]: {
            description: [
                {
                    text: 'Track loan details',
                    isBold: true,
                },
                {
                    text: ' with key metrics like outstanding debt, DSCR, Cap details and more.',
                    isBold: false,
                },
            ],
            title: TABLE_TYPE_LABELS.get(TableType.DebtData) as string,
            img: TABLES_FOLDER + 'DebtData.png',
        },
        [TableType.LeaseExpiration]: {
            description: [
                {
                    text: 'Compare expiring leases',
                    isBold: false,
                },
                {
                    text: ' with key metrics like leases expiring, expiring annual rent, and PSF metrics.',
                    isBold: true,
                },
            ],
            title: TABLE_TYPE_LABELS.get(TableType.LeaseExpiration) as string,
            img: TABLES_FOLDER + 'LeaseExpiration.png',
        },
        [TableType.BSTimeSeries]: {
            description: [
                {
                    text: 'Track balance sheet trends',
                    isBold: true,
                },
                {
                    text: ' over time by category and account, with options for intervals and views.',
                    isBold: false,
                },
            ],
            title: TABLE_TYPE_LABELS.get(TableType.BSTimeSeries) as string,
            img: TABLES_FOLDER + 'BSTimeSeries.png',
        },
        [TableType.PortfolioSelectedValue]: {
            description: [
                {
                    text: 'Choose multiple assets to be displayed on the y-axis (left side) with the ability to view a single metric across various scenario on the x-axis (top) including actual to budget, actual to prior year, MTD, YTD, and more. This table is typically used for export purposes only.',
                    isBold: false,
                },
            ],
            title: TABLE_TYPE_LABELS.get(TableType.PortfolioSelectedValue) as string,
            img: TABLES_FOLDER + 'PortfolioSelectedValue.png',
            hide: true,
        },
        [TableType.TrialBalance]: {
            description: [
                {
                    text: 'Track account balances with',
                    isBold: true,
                },
                {
                    text: ' metrics like balances, debits, credits, and more for multiple assets and time periods.',
                    isBold: false,
                },
            ],
            title: TABLE_TYPE_LABELS.get(TableType.TrialBalance) as string,
            img: TABLES_FOLDER + 'TrialBalance.png',
        },
    }
