import { ColDef, ValueFormatterParams } from '@ag-grid-community/core'

import { STRING_COMPARATOR } from '@/components/tables/AgGridTable'

export const NEO_COL_TYPES: Record<string, ColDef> = {
    string: {
        cellStyle: {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
        },
        valueFormatter: (params: ValueFormatterParams): string => {
            const { value, node } = params
            const isOpenGroup = node?.group && node?.expanded

            if (!value && (isOpenGroup || node?.footer)) {
                return ''
            }
            return params.value ?? '—'
        },
        tooltipValueGetter: ({ valueFormatted }) => valueFormatted && valueFormatted !== '—' ? valueFormatted : null,
        comparator: STRING_COMPARATOR,
    },
}
