import { TrialBalanceMapItem } from '@/api/trialBalance/trialBalanceMap'
import { Account as TrialBalanceAccount } from '@/models/ledger'
import { Account as RentRollAccount, RentRollMap } from '@/models/rent_roll'

type Account = RentRollAccount | TrialBalanceAccount

export interface MappingEngineSideModalProps {
    onChange: (option: Account | undefined | null, update: boolean, autoApply?: boolean) => void
    accounts: Account[]
    selectedAccount?: Account
    onClose: () => void
    haveMapping?: boolean
    // @ts-expect-error Need to create universal type for account
    sourceAccountData?: Pick<Account, 'name' | 'code' | 'id'>
    accountType: 'rentRoll' | 'trialBalance'
    customLedgerId?: number | null
    /**
     * Button for updating templates will be shown based on request results
     */
    syncTemplates: boolean
    rowData: TrialBalanceMapItem | RentRollMap
}

export enum MappingEngineMode {
    oneCol = 'oneCol',
    twoCols = 'twoCols',
    threeCols = 'threeCols',
}
