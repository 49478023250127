import { SideModalContainer } from '@/components/containers'

import { INITIAL_VERTICAL_OFFSET, INITIAL_UNDERLAYER_WIDTH, INITIAL_Z_INDEX, DEFAULT_MODAL_WIDTH } from './SideModalMulti.constants'
import { SideModalMultiProps } from './SideModalMulti.types'

import styles from './SideModalMulti.module.scss'

export const SideModalMulti = (props: SideModalMultiProps) => {
    const {
        width = DEFAULT_MODAL_WIDTH,
        children,
        level = 0,
        onBgClick = () => undefined,
    } = props

    return (
        <SideModalContainer
            onBgClick={onBgClick}
            dropShadow
        >
            <div className={styles.wrapper} style={{ width }}>
                <div
                    className={styles.gradientLayer}
                    style={{ zIndex: INITIAL_Z_INDEX }}
                >
                    <div className={styles.content}>
                        {children}
                    </div>
                </div>

                {level > 0 && Array(level).fill('').map((_, index) => (
                    <div
                        key={index}
                        className={styles.underLayer}
                        style={{
                            top: (index + 1) * INITIAL_VERTICAL_OFFSET,
                            bottom: (index + 1) * INITIAL_VERTICAL_OFFSET,
                            left: -((index + 1) * INITIAL_UNDERLAYER_WIDTH),
                            zIndex: INITIAL_Z_INDEX - (index + 1),
                        }}
                    />
                ))}
            </div>
        </SideModalContainer>
    )
}
