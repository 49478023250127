import { useLocation, matchPath } from 'react-router'

import { COMMON_ROUTES_CONFIG, RouterConfigKeys } from '@/core/routes'
import { getRoutesConfig } from '@/core/routes/routes.utils'
import { useMe } from '@/hooks/core/useMe'

export const useRouteKey = () => {
    const { me } = useMe()
    const location = useLocation()
    const filteredCommonRoutesEntries = Object.entries(COMMON_ROUTES_CONFIG)

    const routeConfigs = [...Object.entries({ ...getRoutesConfig(me) }), ...filteredCommonRoutesEntries]

    const routeEntry = routeConfigs
    // Filter out non-route config entries as they break storybook tests.
        .filter(([key]) => !['displayName', '__docgenInfo'].includes(key))
        .find(([_, config]) => matchPath(
            {
                path: config.path,
                end: true,
            },
            location.pathname),
        )

    return routeEntry?.[0] as RouterConfigKeys | undefined
}
