export enum TableType {
    PortfolioOverview = 'po', // Portfolio Income Statement - Asset Columns (table 1.1)
    PortfolioRentRoll = 'prr', // Portfolio Rent Roll - Asset Rows (rent roll details)
    PortfolioSelectedValue = 'psv', // Portfolio - Asset Rows (table 1.2)
    MetricSideBySide = 'msbs', // Metric - Side-by-Side
    MetricTimeSeries = 'mts', // Metric - Time Series
    PnLTimeSeries = 'pnlts', // P&L - Time Series
    PnLVarianceAnalysis = 'pnlva', // P&L - Variance Analysis
    RRTradeOutBasic = 'rrtob', // Rent Roll - Trade Out Basic aka Rent Roll - Metrics (Multifamily)
    RRMetricsCommercial = 'rrmc', // Rent Roll - Metrics (Commercial)
    BSTimeSeries = 'bsts', // Balance Sheet - Time Series
    LeaseExpiration = 'le', // Office (Commercial) - Lease Expiration
    TrialBalance = 'tb', // Trial Balance
    DebtData = 'dd', // Debt Data
}

export enum TableDataType {
    AssetDetails = 'asset_details', // 1 field
    RentRollMetric = 'rent_roll_metric', // 1 field
    TrialBalanceMetric = 'trial_balance_metric', // 4 fields: actual, budget, variance, variance_percent
    TrialBalanceCategory = 'trial_balance_category', // 4 fields: actual, budget, variance, variance_percent
    Spacer = 'spacer', // 0 fields
}

export enum TableMode {
    Table = 'table',
    Chart = 'chart',
}
