import { GridState, IAggFunc, IAggFuncParams } from '@ag-grid-community/core'

import { ReportingDebtRow } from '@/api/reportingData/reportingDebt'
import { AgGridTableProps } from '@/components/tables'
import { FEB25_COL_TYPES } from '@/components/tables/AgGridTable/themes/feb25/feb25.constants'
import { Feb25Classes, Feb25ColDef, Feb25ColGroupDef } from '@/components/tables/AgGridTable/themes/feb25/feb25.types'
import { excelStyles } from '@/constants/reportTable'
import { formatMonthFull } from '@/utils/date/formatDate'
import { monthIndToDate } from '@/utils/date/monthInd'

const totalRenderer = (params: IAggFuncParams) => {
    const visibleColumns = params.api.getAllDisplayedColumns()
    const isFirstVisible = visibleColumns.length > 0 && visibleColumns[0] === params.column
    return isFirstVisible ? 'Total/Average' : ''
}

const cols: Array<{
    colId: Partial<keyof ReportingDebtRow>
    colName: string
    width: number
    type: string
    fractionDigits?: number
    aggFunc?: string | IAggFunc
}> = [
    {
        colId: 'asset_name',
        colName: 'Asset Name',
        width: 225,
        type: 'string',
        aggFunc: totalRenderer,
    },
    {
        colId: 'city',
        colName: 'City',
        width: 124,
        type: 'string',
    },
    {
        colId: 'state',
        colName: 'State',
        width: 124,
        type: 'string',
    },
    {
        colId: 'asset_class',
        colName: 'Asset Class',
        width: 124,
        type: 'assetClass',
        aggFunc: () => ' ',
    },
    {
        colId: 'manager',
        colName: 'Manager',
        width: 124,
        type: 'string',
    },
    {
        colId: 'fund',
        colName: 'Fund',
        width: 124,
        type: 'string',
    },
    {
        colId: 'units',
        colName: 'Units',
        width: 124,
        type: 'money',
        aggFunc: 'sum',
    },
    {
        colId: 'square_feet',
        colName: 'Square Feet',
        width: 124,
        type: 'money',
        aggFunc: 'sum',
    },
    {
        colId: 'intelas_property_id',
        colName: 'Intelas Property ID',
        width: 160,
        type: 'string',
    },
    {
        colId: 'as_of_date',
        colName: 'As of Date (Month)',
        width: 160,
        type: 'date',
    },
    {
        colId: 'loan_order',
        colName: 'Loan Order',
        width: 160,
        type: 'number',
    },
    {
        colId: 'lender_name',
        colName: 'Lender Name',
        width: 160,
        type: 'string',
    },
    {
        colId: 'close_date',
        colName: 'Close Date',
        width: 160,
        type: 'date',
    },
    {
        colId: 'initially_funded_amount',
        colName: 'Initially Funded Amount',
        width: 160,
        type: 'money',
        aggFunc: 'sum',
    },
    {
        colId: 'future_funding_commitment',
        colName: 'Future Funding Commitment',
        width: 160,
        type: 'money',
        aggFunc: 'sum',
    },
    {
        colId: 'rate_type',
        colName: 'Rate Type',
        width: 160,
        type: 'string',
    },
    {
        colId: 'rate_benchmark',
        colName: 'Rate Benchmark',
        width: 160,
        type: 'string',
    },
    {
        colId: 'benchmark_floor',
        colName: 'Benchmark Floor',
        width: 160,
        type: 'number',
        fractionDigits: 2,
        aggFunc: 'avg',
    },
    {
        colId: 'base_rate',
        colName: 'Base rate',
        width: 160,
        type: 'number',
        fractionDigits: 2,
        aggFunc: 'avg',
    },
    {
        colId: 'spread',
        colName: 'Spread',
        width: 160,
        type: 'number',
        fractionDigits: 3,
        aggFunc: 'avg',
    },
    {
        colId: 'cap_level',
        colName: 'Cap Level',
        width: 160,
        type: 'number',
        fractionDigits: 2,
        aggFunc: 'avg',
    },
    {
        colId: 'cap_expiration_date',
        colName: 'Cap Expiration Date',
        width: 160,
        type: 'date',
    },
    {
        colId: 'cap_notes',
        colName: 'Cap Notes',
        width: 160,
        type: 'string',
    },
    {
        colId: 'initial_maturity',
        colName: 'Intial Maturity',
        width: 160,
        type: 'date',
    },
    {
        colId: 'fully_extended_maturity',
        colName: 'Fully Extended Maturity',
        width: 160,
        type: 'date',
    },
    {
        colId: 'extension_fee',
        colName: 'Extension Fee ',
        width: 160,
        type: 'number',
        fractionDigits: 2,
        aggFunc: 'avg',
    },
    {
        colId: 'extension_description',
        colName: 'Extension Description',
        width: 160,
        type: 'string',
    },
    {
        colId: 'amortization_start',
        colName: 'Amortization Start',
        width: 160,
        type: 'date',
    },
    {
        colId: 'open_prepayment_date',
        colName: 'Open Prepayment Date',
        width: 160,
        type: 'date',
    },
    {
        colId: 'prepayment_fees_description',
        colName: 'Prepayment Fees Description',
        width: 160,
        type: 'string',
    },
    {
        colId: 'dscr_covenant',
        colName: 'DSCR Covenant',
        width: 160,
        type: 'number',
        fractionDigits: 2,
        aggFunc: 'avg',
    },
    {
        colId: 'debt_yield_covenant',
        colName: 'Debt Yield Covenant',
        width: 160,
        type: 'number',
        fractionDigits: 2,
        aggFunc: 'avg',
    },
    {
        colId: 'covenants_description',
        colName: 'Covenants Description',
        width: 160,
        type: 'string',
    },
    {
        colId: 'guaranty_description',
        colName: 'Guaranty Description',
        width: 160,
        type: 'string',
    },
    {
        colId: 'notes_general',
        colName: 'Notes General',
        width: 160,
        type: 'string',
    },
]

export const dDDefaultColSizingModel: GridState['columnSizing'] = {
    columnSizingModel: cols.map(({ colId, width }) => ({
        colId,
        width,
    })),
}

export const dDTableGridOptions: Omit<AgGridTableProps<'feb25'>, 'items' | 'columnDefs'> = {
    theme: 'feb25',
    columnTypes: FEB25_COL_TYPES,
    defaultColDef: {
        wrapHeaderText: true,
        autoHeaderHeight: true,
        suppressMovable: false,
        unSortIcon: false,
    },
    getRowId: ({ data }: { data: ReportingDebtRow }) => JSON.stringify(data),
    suppressCellFocus: true,
    suppressFitToWidth: true,
    suppressAutoResize: true,
    suppressMovableColumns: true,
    maintainColumnOrder: true,
    suppressAggFuncInHeader: true,
    excelStyles,
    grandTotalRow: 'bottom',
}

export const getDDTableColDefs = (month?: number): Array<Feb25ColGroupDef | Feb25ColDef> => {
    if (!month) { return [] }
    const monthString = formatMonthFull(monthIndToDate(month))

    const colDefs = cols.map((col) => {
        const isRightAligned = ['money', 'number'].includes(col.type)
        return ({
            headerName: col.colName,
            field: col.colId,
            headerClass: [Feb25Classes.HeaderL2, isRightAligned ? Feb25Classes.RightAlignedHeader : ''],
            cellClass: [isRightAligned ? Feb25Classes.RightAlignedCell : '', Feb25Classes.HighlightTotalCell],
            type: col.type,
            fractionDigits: col.fractionDigits,
            aggFunc: col.aggFunc,
        })
    })

    return [
        {
            headerName: `As of: ${monthString}`,
            headerClass: [Feb25Classes.HeaderL0],
            children: colDefs,
        },
    ]
}
