import cn from 'classnames'

import { observer } from 'mobx-react-lite'

import { Icon } from '@/components/base'

import { iconNameByType } from './Alert.constants'
import { AlertProps } from './Alert.types'

import styles from './Alert.module.scss'

export const Alert = observer((props: AlertProps) => {
    const {
        className,
        contentClassName = null,
        content,
        type = 'info',
        size = 'l',
        contentCentered = false,
        children,
        flexGrow = undefined,
        loading = false,
        style,
    } = props

    const iconSize = size === 'l' ? 'l' : 'm'

    const alertClassNames = cn(
        className,
        styles.alert,
        styles[`size_${size}`],
        styles[`type_${type}`],
        { [styles.centered]: contentCentered },
    )

    const contentClassNames = cn(
        contentClassName,
        styles.content,
    )

    return (
        <div
            className={alertClassNames}
            style={{
                flexGrow,
                ...style,
            }}
        >
            {iconNameByType[type] && !loading && (
                <Icon
                    className={styles.icon}
                    name={iconNameByType[type]}
                    size={iconSize}
                />
            )}
            {loading && (
                <Icon
                    name='loader'
                    size={iconSize}
                    color='colorsPrimaryPurple'
                />
            )}
            <div className={contentClassNames}>{content ?? children}</div>
        </div>
    )
})
