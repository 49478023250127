import { useCallback, useState } from 'react'

import { Button, TextInput } from '@/components/base'
import { Layout } from '@/components/containers'

import { SpacerInputPanelProps } from './SpacerInputPanel.types'

export const SpacerInputPanel = (props: SpacerInputPanelProps) => {
    const { onAdd, disabled = false } = props
    const [newSpacerName, setNewSpacerName] = useState('')

    const clickHandler = useCallback(() => {
        onAdd(newSpacerName)
        setNewSpacerName('')
    }, [newSpacerName, onAdd])

    return (
        <Layout
            gap={8}
            align='flex-end'
            minWidth='250px'
            maxWidth='400px'
        >
            <TextInput
                value={newSpacerName}
                onChange={e => setNewSpacerName(e.target.value)}
                placeholder='Enter header row name or leave blank'
                disabled={disabled}
                label='Header and Spacer rows'
            />
            <Button
                theme='secondary'
                text='Add row'
                onClick={clickHandler}
                disabled={disabled}
            />
        </Layout>
    )
}
