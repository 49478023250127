import { useMemo } from 'react'

import { AND, EQ } from 'mobx-orm'

import { useReportingRentRollTradeOutBasicDrillDownQuery } from '@/api/reportingData/reportingRentRollTradeOutBasicDrillDown'
import { AgGridTable } from '@/components/tables'
import { useTableRangeSelectionModules } from '@/components/tables/utils/useTableRangeSelectionModules'

import { getRRTOBDrillDown, rRTOBDrillDownGridOptions } from './RRTOBDrillDown.constants'
import { RRTOBDrillDownProps } from './RRTOBDrillDown.types'

export const RRTOBDrillDown = (props: RRTOBDrillDownProps) => {
    const { params } = props
    const rangeSelectionModules = useTableRangeSelectionModules()

    const { data: ddData, isLoading } = useReportingRentRollTradeOutBasicDrillDownQuery(
        {
            filter: AND(
                EQ('asset_ids', params.asset_ids),
                EQ('asset_id', params.asset_id),
                EQ('rent_roll_ledger_id', params.rent_roll_ledger_id),
                EQ('time_period', params.time_period),
                EQ('group_by', params.group_by),
                EQ('metric_type', params.metric_type),
                EQ('group_by_value', params.group_by_value),
            ),
        },
    )

    const colDefs = useMemo(() => getRRTOBDrillDown(params.isNewLeasesSection), [params])

    return (
        // @ts-expect-error TODO: make AgGridTable generic to be able to pass theme
        <AgGridTable
            items={ddData ?? []}
            lazyModules={rangeSelectionModules}
            {...rRTOBDrillDownGridOptions}
            columnDefs={colDefs}
            loading={isLoading}
        />
    )
}
