import { MouseEvent } from 'react'

import cn from 'classnames'
import { createPortal } from 'react-dom'

import { observer } from 'mobx-react-lite'

import { Loader } from '@/components/base'
import { CENTER_MODAL_CONTAINER } from '@/constants/ids'
import { usePortal } from '@/hooks/usePortal'

import { DEFAULT_MAX_WIDTH } from './BaseModal.constants'
import { BaseModalProps } from './BaseModal.types'

import styles from './BaseModal.module.scss'

/**
 * Base component for all custom Popups and Modals
 */
export const BaseModal = observer((props: BaseModalProps) => {
    const {
        className,
        children,
        onOverlayClick,
        maxWidth = DEFAULT_MAX_WIDTH,
        maxHeight = 'none',
        minHeight = 'none',
        gap = 'normal',
        loading = false,
        open = true,
        radius = undefined,
        padding = undefined,
    } = props

    const container = usePortal(CENTER_MODAL_CONTAINER)

    if (!container) {
        return null
    }

    const handleOverlayClick = (e: MouseEvent<HTMLDivElement>) => {
        if (e.target === e.currentTarget) {
            onOverlayClick?.()
        }
    }

    if (!open) {
        return null
    }

    return createPortal(
        <div
            className={styles.overlay}
            onClick={handleOverlayClick}
            data-testid='base-modal-overlay'
        >
            <div
                className={cn(styles.baseModal, className)}
                style={{
                    maxWidth,
                    maxHeight,
                    minHeight,
                    gap,
                    borderRadius: radius,
                    padding,
                }}
            >
                {loading ? <Loader centered/> : children}
            </div>
        </div>,
        container,
    )
})
