import { forwardRef, RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { ExcelExportParams, GridState } from '@ag-grid-community/core'
import isEqual from 'lodash/isEqual'
import { AND, EQ } from 'mobx-orm'
import { createPortal } from 'react-dom'

import { AgGridReact } from '@ag-grid-community/react'
import { useNavigate } from 'react-router'

import { useAssetByIdQuery } from '@/api/asset/asset'
import { ReportingPnLRowType, useReportingProfitAndLossQuery } from '@/api/reportingData/reportingProfitAndLoss'
import { useReportingProfitAndLossMetricsQuery } from '@/api/reportingData/reportingProfitAndLossMetrics'
import { ReportingTableFilters, useReportingTableByIdQuery } from '@/api/reportingTable/reportingTable'
import { getScenarioOptions, MONTH_RANGE_END_QUERY_PARAM, MONTH_RANGE_START_QUERY_PARAM, SCENARIO_INPUT_QUERY_PARAM, TIME_INTERVAL_INPUT_QUERY_PARAM, YEAR_RANGE_END_QUERY_PARAM, YEAR_RANGE_START_QUERY_PARAM } from '@/components/baseInputs'
import { QUARTER_RANGE_END_QUERY_PARAM, QUARTER_RANGE_START_QUERY_PARAM } from '@/components/baseInputs/QuarterRangeInput'
import { Layout, useTableSettings } from '@/components/containers'
import { ASSET_DETAILS_QUERY_PARAM, ASSET_SIDE_MODAL_INPUT_QUERY_PARAM } from '@/components/models/asset'
import { AgGridTable } from '@/components/tables'
import { useTableRangeSelectionModules } from '@/components/tables/utils/useTableRangeSelectionModules'
import {
    GLOBAL_REPORT_MONTH_RANGE_END_QUERY_PARAM,
    GLOBAL_REPORT_MONTH_RANGE_START_QUERY_PARAM,
    GLOBAL_REPORT_MULTY_ASSET_QUERY_PARAM,
} from '@/components/widgets/reports/reports.constants'
import { TimeSettings } from '@/constants/timeSettings'
import { useInputState } from '@/hooks'
import { TableType } from '@/pages/TablesPage/tableType'
import { getRoute } from '@/utils/routing/getRoute'

import { CustomSettingsFilterModal } from '../../CustomSettingsFilterModal'
import { NoDataMessage } from '../../NoDataMessage'
import { CancelSaveButtons } from '../../TableBuilder/CancelSaveButtons'
import {
    ACCOUNT_CODES_INPUT_QUERY_PARAM,
    EMPTY_ROWS_INPUT_QUERY_PARAM,
    PIN_TOTAL_COL_INPUT_QUERY_PARAM,
    PinTotalColumn,
    PNL_METRIC_INPUT_QUERY_PARAM,
    SHOW_HIDE_INPUT_OPTIONS,
    TABLE_ID_ROWS_INPUT_QUERY_PARAM,
} from '../../TableBuilderFilters'
import {
    getPnlMetricInputQueryParam,
    getQuarterRangeEndQueryParam,
    getReportAssetDetailsInputQueryParam, getReportPinTotalColInputQueryParam,
    getReportQuarterRangeStartQueryParam,
    getReportScenarioInputQueryParam,
    getReportShowCodesInputQueryParam,
    getReportShowEmptyRowsInputQueryParam,
    getReportTableAssetTypeParam,
    getReportTableIdRowsInputQueryParam,
    getReportTableRrLedgerInputQueryParam,
    getReportTableTbLedgerInputQueryParam,
    getReportTimeIntervalInputQueryParam,
    getReportYearRangeEndQueryParam,
    getReportYearRangeStartQueryParam,
    TABLE_ASSET_TYPE_PARAM,
    TABLE_RR_LEDGER_INPUT_QUERY_PARAM,
    TABLE_TB_LEDGER_INPUT_QUERY_PARAM,
} from '../../TableBuilderPage.constants'
import { FILTER_DEFAULTS_ON_CREATION } from '../PnLVarianceAnalysisTable/PnLVarianceAnalysisTable.constants'
import { statusBar } from '../TableBuilderTables.constants'
import { TableBuilderTableProps } from '../TableBuilderTables.types'
import { getGridPropsToSave, getPeriodString, getTimePeriodParam, saveGridStateToRef, saveReportingTable } from '../TableBuilderTables.utils'
import { useReportingTableSaveMutation } from '../useReportingTableSaveMutation'

import { ASSET_DETAIL_TO_ENTITY_ID, getContextMenuItems, getPnLTimeSeriesColDefs, pnLTimeSeriesTableGridOptions as gridOptions, TABLE_ID_ROW_TO_ENTITY_ID } from './PnLTimeSeriesTable.constants'
import { normalizePercentageValues, processPnLTableTotalCells } from './PnLTimeSeriesTable.utils'

/**
 * P&L - Time Series Table
 */
export const PnLTimeSeriesTable = forwardRef((props: TableBuilderTableProps, ref: RefObject<AgGridReact>) => {
    const {
        tableMeta = {},
        buttonContainer,
        onCancel,
        onGridReady,
        insideReport = false,
        id,
        onFirstDataRendered,
        tablesListRouteConfigKey,
        builderRouteConfigKey,
    } = props

    const navigate = useNavigate()
    const rangeSelectionModules = useTableRangeSelectionModules()
    const isCreationMode = id === 'new'
    const fallbackTableRef = useRef<AgGridReact>(null)
    const tableRef = ref ?? fallbackTableRef
    const gridStateRef = useRef<GridState>() // Retain grid state between renders
    const areDefaultMetricsSet = useRef(false)
    const openedRowGroups = useRef(new Set<string>())

    const { showTableSettingsModal, setShowTableSettingsModal, getTableSetting, setTableSetting } = useTableSettings()

    const [isTableDirty, setIsTableDirty] = useState(false)
    const saveMutation = useReportingTableSaveMutation()
    const { data: loadedTableData } = useReportingTableByIdQuery(id, { enabled: !isCreationMode })

    // load grid state once
    if (!gridStateRef.current && loadedTableData?.aggrid_state) { gridStateRef.current = loadedTableData.aggrid_state }

    const [tBLedgerId] = useInputState(
        insideReport ? getReportTableTbLedgerInputQueryParam(id) : TABLE_TB_LEDGER_INPUT_QUERY_PARAM,
    )
    const [rRLedgerId] = useInputState(
        insideReport ? getReportTableRrLedgerInputQueryParam(id) : TABLE_RR_LEDGER_INPUT_QUERY_PARAM,
    )
    const [assetType] = useInputState(
        insideReport ? getReportTableAssetTypeParam(id) : TABLE_ASSET_TYPE_PARAM,
    )

    const [metricIds, setMetricId] = useInputState(
        insideReport ? getPnlMetricInputQueryParam(id) : PNL_METRIC_INPUT_QUERY_PARAM,
    )

    const [scenarioIds, setScenarioIds] = useInputState(
        insideReport ? getReportScenarioInputQueryParam(id) : SCENARIO_INPUT_QUERY_PARAM,
    )
    const [assetDetails, setAssetDetails] = useInputState(
        insideReport ? getReportAssetDetailsInputQueryParam(id) : ASSET_DETAILS_QUERY_PARAM,
    )
    const [tableIdRows, setTableIdRows] = useInputState(
        insideReport ? getReportTableIdRowsInputQueryParam(id) : TABLE_ID_ROWS_INPUT_QUERY_PARAM,
    )
    const [showCodes, setShowCodes] = useInputState(
        insideReport ? getReportShowCodesInputQueryParam(id) : ACCOUNT_CODES_INPUT_QUERY_PARAM,
    )
    const [showEmptyRows, setShowEmptyRows] = useInputState(
        insideReport ? getReportShowEmptyRowsInputQueryParam(id) : EMPTY_ROWS_INPUT_QUERY_PARAM,
    )
    const [startQuarter, setStartQuarter] = useInputState(
        insideReport ? getReportQuarterRangeStartQueryParam(id) : QUARTER_RANGE_START_QUERY_PARAM,
    )
    const [endQuarter, setEndQuarter] = useInputState(
        insideReport ? getQuarterRangeEndQueryParam(id) : QUARTER_RANGE_END_QUERY_PARAM,
    )
    const [startYear, setStartYear] = useInputState(
        insideReport ? getReportYearRangeStartQueryParam(id) : YEAR_RANGE_START_QUERY_PARAM,
    )
    const [endYear, setEndYear] = useInputState(
        insideReport ? getReportYearRangeEndQueryParam(id) : YEAR_RANGE_END_QUERY_PARAM,
    )
    const [timeInterval, setTimeInterval] = useInputState(
        insideReport ? getReportTimeIntervalInputQueryParam(id) : TIME_INTERVAL_INPUT_QUERY_PARAM,
    )
    const [totalCol, setTotalCol] = useInputState(
        insideReport ? getReportPinTotalColInputQueryParam(id) : PIN_TOTAL_COL_INPUT_QUERY_PARAM,
    )

    const [startMonth, setStartMonth] = useInputState(insideReport ? GLOBAL_REPORT_MONTH_RANGE_START_QUERY_PARAM : MONTH_RANGE_START_QUERY_PARAM)
    const [assetIds, setAssetIds] = useInputState(insideReport ? GLOBAL_REPORT_MULTY_ASSET_QUERY_PARAM : ASSET_SIDE_MODAL_INPUT_QUERY_PARAM)
    const [endMonth, setEndMonth] = useInputState(insideReport ? GLOBAL_REPORT_MONTH_RANGE_END_QUERY_PARAM : MONTH_RANGE_END_QUERY_PARAM)

    const showAccountCodes = showCodes === '1'
    const isShowEmptyRowsEnabled = showEmptyRows === '1'
    const scenarioId = scenarioIds?.[0]

    const conditionalFormatRules = loadedTableData?.id ? (getTableSetting(loadedTableData.id)?.conditionalRules ?? undefined) : undefined

    const assetDetailRowIds = useMemo(() => new Set(assetDetails?.map(d => ASSET_DETAIL_TO_ENTITY_ID[d])), [assetDetails])
    const reportDetailRowIds = useMemo(() => new Set(tableIdRows?.map(d => TABLE_ID_ROW_TO_ENTITY_ID[d])), [tableIdRows])

    const timePeriod = useMemo(
        () => getTimePeriodParam({
            startMonth,
            endMonth,
            startQuarter,
            endQuarter,
            startYear,
            endYear,
            timeInterval,
        }),
        [startMonth, endMonth, startQuarter, endQuarter, startYear, endYear, timeInterval],
    )

    const areFiltersSet = Boolean(assetIds && timePeriod && scenarioId)

    const { data: reportData, isLoading, isFetched, isSuccess } = useReportingProfitAndLossQuery(
        {
            filter: AND(
                EQ('asset_ids', assetIds),
                EQ('trial_balance_ledger_id', tBLedgerId),
                EQ('rent_roll_ledger_id', rRLedgerId),
                EQ('metric_ids', metricIds),
                EQ('time_settings', TimeSettings.SERIES),
                EQ('time_period', timePeriod),
                EQ('time_interval', timeInterval),
                EQ('scenario', scenarioId),
            ),
        },
        { enabled: areFiltersSet })

    const { data: pnLMetrics } = useReportingProfitAndLossMetricsQuery(
        {
            filter: AND(
                EQ('asset_ids', assetIds),
                EQ('trial_balance_ledger_id', tBLedgerId),
                EQ('rent_roll_ledger_id', rRLedgerId),
            ),
        },
        { enabled: Boolean(isCreationMode && assetIds) },
    )

    // set default metrics once in creation mode
    const defaultPnLMetrics = useMemo(() => pnLMetrics?.filter(m => m.is_default), [pnLMetrics])
    useEffect(() => {
        if (isCreationMode && defaultPnLMetrics?.length && !areDefaultMetricsSet.current) {
            setMetricId(defaultPnLMetrics.map(m => m.id))
            setShowEmptyRows(FILTER_DEFAULTS_ON_CREATION.ShowEmptyRows)
            areDefaultMetricsSet.current = true
        }
    }, [defaultPnLMetrics, isCreationMode, setMetricId, setShowEmptyRows])

    const [rows, columns] = useMemo(() => [reportData?.[0]?.rows ?? [], reportData?.[0]?.columns ?? []], [reportData])

    const filteredRows = useMemo(() => {
        const filtered = normalizePercentageValues(rows)
            .filter(row => {
                if (row.type === ReportingPnLRowType.ReportDetails) {
                    return reportDetailRowIds.has(row.entity_id)
                } else if (row.type === ReportingPnLRowType.AssetDetails) {
                    return assetDetailRowIds.has(row.entity_id)
                } else if (row.type === ReportingPnLRowType.Separator) {
                    return true
                }

                if (!isShowEmptyRowsEnabled) {
                    const rowEmpty = Object.values(row.values).filter(v => v).length === 0
                    if (rowEmpty) {
                        return false
                    }
                }

                return true
            })
        // filter out double separators & first row separator
        return filtered.filter((row, i, arr) => {
            if (row.type === ReportingPnLRowType.Separator) {
                return i === 0 ? false : arr[i - 1].type !== ReportingPnLRowType.Separator
            }
            return true
        })
    }, [rows, assetDetailRowIds, reportDetailRowIds, isShowEmptyRowsEnabled])

    const { data: asset } = useAssetByIdQuery(assetIds?.length && assetIds[0] ? assetIds[0] : undefined, { enabled: Boolean(assetIds?.length) })

    const periodAsString = useMemo(() => getPeriodString(timeInterval, timePeriod), [timeInterval, timePeriod])
    const scenario = useMemo(() => getScenarioOptions(assetType).find(s => s.value === scenarioId), [assetType, scenarioId])
    const tableTitles = useMemo(() => {
        const assetScenarioString = `${assetIds?.length && assetIds.length > 1 ? 'Multiple assets' : asset?.name ?? '...'} - ${scenario?.label ?? '...'}`
        return [assetScenarioString, periodAsString]
    }, [assetIds, asset?.name, scenario?.label, periodAsString])

    const columnDefs = useMemo(
        () => getPnLTimeSeriesColDefs({
            conditionalFormatRules,
            columns,
            tableTitles,
            totalCol,
            assetType,
            showAccountCodes,
            timeInterval,
        }),
        [conditionalFormatRules, columns, tableTitles, totalCol, assetType, showAccountCodes, timeInterval],
    )

    // table config
    const config = useMemo(() => {
        const filters: ReportingTableFilters = { asset_ids: assetIds as number[] }
        if (timeInterval) { filters.timeInterval = timeInterval }
        if (startMonth) { filters.startMonth = startMonth }
        if (endMonth) { filters.endMonth = endMonth }
        if (startQuarter) { filters.startQuarter = startQuarter }
        if (endQuarter) { filters.endQuarter = endQuarter }
        if (startYear) { filters.startYear = startYear }
        if (endYear) { filters.endYear = endYear }
        if (assetDetails) { filters.assetDetails = assetDetails }
        if (scenarioId) { filters.scenarioId = scenarioId }
        if (metricIds) { filters.pnLmetricIds = metricIds }
        if (totalCol) { filters.pinTotalColumn = totalCol }
        if (tableIdRows) { filters.tableIdRows = tableIdRows }
        if (showCodes) { filters.showCodes = showCodes }
        if (showEmptyRows) { filters.showEmptyRows = showEmptyRows }
        return ({
            filters,
            conditional_format_rules: conditionalFormatRules && { rules: conditionalFormatRules },
        })
    }, [conditionalFormatRules, assetDetails, assetIds, endMonth, endQuarter, endYear, metricIds, scenarioId, showCodes, showEmptyRows, tableIdRows, startMonth, startQuarter, startYear, timeInterval, totalCol])

    const checkIfTableIsDirty = useCallback(() => {
        const loadedState = loadedTableData?.aggrid_state ?? {}
        const currentState = getGridPropsToSave(tableRef.current?.api?.getState())
        const currentConfig = config
        const loadedConfig = loadedTableData?.config ?? {}
        const currentName = tableMeta.name
        const loadedName = loadedTableData?.name

        if (
            isEqual(currentConfig, loadedConfig) &&
            isEqual(currentState, loadedState) &&
            currentName === loadedName
        ) {
            setIsTableDirty(false)
        } else {
            setIsTableDirty(true)
        }
    }, [config, loadedTableData?.aggrid_state, loadedTableData?.config, loadedTableData?.name, tableMeta.name, tableRef])

    useEffect(() => checkIfTableIsDirty(), [checkIfTableIsDirty])

    // set filters from loaded table data
    useEffect(() => {
        if (!loadedTableData) {
            return
        }

        const { filters, conditional_format_rules } = loadedTableData.config
        if (filters) {
            if ((insideReport && !assetIds) || !insideReport) {
                filters.asset_ids && setAssetIds(filters.asset_ids)
            }
            if ((insideReport && !startMonth) || !insideReport) {
                filters.startMonth && setStartMonth(filters.startMonth)
                filters.endMonth && setEndMonth(filters.endMonth)
            }
            filters.timeInterval && setTimeInterval(filters.timeInterval)
            filters.startQuarter && setStartQuarter(filters.startQuarter)
            filters.endQuarter && setEndQuarter(filters.endQuarter)
            filters.startYear && setStartYear(filters.startYear)
            filters.endYear && setEndYear(filters.endYear)
            filters.assetDetails && setAssetDetails(filters.assetDetails)
            filters.scenarioId && setScenarioIds([filters.scenarioId])
            filters.pnLmetricIds && setMetricId(filters.pnLmetricIds)
            filters.tableIdRows && setTableIdRows(filters.tableIdRows)

            // Set default values here to avoid overwriting with default Select value
            setShowCodes(filters.showCodes ? filters.showCodes : SHOW_HIDE_INPUT_OPTIONS[0].value)
            setShowEmptyRows(filters.showEmptyRows ? filters.showEmptyRows : SHOW_HIDE_INPUT_OPTIONS[0].value)
            setTotalCol(filters.pinTotalColumn ? filters.pinTotalColumn : PinTotalColumn.Right)
        }
        setTableSetting(loadedTableData.id, conditional_format_rules ? conditional_format_rules.rules : [])
    }, [loadedTableData, setAssetDetails, setAssetIds, setEndMonth, setEndQuarter, setEndYear, setMetricId, setScenarioIds, setShowCodes, setShowEmptyRows, setTableIdRows, setStartMonth, setStartQuarter, setStartYear, setTimeInterval, setTotalCol, insideReport])

    const onTableSave = useCallback(async () => {
        const currentState = tableRef.current?.api?.getState() ?? {}
        const response = await saveReportingTable({
            tableMeta,
            currentState,
            isCreationMode,
            saveMutation,
            id,
            tableType: TableType.PnLTimeSeries,
            config,
            assetType,
        })

        if (!response) {
            return
        }

        // redirect after saving
        if (isCreationMode) {
            navigate(getRoute(builderRouteConfigKey, { id: response.id }))
        } else {
            navigate(getRoute(tablesListRouteConfigKey))
        }
    }, [assetType, config, id, isCreationMode, navigate, saveMutation, tableMeta, tableRef])

    const excelExportParams = useMemo((): ExcelExportParams => {
        return ({
            fileName: `P&L - ${scenario?.label} - ${periodAsString}.xlsx`,
            sheetName: periodAsString,
            processCellCallback: processPnLTableTotalCells,
            freezeColumns: 'pinned',
            suppressRowOutline: true,
        })
    }, [periodAsString, scenario?.label])

    const onModelUpdated = useCallback(() => {
        if (!isLoading && isFetched && isSuccess) {
            onFirstDataRendered()
        }
    }, [isLoading, isFetched, isSuccess, onFirstDataRendered])

    return (
        <>
            <Layout gap={16} flexGrow={1} direction='column'>
                {areFiltersSet
                    ? (
                        // @ts-expect-error TODO: make AgGridTable generic to be able to pass theme
                        <AgGridTable
                            ref={tableRef}
                            items={filteredRows}
                            {...gridOptions}
                            columnDefs={columnDefs}
                            loading={isLoading}
                            initialState={gridStateRef.current}
                            lazyModules={rangeSelectionModules}
                            // Track expand state separately because onStateUpdated will reset the expand state if refetched
                            onRowGroupOpened={(params) => {
                                if (params.node.key) {
                                    if (params.node.expanded) {
                                        openedRowGroups.current.add(params.node.key)
                                    } else {
                                        openedRowGroups.current.delete(params.node.key)
                                    }
                                }
                                tableRef?.current?.api?.redrawRows() // redraw to update conditional formatting
                            }}
                            onRowDataUpdated={({ api }) => {
                                // Recover expanded state if data updated
                                api.forEachNode(node => {
                                    if (node.group && node.key) {
                                        if (openedRowGroups.current.has(node.key)) {
                                            node.setExpanded(true)
                                        }
                                    }
                                })
                            }}
                            onStateUpdated={(e) => {
                                saveGridStateToRef(e, gridStateRef)
                                checkIfTableIsDirty()
                            }}
                            statusBar={statusBar}
                            defaultExcelExportParams={excelExportParams}
                            getContextMenuItems={(params) => [...getContextMenuItems(params),
                                {
                                    name: 'Table Settings',
                                    action: () => setShowTableSettingsModal(true),
                                },
                            ]}
                            onGridReady={onGridReady}
                            onModelUpdated={onModelUpdated}
                        />
                    )
                    : (<NoDataMessage text='Choose required fields'/>)}
            </Layout>

            {showTableSettingsModal && (
                <CustomSettingsFilterModal
                    tableId={id}
                    tableRef={tableRef}
                    tableType={TableType.PnLTimeSeries}
                    onClose={() => setShowTableSettingsModal(false)}
                    onSave={() => setIsTableDirty(true)}
                />
            )}

            {buttonContainer && createPortal(
                <CancelSaveButtons
                    onCancel={onCancel}
                    onSave={onTableSave}
                    isLoading={saveMutation.isPending}
                    isDisabled={!isTableDirty || !tableMeta.name}
                />,
                buttonContainer,
            )}
        </>
    )
})
