/* eslint-disable @typescript-eslint/prefer-reduce-type-parameter */
import { CSSProperties } from 'react'

import { ICellRendererParams } from '@ag-grid-community/core'

import { ReportingPnLRowType } from '@/api/reportingData/reportingProfitAndLoss'
import { ReportingPnLVarianceRow, ReportingPnLVarianceValueEntry } from '@/api/reportingData/reportingProfitAndLossVariance'
import { ReportingDataValueType } from '@/constants/reportingDataValueTypes'
import { TimeComparisonPeriod } from '@/constants/timeComparisonPeriods'

import { ReportScenario } from '../../../../../constants/reportScenario'
import { applyFunction, ConditionalFormatRule, ConditionalFormatStyle } from '../../ConditionalRuleEditor/ConditionalRuleEditor.types'
import { applyConditionalFormattingRuleToStyle } from '../../ConditionalRuleEditor/ConditionalRuleEditor.utils'

/**
 * Transforms percentage values from decimal to percentage (0.1234 => 12.34)
 */
export const normalizePercentageValues = (rows: ReportingPnLVarianceRow[]): ReportingPnLVarianceRow[] => {
    return rows.map(row => {
        switch (row.type) {
            case ReportingPnLRowType.Separator:
            case ReportingPnLRowType.ReportDetails:
            case ReportingPnLRowType.AssetDetails:
                return row

            default:
                // normalize all values for percentage value_type
                if (row.value_type === ReportingDataValueType.PERCENTAGE) {
                    return {
                        ...row,
                        values: Object.entries(row.values).reduce((acc, [key, data]) => {
                            return {
                                ...acc,
                                [key]: Object.entries(data).reduce((dataObj, [scenario, value]) => {
                                    return {
                                        ...dataObj,
                                        [scenario]: (typeof value === 'number') ? value * 100 : value,
                                    }
                                }, {} as ReportingPnLVarianceValueEntry),
                            }
                        }, {} as Record<TimeComparisonPeriod, ReportingPnLVarianceValueEntry>),
                    }
                }

                // normalize only variance_percent values
                return {
                    ...row,
                    values: Object.entries(row.values).reduce((acc, [key, data]) => {
                        return {
                            ...acc,
                            [key]: {
                                ...data,
                                variance_percent: (typeof data.variance_percent === 'number') ? data.variance_percent * 100 : data.variance_percent,
                            },
                        }
                    }, {} as Record<TimeComparisonPeriod, ReportingPnLVarianceValueEntry>),
                }
        }
    })
}

export const getPnlVarianceTableRowId = ({ data }: { data: ReportingPnLVarianceRow }) => {
    return (data?.entity_id || '') + (data?.tree_path || []).join('|')
}

export const cellRenderStyledFromConditionalFormatRules = (conditionalFormatRules: ConditionalFormatRule[], params: ICellRendererParams<ReportingPnLVarianceRow>, timeComparison: TimeComparisonPeriod, colScenario: ReportScenario) => {
    if ((params.node.group && params.node.expanded) || params.value === undefined || params.value === '' || params?.data?.type === 'category_metric') {
        return params.valueFormatted
    }

    let newStyle: ConditionalFormatStyle = {}

    // Apply conditional formatting rules. Reverse the array to apply the rules in priority order
    // First rule gets applied last and therefore has the highest priority since styles can overwrite each other
    for (const rule of conditionalFormatRules.reverse()) {
        // Determine if condition(s) are met to apply a rule's styling to the cell
        let conditionMet = true

        // Check Primary Condition First
        const primaryConditionColumnValue = params?.data?.values?.[timeComparison]?.[rule.primaryConditionColumn]
        if (!primaryConditionColumnValue) continue
        const isPrimaryPercentage = [ReportScenario.VARIANCE_PERCENT, ReportScenario.VARIANCE_UNDERWRITING_PERCENT, ReportScenario.VARIANCE_BUDGET_UNDERWRITING_PERCENT].includes(rule.primaryConditionColumn as ReportScenario)
        const primaryConditionColumnValueFormatted = isPrimaryPercentage
            ? Math.round(primaryConditionColumnValue * 10) / 1000 // represent percentage as decimal with 3 decimal places
            : parseFloat(primaryConditionColumnValue.toFixed(0))

        const firstConditionResult = applyFunction(rule.primaryConditionFunction, primaryConditionColumnValueFormatted, rule.primaryConditionValue)

        // Check Secondary Condition (may not be present)
        const secondaryConditionColumnValue = rule.secondaryConditionColumn && params?.data?.values?.[timeComparison]?.[rule.secondaryConditionColumn]
        const isSecondaryPercentage = secondaryConditionColumnValue && [ReportScenario.VARIANCE_PERCENT, ReportScenario.VARIANCE_UNDERWRITING_PERCENT, ReportScenario.VARIANCE_BUDGET_UNDERWRITING_PERCENT].includes(rule.secondaryConditionColumn as ReportScenario)
        const secondaryConditionColumnValueFormatted = secondaryConditionColumnValue && (isSecondaryPercentage
            ? Math.round(secondaryConditionColumnValue * 10) / 1000 // represent percentage as decimal with 3 decimal places
            : parseFloat(secondaryConditionColumnValue.toFixed(0)))
        const secondaryConditionResult = (rule.secondaryConditionFunction && applyFunction(rule.secondaryConditionFunction, secondaryConditionColumnValueFormatted, rule.secondaryConditionValue)) ?? false

        const applySecondaryCondition = secondaryConditionColumnValue && rule.secondaryConditionFunction && rule.secondaryConditionValue && rule.secondaryConditionColumn
        if (applySecondaryCondition) {
            if (rule.conditionCombinationOperator === 'AND') {
                conditionMet = firstConditionResult && secondaryConditionResult
            } else {
                conditionMet = firstConditionResult || secondaryConditionResult
            }
        } else {
            conditionMet = firstConditionResult
        }

        // Apply the rule's styling to the cell if the condition(s) are met
        if (conditionMet && rule.columnsToBeFormatted.includes(colScenario)) {
            newStyle = applyConditionalFormattingRuleToStyle(rule, newStyle)
        }
    }

    const styledIcon = newStyle.cellIconStyle ? <span className={newStyle.cellIconStyle?.iconClass} style={newStyle?.cellIconStyle ? newStyle.cellIconStyle as CSSProperties : {}}/> : <span/>

    return (
        <div style={newStyle as CSSProperties}>{styledIcon}<span>{params.valueFormatted}</span></div>
    )
}
