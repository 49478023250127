import { ChangeEvent, useCallback, useEffect, useState } from 'react'

import debounce from 'lodash/debounce'

import { TextInput } from '@/components/base/TextInput'
import { useInputState } from '@/hooks'

import { TEXT_INPUT_QUERY_PARAM, TEXT_SEARCH_DEBOUNCE_TIME } from './TextSearchInput.constants'
import { TextSearchInputProps } from './TextSearchInput.types'

const { type: queryParamType, name: queryParamName } = TEXT_INPUT_QUERY_PARAM

const NUM_ONLY_REGEX = /^\d+$/

export const TextSearchInput = (props: TextSearchInputProps) => {
    const {
        placeholder = 'Search',
        paramName = queryParamName,
        debounce: delay = TEXT_SEARCH_DEBOUNCE_TIME,
        syncLocalStorage,
        hideIcon = false,
        defaultValue = '',
        numberInputOnly = false,
        inputParams = {},
        ...rest
    } = props

    const [param, setParam] = useInputState({
        name: paramName,
        type: queryParamType,
        syncLocalStorage,
        defaultValue,
        ...inputParams,
    })
    const [value, setValue] = useState(defaultValue)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSetParam = useCallback(debounce(setParam, delay), [delay, setParam])

    const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        const updatedValue = e.target.value

        if (!numberInputOnly || updatedValue === '' || NUM_ONLY_REGEX.test(updatedValue.replaceAll(',', ''))) {
            setValue(numberInputOnly ? updatedValue.replaceAll(',', '') : updatedValue)
            debouncedSetParam(updatedValue)
        }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => setValue(param ?? ''), [])

    const formatNumberValue = (value: string) => {
        if (value === '') return value
        const format = new Intl.NumberFormat('en-US')
        return format.format(parseFloat(value))
    }

    return (
        <TextInput
            {...rest}
            onChange={changeHandler}
            value={numberInputOnly ? formatNumberValue(value) : value}
            placeholder={placeholder}
            icon={hideIcon ? undefined : 'search'}
            dataTestId='search-input'
        />
    )
}
