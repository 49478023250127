import { useMemo } from 'react'

import cn from 'classnames'

import { Text } from '@/components/base'

import { InputBoxProps } from './InputBox.types'

import styles from './InputBox.module.scss'

/**
 * Wrapper with input styles
 * Can be used as a base for creating selects/pickers etc.
 */
export const InputBox = (props: InputBoxProps) => {
    const {
        className,
        label,
        labelInside,
        size = 's',
        width = null,
        maxWidth = null,
        minWidth = null,
        isActive,
        children,
        onClick,
        onMouseEnter,
        onMouseLeave,
        testId,
        labelsOnly,
        hint,
        error,
        disabled,
        borderless,
        required = false,
        flexGrow = null,
    } = props

    const contentClassName = cn(styles.content, className, {
        [styles['size_' + size]]: size,
        [styles.active]: isActive,
        [styles.labelsOnly]: labelsOnly,
        [styles.hasError]: error,
        [styles.disabled]: disabled,
        [styles.borderless]: borderless,
    })

    const labelEl = useMemo(() => (
        <Text
            text={label + (required ? ' (Required)' : '')}
            color='secondaryLightBlack'
            variant='smallTextMediumDefault'
            whiteSpace='nowrap'
        />
    ), [label, required])

    const haveLabel = Boolean(label)

    return (
        <label
            className={cn(styles.wrapper, { [styles.isDisabled]: disabled })}
            style={{
                // @ts-expect-error TS(2322) FIXME: Type 'Width<0 | (string & {})> | null' is not assi... Remove this comment to see the full error message
                width,
                // @ts-expect-error TS(2322) FIXME: Type 'Width<0 | (string & {})> | null' is not assi... Remove this comment to see the full error message
                maxWidth,
                // @ts-expect-error TS(2322) FIXME: Type 'Width<0 | (string & {})> | null' is not assi... Remove this comment to see the full error message
                flexGrow,
                // @ts-expect-error TS(2322) FIXME: Type 'Width<0 | (string & {})> | null' is not assi... Remove this comment to see the full error message
                minWidth,
            }}
            // TODO (Andrey V): Test and make it work
            // inert={disabled || isActive ? '' : undefined}
        >
            {(haveLabel && !labelInside) && (labelEl)}
            <div
                className={contentClassName}
                onClick={onClick}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                data-testid={testId}
            >
                {(haveLabel && labelInside) && (
                    <span className={styles.label} key='label'>
                        {[labelEl, ':']}
                    </span>
                )}
                {children}
            </div>
            {hint && (
                <Text
                    color={error ? 'statusRed' : 'secondaryLightBlack'}
                    variant='smallTextMediumDefault'
                    text={hint}
                />
            )}
        </label>
    )
}
