import { Model, model, field, foreign } from 'mobx-orm'

import { DataPoint, DataPointType } from '../data_pulling'

@model
export class PackageFile extends Model {
    @field datapoint_id: number
    @field name:         string
    @field uploaded_at:  string
    @field type:         DataPointType

    @foreign(DataPoint) readonly datapoint: DataPoint
}
