import { forwardRef, RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { GridState, RowDragEndEvent } from '@ag-grid-community/core'
import isEqual from 'lodash/isEqual'
import { AND, EQ } from 'mobx-orm'
import { createPortal } from 'react-dom'

import { AgGridReact } from '@ag-grid-community/react'
import { useNavigate } from 'react-router'

import { useAssetsQuery } from '@/api/asset/asset'
import { useReportingPortfolioOverviewQuery } from '@/api/reportingData/reportingPortfolioOverview'
import { ReportingTableSpacer, useReportingTableByIdQuery } from '@/api/reportingTable/reportingTable'
import { Loader } from '@/components/base'
import { MONTH_INPUT_QUERY_PARAM, TIME_COMPARISON_INPUT_QUERY_PARAM } from '@/components/baseInputs'
import { Layout } from '@/components/containers'
import { ASSET_SIDE_MODAL_INPUT_QUERY_PARAM } from '@/components/models/asset'
import { COMPANY_SELECT_INPUT_QUERY_PARAM } from '@/components/models/company'
import { AgGridTable } from '@/components/tables'
import { useTableToolPanelModules } from '@/components/tables/utils/useTableToolPanelModules'
import {
    GLOBAL_REPORT_MONTH_QUERY_PARAM,
    GLOBAL_REPORT_MULTY_ASSET_QUERY_PARAM,
} from '@/components/widgets/reports/reports.constants'
import { TimeComparisonPeriod } from '@/constants/timeComparisonPeriods'
import { useInputState, usePortal } from '@/hooks'
import { useMe } from '@/hooks/core/useMe'
import { TableType } from '@/pages/TablesPage/tableType'
import { monthIndToString } from '@/utils/date/monthInd'
import { getRoute } from '@/utils/routing/getRoute'

import { CancelSaveButtons } from '../../TableBuilder/CancelSaveButtons'
import { SpacerInputPanel } from '../../TableBuilder/SpacerInputPanel'
import { getNewSpacerName, moveInArray } from '../../TableBuilder/TableBuilder.utils'
import {
    getReportTableAssetTypeParam,
    getReportTableRrLedgerInputQueryParam,
    getReportTableTbLedgerInputQueryParam, getReportTimeComparisonInputQueryParam, TABLE_ASSET_TYPE_PARAM,
    TABLE_BUILDER_EDIT_PAGE_HEADER_SPACER_INPUT, TABLE_RR_LEDGER_INPUT_QUERY_PARAM, TABLE_TB_LEDGER_INPUT_QUERY_PARAM,
} from '../../TableBuilderPage.constants'
import { TableBuilderTableProps } from '../TableBuilderTables.types'
import { getGridPropsToSave, getTableExportMeta, saveGridStateToRef, saveReportingTable } from '../TableBuilderTables.utils'
import { useReportingTableSaveMutation } from '../useReportingTableSaveMutation'

import { getPortfolioOverviewColDefs, getPortfolioOverviewSpacerItems, porfolioOverviewGridOptons as gridOptions } from './PortfolioOverviewTable.constants'

import styles from '../../TableBuilder/TableBuilder.module.scss'

/**
 * Portfolio Income Statement - Asset Columns table
 */
export const PortfolioOverviewTable = forwardRef((props: TableBuilderTableProps, ref: RefObject<AgGridReact>) => {
    const {
        tableMeta = {},
        buttonContainer,
        onCancel,
        onGridReady,
        id,
        editable = false,
        insideReport = false,
        onFirstDataRendered,
        tablesListRouteConfigKey,
        builderRouteConfigKey,
    } = props

    const { me } = useMe()
    const navigate = useNavigate()
    const isCreationMode = id === 'new'
    const fallbackTableRef = useRef<AgGridReact>(null)
    const tableRef = ref ?? fallbackTableRef
    const gridStateRef = useRef<GridState>() // Retain grid state between renders
    const toolPanelModules = useTableToolPanelModules()
    const spacerInputContainer = usePortal(TABLE_BUILDER_EDIT_PAGE_HEADER_SPACER_INPUT)

    const { data: loadedTableData } = useReportingTableByIdQuery(id, { enabled: !isCreationMode })

    // load grid state from table data once
    if (!gridStateRef.current && loadedTableData?.aggrid_state) { gridStateRef.current = loadedTableData.aggrid_state }

    const saveMutation = useReportingTableSaveMutation()

    const [assetIds, setAssetIds] = useInputState(
        insideReport ? GLOBAL_REPORT_MULTY_ASSET_QUERY_PARAM : ASSET_SIDE_MODAL_INPUT_QUERY_PARAM,
    )

    const [monthIndex, setMonthIndex] = useInputState(
        insideReport ? GLOBAL_REPORT_MONTH_QUERY_PARAM : MONTH_INPUT_QUERY_PARAM,
    )

    const [timeComparison, setTimeComparison] = useInputState(
        insideReport ? getReportTimeComparisonInputQueryParam(id) : TIME_COMPARISON_INPUT_QUERY_PARAM,
    )

    const [companyId] = useInputState(COMPANY_SELECT_INPUT_QUERY_PARAM)

    const [tBLedgerId] = useInputState(
        insideReport ? getReportTableTbLedgerInputQueryParam(id) : TABLE_TB_LEDGER_INPUT_QUERY_PARAM,
    )
    const [rRLedgerId] = useInputState(
        insideReport ? getReportTableRrLedgerInputQueryParam(id) : TABLE_RR_LEDGER_INPUT_QUERY_PARAM,
    )
    const [assetType] = useInputState(
        insideReport ? getReportTableAssetTypeParam(id) : TABLE_ASSET_TYPE_PARAM,
    )

    const [rowOrder, setRowOrder] = useState<string[]>([])
    const [spacers, setSpacers] = useState<ReportingTableSpacer[]>([])
    const [isTableDirty, setIsTableDirty] = useState(false)

    const { data: assets } = useAssetsQuery({ filter: EQ('company_id', me.isAdministratorMode ? companyId : undefined) })
    const firstSelectedAsset = assets?.find((asset) => asset.id === assetIds?.[0])

    const { data: portfolioOverviewData, isLoading, isFetched, isSuccess } = useReportingPortfolioOverviewQuery(
        {
            filter: AND(
                EQ('asset_ids', assetIds),
                EQ('trial_balance_ledger_id', tBLedgerId),
                EQ('rent_roll_ledger_id', rRLedgerId),
                EQ('month', monthIndex),
                EQ('time_periods', timeComparison),
            ),
        },
        { enabled: Boolean(assetIds && monthIndex && timeComparison) },
    )

    const [itemsData, headersData, columnsData, treeData] = useMemo(() => [
        portfolioOverviewData?.[0]?.rows ?? [],
        portfolioOverviewData?.[0]?.header ?? [],
        portfolioOverviewData?.[0]?.subheader ?? [],
        portfolioOverviewData?.[0]?.tree_data ?? [],
    ], [portfolioOverviewData])

    const dataItemsWithSpacers = useMemo(() => [
        ...itemsData,
        ...getPortfolioOverviewSpacerItems(spacers),
    ], [itemsData, spacers])

    const orderedDataItems = useMemo(() => {
        if (!dataItemsWithSpacers.length) {
            return []
        }

        if (!rowOrder.length) {
            return dataItemsWithSpacers
        }

        return [...dataItemsWithSpacers].sort((a, b) => rowOrder.indexOf(a.id) - rowOrder.indexOf(b.id))
    }, [dataItemsWithSpacers, rowOrder])

    const treeDataWithSpacers = useMemo(() => [
        ...treeData,
        ...(spacers.length ? [
            {
                id: 'spacers',
                name: 'Spacers',
                children: spacers.map(({ id, value }) => ({
                    id,
                    name: value.trim() || id,
                })),
            },
        ] : []),
    ], [spacers, treeData])

    // table config
    const config = useMemo(() => ({
        filters: {
            asset_ids: assetIds as number[],
            month: monthIndex as number,
            time_periods: timeComparison as TimeComparisonPeriod[],
        },
        order: rowOrder,
        spacers,
    }), [assetIds, monthIndex, timeComparison, rowOrder, spacers])

    // set filters from loaded table data
    useEffect(() => {
        if (!loadedTableData) {
            return
        }

        const { filters } = loadedTableData.config

        if (filters) {
            if ((insideReport && !assetIds) || !insideReport) {
                filters.asset_ids && setAssetIds(filters.asset_ids)
            }
            if ((insideReport && !monthIndex) || !insideReport) {
                filters.month && setMonthIndex(filters.month)
            }
            filters.time_periods && setTimeComparison(filters.time_periods)
        }
    }, [loadedTableData, setAssetIds, setMonthIndex, setTimeComparison])

    // set row order & spacers from loaded table data
    useEffect(() => {
        if (loadedTableData?.config.spacers) {
            setSpacers(loadedTableData.config.spacers)
        }

        if (loadedTableData?.config.order) {
            setRowOrder(loadedTableData.config.order)
        }
    }, [itemsData, loadedTableData?.config.order, loadedTableData?.config.spacers])

    useEffect(() => {
        // add data item ids to rowOrder if they are not there yet
        const idsInOrderList = new Set(rowOrder)
        const missingIds = dataItemsWithSpacers.filter(({ id }) => !idsInOrderList.has(id)).map(({ id }) => id)
        if (missingIds.length) {
            setRowOrder([...idsInOrderList, ...missingIds])
        }
    }, [dataItemsWithSpacers, rowOrder])

    const checkIfTableIsDirty = useCallback(() => {
        const loadedState = loadedTableData?.aggrid_state ?? {}
        const currentState = getGridPropsToSave(tableRef.current?.api?.getState())
        const currentConfig = config
        const loadedConfig = loadedTableData?.config ?? {}
        const currentName = tableMeta.name
        const loadedName = loadedTableData?.name

        if (
            isEqual(currentConfig, loadedConfig) &&
            isEqual(currentState, loadedState) &&
            currentName === loadedName
        ) {
            setIsTableDirty(false)
        } else {
            setIsTableDirty(true)
        }
    }, [config, loadedTableData?.aggrid_state, loadedTableData?.config, loadedTableData?.name, tableMeta.name, tableRef])

    useEffect(() => checkIfTableIsDirty(), [checkIfTableIsDirty])

    const columnDefs = useMemo(() => getPortfolioOverviewColDefs({
        headers: headersData,
        columns: columnsData,
    }), [headersData, columnsData])

    const onRowDragEnd = useCallback((event: RowDragEndEvent) => {
        if (!rowOrder.length) { return }
        const movingNode = event.node
        const overNode = event.overNode
        const rowNeedsToMove = movingNode !== overNode

        if (rowNeedsToMove) {
            const movingId = movingNode.data.id
            const overId = overNode?.data.id
            const fromIndex = rowOrder.indexOf(movingId)
            const toIndex = overNode ? rowOrder.indexOf(overId) : rowOrder.length

            const newOrder = rowOrder.slice()
            moveInArray(newOrder, fromIndex, toIndex)
            setRowOrder(newOrder)
            tableRef.current?.api.clearFocusedCell()
        }
    }, [rowOrder, tableRef])

    const onTableSave = useCallback(async () => {
        const currentState = tableRef.current?.api?.getState() ?? {}
        const response = await saveReportingTable({
            tableMeta,
            currentState,
            isCreationMode,
            saveMutation,
            id,
            tableType: TableType.PortfolioOverview,
            config,
            assetType,
        })

        if (!response) {
            return
        }

        // redirect after saving
        if (isCreationMode) {
            navigate(getRoute(builderRouteConfigKey, { id: response.id }))
        } else {
            navigate(getRoute(tablesListRouteConfigKey))
        }
    }, [tableRef, tableMeta, isCreationMode, saveMutation, id, config, assetType, navigate])

    const onSpacerAdd = useCallback((spacerName: string) => {
        const spacerId = getNewSpacerName(spacers.map(({ id }) => id))
        const newSpacer: ReportingTableSpacer = {
            value: spacerName || ' ',
            id: spacerId,
        }

        setSpacers([...spacers, newSpacer])
    }, [spacers])

    const excelExportParams = useMemo(() => ({
        fileName: `Portfolio Income Statement - Asset Columns - ${assetIds?.length === 1 ? `${firstSelectedAsset?.name} - ` : ''} ${monthIndex ? monthIndToString(monthIndex) : ''}.xlsx`,
        sheetName: monthIndex ? monthIndToString(monthIndex) : '',
        prependContent: getTableExportMeta(assets, assetIds, monthIndex),
    }), [assetIds, firstSelectedAsset?.name, monthIndex, assets])

    const onModelUpdated = useCallback(() => {
        if (!isLoading && isFetched && isSuccess) {
            onFirstDataRendered()
        }
    }, [isLoading, isFetched, isSuccess, onFirstDataRendered])

    return (
        <>
            <Layout gap={16} flexGrow={1} direction='column'>
                {columnDefs.length
                    ? (
                        <AgGridTable
                            className={styles.table}
                            ref={tableRef}
                            items={orderedDataItems}
                            {...gridOptions}
                            columnDefs={columnDefs}
                            initialState={gridStateRef.current}
                            lazyModules={toolPanelModules}
                            hideStatusBar
                            onRowDragEnd={editable ? onRowDragEnd : undefined}
                            sideBar={editable ? gridOptions.sideBar : false}
                            suppressRowDrag={editable ? gridOptions.suppressRowDrag : true}
                            suppressMovableColumns={editable ? gridOptions.suppressMovableColumns : true}
                            onStateUpdated={(e) => {
                                saveGridStateToRef(e, gridStateRef)
                                checkIfTableIsDirty()
                            }}
                            context={{
                                treeData: treeDataWithSpacers,
                                editable,
                            }}
                            defaultExcelExportParams={excelExportParams}
                            onGridReady={onGridReady}
                            onModelUpdated={onModelUpdated}
                        />
                    )
                    : (<Loader/>)
                }
            </Layout>

            {buttonContainer && createPortal(
                <CancelSaveButtons
                    onCancel={onCancel}
                    onSave={onTableSave}
                    isLoading={saveMutation.isPending}
                    isDisabled={!isTableDirty || !tableMeta.name}
                />,
                buttonContainer,
            )}

            {editable && createPortal(
                <SpacerInputPanel onAdd={onSpacerAdd}/>,
                spacerInputContainer,
            )}
        </>
    )
})
