import { DateRangePicker } from '@/components/base/DateRangePicker'
import { useInputState } from '@/hooks'

import { monthIndToDateNoTimezoneReset } from '../../../utils/date/monthInd'
import { QuarterString, quarterToDate } from '../../../utils/date/quarter'
import { MONTH_RANGE_END_QUERY_PARAM, MONTH_RANGE_START_QUERY_PARAM } from '../MonthRangeInput'
import { QUARTER_RANGE_END_QUERY_PARAM, QUARTER_RANGE_START_QUERY_PARAM } from '../QuarterRangeInput'

import { YEAR_RANGE_END_QUERY_PARAM, YEAR_RANGE_START_QUERY_PARAM } from './YearRangeInput.constants'
import { YearRangeInputProps } from './YearRangeInput.types'

export const YearRangeInput = (props: YearRangeInputProps) => {
    const { defaultStart, defaultEnd, maxDate, required, label, startInputParams = {}, endInputParams = {} } = props

    const [yearStartParam, yearSetStartParam] = useInputState({
        ...YEAR_RANGE_START_QUERY_PARAM,
        ...startInputParams,
    })
    const [yearEndParam, yearSetEndParam] = useInputState({
        ...YEAR_RANGE_END_QUERY_PARAM,
        ...endInputParams,
    })
    const [quarterStartParam] = useInputState({
        ...QUARTER_RANGE_START_QUERY_PARAM,
    })
    const [quarterEndParam] = useInputState({
        ...QUARTER_RANGE_END_QUERY_PARAM,
    })
    const [monthStartParam] = useInputState({
        ...MONTH_RANGE_START_QUERY_PARAM,
    })
    const [monthEndParam] = useInputState({
        ...MONTH_RANGE_END_QUERY_PARAM,
    })

    if (!yearStartParam && defaultStart) {
        yearSetStartParam(defaultStart)
    }

    if (!yearEndParam && defaultEnd) {
        yearSetEndParam(defaultEnd)
    }

    let dateFrom = yearStartParam ? new Date(yearStartParam, 0) : undefined
    let dateTo = yearEndParam ? new Date(yearEndParam, 0) : undefined

    if (!dateFrom && monthStartParam) {
        dateFrom = monthIndToDateNoTimezoneReset(monthStartParam)
        yearSetStartParam(dateFrom ? dateFrom.getFullYear() : undefined)
    }
    if (!dateTo && monthEndParam) {
        dateTo = monthIndToDateNoTimezoneReset(monthEndParam)
        yearSetEndParam(dateTo ? dateTo.getFullYear() : undefined)
    }
    if (!dateFrom && quarterStartParam) {
        dateFrom = quarterToDate(quarterStartParam as QuarterString)
        yearSetStartParam(dateFrom ? dateFrom.getFullYear() : undefined)
    }
    if (!dateTo && quarterEndParam) {
        dateTo = quarterToDate(quarterEndParam as QuarterString)
        yearSetEndParam(dateTo ? dateTo.getFullYear() : undefined)
    }

    return (
        <DateRangePicker
            type='year'
            label={label}
            dateFrom={dateFrom}
            dateTo={dateTo}
            maxDate={maxDate}
            onChange={({ dateFrom, dateTo }) => {
                yearSetStartParam(dateFrom ? dateFrom.getFullYear() : undefined)
                yearSetEndParam(dateTo ? dateTo.getFullYear() : undefined)
            }}
            error={required && (!dateFrom || !dateTo)}
        />
    )
}
