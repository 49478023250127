import { FC } from 'react'

import cn from 'classnames'
import format from 'date-fns/format'

import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router'

import { useAssetsQuery } from '@/api/asset/asset'
import { DataPointType } from '@/models/data_pulling'
import {
    getMonthDays,
    getYearMonths1st,
} from '@/pages/MainPage/CommonPages/ValidationTrackerPage'
import { isFeatureOn } from '@/utils/isFeatureOn'

import { ResultsTableItemData, ResultsTableProps } from './ResultsTable.types'

import styles from './ResultsTable.module.scss'

export const ResultsTable: FC<ResultsTableProps> = observer((props) => {
    const navigate = useNavigate()

    const assetQuery = useAssetsQuery()

    let days: Date[]
    if (props.periodType === 'daily') {
        days = getMonthDays(props.year, props.month)

        props.holidays?.forEach((holiday, i) => {
            const ind = new Date(holiday).getUTCDate() - 1 - i
            days.splice(ind, 1)
        })

        // Move trial balance to last day of month
        const month1stStr = format(new Date(props.year, props.month - 1, 1), 'yyyy-MM-dd')
        const monthLastStr = format(days[days.length - 1], 'yyyy-MM-dd')
        props.data.forEach((assetData) => {
            const dayData = assetData.dates[month1stStr] || {}
            if (DataPointType.TRIAL_BALANCE in dayData) {
                assetData.dates[monthLastStr] = assetData.dates[monthLastStr] || {}
                assetData.dates[monthLastStr][DataPointType.TRIAL_BALANCE] = dayData[DataPointType.TRIAL_BALANCE]
                // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
                delete dayData[DataPointType.TRIAL_BALANCE]
            }
        })
    } else {
        days = getYearMonths1st(props.year)
    }

    const checkFileType = (sf?: ResultsTableItemData) => {
        return props.fileType === 'all' || sf?.type === props.fileType
    }

    const checkStatusType = (sf?: ResultsTableItemData) => {
        return props.statusType === 'all' ||
            (props.statusType === 'error' && sf?.is_error) ||
            (props.statusType === 'alert' && sf?.is_done_with_alerts)
    }

    return (
        <>
            <div className={styles.results}>
                <table>
                    <thead>
                        <tr style={{ position: 'relative' }}>
                            <th className={styles.resultsAssetNameTitle}>Asset&nbsp;Name</th>
                            <th className={styles.resultsAssetNameTitle}>Property&nbsp;Manager</th>
                            {days.map((date, index) => (
                                <th key={index}>
                                    {props.periodType === 'monthly' && format(date, 'MMM')}
                                    {props.periodType === 'daily' && <>{format(date, 'dd')}<br/>{format(date, 'EEE')}</>}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        <tr/>
                        {props.data.map((asset_data, index) => {
                            const assetData = asset_data.dates
                            const propertyManager = assetQuery.data?.find(asset => asset_data.asset_id === asset.id)?.property_manager_company ?? '—'

                            return (
                                <tr key={index} data-testid='validation-tracker-data-row'>
                                    <td className={styles.resultsAssetName}>{asset_data.asset_name}</td>
                                    <td className={styles.resultsAssetName}>{propertyManager}</td>
                                    {days.map((date, dayIndex) => {
                                        const dayData = assetData[format(date, 'yyyy-MM-dd')] || {}
                                        const getClassNames = (sf?: ResultsTableItemData) => {
                                            const stylesList: [any] = [styles.emptyMark]
                                            stylesList.push({
                                                [styles.inProgressMark]: sf?.is_processing,
                                                [styles.doneMark]: sf?.is_done,
                                                [styles.emptyDatesMark]: sf?.is_empty_dates,
                                                [styles.doneWithAlertsMark]: sf?.is_done_with_alerts,
                                                [styles.problemMark]: sf?.is_error || sf?.is_done_with_submission_errors,
                                            })
                                            return stylesList
                                        }
                                        const navigateToDetails = (sf?: ResultsTableItemData) => {
                                            if (sf === undefined) {
                                                return
                                            }
                                            if (props.periodType === 'monthly' && sf.type === DataPointType.RENT_ROLL) {
                                                navigate(`rr/${asset_data.asset_id}/${date.getFullYear()}/${date.getMonth() + 1}?${props.searchString}`)
                                            } else {
                                                navigate(`${sf.data_point_id}?${props.searchString}`)
                                            }
                                        }
                                        const tbFile = dayData[DataPointType.TRIAL_BALANCE]
                                        const rrFile = dayData[DataPointType.RENT_ROLL]
                                        return (
                                            <td key={dayIndex} align='center'>
                                                <div className={styles.markContainer}>
                                                    {
                                                        date.getDate() === days[days.length - 1].getDate() &&
                                                        checkFileType(tbFile) &&
                                                        checkStatusType(tbFile) && (
                                                            <span
                                                                className={cn(getClassNames(tbFile))}
                                                                onClick={() => navigateToDetails(tbFile)}
                                                            >T</span>
                                                        )
                                                    }
                                                    {
                                                        isFeatureOn('rent_roll') &&
                                                        checkFileType(rrFile) &&
                                                        checkStatusType(rrFile) && (
                                                            <span
                                                                className={cn(getClassNames(rrFile))}
                                                                onClick={() => navigateToDetails(rrFile)}
                                                            >R</span>
                                                        )
                                                    }
                                                </div>
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                        {
                            props.data.length === 0 && (
                                <tr>
                                    <td colSpan={50} className={styles.noDataCell}>No data found</td>
                                </tr>
                            )}
                    </tbody>
                </table>
            </div>
        </>
    )
})
