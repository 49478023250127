import { ReportingPnLItem } from '@/api/reportingData/reportingProfitAndLoss'
import { getRequestQueries } from '@/api/utils/getRequestQueries'

// use the same schema as in reportingProfitAndLoss.ts
export type ReportingBalanceSheetItem = ReportingPnLItem

export const REPORTING_BALANCE_SHEET_URL = 'reporting/balance-sheet'

export const {
    useItemsQuery: useReportingBalanceSheetQuery,
} = getRequestQueries<ReportingBalanceSheetItem, Record<string, unknown>>({
    url: REPORTING_BALANCE_SHEET_URL,
    extractItemsFn: (data: ReportingBalanceSheetItem) => [data],
})
