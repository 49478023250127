/* eslint-disable @typescript-eslint/naming-convention */
export enum ReportScenario {
    ACTUAL = 'actual',
    ACTUAL_PSF = 'actual_psf', // or per unit
    BUDGET = 'budget',
    BUDGET_PSF = 'budget_psf', // or per unit
    VARIANCE = 'variance',
    VARIANCE_PSF = 'variance_psf', // or per unit
    VARIANCE_PERCENT = 'variance_percent',
    UNDERWRITING = 'underwriting',
    UNDERWRITING_PSF = 'underwriting_psf',
    VARIANCE_UNDERWRITING = 'variance_underwriting',
    VARIANCE_UNDERWRITING_PSF = 'variance_underwriting_psf',
    VARIANCE_UNDERWRITING_PERCENT = 'variance_underwriting_percent',
    VARIANCE_BUDGET_UNDERWRITING = 'variance_budget_underwriting',
    VARIANCE_BUDGET_UNDERWRITING_PSF = 'variance_budget_underwriting_psf',
    VARIANCE_BUDGET_UNDERWRITING_PERCENT = 'variance_budget_underwriting_percent',
}

export const getScenarioLabels = (measure: string): Record<ReportScenario, string> => {
    return ({
        [ReportScenario.ACTUAL]: 'Actual $',
        [ReportScenario.ACTUAL_PSF]: `Actual ${measure}`,
        [ReportScenario.BUDGET]: 'Budget $',
        [ReportScenario.BUDGET_PSF]: `Budget ${measure}`,
        [ReportScenario.VARIANCE]: 'Actual vs. Budget Variance $',
        [ReportScenario.VARIANCE_PSF]: `Actual vs. Budget Variance ${measure}`,
        [ReportScenario.VARIANCE_PERCENT]: 'Actual vs. Budget Variance %',
        [ReportScenario.UNDERWRITING]: 'Underwriting $',
        [ReportScenario.UNDERWRITING_PSF]: `Underwriting ${measure}`,
        [ReportScenario.VARIANCE_UNDERWRITING]: 'Actual vs. Underwriting $',
        [ReportScenario.VARIANCE_UNDERWRITING_PSF]: `Actual vs. Underwriting ${measure}`,
        [ReportScenario.VARIANCE_UNDERWRITING_PERCENT]: 'Actual vs. Underwriting %',
        [ReportScenario.VARIANCE_BUDGET_UNDERWRITING]: 'Budget vs. Underwriting $',
        [ReportScenario.VARIANCE_BUDGET_UNDERWRITING_PSF]: `Budget vs. Underwriting ${measure}`,
        [ReportScenario.VARIANCE_BUDGET_UNDERWRITING_PERCENT]: 'Budget vs. Underwriting %',
    })
}

/**
 * Balance Sheet Report Scenarios
 */
export enum BSReportScenario {
    ACTIVITY = 'actual',
    ENDING_BALANCE = 'closing_balance',
}

export const BALANCE_SHEET_SCENARIO_LABLES: Record<`${BSReportScenario}`, string> = {
    [BSReportScenario.ENDING_BALANCE]: 'No',
    [BSReportScenario.ACTIVITY]: 'Yes',
}
