import React, { useEffect, useMemo, useState } from 'react'

import { useQueryClient } from '@tanstack/react-query'
import { matchPath, Outlet, useNavigate, useParams } from 'react-router'
import { useLocation } from 'react-router-dom'

import {
    REPORTING_FOLDER_URL,
    ReportingFolderItem,
    useReportingFolderDeleteMutation,
    useReportingFolderQuery,
    useReportingFolderUpdateMutation,
} from '@/api/reportingData/reportingFolder'
import {
    REPORTING_REPORT_URL,
    ReportingReportItem,
    useReportingReportCreateMutation,
    useReportingReportDeleteMutation,
    useReportingReportQuery,
} from '@/api/reportingData/reportingReport'
import { Alert, Button, Confirm, FilesTree, Loader, Popup, TabsProps, TextInput } from '@/components/base'
import { Layout } from '@/components/containers'
import { SubMenu } from '@/components/widgets'
import { OWNER_ROUTES_CONFIG, RouterConfigKeys } from '@/core/routes'
import http from '@/http.service'
import { ExtendedFilesTreeItem, ReportTypes } from '@/pages/MainPage/UserPages/ReportsPage/ReportPage.types'
import {
    DEFAUILT_REPORT_NAME,
    DEFAULT_REPORT_FOLDER_NAME,
    getReportsHomeItem,
    getReportsTree,
} from '@/pages/MainPage/UserPages/ReportsPage/ReportsPage.constants'
import { useStandardReports } from '@/pages/MainPage/UserPages/ReportsPage/ReportsPage.hooks'
import { getIncrementalName } from '@/utils/getIncrementalName'
import { getRoute } from '@/utils/routing/getRoute'
import { getRouteConfig } from '@/utils/routing/getRouteConfig'

export const ReportsPage = () => {
    const navigate = useNavigate()
    const { id: customReportId } = useParams()

    const standardReports = useStandardReports()
    const [activeReportsList, setActiveReportsList] = useState<ExtendedFilesTreeItem[]>([])
    const [showDeleteFolderData, setShowDeleteFolderData] = useState<ExtendedFilesTreeItem | null>(null)
    const [showRenameFolderData, setShowRenameFolderData] = useState<ExtendedFilesTreeItem | null>(null)
    const [newFolderName, setNewFolderName] = useState<string>('')

    const location = useLocation()

    const [activeReportType, setActiveReportType] = useState<ReportTypes | undefined>(() => {
        const companyReportsPageMatch = Boolean(matchPath({
            path: OWNER_ROUTES_CONFIG.REPORTS_CUSTOM_COMPANY.path,
            end: true,
        }, location.pathname)) ||
            Boolean(matchPath({
                path: OWNER_ROUTES_CONFIG.REPORTS_CUSTOM_COMPANY_BY_ID.path,
                end: true,
            }, location.pathname))

        const myReportsPageMatch = Boolean(matchPath({
            path: OWNER_ROUTES_CONFIG.REPORTS_CUSTOM_MY.path,
            end: true,
        }, location.pathname)) ||
            Boolean(matchPath({
                path: OWNER_ROUTES_CONFIG.REPORTS_CUSTOM_MY_BY_ID.path,
                end: true,
            }, location.pathname))

        return companyReportsPageMatch ? 'company' : myReportsPageMatch ? 'my' : 'company'
    })

    const homeRouteConfig = activeReportType === 'my' ? OWNER_ROUTES_CONFIG.REPORTS_CUSTOM_MY : OWNER_ROUTES_CONFIG.REPORTS_CUSTOM_COMPANY

    const [reportForDelete, setReportForDelete] = useState<ExtendedFilesTreeItem | null>()

    const reportingReportQuery = useReportingReportQuery({
        queryParams: {
            is_private: activeReportType === 'my' ? true : undefined,
        },
    }, {
        enabled: activeReportType !== undefined,
    })
    const reportingFolderQuery = useReportingFolderQuery({
        queryParams: {
            is_private: activeReportType === 'my' ? true : undefined,
        },
    },
    {
        enabled: activeReportType !== undefined,
    })

    const reportingReportCreateMutation = useReportingReportCreateMutation()
    const reportingReportDeleteMutation = useReportingReportDeleteMutation()
    const reportingFolderDeleteMutation = useReportingFolderDeleteMutation()
    const reportingFolderUpdateMutation = useReportingFolderUpdateMutation()

    useEffect(() => {
        // Configure list of custom reports
        if (reportingReportQuery.data && reportingReportQuery.isFetched && reportingFolderQuery.isFetched) {
            const customTree: ExtendedFilesTreeItem[] = getReportsTree(reportingFolderQuery.data!, reportingReportQuery.data, null, activeReportType)

            const homeIcon = getReportsHomeItem(activeReportType || 'company')

            if (activeReportType === 'company') {
                // Apply isStandardReport flag to standard reports and their parent folders
                const standardReportsWithSpecialProps = standardReports.map(item => {
                    const folder = {
                        ...item,
                        hideContextMenu: true, // Prevent actions on standard reports
                        isStandardReport: true, // Flag to identify standard reports
                    }

                    if (folder.children) {
                        folder.children = folder.children.map(child => ({
                            ...child,
                            hideContextMenu: true,
                            isStandardReport: true,
                        }))
                    }

                    return folder
                })

                setActiveReportsList([homeIcon, ...standardReportsWithSpecialProps, ...customTree])
            } else {
                setActiveReportsList([homeIcon, ...customTree])
            }
        }
    }, [
        reportingReportQuery.isFetched,
        reportingFolderQuery.isFetched,
        activeReportType,
        reportingReportQuery.data,
        reportingFolderQuery.data,
    ])

    const isRootPath = useMemo(() => (
        Boolean(matchPath({
            path: location.pathname,
            end: true,
        }, OWNER_ROUTES_CONFIG.REPORTS.path))
    ), [location.pathname])

    useEffect(() => {
        if (isRootPath) {
            navigate(homeRouteConfig.path)
        }
    }, [location.pathname, activeReportType])

    const handleTreeItemClick = (item: ExtendedFilesTreeItem) => {
        item.path && (navigate(item.path))
    }

    const handleReportTypeChange = (key: ReportTypes) => {
        setActiveReportType(key)

        const pathByType: Record<ReportTypes, RouterConfigKeys> = {
            standard: 'REPORTS_CUSTOM_COMPANY',
            company: 'REPORTS_CUSTOM_COMPANY',
            my: 'REPORTS_CUSTOM_MY',
        }

        navigate(getRouteConfig(pathByType[key]).path)
    }

    const queryClient = useQueryClient()

    const handleCreateReport = async (parentItem?: ExtendedFilesTreeItem | null) => {
        const newReportName = getIncrementalName(DEFAUILT_REPORT_NAME, reportingReportQuery.data!.map((report) => report.name))

        const newItemReponse = await http.post!<ReportingReportItem>(
            REPORTING_REPORT_URL + '/',
            {
                name: newReportName,
                parent_id: parentItem?.id ? Number(parentItem?.id) : undefined,
            },
            {
                params: {
                    is_private: activeReportType === 'my' ? true : undefined,
                },
            },
        )
        const newItem: ReportingReportItem = newItemReponse.data

        queryClient.invalidateQueries({ queryKey: [REPORTING_REPORT_URL] })

        const link = getRoute(activeReportType === 'company' ? 'REPORTS_CUSTOM_COMPANY_BY_ID' : 'REPORTS_CUSTOM_MY_BY_ID', { id: newItem.id })
        navigate(link)
    }

    const handleCreateFolder = async (parentItem: ExtendedFilesTreeItem | null): Promise<ReportingFolderItem> => {
        const name = getIncrementalName(DEFAULT_REPORT_FOLDER_NAME, reportingFolderQuery.data?.map((report) => report.name) ?? [])

        const newItemReponse = await http.post!<ReportingFolderItem>(
            REPORTING_FOLDER_URL + '/',
            {
                name,
                parent_id: parentItem?.id ? Number(parentItem.id) : undefined,
            },
            {
                params: {
                    is_private: activeReportType === 'my' ? true : undefined,
                },
            },
        )
        const newItem: ReportingFolderItem = newItemReponse.data

        queryClient.invalidateQueries({ queryKey: [REPORTING_FOLDER_URL] })

        return newItem
    }

    const handleDeleteReport = async () => {
        if (!reportForDelete) {
            return
        }

        // Extracting ID here with matchPath
        const matchedPath = matchPath(
            activeReportType === 'my'
                ? OWNER_ROUTES_CONFIG.REPORTS_CUSTOM_MY_BY_ID.path : OWNER_ROUTES_CONFIG.REPORTS_CUSTOM_COMPANY_BY_ID.path,
            reportForDelete.path?.toString() || '',
        )
        const id = matchedPath?.params.id

        if (id) {
            await reportingReportDeleteMutation.mutateAsync({
                id,
                config: {
                    queryParams: {
                        is_private: activeReportType === 'my' ? true : undefined,
                    },
                },
            })
        }

        if (customReportId === id) {
            navigate(OWNER_ROUTES_CONFIG.REPORTS.path)
        }

        setReportForDelete(null)
    }

    const handleDeleteFolder = async () => {
        if (!showDeleteFolderData) {
            return
        }

        const reportsInFolder = reportingReportQuery.data?.filter((report) => report.parent_id === showDeleteFolderData.id)

        for (const report of reportsInFolder || []) {
            await reportingReportDeleteMutation.mutateAsync({
                id: report.id,
            })
        }

        const foldersInFolder = reportingFolderQuery.data?.filter((folder) => folder.parent_id === showDeleteFolderData.id)

        for (const folder of foldersInFolder || []) {
            await reportingFolderDeleteMutation.mutateAsync({
                id: folder.id,
            })
        }

        const isUserOnDeletedReport = reportsInFolder?.some((report) => report.id.toString() === customReportId)
        if (isUserOnDeletedReport) {
            navigate(OWNER_ROUTES_CONFIG.REPORTS.path)
        }

        await reportingFolderDeleteMutation.mutateAsync({
            id: showDeleteFolderData.id,
        })

        setShowDeleteFolderData(null)
    }

    const handleRenameFolder = async () => {
        if (!showRenameFolderData) {
            return
        }

        await reportingFolderUpdateMutation.mutateAsync({
            id: Number(showRenameFolderData.id),
            updatedItemData: {
                name: newFolderName,
            },
        })

        setShowRenameFolderData(null)
    }

    const navItems: TabsProps['options'] = useMemo(() => {
        const res = new Map<ReportTypes, string>([])

        res.set('company', OWNER_ROUTES_CONFIG.REPORTS_CUSTOM_COMPANY.title)
        res.set('my', OWNER_ROUTES_CONFIG.REPORTS_CUSTOM_MY.title)

        return res
    }, [])

    const isCustomReportsLoading = !reportingReportQuery.isFetched || !reportingFolderQuery.isFetched

    // Remove home button
    const haveReports = !!activeReportsList.filter(el => el.path !== homeRouteConfig.path).length

    const MAX_FOLDER_LEVEL = 2

    return (
        <>
            <SubMenu
                onChangeTab={handleReportTypeChange}
                navItems={navItems}
                activeNavItem={activeReportType}
                headerButtonActions={[
                    {
                        label: 'Create Folder',
                        onClick: () => handleCreateFolder(null),
                    },
                    {
                        label: 'Create Report',
                        onClick: () => handleCreateReport(),
                    },
                ]}
            >
                <FilesTree
                    onItemClick={handleTreeItemClick}
                    items={activeReportsList}
                    isItemActive={(item) => {
                        if (!item.path) return false
                        const matchedPath = matchPath(
                            {
                                path: item.path,
                                end: item.strictUrlMatch || false,
                            },
                            location.pathname)
                        return Boolean(matchedPath)
                    }}
                    defaultFoldersState
                    getFolderActions={(level) => ([
                        {
                            label: 'Create Folder',
                            onClick: handleCreateFolder,
                            hide: level > MAX_FOLDER_LEVEL - 1,
                        },
                        {
                            label: 'Create Report',
                            onClick: (item) => handleCreateReport(item),
                            hide: level > MAX_FOLDER_LEVEL,
                        },
                        {
                            label: 'Rename Folder',
                            onClick: (item) => {
                                setNewFolderName(item.name) // Set initial value
                                setShowRenameFolderData(item)
                            },
                        },
                        {
                            label: 'Delete Folder',
                            onClick: (item) => setShowDeleteFolderData(item),
                        },
                    ])
                    }
                    fileActions={[
                        {
                            label: 'Delete',
                            onClick: (item) => {
                                setReportForDelete(item as ExtendedFilesTreeItem)
                            },
                        },
                    ]}
                />

                {(!haveReports) && (
                    <Layout justify='center' mt={12} width='100%'>
                        {(!isCustomReportsLoading) ? (
                            <Button
                                text='Create a New Report'
                                theme='secondary'
                                icon='plus'
                                loading={reportingReportCreateMutation.isPending}
                                onClick={() => handleCreateReport(null)}
                            />
                        ) : (
                            <Loader centered/>
                        )}
                    </Layout>
                )}
            </SubMenu>
            {Boolean(showDeleteFolderData) && (
                <Confirm
                    confirmText='Delete Folder'
                    isLoading={reportingFolderDeleteMutation.isPending || reportingReportDeleteMutation.isPending || reportingFolderDeleteMutation.isPending}
                    onConfirm={handleDeleteFolder}
                    onCancel={() => setShowDeleteFolderData(null)}
                >
                    <Alert type='warning'>
                        Are you sure you want to delete this folder and all the reports inside it?
                    </Alert>
                </Confirm>
            )}
            {Boolean(reportForDelete) && (
                <Confirm
                    confirmText='Delete Report'
                    isLoading={reportingReportDeleteMutation.isPending}
                    onConfirm={handleDeleteReport}
                    onCancel={() => setReportForDelete(null)}
                >
                    <Alert type='warning'>
                        Are you sure you want to delete this report?
                    </Alert>
                </Confirm>
            )}
            {Boolean(showRenameFolderData) && (
                <Popup
                    confirmText='Rename Folder'
                    isLoading={reportingFolderUpdateMutation.isPending}
                    onConfirm={handleRenameFolder}
                    onClose={() => setShowRenameFolderData(null)}
                    content={(
                        <Layout mb={32}>
                            <TextInput
                                label='Folder name'
                                placeholder='Enter new folder name'
                                value={newFolderName}
                                onChange={(e) => {
                                    setNewFolderName(e.target.value)
                                }}
                            />
                        </Layout>
                    )}
                />
            )}
            <Outlet/>
        </>
    )
}
