import { useMemo } from 'react'

import format from 'date-fns/format'
import { Query } from 'mobx-orm'

import { observer } from 'mobx-react-lite'
import { StringParam } from 'use-query-params'

import { Filters, FiltersInput, LegacySelectItemData } from '@/components/base'
import { SelectInput } from '@/components/baseInputs'
import { Layout } from '@/components/containers'
import { DEFAULT_FILTER_MAX_WIDTH } from '@/constants/filters'
import { useMe } from '@/hooks/core/useMe'
import { useManagerOptions } from '@/hooks/query/useManagerOptions'
import { useQueryParam } from '@/hooks/useQueryParams'
import { Company } from '@/models/company'
import { DataPointType } from '@/models/data_pulling'
import {
    getMonthsFromBeginning,
    getYearsFromBeginning,
    ValidationTrackerFiltersState,
} from '@/pages/MainPage/CommonPages/ValidationTrackerPage'

import { ValidationTrackerFiltersProps } from './ValidationTrackerFilters.types'

export const ValidationTrackerFilters = observer((props: ValidationTrackerFiltersProps) => {
    const { assetQuery } = props

    const { me } = useMe()
    const companyQuery = useMemo(() => Company.getQuery() as Query<Company>, [])

    const [params, updateParam] = useQueryParam<ValidationTrackerFiltersState>()

    // @ts-expect-error TS(2345) FIXME: Argument of type '() => { label: string | undefine... Remove this comment to see the full error message
    const companyOptions = useMemo<LegacySelectItemData[]>(() => {
        const mainList = companyQuery.items.map((item) => (
            {
                label: item.name,
                // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
                value: item.id.toString(),
            }
        ))

        return [{
            label: 'All Companies',
            value: '0',
        }, ...mainList]
    }, [companyQuery.items])

    // @ts-expect-error TS(2345) FIXME: Argument of type '() => { label: string | undefine... Remove this comment to see the full error message
    const assetOptions = useMemo<LegacySelectItemData[]>(() => {
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        const mainList = assetQuery.items.map((item) => (
            {
                label: item.name,
                // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
                value: item.id.toString(),
            }
        ))

        return [{
            label: 'All Assets',
            value: '0',
        }, ...mainList]
    }, [assetQuery?.items])

    const requiredInputsConfig: FiltersInput[] = [
        {
            type: 'select',
            name: 'companyId',
            hidden: !!me.company.id,
            props: {
                labelInside: true,
                label: 'Company',
                placeholder: 'Company',
                customValue: params.companyId?.toString(),
                options: companyOptions,
                onChange: () => {
                    updateParam({
                        key: 'assetId',
                        val: '0',
                    })
                },
            },
        },
        {
            type: 'select',
            name: 'assetId',
            props: {
                labelInside: true,
                label: 'Asset',
                placeholder: 'Asset',
                customValue: params.assetId?.toString(),
                options: assetOptions,
            },
        },
    ]

    const fileTypeOptions = useMemo<LegacySelectItemData[]>(() => (
        [
            {
                label: 'All',
                value: 'all',
            },
            {
                label: 'Trial Balance',
                value: DataPointType.TRIAL_BALANCE,
            },
            {
                label: 'Rent Roll',
                value: DataPointType.RENT_ROLL,
            },
        ]
    ), [])

    const statusOptions = useMemo<LegacySelectItemData[]>(() => (
        [
            {
                label: 'All',
                value: 'all',
            },
            {
                label: 'Errors',
                value: 'error',
            },
            {
                label: 'Missing mapping',
                value: '1001',
            },
            {
                label: 'Opening balance is not equal to prior closing balance',
                value: '217',
            },
            {
                label: 'Opening balance does not sum to zero',
                value: '215',
            },
            {
                label: 'Closing balance does not sum to zero',
                value: '216',
            },
        ]
    ), [])

    const managersOptions = useManagerOptions()

    const filtersConfig: FiltersInput[] = [
        {
            type: 'select',
            name: 'fileType',
            props: {
                label: 'Report Type',
                placeholder: 'Report Type',
                customValue: params.fileType?.toString(),
                options: fileTypeOptions,
            },
        },
        {
            type: 'buttonsGroup',
            name: 'periodType',
            props: {
                label: 'Interval',
                options: [
                    {
                        title: 'Daily',
                        key: 'daily',
                    },
                    {
                        title: 'Monthly',
                        key: 'monthly',
                    },
                ],
                selected: params.periodType,
            },
        },
        {
            type: 'select',
            name: 'currentYear',
            hidden: params.periodType !== 'monthly',
            props: {
                label: 'Period',
                placeholder: 'Year',
                customValue: params.currentYear?.toString(),
                options: getYearsFromBeginning().reverse().map((date) => (
                    {
                        value: date.getFullYear().toString(),
                        label: date.getFullYear().toString(),
                    }
                )),
            },
        },
        {
            type: 'select',
            hidden: params.periodType !== 'daily',
            props: {
                label: 'Period',
                placeholder: 'Month',
                customValue: `${params.currentYear}-${params.currentMonth}`,
                options: getMonthsFromBeginning().map((date: Date) => (
                    {
                        value: `${date.getFullYear()}-${date.getMonth() + 1}`,
                        label: format(date, 'MMMM yyyy'),
                    }
                )),
                multiSelect: false,
                onChange: (option: { label: string, value: string }) => {
                    updateParam(
                        {
                            key: 'currentMonth',
                            val: Number(option.value.split('-')[1]),
                        },
                        {
                            key: 'currentYear',
                            val: Number(option.value.split('-')[0]),
                        },
                    )
                },
            },
        },
        {
            type: 'select',
            props: {
                label: 'Status',
                placeholder: 'Status',
                customValue: params.errorCode === '0' ? params.statusType : params.errorCode,
                options: statusOptions,
                onChange: (option: { label: string, value: string }) => {
                    const intValue = Number(option.value)
                    const isErrorCodeSelected = !isNaN(intValue)
                    updateParam(
                        {
                            key: 'statusType',
                            val: !isErrorCodeSelected ? option.value : 'all',
                        },
                        {
                            key: 'errorCode',
                            val: isErrorCodeSelected ? intValue : 0,
                        },
                    )
                },
            },
        },
    ]

    return (
        <>
            <Filters
                inputs={requiredInputsConfig}
                required
            />
            <Layout gap={8}>
                <Filters
                    inputs={filtersConfig}
                    hideTitle
                />
                <SelectInput
                    label='Manager'
                    paramName='propertyManager'
                    paramType={StringParam}
                    width={DEFAULT_FILTER_MAX_WIDTH}
                    options={managersOptions}
                    placeholder='Manager'
                    unselectOnClick
                />
            </Layout>
        </>
    )
})
