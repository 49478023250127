import { useEffect, useState } from 'react'

import { Module } from '@ag-grid-community/core'

export const useTableToolPanelModules = () => {
    const [toolPanelModules, setToolPanelModules] = useState<Promise<Module[]>>(new Promise(() => []))

    useEffect(() => {
        (async () => {
            const modules = Promise.all([
                // tool panel is required for the table builder
                (await import('@ag-grid-enterprise/column-tool-panel')).ColumnsToolPanelModule,
                (await import('@ag-grid-enterprise/filter-tool-panel')).FiltersToolPanelModule,
            ])
            setToolPanelModules(modules)
        })()
    }, [])

    return toolPanelModules
}
