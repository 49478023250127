import { forwardRef, RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { GridState } from '@ag-grid-community/core'
import isEqual from 'lodash/isEqual'
import { AND, EQ } from 'mobx-orm'
import { createPortal } from 'react-dom'

import { AgGridReact } from '@ag-grid-community/react'
import { useNavigate } from 'react-router'

import { useAssetsQuery } from '@/api/asset/asset'
import { useReportingPortfolioSelectedValueQuery } from '@/api/reportingData/reportingPortfolioSelectedValue'
import { ReportingTableFilters, useReportingTableByIdQuery } from '@/api/reportingTable/reportingTable'
import { Loader } from '@/components/base'
import {
    MONTH_INPUT_QUERY_PARAM,
    MONTH_RANGE_END_QUERY_PARAM,
    MONTH_RANGE_START_QUERY_PARAM,
    QUARTER_INPUT_QUERY_PARAM,
    TIME_COMPARISON_INPUT_QUERY_PARAM,
    TIME_INTERVAL_INPUT_QUERY_PARAM,
    TIME_SETTINGS_INPUT_QUERY_PARAM,
    YEAR_INPUT_QUERY_PARAM,
    YEAR_RANGE_END_QUERY_PARAM,
    YEAR_RANGE_START_QUERY_PARAM,
} from '@/components/baseInputs'
import { QUARTER_RANGE_END_QUERY_PARAM, QUARTER_RANGE_START_QUERY_PARAM } from '@/components/baseInputs/QuarterRangeInput'
import { Layout } from '@/components/containers'
import { ASSET_SIDE_MODAL_INPUT_QUERY_PARAM } from '@/components/models/asset'
import { COMPANY_SELECT_INPUT_QUERY_PARAM } from '@/components/models/company'
import { AgGridTable } from '@/components/tables'
import { useTableToolPanelModules } from '@/components/tables/utils/useTableToolPanelModules'
import {
    GLOBAL_REPORT_MONTH_QUERY_PARAM,
    GLOBAL_REPORT_MONTH_RANGE_END_QUERY_PARAM,
    GLOBAL_REPORT_MONTH_RANGE_START_QUERY_PARAM,
    GLOBAL_REPORT_MULTY_ASSET_QUERY_PARAM,
} from '@/components/widgets/reports/reports.constants'
import { ReportingDataValueType } from '@/constants/reportingDataValueTypes'
import { TimeSettings } from '@/constants/timeSettings'
import { useInputState } from '@/hooks'
import { useMe } from '@/hooks/core/useMe'
import {
    getMetricInputQueryParam,
    getReportTableTbLedgerInputQueryParam,
    TABLE_ASSET_TYPE_PARAM,
    TABLE_RR_LEDGER_INPUT_QUERY_PARAM,
    TABLE_TB_LEDGER_INPUT_QUERY_PARAM,
    getReportTableAssetTypeParam,
    getReportTableRrLedgerInputQueryParam,
    getReportQuerterInputQueryParam,
    getReportQuarterRangeStartQueryParam,
    getQuarterRangeEndQueryParam,
    getReportYearInputQueryParam,
    getReportYearRangeStartQueryParam,
    getReportYearRangeEndQueryParam,
    getReportTimeSettingsInputQueryParam,
    getReportTimeIntervalInputQueryParam,
    getReportTimeComparisonInputQueryParam,
} from '@/pages/TablesPage/TableBuilderPage/TableBuilderPage.constants'
import { TableType } from '@/pages/TablesPage/tableType'
import { getRoute } from '@/utils/routing/getRoute'

import { NoDataMessage } from '../../NoDataMessage'
import { CancelSaveButtons } from '../../TableBuilder/CancelSaveButtons'
import { METRIC_INPUT_QUERY_PARAM } from '../../TableBuilderFilters'
import { TableBuilderTableProps } from '../TableBuilderTables.types'
import {
    getGridPropsToSave,
    getTimePeriodAsString,
    saveGridStateToRef,
    saveReportingTable,
} from '../TableBuilderTables.utils'
import { useReportingTableSaveMutation } from '../useReportingTableSaveMutation'

import {
    getPortfolioSelectedValueColDefs,
    getPortfolioSelectedValueTableExportMeta,
    reportingPSVTableGridOptions as gridOptions,
} from './PortfolioSelectedValueTable.constants'
import { getTimePeriodParamForSettings } from './PortfolioSelectedValueTable.utils'

import styles from '../../TableBuilder/TableBuilder.module.scss'

/**
 * Portfolio - Asset Rows table
 */
export const PortfolioSelectedValueTable = forwardRef((props: TableBuilderTableProps, ref: RefObject<AgGridReact>) => {
    const {
        tableMeta = {},
        buttonContainer,
        onCancel,
        onGridReady,
        id,
        editable = false,
        insideReport = false,
        onFirstDataRendered,
        tablesListRouteConfigKey,
        builderRouteConfigKey,
    } = props

    const { me } = useMe()
    const navigate = useNavigate()
    const isCreationMode = id === 'new'
    const fallbackTableRef = useRef<AgGridReact>(null)
    const tableRef = ref ?? fallbackTableRef
    const gridStateRef = useRef<GridState>() // Retain grid state between renders
    const toolPanelModules = useTableToolPanelModules()

    const [isTableDirty, setIsTableDirty] = useState(false)
    const saveMutation = useReportingTableSaveMutation()
    const { data: loadedTableData } = useReportingTableByIdQuery(id, { enabled: !isCreationMode })

    // load grid state from table data once
    if (!gridStateRef.current && loadedTableData?.aggrid_state) { gridStateRef.current = loadedTableData.aggrid_state }

    const [companyId] = useInputState(COMPANY_SELECT_INPUT_QUERY_PARAM)

    const [tBLedgerId] = useInputState(
        insideReport ? getReportTableTbLedgerInputQueryParam(id) : TABLE_TB_LEDGER_INPUT_QUERY_PARAM,
    )
    const [rRLedgerId] = useInputState(
        insideReport ? getReportTableRrLedgerInputQueryParam(id) : TABLE_RR_LEDGER_INPUT_QUERY_PARAM,
    )
    const [assetType] = useInputState(
        insideReport ? getReportTableAssetTypeParam(id) : TABLE_ASSET_TYPE_PARAM,
    )

    const [assetIds, setAssetIds] = useInputState(
        insideReport ? GLOBAL_REPORT_MULTY_ASSET_QUERY_PARAM : ASSET_SIDE_MODAL_INPUT_QUERY_PARAM,
    )
    const [month, setMonth] = useInputState(
        insideReport ? GLOBAL_REPORT_MONTH_QUERY_PARAM : MONTH_INPUT_QUERY_PARAM,
    )

    const [metricId, setMetricId] = useInputState(
        insideReport ? getMetricInputQueryParam(id) : METRIC_INPUT_QUERY_PARAM,
    )
    const [startMonth, setStartMonth] = useInputState(
        insideReport ? GLOBAL_REPORT_MONTH_RANGE_START_QUERY_PARAM : MONTH_RANGE_START_QUERY_PARAM,
    )
    const [endMonth, setEndMonth] = useInputState(
        insideReport ? GLOBAL_REPORT_MONTH_RANGE_END_QUERY_PARAM : MONTH_RANGE_END_QUERY_PARAM,
    )
    const [quarter, setQuarter] = useInputState(
        insideReport ? getReportQuerterInputQueryParam(id) : QUARTER_INPUT_QUERY_PARAM,
    )
    const [startQuarter, setStartQuarter] = useInputState(
        insideReport ? getReportQuarterRangeStartQueryParam(id) : QUARTER_RANGE_START_QUERY_PARAM,
    )
    const [endQuarter, setEndQuarter] = useInputState(
        insideReport ? getQuarterRangeEndQueryParam(id) : QUARTER_RANGE_END_QUERY_PARAM,
    )

    const [year, setYear] = useInputState(
        insideReport ? getReportYearInputQueryParam(id) : YEAR_INPUT_QUERY_PARAM,
    )
    const [startYear, setStartYear] = useInputState(
        insideReport ? getReportYearRangeStartQueryParam(id) : YEAR_RANGE_START_QUERY_PARAM,
    )
    const [endYear, setEndYear] = useInputState(
        insideReport ? getReportYearRangeEndQueryParam(id) : YEAR_RANGE_END_QUERY_PARAM,
    )

    const [timeSettings, setTimeSettings] = useInputState(
        insideReport ? getReportTimeSettingsInputQueryParam(id) : TIME_SETTINGS_INPUT_QUERY_PARAM,
    )
    const [timeInterval, setTimeInterval] = useInputState(
        insideReport ? getReportTimeIntervalInputQueryParam(id) : TIME_INTERVAL_INPUT_QUERY_PARAM,
    )
    const [timeComparison, setTimeComparison] = useInputState(
        insideReport ? getReportTimeComparisonInputQueryParam(id) : TIME_COMPARISON_INPUT_QUERY_PARAM,
    )

    const isTimeIntervalRequired = timeSettings === TimeSettings.SINGLE || timeSettings === TimeSettings.SERIES
    const isTimeIntervalPresent = isTimeIntervalRequired && timeInterval

    const timePeriod = useMemo(() => getTimePeriodParamForSettings(
        {
            timeSettings,
            timeInterval,
            month,
            quarter,
            year,
            startMonth,
            endMonth,
            startQuarter,
            endQuarter,
            startYear,
            endYear,
        },
    ), [endMonth, endQuarter, endYear, isTimeIntervalRequired, month, quarter, startMonth, startQuarter, startYear, timeInterval, timeSettings, year])

    const areFiltersSet = Boolean(assetIds && metricId && timeSettings && timePeriod && (isTimeIntervalPresent || timeComparison))

    const { data: portfolioData, isLoading, isFetched, isSuccess } = useReportingPortfolioSelectedValueQuery(
        {
            filter: AND(
                EQ('asset_ids', assetIds),
                EQ('trial_balance_ledger_id', tBLedgerId),
                EQ('rent_roll_ledger_id', rRLedgerId),
                EQ('metric_id', metricId),
                EQ('time_settings', timeSettings),
                EQ('time_period', timePeriod),
                EQ(isTimeIntervalPresent ? 'time_interval' : 'time_comparison', isTimeIntervalPresent ? timeInterval : timeComparison),
            ),
        },
        { enabled: areFiltersSet })

    const [items, columns, subheaders, valueType] = useMemo(() => [
        portfolioData?.[0]?.rows ?? [],
        portfolioData?.[0]?.columns ?? [],
        portfolioData?.[0]?.subheader ?? [],
        portfolioData?.[0]?.value_type ?? ReportingDataValueType.MONEY,
    ], [portfolioData])

    const columnDefs = useMemo(() => getPortfolioSelectedValueColDefs({
        columns,
        subheaders,
        valueType,
    }), [columns, subheaders, valueType])

    const { data: assets } = useAssetsQuery({ filter: EQ('company_id', me.isAdministratorMode ? companyId : undefined) })
    const firstSelectedAsset = assets?.find((asset) => asset.id === assetIds?.[0])

    // table config
    const config = useMemo(() => {
        const filters: ReportingTableFilters = { asset_ids: assetIds as number[] }
        if (metricId) { filters.metricId = metricId }
        if (month) { filters.month = month }
        if (startMonth) { filters.startMonth = startMonth }
        if (endMonth) { filters.endMonth = endMonth }
        if (quarter) { filters.quarter = quarter }
        if (startQuarter) { filters.startQuarter = startQuarter }
        if (endQuarter) { filters.endQuarter = endQuarter }
        if (year) { filters.year = year }
        if (startYear) { filters.startYear = startYear }
        if (endYear) { filters.endYear = endYear }
        if (timeSettings) { filters.timeSettings = timeSettings }
        if (timeInterval) { filters.timeInterval = timeInterval }
        if (timeComparison) { filters.timeComparison = timeComparison }

        return ({ filters })
    }, [assetIds, endMonth, endQuarter, endYear, metricId, month, quarter, startMonth, startQuarter, startYear, timeComparison, timeInterval, timeSettings, year])

    const checkIfTableIsDirty = useCallback(() => {
        const loadedState = loadedTableData?.aggrid_state ?? {}
        const currentState = getGridPropsToSave(tableRef.current?.api?.getState())
        const currentConfig = config
        const loadedConfig = loadedTableData?.config ?? {}
        const currentName = tableMeta.name
        const loadedName = loadedTableData?.name

        if (
            isEqual(currentConfig, loadedConfig) &&
            isEqual(currentState, loadedState) &&
            currentName === loadedName
        ) {
            setIsTableDirty(false)
        } else {
            setIsTableDirty(true)
        }
    }, [config, loadedTableData?.aggrid_state, loadedTableData?.config, loadedTableData?.name, tableMeta.name, tableRef])

    useEffect(() => checkIfTableIsDirty(), [checkIfTableIsDirty])

    // set filters from loaded table data
    useEffect(() => {
        if (!loadedTableData) {
            return
        }

        const { filters } = loadedTableData.config

        if (filters) {
            if ((insideReport && !assetIds) || !insideReport) {
                filters.asset_ids && setAssetIds(filters.asset_ids)
            }
            if ((insideReport && !month) || !insideReport) {
                filters.month && setMonth(filters.month)
            }
            filters.metricId && setMetricId(filters.metricId)
            filters.startMonth && setStartMonth(filters.startMonth)
            filters.endMonth && setEndMonth(filters.endMonth)
            filters.quarter && setQuarter(filters.quarter)
            filters.startQuarter && setStartQuarter(filters.startQuarter)
            filters.endQuarter && setEndQuarter(filters.endQuarter)
            filters.year && setYear(filters.year)
            filters.startYear && setStartYear(filters.startYear)
            filters.endYear && setEndYear(filters.endYear)
            filters.timeSettings && setTimeSettings(filters.timeSettings)
            filters.timeInterval && setTimeInterval(filters.timeInterval)
            filters.timeComparison && setTimeComparison(filters.timeComparison)
        }
    }, [loadedTableData, setAssetIds, setEndMonth, setEndQuarter, setEndYear, setMetricId, setMonth, setQuarter, setStartMonth, setStartQuarter, setStartYear, setTimeComparison, setTimeInterval, setTimeSettings, setYear])

    const onTableSave = useCallback(async () => {
        const currentState = tableRef.current?.api?.getState() ?? {}
        const response = await saveReportingTable({
            tableMeta,
            currentState,
            isCreationMode,
            saveMutation,
            id,
            tableType: TableType.PortfolioSelectedValue,
            config,
            assetType,
        })

        if (!response) {
            return
        }

        // redirect after saving
        if (isCreationMode) {
            navigate(getRoute(builderRouteConfigKey, { id: response.id }))
        } else {
            navigate(getRoute(tablesListRouteConfigKey))
        }
    }, [assetType, config, id, isCreationMode, navigate, saveMutation, tableMeta, tableRef])

    const excelExportParams = useMemo(() => {
        const periodAsString = getTimePeriodAsString(timeSettings, timeInterval, timePeriod)
        return ({
            fileName: `Portfolio - Asset Rows - ${assetIds?.length === 1 ? `${firstSelectedAsset?.name} - ` : ''} ${periodAsString}.xlsx`,
            sheetName: periodAsString,
            prependContent: getPortfolioSelectedValueTableExportMeta(assets, assetIds, periodAsString),
        })
    }, [timeSettings, timeInterval, timePeriod, assetIds, firstSelectedAsset?.name, assets])

    const onModelUpdated = useCallback(() => {
        if (!isLoading && isFetched && isSuccess) {
            onFirstDataRendered()
        }
    }, [isLoading, isFetched, isSuccess, onFirstDataRendered])

    return (
        <>
            <Layout gap={16} flexGrow={1} direction='column'>
                {areFiltersSet
                    ? columnDefs.length
                        ? (
                            <AgGridTable
                                className={styles.table}
                                ref={tableRef}
                                items={items}
                                {...gridOptions}
                                columnDefs={columnDefs}
                                initialState={gridStateRef.current}
                                lazyModules={toolPanelModules}
                                hideStatusBar
                                sideBar={editable ? gridOptions.sideBar : false}
                                suppressMovableColumns={editable ? gridOptions.suppressMovableColumns : true}
                                onStateUpdated={(e) => {
                                    saveGridStateToRef(e, gridStateRef)
                                    checkIfTableIsDirty()
                                }}
                                context={{ editable }}
                                defaultExcelExportParams={excelExportParams}
                                onGridReady={onGridReady}
                                onModelUpdated={onModelUpdated}
                            />
                        )
                        : (<Loader/>)
                    : (<NoDataMessage text='Choose required fields'/>)}
            </Layout>

            {buttonContainer && createPortal(
                <CancelSaveButtons
                    onCancel={onCancel}
                    onSave={onTableSave}
                    isLoading={saveMutation.isPending}
                    isDisabled={!isTableDirty || !tableMeta.name}
                />,
                buttonContainer,
            )}
        </>
    )
})
