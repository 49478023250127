import { CellClassFunc, ColDef, ColGroupDef, HeaderClassParams } from '@ag-grid-community/core'

import { ExcelClasses } from '@/constants/reportTable'

export enum Feb25Classes {
    HeaderL0 = 'header-l0',
    HeaderL1 = 'header-l1',
    HeaderL2 = 'header-l2',
    HeaderHidden = 'header-hidden',
    RightAlignedHeader = 'ag-right-aligned-header', // standard ag-grid class
    RightAlignedCell = 'ag-right-aligned-cell', // standard ag-grid class
    RightBorder1 = 'feb25-right-border-1', // right border 1px
    HighlightTotalCell = 'feb25-highlight-total', // highlight the cell in group subtotals and grand total rows
    PurpleLeftBorder = 'feb25-left-border-purple', // purple left border
    PurpleBG = 'feb25-bg-purple50', // purple background
    GreyBG = 'feb25-bg-grey', // light grey background for marking a column
    WhiteBG = 'feb25-bg-white', // standard white background color and standard grey text color
    GreenCellHighlight = 'feb25-cell-green-highlight', // green cell highlight
    RedCellHighlight = 'feb25-cell-red-highlight', // red cell highlight
    None = '', // empty class
    IndentLeft = 'feb25-indent-left', // indent left
    ItalicRow = 'feb25-italic-row', // italic text
    SmallFontRow = 'feb25-small-font-row', // small font
    SeparatorRow = 'feb25-separator-row', // separator row
    GrandTotalRow = 'feb25-grand-total-row', // grand total row
    NoTopBorder = 'feb25-no-top-border', // no top border
    Clickable = 'feb25-clickable', // clickable cell
}

export type Feb25HeaderClass<TData = any, TValue = any> = `${Feb25Classes | ExcelClasses}` | Array<`${Feb25Classes | ExcelClasses}`> | ((params: HeaderClassParams<TData, TValue>) => `${Feb25Classes}` | Array<`${Feb25Classes}`> | undefined)

export type Feb25CellClass<TData = any, TValue = any> = `${Feb25Classes | ExcelClasses}` | Array<`${Feb25Classes | ExcelClasses}`> | CellClassFunc<TData, TValue>

export interface Feb25ColDef extends Omit<ColDef, 'headerClass' | 'cellClass'> {
    headerClass?: Feb25HeaderClass
    cellClass?: Feb25CellClass
    fractionDigits?: number
}

export interface Feb25ColGroupDef extends Omit<ColGroupDef, 'headerClass' | 'cellClass'> {
    headerClass?: Feb25HeaderClass
    cellClass?: Feb25CellClass
}
