import { getRequestQueries } from '@/api/utils/getRequestQueries'

export interface ReportingBalanceSheetMetricsItem {
    id: string
    name: string
    is_default?: boolean
}

export const REPORTING_BALANCE_SHEET_METRICS_URL = 'reporting/balance-sheet/metrics'

export const {
    useItemsQuery: useReportingBalanceSheetMetricsQuery,
} = getRequestQueries<ReportingBalanceSheetMetricsItem, Record<string, unknown>>({
    url: REPORTING_BALANCE_SHEET_METRICS_URL,
})
