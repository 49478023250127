import { useState } from 'react'

import cn from 'classnames'

import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router'

import { Button, FormBox, Text, Label } from '@/components/base'
import { ObjectFormLegacy } from '@/components/legacy/models/ObjectFormLegacy'
import { CompanyUser } from '@/models/company'
import { resetUserPassword } from '@/pages/MainPage/UserPages/CompanyProfilePage/CompanyProfilePage.utils'

import { CompanyUserPageProps } from './CompanyUserPage.types'

import styles from './CompanyUserPage.module.scss'

export const CompanyUserPage = observer((props: CompanyUserPageProps) => {
    const { className } = props
    const { userId } = useParams()

    const [resendPasswordButtonDisabled, setResendPasswordButtonDisabled] = useState(false)

    const companyUserObj = CompanyUser.get(Number(userId)) as (CompanyUser | undefined)

    if (!companyUserObj) {
        console.error('Can\'t get Model object')
        return null
    }

    const handleResendPassword = () => {
        resetUserPassword({
            onRequest: () => { setResendPasswordButtonDisabled(true) },
            onResponse: () => { setResendPasswordButtonDisabled(false) },
            // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
            email: companyUserObj.email,
        })
    }

    const userName = [companyUserObj.first_name, companyUserObj.last_name].filter(el => el).join(' ')

    return (
        <div className={cn(styles.companyUserPage, className)}>
            <div className={styles.name}>
                <Text
                    text={userName}
                    variant='subheader'
                    color='secondaryBlack'
                />
                {
                    companyUserObj.is_active ? (
                        <Label color='green' text='Active'/>
                    ) : (
                        <Label text='Inactive' color='red'/>
                    )
                }
            </div>
            <FormBox
                title='Personal Info'
                body={(
                    <div>
                        <ObjectFormLegacy
                            hideButtons
                            obj={companyUserObj}
                            fields={new Map([
                                ['email', {
                                    type: 'string',
                                    title: 'Email',
                                    readonly: true,
                                }],
                                ['first_name', {
                                    type: 'string',
                                    title: 'First Name',
                                    readonly: true,
                                }],
                                ['last_name', {
                                    type: 'string',
                                    title: 'Second Name',
                                    readonly: true,
                                }],
                            ])}
                        />
                    </div>
                )}
            />
            <FormBox
                title='Password'
                body={(
                    <Button
                        disabled={resendPasswordButtonDisabled}
                        className='intelas-button highlighted'
                        theme='secondary'
                        text='Resend Password'
                        onClick={handleResendPassword}
                    />
                )}
            />
        </div>
    )
})
