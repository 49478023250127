import { forwardRef, RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { ExcelExportParams, GridState } from '@ag-grid-community/core'
import isEqual from 'lodash/isEqual'
import { AND, EQ } from 'mobx-orm'
import { createPortal } from 'react-dom'

import { AgGridReact } from '@ag-grid-community/react'
import { useNavigate } from 'react-router'

import { useReportingRentRollMetricsCommercialQuery } from '@/api/reportingData/reportingRentRollMetricsCommercial'
import { ReportingTableFilters, useReportingTableByIdQuery } from '@/api/reportingTable/reportingTable'
import { MONTH_INPUT_QUERY_PARAM } from '@/components/baseInputs'
import { Layout } from '@/components/containers'
import { ASSET_SIDE_MODAL_INPUT_QUERY_PARAM } from '@/components/models/asset'
import { AgGridTable } from '@/components/tables'
import { useTableRangeSelectionModules } from '@/components/tables/utils/useTableRangeSelectionModules'
import { GLOBAL_REPORT_MONTH_QUERY_PARAM, GLOBAL_REPORT_MULTY_ASSET_QUERY_PARAM } from '@/components/widgets/reports/reports.constants'
import { useInputState } from '@/hooks'
import { TableType } from '@/pages/TablesPage/tableType'
import { dateToMonthInd, monthIndToString } from '@/utils/date/monthInd'
import { getRoute } from '@/utils/routing/getRoute'

import { NoDataMessage } from '../../NoDataMessage'
import { CancelSaveButtons } from '../../TableBuilder/CancelSaveButtons'
import { GROUP_BY_RR_FIELD_INPUT_QUERY_PARAM } from '../../TableBuilderFilters'
import {
    getReportTableRrLedgerInputQueryParam,
    getReportTableAssetTypeParam,
    getReportGroupByRRFieldInputQueryParam,
    TABLE_RR_LEDGER_INPUT_QUERY_PARAM,
    TABLE_ASSET_TYPE_PARAM,
} from '../../TableBuilderPage.constants'
import { getContextMenuItems } from '../PnLTimeSeriesTable/PnLTimeSeriesTable.constants'
import { processRRTradeOutBasicTableTotalCells } from '../RRTradeOutBasicTable/RRTradeOutBasicTable.utils'
import { statusBar } from '../TableBuilderTables.constants'
import { TableBuilderTableProps } from '../TableBuilderTables.types'
import { getGridPropsToSave, saveGridStateToRef, saveReportingTable } from '../TableBuilderTables.utils'
import { useReportingTableSaveMutation } from '../useReportingTableSaveMutation'

import { getRRMCColDefs, rRMCTableGridOptions } from './RRMetricsCommTable.constants'
import { normalizePercentageValues } from './RRMetricsCommTable.utils'

/**
 * Rent Roll Metrics (Commercial) table
 */
export const RRMetricsCommercialTable = forwardRef((props: TableBuilderTableProps, ref: RefObject<AgGridReact>) => {
    const {
        tableMeta = {},
        buttonContainer,
        onCancel,
        onGridReady,
        id,
        insideReport = false,
        onFirstDataRendered,
        tablesListRouteConfigKey,
        builderRouteConfigKey,
    } = props

    const priorMonth = dateToMonthInd(new Date()) - 1
    const navigate = useNavigate()
    const rangeSelectionModules = useTableRangeSelectionModules()
    const isCreationMode = id === 'new'
    const fallbackTableRef = useRef<AgGridReact>(null)
    const tableRef = ref ?? fallbackTableRef
    const gridStateRef = useRef<GridState>() // Retain grid state between renders

    const [isTableDirty, setIsTableDirty] = useState(false)
    const saveMutation = useReportingTableSaveMutation()
    const { data: loadedTableData } = useReportingTableByIdQuery(id, { enabled: !isCreationMode })

    // load grid state once
    if (!gridStateRef.current && loadedTableData?.aggrid_state) { gridStateRef.current = loadedTableData.aggrid_state }

    const [rRLedgerId] = useInputState(
        insideReport ? getReportTableRrLedgerInputQueryParam(id) : TABLE_RR_LEDGER_INPUT_QUERY_PARAM,
    )
    const [assetType] = useInputState(
        insideReport ? getReportTableAssetTypeParam(id) : TABLE_ASSET_TYPE_PARAM,
    )
    const [groupByRRField, setGroupByRRField] = useInputState(
        insideReport ? getReportGroupByRRFieldInputQueryParam(id) : GROUP_BY_RR_FIELD_INPUT_QUERY_PARAM,
    )

    const [assetIds, setAssetIds] = useInputState(insideReport ? GLOBAL_REPORT_MULTY_ASSET_QUERY_PARAM : ASSET_SIDE_MODAL_INPUT_QUERY_PARAM)
    const [month, setMonth] = useInputState(
        insideReport ? GLOBAL_REPORT_MONTH_QUERY_PARAM : MONTH_INPUT_QUERY_PARAM,
    )
    const areFiltersSet = Boolean(assetIds && month && rRLedgerId)

    const period = month ? monthIndToString(month) : ''

    const { data: reportData, isLoading, isFetched, isSuccess } = useReportingRentRollMetricsCommercialQuery(
        {
            filter: AND(
                EQ('asset_ids', assetIds),
                EQ('rent_roll_ledger_id', rRLedgerId),
                EQ('time_period', month),
            ),
        },
        { enabled: areFiltersSet },
    )

    const rows = useMemo(() => normalizePercentageValues(reportData?.[0]?.rows ?? []), [reportData])

    const columnDefs = useMemo(
        () => getRRMCColDefs({
            groupByRRField,
            period,
        }),
        [groupByRRField, month],
    )

    // default month range in creation mode
    useEffect(() => {
        if (isCreationMode) {
            setMonth(priorMonth)
        }
    }, [isCreationMode, priorMonth, setMonth])

    // table config
    const config = useMemo(() => {
        const filters: ReportingTableFilters = { assetIds: assetIds as number[] }
        if (month) { filters.month = month }
        if (groupByRRField) { filters.rrGroupBy = groupByRRField }
        return ({ filters })
    }, [assetIds, month, groupByRRField])

    const checkIfTableIsDirty = useCallback(() => {
        const loadedState = loadedTableData?.aggrid_state ?? {}
        const currentState = getGridPropsToSave(tableRef.current?.api?.getState())
        const currentConfig = config
        const loadedConfig = loadedTableData?.config ?? {}
        const currentName = tableMeta.name
        const loadedName = loadedTableData?.name

        if (
            isEqual(currentConfig, loadedConfig) &&
            isEqual(currentState, loadedState) &&
            currentName === loadedName
        ) {
            setIsTableDirty(false)
        } else {
            setIsTableDirty(true)
        }
    }, [config, loadedTableData?.aggrid_state, loadedTableData?.config, loadedTableData?.name, tableMeta.name, tableRef])

    useEffect(() => checkIfTableIsDirty(), [checkIfTableIsDirty])

    // set filters from loaded table data
    useEffect(() => {
        if (!loadedTableData) {
            return
        }

        const { filters } = loadedTableData.config
        if (filters) {
            if ((insideReport && !assetIds) || !insideReport) {
                filters.assetIds && setAssetIds(filters.assetIds)
            }
            if ((insideReport && !month) || !insideReport) {
                filters.month && setMonth(filters.month)
            }
            filters.rrGroupBy && setGroupByRRField(filters.rrGroupBy)
        }
    }, [loadedTableData, setAssetIds, month, setGroupByRRField, insideReport])

    const onTableSave = useCallback(async () => {
        const currentState = tableRef.current?.api?.getState() ?? {}
        const response = await saveReportingTable({
            tableMeta,
            currentState,
            isCreationMode,
            saveMutation,
            id,
            tableType: TableType.RRMetricsCommercial,
            config,
            assetType,
        })

        if (!response) {
            return
        }

        // redirect after saving
        if (isCreationMode) {
            navigate(getRoute(builderRouteConfigKey, { id: response.id }))
        } else {
            navigate(getRoute(tablesListRouteConfigKey))
        }
    }, [assetType, config, id, isCreationMode, navigate, saveMutation, tableMeta, tableRef])

    const excelExportParams = useMemo((): ExcelExportParams => {
        return ({
            fileName: `RR Metrics (Commercial) - ${period}.xlsx`,
            sheetName: period,
            processCellCallback: processRRTradeOutBasicTableTotalCells(groupByRRField),
            suppressRowOutline: true,
        })
    }, [groupByRRField, period])

    const onModelUpdated = useCallback(() => {
        if (!isLoading && isFetched && isSuccess) {
            onFirstDataRendered()
        }
    }, [isLoading, isFetched, isSuccess, onFirstDataRendered])

    return (
        <>
            <Layout gap={16} flexGrow={1} direction='column'>
                {areFiltersSet
                    ? (
                    // @ts-expect-error TODO: make AgGridTable generic to be able to pass theme
                        <AgGridTable
                            ref={tableRef}
                            items={rows}
                            {...rRMCTableGridOptions}
                            columnDefs={columnDefs}
                            loading={isLoading}
                            initialState={gridStateRef.current}
                            lazyModules={rangeSelectionModules}
                            onStateUpdated={(e) => {
                                saveGridStateToRef(e, gridStateRef)
                                checkIfTableIsDirty()
                            }}
                            statusBar={statusBar}
                            defaultExcelExportParams={excelExportParams}
                            getContextMenuItems={getContextMenuItems}
                            onGridReady={onGridReady}
                            onModelUpdated={onModelUpdated}
                        />
                    )
                    : (<NoDataMessage text='Choose required fields'/>)}
            </Layout>

            {buttonContainer && createPortal(
                <CancelSaveButtons
                    onCancel={onCancel}
                    onSave={onTableSave}
                    isLoading={saveMutation.isPending}
                    isDisabled={!isTableDirty || !tableMeta.name}
                />,
                buttonContainer,
            )}
        </>
    )
})
