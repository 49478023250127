import { useCallback, useEffect, useState } from 'react'

import { ModelUpdatedEvent, RowDataUpdatedEvent } from '@ag-grid-community/core'

import { FilterTree, FilterTreeItem, FilterTreeSearch } from '@/components/base'

import { TreeFilterToolPanelProps } from './TreeFilterToolPanel.types'
import { getAllIds } from './TreeFilterToolPanel.utils'

import styles from './TreeFilterToolPanel.module.scss'

export const TreeFilterToolPanel = (props: TreeFilterToolPanelProps) => {
    const { api, context } = props

    // use context only on the 1st render, as it doesn't update on rerender
    const [treeData, setTreeData] = useState<FilterTreeItem[]>(context.treeData)
    const [filteredItems, setFilteredItems] = useState<FilterTreeItem[]>([])
    const [selectedIds, setSelectedIds] = useState<string[]>([])
    const [expandAll, setExpandAll] = useState<boolean>(true)

    const updateSelectedIds = useCallback((e?: ModelUpdatedEvent | RowDataUpdatedEvent) => {
        // take context from the event because it doesn't update in props
        const tree = e?.context?.treeData ?? treeData
        setTreeData(tree)

        const gridState = api.getState()
        const selected: string[] | undefined = gridState?.filter?.filterModel?.name?.values
        // undefined means filters are not set and we must show that "all items are selected"
        setSelectedIds(selected || getAllIds(tree) as string[])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) // no need to update this callback because it is only used once and stored in the useEffect closure

    useEffect(() => {
        updateSelectedIds()
        api.addEventListener('modelUpdated', updateSelectedIds)
        api.addEventListener('rowDataUpdated', updateSelectedIds)

        return () => {
            if (!api.isDestroyed()) {
                api.removeEventListener('modelUpdated', updateSelectedIds)
                api.removeEventListener('rowDataUpdated', updateSelectedIds)
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onChange = useCallback(
        (values: string[]) => api.setColumnFilterModel('name', { values }).then(() => api.onFilterChanged()),
        [api],
    )

    return (
        <div className={styles.panel}>
            <div className={styles.search}>
                <FilterTreeSearch
                    items={treeData}
                    selected={selectedIds}
                    onFilter={(filteredItems) => setFilteredItems(filteredItems)}
                    onChange={onChange}
                    onExpand={(value) => setExpandAll(value)}
                    expandAll={expandAll}
                />
            </div>

            <div className={styles.filter}>
                <div className={styles.tree}>
                    <FilterTree
                        items={filteredItems}
                        selected={selectedIds}
                        onChange={onChange}
                        expandAll={expandAll}
                    />
                </div>
            </div>
        </div>
    )
}
