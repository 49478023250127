import { AgChartTheme } from '@ag-grid-enterprise/charts-enterprise'

import { colorsPrimaryGrey, colorsSecondaryGrey100, colorsSecondaryGrey600 } from '@/styles/tokens/generatedColors'

export const Feb25ChartTheme: AgChartTheme = {
    palette: {
        fills: [
            '#75A1FD', '#8EBCFB', '#A0D4FA', '#A9E7FA', // Blue
            '#54B37E', '#72B984', '#93D3A1', '#B2EBC0', // Green
            '#7958CD', '#8F70E0', '#A78EEF', '#C1ABF5', // Purple
            '#E8777C', '#F08D94', '#F5A7AE', '#FBC3C7', // Coral-Pink
            '#E8854A', '#F0975F', '#F5AF7E', '#FBC8A3', // Coral-Orange
        ],
        strokes: [
            '#5C87E0', '#6697DA', '#6FAAD6', '#7AC1D7', // Blue
            '#3F946B', '#5C9F6E', '#78BA8A', '#94D2A7', // Green
            '#6443B6', '#7655C2', '#8D72D7', '#A38DE9', // Purple
            '#D1646E', '#D97783', '#E08D9B', '#E8A5B4', // Coral-Pink
            '#C66F3C', '#D88152', '#E3956B', '#F0AC87', // Coral-Orange
        ],
    },
    overrides: {
        common: {
            contextMenu: {
                enabled: false,
            },
            axes: {
                number: {
                    title: {
                        enabled: false,
                        color: colorsSecondaryGrey600,
                        fontFamily: 'Inter',
                        fontSize: 14,
                        fontWeight: 400,
                    },
                    label: {
                        color: colorsSecondaryGrey600,
                        fontFamily: 'Inter',
                        fontSize: 14,
                        fontWeight: 400,
                        formatter: ({ value }) => {
                            if (typeof value !== 'number') {
                                return value
                            }
                            return value.toLocaleString('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                            })
                        },
                    },
                    gridLine: {
                        width: 1,
                        style: [
                            {
                                stroke: colorsSecondaryGrey100,
                                lineDash: [4, 4],
                            },
                        ],
                    },
                },
                category: {
                    line: {
                        stroke: colorsSecondaryGrey600,
                        width: 2,
                    },
                    tick: {
                        enabled: true,
                        width: 2,
                        size: 11,
                        stroke: colorsSecondaryGrey600,
                    },
                    label: {
                        fontSize: 14,
                        fontWeight: 400,
                        fontFamily: 'Inter',
                        color: colorsSecondaryGrey600,
                        enabled: true,
                        rotation: 0,
                        formatter: (params) => {
                            return params.value
                        },
                    },
                },
            },
            title: {
                textAlign: 'left',
                color: colorsPrimaryGrey,
                fontFamily: 'Inter',
                fontSize: 18,
                fontWeight: 600,
            },
            legend: {
                enabled: false,
            },
        },
        bar: {
            series: {
                cornerRadius: 3,
                strokeWidth: 0,
            },
        },
        line: {
            series: {
                strokeWidth: 4,
                marker: {
                    strokeWidth: 2,
                    size: 12,
                },
            },
        },
    },
}
