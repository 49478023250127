import { GridState } from '@ag-grid-community/core'

import { getRequestQueries } from '@/api/utils/getRequestQueries'
import { AssetDetails } from '@/constants/assetDetails'
import { BSReportScenario, ReportScenario } from '@/constants/reportScenario'
import { TimeComparisonPeriod } from '@/constants/timeComparisonPeriods'
import { TimeInterval } from '@/constants/timeInterval'
import { TimeSettings } from '@/constants/timeSettings'
import { AssetType } from '@/models/core'
import { GroupByRRField, PinTotalColumn, TableIdRows, TbAggregation } from '@/pages/TablesPage/TableBuilderPage/TableBuilderFilters'
import { TableType } from '@/pages/TablesPage/tableType'

import { ConditionalFormatRule } from '../../pages/TablesPage/TableBuilderPage/ConditionalRuleEditor/ConditionalRuleEditor.types'

export interface ReportingTableFilters {
    // @deprecated: instead use camelCase properties
    asset_ids?: number[]
    time_periods?: TimeComparisonPeriod[]

    // -- actual properties ---
    assetIds?: number[]
    metricId?: string
    month?: number
    startMonth?: number
    endMonth?: number
    quarter?: string
    startQuarter?: string
    endQuarter?: string
    year?: number
    startYear?: number
    endYear?: number
    timeSettings?: TimeSettings
    timeInterval?: TimeInterval
    timeComparison?: TimeComparisonPeriod[]
    assetDetails?: AssetDetails[]
    scenarioId?: ReportScenario
    scenarioIds?: ReportScenario[]
    bsScenario?: BSReportScenario
    groupBy?: AssetDetails[]
    rrGroupBy?: GroupByRRField // RentRoll Group By
    showTotalRow?: string
    pinTotalColumn?: PinTotalColumn
    tableIdRows?: TableIdRows[]
    showCodes?: string
    showEmptyRows?: string
    pnLmetricIds?: Array<string | null> // in P&L - Time Series Table
    pnLVAmetricIds?: Array<string | null> // in P&L - Variance Analysis Table
    rpMetricIds?: Array<string | null>
    bsMetricIds?: Array<string | null> // in Balance Sheet - Time Series Table
    underwritingDate?: string
    tbAggregation?: TbAggregation[]
}

export interface ReportingTableSpacer {
    id: string
    value: string
}

export interface ReportingTableItem {
    id: number
    company_id?: number
    name: string
    type: TableType
    description?: string
    aggrid_state: GridState
    rent_roll_ledger?: number
    trial_balance_ledger?: number

    asset_type?: AssetType

    config: {
        filters?: ReportingTableFilters
        order?: string[] // row ids in the order they should be displayed
        spacers?: ReportingTableSpacer[]
        visible_columns?: string[] // column ids
        conditional_format_rules?: { rules: ConditionalFormatRule[] }
        charts?: { isChartMode?: boolean }
    }

    /**
     * date time string, ISO 8601, YYYY-MM-DDTHH:mm:ss.sssZ
     */
    created_at?: string
    /**
     * date time string, ISO 8601, YYYY-MM-DDTHH:mm:ss.sssZ
     */
    updated_at?: string
}

export const REPORTING_TABLES_QUERY_URL = 'reporting/table'

export const {
    useItemsQuery: useReportingTableQuery,
    useItemByIdQuery: useReportingTableByIdQuery,
    useItemUpdateMutation: useReportingTableUpdateMutation,
    useItemsCountQuery: useReportingTableCountQuery,
    useItemCreateMutation: useReportingTableCreateMutation,
    useItemDeleteMutation: useReportingTableDeleteMutation,
} = getRequestQueries<ReportingTableItem, Record<string, unknown>>({
    url: 'reporting/table',
    invalidateAfterUpdate: [REPORTING_TABLES_QUERY_URL],
})
