import { observer } from 'mobx-react-lite'

import { ButtonGroup } from '@/components/base'

import { ShowEmptyRowsFilterProps } from './ShowEmptyRowsFilter.types'

// TODO: remove hide/show keys, it should be boolean
export const ShowEmptyRowsFilter = observer((props: ShowEmptyRowsFilterProps) => {
    const { filter, label = 'Empty Rows' } = props

    return (
        <ButtonGroup
            label={label}
            options={[
                {
                    title: 'Hide',
                    key: 'hide',
                },
                {
                    title: 'Show',
                    key: 'show',
                },
            ]}
            selected={filter.value ? 'show' : 'hide'}
            onSelect={(key) => filter.set(key === 'show' ? true : undefined)}
        />
    )
})
