import { getRequestQueries } from '@/api/utils/getRequestQueries'
import { ReportingDataValueType } from '@/constants/reportingDataValueTypes'

import { ReportingPortfolioMetricsAggregation } from './reportingPortfolioMetrics'

export interface ReportingRentRollColumn {
    key: string
    name: string
    value_type: `${ReportingDataValueType}`
    can_be_grouped?: boolean
    aggregation?: `${ReportingPortfolioMetricsAggregation}`
}

export interface ReportingRentRollRow {
    id: number
    [key: string]: string | number | null

}

export interface ReportingRentRollItem {
    columns: ReportingRentRollColumn[]
    rows: ReportingRentRollRow[]
}

const REPORTING_RENT_ROLL_URL = 'reporting/rent-roll'

export const {
    useItemsQuery: useReportingRentRollQuery,
} = getRequestQueries<ReportingRentRollItem, Record<string, unknown>>({
    url: REPORTING_RENT_ROLL_URL,
    extractItemsFn: (data: ReportingRentRollItem) => [data],
})
