import { RefObject, useEffect, useState } from 'react'

import cn from 'classnames'

import { AgGridReact } from '@ag-grid-community/react'
import { matchPath, useNavigate } from 'react-router'

import { useReportingReportByIdQuery, useReportingReportUpdateMutation } from '@/api/reportingData/reportingReport'
import { useReportingTableByIdQuery } from '@/api/reportingTable/reportingTable'
import { ActionsMenu, ActionsMenuItem, Text, TextInput } from '@/components/base'
import { Layout } from '@/components/containers'
import { OWNER_ROUTES_CONFIG } from '@/core/routes'
import { useTableChartToggle } from '@/hooks/useTableChartToggle'
import {
    REPORT_WRAPPER_BUTTONS_CONTAINER,
} from '@/pages/MainPage/UserPages/ReportsPage/ReportTableWrapper/ReportTableWrapper.constants'
import { TableChartButton } from '@/pages/TablesPage/TableBuilderPage/TableChartButton'
import { getRoute } from '@/utils/routing/getRoute'

import { ReportTableWrapperProps } from './ReportTableWrapper.types'

import styles from './ReportTableWrapper.module.scss'

export const ReportTableWrapper = (props: ReportTableWrapperProps) => {
    const { children, tableId, reportId, tableRef, reportType } = props
    const navigate = useNavigate()
    const tableChartToggle = useTableChartToggle(tableRef as RefObject<AgGridReact>)

    const reportingReportByIdQuery = useReportingReportByIdQuery(reportId, {
        enabled: !!reportId,
    })
    const reportingReportUpdateMutation = useReportingReportUpdateMutation()
    const reportingTableByIdQuery = useReportingTableByIdQuery(tableId, {
        enabled: !!tableId,
    })

    const [renameMode, setRenameMode] = useState(false)
    const [descriptionMode, setDescriptionMode] = useState(false)
    const [titleInputState, setTitleInputState] = useState('')
    const [descriptionInputState, setDescriptionInputState] = useState('')

    const tableData = reportingReportByIdQuery.data?.items.find(el => el.table_id === tableId)
    const initialTitle = tableData?.title || reportingTableByIdQuery.data?.name || ''
    const initialDescription = tableData?.outline || ''

    useEffect(() => {
        setTitleInputState(initialTitle)
        setDescriptionInputState(initialDescription)
    }, [initialTitle, initialDescription])

    const myReportsPageMatch = Boolean(matchPath({
        path: OWNER_ROUTES_CONFIG.REPORTS_CUSTOM_MY.path,
        end: true,
    }, location.pathname)) ||
             Boolean(matchPath({
                 path: OWNER_ROUTES_CONFIG.REPORTS_CUSTOM_MY_BY_ID.path,
                 end: true,
             }, location.pathname))

    const handleSave = () => {
        if (!reportingReportByIdQuery.data || !reportId) return

        const updatedItems = reportingReportByIdQuery.data.items.map(item => {
            if (item.table_id !== Number(tableId)) return item

            return {
                ...item,
                title: titleInputState,
                outline: descriptionInputState,
            }
        })

        reportingReportUpdateMutation.mutate({
            id: reportId,
            updatedItemData: {
                ...reportingReportByIdQuery.data,
                items: updatedItems,
            },
        })

        setRenameMode(false)
        setDescriptionMode(false)
    }

    const handleDeleteTable = () => {
        if (!reportingReportByIdQuery.data || !reportId) return

        const updatedList = reportingReportByIdQuery.data.items.filter(el => el.table_id !== tableId)
        reportingReportUpdateMutation.mutate({
            id: reportId,
            updatedItemData: {
                items: updatedList,
            },
            config: {
                queryParams: {
                    is_private: myReportsPageMatch ? true : undefined,
                },
            },
        })
    }

    const menuItems: ActionsMenuItem[] = [
        ...(props.extraMenuElements || []),
        ...(tableRef ? [{
            text: 'Download',
            onClick: () => {
                if ('current' in tableRef && tableRef.current?.api) {
                    tableRef.current.api.exportDataAsExcel()
                }
            },
        }] : []),
        {
            text: 'Rename',
            onClick: () => {
                // Timeout to avoid trigering onBlur during click
                setTimeout(() => setRenameMode(true), 200)
            },
        }, {
            text: 'Add Description',
            onClick: () => {
                // Timeout to avoid trigering onBlur during click
                setTimeout(() => setDescriptionMode(true), 200)
            },
            hidden: Boolean(descriptionInputState),
        }, {
            text: 'Edit Description',
            onClick: () => {
                // Timeout to avoid trigering onBlur during click
                setTimeout(() => setDescriptionMode(true), 200)
            },
            hidden: !descriptionInputState,
        }, {
            text: 'Edit Table',
            onClick: () => {
                navigate(getRoute(reportType === 'my' ? 'REPORTS_CUSTOM_MY_TABLE_BUILDER' : 'REPORTS_CUSTOM_COMPANY_TABLE_BUILDER', { id: tableId }))
            },
        }, {
            text: 'Delete Table',
            onClick: handleDeleteTable,
        },
    ]

    return (
        <Layout className={cn(styles.reportTableWrapper)} direction='column' p={20} gap={28}>
            <Layout gap={12}>
                <Layout className={styles.line} width='5px'/>
                <Layout direction='column' gap={4} flexGrow={1}>
                    <Layout gap={8} align='center'>
                        <Layout gap={12} align='center'>
                            {
                                renameMode ? (
                                    <TextInput
                                        value={titleInputState}
                                        onChange={(e) => setTitleInputState(e.target.value)}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                handleSave()
                                            }
                                        }}
                                        autoFocus
                                        onBlur={handleSave}
                                    />
                                ) : (
                                    <Layout
                                        onClick={() => setRenameMode(true)}
                                    >
                                        <Text
                                            text={titleInputState}
                                            variant='header'
                                            block
                                        />
                                    </Layout>
                                )
                            }
                        </Layout>
                        <Layout flexGrow={1}/>
                        <Layout
                            gap={8}
                            id={REPORT_WRAPPER_BUTTONS_CONTAINER}
                        />
                        <TableChartButton
                            tableChartToggle={tableChartToggle}
                            tablesListRouteConfigKey={reportType === 'my' ? 'REPORTS_CUSTOM_MY_TABLES' : 'REPORTS_CUSTOM_COMPANY_TABLES'}
                        />
                        <ActionsMenu items={menuItems}/>
                    </Layout>
                    {(descriptionInputState || descriptionMode) && (
                        <Layout align='center'>
                            {
                                descriptionMode ? (
                                    <TextInput
                                        value={descriptionInputState}
                                        onChange={(e) => setDescriptionInputState(e.target.value)}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                handleSave()
                                            }
                                        }}
                                        onBlur={handleSave}
                                        placeholder='Add description'
                                        autoFocus
                                    />
                                ) : (
                                    <Layout
                                        onClick={() => setDescriptionMode(true)}
                                    >
                                        <Text
                                            color='colorsSecondaryGrey600'
                                            text={descriptionInputState}
                                            variant='smallTextMediumDefault'
                                            block
                                        />
                                    </Layout>
                                )
                            }
                        </Layout>
                    )}
                </Layout>
            </Layout>
            {children}
        </Layout>
    )
}
