import { CellClassParams, GridState, IAggFuncParams } from '@ag-grid-community/core'

import { ReportingTrialBalanceRow } from '@/api/reportingData/reportingTrialBalance'
import { AgGridTableProps } from '@/components/tables'
import { FEB25_COL_TYPES } from '@/components/tables/AgGridTable/themes/feb25/feb25.constants'
import { Feb25Classes, Feb25ColDef, Feb25ColGroupDef } from '@/components/tables/AgGridTable/themes/feb25/feb25.types'
import { ExcelClasses, excelStyles, getExcelGroupClasses } from '@/constants/reportTable'

export const tBDefaultColSizingModel: GridState['columnSizing'] = {
    columnSizingModel: [
        {
            colId: 'asset_name',
            width: 200,
        },
        {
            colId: 'period',
            width: 150,
        },
        {
            colId: 'source_account_code',
            width: 200,
        },
        {
            colId: 'source_account_name',
            width: 200,
        },
        {
            colId: 'prior_period_closing_balance',
            width: 250,
        },
        {
            colId: 'opening_balance',
            width: 250,
        },
        {
            colId: 'debit',
            width: 170,
        },
        {
            colId: 'net_debit',
            width: 170,
        },
        {
            colId: 'credit',
            width: 170,
        },
        {
            colId: 'net_credit',
            width: 170,
        },
        {
            colId: 'activity',
            width: 170,
        },
        {
            colId: 'closing_balance',
            width: 170,
        },
        {
            colId: 'target_account_code',
            width: 200,
        },
        {
            colId: 'target_account_name',
            width: 200,
        },
    ],
}

export const tBDefaultColVisibility: GridState['columnVisibility'] = {
    hiddenColIds: [
        'net_debit',
        'net_credit',
    ],
}

export const tBTableGridOptions: Omit<AgGridTableProps<'feb25'>, 'items' | 'columnDefs'> = {
    theme: 'feb25',
    columnTypes: FEB25_COL_TYPES,
    defaultColDef: {
        wrapHeaderText: true,
        autoHeaderHeight: true,
        suppressMovable: false,
        unSortIcon: false,
    },
    sideBar: {
        toolPanels: [
            {
                id: 'columns',
                labelDefault: 'Columns',
                labelKey: 'columns',
                iconKey: 'columns',
                toolPanel: 'agColumnsToolPanel',
                toolPanelParams: {
                    suppressPivotMode: true,
                    suppressValues: true,
                },
            },
        ],
        defaultToolPanel: 'columns',
    },
    getRowId: ({ data }: { data: ReportingTrialBalanceRow }) => JSON.stringify(data),
    suppressCellFocus: true,
    suppressFitToWidth: true,
    suppressAutoResize: true,
    suppressMovableColumns: true,
    maintainColumnOrder: true,
    suppressAggFuncInHeader: true,
    excelStyles,
}

const totalRenderer = (params: IAggFuncParams) => {
    const visibleColumns = params.api.getAllDisplayedColumns()
    const isFirstVisible = visibleColumns.length > 0 && visibleColumns[0] === params.column
    return isFirstVisible ? 'Total' : ''
}

export const tBColumnDefs: Array<Feb25ColGroupDef | Feb25ColDef> = [
    {
        headerName: '',
        headerClass: [Feb25Classes.HeaderL0, Feb25Classes.RightBorder1],
        children: [
            {
                headerName: 'Asset Name',
                field: 'asset_name',
                headerClass: [Feb25Classes.HeaderL2],
                cellClass: (params: CellClassParams) => [Feb25Classes.RightBorder1, Feb25Classes.HighlightTotalCell, ...getExcelGroupClasses(params)],
                type: 'string',
                aggFunc: totalRenderer,
            },
            {
                headerName: 'Period',
                field: 'period',
                headerClass: [Feb25Classes.HeaderL2, Feb25Classes.RightBorder1],
                cellClass: (params: CellClassParams) => [Feb25Classes.RightBorder1, Feb25Classes.HighlightTotalCell, ...getExcelGroupClasses(params)],
                type: 'string',
                aggFunc: totalRenderer,
            },
        ],
    },
    {
        headerName: 'Source Account',
        headerClass: [Feb25Classes.HeaderL0, Feb25Classes.RightBorder1],
        children: [
            {
                headerName: 'Code',
                field: 'source_account_code',
                headerClass: [Feb25Classes.HeaderL2],
                cellClass: (params: CellClassParams) => [Feb25Classes.HighlightTotalCell, ...getExcelGroupClasses(params)],
                type: 'string',
                aggFunc: totalRenderer,
            },
            {
                headerName: 'Name',
                field: 'source_account_name',
                headerClass: [Feb25Classes.HeaderL2, Feb25Classes.RightBorder1],
                cellClass: (params: CellClassParams) => [Feb25Classes.RightBorder1, Feb25Classes.HighlightTotalCell, ...getExcelGroupClasses(params)],
                type: 'string',
                aggFunc: totalRenderer,
            },
        ],
    },
    {
        headerName: '',
        headerClass: [Feb25Classes.HeaderL0, Feb25Classes.RightBorder1],
        children: [
            {
                headerName: 'Prior Period Closing Balance',
                field: 'prior_period_closing_balance',
                headerClass: [Feb25Classes.HeaderL2, Feb25Classes.RightAlignedHeader],
                cellClass: (params: CellClassParams) => [Feb25Classes.RightBorder1, Feb25Classes.RightAlignedCell, Feb25Classes.HighlightTotalCell, ...getExcelGroupClasses(params), ExcelClasses.Currency],
                type: 'money',
                aggFunc: 'sum',
            },
            {
                headerName: 'Opening Balance',
                field: 'opening_balance',
                headerClass: [Feb25Classes.HeaderL2, Feb25Classes.RightAlignedHeader],
                cellClass: (params: CellClassParams) => [Feb25Classes.RightBorder1, Feb25Classes.RightAlignedCell, Feb25Classes.HighlightTotalCell, ...getExcelGroupClasses(params), ExcelClasses.Currency],
                type: 'money',
                aggFunc: 'sum',
            },
            {
                headerName: 'Debit',
                field: 'debit',
                headerClass: [Feb25Classes.HeaderL2, Feb25Classes.RightAlignedHeader],
                cellClass: (params: CellClassParams) => [Feb25Classes.RightBorder1, Feb25Classes.RightAlignedCell, Feb25Classes.HighlightTotalCell, ...getExcelGroupClasses(params), ExcelClasses.Currency],
                type: 'money',
                aggFunc: 'sum',
            },
            {
                headerName: 'Net Debit',
                field: 'net_debit',
                headerClass: [Feb25Classes.HeaderL2, Feb25Classes.RightAlignedHeader],
                cellClass: (params: CellClassParams) => [Feb25Classes.RightBorder1, Feb25Classes.RightAlignedCell, Feb25Classes.HighlightTotalCell, ...getExcelGroupClasses(params), ExcelClasses.Currency],
                type: 'money',
                aggFunc: 'sum',
            },
            {
                headerName: 'Credit',
                field: 'credit',
                headerClass: [Feb25Classes.HeaderL2, Feb25Classes.RightAlignedHeader],
                cellClass: (params: CellClassParams) => [Feb25Classes.RightBorder1, Feb25Classes.RightAlignedCell, Feb25Classes.HighlightTotalCell, ...getExcelGroupClasses(params), ExcelClasses.Currency],
                type: 'money',
                aggFunc: 'sum',
            },
            {
                headerName: 'Net Credit',
                field: 'net_credit',
                headerClass: [Feb25Classes.HeaderL2, Feb25Classes.RightAlignedHeader],
                cellClass: (params: CellClassParams) => [Feb25Classes.RightBorder1, Feb25Classes.RightAlignedCell, Feb25Classes.HighlightTotalCell, ...getExcelGroupClasses(params), ExcelClasses.Currency],
                type: 'money',
                aggFunc: 'sum',
            },
            {
                headerName: 'Activity',
                field: 'activity',
                headerClass: [Feb25Classes.HeaderL2, Feb25Classes.RightAlignedHeader],
                cellClass: (params: CellClassParams) => [Feb25Classes.RightBorder1, Feb25Classes.RightAlignedCell, Feb25Classes.HighlightTotalCell, ...getExcelGroupClasses(params), ExcelClasses.Currency],
                type: 'money',
                aggFunc: 'sum',
            },
            {
                headerName: 'Closing Balance',
                field: 'closing_balance',
                headerClass: [Feb25Classes.HeaderL2, Feb25Classes.RightAlignedHeader, Feb25Classes.RightBorder1],
                cellClass: (params: CellClassParams) => [Feb25Classes.RightBorder1, Feb25Classes.RightAlignedCell, Feb25Classes.HighlightTotalCell, ...getExcelGroupClasses(params), ExcelClasses.Currency],
                type: 'money',
                aggFunc: 'sum',
            },
        ],
    },
    {
        headerName: 'Target Account',
        headerClass: [Feb25Classes.HeaderL0, Feb25Classes.RightBorder1],
        children: [
            {
                headerName: 'Code',
                field: 'target_account_code',
                headerClass: [Feb25Classes.HeaderL2],
                cellClass: (params: CellClassParams) => [Feb25Classes.HighlightTotalCell, ...getExcelGroupClasses(params)],
                type: 'string',
            },
            {
                headerName: 'Name',
                field: 'target_account_name',
                headerClass: [Feb25Classes.HeaderL2, Feb25Classes.RightBorder1],
                cellClass: (params: CellClassParams) => [Feb25Classes.RightBorder1, Feb25Classes.HighlightTotalCell, ...getExcelGroupClasses(params)],
                type: 'string',
            },
        ],
    },
]
