import { DateRangePicker } from '@/components/base/DateRangePicker'
import { useInputState } from '@/hooks'
import { dateToQuarter, QuarterString, quarterToDate } from '@/utils/date/quarter'

import { monthIndToDateNoTimezoneReset } from '../../../utils/date/monthInd'
import { MONTH_RANGE_END_QUERY_PARAM, MONTH_RANGE_START_QUERY_PARAM } from '../MonthRangeInput'
import { YEAR_RANGE_END_QUERY_PARAM, YEAR_RANGE_START_QUERY_PARAM } from '../YearRangeInput'

import { QUARTER_RANGE_END_QUERY_PARAM, QUARTER_RANGE_START_QUERY_PARAM } from './QuarterRangeInput.constants'
import { QuarterRangeInputProps } from './QuarterRangeInput.types'

export const QuarterRangeInput = (props: QuarterRangeInputProps) => {
    const { defaultStart, defaultEnd, maxDate, required, label, syncLocalStorage, startInputParams = {}, endInputParams = {} } = props

    const [quarterStartParam, setQuarterStartParam] = useInputState({
        ...QUARTER_RANGE_START_QUERY_PARAM,
        syncLocalStorage,
        ...startInputParams,
    })
    const [quarterEndParam, setQuarterEndParam] = useInputState({
        ...QUARTER_RANGE_END_QUERY_PARAM,
        syncLocalStorage,
        ...endInputParams,
    })

    const [monthStartParam] = useInputState({
        ...MONTH_RANGE_START_QUERY_PARAM,
    })
    const [monthEndParam] = useInputState({
        ...MONTH_RANGE_END_QUERY_PARAM,
    })
    const [yearStartParam] = useInputState({
        ...YEAR_RANGE_START_QUERY_PARAM,
    })
    const [yearEndParam] = useInputState({
        ...YEAR_RANGE_END_QUERY_PARAM,
    })

    if (!quarterStartParam && defaultStart) {
        setQuarterStartParam(dateToQuarter(defaultStart))
    }

    if (!quarterEndParam && defaultEnd) {
        setQuarterEndParam(dateToQuarter(defaultEnd))
    }

    let dateFrom = quarterStartParam ? quarterToDate(quarterStartParam as QuarterString) : undefined
    let dateTo = quarterEndParam ? quarterToDate(quarterEndParam as QuarterString) : undefined

    if (!dateFrom && monthStartParam) {
        dateFrom = monthIndToDateNoTimezoneReset(monthStartParam)
        setQuarterStartParam(dateFrom ? dateToQuarter(dateFrom) : undefined)
    }
    if (!dateTo && monthEndParam) {
        dateTo = monthIndToDateNoTimezoneReset(monthEndParam)
        setQuarterEndParam(dateTo ? dateToQuarter(dateTo) : undefined)
    }
    if (!dateFrom && yearStartParam) {
        dateFrom = new Date(yearStartParam, 0)
        setQuarterStartParam(dateFrom ? dateToQuarter(dateFrom) : undefined)
    }
    if (!dateTo && yearEndParam) {
        dateTo = new Date(yearEndParam, 0)
        setQuarterEndParam(dateTo ? dateToQuarter(dateTo) : undefined)
    }

    return (
        <DateRangePicker
            type='quarter'
            label={label}
            dateFrom={dateFrom}
            dateTo={dateTo}
            maxDate={maxDate}
            onChange={({ dateFrom, dateTo }) => {
                setQuarterStartParam(dateFrom ? dateToQuarter(dateFrom) : undefined)
                setQuarterEndParam(dateTo ? dateToQuarter(dateTo) : undefined)
            }}
            error={required && (!dateFrom || !dateTo)}
        />
    )
}
