import { CellClassFunc, ColDef, ColGroupDef, HeaderClassParams } from '@ag-grid-community/core'

import { ExcelClasses } from '@/constants/reportTable'

export enum NeoClasses {
    RightAlignedHeader = 'ag-right-aligned-header', // standard ag-grid class
    RightAlignedCell = 'ag-right-aligned-cell', // standard ag-grid class
    RightBorder1 = 'neo-right-border-1', // right border 1px
    HighlightTotalCell = 'neo-highlight-total', // highlight the cell in group subtotals and grand total rows
    GreyBG = 'neo-bg-grey', // light grey background for marking a column
    Grey500BG = 'neo-bg-grey-500', // grey 500 bg & white color
    Purple900BG = 'neo-bg-purple-900', // purple 900 bg & white color
    GreenCellHighlight = 'neo-cell-green-highlight', // green cell highlight
    RedCellHighlight = 'neo-cell-red-highlight', // red cell highlight
    None = '', // empty class
    ItalicRow = 'neo-italic-row', // italic text
    SmallFontRow = 'neo-small-font-row', // small font
    SeparatorRow = 'neo-separator-row', // separator row
    GrandTotalRow = 'neo-grand-total-row', // grand total row
    NoTopBorder = 'neo-no-top-border', // no top border
    Clickable = 'neo-clickable', // clickable cell
}

export type NeoHeaderClass<TData = any, TValue = any> = `${NeoClasses | ExcelClasses}` | Array<`${NeoClasses | ExcelClasses}`> | ((params: HeaderClassParams<TData, TValue>) => `${NeoClasses}` | Array<`${NeoClasses}`> | undefined)

export type NeoCellClass<TData = any, TValue = any> = `${NeoClasses | ExcelClasses}` | Array<`${NeoClasses | ExcelClasses}`> | CellClassFunc<TData, TValue>

export interface NeoColDef extends Omit<ColDef, 'headerClass' | 'cellClass'> {
    headerClass?: NeoHeaderClass
    cellClass?: NeoCellClass
    fractionDigits?: number
}

export interface NeoColGroupDef extends Omit<ColGroupDef, 'headerClass' | 'cellClass'> {
    headerClass?: NeoHeaderClass
    cellClass?: NeoCellClass
}
