import { CellClassParams, GridState, IAggFunc, ICellRendererParams } from '@ag-grid-community/core'

import { ReportingPortfolioSelectedValueItem, ReportingPortfolioSelectedValueRow } from '@/api/reportingData/reportingPortfolioSelectedValue'
import { AgGridTableProps } from '@/components/tables'
import { NEO_COL_TYPES } from '@/components/tables/AgGridTable/themes/neo/neo.constants'
import { AssetDetails, ASSET_DETAIL_LABELS } from '@/constants/assetDetails'
import { ReportColumnAggregation } from '@/constants/reportColumnAggregation'
import { ReportingDataValueType } from '@/constants/reportingDataValueTypes'
import { ReportScenario } from '@/constants/reportScenario'
import { ExcelClasses, excelStyles, getExcelGroupClasses } from '@/constants/reportTable'
import { TimeInterval } from '@/constants/timeInterval'
import { AGGREGATION_BY_ASSET_TYPE, AssetType, ASSET_TYPE_LABEL } from '@/models/core'

import { Feb25Classes, Feb25ColDef, Feb25ColGroupDef } from '../../../../../components/tables/AgGridTable/themes/feb25/feb25.types'
import { ConditionalFormatRule } from '../../ConditionalRuleEditor/ConditionalRuleEditor.types'
import { PinTotalColumn } from '../../TableBuilderFilters'
import { PinnedPositionByTotalCol } from '../TableBuilderTables.constants'
import { getAggFuncWithIntermediateCalcs, getHeaderNameForValueCols, getWeightedAvgAggregation, grandTotalAverageRenderer, modifiedSumAggregation, refreshAggregationAfterFiltering } from '../TableBuilderTables.utils'
import { Feb25ChartTheme } from '../TableChart/Feb25ChartTheme'

import { cellRenderStyledFromConditionalFormatRules } from './MetricTimeSeriesTable.utils'

export const ASSET_DETAIL_ID_TO_FIELD: Record<AssetDetails, keyof ReportingPortfolioSelectedValueRow> = {
    [AssetDetails.NAME]: 'name',
    [AssetDetails.CITY]: 'city',
    [AssetDetails.STATE]: 'state',
    [AssetDetails.ASSET_CLASS]: 'asset_class',
    [AssetDetails.MANAGER_COMPANY]: 'manager',
    [AssetDetails.ASSET_MANAGER]: 'asset_manager_name',
    [AssetDetails.FUND]: 'fund',
    [AssetDetails.UNITS]: 'units',
    [AssetDetails.SQUARE_FEET]: 'sqft',
    [AssetDetails.CUSTOM_ID1]: 'custom_id1',
    [AssetDetails.CUSTOM_ID2]: 'custom_id2',
    [AssetDetails.SOFTWARE]: 'software',
}

export const defaultColSizingModelForAssetDetails: GridState['columnSizing'] = {
    columnSizingModel: [
        {
            colId: 'name',
            width: 162,
        },
        {
            colId: 'city',
            width: 100,
        },
        {
            colId: 'state',
            width: 71,
        },
        {
            colId: 'asset_class',
            width: 119,
        },
        {
            colId: 'manager',
            width: 175,
        },
        {
            colId: 'fund',
            width: 120,
        },
        {
            colId: 'units',
            width: 80,
        },
        {
            colId: 'sqft',
            width: 120,
        },
        {
            colId: 'values.total.actual',
            width: 120,
        },
    ],
}

const aggFuncs: Record<ReportColumnAggregation.SFAVG | ReportColumnAggregation.UAVG | ReportColumnAggregation.SUM, IAggFunc> = {
    [ReportColumnAggregation.SFAVG]: getWeightedAvgAggregation('sqft'),
    [ReportColumnAggregation.UAVG]: getWeightedAvgAggregation('units'),
    [ReportColumnAggregation.SUM]: modifiedSumAggregation,
}

const getAggFunc = (scenarioId: ReportScenario, assetType: AssetType) => {
    switch (scenarioId) {
        case ReportScenario.ACTUAL:
        case ReportScenario.BUDGET:
        case ReportScenario.VARIANCE:
            return 'sum'
        case ReportScenario.ACTUAL_PSF:
        case ReportScenario.BUDGET_PSF:
        case ReportScenario.VARIANCE_PSF:
            return AGGREGATION_BY_ASSET_TYPE.get(assetType)
        case ReportScenario.VARIANCE_PERCENT:
            return 'avg'
        default:
            return null
    }
}

const minMaxWidthByColId: Record<AssetDetails, [number, number]> = {
    [AssetDetails.NAME]: [100, 300],
    [AssetDetails.CITY]: [100, 300],
    [AssetDetails.STATE]: [50, 150],
    [AssetDetails.ASSET_CLASS]: [100, 200],
    [AssetDetails.MANAGER_COMPANY]: [120, 300],
    [AssetDetails.FUND]: [120, 300],
    [AssetDetails.UNITS]: [80, 100],
    [AssetDetails.SQUARE_FEET]: [80, 150],
    [AssetDetails.CUSTOM_ID1]: [100, 300],
    [AssetDetails.CUSTOM_ID2]: [100, 300],
    [AssetDetails.ASSET_MANAGER]: [100, 300],
    [AssetDetails.SOFTWARE]: [100, 300],
}

const colTypeByScenario: Record<ReportScenario, string> = {
    [ReportScenario.ACTUAL]: 'money',
    [ReportScenario.BUDGET]: 'money',
    [ReportScenario.VARIANCE]: 'money',
    [ReportScenario.ACTUAL_PSF]: 'money',
    [ReportScenario.BUDGET_PSF]: 'money',
    [ReportScenario.VARIANCE_PSF]: 'money',
    [ReportScenario.VARIANCE_PERCENT]: 'percentage',
    [ReportScenario.UNDERWRITING]: 'money',
    [ReportScenario.UNDERWRITING_PSF]: 'money',
    [ReportScenario.VARIANCE_UNDERWRITING]: 'money',
    [ReportScenario.VARIANCE_UNDERWRITING_PSF]: 'money',
    [ReportScenario.VARIANCE_UNDERWRITING_PERCENT]: 'percentage',
    [ReportScenario.VARIANCE_BUDGET_UNDERWRITING]: 'money',
    [ReportScenario.VARIANCE_BUDGET_UNDERWRITING_PSF]: 'money',
    [ReportScenario.VARIANCE_BUDGET_UNDERWRITING_PERCENT]: 'percentage',
}

export const metricTimeSeriesTableGridOptions: Omit<AgGridTableProps<'feb25'>, 'items'> = {
    customChartThemes: { Feb25ChartTheme },
    chartThemes: ['Feb25ChartTheme'],
    defaultColDef: {
        suppressMovable: true,
        lockPinned: true,
        filterParams: {
            cellHeight: 18,
        },
    },
    getRowId: ({ data }: { data: ReportingPortfolioSelectedValueRow }) => data.id?.toString(),
    suppressFitToWidth: true,
    suppressAutoResize: true,
    suppressRowDrag: true,
    suppressMovableColumns: false,
    groupDefaultExpanded: -1,
    groupDisplayType: 'groupRows',
    groupRowRendererParams: {
        suppressCount: true,
    },
    suppressDragLeaveHidesColumns: true,
    groupTotalRow: 'bottom',
    suppressAggFuncInHeader: true,
    aggFuncs,
    theme: 'feb25',
    columnTypes: NEO_COL_TYPES,
    cellSelection: true,
    popupParent: document.body, // chart container
    columnDefs: [],
    excelStyles,
    onFilterChanged: refreshAggregationAfterFiltering,
}

const getGroupColumnDef = (groupColId: AssetDetails): Feb25ColDef => ({
    headerName: ASSET_DETAIL_LABELS[groupColId],
    headerTooltip: ASSET_DETAIL_LABELS[groupColId],
    headerClass: [Feb25Classes.HeaderL2],
    minWidth: minMaxWidthByColId[groupColId][0],
    maxWidth: minMaxWidthByColId[groupColId][1],
    lockPosition: 'left',
    type: 'string',
    cellClass: (params: CellClassParams) => {
        const footer = params.node.footer
        const isRootLevel = params.node.level === -1

        if (footer) {
            if (isRootLevel) {
                return [Feb25Classes.HighlightTotalCell]
            } else {
                return [Feb25Classes.PurpleBG, ...getExcelGroupClasses(params)]
            }
        } else {
            return getExcelGroupClasses(params)
        }
    },
    chartDataType: 'category',
})

export const getMetricTimeSeriesColDefs = ({ conditionalFormatRules = [], columns, tableTitle, assetDetails = [], scenarioId, totalCol, groupBy, assetType, timeInterval, valueType }: {
    conditionalFormatRules?: ConditionalFormatRule[]
    columns: ReportingPortfolioSelectedValueItem['columns']
    tableTitle: string
    assetDetails?: AssetDetails[] | null
    scenarioId?: ReportScenario | null
    totalCol?: PinTotalColumn | null
    groupBy?: AssetDetails[] | null
    assetType?: AssetType | null
    timeInterval?: TimeInterval | null
    valueType?: `${ReportingDataValueType}`
}): Feb25ColGroupDef[] => {
    if (!scenarioId || !assetType || !Array.isArray(assetDetails)) return []
    const dataAggFunc = getAggFunc(scenarioId, assetType)
    const groupColId = groupBy?.[0]
    const isVariancePercentScenario = scenarioId === ReportScenario.VARIANCE_PERCENT
    const isPercentMetric = valueType === ReportingDataValueType.PERCENTAGE
    const isPSFScenario = assetType !== AssetType.MULTIFAMILY && [
        ReportScenario.ACTUAL_PSF,
        ReportScenario.BUDGET_PSF,
        ReportScenario.VARIANCE_PSF,
        ReportScenario.UNDERWRITING_PSF,
        ReportScenario.VARIANCE_UNDERWRITING_PSF,
        ReportScenario.VARIANCE_BUDGET_UNDERWRITING_PSF,
    ].includes(scenarioId)
    const excelTypeClass = (isVariancePercentScenario || isPercentMetric) ? ExcelClasses.Percentage1Decimal : ExcelClasses.Currency

    // Put Units and Square Feet cols last if they are selected
    const sortedAssetDetails = assetDetails?.sort((a, b) => {
        if (a === AssetDetails.UNITS && b === AssetDetails.SQUARE_FEET) { return -1 }
        if (a === AssetDetails.SQUARE_FEET && b === AssetDetails.UNITS) { return 1 }
        if (a === AssetDetails.UNITS || a === AssetDetails.SQUARE_FEET) { return 1 }
        if (b === AssetDetails.UNITS || b === AssetDetails.SQUARE_FEET) { return -1 }
        return 0
    })

    // Add group column and required details column ids
    const detailsColIds: AssetDetails[] = []
    if (groupColId && !assetDetails?.includes(groupColId)) { detailsColIds.push(groupColId) }
    if (!assetDetails?.includes(AssetDetails.UNITS)) { detailsColIds.push(AssetDetails.UNITS) }
    if (!assetDetails?.includes(AssetDetails.SQUARE_FEET)) { detailsColIds.push(AssetDetails.SQUARE_FEET) }
    // then selected details
    detailsColIds.push(...(sortedAssetDetails ?? []))

    // ColDefs: Push group column defs first
    const assetDetailsColumns = groupColId ? [getGroupColumnDef(groupColId)] : []
    // then push coldefs for other details
    assetDetailsColumns.push(
        {
            headerName: 'Asset Name',
            headerTooltip: 'Asset Name',
            headerClass: [Feb25Classes.HeaderL2, ...(groupColId ? [Feb25Classes.IndentLeft] : [])],
            field: 'name',
            lockPosition: 'left',
            pinned: true,
            type: 'string',
            // Show total / average in the first column if no group column
            aggFunc: groupColId ? null : grandTotalAverageRenderer,
            filter: 'agSetColumnFilter',
            cellClass: (params: CellClassParams) => [Feb25Classes.HighlightTotalCell, ...getExcelGroupClasses(params), ...(groupColId ? [Feb25Classes.IndentLeft] : [])],
            chartDataType: 'category',
            cellRenderer: (params: ICellRendererParams<ReportingPortfolioSelectedValueRow>) => {
                const footer = params.node.footer
                const isRootLevel = params.node.level === -1
                if (footer) {
                    if (isRootLevel) {
                        return 'Total / Average'
                    } else {
                        return `${params.node.key} Total`
                    }
                } else {
                    return params.value
                }
            },
        },
        ...detailsColIds.map((detailId, i): Feb25ColDef => {
            const isNumericValueCol = detailId === AssetDetails.UNITS || detailId === AssetDetails.SQUARE_FEET
            const rightBorderClass = i === detailsColIds.length - 1 ? Feb25Classes.RightBorder1 : Feb25Classes.None
            const rightAlignedHeaderClass = isNumericValueCol ? Feb25Classes.RightAlignedHeader : Feb25Classes.None
            const rightAlignedCellClass = isNumericValueCol ? [Feb25Classes.RightAlignedCell, ExcelClasses.Currency] : [Feb25Classes.None]

            return ({
                headerName: ASSET_DETAIL_LABELS[detailId],
                headerTooltip: ASSET_DETAIL_LABELS[detailId],
                headerClass: [rightBorderClass, rightAlignedHeaderClass, Feb25Classes.HeaderL2],
                cellClass: (params: CellClassParams) => [rightBorderClass, Feb25Classes.HighlightTotalCell, ...rightAlignedCellClass, ...getExcelGroupClasses(params)],
                field: ASSET_DETAIL_ID_TO_FIELD[detailId],
                valueGetter: detailId === AssetDetails.ASSET_CLASS
                    ? (params) => ASSET_TYPE_LABEL.get(params?.data?.asset_class) ?? ''
                    : isNumericValueCol
                        ? (params) => params?.data?.[params.column.getColId()] ?? 0 // replace null / undefined with 0 in excel export
                        : undefined,
                aggFunc: isNumericValueCol ? getAggFuncWithIntermediateCalcs('sum') : null,
                // Hide column that used for groups and columns that are not selected
                hide: detailId === groupColId || !assetDetails?.includes(detailId),
                rowGroup: groupColId === detailId,
                minWidth: minMaxWidthByColId[detailId][0],
                maxWidth: minMaxWidthByColId[detailId][1],
                pinned: true,
                type: isNumericValueCol ? 'money' : 'string',
                filter: isNumericValueCol ? 'agNumberColumnFilter' : 'agSetColumnFilter',
                chartDataType: 'excluded',
            })
        }),
    )

    const colDefs: Feb25ColGroupDef[] = [
        {
            // Asset Details section
            headerName: tableTitle,
            headerTooltip: tableTitle,
            children: assetDetailsColumns,
            headerClass: [Feb25Classes.HeaderL2, Feb25Classes.RightBorder1],
            marryChildren: true,
        },
        {
            // Time series data section
            headerClass: [Feb25Classes.HeaderL2],
            children: [
                ...columns.flatMap(({ id, name }) => {
                    const dataCol: Feb25ColDef = {
                        headerName: getHeaderNameForValueCols(id, timeInterval),
                        headerTooltip: name,
                        headerClass: [Feb25Classes.HeaderL2, Feb25Classes.RightAlignedHeader],
                        cellClass: (params: CellClassParams) => [Feb25Classes.RightAlignedCell, Feb25Classes.HighlightTotalCell, excelTypeClass, ...getExcelGroupClasses(params)],
                        field: `values.${id}.${scenarioId}`,
                        flex: 1,
                        aggFunc: isPercentMetric ? 'avg' : dataAggFunc,
                        fractionDigits: isPercentMetric ? 1 : isPSFScenario ? 2 : 0,
                        type: isPercentMetric ? 'percentage' : colTypeByScenario[scenarioId],
                        filter: 'agNumberColumnFilter',
                        valueGetter: (params) => params?.data?.values?.[id]?.[scenarioId] ?? 0,
                        chartDataType: 'series',
                        cellRenderer: (params: ICellRendererParams<ReportingPortfolioSelectedValueRow>) => cellRenderStyledFromConditionalFormatRules(conditionalFormatRules, params, params?.data?.values?.[id]?.[scenarioId] ?? 0, id, scenarioId, 'all-data'),
                    }

                    // add hidden budget column for variance percent calculation
                    if (isVariancePercentScenario) {
                        return [
                            {
                                field: `values.${id}.${ReportScenario.BUDGET}`,
                                aggFunc: getAggFuncWithIntermediateCalcs('sum'),
                                hide: true,
                            },
                            {
                                ...dataCol,
                                fractionDigits: 1,
                                // override aggregation for WAVG calculation
                                aggFunc: getWeightedAvgAggregation(`values.${id}.${ReportScenario.BUDGET}`),
                            },
                        ]
                    } else {
                        return [dataCol]
                    }
                }),
            ],
        },
    ]

    // Total column
    if (totalCol === PinTotalColumn.Right || totalCol === PinTotalColumn.Left) {
        const totalDataCol: Feb25ColDef = {
            headerName: 'Total',
            headerTooltip: 'Total',
            headerClass: [Feb25Classes.RightAlignedHeader, Feb25Classes.PurpleBG],
            cellClass: (params: CellClassParams) =>
                (params.node.footer)
                    ? [Feb25Classes.RightAlignedCell, Feb25Classes.HighlightTotalCell]
                    : [Feb25Classes.RightAlignedCell, Feb25Classes.PurpleBG, Feb25Classes.HighlightTotalCell, excelTypeClass, ...getExcelGroupClasses(params)],
            field: `values.total.${scenarioId}`,
            pinned: PinnedPositionByTotalCol[totalCol],
            maxWidth: 150,
            minWidth: 80,
            aggFunc: (isVariancePercentScenario)
                ? getWeightedAvgAggregation(`values.total.${ReportScenario.BUDGET}`)
                : isPercentMetric ? 'avg' : dataAggFunc,
            type: isPercentMetric ? 'percentage' : colTypeByScenario[scenarioId],
            filter: 'agNumberColumnFilter',
            valueGetter: (params) => params?.data?.values?.total?.[scenarioId] ?? 0,
            fractionDigits: (isPercentMetric || isVariancePercentScenario) ? 1 : 0,
            chartDataType: 'series',
            cellRenderer: (params: ICellRendererParams<ReportingPortfolioSelectedValueRow>) => cellRenderStyledFromConditionalFormatRules(conditionalFormatRules, params, params?.data?.values?.total?.[scenarioId] ?? 0, 'total', scenarioId, 'total'),
        }

        // add hidden budget column for variance percent calculation
        const hiddenBudgetCol = {
            field: `values.total.${ReportScenario.BUDGET}`,
            aggFunc: getAggFuncWithIntermediateCalcs('sum'),
            hide: true,
        }

        colDefs.push({
            headerClass: [Feb25Classes.PurpleBG],
            children: isVariancePercentScenario ? [totalDataCol, hiddenBudgetCol] : [totalDataCol],
        })
    }

    return colDefs
}
