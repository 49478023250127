import { MutableRefObject } from 'react'

import {
    GridState,
    StateUpdatedEvent,
    ExcelRow,
    IAggFuncParams,
    ValueFormatterFunc,
    ValueFormatterParams,
    FilterChangedEvent,
} from '@ag-grid-community/core'

import { ReportingPnLRow } from '@/api/reportingData/reportingProfitAndLoss'
import { ReportingTableFilters, ReportingTableItem, ReportingTableSpacer } from '@/api/reportingTable/reportingTable'
import {
    formatDate,
    formatMoney,
    formatNumber,
    formatPercentageNumber,
    formatString,
} from '@/components/tables/AgGridTable/AgGridTable.utils'
import { ReportColumnAggregation } from '@/constants/reportColumnAggregation'
import { ReportingDataValueType } from '@/constants/reportingDataValueTypes'
import { TimeInterval } from '@/constants/timeInterval'
import { TimeSettings } from '@/constants/timeSettings'
import { Asset } from '@/models/asset'
import { AssetType } from '@/models/core'
import {
    ReportingTableSaveMutation,
} from '@/pages/TablesPage/TableBuilderPage/TableBuilderTables/useReportingTableSaveMutation'
import { monthIndToString } from '@/utils/date/monthInd'
import { QuarterString, quarterToString } from '@/utils/date/quarter'
import { notify } from '@/utils/notify'

import { TableType } from '../../tableType'
import { ConditionalFormatRule } from '../ConditionalRuleEditor/ConditionalRuleEditor.types'
import { TableMeta } from '../TableBuilder'
import { TABLE_BUILDER_MESSAGES } from '../TableBuilder/TableBuilder.constants'

export const GRID_EVENT_SOURCES_TO_IGNORE = new Set([
    'gridInitializing',
    'sideBar',
    'focusedCell',
    'scroll',
])

const GRID_PROPS_TO_SAVE = new Set<keyof GridState>([
    'aggregation',
    'columnGroup',
    'columnOrder',
    'columnPinning',
    'columnSizing',
    'columnVisibility',
    'filter',
    'pivot',
    'rowGroup',
    'sort',
])

export const getGridPropsToSave = (gridState: GridState = {}): Partial<GridState> => {
    const currentState: Partial<GridState> = Object
        .keys(gridState)
        .reduce((acc, key: keyof GridState) => {
            if (GRID_PROPS_TO_SAVE.has(key) && gridState[key]) {
                acc[key] = gridState[key]
            }
            return acc
        }, {})

    // Need to clean up undefined values as it breaks the comparison in isEqual

    // Remove advanced filter model (it's undefined by default)
    if (Object.hasOwn(currentState.filter ?? {}, 'advancedFilterModel')) {
        delete currentState.filter?.advancedFilterModel
    }

    // Remove flex from column sizing if it's undefined
    currentState.columnSizing?.columnSizingModel?.forEach((column) => {
        if (!column.flex) {
            delete column.flex
        }
    })

    return currentState
}

export const saveGridStateToRef = (e: StateUpdatedEvent, gridStateRef: MutableRefObject<GridState | undefined>) => {
    const { sources } = e
    if (sources.every((source) => GRID_EVENT_SOURCES_TO_IGNORE.has(source))) {
        return
    }

    gridStateRef.current = e.api.getState()
}

export const getTableExportMeta = (
    assets: Asset[] | undefined,
    assetIds: Array<number | null> | null | undefined,
    monthIndex: number | null | undefined,
): ExcelRow[] => {
    const assetIdsSet = new Set(assetIds)
    return [
        { cells: [] },
        {
            cells: [
                {
                    data: {
                        value: `Assets: ${(assets ?? []).filter(a => assetIdsSet.has(a.id ?? 0)).map(a => a.name).join(', ')}`,
                        type: 'String',
                    },
                },
            ],
        },
        {
            cells: [
                {
                    data: {
                        value: `Time period: ${monthIndex ? monthIndToString(monthIndex) : ''}`,
                        type: 'String',
                    },
                },
            ],
        },
        { cells: [] },
    ]
}

export const saveReportingTable = async ({ tableMeta, currentState, isCreationMode, saveMutation, id, tableType, config, assetType }:
{
    tableMeta: TableMeta
    currentState: GridState
    isCreationMode: boolean
    saveMutation: ReportingTableSaveMutation
    id: string | number
    tableType: TableType
    config?: {
        filters?: ReportingTableFilters
        order?: string[]
        spacers?: ReportingTableSpacer[]
        visible_columns?: string[]
        conditional_format_rules?: { rules: ConditionalFormatRule[] }
    }
    assetType?: AssetType | null
}) => {
    const updatedState = getGridPropsToSave(currentState)
    let tableData: ReportingTableItem | null = null
    try {
        if (isCreationMode) {
            tableData = await saveMutation.create.mutateAsync({
                name: tableMeta.name ?? 'New table',
                company_id: saveMutation.me.isAdministratorMode ? tableMeta.company_id : undefined,
                trial_balance_ledger: tableMeta.trial_balance_ledger,
                rent_roll_ledger: tableMeta.rent_roll_ledger,
                type: tableType,
                aggrid_state: updatedState,
                description: tableMeta.description,
                config,
                asset_type: assetType as AssetType,
            })
            notify.success(TABLE_BUILDER_MESSAGES.saved)
        } else {
            tableData = await saveMutation.update.mutateAsync({
                id,
                updatedItemData: {
                    name: tableMeta.name ?? 'New table',
                    company_id: saveMutation.me.isAdministratorMode ? tableMeta.company_id : undefined,
                    trial_balance_ledger: tableMeta.trial_balance_ledger,
                    aggrid_state: updatedState,
                    config,
                },
            })
            notify.success(TABLE_BUILDER_MESSAGES.updated)
        }
    } catch (e) {
        const message = e?.response?.data?.name?.[0] || TABLE_BUILDER_MESSAGES.error
        notify.error(message)
    }
    return tableData
}

export const getTimePeriodParam = ({ startMonth, endMonth, startQuarter, endQuarter, startYear, endYear, timeInterval }: {
    startMonth?: number | null
    endMonth?: number | null
    startQuarter?: string | null
    endQuarter?: string | null
    startYear?: number | null
    endYear?: number | null
    timeInterval?: TimeInterval | null
}) => {
    switch (timeInterval) {
        case TimeInterval.MONTH:
            return (startMonth && endMonth ? `${startMonth}-${endMonth}` : undefined)
        case TimeInterval.QUARTER:
            return (startQuarter && endQuarter ? `${startQuarter}-${endQuarter}` : undefined)
        case TimeInterval.YEAR:
            return (startYear && endYear ? `${startYear}-${endYear}` : undefined)
        default:
            return undefined
    }
}

/**
 * Returns func 'sum' or 'avg' and holds intermediate calculated values for Weighted Average aggregation
 */
export const getAggFuncWithIntermediateCalcs = (func?: `${ReportColumnAggregation}`) => (params: IAggFuncParams) => {
    if (func !== 'sum' && func !== 'avg') {
        throw new Error(`func must be 'sum' of 'avg' but passed '${func}'`)
    }

    // the average will be the sum / count
    let sum = 0
    let count = 0

    params.values.forEach((value) => {
        const groupNode = value !== null && value !== undefined && typeof value === 'object'
        if (groupNode) {
            // we are aggregating groups, so we take the
            // aggregated values to calculated a weighted average
            sum += value.sum
            count += value.count
        } else {
            // skip values that are not numbers (ie skip empty values)
            if (typeof value === 'number') {
                sum += value
                count++
            }
        }
    })

    // avoid divide by zero error
    let avg = 0
    if (count !== 0) {
        avg = sum / count
    }

    // the result will be an object. when this cell is rendered, only the avg is shown.
    // however when this cell is part of another aggregation, the count is also needed
    // to create a weighted average for the next level.
    const result = {
        sum,
        count,
        avg,
        // trick to get the default cellRenderer to display the avg value
        toString: function () {
            return `${this[func]}`
        },
        values: params.values,
    }

    return result
}

/**
 * Returns the weighted average aggregation function by the column name of the weights.
 * Used in conjunction with the 'getAggFuncWithIntermediateCalcs'
 * @param weightsColName weights column name
 */
export const getWeightedAvgAggregation = (weightsColName: string) => (params: IAggFuncParams) => {
    const sfValues: number[] | undefined = params.rowNode.aggData?.[weightsColName]?.values
    const sfSum: number | undefined = params.rowNode.aggData?.[weightsColName]?.sum

    let sumProduct = 0
    let sfSumGroups: null | number = null

    params.values.forEach((value, i) => {
        const groupNode = value !== null && value !== undefined && typeof value === 'object'
        if (groupNode) {
            // calculate WA from group values
            sumProduct += value.sumP
            sfSumGroups = sfSumGroups ? sfSumGroups + value.sum : value.sum
        } else {
            // calculate WA from rows
            if (typeof value === 'number') {
                sumProduct += value * (sfValues?.[i] ?? 1)
            }
        }
    })

    // avoid divide by zero error
    let avg = 0
    // calculate WA from rows
    if (sfSum && typeof sfSum === 'number' && sfSumGroups === null) {
        avg = sumProduct / sfSum
    } else if (sfSumGroups) {
        // calculate WA from group values
        avg = sumProduct / sfSumGroups
    }

    const result = {
        sumP: sumProduct,
        sum: sfSumGroups ?? sfSum,
        avg,
        // trick to get the default cellRenderer to display the avg value
        toString: function () {
            return `${this.avg}`
        },
    }

    return result
}

/**
 * Used for groups aggregation. Returns object instead of value.
 * 'toString' method is called in cell renderer
 * 'sum' is used for groups aggregation
 */
export const modifiedSumAggregation = (params: IAggFuncParams) => {
    let sum: null | number = null

    params.values.forEach((value) => {
        const groupNode = value !== null && value !== undefined && typeof value === 'object'
        if (groupNode) {
            sum = sum ? sum + value.sum : value.sum
        } else {
            if (typeof value === 'number') {
                sum = sum ? sum + value : value
            }
        }
    })

    return {
        sum,
        toString: function () {
            return `${this.sum}`
        },
    }
}

export const getPeriodString = (timeInterval?: TimeInterval | null, timePeriod?: string | number | null) => {
    if (!timePeriod?.toString().includes('-')) { return '' }
    const [start, end] = timePeriod?.toString().split('-') ?? []
    switch (timeInterval) {
        case TimeInterval.YEAR:
            return `${start} - ${end}`
        case TimeInterval.QUARTER:
            return `${quarterToString(start as QuarterString)} - ${quarterToString(end as QuarterString)}`
        case TimeInterval.MONTH:
        default:
            return `${monthIndToString(Number(start))} - ${monthIndToString(Number(end))}`
    }
}

export const getTimePeriodAsString = (timeSettings?: TimeSettings | null, timeInterval?: TimeInterval | null, timePeriod?: string | number | null) => {
    switch (timeSettings) {
        case TimeSettings.SINGLE:
            return timeInterval === TimeInterval.MONTH && timePeriod ? monthIndToString(Number(timePeriod)) : `${timePeriod}`
        case TimeSettings.SERIES:
            return getPeriodString(timeInterval, timePeriod)
        case TimeSettings.MULTIPLE:
            return timePeriod ? monthIndToString(Number(timePeriod)) : ''
    }
}

export const getHeaderNameForValueCols = (id: string, timeInterval?: TimeInterval | null) => {
    switch (timeInterval) {
        case TimeInterval.YEAR:
            return id
        case TimeInterval.QUARTER:
            return quarterToString(id as QuarterString)
        case TimeInterval.MONTH:
        default:
            return monthIndToString(Number(id))
    }
}

export const FORMATTER_BY_VALUE_TYPE: Record<`${ReportingDataValueType}`, ValueFormatterFunc<ReportingPnLRow>> = {
    M: formatMoney,
    P: formatPercentageNumber,
    N: formatNumber,
    S: formatString,
    D: formatDate,
}

export const formatterByValueType = (valueType: `${ReportingDataValueType}`, params: ValueFormatterParams) => {
    // @ts-expect-error custom fractionDigits
    const fractionDigits = params.colDef.fractionDigits ? params.colDef.fractionDigits : (valueType === ReportingDataValueType.PERCENTAGE) ? 1 : 0
    return FORMATTER_BY_VALUE_TYPE[valueType]({
        ...params,
        // @ts-expect-error custom fractionDigits
        fractionDigits,
    })
}

// Show Total / Average label only for root level
export const grandTotalAverageRenderer = (params: IAggFuncParams) => {
    const isRootLevel = params.rowNode.level === -1
    return isRootLevel ? 'Total / Average' : undefined
}

export const refreshAggregationAfterFiltering = (e: FilterChangedEvent) => e.api.refreshClientSideRowModel('aggregate')
