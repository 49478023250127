import { createEnumParam } from 'use-query-params'

import { TimeSettings, TIME_SETTINGS_LABELS } from '@/constants/timeSettings'

const TimeSettingsParam = createEnumParam(Object.values(TimeSettings))

export const TIME_SETTINGS_INPUT_QUERY_PARAM = {
    type: TimeSettingsParam,
    name: 'time-settings',
}

export const TIME_SETTINGS_OPTIONS = Object.entries(TIME_SETTINGS_LABELS).map(([value, label]) => ({
    value,
    label,
}))
