import { useEffect, useRef } from 'react'

import cn from 'classnames'

import { Icon, SelectOptionProps, Text, SelectOptionDefault } from '@/components/base'

import styles from './SelectOptionBase.module.scss'

export const SelectOptionBase = <Option = SelectOptionDefault>(props: SelectOptionProps<Option>) => {
    const {
        option,
        labelFn,
        selected,
        active,
        onClick,
        onMouseEnter,
        seletedInitalMark = false,
        maxLines,
        startEl = null,
        disabled = false,
    } = props

    const ref = useRef<HTMLLIElement>(null)

    useEffect(() => {
        if (active && ref.current) {
            ref.current.scrollIntoView({
                block: 'nearest',
                behavior: 'smooth',
            })
        }
    }, [active])

    return (
        <li
            className={cn(styles.option, {
                [styles.active]: active,
                [styles.disabled]: disabled,
            })}
            role='option'
            aria-selected={selected && active}
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            ref={ref}
            data-testid='option'
            title={labelFn(option)}
        >
            {startEl}
            <Text
                className={styles.text}
                color={selected ? 'colorsPrimaryPurple' : disabled ? 'colorsSecondaryGrey500' : 'colorsPrimaryGrey'}
                variant='smallTextMediumDefault'
                text={labelFn(option)}
                maxLines={maxLines}
            />

            {selected && (
                <Icon
                    name='check'
                    color='colorsPrimaryPurple'
                    ariaHidden
                />
            )}
            {(seletedInitalMark && !selected) && (
                <Text
                    variant='labelMediumDefault'
                    color='colorsSecondaryGrey400'
                    text='current'
                />
            )}
        </li>
    )
}
