import { forwardRef, RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { ExcelExportParams, GridState } from '@ag-grid-community/core'
import isEqual from 'lodash/isEqual'
import { AND, EQ } from 'mobx-orm'
import { createPortal } from 'react-dom'

import { AgGridReact } from '@ag-grid-community/react'
import { useNavigate } from 'react-router'

import { useAssetByIdQuery } from '@/api/asset/asset'
import { useReportingBalanceSheetQuery } from '@/api/reportingData/reportingBalanceSheet'
import { useReportingBalanceSheetMetricsQuery } from '@/api/reportingData/reportingBalanceSheetMetrics'
import { ReportingPnLRowType } from '@/api/reportingData/reportingProfitAndLoss'
import { ReportingTableFilters, useReportingTableByIdQuery } from '@/api/reportingTable/reportingTable'
import { MONTH_RANGE_END_QUERY_PARAM, MONTH_RANGE_START_QUERY_PARAM, QUARTER_RANGE_END_QUERY_PARAM, QUARTER_RANGE_START_QUERY_PARAM, TIME_INTERVAL_INPUT_QUERY_PARAM, YEAR_RANGE_END_QUERY_PARAM, YEAR_RANGE_START_QUERY_PARAM } from '@/components/baseInputs'
import { Layout } from '@/components/containers'
import { ASSET_DETAILS_QUERY_PARAM, ASSET_SIDE_MODAL_INPUT_QUERY_PARAM } from '@/components/models/asset'
import { AgGridTable } from '@/components/tables'
import { useTableRangeSelectionModules } from '@/components/tables/utils/useTableRangeSelectionModules'
import {
    GLOBAL_REPORT_MONTH_RANGE_END_QUERY_PARAM,
    GLOBAL_REPORT_MONTH_RANGE_START_QUERY_PARAM,
    GLOBAL_REPORT_MULTY_ASSET_QUERY_PARAM,
} from '@/components/widgets/reports/reports.constants'
import { BSReportScenario } from '@/constants/reportScenario'
import { TimeSettings } from '@/constants/timeSettings'
import { useInputState } from '@/hooks'
import { TableType } from '@/pages/TablesPage/tableType'
import { getRoute } from '@/utils/routing/getRoute'

import { NoDataMessage } from '../../NoDataMessage'
import { CancelSaveButtons } from '../../TableBuilder/CancelSaveButtons'
import {
    ACCOUNT_CODES_INPUT_QUERY_PARAM,
    BALANCE_SHEET_SCENARIO_INPUT_QUERY_PARAM,
    PinTotalColumn,
    PIN_TOTAL_COL_INPUT_QUERY_PARAM,
    TABLE_ID_ROWS_INPUT_QUERY_PARAM,
    PNL_METRIC_INPUT_QUERY_PARAM,
} from '../../TableBuilderFilters'
import {
    TABLE_TB_LEDGER_INPUT_QUERY_PARAM,
    TABLE_RR_LEDGER_INPUT_QUERY_PARAM,
    TABLE_ASSET_TYPE_PARAM,
    getReportTableTbLedgerInputQueryParam,
    getReportTableRrLedgerInputQueryParam,
    getReportTableAssetTypeParam,
    getReportQuarterRangeStartQueryParam,
    getReportYearRangeStartQueryParam,
    getReportYearRangeEndQueryParam,
    getReportTimeIntervalInputQueryParam,
    getReportAssetDetailsInputQueryParam,
    getReportPinTotalColInputQueryParam,
    getReportShowCodesInputQueryParam,
    getReportTableIdRowsInputQueryParam,
    getQuarterRangeEndQueryParam,
    getReportBalanceSheetScenarioInputQueryParam,
    getPnlMetricInputQueryParam,
} from '../../TableBuilderPage.constants'
import { ASSET_DETAIL_TO_ENTITY_ID, getContextMenuItems, getPnLTimeSeriesColDefs, pnLTimeSeriesTableGridOptions as gridOptions, TABLE_ID_ROW_TO_ENTITY_ID } from '../PnLTimeSeriesTable/PnLTimeSeriesTable.constants'
import { normalizePercentageValues, processPnLTableTotalCells } from '../PnLTimeSeriesTable/PnLTimeSeriesTable.utils'
import { statusBar } from '../TableBuilderTables.constants'
import { TableBuilderTableProps } from '../TableBuilderTables.types'
import { getGridPropsToSave, getPeriodString, getTimePeriodParam, saveGridStateToRef, saveReportingTable } from '../TableBuilderTables.utils'
import { useReportingTableSaveMutation } from '../useReportingTableSaveMutation'

/**
 * Balance Sheet - Time Series Table
 */
export const BSTimeSeriesTable = forwardRef((props: TableBuilderTableProps, ref: RefObject<AgGridReact>) => {
    const {
        tableMeta = {},
        buttonContainer,
        onCancel,
        onGridReady,
        insideReport = false,
        id,
        onFirstDataRendered,
        tablesListRouteConfigKey,
        builderRouteConfigKey,
    } = props

    const navigate = useNavigate()
    const rangeSelectionModules = useTableRangeSelectionModules()
    const isCreationMode = id === 'new'
    const fallbackTableRef = useRef<AgGridReact>(null)
    const tableRef = ref ?? fallbackTableRef
    const gridStateRef = useRef<GridState>() // Retain grid state between renders
    const areDefaultMetricsSet = useRef(false)
    const openedRowGroups = useRef(new Set<string>())

    const [isRefreshing, setIsRefreshing] = useState(false)
    const [isTableDirty, setIsTableDirty] = useState(false)
    const saveMutation = useReportingTableSaveMutation()
    const { data: loadedTableData } = useReportingTableByIdQuery(id, { enabled: !isCreationMode })

    // load grid state once
    if (!gridStateRef.current && loadedTableData?.aggrid_state) { gridStateRef.current = loadedTableData.aggrid_state }

    const [tBLedgerId] = useInputState(
        insideReport ? getReportTableTbLedgerInputQueryParam(id) : TABLE_TB_LEDGER_INPUT_QUERY_PARAM,
    )
    const [rRLedgerId] = useInputState(
        insideReport ? getReportTableRrLedgerInputQueryParam(id) : TABLE_RR_LEDGER_INPUT_QUERY_PARAM,
    )
    const [assetType] = useInputState(
        insideReport ? getReportTableAssetTypeParam(id) : TABLE_ASSET_TYPE_PARAM,
    )

    const [assetIds, setAssetIds] = useInputState(insideReport ? GLOBAL_REPORT_MULTY_ASSET_QUERY_PARAM : ASSET_SIDE_MODAL_INPUT_QUERY_PARAM)
    const [startMonth, setStartMonth] = useInputState(insideReport ? GLOBAL_REPORT_MONTH_RANGE_START_QUERY_PARAM : MONTH_RANGE_START_QUERY_PARAM)
    const [endMonth, setEndMonth] = useInputState(insideReport ? GLOBAL_REPORT_MONTH_RANGE_END_QUERY_PARAM : MONTH_RANGE_END_QUERY_PARAM)

    const [timeInterval, setTimeInterval] = useInputState(
        insideReport ? getReportTimeIntervalInputQueryParam(id) : TIME_INTERVAL_INPUT_QUERY_PARAM,
    )
    const [startQuarter, setStartQuarter] = useInputState(
        insideReport ? getReportQuarterRangeStartQueryParam(id) : QUARTER_RANGE_START_QUERY_PARAM,
    )
    const [endQuarter, setEndQuarter] = useInputState(
        insideReport ? getQuarterRangeEndQueryParam(id) : QUARTER_RANGE_END_QUERY_PARAM,
    )
    const [startYear, setStartYear] = useInputState(
        insideReport ? getReportYearRangeStartQueryParam(id) : YEAR_RANGE_START_QUERY_PARAM,
    )
    const [endYear, setEndYear] = useInputState(
        insideReport ? getReportYearRangeEndQueryParam(id) : YEAR_RANGE_END_QUERY_PARAM,
    )
    const [scenarioId, setScenarioId] = useInputState(
        insideReport ? getReportBalanceSheetScenarioInputQueryParam(id) : BALANCE_SHEET_SCENARIO_INPUT_QUERY_PARAM,
    )
    const [assetDetails, setAssetDetails] = useInputState(
        insideReport ? getReportAssetDetailsInputQueryParam(id) : ASSET_DETAILS_QUERY_PARAM,
    )
    const [totalCol, setTotalCol] = useInputState(
        insideReport ? getReportPinTotalColInputQueryParam(id) : PIN_TOTAL_COL_INPUT_QUERY_PARAM,
    )
    const [tableIdRows, setTableIdRows] = useInputState(
        insideReport ? getReportTableIdRowsInputQueryParam(id) : TABLE_ID_ROWS_INPUT_QUERY_PARAM,
    )
    const [showCodes, setShowCodes] = useInputState(
        insideReport ? getReportShowCodesInputQueryParam(id) : ACCOUNT_CODES_INPUT_QUERY_PARAM,
    )
    const [metricIds, setMetricIds] = useInputState(
        insideReport ? getPnlMetricInputQueryParam(id) : PNL_METRIC_INPUT_QUERY_PARAM,
    )

    const showAccountCodes = showCodes === '1'

    const assetDetailRowIds = useMemo(() => new Set(assetDetails?.map(d => ASSET_DETAIL_TO_ENTITY_ID[d])), [assetDetails])
    const reportDetailRowIds = useMemo(() => new Set(tableIdRows?.map(d => TABLE_ID_ROW_TO_ENTITY_ID[d])), [tableIdRows])
    const scenario = scenarioId === BSReportScenario.ENDING_BALANCE ? 'Ending Balance' : 'Activity'

    const timePeriod = useMemo(
        () => getTimePeriodParam({
            startMonth,
            endMonth,
            startQuarter,
            endQuarter,
            startYear,
            endYear,
            timeInterval,
        }),
        [startMonth, endMonth, startQuarter, endQuarter, startYear, endYear, timeInterval],
    )

    const areFiltersSet = Boolean(assetIds && timePeriod && scenarioId)

    const { data: reportData, isLoading, isFetched, isSuccess } = useReportingBalanceSheetQuery(
        {
            filter: AND(
                EQ('asset_ids', assetIds),
                EQ('trial_balance_ledger_id', tBLedgerId),
                EQ('rent_roll_ledger_id', rRLedgerId),
                EQ('metric_ids', metricIds),
                EQ('time_settings', TimeSettings.SERIES),
                EQ('time_period', timePeriod),
                EQ('time_interval', timeInterval),
                EQ('scenario', scenarioId),
            ),
        },
        { enabled: areFiltersSet },
    )

    const { data: bsMetrics } = useReportingBalanceSheetMetricsQuery(
        {
            filter: AND(
                EQ('asset_ids', assetIds),
                EQ('trial_balance_ledger_id', tBLedgerId),
                EQ('rent_roll_ledger_id', rRLedgerId),
            ),
        },
        { enabled: Boolean(isCreationMode && assetIds) },
    )

    // set default metrics once in creation mode
    const defaultBsMetrics = useMemo(() => bsMetrics?.filter(m => m.is_default), [bsMetrics])
    useEffect(() => {
        if (isCreationMode && defaultBsMetrics?.length && !areDefaultMetricsSet.current) {
            setMetricIds(defaultBsMetrics.map(m => m.id))
            areDefaultMetricsSet.current = true
        }
    }, [defaultBsMetrics, isCreationMode, setMetricIds])

    const [rows, columns] = useMemo(() => [reportData?.[0]?.rows ?? [], reportData?.[0]?.columns ?? []], [reportData])

    const filteredRows = useMemo(() => {
        const filtered = normalizePercentageValues(rows)
            .filter(row => {
                if (row.type === ReportingPnLRowType.ReportDetails) {
                    return reportDetailRowIds.has(row.entity_id)
                } else if (row.type === ReportingPnLRowType.AssetDetails) {
                    return assetDetailRowIds.has(row.entity_id)
                } else {
                    return true
                }
            })
        // filter out double separators & first row separator
        return filtered.filter((row, i, arr) => {
            if (row.type === ReportingPnLRowType.Separator) {
                return i === 0 ? false : arr[i - 1].type !== ReportingPnLRowType.Separator
            }
            return true
        })
    }, [rows, assetDetailRowIds, reportDetailRowIds])

    const { data: asset } = useAssetByIdQuery(assetIds?.length && assetIds[0] ? assetIds[0] : undefined, { enabled: Boolean(assetIds?.length) })

    const periodAsString = useMemo(() => getPeriodString(timeInterval, timePeriod), [timeInterval, timePeriod])
    const tableTitles = useMemo(() => {
        const assetScenarioString = `${assetIds?.length && assetIds.length > 1 ? 'Multiple assets' : asset?.name ?? '...'} - ${scenario}`
        return [assetScenarioString, periodAsString]
    }, [assetIds, asset?.name, scenario, periodAsString])

    const columnDefs = useMemo(
        () => getPnLTimeSeriesColDefs({
            conditionalFormatRules: [],
            columns,
            tableTitles,
            totalCol,
            assetType,
            showAccountCodes,
            timeInterval,
        }),
        [columns, tableTitles, totalCol, assetType, showAccountCodes, timeInterval],
    )

    // show total column for activity scenario only
    useEffect(() => {
        if (scenarioId === BSReportScenario.ENDING_BALANCE) {
            setTotalCol(PinTotalColumn.None)
        }
    }, [scenarioId, setTotalCol])

    // table config
    const config = useMemo(() => {
        const filters: ReportingTableFilters = { assetIds: assetIds as number[] }
        if (timeInterval) { filters.timeInterval = timeInterval }
        if (startMonth) { filters.startMonth = startMonth }
        if (endMonth) { filters.endMonth = endMonth }
        if (startQuarter) { filters.startQuarter = startQuarter }
        if (endQuarter) { filters.endQuarter = endQuarter }
        if (startYear) { filters.startYear = startYear }
        if (endYear) { filters.endYear = endYear }
        if (assetDetails) { filters.assetDetails = assetDetails }
        if (scenarioId) { filters.bsScenario = scenarioId }
        if (metricIds) { filters.bsMetricIds = metricIds }
        if (totalCol) { filters.pinTotalColumn = totalCol }
        if (tableIdRows) { filters.tableIdRows = tableIdRows }
        if (showCodes) { filters.showCodes = showCodes }
        return ({ filters })
    }, [assetDetails, assetIds, endMonth, endQuarter, endYear, metricIds, scenarioId, showCodes, tableIdRows, startMonth, startQuarter, startYear, timeInterval, totalCol])

    const checkIfTableIsDirty = useCallback(() => {
        const loadedState = loadedTableData?.aggrid_state ?? {}
        const currentState = getGridPropsToSave(tableRef.current?.api?.getState())
        const currentConfig = config
        const loadedConfig = loadedTableData?.config ?? {}
        const currentName = tableMeta.name
        const loadedName = loadedTableData?.name

        if (
            isEqual(currentConfig, loadedConfig) &&
            isEqual(currentState, loadedState) &&
            currentName === loadedName
        ) {
            setIsTableDirty(false)
        } else {
            setIsTableDirty(true)
        }
    }, [config, loadedTableData?.aggrid_state, loadedTableData?.config, loadedTableData?.name, tableMeta.name, tableRef])

    useEffect(() => checkIfTableIsDirty(), [checkIfTableIsDirty])

    // set filters from loaded table data
    useEffect(() => {
        if (!loadedTableData) {
            return
        }

        const { filters } = loadedTableData.config
        if (filters) {
            if ((insideReport && !assetIds) || !insideReport) {
                filters.assetIds && setAssetIds(filters.assetIds)
            }
            if ((insideReport && !startMonth) || !insideReport) {
                filters.startMonth && setStartMonth(filters.startMonth)
                filters.endMonth && setEndMonth(filters.endMonth)
            }
            filters.timeInterval && setTimeInterval(filters.timeInterval)
            filters.startQuarter && setStartQuarter(filters.startQuarter)
            filters.endQuarter && setEndQuarter(filters.endQuarter)
            filters.startYear && setStartYear(filters.startYear)
            filters.endYear && setEndYear(filters.endYear)
            filters.assetDetails && setAssetDetails(filters.assetDetails)
            filters.bsScenario && setScenarioId(filters.bsScenario)
            filters.bsMetricIds && setMetricIds(filters.bsMetricIds)
            filters.pinTotalColumn && setTotalCol(filters.pinTotalColumn)
            filters.tableIdRows && setTableIdRows(filters.tableIdRows)
            filters.showCodes && setShowCodes(filters.showCodes)
        }
    }, [loadedTableData, setAssetDetails, setAssetIds, setEndMonth, setEndQuarter, setEndYear, setMetricIds, setShowCodes, setTableIdRows, setStartMonth, setStartQuarter, setStartYear, setTimeInterval, setTotalCol, setScenarioId, insideReport])

    const onTableSave = useCallback(async () => {
        const currentState = tableRef.current?.api?.getState() ?? {}
        const response = await saveReportingTable({
            tableMeta,
            currentState,
            isCreationMode,
            saveMutation,
            id,
            tableType: TableType.BSTimeSeries,
            config,
            assetType,
        })

        if (!response) {
            return
        }

        // redirect after saving
        if (isCreationMode) {
            navigate(getRoute(builderRouteConfigKey, { id: response.id }))
        } else {
            navigate(getRoute(tablesListRouteConfigKey))
        }
    }, [assetType, config, id, isCreationMode, navigate, saveMutation, tableMeta, tableRef])

    const excelExportParams = useMemo((): ExcelExportParams => {
        return ({
            fileName: `Balance Sheet - Time Series - ${scenario} - ${periodAsString}.xlsx`,
            sheetName: periodAsString,
            processCellCallback: processPnLTableTotalCells,
            freezeColumns: 'pinned',
            suppressRowOutline: true,
        })
    }, [periodAsString, scenario])

    // HACK: redraw rows on filter change because group total rows are not updated when data is cached
    // TODO: investigate if this can be fixed another way
    useEffect(() => {
        setIsRefreshing(true)
        setTimeout(() => setIsRefreshing(false), 100)
    }, [filteredRows])

    const onModelUpdated = useCallback(() => {
        if (!isLoading && isFetched && isSuccess) {
            onFirstDataRendered()
        }
    }, [isLoading, isFetched, isSuccess, onFirstDataRendered])

    return (
        <>
            <Layout gap={16} flexGrow={1} direction='column'>
                {areFiltersSet
                    ? (
                        // @ts-expect-error TODO: make AgGridTable generic to be able to pass theme
                        <AgGridTable
                            ref={tableRef}
                            items={filteredRows}
                            {...gridOptions}
                            columnDefs={columnDefs}
                            loading={isLoading || isRefreshing}
                            initialState={gridStateRef.current}
                            lazyModules={rangeSelectionModules}
                            // Track expand state separately because onStateUpdated will reset the expand state if refetched
                            onRowGroupOpened={(params) => {
                                if (params.node.key) {
                                    if (params.node.expanded) {
                                        openedRowGroups.current.add(params.node.key)
                                    } else {
                                        openedRowGroups.current.delete(params.node.key)
                                    }
                                }
                            }}
                            onRowDataUpdated={({ api }) => {
                                // Recover expanded state if data updated
                                api.forEachNode(node => {
                                    if (node.group && node.key) {
                                        if (openedRowGroups.current.has(node.key)) {
                                            node.setExpanded(true)
                                        }
                                    }
                                })
                            }}
                            onStateUpdated={(e) => {
                                saveGridStateToRef(e, gridStateRef)
                                checkIfTableIsDirty()
                            }}
                            statusBar={statusBar}
                            defaultExcelExportParams={excelExportParams}
                            getContextMenuItems={getContextMenuItems}
                            onGridReady={onGridReady}
                            onModelUpdated={onModelUpdated}
                        />
                    )
                    : (<NoDataMessage text='Choose required fields'/>)}
            </Layout>

            {buttonContainer && createPortal(
                <CancelSaveButtons
                    onCancel={onCancel}
                    onSave={onTableSave}
                    isLoading={saveMutation.isPending}
                    isDisabled={!isTableDirty || !tableMeta.name}
                />,
                buttonContainer,
            )}
        </>
    )
})
