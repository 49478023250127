import { CSSProperties, MutableRefObject } from 'react'

import { GridApi, GridState, ICellRendererParams, StateUpdatedEvent } from '@ag-grid-community/core'

import { ReportingPortfolioMetricsItemColumn } from '../../../../../api/reportingData/reportingPortfolioMetrics'
import { ReportingDataValueType } from '../../../../../constants/reportingDataValueTypes'
import { applyFunction, ConditionalFormatRule, ConditionalFormatStyle } from '../../ConditionalRuleEditor/ConditionalRuleEditor.types'
import { applyConditionalFormattingRuleToStyle } from '../../ConditionalRuleEditor/ConditionalRuleEditor.utils'
import { GRID_EVENT_SOURCES_TO_IGNORE } from '../TableBuilderTables.utils'

export const createMetricChart = (api: GridApi | undefined, container: HTMLDivElement | null, totalRowCount: number) => {
    if (!api || !container) {
        return
    }
    const rowCount = api.getDisplayedRowCount() - totalRowCount
    const ranges = api.getCellRanges() || []
    const selectedColumns = ranges.flatMap(range => range.columns.map(col => col.getColId()))
    let selectedSeriesColumns = selectedColumns.filter(colId => colId.startsWith('values') && !colId.includes('total'))

    const { selectedStartIndex, selectedEndIndex } = ranges.reduce((acc, range) => {
        const startRowIndex = range.startRow?.rowIndex ?? 0
        const endRowIndex = range.endRow?.rowIndex ?? 0
        return {
            selectedStartIndex: Math.min(startRowIndex, acc.selectedStartIndex),
            selectedEndIndex: Math.max(endRowIndex, acc.selectedEndIndex),
        }
    }, {
        selectedStartIndex: Infinity,
        selectedEndIndex: -Infinity,
    })

    const isOneCellSelected = (selectedStartIndex === Infinity || selectedEndIndex === -Infinity || selectedStartIndex === selectedEndIndex) && (selectedSeriesColumns.length < 2)
    const [rowStartIndex, rowEndIndex] = isOneCellSelected ? [0, rowCount - 1] : [selectedStartIndex, selectedEndIndex]
    if (isOneCellSelected) {
        const allSeriesColumns = api.getAllDisplayedColumns()
            .map(col => col.getColId())
            .filter(colId => colId.startsWith('values') && !colId.includes('total'))
        selectedSeriesColumns = allSeriesColumns
    }

    selectedSeriesColumns.unshift('name')

    const metricsNames = selectedSeriesColumns
        .filter(col => col !== 'name')
        .map(colId => api.getColumnDef(colId)?.headerName || colId)
        .join(', ')

    const chartData: any[] = []
    for (let i = rowStartIndex; i <= rowEndIndex; i++) {
        const rowNode = api.getDisplayedRowAtIndex(i)
        if (rowNode && !rowNode.data?.name?.toLowerCase().includes('total')) {
            chartData.push(rowNode.data)
        }
    }

    return api.createRangeChart({
        chartContainer: container,
        cellRange: {
            columns: selectedSeriesColumns,
            rowStartIndex,
            rowEndIndex,
        },
        chartType: 'groupedColumn',
        switchCategorySeries: true,
        chartThemeOverrides: {
            common: {
                contextMenu: {
                    enabled: false,
                },
                legend: {
                    item: {
                        label: {
                            formatter: (params) => {
                                const rowData = chartData[(Number(params.value) - 1).toString()]
                                if (!rowData) {
                                    return ''
                                }
                                return rowData?.name
                            },
                        },
                    },
                },
                axes: {
                    category: {
                        label: {
                            formatter: (params) => {
                                const rowData = chartData[params.value]
                                return rowData?.name || params.value
                            },
                        },
                    },
                    number: {
                        label: {
                            formatter: ({ value }) => {
                                if (typeof value !== 'number') {
                                    return value
                                }
                                return value.toLocaleString('en-US', {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0,
                                })
                            },
                        },
                    },

                },
                title: {
                    enabled: true,
                    text: metricsNames,
                },
            },
            bar: {
                series: {
                    tooltip: {
                        renderer: (params) => {
                            const rowData = chartData[(Number(params.title) - 1).toString()]
                            if (!rowData) {
                                return ''
                            }
                            return {
                                title: rowData?.name,
                            }
                        },
                    },
                },
            },
        },
    })
}

/**
 * To keep the order of the columns we need to ignore the state with no value columns in columnOrder prop.
 * This happens when the query is in flight and the grid is not fully initialized.
 */
export const checkColumnOrderAndSaveGridStateToRef = (e: StateUpdatedEvent, gridStateRef: MutableRefObject<GridState | undefined>) => {
    const { sources } = e
    if (sources.every((source) => GRID_EVENT_SOURCES_TO_IGNORE.has(source))) {
        return
    }

    const state = e.api.getState()
    const hasValueColumns = state.columnOrder?.orderedColIds.some(colId => colId.startsWith('values.'))

    if (hasValueColumns) {
        gridStateRef.current = state
    } else {
        gridStateRef.current = {
            ...state,
            columnOrder: gridStateRef.current?.columnOrder,
        }
    }
}

export const cellRenderStyledFromConditionalFormatRules = (
    conditionalFormatRules: ConditionalFormatRule[],
    params: ICellRendererParams,
    cellValue: number,
    id: string,
    columns: ReportingPortfolioMetricsItemColumn[],
) => {
    if ((params.node.group && params.node.expanded) || params.value === undefined || !params.data || !params.data.values || params.value === '') {
        return params.valueFormatted
    }

    let newStyle: ConditionalFormatStyle = {}

    // Apply conditional formatting rules. Reverse the array to apply the rules in priority order
    // First rule gets applied last and therefore has the highest priority since styles can overwrite each other
    for (const rule of conditionalFormatRules.reverse()) {
        // Determine if condition(s) are met to apply a rule's styling to the cell
        let conditionMet = true

        // Determine reference value for primary column and check then primary condition
        const primaryConditionColumnValue = params.data.values[rule.primaryConditionColumn]
        if (!primaryConditionColumnValue) continue
        const isPercentage = columns.filter(c => c.id === rule.primaryConditionColumn)?.[0].value_type === ReportingDataValueType.PERCENTAGE
        const primaryConditionColumnValueFormatted = (isPercentage)
            ? Math.round(primaryConditionColumnValue * 1000) / 1000 // represent percentage as decimal with 3 decimal places
            : parseFloat(primaryConditionColumnValue.toFixed(0))

        const firstConditionResult = applyFunction(rule.primaryConditionFunction, primaryConditionColumnValueFormatted, rule.primaryConditionValue)

        // Check Secondary Condition (may not be present)
        const secondaryConditionColumnValue = rule.secondaryConditionColumn && params.data.values[rule.secondaryConditionColumn]
        const isSecondaryPercentage = secondaryConditionColumnValue && columns.filter(c => c.id === rule.secondaryConditionColumn)?.[0].value_type === ReportingDataValueType.PERCENTAGE
        const secondaryConditionColumnValueFormatted = (isSecondaryPercentage)
            ? Math.round(secondaryConditionColumnValue * 1000) / 1000 // represent percentage as decimal with 3 decimal places
            : secondaryConditionColumnValue && parseFloat(secondaryConditionColumnValue.toFixed(0))

        const secondaryConditionResult = (rule.secondaryConditionFunction && applyFunction(rule.secondaryConditionFunction, secondaryConditionColumnValueFormatted, rule.secondaryConditionValue)) ?? false

        const applySecondaryCondition = secondaryConditionColumnValue && rule.secondaryConditionFunction && rule.secondaryConditionValue && rule.secondaryConditionColumn
        if (applySecondaryCondition) {
            if (rule.conditionCombinationOperator === 'AND') {
                conditionMet = firstConditionResult && secondaryConditionResult
            } else {
                conditionMet = firstConditionResult || secondaryConditionResult
            }
        } else {
            conditionMet = firstConditionResult
        }

        // Apply the rule's styling to the cell if the condition(s) are met
        if (conditionMet && rule.columnsToBeFormatted.includes(id)) {
            newStyle = applyConditionalFormattingRuleToStyle(rule, newStyle)
        }
    }

    const styledIcon = newStyle.cellIconStyle ? <span className={newStyle.cellIconStyle?.iconClass} style={newStyle?.cellIconStyle ? newStyle.cellIconStyle as CSSProperties : {}}/> : <span/>

    return (
        <div style={newStyle as CSSProperties}>{styledIcon}<span>{params.valueFormatted}</span></div>
    )
}
