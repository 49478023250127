/* eslint-disable @typescript-eslint/naming-convention */
export enum AssetDetails {
    NAME = 'n',
    CITY = 'c',
    STATE = 's',
    ASSET_CLASS = 'ac',
    MANAGER_COMPANY = 'm',
    ASSET_MANAGER = 'am',
    FUND = 'f',
    UNITS = 'u',
    SQUARE_FEET = 'sf',
    CUSTOM_ID1 = 'ci1',
    CUSTOM_ID2 = 'ci2',
    SOFTWARE = 'so',
}
export const ASSET_DETAIL_LABELS: Record<AssetDetails, string> = {
    [AssetDetails.NAME]: 'Name',
    [AssetDetails.CITY]: 'City',
    [AssetDetails.STATE]: 'State',
    [AssetDetails.ASSET_CLASS]: 'Asset Class',
    [AssetDetails.MANAGER_COMPANY]: 'Property Management Firm',
    [AssetDetails.ASSET_MANAGER]: 'Asset Manager',
    [AssetDetails.FUND]: 'Fund Name',
    [AssetDetails.UNITS]: 'Units',
    [AssetDetails.SQUARE_FEET]: 'Square Feet',
    [AssetDetails.CUSTOM_ID1]: 'Custom ID 1',
    [AssetDetails.CUSTOM_ID2]: 'Custom ID 2',
    [AssetDetails.SOFTWARE]: 'Accounting Software',
}
