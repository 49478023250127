/* eslint-disable @typescript-eslint/no-dynamic-delete */
import { GridApi, SortChangedEvent } from '@ag-grid-community/core'
import { observe, autorun, runInAction } from 'mobx'
import { ASC, DESC } from 'mobx-orm'

import { AgGridTableProps } from './AgGridTableLegacy.types'

// syncronize updates from mobx to ag-grid transaction
export class AgGridUpdater {
    private disposer_list: any
    private disposer_objects: any = {}
    private disposer_state_reaction: any

    //  var rowNode1 = gridOptions.api.getDisplayedRowAtIndex(4);
    //   var rowNode2 = gridOptions.api.getDisplayedRowAtIndex(5);
    //   // flash whole row, so leave column selection out
    //   gridOptions.api.flashCells({
    //     rowNodes: [rowNode1, rowNode2],
    //     flashDelay: 3000,
    //     fadeDelay: 2000,
    //   });
    constructor (api: GridApi, columnApi: GridApi, query: AgGridTableProps['query']) {
        // we have to observe internal `__items` property, because `items` are computed and not observable
        // TODO: observe MultiQuery changes
        this.disposer_list = observe(query.__items, (change: any) => {
            api.applyTransactionAsync({
                add: change.added,
                remove: change.removed,
            })

            for (const obj of change.removed) {
                if (this.disposer_objects[obj.id]) {
                    this.disposer_objects[obj.id]()
                    delete this.disposer_objects[obj.id]
                }
            }

            for (const obj of change.added) {
                this.disposer_objects[obj.id] = observe(obj, () => {
                    api.applyTransactionAsync({ update: [obj] })
                })
                // flash of changes
                // setTimeout(() => {
                //     api.forEachNode((rowNode: any, index: any) => {
                //         if (rowNode.data === obj) api.flashCells({ rowNodes: [rowNode] })
                //     })
                // }, 100)
            }
        })

        api.showLoadingOverlay()
        this.disposer_state_reaction = autorun(() => {
            if (query.error || (!query.is_loading && !query.items.length)) {
                setTimeout(() => api.showNoRowsOverlay())
            } else if (query.is_loading) {
                setTimeout(() => api.showLoadingOverlay())
            } else {
                setTimeout(() => api.hideOverlay())
            }
        })

        // sync sort
        if (query.order_by) {
            for (const field of query.order_by.keys()) {
                columnApi.applyColumnState({
                    state: [
                        {
                            colId: field,
                            sort: query.order_by.get(field) === ASC ? 'asc' : 'desc',
                        },
                    ],
                })
            }
        }

        api.addEventListener('sortChanged', ({ api }: SortChangedEvent) => {
            runInAction(() => {
                for (const columnState of api.getColumnState()) {
                    const field = columnState.colId
                    const query_sort = query.order_by.has(field) ? (query.order_by.get(field) === ASC ? 'asc' : 'desc') : null // eslint-disable-line @typescript-eslint/naming-convention
                    const table_sort = columnState.sort // eslint-disable-line @typescript-eslint/naming-convention
                    // console.log(field, query_sort, table_sort)
                    if (query_sort !== table_sort) {
                        if (table_sort === 'asc') {
                            // console.log('asc')
                            query.order_by.set(field, ASC)
                            // REFACTORING: need_to_update should be set to true automaticly after changes, why it does not happened?
                            query.need_to_update = true
                        } else if (table_sort === 'desc') {
                            // console.log('desc')
                            query.order_by.set(field, DESC)
                            query.need_to_update = true
                        } else {
                            // console.log('delete')
                            query.order_by.delete(field)
                            query.need_to_update = true
                        }
                    }
                }
            })
        })
    }

    destroy () {
        if (this.disposer_list) this.disposer_list()
        if (this.disposer_state_reaction) this.disposer_state_reaction()
        for (const disposer of Object.values(this.disposer_objects)) {
            (disposer as any)()
        }
        this.disposer_list = null
        this.disposer_objects = null
        this.disposer_state_reaction = null
    }
}
