import { observer } from 'mobx-react-lite'

import { LegacySelect, LegacySelectItemData } from '@/components/base'

import { DistinctSelectInputProps } from './DistinctSelectInput.types'

/**
 * Works with multiselect option only
 * TODO: add support for single selected option
 */
export const DistinctSelectInput = observer((props: DistinctSelectInputProps) => {
    const { input, disabled, multiSelect = false, ...rest } = props

    // Make sure options.items are strings
    if (input.options?.items?.length &&
        input.options.items.some(item => typeof item !== 'string')) {
        throw new Error('DistinctSelectInput: options.items must be strings!')
    }

    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    const options: LegacySelectItemData[] = input.options.isReady
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        ? (input.options.items as unknown as string[]).map(item => ({
            value: item,
            label: item,
        }))
        : []

    return (
        <LegacySelect
            {...rest}
            options={options}
            multiSelect={multiSelect}
            multiSelectCustomValues={input.value}
            disabled={disabled || !input.isReady}
            onMultiChange={(opts) => input.set(opts.map(o => o.value.toString()))}
        />
    )
})
