import { useEffect, useRef, useState } from 'react'

import { ColDef } from '@ag-grid-community/core'
import { runInAction } from 'mobx'
import { EQ, ValueType } from 'mobx-orm'

import { AgGridReact } from '@ag-grid-community/react'
import { observer } from 'mobx-react-lite'
import { Outlet } from 'react-router'

import { AgGridTableLegacy } from '@/components/legacy/tables/AgGridTableLegacy'
import { TABLE_PER_PAGE_DEFAULT } from '@/components/legacy/tables/AgGridTableLegacy/components/ElementsPerPageSelect'
import { useMe } from '@/hooks/core/useMe'
import { Company, CompanyUser } from '@/models/company'

import { gridOptions } from './CompanyMembersSubpage.constants'
import { CompanyMembersSubpageProps } from './CompanyMembersSubpage.types'

import styles from './CompanyMembersSubpage.module.scss'

export const CompanyMembersSubpage = observer((props: CompanyMembersSubpageProps) => {
    const { companyId, handleRowClick } = props

    const { me } = useMe()

    const tableRef = useRef<AgGridReact>()

    const [companyFilter] = useState(() => EQ('company_id', undefined, ValueType.NUMBER))
    const [companyUserQuery] = useState(() => CompanyUser.getQueryPage({
        filter: companyFilter,
        limit: TABLE_PER_PAGE_DEFAULT,
    }))

    useEffect(() => {
        runInAction(() => {
            companyFilter.value = companyId !== undefined ? companyId : me.companyUser.company_id
        })
    }, [companyId])

    useEffect(() => {
        companyUserQuery.load()
        return () => { companyUserQuery.destroy() }
    }, [companyUserQuery])

    const [gridOptionsState, setGridOptions] = useState(gridOptions)

    const companyObj = Company.get(companyId) as Company

    useEffect(() => {
        // Adding click handlers to all cells except actions (it have another handler)
        const updatedColumnDefs = gridOptions.columnDefs.map((el) => (el as ColDef).type === 'actions' ? el : {
            onCellClicked: handleRowClick,
            ...el,
        })

        setGridOptions({
            ...gridOptions,
            columnDefs: updatedColumnDefs,
        })
    }, [gridOptions])

    if (!companyObj) {
        return null
    }

    return (
        <>
            <div className={styles.headerBox}>
                <div
                    className={styles.filters}
                />
                {/* <Button
                    icon='plus'
                    text={getRouteConfig('COMPANY_PROFILE_INVITES_NEW').title}
                    onClick={() => { navigate(invitePopupPath) }}
                /> */}
            </div>
            <AgGridTableLegacy
                ref={tableRef}
                query={companyUserQuery}
                {...gridOptionsState}
            />
            <Outlet/>
        </>
    )
})
