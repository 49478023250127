import { useState, MouseEvent } from 'react'

import cn from 'classnames'

import { observer } from 'mobx-react-lite'
import { useLocation, useNavigate } from 'react-router'

import { Icon, Text, Popover } from '@/components/base'
import menuStyles from '@/components/widgets/CompaniesMenu/CompaniesMenu.module.scss'
import { ACCESS_LEVEL_CHOICES } from '@/constants/accessLevels'
import { useMe } from '@/hooks/core/useMe'
import { AccessLevel } from '@/models/core'

import { getHeaderMenuItemsConfig } from './HeaderMenu.constants'
import { HeaderMenuItem } from './HeaderMenuItem'

import styles from './HeaderMenu.module.scss'

/**
 * Popop over menu for user profile settings
 *
 * TODO:
 * - Switching between companies
 * - active if path inside is acitve
 */
export const HeaderMenu = observer(() => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    const { logout, me, switchMode } = useMe()
    const navigate = useNavigate()
    const { pathname } = useLocation()

    const isMenuOpened = Boolean(anchorEl)

    const { first_name, last_name } = me.user ?? {} // eslint-disable-line @typescript-eslint/naming-convention

    const handleToggleClick = (event: MouseEvent<HTMLDivElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget)
    }

    return (
        <>
            <div
                className={cn(styles.headerMenu, { [styles.headerMenu_active]: isMenuOpened })}
                onClick={handleToggleClick}
                data-testid='HeaderMenu'
            >
                <div className={styles.nameBox}>
                    <Text
                        variant='smallTextSemiboldDefault'
                        color='secondaryBlack'
                        alignRight
                    >
                        {first_name}
                        {first_name ? ' ' : ''}
                        {last_name}
                    </Text>
                    <Text
                        alignRight
                        color='colorsSecondaryGrey600'
                        variant='labelMediumDefault'
                    >
                        {!me.isAdministratorMode ? me?.company?.name : ACCESS_LEVEL_CHOICES[AccessLevel.Admin]}
                    </Text>
                </div>
                <div
                    className={menuStyles.avatarPlaceholder}
                >
                    <Icon
                        name='user'
                        color='colorsPrimaryPurple'
                        size='l'
                    />
                </div>
                <Icon
                    color={isMenuOpened ? 'colorsPrimaryPurple' : 'colorsSecondaryGrey600'}
                    size='m'
                    name='arrowDown'
                    className={cn(styles.arrow, { [styles.arrow_rotated]: isMenuOpened })}
                />
            </div>
            <Popover
                anchorEl={anchorEl}
                placement='bottom-end'
                onClose={() => { setAnchorEl(null) }}
            >
                {
                    getHeaderMenuItemsConfig({
                        logout,
                        switchMode,
                        me,
                    }).map((item, i) => {
                        const isActive = item.path ? pathname.startsWith(item.path) : false

                        return (
                            <HeaderMenuItem
                                key={i}
                                isActive={isActive}
                                onClick={() => {
                                    item.onClick?.()
                                    item.path && (navigate(item.path))
                                    setAnchorEl(null)
                                }}
                                {...item}
                            />
                        )
                    })
                }
            </Popover>
        </>
    )
})
