import { Outlet } from 'react-router'

import { NavigaionSubtabs } from '@/components/base'
import { PageLayout } from '@/components/containers'
import { getRouteConfig } from '@/utils/routing/getRouteConfig'

export const UploadsPage = () => {
    const SUBNAVIGAION_ITEMS = [
        getRouteConfig('UPLOADS_UPLOADS'),
        getRouteConfig('UPLOADS_LOGS'),
    ].filter(Boolean)

    return (
        <PageLayout noPadding header={<NavigaionSubtabs items={SUBNAVIGAION_ITEMS}/>}>
            <Outlet/>
        </PageLayout>
    )
}
