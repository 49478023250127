import { Text } from '@/components/base'
import { Layout } from '@/components/containers'

import { SideDrilldownTitleProps } from './SideDrilldownTitle.types'

export const SideDrilldownTitle = (props: SideDrilldownTitleProps) => {
    const { title, subtitle } = props
    return (
        <Layout direction='column' gap={8}>
            <Text
                variant='subheader'
                color='colorsPrimaryGrey'
                text={title}
                maxLines={1}
                block
            />
            <Text
                variant='bodyMedium'
                color='colorsSecondaryGrey600'
                text={subtitle}
                maxLines={1}
                block
            />
        </Layout>
    )
}
