import { getRequestQueries } from '@/api/utils/getRequestQueries'
import { PackageFile, PackageStatus } from '@/models/package'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FetchPackagesQueryParams {}

export interface PackageItem {
    alias: string
    asset_id: number
    month_ind: number
    status: PackageStatus
    created_at: string | null
    updated_at: string | null
    error_count: number
    files: PackageFile[]
    asset_comments_id: string
}

export const {
    useItemByIdQuery: usePackageByIdQuery,
    useItemsQuery: usePackageQuery,
    useItemUpdateMutation: usePackageUpdateMutation,
    useItemCreateMutation: usePackageCreateMutation,
    useItemsCountQuery: usePackageCountQuery,
} = getRequestQueries<PackageItem, FetchPackagesQueryParams>({
    url: 'package',
    errorMessageUpdate: 'Failed to update package',
    errorMessageCreate: 'Failed to create package',
})
