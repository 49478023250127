import { useEffect } from 'react'

import * as Sentry from '@sentry/react'
import { useLocation } from 'react-router'
import { createRoutesFromChildren, matchRoutes, useNavigationType } from 'react-router-dom'

export const sentryInit = () => {
    const sentryUrl = process.env.SENTRY_ENDPOINT

    const ignoreErrors: RegExp[] = []

    if (process.env.NODE_ENV === 'preprod') {
        // It fails on prod because it's a copy of the prod and files stored in prod bucket
        ignoreErrors.push(/\/api\/data-pulling\/download\/\d+/)
    }

    if (sentryUrl) {
        Sentry.init({
            ignoreErrors,
            dsn: sentryUrl,
            environment: process.env.ENVIRONMENT,
            integrations: [
                new Sentry.BrowserProfilingIntegration(),
                new Sentry.BrowserTracing({
                    routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                        useEffect,
                        useLocation,
                        useNavigationType,
                        createRoutesFromChildren,
                        matchRoutes,
                    ),
                }),
                new Sentry.Replay({
                    unmask: ['*'],
                }),
            ],
            release: process.env.RELEASE_VERSION,
            tracesSampleRate: 1.0,
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
        })
    }

    window.addEventListener('visibilitychange', () => {
        const isTabOpen = document.visibilityState === 'visible'

        Sentry.configureScope(function (scope) {
            scope.setTag('isTabOpen', isTabOpen)
        })
    })
}
