import { Link } from '@/components/base'
import { AccountSelectCell } from '@/components/legacy/models/ledger/AccountSelectCell'
import { AgGridTableProps } from '@/components/legacy/tables/AgGridTableLegacy'
import { ObserverCell } from '@/components/legacy/tables/cells/ObserverCell/ObserverCell'
import { MappingListActionsCell } from '@/pages/MainPage/UserPages/MappingPage/MappingTemplatesPage/MappingTemplateEditPage/MappingListActionsCell'

export const DELETE_TEMPLATE_ALERT = (
    <>
        To delete this template, please email us at:&nbsp;
        <Link
            href='mailto:help@intelas.com?subject=Mapping Template: Delete Template'
            variant='bodyMedium'
            color='colorsTetrialOrange800'
            asAnchor
        >help@intelas.com</Link>
    </>
)

export const EDIT_TEMPLATE_MESSAGES = {
    removed: 'Mapping removed',
    added: 'Mapping added',
    changed: 'Mapping changes / additions made',
    nameChanged: 'Template name changed',
    mappingChanged: 'Mapping updated',
    mappingAddedToTemplate: 'The mapping has been added to the template',
    mappingDidNotChange: 'Mapping did not change',
    applied: 'Template applied successfully',
    error: 'Error occurred during saving',
}

export const GRID_OPTIONS: Omit<AgGridTableProps, 'query' | 'items'> = {
    context: {},
    rowHeight: 32,
    headerHeight: 32,
    defaultColDef: {
        headerComponentParams: {
            menuIcon: 'fa-bars',
        },
    },
    suppressCellFocus: true,
    columnDefs: [
        {
            headerName: 'Source',
            type: 'string',
            rightBorder: true,
            children: [
                {
                    headerName: 'Code',
                    field: 'source_account_code',
                    type: 'string',
                    width: 96,
                    cellRenderer: ObserverCell,
                },
                {
                    headerName: 'Name',
                    field: 'source_account_name',
                    type: 'string',
                    cellRenderer: ObserverCell,
                },
            ],
        },
        {
            headerName: 'Ledger',
            type: 'string',
            rightBorder: true,
            headerValueGetter: (params) => {
                return `Ledger ${params.context.ledgerName ?? ''}`
            },
            children: [
                {
                    headerName: 'Code',
                    field: 'account.code',
                    type: 'string',
                    width: 96,
                    cellRenderer: ObserverCell,
                },
                {
                    headerName: 'Name',
                    field: 'account.name',
                    type: 'string',
                    cellRenderer: AccountSelectCell,
                },
            ],
        },
        {
            type: 'actions',
            sortable: false,
            lockPosition: 'right',
            maxWidth: 76,
            cellRenderer: MappingListActionsCell,
            cellContentAlignVertical: true,
        },
    ],
}
