import { CSSProperties } from 'react'

import { ICellRendererParams, ProcessCellForExportParams } from '@ag-grid-community/core'

import { ReportingRRTradeOutBasicRow } from '@/api/reportingData/reportingRentRollTradeOutBasic'

import { ReportingDataValueType } from '../../../../../constants/reportingDataValueTypes'
import { applyFunction, ConditionalFormatRule, ConditionalFormatStyle } from '../../ConditionalRuleEditor/ConditionalRuleEditor.types'
import { applyConditionalFormattingRuleToStyle } from '../../ConditionalRuleEditor/ConditionalRuleEditor.utils'
import { GroupByRRField, GROUP_BY_RR_FIELD_LABLES } from '../../TableBuilderFilters'

import { BASE_COLS, NEW_LEASE_COLS } from './RRTradeOutBasicTable.constants'

export const normalizePercentageValues = (rows: ReportingRRTradeOutBasicRow[]): ReportingRRTradeOutBasicRow[] => {
    return rows.map((row) => ({
        ...row,
        ending_occupancy: row.ending_occupancy ? row.ending_occupancy * 100 : row.ending_occupancy,
        new_lease_percent: row.new_lease_percent ? row.new_lease_percent * 100 : row.new_lease_percent,
    }))
}

/**
 * - Remove values from group rows except for footer cells
 * - Fix total rows in Excel export
 */
export const processRRTradeOutBasicTableTotalCells = (groupBy?: GroupByRRField | null) =>
    (!groupBy || groupBy === GroupByRRField.None)
        ? undefined
        : (params: ProcessCellForExportParams): string => {
            // if grouped by floorplan or bedrooms, use asset_name as group column
            const groupColField = [GroupByRRField.Floorplan, GroupByRRField.Bedrooms].includes(groupBy) ? 'asset_name' : groupBy
            const { node, column } = params
            const colId = column.getColId()
            const isRootTotal = node?.id === 'rowGroupFooter_ROOT_NODE_ID'
            const isGroupColumn = colId === groupColField

            if (isGroupColumn) {
                if (node?.group && !node?.footer) {
                    return `${node.key}`
                } else if (node?.footer) {
                    return isRootTotal ? 'Total/Average' : `Total ${node.key}`
                } else {
                    return ''
                }
            }

            if (node?.group && !node?.footer) {
                return ''
            }

            return params.value
        }

/**
 * We must use template to have all the default functionality like sorting, filtering, etc.
 * @returns Standard header template with title split into two lines
 */
export const getHeaderTemplate = (title: string): string => {
    const [firstLine = '', secondLine = ''] = title.split('~')
    return `<div class="ag-cell-label-container" role="presentation">
                <span data-ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
                <span data-ref="eFilterButton" class="ag-header-icon ag-header-cell-filter-button"></span>
                <div data-ref="eLabel" class="ag-header-cell-label" role="presentation">
                    <span data-ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>
                    <span data-ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>
                    <span data-ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>
                    <span data-ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>
                    <span style="display: flex; flex-direction: column; align-items: flex-end;">
                        <span>${firstLine}</span><span>${secondLine}</span>
                    </span>
                    <span data-ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
                </div>
            </div>`
}

const getColType = (field: string): ReportingDataValueType => {
    const col: { field: string, title: string, type?: string } = [
        {
            field: 'asset_name',
            title: 'Asset Name',
            type: 'string',
        },
        ...BASE_COLS,
        ...NEW_LEASE_COLS,
        ...Object.values(GroupByRRField).map(key => ({
            field: key,
            title: GROUP_BY_RR_FIELD_LABLES[key],
        })),
    ].filter(col => col.field === field)?.[0]
    switch (col?.type) {
        case 'money': return ReportingDataValueType.MONEY
        case 'number': return ReportingDataValueType.NUMBER
        case 'percentage': return ReportingDataValueType.PERCENTAGE
        default: return ReportingDataValueType.STRING
    }
}

export const cellRenderStyledFromConditionalFormatRules = (
    conditionalFormatRules: ConditionalFormatRule[],
    params: ICellRendererParams<ReportingRRTradeOutBasicRow>,
    key: string,
) => {
    if ((params.node.group && params.node.expanded) || params.value === undefined || params.value === null || !params.data || params.value === '') {
        return params.valueFormatted ?? params.value
    }

    let newStyle: ConditionalFormatStyle = {}

    // Apply conditional formatting rules. Reverse the array to apply the rules in priority order
    // First rule gets applied last and therefore has the highest priority since styles can overwrite each other
    for (const rule of conditionalFormatRules.reverse()) {
        // Determine if condition(s) are met to apply a rule's styling to the cell
        let conditionMet = true

        // Determine reference value for primary column and check then primary condition
        let primaryConditionColumnValue = params.data[rule.primaryConditionColumn]
        if (!primaryConditionColumnValue) continue
        let primaryConditionColumnValueFormatted
        switch (getColType(rule.primaryConditionColumn)) {
            case ReportingDataValueType.MONEY:
            case ReportingDataValueType.NUMBER:
                primaryConditionColumnValueFormatted = parseFloat((primaryConditionColumnValue as number).toFixed(0))
                break
            case ReportingDataValueType.PERCENTAGE:
                primaryConditionColumnValue = (primaryConditionColumnValue as number) / 100.0
                primaryConditionColumnValueFormatted = Math.round(primaryConditionColumnValue * 1000) / 1000 // represent percentage as decimal with 3 decimal places
                break
            default:
                primaryConditionColumnValueFormatted = primaryConditionColumnValue
                break
        }

        const firstConditionResult = applyFunction(rule.primaryConditionFunction, primaryConditionColumnValueFormatted, rule.primaryConditionValue)

        // Check Secondary Condition (may not be present)
        let secondaryConditionColumnValue = rule.secondaryConditionColumn && params.data[rule.secondaryConditionColumn]
        let secondaryConditionColumnValueFormatted
        if (rule.secondaryConditionColumn && rule.secondaryConditionValue && rule.secondaryConditionFunction) {
            switch (getColType(rule.secondaryConditionColumn)) {
                case ReportingDataValueType.MONEY:
                case ReportingDataValueType.NUMBER:
                    secondaryConditionColumnValueFormatted = parseFloat((secondaryConditionColumnValue as number).toFixed(0))
                    break
                case ReportingDataValueType.PERCENTAGE:
                    secondaryConditionColumnValue = (secondaryConditionColumnValue as number) / 100
                    secondaryConditionColumnValueFormatted = Math.round(secondaryConditionColumnValue * 1000) / 1000 // represent percentage as decimal with 3 decimal places
                    break
                default:
                    secondaryConditionColumnValueFormatted = secondaryConditionColumnValue
                    break
            }
        }

        const secondaryConditionResult = secondaryConditionColumnValueFormatted ? (rule.secondaryConditionFunction && applyFunction(rule.secondaryConditionFunction, secondaryConditionColumnValueFormatted, rule.secondaryConditionValue)) ?? false : false

        const applySecondaryCondition = secondaryConditionColumnValue && rule.secondaryConditionFunction && rule.secondaryConditionValue && rule.secondaryConditionColumn
        if (applySecondaryCondition) {
            if (rule.conditionCombinationOperator === 'AND') {
                conditionMet = firstConditionResult && secondaryConditionResult
            } else {
                conditionMet = firstConditionResult || secondaryConditionResult
            }
        } else {
            conditionMet = firstConditionResult
        }

        // Apply the rule's styling to the cell if the condition(s) are met
        if (conditionMet && rule.columnsToBeFormatted.includes(key)) {
            newStyle = applyConditionalFormattingRuleToStyle(rule, newStyle)
        }
    }

    const styledIcon = newStyle.cellIconStyle ? <span className={newStyle.cellIconStyle?.iconClass} style={newStyle?.cellIconStyle ? newStyle.cellIconStyle as CSSProperties : {}}/> : (['bedrooms', 'floorplan'].includes(key)) ? null : <span/>

    if (['bedrooms', 'floorplan'].includes(key)) {
        newStyle = {
            ...newStyle,
            paddingLeft: '10px',
        }
    }

    return (
        <div style={newStyle as CSSProperties}>{styledIcon}<span>{params.valueFormatted ?? params.value}</span></div>
    )
}
