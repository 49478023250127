import { getRequestQueries } from '@/api/utils/getRequestQueries'

export interface ReportingPnLVarianceNewDrillDownRow {
    asset_id: string
    asset_name: string
    account_name?: string
    account_code?: string
    account_id?: number
    month_ind?: number
    values: {
        actual?: number
        budget?: number
        closing_balance?: number
        variance?: number
        variance_percent?: number
    }
}

export interface ReportingPnLVarianceNewDrillDownItem {
    rows: ReportingPnLVarianceNewDrillDownRow[]
}

export const REPORTING_PROFIT_AND_LOSS_VARIANCE_NEW_DRILL_DOWN_URL = 'reporting/profit-and-loss-variance/drill-down'

export const {
    useItemsQuery: useReportingProfitAndLossVarianceNewDrillDownQuery,
} = getRequestQueries<ReportingPnLVarianceNewDrillDownRow, Record<string, unknown>>({
    url: REPORTING_PROFIT_AND_LOSS_VARIANCE_NEW_DRILL_DOWN_URL,
    extractItemsFn: (data: ReportingPnLVarianceNewDrillDownItem) => data.rows,
})
