import { RefObject, useCallback, useEffect, useState } from 'react'

import { AgGridReact } from '@ag-grid-community/react'

import { TableMode } from '@/pages/TablesPage/tableType'

export interface UseTableChartToggle {
    hasChart: boolean
    tableMode: TableMode
    toggleTableMode?: () => void
}

export function useTableChartToggle (tableRef?: RefObject<AgGridReact>): UseTableChartToggle {
    const [hasChart, setHasChart] = useState(false)
    const [tableMode, setTableMode] = useState<TableMode>(TableMode.Table)

    useEffect(() => {
        let timeoutId: NodeJS.Timeout
        const getInitialTableMode = () => {
            if (!tableRef?.current) {
                timeoutId = setTimeout(getInitialTableMode, 100)
                return
            }

            // continue to check if table has chart and table mode as table may have not been loaded yet
            if (tableRef.current.props.context?.hasChart && !tableRef.current.props.context?.tableMode) {
                timeoutId = setTimeout(getInitialTableMode, 100)
                return
            }

            setHasChart(tableRef.current.props.context?.hasChart ?? false)
            setTableMode(tableRef.current.props.context?.tableMode ?? TableMode.Table)
        }

        getInitialTableMode()
        return () => clearTimeout(timeoutId)
    }, [])

    const toggleTableMode = useCallback(() => {
        setTableMode((prev) => (prev === TableMode.Table ? TableMode.Chart : TableMode.Table))
        tableRef?.current?.props?.context?.toggleTableMode()
    }, [])

    return {
        hasChart,
        tableMode,
        toggleTableMode,
    }
}
