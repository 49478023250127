import { MouseEvent } from 'react'

import cn from 'classnames'

import { Icon } from '@/components/base'

import { IconButtonProps } from './IconButton.types'

import styles from './IconButton.module.scss'

export const IconButton = (props: IconButtonProps) => {
    const { className, size = 'm', onClick, icon } = props

    const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
        onClick?.(e)
    }

    const iconSize = size === 'm' ? 'l' : 'm'

    return (
        <button className={cn(styles.button, className)} onClick={handleClick}>
            <Icon name={icon} size={iconSize}/>
        </button>
    )
}
