import { ColDef } from '@ag-grid-community/core'

import { AgGridTableProps } from '@/components/tables'

import { PinTotalColumn } from '../TableBuilderFilters'

export const PinnedPositionByTotalCol: Record<PinTotalColumn, ColDef['pinned']> = {
    l: 'left',
    r: 'right',
    n: null,
}

export const statusBar: AgGridTableProps['statusBar'] = {
    statusPanels: [
        {
            statusPanel: 'agTotalRowCountComponent',
            align: 'left',
        },
        {
            statusPanel: 'agAggregationComponent',
            statusPanelParams: {
                aggFuncs: ['count', 'avg', 'sum'],
            },
        },
    ],
}
