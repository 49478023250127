import { forwardRef, RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { CellClickedEvent, ExcelExportParams, GetContextMenuItemsParams, GridState, MenuItemDef } from '@ag-grid-community/core'
import isEqual from 'lodash/isEqual'
import { AND, EQ } from 'mobx-orm'
import { createPortal } from 'react-dom'

import { AgGridReact } from '@ag-grid-community/react'
import { useNavigate } from 'react-router'

import { ReportingRRTradeOutBasicRow, useReportingRentRollTradeOutBasicQuery } from '@/api/reportingData/reportingRentRollTradeOutBasic'
import { ReportingTableFilters, useReportingTableByIdQuery } from '@/api/reportingTable/reportingTable'
import { SlideoutPanel } from '@/components/base'
import { MONTH_RANGE_END_QUERY_PARAM, MONTH_RANGE_START_QUERY_PARAM } from '@/components/baseInputs'
import { Layout, useTableSettings } from '@/components/containers'
import { ASSET_SIDE_MODAL_INPUT_QUERY_PARAM } from '@/components/models/asset'
import { AgGridTable } from '@/components/tables'
import { useTableRangeSelectionModules } from '@/components/tables/utils/useTableRangeSelectionModules'
import {
    GLOBAL_REPORT_MONTH_RANGE_END_QUERY_PARAM,
    GLOBAL_REPORT_MONTH_RANGE_START_QUERY_PARAM,
    GLOBAL_REPORT_MULTY_ASSET_QUERY_PARAM,
} from '@/components/widgets/reports/reports.constants'
import { TimeInterval } from '@/constants/timeInterval'
import { useInputState } from '@/hooks'
import { TableType } from '@/pages/TablesPage/tableType'
import { dateToMonthInd } from '@/utils/date/monthInd'
import { isFeatureOn } from '@/utils/isFeatureOn'
import { getRoute } from '@/utils/routing/getRoute'

import { CustomSettingsFilterModal } from '../../CustomSettingsFilterModal'
import { NoDataMessage } from '../../NoDataMessage'
import { CancelSaveButtons } from '../../TableBuilder/CancelSaveButtons'
import { GroupByRRField, GROUP_BY_RR_FIELD_INPUT_QUERY_PARAM } from '../../TableBuilderFilters'
import {
    getReportTableTbLedgerInputQueryParam,
    getReportTableRrLedgerInputQueryParam,
    getReportTableAssetTypeParam,
    getReportGroupByRRFieldInputQueryParam,
    TABLE_TB_LEDGER_INPUT_QUERY_PARAM,
    TABLE_RR_LEDGER_INPUT_QUERY_PARAM,
    TABLE_ASSET_TYPE_PARAM,
} from '../../TableBuilderPage.constants'
import { statusBar } from '../TableBuilderTables.constants'
import { TableBuilderTableProps } from '../TableBuilderTables.types'
import { getGridPropsToSave, getPeriodString, saveGridStateToRef, saveReportingTable } from '../TableBuilderTables.utils'
import { useReportingTableSaveMutation } from '../useReportingTableSaveMutation'

import { RRTOBDrillDown, RRTOBDrillDownParams } from './RRTOBDrillDown'
import { getRRTradeOutBasicColDefs, NEW_LEASE_COLS, rRTradeOutBasicTableGridOptions as gridOptions } from './RRTradeOutBasicTable.constants'
import { normalizePercentageValues, processRRTradeOutBasicTableTotalCells } from './RRTradeOutBasicTable.utils'

/**
 * Rent Roll Metrics (Multifamily) table
 */
export const RRTradeOutBasicTable = forwardRef((props: TableBuilderTableProps, ref: RefObject<AgGridReact>) => {
    const {
        tableMeta = {},
        buttonContainer,
        onCancel,
        onGridReady,
        id,
        insideReport = false,
        onFirstDataRendered,
        tablesListRouteConfigKey,
        builderRouteConfigKey,
    } = props

    const priorMonth = dateToMonthInd(new Date()) - 1
    const navigate = useNavigate()
    const rangeSelectionModules = useTableRangeSelectionModules()
    const isCreationMode = id === 'new'
    const fallbackTableRef = useRef<AgGridReact>(null)
    const tableRef = ref ?? fallbackTableRef
    const gridStateRef = useRef<GridState>() // Retain grid state between renders

    const { showTableSettingsModal, setShowTableSettingsModal, getTableSetting, setTableSetting } = useTableSettings()

    const [isTableDirty, setIsTableDirty] = useState(false)
    const saveMutation = useReportingTableSaveMutation()
    const { data: loadedTableData } = useReportingTableByIdQuery(id, { enabled: !isCreationMode })

    // load grid state once
    if (!gridStateRef.current && loadedTableData?.aggrid_state) { gridStateRef.current = loadedTableData.aggrid_state }

    const [tBLedgerId] = useInputState(
        insideReport ? getReportTableTbLedgerInputQueryParam(id) : TABLE_TB_LEDGER_INPUT_QUERY_PARAM,
    )
    const [rRLedgerId] = useInputState(
        insideReport ? getReportTableRrLedgerInputQueryParam(id) : TABLE_RR_LEDGER_INPUT_QUERY_PARAM,
    )
    const [assetType] = useInputState(
        insideReport ? getReportTableAssetTypeParam(id) : TABLE_ASSET_TYPE_PARAM,
    )
    const [groupByRRField, setGroupByRRField] = useInputState(
        insideReport ? getReportGroupByRRFieldInputQueryParam(id) : GROUP_BY_RR_FIELD_INPUT_QUERY_PARAM,
    )

    const [assetIds, setAssetIds] = useInputState(insideReport ? GLOBAL_REPORT_MULTY_ASSET_QUERY_PARAM : ASSET_SIDE_MODAL_INPUT_QUERY_PARAM)
    const [startMonth, setStartMonth] = useInputState(insideReport ? GLOBAL_REPORT_MONTH_RANGE_START_QUERY_PARAM : MONTH_RANGE_START_QUERY_PARAM)
    const [endMonth, setEndMonth] = useInputState(insideReport ? GLOBAL_REPORT_MONTH_RANGE_END_QUERY_PARAM : MONTH_RANGE_END_QUERY_PARAM)

    const timePeriod = startMonth && endMonth ? `${startMonth}-${endMonth}` : undefined
    const areFiltersSet = Boolean(assetIds && timePeriod && groupByRRField)
    const isNewLeasesSectionAvailable = isFeatureOn('rrtob_new_leases_section')

    const [detailsTitle, setDetailsTitle] = useState('')
    const [drilldownParams, setDrilldownParams] = useState<RRTOBDrillDownParams | null>(null)

    const groupBy = groupByRRField === GroupByRRField.Bedrooms || groupByRRField === GroupByRRField.Floorplan
        ? groupByRRField
        : GroupByRRField.None

    const conditionalFormatRules = loadedTableData?.id ? (getTableSetting(loadedTableData.id)?.conditionalRules ?? undefined) : undefined

    const { data: reportData, isLoading, isFetched, isSuccess } = useReportingRentRollTradeOutBasicQuery(
        {
            filter: AND(
                EQ('asset_ids', assetIds),
                EQ('trial_balance_ledger_id', tBLedgerId),
                EQ('rent_roll_ledger_id', rRLedgerId),
                EQ('time_period', timePeriod),
                EQ('group_by', groupBy),
            ),
        },
        { enabled: areFiltersSet },
    )

    const [rows, columns] = useMemo(() => [normalizePercentageValues(reportData?.[0]?.rows ?? []), reportData?.[0]?.columns ?? {}], [reportData])
    const periodAsString = useMemo(() => getPeriodString(TimeInterval.MONTH, timePeriod), [timePeriod])

    const columnDefs = useMemo(
        () => getRRTradeOutBasicColDefs({
            conditionalFormatRules,
            groupByRRField,
            periodAsString,
            showNewLeasesSection: isNewLeasesSectionAvailable,
        }),
        [conditionalFormatRules, groupByRRField, isNewLeasesSectionAvailable, periodAsString],
    )

    // table config
    const config = useMemo(() => {
        const filters: ReportingTableFilters = { assetIds: assetIds as number[] }
        if (startMonth) { filters.startMonth = startMonth }
        if (endMonth) { filters.endMonth = endMonth }
        if (groupByRRField) { filters.rrGroupBy = groupByRRField }
        return ({
            filters,
            conditional_format_rules: conditionalFormatRules && { rules: conditionalFormatRules },
        })
    }, [conditionalFormatRules, assetIds, startMonth, endMonth, groupByRRField])

    const checkIfTableIsDirty = useCallback(() => {
        const loadedState = loadedTableData?.aggrid_state ?? {}
        const currentState = getGridPropsToSave(tableRef.current?.api?.getState())
        const currentConfig = config
        const loadedConfig = loadedTableData?.config ?? {}
        const currentName = tableMeta.name
        const loadedName = loadedTableData?.name

        if (
            isEqual(currentConfig, loadedConfig) &&
            isEqual(currentState, loadedState) &&
            currentName === loadedName
        ) {
            setIsTableDirty(false)
        } else {
            setIsTableDirty(true)
        }
    }, [config, loadedTableData?.aggrid_state, loadedTableData?.config, loadedTableData?.name, tableMeta.name, tableRef])

    useEffect(() => checkIfTableIsDirty(), [checkIfTableIsDirty])

    // default month range in creation mode
    useEffect(() => {
        if (isCreationMode) {
            setStartMonth(priorMonth)
            setEndMonth(priorMonth)
        }
    }, [isCreationMode, priorMonth, setStartMonth, setEndMonth])

    // set filters from loaded table data
    useEffect(() => {
        if (!loadedTableData) {
            return
        }

        const { filters, conditional_format_rules } = loadedTableData.config
        if (filters) {
            if ((insideReport && !assetIds) || !insideReport) {
                filters.assetIds && setAssetIds(filters.assetIds)
            }
            if ((insideReport && !startMonth) || !insideReport) {
                filters.startMonth && setStartMonth(filters.startMonth)
                filters.endMonth && setEndMonth(filters.endMonth)
            }
            filters.rrGroupBy && setGroupByRRField(filters.rrGroupBy)
        }
        setTableSetting(loadedTableData.id, conditional_format_rules ? conditional_format_rules.rules : [])
    }, [loadedTableData, setAssetIds, setEndMonth, setStartMonth, setGroupByRRField, insideReport])

    const onTableSave = useCallback(async () => {
        const currentState = tableRef.current?.api?.getState() ?? {}
        const response = await saveReportingTable({
            tableMeta,
            currentState,
            isCreationMode,
            saveMutation,
            id,
            tableType: TableType.RRTradeOutBasic,
            config,
            assetType,
        })

        if (!response) {
            return
        }

        // redirect after saving
        if (isCreationMode) {
            navigate(getRoute(builderRouteConfigKey, { id: response.id }))
        } else {
            navigate(getRoute(tablesListRouteConfigKey))
        }
    }, [assetType, config, id, isCreationMode, navigate, saveMutation, tableMeta, tableRef])

    const excelExportParams = useMemo((): ExcelExportParams => {
        return ({
            fileName: `RR Metrics (Multifamily) - ${periodAsString}.xlsx`,
            sheetName: periodAsString,
            processCellCallback: processRRTradeOutBasicTableTotalCells(groupByRRField),
            suppressRowOutline: true,
        })
    }, [groupByRRField, periodAsString])

    const closeDrillDown = useCallback(() => {
        setDetailsTitle('')
        setDrilldownParams(null)
    }, [])

    // close drilldown on filters change
    useEffect(() => closeDrillDown(), [assetIds, timePeriod, groupByRRField])

    const onCellClicked = useCallback((e: CellClickedEvent<ReportingRRTradeOutBasicRow>) => {
        const totalDrilldownCols = new Set(['total_units', 'market_rent'])
        const isOccupiedDrilldownCols = new Set(['occupied_units', 'ending_occupancy', 'in_place_rent'])
        const newLeasesCols = new Set(NEW_LEASE_COLS.map((col) => col.field))
        const clickableCols = new Set([...totalDrilldownCols, ...isOccupiedDrilldownCols, ...newLeasesCols])

        const colId = e.column.getColId()
        if (!clickableCols.has(colId) || e.node.group) {
            closeDrillDown()
            return
        }

        const { asset_id, bedrooms, floorplan } = e.data ?? {}
        const groupByValue = groupBy === GroupByRRField.Bedrooms
            ? bedrooms?.toString()
            : groupBy === GroupByRRField.Floorplan
                ? floorplan
                : undefined

        const metricType = isOccupiedDrilldownCols.has(colId)
            ? columns.occupied_units?.metric_type
            : newLeasesCols.has(colId)
                ? columns.new_lease_count?.metric_type
                : undefined

        const period = isOccupiedDrilldownCols.has(colId)
            ? columns.occupied_units?.time_period
            : newLeasesCols.has(colId)
                ? columns.new_lease_count?.time_period
                : columns.total_units?.time_period

        setDrilldownParams({
            asset_ids: assetIds,
            rent_roll_ledger_id: rRLedgerId ?? undefined,
            time_period: period,
            asset_id,
            group_by: groupBy,
            group_by_value: groupByValue ?? undefined,
            metric_type: metricType,
            isNewLeasesSection: newLeasesCols.has(colId),
        })
        setDetailsTitle(e.data?.asset_name ?? '')
    }, [assetIds, rRLedgerId, groupBy, columns])

    const onModelUpdated = useCallback(() => {
        if (!isLoading && isFetched && isSuccess) {
            onFirstDataRendered()
        }
    }, [isLoading, isFetched, isSuccess, onFirstDataRendered])

    const getContextMenuItems = (params: GetContextMenuItemsParams): Array<string | MenuItemDef> => {
        const result: Array<string | MenuItemDef> = [
            'copy',
            'copyWithHeaders',
            'copyWithGroupHeaders',
            'separator',
            'export',
            'separator',
            {
                name: 'Expand All Row Groups',
                action: () => params.api.expandAll(),
            },
            {
                name: 'Collapse All Row Groups',
                action: () => params.api.collapseAll(),
            },
            'separator',
            {
                name: 'Table Settings',
                action: () => setShowTableSettingsModal(true),
            },
        ]
        return result
    }

    return (
        <>
            <Layout flexGrow={1} direction='column'>
                {areFiltersSet
                    ? (
                    // @ts-expect-error TODO: make AgGridTable generic to be able to pass theme
                        <AgGridTable
                            ref={tableRef}
                            items={rows}
                            {...gridOptions}
                            columnDefs={columnDefs}
                            loading={isLoading}
                            initialState={gridStateRef.current}
                            lazyModules={rangeSelectionModules}
                            onStateUpdated={(e) => {
                                saveGridStateToRef(e, gridStateRef)
                                checkIfTableIsDirty()
                            }}
                            statusBar={statusBar}
                            defaultExcelExportParams={excelExportParams}
                            getContextMenuItems={getContextMenuItems}
                            onCellClicked={onCellClicked}
                            onGridReady={onGridReady}
                            onModelUpdated={onModelUpdated}
                        />
                    )
                    : (<NoDataMessage text='Choose required fields'/>)}

                {drilldownParams && (
                    <SlideoutPanel
                        title={detailsTitle}
                        onClose={closeDrillDown}
                        maxHeightPercentage={66}
                    >
                        <RRTOBDrillDown params={drilldownParams}/>
                    </SlideoutPanel>
                )}
            </Layout>

            {showTableSettingsModal && (
                <CustomSettingsFilterModal
                    tableId={id}
                    tableRef={tableRef}
                    tableType={TableType.RRTradeOutBasic}
                    onClose={() => setShowTableSettingsModal(false)}
                    onSave={() => setIsTableDirty(true)}
                />
            )}

            {buttonContainer && createPortal(
                <CancelSaveButtons
                    onCancel={onCancel}
                    onSave={onTableSave}
                    isLoading={saveMutation.isPending}
                    isDisabled={!isTableDirty || !tableMeta.name}
                />,
                buttonContainer,
            )}
        </>
    )
})
