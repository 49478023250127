import React from 'react'

import { createRoot } from 'react-dom/client'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { BrowserRouter } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'

import App from '@/App'
import { ErrorBoundary } from '@/components/base'
import { AuthContainer, AuthContext, MeContext, PostAuthAppPreparations, TableSettingsProvider } from '@/components/containers'
import { sentryInit } from '@/core/sentry'
import StoreProvider from '@/hooks/useStore'
import '@/styles/overrides/velt.scss'

import { agGridLicenseKey } from './agGridLicenseKey'
import reportWebVitals from './reportWebVitals'

import './index.scss'

sentryInit()

const enableMocking = async () => {
    if (process.env.NODE_ENV === 'development' && process.env.DEV_API_MOCKS === 'true') {
        const { worker } = await import('@/mocks/browser')
        return worker.start({ onUnhandledRequest: 'bypass' })
    }
    return Promise.resolve()
}

// Prefetch modules list (loading after main bundle)
import(/* webpackPrefetch: true */ '@ag-grid-community/client-side-row-model')
import(/* webpackPrefetch: true */ '@ag-grid-community/core')
import(/* webpackPrefetch: true */ '@ag-grid-community/csv-export')
import(/* webpackPrefetch: true */ '@ag-grid-enterprise/charts-enterprise')
import(/* webpackPrefetch: true */ '@ag-grid-enterprise/clipboard')
import(/* webpackPrefetch: true */ '@ag-grid-enterprise/column-tool-panel')
import(/* webpackPrefetch: true */ '@ag-grid-enterprise/core')
import(/* webpackPrefetch: true */ '@ag-grid-enterprise/excel-export')
import(/* webpackPrefetch: true */ '@ag-grid-enterprise/filter-tool-panel')
import(/* webpackPrefetch: true */ '@ag-grid-enterprise/menu')
import(/* webpackPrefetch: true */ '@ag-grid-enterprise/range-selection')
import(/* webpackPrefetch: true */ '@ag-grid-enterprise/row-grouping')
import(/* webpackPrefetch: true */ '@ag-grid-enterprise/set-filter')
import(/* webpackPrefetch: true */ '@ag-grid-enterprise/status-bar')

import('@ag-grid-enterprise/core').then(({ LicenseManager }) => LicenseManager.setLicenseKey(agGridLicenseKey))
import('@ag-grid-enterprise/charts-enterprise').then(({ AgCharts }) => AgCharts.setLicenseKey(agGridLicenseKey))

const container = document.getElementById('root')
// @ts-expect-error TS(2345) FIXME: Argument of type 'HTMLElement | null' is not assig... Remove this comment to see the full error message
const root = createRoot(container)

export const queryClient = new QueryClient()

enableMocking().then(() => {
    root.render(
        <StoreProvider>
            <QueryClientProvider client={queryClient}>
                <BrowserRouter>
                    <QueryParamProvider adapter={ReactRouter6Adapter}>
                        <ErrorBoundary level='top'>
                            <AuthContext>
                                <AuthContainer>
                                    <MeContext>
                                        <PostAuthAppPreparations>
                                            <TableSettingsProvider>
                                                <App/>
                                            </TableSettingsProvider>
                                        </PostAuthAppPreparations>
                                    </MeContext>
                                </AuthContainer>
                            </AuthContext>
                        </ErrorBoundary>
                    </QueryParamProvider>
                </BrowserRouter>
            </QueryClientProvider>
        </StoreProvider>,
    )
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

// Check if HMR interface is enabled
if (module.hot) {
    // Accept hot update
    module.hot.accept()
}
