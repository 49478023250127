import { CellClassFunc, ColDef, ColGroupDef, HeaderClassParams } from '@ag-grid-community/core'

import { ExcelClasses } from '@/constants/reportTable'

export enum Feb25DetailsClasses {
    HeaderL1 = 'header-l1',
    RightAlignedHeader = 'ag-right-aligned-header', // standard ag-grid class
    RightAlignedCell = 'ag-right-aligned-cell', // standard ag-grid class
    RightBorder1 = 'right-border-1', // right border 1px
    FooterRow = 'footer-row', // footer row
    ClickableRow = 'clickable-row', // clickable row
}

export type Feb25DetailsHeaderClass<TData = any, TValue = any> = `${Feb25DetailsClasses | ExcelClasses}` | Array<`${Feb25DetailsClasses | ExcelClasses}`> | ((params: HeaderClassParams<TData, TValue>) => `${Feb25DetailsClasses}` | Array<`${Feb25DetailsClasses}`> | undefined)

export type Feb25DetailsCellClass<TData = any, TValue = any> = `${Feb25DetailsClasses | ExcelClasses}` | Array<`${Feb25DetailsClasses | ExcelClasses}`> | CellClassFunc<TData, TValue>

export interface Feb25DetailsColDef extends Omit<ColDef, 'headerClass' | 'cellClass'> {
    headerClass?: Feb25DetailsHeaderClass
    cellClass?: Feb25DetailsCellClass
    fractionDigits?: number
}

export interface Feb25DetailsColGroupDef extends Omit<ColGroupDef, 'headerClass' | 'cellClass'> {
    headerClass?: Feb25DetailsHeaderClass
    cellClass?: Feb25DetailsCellClass
}
