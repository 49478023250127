import { getRequestQueries } from '@/api/utils/getRequestQueries'

export interface LeaseExpirationDrillDownItem {
    id: number
    contract_object_id: number
    asset_name: string
    owner_id: number | null
    unit_id: number
    unit_name: string
    unit_square_feet: number
    unit_segment: string | null
    unit_floor: string | null
    unit_floor_bedrooms: string | null
    unit_floor_bathrooms: string | null
    unit_status: string
    market_rent: number
    tenant_name: string
    start_date: string
    end_date: string
    move_in_date: string
    move_out_date: string
    lease_expiration_month: number
    lease_expiration_year: number
    lease_expiration_quarter: number
    count: number
    unit_to_building_sf_percentage: number
    lease_term_months: number
    lease_term_years: number
    previous_lease_start_date: string | null
    previous_lease_end_date: string | null
    previous_base_rent: number | null
    is_new_tenant: number
    is_occupied: number
    is_renewal: number
    balance: number
    program_designation: string
    max_rent: number
    gain_loss_to_lease: number | null
    gain_loss_to_lease_percentage: number | null
    term_remaining_years: number
    term_remaining_months: number
    base_rent_per_year: number | null
    base_rent_per_month: number | null
    base_rent_per_year_per_sf: number | null
}

export interface LeaseExpirationRow {
    year: string
    leases: number
    sf: number
    rent_annual: number
    rent_psf: number
    sf_percentage: number
    rent_annual_percentage: number
    drill_down: LeaseExpirationDrillDownItem[]
}

export interface ReportingLeaseExpirationItem {
    rows: LeaseExpirationRow[]
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FetchReportingLeaseExpirationQueryParams {}

export const REPORTING_LEASE_EXPIRATION_URL = '/reporting/lease-expiration'

export const {
    useItemsQuery: useReportingLeaseExpirationQuery,
    useItemUpdateMutation: useReportingLeaseExpirationUpdateMutation,
    useItemsCountQuery: useReportingLeaseExpirationCountQuery,
    useItemCreateMutation: useReportingLeaseExpirationCreateMutation,
    useItemDeleteMutation: useReportingLeaseExpirationDeleteMutation,
} = getRequestQueries<ReportingLeaseExpirationItem, FetchReportingLeaseExpirationQueryParams>({
    url: REPORTING_LEASE_EXPIRATION_URL,
    extractItemsFn: (data: ReportingLeaseExpirationItem) => [data],
})
