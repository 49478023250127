import React, { useEffect, useState } from 'react'

import { CommentAnnotation } from '@veltdev/types'

import { useVeltClient, VeltSidebarButton } from '@veltdev/react'

import { Icon } from '@/components/base'
import { Layout } from '@/components/containers'
import { GeneratedColor } from '@/styles/tokens/Tokens.types'

import { VeltReportNoteProps } from './VeltReportNote.types'

export const VeltReportNote = (props: VeltReportNoteProps) => {
    const { documentId, handleClick, noteLocationId } = props

    const { client } = useVeltClient()

    const [veltNotesCount, setVeltNotesCount] = useState(0)
    const [hovered, setHovered] = useState(false)

    useEffect(() => {
        if (!client || !documentId) return

        const commentElement = client.getCommentElement()

        if (documentId) {
            const subscriptionNotes = commentElement.getAllCommentAnnotations(documentId)
                .subscribe((comments = []) => {
                    const active = (comments || []).filter((comment: CommentAnnotation) => comment.status.id !== 'RESOLVED' && comment.location?.id === noteLocationId)
                    setVeltNotesCount(active.length)
                })

            return () => {
                subscriptionNotes.unsubscribe()
            }
        }
    }, [client, documentId])

    const iconColor: GeneratedColor = veltNotesCount ? 'colorsPrimaryPurple' : hovered ? 'colorsSecondaryPurple100' : 'colorsTransparent100'

    return (
        <div
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            // Cancel AgGrid click on cell
            ref={(ref) => {
                if (!ref) return

                ref.onclick = (e) => {
                    e.stopPropagation()
                    handleClick()
                }
            }}
        >
            <VeltSidebarButton>
                <Layout
                    slot='button'
                    style={{
                        width: 70,
                        cursor: 'pointer',
                    }}
                    align='center'
                    justify='center'
                >
                    <Icon
                        name='stickyNote'
                        color={iconColor}
                    />
                </Layout>
            </VeltSidebarButton>
        </div>
    )
}
