import React, { createContext, useState } from 'react'

import { ConditionalFormatRule } from '@/pages/TablesPage/TableBuilderPage/ConditionalRuleEditor/ConditionalRuleEditor.types'

import { TableSettings, TableSettingsContextProps, TableSettingsProviderProps } from './TableSettingsContext.types'

const TableSettingsContext = createContext<TableSettingsContextProps>({
    tableSettings: [],
    getTableSetting: () => {
        throw new Error('getTableSetting must be used within a TableSettingsProvider')
    },
    setTableSetting: () => {
        throw new Error('setTableSetting must be used within a TableSettingsProvider')
    },
    showTableSettingsModal: false,
    setShowTableSettingsModal: () => {
        throw new Error('setShowTableSettingsModal must be used within a TableSettingsProvider')
    },
})

export const TableSettingsProvider = (props: TableSettingsProviderProps) => {
    const [tableSettings, setTableSettings] = useState<TableSettings[]>([])
    const [showTableSettingsModal, setShowTableSettingsModal] = useState(false)

    const getTableSetting = (tableId: number) => {
        const settings = tableSettings.find((tableSetting) => tableSetting.tableId === tableId)
        if (!settings) {
            return {
                tableId,
                conditionalRules: [],
            }
        } else {
            return settings
        }
    }

    const setTableSetting = (tableId: number, conditionalRules: ConditionalFormatRule[]) => {
        const exists = !!tableSettings.find((tableSetting) => tableSetting.tableId === tableId)
        if (exists) {
            setTableSettings(tableSettings.map((tableSetting) => {
                if (tableSetting.tableId === tableId) {
                    return {
                        ...tableSetting,
                        conditionalRules,
                    }
                }
                return tableSetting
            }))
        } else {
            setTableSettings([...tableSettings, {
                tableId,
                conditionalRules,
            }])
        }
    }

    return (
        <TableSettingsContext.Provider
            value={{
                tableSettings,
                getTableSetting,
                setTableSetting,
                showTableSettingsModal,
                setShowTableSettingsModal,
            }}
        >{props.children}</TableSettingsContext.Provider>
    )
}

export const useTableSettings = () => {
    const context = React.useContext(TableSettingsContext)
    if (context === undefined) {
        throw new Error('useTableSettings must be used within a TableSettingsProvider')
    }
    return context
}
