import { AssetType } from '@/models/core'
import { getReportScenarioInputQueryParam } from '@/pages/TablesPage/TableBuilderPage/TableBuilderPage.constants'

import { SelectInputWithModal } from '../SelectInputWithModal'

import { getScenarioOptions, SCENARIO_INPUT_QUERY_PARAM } from './ScenarioInput.constants'
import { ScenarioInputProps } from './ScenarioInput.types'

export const ScenarioInput = (props: ScenarioInputProps) => {
    const {
        assetType = AssetType.MULTIFAMILY,
        defaultActiveOptions,
        multiselect = false,
        insideReport = false,
        tableId,
    } = props

    return (
        <SelectInputWithModal
            paramName={SCENARIO_INPUT_QUERY_PARAM.name}
            paramType={SCENARIO_INPUT_QUERY_PARAM.type}
            options={getScenarioOptions(assetType)}
            label='Scenario'
            placeholder='Select scenario'
            required
            width='150px' // width of the longest option
            defaultActiveOptions={defaultActiveOptions}
            multiSelect={multiselect}
            inputParams={insideReport ? getReportScenarioInputQueryParam(tableId) : undefined}
        />
    )
}
