import { useCallback, useMemo, useRef, useState } from 'react'

import { CellClickedEvent, RowClassParams } from '@ag-grid-community/core'
import { AND, EQ } from 'mobx-orm'

import { AgGridReact } from '@ag-grid-community/react'

import { useAssetsQuery } from '@/api/asset/asset'
import { useGeneralLedgerDrillDownQuery } from '@/api/reportingData/generalLedgerDrillDown'
import { ReportingPnLVarianceNewDrillDownRow, useReportingProfitAndLossVarianceNewDrillDownQuery } from '@/api/reportingData/reportingProfitAndLossVarianceNewDrillDown'
import { Button, SideModalMulti, TableSearchInput } from '@/components/base'
import styles from '@/components/base/SideModalMulti/SideModalMulti.module.scss'
import { Layout } from '@/components/containers'
import { AgGridTable } from '@/components/tables'
import { Feb25DetailsClasses } from '@/components/tables/AgGridTable/themes/feb25Details/feb25Details.types'
import { useTableRangeSelectionModules } from '@/components/tables/utils/useTableRangeSelectionModules'
import { TimeComparisonPeriod, TIME_COMPARISON_PERIOD_LABELS } from '@/constants/timeComparisonPeriods'
import { monthIndToString } from '@/utils/date/monthInd'

import { SideDrilldownTitle } from '../../sideDrilldownComponents/SideDrilldownTitle'

import { DrillDownState, getPnlVaDrillDownColDefs, pnlVaDrillDownGridOptions } from './PnLVarianceDrillDownSlideover.constant'
import { PnLVarianceDrillDownSlideoverProps } from './PnLVarianceDrillDownSlideover.types'

export const PnLVarianceDrillDownSlideover = (props: PnLVarianceDrillDownSlideoverProps) => {
    const { params, onClose } = props
    const {
        accountId,
        accountName,
        accountCode,
        assetIds,
        tBLedgerId,
        startMonth,
        endMonth,
        timeComparison,
    } = params

    const initialDrilldownState = assetIds.length > 1
        ? DrillDownState.Assets
        : startMonth === endMonth
            ? DrillDownState.SourceAccounts
            : DrillDownState.Months

    const rangeSelectionModules = useTableRangeSelectionModules()
    const tableRef = useRef<AgGridReact>(null)

    const [requestedAssetIds, setRequestedAssetIds] = useState<string[]>(assetIds)
    const [requestedStartMonth, setRequestedStartMonth] = useState<number | undefined>(startMonth)
    const [requestedEndMonth, setRequestedEndMonth] = useState<number | undefined>(endMonth)
    const [sourceAccountString, setSourceAccountString] = useState<string | null>(null)
    const [sourceAccountId, setSourceAccountId] = useState<number | null | undefined>(null)
    const [drilldownLevel, setDrilldownLevel] = useState(0)
    const [drilldownState, setDrilldownState] = useState(initialDrilldownState)

    const isDrillDownQueryEnabled =
        drilldownState === DrillDownState.Months ||
        drilldownState === DrillDownState.Assets ||
        drilldownState === DrillDownState.SourceAccounts

    const isLedgerDetailQueryEnabled = Boolean(drilldownState === DrillDownState.LedgerDetail && sourceAccountId)

    const assetsQuery = useAssetsQuery()
    const { data: reportData, isLoading } = useReportingProfitAndLossVarianceNewDrillDownQuery(
        {
            filter: AND(
                EQ('asset_ids', requestedAssetIds),
                EQ('trial_balance_ledger_id', tBLedgerId),
                EQ('start_month', requestedStartMonth),
                EQ('end_month', requestedEndMonth),
                EQ('account_id', accountId),
            ),
        },
        { enabled: isDrillDownQueryEnabled },
    )

    const { data: glData, isLoading: glDataIsLoading } = useGeneralLedgerDrillDownQuery(
        {
            filter: AND(
                EQ('asset_id', requestedAssetIds?.[0]),
                EQ('month_ind', requestedStartMonth),
                EQ('source_account_id', sourceAccountId),
            ),
        },
        { enabled: isLedgerDetailQueryEnabled },
    )

    const period = useMemo(() => (
        requestedStartMonth === requestedEndMonth
            ? requestedStartMonth ? monthIndToString(requestedStartMonth) : ''
            : requestedStartMonth && requestedEndMonth ? `${monthIndToString(requestedStartMonth)} – ${monthIndToString(requestedEndMonth)}` : ''
    ), [requestedStartMonth, requestedEndMonth])

    const title = useMemo(() => {
        switch (drilldownState) {
            case DrillDownState.Assets:
            case DrillDownState.Months:
                return `${TIME_COMPARISON_PERIOD_LABELS[timeComparison as TimeComparisonPeriod]}: (${accountCode}) ${accountName} [${period}]`
            case DrillDownState.SourceAccounts:
                return `(${accountCode}) ${accountName}, ${period}`
            case DrillDownState.LedgerDetail:
                return `${sourceAccountString}, ${period}` ?? ''
            default:
                return ''
        }
    }, [drilldownState, timeComparison, accountCode, accountName, period, sourceAccountString])

    const subtitle = useMemo(() => {
        if (requestedAssetIds.length > 1) {
            return 'Multiple Assets'
        } else {
            const assetId = Number(requestedAssetIds[0])
            const asset = assetsQuery.data?.find(asset => asset.id === assetId)
            return asset ? asset.name : ''
        }
    }, [requestedAssetIds, assetsQuery])

    const colDefs = useMemo(() => getPnlVaDrillDownColDefs({
        drilldownState,
        accountCode: accountCode ?? '',
        accountName: accountName ?? '',
    }), [drilldownState, accountCode, accountName])

    const handleClose = useCallback(() => {
        setDrilldownLevel(drilldownLevel - 1)

        if (drilldownLevel < 1) {
            onClose()
            return
        }

        switch (drilldownState) {
            case DrillDownState.Months:
                setDrilldownState(DrillDownState.Assets)
                setRequestedAssetIds(assetIds)
                break
            case DrillDownState.SourceAccounts:
                if (startMonth === endMonth) {
                    setDrilldownState(DrillDownState.Assets)
                    setRequestedAssetIds(assetIds)
                } else {
                    setDrilldownState(DrillDownState.Months)
                    setRequestedStartMonth(startMonth)
                    setRequestedEndMonth(endMonth)
                }
                break
            case DrillDownState.LedgerDetail:
                setDrilldownState(DrillDownState.SourceAccounts)
                setSourceAccountId(null)
                setSourceAccountString(null)
                break
            default:
                onClose()
        }
    }, [drilldownState, drilldownLevel, assetIds, startMonth, endMonth, onClose])

    const onCellClicked = useCallback((e: CellClickedEvent<ReportingPnLVarianceNewDrillDownRow>) => {
        if (!e.node.data) {
            return
        }

        switch (drilldownState) {
            case DrillDownState.Assets:
                setRequestedAssetIds([e.node.data.asset_id])
                setDrilldownLevel(drilldownLevel + 1)
                if (startMonth === endMonth) {
                    setDrilldownState(DrillDownState.SourceAccounts)
                } else {
                    setDrilldownState(DrillDownState.Months)
                }
                break
            case DrillDownState.Months:
                setDrilldownState(DrillDownState.SourceAccounts)
                setRequestedStartMonth(e.data?.month_ind)
                setRequestedEndMonth(e.data?.month_ind)
                setDrilldownLevel(drilldownLevel + 1)
                break
            case DrillDownState.SourceAccounts:
                setDrilldownState(DrillDownState.LedgerDetail)
                setSourceAccountId(e.data?.account_id)
                setSourceAccountString(`(${e.data?.account_code ?? ''}) ${e.data?.account_name ?? ''}`)
                setDrilldownLevel(drilldownLevel + 1)
                break
        }
    }, [drilldownState, drilldownLevel, startMonth, endMonth])

    return (
        <SideModalMulti
            width={1057}
            onBgClick={handleClose}
            level={drilldownLevel}
        >
            <Layout direction='column' gap={32} flexGrow={1}>
                <Layout
                    justify='space-between'
                    gap={16}
                    mt={20}
                    mr={20}
                    ml={32}
                >
                    <SideDrilldownTitle
                        title={title}
                        subtitle={subtitle ?? ''}
                    />
                    <Button
                        className={styles.closeButton}
                        icon='cross'
                        theme='secondary'
                        onClick={handleClose}
                    />
                </Layout>
                <Layout
                    direction='column'
                    flexGrow={1}
                    gap={32}
                    mr={28}
                    mb={20}
                    ml={32}
                >
                    <TableSearchInput tableRef={tableRef}/>
                    <Layout direction='column' flexGrow={1}>
                        {/* @ts-expect-error TODO: make AgGridTable generic to be able to pass theme */}
                        <AgGridTable
                            ref={tableRef}
                            items={(drilldownState === DrillDownState.LedgerDetail ? glData : reportData) ?? []}
                            loading={isLoading || glDataIsLoading}
                            hideStatusBar
                            noBorders
                            {...pnlVaDrillDownGridOptions}
                            lazyModules={rangeSelectionModules}
                            columnDefs={colDefs}
                            grandTotalRow={drilldownState === DrillDownState.LedgerDetail ? undefined : 'bottom'}
                            onCellClicked={onCellClicked}
                            getRowClass={(params: RowClassParams<ReportingPnLVarianceNewDrillDownRow>) => {
                                if (params.node.footer) {
                                    return Feb25DetailsClasses.FooterRow
                                }
                                return drilldownState === DrillDownState.LedgerDetail ? '' : Feb25DetailsClasses.ClickableRow
                            }}
                            headerHeight={drilldownState === DrillDownState.LedgerDetail ? 60 : undefined}
                        />
                    </Layout>
                </Layout>
            </Layout>
        </SideModalMulti>
    )
}
