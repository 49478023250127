import { useEffect } from 'react'

import { DateRangePicker } from '@/components/base'
import { useInputState } from '@/hooks'
import { dateToMonthInd, monthIndToDateNoTimezoneReset } from '@/utils/date/monthInd'

import { QuarterString, quarterToDate } from '../../../utils/date/quarter'
import { QUARTER_RANGE_END_QUERY_PARAM, QUARTER_RANGE_START_QUERY_PARAM } from '../QuarterRangeInput'
import { YEAR_RANGE_END_QUERY_PARAM, YEAR_RANGE_START_QUERY_PARAM } from '../YearRangeInput'

import { MONTH_RANGE_END_QUERY_PARAM, MONTH_RANGE_START_QUERY_PARAM } from './MonthRangeInput.constants'
import { MonthRangeInputProps } from './MonthRangeInput.types'

export const MonthRangeInput = (props: MonthRangeInputProps) => {
    const { defaultStart, defaultEnd, maxDate, required, label, startRangeInputParams, endRangeInputParams } = props
    const [monthStartParam, monthSetStartParam] = useInputState({
        ...MONTH_RANGE_START_QUERY_PARAM,
        ...startRangeInputParams,
    })
    const [monthEndParam, monthSetEndParam] = useInputState({
        ...MONTH_RANGE_END_QUERY_PARAM,
        ...endRangeInputParams,
    })
    const [yearStartParam] = useInputState({
        ...YEAR_RANGE_START_QUERY_PARAM,
    })
    const [yearEndParam] = useInputState({
        ...YEAR_RANGE_END_QUERY_PARAM,
    })
    const [quarterStartParam] = useInputState({
        ...QUARTER_RANGE_START_QUERY_PARAM,
    })
    const [quarterEndParam] = useInputState({
        ...QUARTER_RANGE_END_QUERY_PARAM,
    })

    useEffect(() => {
        if (!monthStartParam && defaultStart) {
            monthSetStartParam(defaultStart)
        }
    }, [monthStartParam, defaultStart])

    useEffect(() => {
        if (!monthEndParam && defaultEnd) {
            monthSetEndParam(defaultEnd)
        }
    }, [defaultEnd, monthEndParam])

    let dateFrom = monthStartParam ? monthIndToDateNoTimezoneReset(monthStartParam) : undefined
    let dateTo = monthEndParam ? monthIndToDateNoTimezoneReset(monthEndParam) : undefined

    if (!dateFrom && quarterStartParam) {
        dateFrom = quarterToDate(quarterStartParam as QuarterString)
        monthSetStartParam(dateFrom ? dateToMonthInd(dateFrom) : undefined)
    }
    if (!dateTo && quarterEndParam) {
        dateTo = quarterToDate(quarterEndParam as QuarterString)
        monthSetEndParam(dateTo ? dateToMonthInd(dateTo) : undefined)
    }

    if (!dateFrom && yearStartParam) {
        dateFrom = new Date(yearStartParam, 0)
        monthSetStartParam(dateFrom ? dateToMonthInd(dateFrom) : undefined)
    }
    if (!dateTo && yearEndParam) {
        dateTo = new Date(yearEndParam, 0)
        monthSetEndParam(dateTo ? dateToMonthInd(dateTo) : undefined)
    }

    return (
        <DateRangePicker
            type='month'
            label={label}
            dateFrom={dateFrom}
            dateTo={dateTo}
            maxDate={maxDate}
            onChange={({ dateFrom, dateTo }) => {
                monthSetStartParam(dateFrom ? dateToMonthInd(dateFrom) : undefined)
                monthSetEndParam(dateTo ? dateToMonthInd(dateTo) : undefined)
            }}
            error={required && (!dateFrom || !dateTo)}
        />
    )
}
