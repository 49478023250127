import { getRequestQueries } from '@/api/utils/getRequestQueries'

export interface ReportingRentRollDrillDownItem {
    rows: ReportingRentRollDrillDownRow[]
}

export interface ReportingRentRollDrillDownRow {
    monthly_price: number
    source_charge_code: string
}

export const REPORTING_RENT_ROLL_DRILL_DOWN_URL = 'reporting/rent-roll/drill-down'

export const {
    useItemsQuery: useReportingRentRollDrillDownQuery,
} = getRequestQueries<ReportingRentRollDrillDownRow, Record<string, unknown>>({
    url: REPORTING_RENT_ROLL_DRILL_DOWN_URL,
    extractItemsFn: (data: ReportingRentRollDrillDownItem) => data.rows,
})
