import { observer } from 'mobx-react-lite'

import { LegacySelect, LegacySelectItemData } from '@/components/base'
import { ASSET_TYPE_LABEL } from '@/models/core/AssetType'

import { AssetTypeSelectLegacyProps } from './AssetTypeSelectLegacy.types'

const options: LegacySelectItemData[] = [...ASSET_TYPE_LABEL].map(([value, label]) => ({
    value,
    label,
}))

/**
 * @deprecated
 * Use AssetTypeSelect instead
 */
export const AssetTypeSelectLegacy = observer((props: AssetTypeSelectLegacyProps) => {
    return (
        <LegacySelect
            options={options}
            label='Asset Type'
            placeholder='Choose asset type'
            {...props}
        />
    )
})
