import { getRequestQueries } from '@/api/utils/getRequestQueries'

export interface GeneralLedgerDrillDownItem {
    rows: GeneralLedgerDrillDownRow[]
}

export interface GeneralLedgerDrillDownRow {
    id: number
    transaction_code?: string
    transaction_date?: string
    debit?: number
    credit?: number
    closing_balance?: number
    vendor?: string
    description?: string
    remarks?: string
    unit?: string
}

export const GENERAL_LEDGER_DRILL_DOWN_URL = 'general-ledger-drill-down'

export const {
    useItemsQuery: useGeneralLedgerDrillDownQuery,
} = getRequestQueries<GeneralLedgerDrillDownRow, Record<string, unknown>>({
    url: GENERAL_LEDGER_DRILL_DOWN_URL,
    extractItemsFn: (data: GeneralLedgerDrillDownItem) => data.rows,
})
