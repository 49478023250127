import { CSSProperties } from 'react'

import { CellClassParams, ICellRendererParams, ProcessCellForExportParams } from '@ag-grid-community/core'

import { ReportingPnLRow, ReportingPnLRowType } from '@/api/reportingData/reportingProfitAndLoss'
import { ReportingDataValueType } from '@/constants/reportingDataValueTypes'
import { ExcelClasses } from '@/constants/reportTable'

import { applyFunction, ConditionalFormatRule, ConditionalFormatStyle } from '../../ConditionalRuleEditor/ConditionalRuleEditor.types'
import { applyConditionalFormattingRuleToStyle } from '../../ConditionalRuleEditor/ConditionalRuleEditor.utils'

/**
 * Transforms percentage values from decimal to percentage (0.1234 => 12.34)
 */
export const normalizePercentageValues = (rows: ReportingPnLRow[]): ReportingPnLRow[] => {
    return rows.map(row => {
        if (row.value_type === ReportingDataValueType.PERCENTAGE) {
            return {
                ...row,
                values: Object.entries(row.values).reduce((acc, [key, value]) => {
                    return {
                        ...acc,
                        [key]: (typeof value === 'number') ? value * 100 : value,
                    }
                }, {}),
            }
        }

        return row
    })
}

/**
 * Highlight total cells in Excel export
 */
export const getExcelGroupClasses = (params: CellClassParams): string[] => {
    const classes: ExcelClasses[] = []
    if (params.node.group && params.node.footer) {
        classes.push(ExcelClasses.Total)
    }

    if (params.node.group && params.node.level === 0) {
        classes.push(ExcelClasses.Group)
    }

    if (params.data?.type === ReportingPnLRowType.SubCategoryMetric) {
        classes.push(ExcelClasses.Italic)
    }

    if (params.data?.type === ReportingPnLRowType.CategoryMetric) {
        classes.push(ExcelClasses.GrandTotal)
    }

    return classes
}

/**
 *
 * - Remove values from group rows except for footer cells
 * - Fix total rows in Excel export
 */
export const processPnLTableTotalCells = (params: ProcessCellForExportParams): string => {
    const { node, column } = params
    const colId = column.getColId()
    const isMetricColumn = colId === 'name'

    if (node?.group && !node?.footer) {
        return isMetricColumn ? `${node.key}` : ''
    }

    if (node?.footer && isMetricColumn) {
        return `Total ${node.key}`
    }

    return params.value
}

export const cellRenderStyledFromConditionalFormatRules = (conditionalFormatRules: ConditionalFormatRule[], params: ICellRendererParams<ReportingPnLRow>, colScenario: 'all-data' | 'total') => {
    if ((params.node.group && params.node.expanded) || params.value === undefined || params.value === '' || params?.data?.type !== 'category') {
        return params.valueFormatted
    }

    let newStyle: ConditionalFormatStyle = {}

    // Apply conditional formatting rules. Reverse the array to apply the rules in priority order
    // First rule gets applied last and therefore has the highest priority since styles can overwrite each other
    for (const rule of conditionalFormatRules.reverse()) {
        // Determine if condition(s) are met to apply a rule's styling to the cell
        let conditionMet = true

        // Determine reference value for primary column and check then primary condition
        let primaryConditionColumnValue
        if (colScenario === 'all-data') {
            primaryConditionColumnValue = (rule.primaryConditionColumn === 'total') ? params?.data?.values?.total : params.value
        }
        if (colScenario === 'total' && rule.primaryConditionColumn === 'total') {
            primaryConditionColumnValue = params.value
        }
        if (colScenario === 'total' && rule.primaryConditionColumn === 'all-data') {
            // when subject col is total and condition col is data column, use any col value that matches the rule to test the condition for total column
            for (const [key, value] of Object.entries(params.data.values)) {
                if (!value) continue
                const valueFormatted = parseFloat((value as number).toFixed(0))
                if (key !== 'total' && applyFunction(rule.primaryConditionFunction, valueFormatted, rule.primaryConditionValue)) {
                    primaryConditionColumnValue = value
                    break
                }
            }
        }
        if (!primaryConditionColumnValue) continue
        const primaryConditionColumnValueFormatted = parseFloat(primaryConditionColumnValue.toFixed(0))

        const firstConditionResult = applyFunction(rule.primaryConditionFunction, primaryConditionColumnValueFormatted, rule.primaryConditionValue)

        // Check Secondary Condition (may not be present)
        let secondaryConditionColumnValue
        if (rule.secondaryConditionColumn) {
            if (colScenario === 'all-data') {
                secondaryConditionColumnValue = (rule.secondaryConditionColumn === 'total') ? params?.data?.values?.total : params.value
            }
            if (colScenario === 'total' && rule.secondaryConditionColumn === 'total') {
                secondaryConditionColumnValue = params.value
            }
            if (colScenario === 'total' && rule.secondaryConditionColumn === 'all-data') {
                // when subject col is total and condition col is data column, use any col value that matches the rule to test the condition for total column
                for (const [key, value] of Object.entries(params.data.values)) {
                    if (!value) continue
                    const valueFormatted = parseFloat((value as number).toFixed(0))
                    if (key !== 'total' && rule.secondaryConditionFunction && applyFunction(rule.secondaryConditionFunction, valueFormatted, rule.secondaryConditionValue)) {
                        secondaryConditionColumnValue = value
                        break
                    }
                }
            }
        }

        const secondaryConditionColumnValueFormatted = secondaryConditionColumnValue && parseFloat(secondaryConditionColumnValue.toFixed(0))
        const secondaryConditionResult = (secondaryConditionColumnValue && rule.secondaryConditionFunction && applyFunction(rule.secondaryConditionFunction, secondaryConditionColumnValueFormatted, rule.secondaryConditionValue)) ?? false

        const applySecondaryCondition = secondaryConditionColumnValue && rule.secondaryConditionFunction && rule.secondaryConditionValue && rule.secondaryConditionColumn
        if (applySecondaryCondition) {
            if (rule.conditionCombinationOperator === 'AND') {
                conditionMet = firstConditionResult && secondaryConditionResult
            } else {
                conditionMet = firstConditionResult || secondaryConditionResult
            }
        } else {
            conditionMet = firstConditionResult
        }

        // Apply the rule's styling to the cell if the condition(s) are met
        if (conditionMet && rule.columnsToBeFormatted.includes(colScenario)) {
            newStyle = applyConditionalFormattingRuleToStyle(rule, newStyle)
        }
    }

    const styledIcon = newStyle.cellIconStyle ? <span className={newStyle.cellIconStyle?.iconClass} style={newStyle?.cellIconStyle ? newStyle.cellIconStyle as CSSProperties : {}}/> : <span/>

    return (
        <div style={newStyle as CSSProperties}>{styledIcon}<span>{params.valueFormatted}</span></div>
    )
}
