import { TrialBalanceTemplateBindingItem } from '@/api/trialBalance/trialBalanceTemplate'
import { Alert, Confirm } from '@/components/base'
import { useApplyTemplates } from '@/pages/MainPage/UserPages/MappingPage/MappingPage.hooks'

import { CONFIRMATION_TEXT } from './ApplyAllTemplatesModal.constants'
import { ApplyAllTemplatesModalProps } from './ApplyAllTemplatesModal.types'

export const ApplyAllTemplatesModal = (props: ApplyAllTemplatesModalProps) => {
    const {
        bindings, onClose, onDone,
        confirmText = 'Apply All Templates',
        cancelText = 'Back',
        content = CONFIRMATION_TEXT,
        requestAllFields = false,
    } = props

    const { isLoading, applyTemplates } = useApplyTemplates({
        onClose,
        onDone,
        requestAllFields,
    })

    const handleApplyTemplates = () => applyTemplates(bindings as TrialBalanceTemplateBindingItem[])

    return (
        <Confirm
            confirmText={confirmText}
            onConfirm={handleApplyTemplates}
            cancelText={cancelText}
            onCancel={onClose}
            isLoading={isLoading}
            disableEscapeKeyDown
            disableOverlayClick
        >
            <Alert type='warning' content={content}/>
        </Confirm>
    )
}
