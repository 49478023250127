import { getRequestQueries } from '@/api/utils/getRequestQueries'

export interface ReportingFolderItem {
    id: number
    name: string
    order: number
    parent_id: number
    company_id: number
    created_at: string
    updated_at: string
}

export interface FetchReportingFolderQueryParams {
    is_private?: boolean
}

export const REPORTING_FOLDER_URL = '/reporting/folder'

export const {
    useItemsQuery: useReportingFolderQuery,
    useItemUpdateMutation: useReportingFolderUpdateMutation,
    useItemsCountQuery: useReportingFolderCountQuery,
    useItemDeleteMutation: useReportingFolderDeleteMutation,
} = getRequestQueries<ReportingFolderItem, FetchReportingFolderQueryParams>({
    url: REPORTING_FOLDER_URL,
    errorMessageUpdate: 'Failed to update reporting folder',
    errorMessageDelete: 'Failed to delete reporting folder',
    errorMessageCreate: 'Failed to create reporting folder',
})
