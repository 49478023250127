import { useEffect } from 'react'

import { useAssetsQuery } from '@/api/asset/asset'
import { MonthRangeInput } from '@/components/baseInputs'
import { Layout } from '@/components/containers'
import { AssetSelectWithSideModalInput } from '@/components/models/asset'
import {
    GLOBAL_REPORT_MONTH_QUERY_PARAM,
    GLOBAL_REPORT_MONTH_RANGE_END_QUERY_PARAM,
    GLOBAL_REPORT_MONTH_RANGE_START_QUERY_PARAM, GLOBAL_REPORT_MULTY_ASSET_QUERY_PARAM,
} from '@/components/widgets/reports/reports.constants'
import { useInputState } from '@/hooks'

export const ReportFilters = () => {
    const assetQuery = useAssetsQuery()

    const [reportMonthRangeEndFilter] = useInputState(GLOBAL_REPORT_MONTH_RANGE_END_QUERY_PARAM)
    const [, setReportMonthFilter] = useInputState(GLOBAL_REPORT_MONTH_QUERY_PARAM)

    /**
     * Hack to sync range picker and single month to avoid creating one more filter for the reports
     */
    useEffect(() => {
        setReportMonthFilter(reportMonthRangeEndFilter)
    }, [reportMonthRangeEndFilter])

    return (
        <Layout gap={24} align='center' flexGrow={1}>
            <AssetSelectWithSideModalInput
                inputParams={GLOBAL_REPORT_MULTY_ASSET_QUERY_PARAM}
                label='Asset'
                options={assetQuery.data || []}
                multiSelect
                defaultActiveOptions='none'
            />
            <MonthRangeInput
                startRangeInputParams={GLOBAL_REPORT_MONTH_RANGE_START_QUERY_PARAM}
                endRangeInputParams={GLOBAL_REPORT_MONTH_RANGE_END_QUERY_PARAM}
                label='Time Period'
            />
        </Layout>
    )
}
