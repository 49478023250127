import React, { FC, Fragment, Suspense } from 'react'

// Too dangerous to manage imports order here automatically
/* eslint-disable no-restricted-imports */
/* eslint-disable import/order */

import * as Sentry from '@sentry/react'
import mixpanel from 'mixpanel-browser'
import { observer } from 'mobx-react-lite'
import { Navigate, Route, Routes } from 'react-router'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { Me } from '@/components/containers'
import AssetDeletePopup from '@/components/legacy/models/asset/asset-delete-popup'
import { AssetEditSideModal } from '@/components/legacy/models/asset/AssetEditSideModal'
import { CENTER_MODAL_CONTAINER } from '@/constants/ids'
import { CompanyUserMode } from '@/core/modes'
import { ADMIN_ROUTES_CONFIG, OWNER_ROUTES_CONFIG, MANAGER_ROUTES_CONFIG, COMMON_ROUTES_CONFIG } from '@/core/routes/routes.constants'
import { config } from '@/globals'
import { useMe } from '@/hooks/core/useMe'
import { useSetPageTitlteTag } from '@/hooks/usePageTitle'
import { AiChatPage } from '@/pages/MainPage/AdminPages/AiChatPage'
import {
    CompanyInvitesPopup,
} from '@/pages/MainPage/AdminPages/CompaniesPage/CompanyInvitesPage/CompanyInvitesPopup'
import {
    FileRenamePopup,
} from '@/pages/MainPage/CommonPages/CsvBuilderPage/CsvBuilderFilesSubPage/FilesTable/FileRenamePopup'
import {
    RemoveFilePopup,
} from '@/pages/MainPage/CommonPages/CsvBuilderPage/CsvBuilderFilesSubPage/FilesTable/RemoveFilePopup'
import { FileErrorsDataPointSideModal }
    from '@/pages/MainPage/CommonPages/DataSubmissionPage/DataSubmissionUploadsPage/FileErrorsDataPointSideModal'
import FileErrorsSideModal from '@/pages/MainPage/CommonPages/DataSubmissionPage/DataSubmissionUploadsPage/FileErrorsSideModal'
import { DataPointSideModal as ValidationTrackerDataPointSideModal }
    from '@/pages/MainPage/CommonPages/ValidationTrackerPage/DataPointSideModal'
import { MonthlyRentRollSideModal as ValidationTrackerMonthlyRentRollSideModal }
    from '@/pages/MainPage/CommonPages/ValidationTrackerPage/MonthlyRentRollSideModal'
import { MainPage } from '@/pages/MainPage/MainPage'
import { CompanyProfileNewInvitePopup } from '@/pages/MainPage/UserPages/CompanyProfilePage'
import { MappingTemplateCreatePage } from '@/pages/MainPage/UserPages/MappingPage/MappingTemplatesPage'
import MappingTemplateEditPage, {
    MappingTemplateApplyModal,
} from '@/pages/MainPage/UserPages/MappingPage/MappingTemplatesPage/MappingTemplateEditPage'
import { MappingTemplateAddMappingPage } from '@/pages/MainPage/UserPages/MappingPage/MappingTemplatesPage/MappingTemplateEditPage/MappingTemplateAddMappingPage'
import { MappingTemplateDeleteMappingPage } from '@/pages/MainPage/UserPages/MappingPage/MappingTemplatesPage/MappingTemplateEditPage/MappingTemplateDeleteMappingPage'
import { MappingTemplateUploadMappingPage } from '@/pages/MainPage/UserPages/MappingPage/MappingTemplatesPage/MappingTemplateEditPage/MappingTemplateUploadMappingPage'
import { SubmissionLogsPage } from '@/pages/UploadsPage'
import { lazyWithCatch } from '@/utils/lazyCatch'
import { ReportsHome } from '@/pages/MainPage/UserPages/ReportsPage/CustomReportPage'

/**
 * Lazy Pages. High lvl Pages and SubPages loaded with dynamic imports
*
* DON't use for Modals, Popups etc
*/

const ExcelPage = lazyWithCatch(() => import('@/pages/ExcelPage'))
const PPAPage = lazyWithCatch(() => import('@/pages/PPAPage'))
const PriorPeriodAdjustmentPage = lazyWithCatch(() => import('@/pages/PPAPage/PriorPeriodAdjustmentPage'))
const PriorPeriodTBPage = lazyWithCatch(() => import('@/pages/PPAPage/PriorPeriodTBPage'))
const CustomReportPage = lazyWithCatch(() => import('@/pages/MainPage/UserPages/ReportsPage/CustomReportPage'))
const ReportsPage = lazyWithCatch(() => import('@/pages/MainPage/UserPages/ReportsPage'))
const ReportsRentRollTopTenantsPage = lazyWithCatch(() => import('@/pages/MainPage/UserPages/ReportsPage/ReportsRentRollTopTenantsPage'))
const ReportsLeaseExpirationsPage = lazyWithCatch(() => import('@/pages/MainPage/UserPages/ReportsPage/ReportsLeaseExpirationsPage'))
const ReportsFinancialsPage = lazyWithCatch(() => import('@/pages/MainPage/UserPages/ReportsPage/ReportsFinancialsPage'))
const ReportsPortfolioPage = lazyWithCatch(() => import('@/pages/MainPage/UserPages/ReportsPage/ReportsPortfolioPage'))
const ReportsIncomeStatementPage = lazyWithCatch(() => import('@/pages/MainPage/UserPages/ReportsPage/ReportsIncomeStatementPage'))
const ReportsBalanceSheetPage = lazyWithCatch(() => import('@/pages/MainPage/UserPages/ReportsPage/ReportsBalanceSheetPage'))
const ReportsRentRollTabPage = lazyWithCatch(() => import('@/pages/MainPage/UserPages/ReportsPage/ReportsRentRollTabPage'))
const ReportsRentRollPage = lazyWithCatch(() => import('@/pages/MainPage/UserPages/ReportsPage/ReportsRentRollPage'))
const ReportsOccupancyPage = lazyWithCatch(() => import('@/pages/MainPage/UserPages/ReportsPage/ReportsOccupancyPage'))
const PackagesFinancialPage = lazyWithCatch(() => import('@/pages/MainPage/CommonPages/PackagesPage/PackagesApprovalPage/PackagesFinancialPage'))
const PackagesMappingsPage = lazyWithCatch(() => import('@/pages/MainPage/CommonPages/PackagesPage/PackagesApprovalPage/PackagesMappingsPage'))
const PackagesOverviewPage = lazyWithCatch(() => import('@/pages/MainPage/CommonPages/PackagesPage/PackagesApprovalPage/PackagesOverviewPage'))
const PackagesApprovalPage = lazyWithCatch(() => import('@/pages/MainPage/CommonPages/PackagesPage/PackagesApprovalPage'))
const PackagesPage = lazyWithCatch(() => import('@/pages/MainPage/CommonPages/PackagesPage'))
const PackagesDashboardPage = lazyWithCatch(() => import('@/pages/MainPage/CommonPages/PackagesPage/PackagesDashboardPage'))
const CompaniesPage = lazyWithCatch(() => import('@/pages/MainPage/AdminPages/CompaniesPage'))
const UserProfilePage = lazyWithCatch(() => import('@/pages/MainPage/UserPages/UserProfilePage'))
const CompanyUserPage = lazyWithCatch(() => import('@/pages/MainPage/UserPages/CompanyProfilePage/CompanyUserPage'))
const ValidationTrackerPage = lazyWithCatch(() => import('@/pages/MainPage/CommonPages/ValidationTrackerPage'))
const InternalNotFoundPage = lazyWithCatch(() => import('@/pages/errors/internal/InternalNotFoundPage'))
const ActualAdminAssetsPage = lazyWithCatch(() => import('@/pages/MainPage/AdminPages/AssetsPage'))
const LedgersPage = lazyWithCatch(() => import('@/pages/MainPage/UserPages/LedgersPage'))
const LedgerTrialBalancePage = lazyWithCatch(() => import('@/pages/MainPage/UserPages/LedgersPage/LedgerTrialBalancePage'))
const LedgerTrialBalanceDetailsPage = lazyWithCatch(() => import('@/pages/MainPage/UserPages/LedgersPage/LedgerTrialBalancePage/LedgerTrialBalanceDetailsPage'))
const LedgerRentRollPage = lazyWithCatch(() => import('@/pages/MainPage/UserPages/LedgersPage/LedgerRentRollPage'))
const LedgerRentRollDetailsPage = lazyWithCatch(() => import('@/pages/MainPage/UserPages/LedgersPage/LedgerRentRollPage/LedgerRentRollDetailsPage'))
const ReportsActualBudgetPage = lazyWithCatch(() => import('@/pages/MainPage/UserPages/ReportsPage/ReportsActualBudgetPage'))
const ReportsPropertyComparisonPage = lazyWithCatch(() => import('@/pages/MainPage/UserPages/ReportsPage/ReportsPropertyComparisonPage'))
const ReportsTbSummaryPage = lazyWithCatch(() => import('@/pages/MainPage/UserPages/ReportsPage/ReportsTbSummaryPage'))
const ReportsTrialBalancePage = lazyWithCatch(() => import('@/pages/MainPage/UserPages/ReportsPage/ReportsTrialBalancePage'))
const MappingPage = lazyWithCatch(() => import('@/pages/MainPage/UserPages/MappingPage'))
const MappingTemplatesPage = lazyWithCatch(() => import('@/pages/MainPage/UserPages/MappingPage/MappingTemplatesPage'))
const MappingRentRollPage = lazyWithCatch(() => import('@/pages/MainPage/UserPages/MappingPage/MappingRentRollPage'))
const MappingTrialBalancePage = lazyWithCatch(() => import('@/pages/MainPage/UserPages/MappingPage/MappingTrialBalancePage'))
const MappingHistoryPage = lazyWithCatch(() => import('@/pages/MainPage/UserPages/MappingPage/MappingHistoryPage'))
const CompanyUsers = lazyWithCatch(() => import('@/pages/MainPage/AdminPages/CompaniesPage/CompanyUsersSubpage'))
const CompanyProfilePage = lazyWithCatch(() => import('@/pages/MainPage/UserPages/CompanyProfilePage'))
const CompanyProfileDetails = lazyWithCatch(() => import('@/pages/MainPage/UserPages/CompanyProfilePage/CompanyProfileDetails'))
const CompanyProfileMembers = lazyWithCatch(() => import('@/pages/MainPage/UserPages/CompanyProfilePage/CompanyProfileMembers'))
const CompanyProfileInvites = lazyWithCatch(() => import('@/pages/MainPage/UserPages/CompanyProfilePage/CompanyProfileInvites'))
const UserCompanyUserPage = lazyWithCatch(() => import('@/pages/MainPage/AdminPages/CompaniesPage/CompanyUserPage'))
const CompanyInvitesPage = lazyWithCatch(() => import('@/pages/MainPage/AdminPages/CompaniesPage/CompanyInvitesPage'))
const DataSubmissionPage = lazyWithCatch(() => import('@/pages/MainPage/CommonPages/DataSubmissionPage'))
const DataSubmissionUploadsPage = lazyWithCatch(() => import('@/pages/MainPage/CommonPages/DataSubmissionPage/DataSubmissionUploadsPage'))
const CompaniesList = lazyWithCatch(() => import('@/pages/MainPage/AdminPages/CompaniesPage/CompaniesListSubpage'))
const CompanyDetails = lazyWithCatch(() => import('@/pages/MainPage/AdminPages/CompaniesPage/CompanyDetailsSubpage'))
const AdminAssetsPage = lazyWithCatch(() => import('@/pages/MainPage/UserPages/AssetsPage'))
const CsvBuilderFilesSubPage = lazyWithCatch(() => import('@/pages/MainPage/CommonPages/CsvBuilderPage/CsvBuilderFilesSubPage'))
const CsvBuilderPage = lazyWithCatch(() => import('@/pages/MainPage/CommonPages/CsvBuilderPage'))
const CsvBuilderRentRollSubpage = lazyWithCatch(() => import('@/pages/MainPage/CommonPages/CsvBuilderPage/CsvBuilderRentRollSubpage'))
const CsvBuilderTrialBalanceSubpage = lazyWithCatch(() => import('@/pages/MainPage/CommonPages/CsvBuilderPage/CsvBuilderTrialBalanceSubpage'))
const UploadsPage = lazyWithCatch(() => import('@/pages/UploadsPage'))
const UploadsDataSyncPage = lazyWithCatch(() => import('@/pages/UploadsPage/UploadsDataSyncPage'))
const UploadsSubpage = lazyWithCatch(() => import('@/pages/UploadsPage/UploadsSubpage'))
const CsvBuilderBudgetSubpage = lazyWithCatch(() => import('@/pages/MainPage/CommonPages/CsvBuilderPage/CsvBuilderBudgetSubpage'))
const CsvBuilderExportSubpage = lazyWithCatch(() => import('@/pages/MainPage/CommonPages/CsvBuilderPage/CsvBuilderExportSubpage'))
const PropertyManagerAssetsPage = lazyWithCatch(() => import('@/pages/MainPage/PropertyManagerPages/AssetsPage'))
const PropertyManagerAssetEditSideModal = lazyWithCatch(() => import('@/pages/MainPage/PropertyManagerPages/AssetsPage/AssetEditSideModal'))
const AdminSettingsPage = lazyWithCatch(() => import('@/pages/MainPage/AdminPages/SettingsPage'))
const AdminSettingsLoginPage = lazyWithCatch(() => import('@/pages/MainPage/AdminPages/SettingsPage/SettingsLoginPage'))
const NoAccessToPortalPage = lazyWithCatch(() => import('@/pages/errors/internal/NoAccessToPortalPage'))
const AccountGroupsPage = lazyWithCatch(() => import('@/pages/MainPage/AdminPages/AccountGroupsPage'))
const PackagesActualToBudgetPage = lazyWithCatch(() => import('@/pages/MainPage/CommonPages/PackagesPage/PackagesApprovalPage/PackagesFinancialPage/PackagesActualToBudgetPage'))
const PackagesPropertyComparisonPage = lazyWithCatch(() => import('@/pages/MainPage/CommonPages/PackagesPage/PackagesApprovalPage/PackagesFinancialPage/PackagesPropertyComparisonPage'))
const PackagesTrialBalancePage = lazyWithCatch(() => import('@/pages/MainPage/CommonPages/PackagesPage/PackagesApprovalPage/PackagesFinancialPage/PackagesTrialBalancePage'))
const MetricBuilderPage = lazyWithCatch(() => import('@/pages/MainPage/AdminPages/MetricBuilderPage'))
const MetricBuilderEditPage = lazyWithCatch(() => import('@/pages/MainPage/AdminPages/MetricBuilderPage/MetricBuilderEditPage'))
const TablesPage = lazyWithCatch(() => import('@/pages/TablesPage'))
const TableListPage = lazyWithCatch(() => import('@/pages/TablesPage/TableListPage'))
const TableBuilderPage = lazyWithCatch(() => import('@/pages/TablesPage/TableBuilderPage'))
const PivotTBPage = lazyWithCatch(() => import('@/pages/TablesPage/TemporaryPages/PivotTBPage'))
const PivotRRPage = lazyWithCatch(() => import('@/pages/TablesPage/TemporaryPages/PivotRRPage'))
const AdminEmailTrackerPage = lazyWithCatch(() => import('@/pages/MainPage/AdminPages/EmailTracker'))

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

mixpanel.init(config.mixpanelKey, {
    debug: true,
    persistence: 'localStorage',
    record_sessions_percent: 100,
    record_mask_text_selector: '',
    record_mask_text_class: '',
    record_inline_images: true,
})

const App: FC = observer(() => {
    const { me } = useMe()

    useSetPageTitlteTag()

    return (
        <div className='app'>
            <SentryRoutes>
                <Route
                    path='excel/*'
                    element={(
                        <Suspense fallback={null}>
                            <ExcelPage/>
                        </Suspense>
                    )}
                />
                <Route path='/' element={<MainPage/>}>
                    {getRoutes(me)}
                </Route>
            </SentryRoutes>
            <ToastContainer
                position='bottom-right'
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                limit={3}
            />
            <div id={CENTER_MODAL_CONTAINER}/>
        </div>
    )
})

/**
 * Routes config for ADMIN UI only
 */
const getAdminRoutes = () => {
    return (
        <Fragment>
            <Route path={ADMIN_ROUTES_CONFIG.COMPANIES.path} element={<CompaniesPage/>}>
                <Route path={ADMIN_ROUTES_CONFIG.COMPANIES.path} element={<CompaniesList/>}/>
                <Route path={ADMIN_ROUTES_CONFIG.COMPANY_USERS.path} element={<CompanyUsers/>}/>
                <Route path={ADMIN_ROUTES_CONFIG.COMPANY_USER.path} element={<UserCompanyUserPage/>}/>
                <Route path={ADMIN_ROUTES_CONFIG.COMPANY_DETAILS.path} element={<CompanyDetails/>}/>
                <Route path={ADMIN_ROUTES_CONFIG.COMPANY_USER_INVITES.path} element={<CompanyInvitesPage/>}>
                    <Route path={ADMIN_ROUTES_CONFIG.COMPANY_USER_INVITES_NEW.path} element={<CompanyInvitesPopup/>}/>
                </Route>
            </Route>

            <Route path={ADMIN_ROUTES_CONFIG.ASSETS.path} element={<ActualAdminAssetsPage/>}>
                <Route path={ADMIN_ROUTES_CONFIG.ASSETS_NEW.path} element={<AssetEditSideModal/>}/>
                <Route path={ADMIN_ROUTES_CONFIG.ASSETS_EDIT.path} element={<AssetEditSideModal/>}>
                    <Route path={ADMIN_ROUTES_CONFIG.ASSETS_DELETE.path} element={<AssetDeletePopup/>}/>
                </Route>
            </Route>

            <Route path={ADMIN_ROUTES_CONFIG.VALIDATION_TRACKER.path} element={<ValidationTrackerPage/>}>
                <Route path={ADMIN_ROUTES_CONFIG.VALIDATION_TRACKER_MONTHLY_RENT_ROLL.path} element={<ValidationTrackerMonthlyRentRollSideModal/>}/>
                <Route path={ADMIN_ROUTES_CONFIG.VALIDATION_TRACKER_ERRORS.path} element={<ValidationTrackerDataPointSideModal/>}/>
            </Route>

            <Route path={ADMIN_ROUTES_CONFIG.DATA_SUBMISSION_UPLOADS.path} element={<DataSubmissionUploadsPage/>}>
                <Route path={ADMIN_ROUTES_CONFIG.DATA_SUBMISSION_ERRORS.path} element={<FileErrorsSideModal closePathKey='DATA_SUBMISSION_UPLOADS'/>}/>
            </Route>

            <Route path={ADMIN_ROUTES_CONFIG.CSV_BUILDER.path} element={<CsvBuilderPage/>}>
                <Route path={ADMIN_ROUTES_CONFIG.CSV_BUILDER.path} element={<Navigate to={ADMIN_ROUTES_CONFIG.CSV_BUILDER_FILES.path} replace/>}/>

                <Route path={ADMIN_ROUTES_CONFIG.CSV_BUILDER_FILES.path} element={<CsvBuilderFilesSubPage/>}>
                    <Route path={ADMIN_ROUTES_CONFIG.CSV_BUILDER_FILES_DELETE.path} element={<RemoveFilePopup/>}/>
                    <Route path={ADMIN_ROUTES_CONFIG.CSV_BUILDER_FILES_EDIT.path} element={<FileRenamePopup/>}/>
                </Route>
                <Route path={ADMIN_ROUTES_CONFIG.CSV_BUILDER_TRIAL_BALANCE.path} element={<CsvBuilderTrialBalanceSubpage/>}/>
                <Route path={ADMIN_ROUTES_CONFIG.CSV_BUILDER_BUDGET.path} element={<CsvBuilderBudgetSubpage/>}/>
                <Route path={OWNER_ROUTES_CONFIG.CSV_BUILDER_EXPORT.path} element={<CsvBuilderExportSubpage/>}/>
                <Route path={ADMIN_ROUTES_CONFIG.CSV_BUILDER_RENT_ROLL.path} element={<CsvBuilderRentRollSubpage/>}/>
            </Route>

            <Route path={ADMIN_ROUTES_CONFIG.SETTINGS.path} element={<AdminSettingsPage/>}>
                <Route path={ADMIN_ROUTES_CONFIG.SETTINGS_AUTH.path} element={<AdminSettingsLoginPage/>}/>
            </Route>

            <Route path={ADMIN_ROUTES_CONFIG.METRIC_BUILDER_EDIT.path} element={<MetricBuilderEditPage/>}/>
            <Route path={ADMIN_ROUTES_CONFIG.METRIC_BUILDER.path} element={<MetricBuilderPage/>}/>

            <Route path={ADMIN_ROUTES_CONFIG.TABLES.path} element={<TablesPage/>}>
                <Route path={ADMIN_ROUTES_CONFIG.TABLES_PIVOT_TB.path} element={<PivotTBPage/>}/>
                <Route path={ADMIN_ROUTES_CONFIG.TABLES_PIVOT_RR.path} element={<PivotRRPage/>}/>
                <Route path={ADMIN_ROUTES_CONFIG.TABLES_LIST.path} element={<TableListPage builderRouteConfigKey='TABLE_BUILDER' tablesListRouteConfigKey='TABLES_LIST'/>}/>
                <Route
                    path={ADMIN_ROUTES_CONFIG.TABLE_BUILDER.path}
                    element={(
                        <TableBuilderPage
                            builderRouteConfigKey='TABLE_BUILDER'
                            tablesListRouteConfigKey='TABLES_LIST'
                        />
                    )}
                />
            </Route>

            <Route path={ADMIN_ROUTES_CONFIG.AI_CHAT.path} element={<AiChatPage/>}/>

            <Route path={ADMIN_ROUTES_CONFIG.ACCOUNTS_GROUPS.path} element={<AccountGroupsPage/>}/>

            <Route path={ADMIN_ROUTES_CONFIG.EMAIL_TRACKER.path} element={<AdminEmailTrackerPage/>}/>
        </Fragment>
    )
}

/**
 * Routes config for USER UI only
 */
const getOwnerRoutes = () => {
    return (
        <Fragment>
            <Route
                path={OWNER_ROUTES_CONFIG.ADMIN_CENTER_ASSETS.path}
                element={<AdminAssetsPage/>}
            >
                <Route path={OWNER_ROUTES_CONFIG.ADMIN_CENTER_ASSETS_EDIT.path} element={<AssetEditSideModal/>}/>
                <Route path={OWNER_ROUTES_CONFIG.ADMIN_CENTER_ASSETS_DELETE.path} element={<AssetDeletePopup/>}/>
            </Route>
            <Route path={OWNER_ROUTES_CONFIG.LEDGERS.path} element={<LedgersPage/>}>
                <Route
                    path={OWNER_ROUTES_CONFIG.LEDGERS_TRIAL_BALANCE.path}
                    element={<LedgerTrialBalancePage/>}
                >
                    <Route path=':accountId' element={<LedgerTrialBalanceDetailsPage/>}/>
                    <Route path='' element={<LedgerTrialBalanceDetailsPage/>}/>
                </Route>
                <Route
                    path={OWNER_ROUTES_CONFIG.LEDGERS_RENT_ROLL.path}
                    element={<LedgerRentRollPage/>}
                >
                    <Route path=':accountId' element={<LedgerRentRollDetailsPage/>}/>
                    <Route path='' element={<LedgerRentRollDetailsPage/>}/>
                </Route>
            </Route>
            <Route path={OWNER_ROUTES_CONFIG.ASSET_MAPPING.path} element={<MappingPage/>}>
                <Route
                    path={OWNER_ROUTES_CONFIG.ASSET_MAPPING_TEMPLATES.path}
                    element={<MappingTemplatesPage/>}
                >
                    <Route
                        path={OWNER_ROUTES_CONFIG.ASSET_MAPPING_TEMPLATES_CREATE.path}
                        element={<MappingTemplateCreatePage/>}
                    />
                </Route>
                <Route
                    path={OWNER_ROUTES_CONFIG.ASSET_MAPPING_TEMPLATES_BY_ID.path}
                    element={<MappingTemplateEditPage/>}
                >
                    <Route
                        path={OWNER_ROUTES_CONFIG.ASSET_MAPPING_TEMPLATES_BY_ID_ADD_MAPPING.path}
                        element={<MappingTemplateAddMappingPage/>}
                    />
                    <Route
                        path={OWNER_ROUTES_CONFIG.ASSET_MAPPING_TEMPLATES_BY_ID_DELETE_MAPPING.path}
                        element={<MappingTemplateDeleteMappingPage/>}
                    />
                    <Route
                        path={OWNER_ROUTES_CONFIG.ASSET_MAPPING_TEMPLATES_BY_ID_UPLOAD.path}
                        element={<MappingTemplateUploadMappingPage/>}
                    />
                    <Route element={<MappingTemplateApplyModal/>} path={OWNER_ROUTES_CONFIG.ASSET_MAPPING_TEMPLATES_APPLY.path}/>
                </Route>
                <Route
                    path={OWNER_ROUTES_CONFIG.ASSET_MAPPING_TRIAL_BALANCE.path}
                    element={<MappingTrialBalancePage/>}
                />
                <Route
                    path={OWNER_ROUTES_CONFIG.ASSET_MAPPING_RENT_ROLL.path}
                    element={<MappingRentRollPage/>}
                />
                <Route
                    path={OWNER_ROUTES_CONFIG.ASSET_MAPPING_HISTORY.path}
                    element={<MappingHistoryPage/>}
                />
            </Route>

            <Route path={OWNER_ROUTES_CONFIG.USER_PROFILE.path} element={<UserProfilePage/>}/>
            <Route path={OWNER_ROUTES_CONFIG.COMPANY_PROFILE.path} element={<CompanyProfilePage/>}>
                <Route path={OWNER_ROUTES_CONFIG.COMPANY_PROFILE.path} element={<Navigate to={OWNER_ROUTES_CONFIG.COMPANY_PROFILE_DETAILS.path} replace/>}/>
                <Route path={OWNER_ROUTES_CONFIG.COMPANY_PROFILE_MEMBERS.path} element={<CompanyProfileMembers/>}/>
                <Route path={OWNER_ROUTES_CONFIG.COMPANY_PROFILE_MEMBERS_BY_ID.path} element={<CompanyUserPage/>}/>
                <Route path={OWNER_ROUTES_CONFIG.COMPANY_PROFILE_DETAILS.path} element={<CompanyProfileDetails/>}/>
                <Route path={OWNER_ROUTES_CONFIG.COMPANY_PROFILE_INVITES.path} element={<CompanyProfileInvites/>}>
                    <Route path={OWNER_ROUTES_CONFIG.COMPANY_PROFILE_INVITES_NEW.path} element={<CompanyProfileNewInvitePopup backPath={OWNER_ROUTES_CONFIG.COMPANY_PROFILE_INVITES.path}/>}/>
                </Route>
            </Route>

            <Route path={OWNER_ROUTES_CONFIG.CSV_BUILDER.path} element={<CsvBuilderPage/>}>
                <Route path={OWNER_ROUTES_CONFIG.CSV_BUILDER.path} element={<Navigate to={OWNER_ROUTES_CONFIG.CSV_BUILDER_FILES.path} replace/>}/>

                <Route path={OWNER_ROUTES_CONFIG.CSV_BUILDER_FILES.path} element={<CsvBuilderFilesSubPage/>}>
                    <Route path={OWNER_ROUTES_CONFIG.CSV_BUILDER_FILES_DELETE.path} element={<RemoveFilePopup/>}/>
                    <Route path={OWNER_ROUTES_CONFIG.CSV_BUILDER_FILES_EDIT.path} element={<FileRenamePopup/>}/>
                </Route>
                <Route path={OWNER_ROUTES_CONFIG.CSV_BUILDER_TRIAL_BALANCE.path} element={<CsvBuilderTrialBalanceSubpage/>}/>
                <Route path={OWNER_ROUTES_CONFIG.CSV_BUILDER_BUDGET.path} element={<CsvBuilderBudgetSubpage/>}/>
                <Route path={OWNER_ROUTES_CONFIG.CSV_BUILDER_EXPORT.path} element={<CsvBuilderExportSubpage/>}/>
                <Route path={OWNER_ROUTES_CONFIG.CSV_BUILDER_RENT_ROLL.path} element={<CsvBuilderRentRollSubpage/>}/>
            </Route>

            <Route path={OWNER_ROUTES_CONFIG.PACKAGES.path} element={<PackagesPage/>}>
                <Route path={OWNER_ROUTES_CONFIG.PACKAGES_APPROVAL.path} element={<PackagesApprovalPage/>}>
                    <Route path={OWNER_ROUTES_CONFIG.PACKAGES_APPROVAL_FINANCIAL.path} element={<PackagesFinancialPage/>}>
                        <Route
                            path={OWNER_ROUTES_CONFIG.PACKAGES_APPROVAL_FINANCIAL_ACTUAL_BUDGET.path}
                            element={<PackagesActualToBudgetPage/>}
                        />
                        <Route
                            path={OWNER_ROUTES_CONFIG.PACKAGES_APPROVAL_FINANCIAL_PROPERTY_COMPARISON.path}
                            element={<PackagesPropertyComparisonPage/>}
                        />
                        <Route
                            path={OWNER_ROUTES_CONFIG.PACKAGES_APPROVAL_FINANCIAL_TRIAL_BALANCE.path}
                            element={<PackagesTrialBalancePage/>}
                        />
                    </Route>
                    <Route path={OWNER_ROUTES_CONFIG.PACKAGES_APPROVAL_MAPPINGS.path} element={<PackagesMappingsPage/>}/>
                    <Route path={OWNER_ROUTES_CONFIG.PACKAGES_APPROVAL_OVERVIEW.path} element={<PackagesOverviewPage/>}/>
                </Route>
                <Route path={OWNER_ROUTES_CONFIG.PACKAGES_DASHBOARD.path} element={<PackagesDashboardPage/>}/>

                <Route path={OWNER_ROUTES_CONFIG.DATA_SUBMISSION.path} element={<DataSubmissionPage/>}>
                    <Route
                        path={OWNER_ROUTES_CONFIG.VALIDATION_TRACKER.path}
                        element={<ValidationTrackerPage/>}
                    >
                        <Route path={OWNER_ROUTES_CONFIG.VALIDATION_TRACKER_MONTHLY_RENT_ROLL.path} element={<ValidationTrackerMonthlyRentRollSideModal/>}/>
                        <Route path={OWNER_ROUTES_CONFIG.VALIDATION_TRACKER_ERRORS.path} element={<ValidationTrackerDataPointSideModal/>}/>
                    </Route>
                </Route>

                <Route path={OWNER_ROUTES_CONFIG.UPLOADS.path} element={<UploadsPage/>}>
                    <Route path={OWNER_ROUTES_CONFIG.UPLOADS_UPLOADS.path} element={<UploadsSubpage/>}/>
                    <Route path={OWNER_ROUTES_CONFIG.UPLOADS_LOGS.path} element={<SubmissionLogsPage/>}>
                        <Route path={OWNER_ROUTES_CONFIG.UPLOADS_LOGS_ERRORS_BY_ID.path} element={<FileErrorsDataPointSideModal closePathKey='UPLOADS_LOGS'/>}/>
                    </Route>
                </Route>

                <Route path={OWNER_ROUTES_CONFIG.PPA.path} element={<PPAPage/>}>
                    <Route path={OWNER_ROUTES_CONFIG.PPA_ADJUSTMENT.path} element={<PriorPeriodAdjustmentPage/>}/>
                    <Route path={OWNER_ROUTES_CONFIG.PPA_TRIAL_BALANCE.path} element={<PriorPeriodTBPage/>}/>
                </Route>
            </Route>

            <Route path={OWNER_ROUTES_CONFIG.REPORTS.path} element={<ReportsPage/>}>
                <Route path={OWNER_ROUTES_CONFIG.REPORTS_FINANCIALS.path} element={<ReportsFinancialsPage/>}>
                    <Route path={OWNER_ROUTES_CONFIG.REPORTS_FINANCIALS_PORTFOLIO.path} element={<ReportsPortfolioPage/>}/>
                    <Route path={OWNER_ROUTES_CONFIG.REPORTS_FINANCIALS_INCOME_STATEMENT.path} element={<ReportsIncomeStatementPage/>}/>
                    <Route path={OWNER_ROUTES_CONFIG.REPORTS_FINANCIALS_BALANCE_SHEET.path} element={<ReportsBalanceSheetPage/>}/>
                    <Route
                        path={OWNER_ROUTES_CONFIG.REPORTS_FINANCIALS_ACTUAL_BUDGET.path}
                        element={<ReportsActualBudgetPage/>}
                    />
                    <Route
                        path={OWNER_ROUTES_CONFIG.REPORTS_FINANCIALS_PROPERTY_COMPARISON.path}
                        element={<ReportsPropertyComparisonPage/>}
                    />
                    <Route
                        path={OWNER_ROUTES_CONFIG.REPORTS_FINANCIALS_TRIAL_BALANCE.path}
                        element={<ReportsTrialBalancePage/>}
                    />
                    <Route
                        path={OWNER_ROUTES_CONFIG.REPORTS_FINANCIALS_TB_SUMMARY.path}
                        element={<ReportsTbSummaryPage/>}
                    />
                </Route>
                <Route path={OWNER_ROUTES_CONFIG.REPORTS_RENT_ROLL.path} element={<ReportsRentRollTabPage/>}>
                    <Route path={OWNER_ROUTES_CONFIG.REPORTS_RENT_ROLL_RENT_ROLL.path} element={<ReportsRentRollPage/>}/>
                    <Route path={OWNER_ROUTES_CONFIG.REPORTS_RENT_ROLL_OCCUPANCY.path} element={<ReportsOccupancyPage/>}/>
                    <Route path={OWNER_ROUTES_CONFIG.REPORTS_RENT_ROLL_LEASE_EXPIRATIONS.path} element={<ReportsLeaseExpirationsPage/>}/>
                    <Route path={OWNER_ROUTES_CONFIG.REPORTS_RENT_ROLL_TOP_TENATS.path} element={<ReportsRentRollTopTenantsPage/>}/>
                </Route>

                <Route path={OWNER_ROUTES_CONFIG.REPORTS_CUSTOM_COMPANY.path} element={<ReportsHome context='company'/>}/>
                <Route path={OWNER_ROUTES_CONFIG.REPORTS_CUSTOM_COMPANY_BY_ID.path} element={<CustomReportPage reportType='company'/>}/>
                <Route path={OWNER_ROUTES_CONFIG.REPORTS_CUSTOM_COMPANY_TABLE_BUILDER.path} element={<TableBuilderPage builderRouteConfigKey='REPORTS_CUSTOM_COMPANY_TABLE_BUILDER' tablesListRouteConfigKey='REPORTS_CUSTOM_COMPANY_TABLES'/>}/>
                <Route path={OWNER_ROUTES_CONFIG.REPORTS_CUSTOM_COMPANY_TABLES.path} element={<TableListPage builderRouteConfigKey='REPORTS_CUSTOM_COMPANY_TABLE_BUILDER' tablesListRouteConfigKey='REPORTS_CUSTOM_COMPANY_TABLES'/>}/>

                <Route path={OWNER_ROUTES_CONFIG.REPORTS_CUSTOM_MY.path} element={<ReportsHome context='my'/>}/>
                <Route path={OWNER_ROUTES_CONFIG.REPORTS_CUSTOM_MY_TABLE_BUILDER.path} element={<TableBuilderPage builderRouteConfigKey='REPORTS_CUSTOM_MY_TABLE_BUILDER' tablesListRouteConfigKey='REPORTS_CUSTOM_MY_TABLES'/>}/>
                <Route path={OWNER_ROUTES_CONFIG.REPORTS_CUSTOM_MY_TABLES.path} element={<TableListPage builderRouteConfigKey='REPORTS_CUSTOM_MY_TABLE_BUILDER' tablesListRouteConfigKey='REPORTS_CUSTOM_MY_TABLES'/>}/>
                <Route path={OWNER_ROUTES_CONFIG.REPORTS_CUSTOM_MY_BY_ID.path} element={<CustomReportPage reportType='my'/>}/>
            </Route>

            <Route path={OWNER_ROUTES_CONFIG.TABLES.path} element={<TablesPage/>}>
                <Route path={ADMIN_ROUTES_CONFIG.TABLES_LIST.path} element={<TableListPage builderRouteConfigKey='TABLE_BUILDER' tablesListRouteConfigKey='TABLES_LIST'/>}/>
                <Route path={ADMIN_ROUTES_CONFIG.TABLE_BUILDER.path} element={<TableBuilderPage builderRouteConfigKey='TABLE_BUILDER' tablesListRouteConfigKey='TABLES_LIST'/>}/>
            </Route>
        </Fragment>
    )
}

/**
 * Routes config for MANAGER PORTAL USER UI only
 */
const getManagerRoutes = () => {
    return (
        <Fragment>
            <Route path={MANAGER_ROUTES_CONFIG.ASSETS.path} element={<PropertyManagerAssetsPage/>}>
                <Route path={MANAGER_ROUTES_CONFIG.ASSETS_EDIT.path} element={<PropertyManagerAssetEditSideModal/>}/>
                <Route path={MANAGER_ROUTES_CONFIG.ASSETS_DELETE.path} element={<AssetDeletePopup/>}/>
            </Route>
            <Route path={MANAGER_ROUTES_CONFIG.USER_PROFILE.path} element={<UserProfilePage/>}/>
            <Route path={MANAGER_ROUTES_CONFIG.COMPANY_PROFILE.path} element={<CompanyProfilePage/>}>
                <Route path={MANAGER_ROUTES_CONFIG.COMPANY_PROFILE.path} element={<Navigate to={MANAGER_ROUTES_CONFIG.COMPANY_PROFILE_DETAILS.path} replace/>}/>
                <Route path={MANAGER_ROUTES_CONFIG.COMPANY_PROFILE_MEMBERS.path} element={<CompanyProfileMembers/>}/>
                <Route path={MANAGER_ROUTES_CONFIG.COMPANY_PROFILE_MEMBERS_BY_ID.path} element={<CompanyUserPage/>}/>
                <Route path={MANAGER_ROUTES_CONFIG.COMPANY_PROFILE_DETAILS.path} element={<CompanyProfileDetails/>}/>
                <Route path={MANAGER_ROUTES_CONFIG.COMPANY_PROFILE_INVITES.path} element={<CompanyProfileInvites/>}>
                    <Route path={MANAGER_ROUTES_CONFIG.COMPANY_PROFILE_INVITES_NEW.path} element={<CompanyProfileNewInvitePopup backPath={MANAGER_ROUTES_CONFIG.COMPANY_PROFILE_INVITES.path}/>}/>
                </Route>
            </Route>

            <Route path={MANAGER_ROUTES_CONFIG.PACKAGES.path} element={<PackagesPage/>}>
                <Route path={MANAGER_ROUTES_CONFIG.PACKAGES_APPROVAL.path} element={<PackagesApprovalPage/>}>
                    <Route path={MANAGER_ROUTES_CONFIG.PACKAGES_APPROVAL_FINANCIAL.path} element={<PackagesFinancialPage/>}>
                        <Route
                            path={MANAGER_ROUTES_CONFIG.PACKAGES_APPROVAL_FINANCIAL_ACTUAL_BUDGET.path}
                            element={<PackagesActualToBudgetPage/>}
                        />
                        <Route
                            path={MANAGER_ROUTES_CONFIG.PACKAGES_APPROVAL_FINANCIAL_PROPERTY_COMPARISON.path}
                            element={<PackagesPropertyComparisonPage/>}
                        />
                        <Route
                            path={MANAGER_ROUTES_CONFIG.PACKAGES_APPROVAL_FINANCIAL_TRIAL_BALANCE.path}
                            element={<PackagesTrialBalancePage/>}
                        />
                    </Route>
                    <Route path={MANAGER_ROUTES_CONFIG.PACKAGES_APPROVAL_MAPPINGS.path} element={<PackagesMappingsPage/>}/>
                    <Route path={MANAGER_ROUTES_CONFIG.PACKAGES_APPROVAL_OVERVIEW.path} element={<PackagesOverviewPage/>}/>
                </Route>
                <Route path={MANAGER_ROUTES_CONFIG.PACKAGES_DASHBOARD.path} element={<PackagesDashboardPage/>}/>

                <Route path={MANAGER_ROUTES_CONFIG.DATA_SUBMISSION.path} element={<DataSubmissionPage/>}>
                    <Route
                        path={MANAGER_ROUTES_CONFIG.VALIDATION_TRACKER.path}
                        element={<ValidationTrackerPage/>}
                    >
                        <Route path={MANAGER_ROUTES_CONFIG.VALIDATION_TRACKER_MONTHLY_RENT_ROLL.path} element={<ValidationTrackerMonthlyRentRollSideModal/>}/>
                        <Route path={MANAGER_ROUTES_CONFIG.VALIDATION_TRACKER_ERRORS.path} element={<ValidationTrackerDataPointSideModal/>}/>
                    </Route>
                </Route>

                <Route path={MANAGER_ROUTES_CONFIG.UPLOADS.path} element={<UploadsPage/>}>
                    <Route path={MANAGER_ROUTES_CONFIG.UPLOADS_UPLOADS.path} element={<UploadsSubpage/>}/>
                    <Route path={MANAGER_ROUTES_CONFIG.UPLOADS_LOGS.path} element={<SubmissionLogsPage/>}>
                        <Route path={MANAGER_ROUTES_CONFIG.UPLOADS_LOGS_ERRORS_BY_ID.path} element={<FileErrorsDataPointSideModal closePathKey='UPLOADS_LOGS'/>}/>
                    </Route>
                </Route>

                <Route path={OWNER_ROUTES_CONFIG.PPA.path} element={<PPAPage/>}>
                    <Route path={OWNER_ROUTES_CONFIG.PPA_ADJUSTMENT.path} element={<PriorPeriodAdjustmentPage/>}/>
                    <Route path={OWNER_ROUTES_CONFIG.PPA_TRIAL_BALANCE.path} element={<PriorPeriodTBPage/>}/>
                </Route>
            </Route>

            <Route path={MANAGER_ROUTES_CONFIG.UPLOADS_SYNC.path} element={<UploadsDataSyncPage/>}/>
        </Fragment>
    )
}

/**
 * Routes config for any user
 */
const getCommonRoutes = () => {
    return (
        <Fragment>
            <Route path={COMMON_ROUTES_CONFIG.NO_ACCESS_TO_PORTAL.path} element={<NoAccessToPortalPage/>}/>
            <Route path='*' element={<InternalNotFoundPage rounded/>}/>
        </Fragment>
    )
}

const getRoutes = (me: Me) => {
    const commonRoutes = getCommonRoutes()
    let userModeSpecificRoutes: JSX.Element | null = null

    if (me.companyUserMode === CompanyUserMode.Owner) {
        userModeSpecificRoutes = getOwnerRoutes()
    }
    if (me.companyUserMode === CompanyUserMode.Admin) {
        userModeSpecificRoutes = getAdminRoutes()
    }
    if (me.companyUserMode === CompanyUserMode.Manager) {
        userModeSpecificRoutes = getManagerRoutes()
    }

    return (
        <Fragment>
            {commonRoutes}
            {userModeSpecificRoutes}
        </Fragment>
    )
}

export default Sentry.withProfiler(App)
