import { MutableRefObject } from 'react'

import { ExcelRow, IRowNode, ProcessCellForExportParams } from '@ag-grid-community/core'
import { Comment, CommentAnnotation } from '@veltdev/types'

import { AgGridReact } from '@ag-grid-community/react'

import { PropertyComparisonReportItem } from '@/api/report/propertyComparison'
import { VarianceReportItem } from '@/api/report/variance'
import { Asset } from '@/models/asset'
import { monthIndToString } from '@/utils/date/monthInd'

type ReportItem = PropertyComparisonReportItem | VarianceReportItem

export const getAccountRowId = (accountId: number | undefined) => `account-${accountId}`

export const getRowId = ({ data }: { data: ReportItem }) =>
    data.type === 'account' ? getAccountRowId(data.entity_id) : JSON.stringify(data)

export const showAccountRow = (tableRef: MutableRefObject<AgGridReact | null>, accountId: number | null | undefined) => {
    if (!accountId) { return }
    const rowId = getAccountRowId(accountId)
    const rowNode = tableRef?.current?.api?.getRowNode(rowId)
    if (!rowNode) { return }
    expandAllParents(rowNode)
    tableRef?.current?.api?.ensureNodeVisible(rowNode, 'middle')
    rowNode.setSelected(true)
}

export const expandAllParents = (node: IRowNode) => {
    if (!node) { return }
    const parentNode = node.parent
    if (parentNode) {
        parentNode.setExpanded(true)
        expandAllParents(parentNode)
    }
}

/**
 * Replace NaN with undefined
 * @param value NaN | number
 */
export const getNumberOrUndefined = (value: typeof NaN | number): undefined | number => {
    return isNaN(value) ? undefined : removeMinusZero(value)
}

const removeMinusZero = (value: number): number => {
    const cleanedValue = Math.round(value * 100) / 100
    return Object.is(cleanedValue, -0) ? 0 : cleanedValue
}

/**
 * @param asset
 * @param startMonthIndex
 * @param endMonthIndex
 * @returns export meta: Asset name and time period rows in Excel cell format
 */
export const getAssetPeriodExportMeta = (
    asset: Asset | undefined,
    startMonthIndex: number | null | undefined,
    endMonthIndex?: number | null,
): ExcelRow[] => {
    return [
        { cells: [] },
        {
            cells: [
                {
                    data: {
                        value: `Asset: ${asset?.name}`,
                        type: 'String',
                    },
                },
            ],
        },
        {
            cells: [
                {
                    data: {
                        value: `Time period: ${startMonthIndex ? monthIndToString(startMonthIndex) : ''} ${endMonthIndex ? ' – ' : ''} ${endMonthIndex ? monthIndToString(endMonthIndex) : ''}`,
                        type: 'String',
                    },
                },
            ],
        },
        { cells: [] },
    ]
}

export const processTotalCells = (params: ProcessCellForExportParams): string => {
    const { node, column } = params
    const { type } = node?.data ?? {}

    // add 'Total' prefix to footer cells
    if (node?.footer && column.getColId() === 'ag-Grid-AutoColumn') {
        return `Total ${params.value}`
    }

    // remove values from group rows except for footer cells
    if (!node?.footer && column.getColId() !== 'ag-Grid-AutoColumn' && ['calculation_with_childs', 'category'].includes(type)) {
        return ''
    }

    return params.value
}

export const getSheetName = (startMonthIndex?: number | null, endMonthIndex?: number | null) => `${startMonthIndex ? monthIndToString(startMonthIndex) : ''} - ${endMonthIndex ? monthIndToString(endMonthIndex) : ''}`

const getFormattedComment = (comment: Comment) =>
    `${comment.from.name ?? comment.from.email}:\n${comment.commentText}`

export const getNoteTextFromAnnotations = (annotations: CommentAnnotation[]) => {
    let noteText = ''
    if (annotations) {
        annotations.forEach((annotation) => {
            annotation.comments?.forEach((comment, index, comments) => {
                noteText += getFormattedComment(comment) + (index === comments.length - 1 ? '' : '\n\n')
            })
        })
    }
    return noteText
}

export const getOtherNotes = (annotations: CommentAnnotation[]): ExcelRow[] => {
    if (!annotations.length) { return [] }

    const otherNotes: ExcelRow[] = [
        { cells: [] },
        {
            cells: [
                {
                    data: {
                        value: 'Other notes',
                        type: 'String',
                    },
                },
            ],
        },
    ]

    annotations.forEach((annotation) => {
        annotation.comments?.forEach((comment) => {
            otherNotes.push({
                cells: [
                    {
                        data: {
                            value: getFormattedComment(comment),
                            type: 'String',
                        },
                    },
                ],
            })
        })
    })
    return otherNotes
}
