import { useEffect, useState } from 'react'

// eslint-disable-next-line no-restricted-imports
import { runInAction } from 'mobx'
import { ASC, Query, Model } from 'mobx-orm'

// eslint-disable-next-line no-restricted-imports
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Popover from '@mui/material/Popover'
import { observer } from 'mobx-react-lite'

import { Icon, Text } from '@/components/base'

import './multi-select-input.scss'

interface MultiSelectPopoverProps<T extends Model> {
    init?: any[]
    auto_apply?: boolean
    options: Query<T>
    onChange: (value: any[]) => void
    onClose: () => void
    label: (obj: T) => string
    sorted_by: string
    anchor: any
}

/**
* @deprecated
*/
const MultiSelectPopover = observer(<T extends Model>({ init, auto_apply, options, onChange, onClose, label, sorted_by, anchor }: MultiSelectPopoverProps<T>) => {
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    const [selected, setSelected] = useState<any[]>(init.map(x => x))
    const [filter_value, setFilterValue] = useState<string>('') // eslint-disable-line @typescript-eslint/naming-convention
    const filter_regex = new RegExp(filter_value, 'i') // eslint-disable-line @typescript-eslint/naming-convention

    const handleSelectAll = () => {
        const result = []
        for (const item of options.items) {
            if (label(item).search(filter_regex) !== -1) {
                // @ts-expect-error TS(2345) FIXME: Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
                result.push(item.id)
            }
        }
        setSelected([...result])
    }

    // if we change the options then we have to reset the selected
    useEffect(() => {
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        setSelected(init.map(x => x))
        return () => {
            setSelected([])
        }
    }, [init])

    const choices_jsx = options.items.map(item => { // eslint-disable-line @typescript-eslint/naming-convention
        if (filter_value && label(item).search(filter_regex) === -1) { return null }
        return (
            <div className='choice' key={item.id}>
                {/* FIXME: Remove MUI control and Checkbox */}
                <FormControlLabel
                    control={(
                        <Checkbox
                        // REFACTOR: Use classes props instead. Example in ObjectFormPopup.tsx
                            sx={{
                                color: '#5C5CE5',
                                '&.Mui-checked': { color: '#5C5CE5' },
                            }}
                            checked={selected.includes(item.id)}
                            onClick={() => {
                                const index = selected.indexOf(item.id)
                                index === -1 ? selected.push(item.id) : selected.splice(index, 1)
                                setSelected([...selected]) // react does not detect changes inside array => create a copy
                                if (auto_apply) {
                                    onChange(selected)
                                }
                            }}
                        />
                    )}
                    label={(
                        <Text
                            block
                            variant='smallTextMediumDefault'
                            color='secondaryBlack'
                            textOverflow='ellipsis'
                            elementTitle={label(item)}
                        >
                            {label(item)}
                        </Text>
                    )}
                />
            </div>
        )
    }).filter((value) => value !== null) // remove all null, we need to use choices_jsx.lenght to detect empty list

    return (
        <Popover
            open={!!anchor}
            anchorEl={anchor}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            style={{ marginTop: 4 }}
            onClose={() => {
                // setSelected([])
                setTimeout(() => { setFilterValue('') }, 1000)
                onClose()
            }}
        >
            <div className='multiselect-input-choices'>
                <div className='input-pane'>
                    <Icon
                        name='search'
                        style={{
                            width: 20,
                            height: 20,
                        }}
                    />
                    <input
                        placeholder='Search'
                        onChange={event => { setFilterValue(event.target.value) }}
                        value={filter_value}
                    />
                </div>
                <div className='buttons-pane'>
                    {!auto_apply && (
                        <div
                            className='btn'
                            onClick={() => {
                                onChange(selected)
                                onClose()
                            }}
                        >Ok</div>
                    )}
                    <div className='btn' onClick={handleSelectAll}>Select All</div>
                    <div
                        className='btn'
                        onClick={() => {
                            setSelected([])
                            if (auto_apply) {
                                onChange([])
                            }
                        }}
                    >Clear</div>
                    <div className='btn' onClick={() => runInAction(() => options.order_by.set(sorted_by, !options.order_by.get(sorted_by)))}>
                    Sort {options?.order_by?.get(sorted_by) === ASC ? ' A - Z' : ' Z - A'}
                    </div>
                </div>
                <div className='choices-pane'>
                    {choices_jsx.length !== 0 && choices_jsx}
                    {choices_jsx.length === 0 && <div className='no-items'>No items to show.</div>}
                </div>
            </div>
        </Popover>
    )
})

export default MultiSelectPopover
