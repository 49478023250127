import { useEffect, useState } from 'react'

import { Select, SelectOptionDefault } from '@/components/base'
import { useInputState } from '@/hooks'

import { SelectInputProps } from './SelectInput.types'

const idFn = (option: SelectOptionDefault) => option?.value?.toString?.()

/**
 * Simple select input works with default option type
 * {@link SelectOptionDefault}
 */
export const SelectInput = (props: SelectInputProps) => {
    const {
        testId,
        required = false,
        paramName,
        paramType,
        options,
        multiSelect,
        syncLocalStorage,
        inputParams = {},
        dataReady = true,
        ...rest
    } = props

    const [param, setParam] = useInputState({
        name: paramName,
        type: paramType,
        syncLocalStorage,
        ...inputParams,
    })
    const [selected, setSelected] = useState<SelectOptionDefault[]>([])

    useEffect(() => {
        if (!dataReady) return
        if (!options?.length) {
            setSelected([])
            return
        }

        const singleSelectSelectedIds = param ? [param] : []
        const selectedIds = (multiSelect ? param as unknown[] : singleSelectSelectedIds) || []
        const selectedOptions = options.filter((option) => selectedIds.includes(idFn(option)))

        setSelected(selectedOptions)

        // Need this to reset selected value in the case if options are changed and selected value is not in the new options
        if (selectedOptions.length < selectedIds.length) {
            setParam(multiSelect ? selectedOptions.map(option => idFn(option)) : null)
        }
    }, [param, options, setParam, dataReady])

    return (
        <Select
            dataReady={dataReady}
            testId={testId}
            idFn={idFn}
            options={options}
            selected={selected}
            onChange={(options) => {
                if (options.length) {
                    multiSelect
                        ? setParam(options.map((option) => idFn(option)))
                        : setParam(idFn(options[0]))
                } else {
                    setParam(multiSelect ? [] : undefined)
                }
            }}
            error={required && selected.length === 0}
            multiSelect={multiSelect}
            {...rest}
        />
    )
}
