import { DatePicker } from '@/components/base/DatePicker'
import { useInputState } from '@/hooks'
import { dateToQuarter, QuarterString, quarterToDate } from '@/utils/date/quarter'

import { QUARTER_INPUT_QUERY_PARAM } from './QuarterInput.constants'
import { QuarterInputProps } from './QuarterInput.types'

const { name: quaterParamName, type } = QUARTER_INPUT_QUERY_PARAM

export const QuarterInput = (props: QuarterInputProps) => {
    const {
        paramName = quaterParamName,
        defaultDate,
        syncLocalStorage,
        placeholderText = 'Select quarter',
        required = false,
        inputParams = {},
        ...rest
    } = props

    const [quarter, setQuarter] = useInputState({
        name: paramName,
        type,
        syncLocalStorage,
        ...inputParams,
    })

    if (!quarter && defaultDate) {
        setQuarter(dateToQuarter(defaultDate))
    }

    return (
        <DatePicker
            date={quarter ? quarterToDate(quarter as QuarterString) : undefined}
            onChange={date => date && setQuarter(dateToQuarter(date))}
            placeholderText={placeholderText}
            error={required && !quarter}
            type='quarter'
            icon='arrowDown'
            rotateIcon
            {...rest}
        />
    )
}
