import { MouseEvent, useEffect, useRef, useState } from 'react'

import { NumberInput, StringInput, XAND, XEQ } from 'mobx-orm'

import { AgGridReact } from '@ag-grid-community/react'
import { ListItemIcon, ListItemText, MenuItem, Menu } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { Outlet, useNavigate, useParams, generatePath } from 'react-router'

import { IconButton, Button, Text, Icon, Confirm, Alert } from '@/components/base'
import { Layout, PageLayout } from '@/components/containers'
import { StringInput2 } from '@/components/legacy/models/core/inputs/StringInput2'
import { AgGridTableLegacy } from '@/components/legacy/tables/AgGridTableLegacy'
import { CompanyUserMode } from '@/core/modes'
import { useInput } from '@/hooks/useInput'
import { useQueryX } from '@/hooks/useQueryX'
import { useQueryXPage } from '@/hooks/useQueryXPage'
import { Account as TrialBalanceAccount } from '@/models/ledger'
import { TrialBalanceTemplate, TrialBalanceTemplateMap } from '@/models/trial_balance_template'
import { getRoute } from '@/utils/routing/getRoute'
import { getRouteConfig } from '@/utils/routing/getRouteConfig'

import { MappingTemplateEditNameModal } from './MappingTemplateEditNameModal'
import { DELETE_TEMPLATE_ALERT, GRID_OPTIONS } from './MappingTemplateEditPage.constants'

import styles from './MappingTemplateEditPage.module.scss'

const templatePath = getRouteConfig('ASSET_MAPPING_TEMPLATES', CompanyUserMode.Owner).path
const addPath = getRouteConfig('ASSET_MAPPING_TEMPLATES_BY_ID_ADD_MAPPING', CompanyUserMode.Owner).path
const uploadPath = getRouteConfig('ASSET_MAPPING_TEMPLATES_BY_ID_UPLOAD', CompanyUserMode.Owner).path

export const MappingTemplateEditPage = observer(() => {
    const { templateId } = useParams()
    const navigate = useNavigate()
    const tableRef = useRef<AgGridReact>(null)

    const [isEditNameModalOpen, setIsEditNameModalOpen] = useState(false)
    const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false)
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null)

    // @ts-expect-error TS(2345) FIXME: Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
    const [template, setTemplate] = useState<TrialBalanceTemplate>(undefined)
    useEffect(() => {
        (async () => {
            const template = await TrialBalanceTemplate.findById(Number(templateId)) as TrialBalanceTemplate
            setTemplate(template)
        })()
    }, [])

    const templateIdInput = useInput(() =>
        new NumberInput({ value: Number(templateId) }))
    // TODO: StringInput - debounce by default
    const searchInput = useInput(() =>
        // TODO: without debounce seach is not working
        new StringInput({
            value: '',
            syncURL: 'search',
            debounce: 400,
        }))

    // TODO: upgrade useQueryXPage, talk to @Aleksey
    const templateMapQuery = useQueryXPage(TrialBalanceTemplateMap, {
        filter: XAND(
            XEQ('template_id', templateIdInput),
            XEQ('__search', searchInput),
        ),
        autoupdate: true,
        syncURLSearchParams: true,
    })
    // TODO: sync order_by doesn't work when refresh the page

    const accountQuery = useQueryX(TrialBalanceAccount, {
        filter: XAND(
            XEQ('ledger__map_templates__id', templateIdInput),
        ),
        autoupdate: true,
    })

    const handleOpenMenu = (e: MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorEl(e.currentTarget)
    }

    const handleCloseMenu = () => {
        setMenuAnchorEl(null)
    }

    const handleDeleteTemplate = () => {
        handleCloseMenu()
        setIsDeletePopupOpen(true)
    }

    const handleDownloadTemplate = () => {
        handleCloseMenu()
        TrialBalanceTemplate.downloadCSV({
            id: String(template.id),
            name: template.name,
        })
    }

    return (
        <PageLayout loading={template === undefined || accountQuery.isLoading}>
            <Layout justify='space-between' align='center' mb={16}>
                <IconButton icon='dialogArrowLeft' onClick={() => { navigate(templatePath) }}/>
            </Layout>
            <Layout mb={16} gap={16}>
                <div className={styles.titleBlock}>
                    <Text text={`Template: ${template?.name}`} variant='header'/>
                    <IconButton icon='pencil' onClick={() => setIsEditNameModalOpen(true)}/>
                </div>
                <Button
                    size='l'
                    text='Select Assets & Apply Template'
                    // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
                    onClick={() => navigate(getRoute('ASSET_MAPPING_TEMPLATES_APPLY', { templateId }))}
                />
                <Button
                    icon='moreVertical'
                    size='l'
                    theme='secondary'
                    onClick={handleOpenMenu}
                />
            </Layout>
            <Layout gap={16} mb={16}>
                <Button
                    icon='plusCircle'
                    size='l'
                    text='Add Accounts'
                    theme='secondary'
                    onClick={() => {
                        navigate(generatePath(addPath, {
                            templateId,
                            mappingId: 'new',
                        }))
                    }}
                />
                <Button
                    icon='plusCircle'
                    size='l'
                    text='Bulk Account update'
                    theme='secondary'
                    onClick={() => { navigate(generatePath(uploadPath, { templateId })) }}
                />
                <StringInput2
                    input={searchInput}
                    maxWidth='506px'
                    dataTestId='input'
                    icon='search'
                    placeholder='Search by code or name'
                    size='l'
                />
            </Layout>

            {isEditNameModalOpen && (
                <MappingTemplateEditNameModal
                    template={template}
                    onClose={() => setIsEditNameModalOpen(false)}
                />
            )}

            {isDeletePopupOpen && (
                <Confirm
                    cancelText='Back to the Template'
                    onCancel={() => setIsDeletePopupOpen(false)}
                    disableEscapeKeyDown
                    disableOverlayClick
                >
                    <Alert
                        type='warning'
                        content={DELETE_TEMPLATE_ALERT}
                    />
                </Confirm>
            )}

            <Menu
                className='menu-paper'
                id='basic-menu'
                anchorEl={menuAnchorEl}
                open={Boolean(menuAnchorEl)}
                onClose={handleCloseMenu}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: -4,
                    horizontal: 'right',
                }}
            >
                <MenuItem onClick={handleDeleteTemplate}>
                    <ListItemIcon>
                        <Icon name='delete' color='colorsSecondaryGrey600'/>
                    </ListItemIcon>
                    <ListItemText>
                        <Text
                            text='Delete Template'
                            variant='smallTextMediumDefault'
                            color='colorsPrimaryGrey'
                        />
                    </ListItemText>
                </MenuItem>
                <MenuItem onClick={handleDownloadTemplate}>
                    <ListItemIcon>
                        <Icon name='download' color='colorsSecondaryGrey600'/>
                    </ListItemIcon>
                    <ListItemText>
                        <Text
                            text='Download to CSV'
                            variant='smallTextMediumDefault'
                            color='colorsPrimaryGrey'
                        />
                    </ListItemText>
                </MenuItem>
            </Menu>
            <AgGridTableLegacy
                ref={tableRef}
                query={templateMapQuery}
                {...GRID_OPTIONS}
                context={{
                    ...GRID_OPTIONS.context,
                    ledgerName: template?.ledger?.name,
                }}
            />
            <Outlet context={{ templateMapQuery }}/>
        </PageLayout>
    )
})
