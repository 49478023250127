import { getIncrementalName } from '@/utils/getIncrementalName'

import { SPACER_NAME_BASE } from './TableBuilder.constants'

export const moveInArray = (arr: string[], fromIndex: number, toIndex: number) => {
    const element = arr[fromIndex]
    arr.splice(fromIndex, 1)
    arr.splice(toIndex, 0, element)
}

export const getNewSpacerName = (list: string[]) => getIncrementalName(SPACER_NAME_BASE, list)

export const getReportPrefix = (tableId: string | number | undefined) => {
    if (!tableId) {
        console.error('getReportPrefix cant\'t be used without tableId param')
        return
    }
    return `T_${tableId}`
}
