import { CellClassParams, IAggFuncParams, RowHeightParams, ValueGetterParams } from '@ag-grid-community/core'

import { CustomCellRendererProps } from '@ag-grid-community/react'

import { ReportingRentRollMetricsCommercialRow } from '@/api/reportingData/reportingRentRollMetricsCommercial'
import { AgGridTableProps, DEFAULT_TOOLTIP_VALUE_GETTER } from '@/components/tables'
import { NEO_COL_TYPES } from '@/components/tables/AgGridTable/themes/neo/neo.constants'
import { NeoClasses, NeoColDef, NeoColGroupDef } from '@/components/tables/AgGridTable/themes/neo/neo.types'
import { ExcelClasses, excelStyles, getExcelGroupClasses, NAME_COLUMN_MAX_WIDTH, NAME_COLUMN_WIDTH } from '@/constants/reportTable'

import { GroupByRRField, GROUP_BY_RR_FIELD_LABLES } from '../../TableBuilderFilters'
import { getHeaderTemplate } from '../RRTradeOutBasicTable/RRTradeOutBasicTable.utils'
import { getAggFuncWithIntermediateCalcs, getWeightedAvgAggregation, grandTotalAverageRenderer, refreshAggregationAfterFiltering } from '../TableBuilderTables.utils'

export const rRMCTableGridOptions: Omit<AgGridTableProps<'neo'>, 'items'> = {
    defaultColDef: {
        suppressMovable: true,
        lockPinned: true,
        sortable: true,
        unSortIcon: true,
        filterParams: {
            cellHeight: 18,
        },
    },
    suppressFitToWidth: true,
    suppressAutoResize: true,
    suppressRowDrag: true,
    suppressMovableColumns: true,
    groupDefaultExpanded: 1,
    groupDisplayType: 'custom',
    groupTotalRow: 'bottom',
    grandTotalRow: 'bottom',
    suppressAggFuncInHeader: true,
    getRowId: ({ data }: { data: ReportingRentRollMetricsCommercialRow }) => String(data.asset_id),
    theme: 'neo',
    getRowHeight: (params: RowHeightParams) => {
        // grand total row height must be greater than default to separate it from other rows
        return (params.node.footer && params.node.level === -1) ? 30 : undefined
    },
    columnTypes: NEO_COL_TYPES,
    cellSelection: true,
    columnDefs: [],
    excelStyles,
    onFilterChanged: refreshAggregationAfterFiltering,
}

const BASE_COLS = [
    {
        field: 'total_sqft',
        title: 'Total~SQFT',
        type: 'money',
    },
    {
        field: 'occupied_sqft',
        title: 'Occupied~SQFT',
        type: 'money',
    },
    {
        field: 'vacant_sqft',
        title: 'Vacant~SQFT',
        type: 'money',
    },
    {
        field: 'ending_occupancy',
        title: 'Occupancy~ ',
        type: 'percentage',
        fractionDigits: 1,
    },
    {
        field: 'in_place_rent',
        title: 'In-Place~Rent',
        type: 'money',
    },
]

const occupiedTotalSQFTRatio = (params: IAggFuncParams) => {
    const { occupied_sqft, total_sqft } = params.rowNode?.aggData ?? {}
    if (!occupied_sqft?.sum || !total_sqft?.sum) { return 0 }
    return occupied_sqft.sum / total_sqft.sum * 100
}

const totalCellRenderer = (props: CustomCellRendererProps) => {
    const footer = props.node.footer
    const isRootLevel = props.node.level === -1

    if (footer) {
        if (isRootLevel) {
            return 'Total / Average'
        } else {
            return `${props.node.key} Total`
        }
    } else {
        return props.node.key
    }
}

const getGroupCol = (groupByRRField: GroupByRRField): NeoColDef => {
    const colName = GROUP_BY_RR_FIELD_LABLES[groupByRRField]
    return {
        headerName: colName,
        headerTooltip: colName,
        field: groupByRRField,
        rowGroup: true,
        cellRenderer: 'agGroupCellRenderer',
        cellRendererParams: {
            suppressCount: true,
            innerRenderer: totalCellRenderer,
        },
        type: 'string',
        minWidth: 140,
        maxWidth: 300,
        sortable: false,
        filter: 'agSetColumnFilter',
        cellClass: (params: CellClassParams) => [NeoClasses.HighlightTotalCell, ...getExcelGroupClasses(params)],
    }
}

export const getRRMCColDefs = (
    {
        groupByRRField,
        period,
    }:
    {
        groupByRRField?: GroupByRRField | null
        period: string
    },

): Array<NeoColGroupDef | NeoColDef> => {
    if (!groupByRRField) return []

    const hasNoGroup = groupByRRField === GroupByRRField.None
    const headerGroupL0Classes = [NeoClasses.RightBorder1, NeoClasses.Purple900BG, ExcelClasses.AlignCenter, ExcelClasses.HeaderGroupL0]

    const assetCol: NeoColDef = {
        headerName: 'Asset Name',
        headerTooltip: 'Asset Name',
        field: 'asset_name',
        type: 'string',
        minWidth: NAME_COLUMN_WIDTH - 100,
        maxWidth: NAME_COLUMN_MAX_WIDTH,
        rowGroup: false,
        // Show Total / Average in the GrandTotal row of the first column if no grouping
        aggFunc: hasNoGroup ? grandTotalAverageRenderer : null,
        cellClass: (params: CellClassParams) => [NeoClasses.HighlightTotalCell, ...getExcelGroupClasses(params)],
    }

    const baseCols: Array<NeoColGroupDef | NeoColDef> = BASE_COLS
        .map(({ field, title, type, fractionDigits }) => {
            const excelTypeClass = field === 'ending_occupancy' ? ExcelClasses.Percentage1Decimal : ExcelClasses.Currency

            const borderClass = field === 'in_place_rent' ? NeoClasses.RightBorder1 : ''

            const getAggFunc = () => {
                switch (field) {
                    case 'ending_occupancy':
                        return occupiedTotalSQFTRatio
                    case 'market_rent':
                        return getWeightedAvgAggregation('total_sqft')
                    case 'in_place_rent':
                        return getWeightedAvgAggregation('occupied_sqft')
                    case 'total_sqft':
                    case 'occupied_sqft':
                    default:
                        return getAggFuncWithIntermediateCalcs('sum')
                }
            }

            const cleanTitle = title.replace(/~/g, ' ')

            return ({
                headerName: cleanTitle,
                headerTooltip: cleanTitle,
                headerClass: [borderClass, NeoClasses.RightAlignedHeader],
                headerComponentParams: {
                    template: getHeaderTemplate(title),
                },
                field,
                type,
                fractionDigits,
                flex: 1,
                filter: 'agNumberColumnFilter',
                aggFunc: getAggFunc(),
                valueGetter: (params: ValueGetterParams<ReportingRentRollMetricsCommercialRow>) => params.data?.[field] ?? 0,
                cellClass: (params: CellClassParams) => [NeoClasses.RightAlignedCell, NeoClasses.HighlightTotalCell, excelTypeClass, borderClass, ...getExcelGroupClasses(params)],
                tooltipValueGetter: DEFAULT_TOOLTIP_VALUE_GETTER,
            })
        })

    const colDefs: Array<NeoColGroupDef | NeoColDef> = []
    const baseSectionCol: NeoColGroupDef = {
        headerClass: headerGroupL0Classes,
        children: [],
    }

    const groupCol: NeoColGroupDef | NeoColDef =
        {
            headerName: period,
            headerTooltip: period,
            headerClass: [NeoClasses.NoTopBorder],
            children: [],
        }

    switch (groupByRRField) {
        case GroupByRRField.City:
        case GroupByRRField.State:
        case GroupByRRField.PropertyManager:
        case GroupByRRField.AssetManager:
        case GroupByRRField.Fund:
        case GroupByRRField.CustomId1:
        case GroupByRRField.CustomId2:
        case GroupByRRField.Software:
        case GroupByRRField.YearBuilt:
        case GroupByRRField.Submarket:
        case GroupByRRField.SubassetClass:
            groupCol.children.push(getGroupCol(groupByRRField))
            baseSectionCol.children.push(groupCol, assetCol)
            break
        case GroupByRRField.None:
        default:
            groupCol.children.push(assetCol)
            baseSectionCol.children.push(groupCol)
    }

    baseSectionCol.children.push(...baseCols)
    colDefs.push(baseSectionCol)

    return colDefs
}
