import { MONTH_INPUT_QUERY_PARAM, MONTH_RANGE_START_QUERY_PARAM, MONTH_RANGE_END_QUERY_PARAM } from '@/components/baseInputs'
import { UseInputStateParams } from '@/hooks'

import { ASSET_SIDE_MODAL_INPUT_QUERY_PARAM } from '../../../components/models/asset/AssetSideModalInput/AssetSideModalInput.constants'

export const GLOBAL_REPORT_MULTY_ASSET_QUERY_PARAM: UseInputStateParams<any> = {
    name: ASSET_SIDE_MODAL_INPUT_QUERY_PARAM.name,
    type: ASSET_SIDE_MODAL_INPUT_QUERY_PARAM.type,
    tabStorageKey: 'REPORTS_' + ASSET_SIDE_MODAL_INPUT_QUERY_PARAM.name + '_MULTY',
    syncTabStorage: true,
    syncQueryParam: false,
}

export const GLOBAL_REPORT_SINGLE_ASSET_QUERY_PARAM: UseInputStateParams<any> = {
    name: ASSET_SIDE_MODAL_INPUT_QUERY_PARAM.name,
    type: ASSET_SIDE_MODAL_INPUT_QUERY_PARAM.type,
    tabStorageKey: 'REPORTS_' + ASSET_SIDE_MODAL_INPUT_QUERY_PARAM.name + '_SINGLE',
    syncTabStorage: true,
    syncQueryParam: false,
}

export const GLOBAL_REPORT_MONTH_QUERY_PARAM: UseInputStateParams<any> = {
    name: MONTH_INPUT_QUERY_PARAM.name,
    type: MONTH_INPUT_QUERY_PARAM.type,
    tabStorageKey: 'REPORTS_' + MONTH_INPUT_QUERY_PARAM.name,
    syncTabStorage: true,
    syncQueryParam: false,
}

export const GLOBAL_REPORT_MONTH_RANGE_START_QUERY_PARAM: UseInputStateParams<any> = {
    name: MONTH_RANGE_START_QUERY_PARAM.name,
    type: MONTH_RANGE_START_QUERY_PARAM.type,
    tabStorageKey: 'REPORTS_' + MONTH_RANGE_START_QUERY_PARAM.name,
    syncTabStorage: true,
    syncQueryParam: false,
}

export const GLOBAL_REPORT_MONTH_RANGE_END_QUERY_PARAM: UseInputStateParams<any> = {
    name: MONTH_RANGE_END_QUERY_PARAM.name,
    type: MONTH_RANGE_END_QUERY_PARAM.type,
    tabStorageKey: 'REPORTS_' + MONTH_RANGE_END_QUERY_PARAM.name,
    syncTabStorage: true,
    syncQueryParam: false,
}
