import React, { ForwardedRef, Ref, RefObject, useEffect, useMemo, useRef, useState } from 'react'

import { AgGridReact } from '@ag-grid-community/react'
import { useNavigate, useParams } from 'react-router'
import { useLocation } from 'react-router-dom'

import {
    ReportingReportItemTable,
    useReportingReportByIdQuery, useReportingReportDeleteMutation,
    useReportingReportUpdateMutation,
} from '@/api/reportingData/reportingReport'
import {
    ReportingTableItem,
} from '@/api/reportingTable/reportingTable'
import {
    ActionsMenu,
    ActionsMenuItem,
    Button,
    Loader,
    Text,
    TextInput,
    Confirm,
    Alert,
} from '@/components/base'
import { Layout, PageLayout } from '@/components/containers'
import { ReportTableWrapper } from '@/pages/MainPage/UserPages/ReportsPage'
import { CustomReportPageProps } from '@/pages/MainPage/UserPages/ReportsPage/CustomReportPage/CustomReportPage.types'
import { ReportFilters } from '@/pages/MainPage/UserPages/ReportsPage/CustomReportPage/ReportFilters'
import { ReportTableSelect } from '@/pages/MainPage/UserPages/ReportsPage/CustomReportPage/ReportTableSelect'
import { TableBuilder } from '@/pages/TablesPage/TableBuilderPage/TableBuilder'
import { TableBuilderFilters } from '@/pages/TablesPage/TableBuilderPage/TableBuilderFilters'
import { notify } from '@/utils/notify'
import { getQueryStringToObject } from '@/utils/routing/getQueryStringToObject'
import { getRoute } from '@/utils/routing/getRoute'

import { LazyReportTable } from './LazyReportTable'

import styles from './CustomReportPage.module.scss'

export const TABLE_HEIGHT = 700

export const CustomReportPage = (props: CustomReportPageProps) => {
    const { reportType } = props

    const { id: reportId } = useParams()

    const navigate = useNavigate()
    const location = useLocation()

    const tableRefs = useRef<Record<string | number, RefObject<AgGridReact>>>({})

    const reportingReportByIdQuery = useReportingReportByIdQuery(reportId, {
        // FIXME: It's a hack whick fixes the bug with filters when tree items need to be clicked twice if anything in cache
        gcTime: 0,
    })

    const reportingReportDeleteMutation = useReportingReportDeleteMutation()
    const reportingReportUpdateMutation = useReportingReportUpdateMutation()

    const reportItems = reportingReportByIdQuery.data?.items ?? []

    const viewModeLink = useMemo(() => (getRoute(reportType === 'company' ? 'REPORTS_CUSTOM_COMPANY_BY_ID' : 'REPORTS_CUSTOM_MY_BY_ID', { id: reportId as string }, getQueryStringToObject(location.search))), [reportId, location.search])

    const [renameMode, setRenameMode] = useState(false)
    const [modifiedReportName, setModifiedReportName] = useState<string>('')
    const [showTableSelect, setShowTableSelect] = useState(false)
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)

    useEffect(() => {
        if (reportingReportByIdQuery.data) {
            setModifiedReportName(reportingReportByIdQuery.data.name)
        }
    }, [reportingReportByIdQuery.data])

    const handleReportSave = async () => {
        try {
            setRenameMode(false)
            await reportingReportUpdateMutation.mutateAsync({
                id: reportId as string,
                updatedItemData: {
                    name: modifiedReportName,
                },
                config: {
                    suppressErrorToast: true,
                },
            })
        } catch (e) {
            notify.error(e?.response?.data?.name?.[0] || 'Failed to update report')
        }
    }

    const handleReportTableChoose = async (selectedTable: ReportingTableItem[]) => {
        const reportTables: ReportingReportItemTable[] = selectedTable.map(table => ({
            table_id: table.id,
        }))

        setShowTableSelect(false)

        await reportingReportUpdateMutation.mutateAsync({
            id: reportId as string,
            updatedItemData: { items: reportTables },
        })
        navigate(viewModeLink)
    }

    const handleDeleteReport = async () => {
        await reportingReportDeleteMutation.mutateAsync({
            id: reportId as string,
        })
        setShowDeleteConfirm(false)
        navigate(getRoute('REPORTS'))
    }

    const isReportEmpty = !reportingReportByIdQuery.data?.items.length
    const isReportLoading = !reportingReportByIdQuery.isFetched

    const menuItems: ActionsMenuItem[] = [
        {
            text: 'Add/Delete Tables',
            onClick: () => setShowTableSelect(true),
        },
        {
            text: 'Rename',
            // Timeout to avoid trigering onBlur during click
            onClick: () => setTimeout(() => setRenameMode(true), 200),
        },
        {
            text: 'Delete',
            onClick: () => setShowDeleteConfirm(true),
        },
    ]

    return (
        <>
            <PageLayout gap={32} pt={8} className={styles.reportPage}>
                <Layout
                    align='flex-end'
                    gap={32}
                    relative
                >
                    {renameMode ? (
                        <TextInput
                            // Limit for this field in API
                            maxLength={255}
                            value={modifiedReportName}
                            onChange={(e) => setModifiedReportName(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    handleReportSave()
                                }
                            }}
                            onBlur={handleReportSave}
                            minWidth='300px'
                            maxWidth='300px'
                            autoFocus
                        />
                    ) : (
                        <Layout onClick={() => setRenameMode(true)}>
                            <Text
                                variant='header'
                                maxLines={2}
                                elementTitle={modifiedReportName}
                            >
                                {modifiedReportName}
                            </Text>
                        </Layout>
                    )}

                    {reportItems.length > 1 ? (
                        <ReportFilters/>
                    ) : <Layout flexGrow={1}/>}

                    <ActionsMenu items={menuItems} variant='secondary'/>
                </Layout>
                <Layout
                    height='100%'
                    gap={16}
                    pb={24}
                >
                    {(isReportEmpty && !isReportLoading) ? (
                        <Layout
                            gap={12}
                            direction='column'
                            align='center'
                            justify='center'
                            height='100%'
                            width='100%'
                        >
                            <Text
                                color='colorsPrimaryGrey'
                                variant='header'
                            >Let's create your report</Text>
                            <Text
                                variant='smallTextMediumDefault'
                                color='colorsSecondaryGrey600'
                            >Get started by adding a block</Text>
                            <Button
                                text='Add a Table to the Report'
                                loading={reportingReportUpdateMutation.isPending}
                                theme='secondary'
                                icon='plus'
                                onClick={() => {
                                    setShowTableSelect(true)
                                }}
                            />
                        </Layout>
                    ) : (
                        <>
                            {(!isReportLoading) ? (
                                <Layout
                                    direction='column'
                                    gap={20}
                                    width='100%'
                                >
                                    {
                                        reportItems.map(({ table_id: tableId }) => {
                                            if (!tableRefs.current[tableId]) {
                                                tableRefs.current[tableId] = React.createRef()
                                            }

                                            return (
                                                <Layout
                                                    key={tableId}
                                                    direction='column'
                                                    gap={16}
                                                    width='100%'
                                                    style={{
                                                        flexShrink: 0,
                                                        minHeight: reportItems.length > 1 ? TABLE_HEIGHT : undefined,
                                                        flexGrow: 1,
                                                    }}
                                                >
                                                    {reportItems.length === 1 && (
                                                        <TableBuilderFilters
                                                            tableRef={tableRefs.current[tableId] as unknown as RefObject<AgGridReact<any>>}
                                                            insideReport
                                                            tableId={tableId}
                                                            tablesListRouteConfigKey={reportType === 'my' ? 'REPORTS_CUSTOM_MY_TABLES' : 'REPORTS_CUSTOM_COMPANY_TABLES'}
                                                        />
                                                    )}
                                                    <LazyReportTable
                                                        minHeight={TABLE_HEIGHT}
                                                    >
                                                        <ReportTableWrapper
                                                            reportType={reportType}
                                                            tableId={tableId}
                                                            reportId={reportId}
                                                            tableRef={tableRefs.current[tableId] as unknown as ForwardedRef<AgGridReact<any>>}
                                                        >
                                                            <TableBuilder
                                                                tableId={tableId}
                                                                insideReport
                                                                ref={tableRefs.current[tableId] as unknown as Ref<AgGridReact<any>>}
                                                                builderRouteConfigKey={reportType === 'my' ? 'REPORTS_CUSTOM_MY_TABLE_BUILDER' : 'REPORTS_CUSTOM_COMPANY_TABLE_BUILDER'}
                                                                tablesListRouteConfigKey={reportType === 'my' ? 'REPORTS_CUSTOM_MY_TABLES' : 'REPORTS_CUSTOM_COMPANY_TABLES'}
                                                            />
                                                        </ReportTableWrapper>
                                                    </LazyReportTable>
                                                </Layout>
                                            )
                                        })
                                    }

                                    <Layout pb={32}>
                                        {reportItems.length > 0 && (
                                            <Button text='Add New Table' icon='plus' theme='secondary' block onClick={() => setShowTableSelect(true)}/>
                                        )}
                                    </Layout>
                                </Layout>
                            ) : (
                                <Loader centered/>
                            )}
                        </>
                    )}
                </Layout>
            </PageLayout>

            {showDeleteConfirm && (
                <Confirm
                    confirmText='Delete Report'
                    isLoading={reportingReportDeleteMutation.isPending}
                    onConfirm={handleDeleteReport}
                    onCancel={() => setShowDeleteConfirm(false)}
                >
                    <Alert type='warning'>
                        Are you sure you want to delete this report?
                    </Alert>
                </Confirm>
            )}

            {showTableSelect && (
                <ReportTableSelect
                    onClose={() => { setShowTableSelect(false) }}
                    multiSelect
                    onSave={handleReportTableChoose}
                />
            )}
        </>
    )
}
