/* eslint-disable @typescript-eslint/no-empty-function */
import { forwardRef, RefObject, useCallback, useEffect, useMemo, useRef } from 'react'

import once from 'lodash/once'

import { AgGridReact } from '@ag-grid-community/react'

import { useReportingTableByIdQuery } from '@/api/reportingTable/reportingTable'
import { useInputState } from '@/hooks'

import { TableType } from '../../tableType'
import {
    getReportTableAssetTypeParam,
    getReportTableRrLedgerInputQueryParam,
    getReportTableTbLedgerInputQueryParam,
    getReportTableTypeInputQueryParam,
    TABLE_ASSET_TYPE_PARAM,
    TABLE_RR_LEDGER_INPUT_QUERY_PARAM,
    TABLE_TB_LEDGER_INPUT_QUERY_PARAM,
    TABLE_TYPE_INPUT_QUERY_PARAM,
} from '../TableBuilderPage.constants'
import {
    PnLTimeSeriesTable,
    MetricTimeSeriesTable,
    PortfolioOverviewTable,
    PortfolioRentRollTable,
    PortfolioSelectedValueTable,
    MetricSideBySideTable,
    PnLVarianceAnalysisTable,
    RRTradeOutBasicTable,
    BSTimeSeriesTable,
    RRMetricsCommercialTable,
    OfficeLeaseExpirationTable,
} from '../TableBuilderTables'
import { DebtDataTable } from '../TableBuilderTables/DebtDataTable'
import { TrialBalanceTable } from '../TableBuilderTables/TrialBalanceTable'

import { AFTER_FIRST_TABLE_RENDER_DELAY } from './TableBuilder.constants'
import { TableBuilderProps } from './TableBuilder.types'

export const TableBuilder = forwardRef((props: TableBuilderProps, ref: RefObject<AgGridReact>) => {
    const {
        tableMeta = {},
        buttonContainer,
        onCancel,
        onGridReady,
        tableId: id,
        editable = false,
        insideReport = false,
        height,
        onTableReady = () => {},
        tablesListRouteConfigKey,
        builderRouteConfigKey,
    } = props

    const isCreationMode = id === 'new'
    const fallbackTableRef = useRef<AgGridReact>(null)
    const tableRef = ref ?? fallbackTableRef
    const onFirstDataRendered = useCallback(once(() => setTimeout(onTableReady, AFTER_FIRST_TABLE_RENDER_DELAY)), [onTableReady])

    const { data: loadedTableData } = useReportingTableByIdQuery(id, { enabled: !isCreationMode })

    const [tableType, setTableType] = useInputState(
        insideReport ? getReportTableTypeInputQueryParam(id) : TABLE_TYPE_INPUT_QUERY_PARAM,
    )
    const [, setTBLedgerId] = useInputState(
        insideReport ? getReportTableTbLedgerInputQueryParam(id) : TABLE_TB_LEDGER_INPUT_QUERY_PARAM,
    )
    const [, setRRLedgerId] = useInputState(
        insideReport ? getReportTableRrLedgerInputQueryParam(id) : TABLE_RR_LEDGER_INPUT_QUERY_PARAM,
    )
    const [, setAssetType] = useInputState(
        insideReport ? getReportTableAssetTypeParam(id) : TABLE_ASSET_TYPE_PARAM,
    )

    useEffect(() => {
        if (loadedTableData?.type && tableType !== loadedTableData.type) {
            setTableType(loadedTableData.type)
        }

        if (loadedTableData?.trial_balance_ledger) {
            setTBLedgerId(loadedTableData.trial_balance_ledger)
        }

        if (loadedTableData?.rent_roll_ledger) {
            setRRLedgerId(loadedTableData.rent_roll_ledger)
        }

        if (loadedTableData?.asset_type) {
            setAssetType(loadedTableData.asset_type)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        tableType,
        setTableType,
        setTBLedgerId,
        setRRLedgerId,
        loadedTableData?.type,
        loadedTableData?.trial_balance_ledger,
        loadedTableData?.rent_roll_ledger,
        // Need this to work within reports page to make updates on page change
        location.pathname,
    ])

    const tableParams = useMemo(() => ({
        id: id ?? '',
        tableMeta,
        buttonContainer,
        onCancel,
        onGridReady,
        editable,
        insideReport,
        height,
        onFirstDataRendered,
        tablesListRouteConfigKey,
        builderRouteConfigKey,
    }), [id, tableMeta, buttonContainer, onCancel, onGridReady, editable, insideReport, onFirstDataRendered])

    if (!id || !tableType) {
        return null
    }

    switch (tableType) {
        case TableType.PortfolioOverview:
            return <PortfolioOverviewTable {...tableParams} ref={tableRef}/>
        case TableType.PortfolioRentRoll:
            return <PortfolioRentRollTable {...tableParams} ref={tableRef}/>
        case TableType.PortfolioSelectedValue:
            return <PortfolioSelectedValueTable {...tableParams} ref={tableRef}/>
        case TableType.MetricSideBySide:
            return <MetricSideBySideTable {...tableParams} ref={tableRef}/>
        case TableType.MetricTimeSeries:
            return <MetricTimeSeriesTable {...tableParams} ref={tableRef}/>
        case TableType.PnLTimeSeries:
            return <PnLTimeSeriesTable {...tableParams} ref={tableRef}/>
        case TableType.PnLVarianceAnalysis:
            return <PnLVarianceAnalysisTable {...tableParams} ref={tableRef}/>
        case TableType.RRTradeOutBasic:
            return <RRTradeOutBasicTable {...tableParams} ref={tableRef}/>
        case TableType.BSTimeSeries:
            return <BSTimeSeriesTable {...tableParams} ref={tableRef}/>
        case TableType.RRMetricsCommercial:
            return <RRMetricsCommercialTable {...tableParams} ref={tableRef}/>
        case TableType.LeaseExpiration:
            return <OfficeLeaseExpirationTable {...tableParams} ref={tableRef}/>
        case TableType.TrialBalance:
            return <TrialBalanceTable {...tableParams} ref={tableRef}/>
        case TableType.DebtData:
            return <DebtDataTable {...tableParams} ref={tableRef}/>
        default:
            return null
    }
})
