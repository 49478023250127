import { Text } from '@/components/base'
import { Layout } from '@/components/containers'

import { NoDataMessageProps } from './NoDataMessage.types'

export const NoDataMessage = (props: NoDataMessageProps) => {
    const { text } = props
    return (
        <Layout
            direction='column'
            gap={8}
            align='center'
            style={{ marginTop: 158 }}
        >
            <Text variant='header' color='colorsSecondaryGrey200'>
                No data to display
            </Text>
            <Text variant='smallTextMediumDefault' color='colorsSecondaryGrey600'>
                {text}
            </Text>
        </Layout>
    )
}
