import React, { forwardRef, RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { CellClickedEvent, ExcelExportParams, GridState } from '@ag-grid-community/core'
import { CommentAnnotation } from '@veltdev/types'
import isEqual from 'lodash/isEqual'
import { AND, EQ } from 'mobx-orm'
import { createPortal } from 'react-dom'

import { AgGridReact } from '@ag-grid-community/react'
import { useVeltClient, VeltCommentsSidebar, VeltSidebarButton } from '@veltdev/react'
import { useNavigate } from 'react-router'

import { ReportingPnLRow, ReportingPnLRowType } from '@/api/reportingData/reportingProfitAndLoss'
import { ReportingPnLVarianceValueEntry, useReportingProfitAndLossVarianceQuery } from '@/api/reportingData/reportingProfitAndLossVariance'
import { useReportingProfitAndLossVarianceMetricsQuery } from '@/api/reportingData/reportingProfitAndLossVarianceMetrics'
import { ReportingTableFilters, useReportingTableByIdQuery } from '@/api/reportingTable/reportingTable'
import { Button } from '@/components/base'
import { MONTH_INPUT_QUERY_PARAM, SCENARIO_INPUT_QUERY_PARAM, TIME_COMPARISON_INPUT_QUERY_PARAM } from '@/components/baseInputs'
import { Layout, useTableSettings } from '@/components/containers'
import { ASSET_DETAILS_QUERY_PARAM, ASSET_SIDE_MODAL_INPUT_QUERY_PARAM } from '@/components/models/asset'
import { AgGridTable } from '@/components/tables'
import { useTableRangeSelectionModules } from '@/components/tables/utils/useTableRangeSelectionModules'
import {
    GLOBAL_REPORT_MONTH_QUERY_PARAM,
    GLOBAL_REPORT_MULTY_ASSET_QUERY_PARAM,
} from '@/components/widgets/reports/reports.constants'
import { getNoteTextFromAnnotations, getOtherNotes } from '@/components/widgets/reports/reports.utils'
import { TimeComparisonPeriod } from '@/constants/timeComparisonPeriods'
import { TimeSettings } from '@/constants/timeSettings'
import { useInputState, usePortal } from '@/hooks'
import {
    REPORT_WRAPPER_BUTTONS_CONTAINER,
} from '@/pages/MainPage/UserPages/ReportsPage/ReportTableWrapper/ReportTableWrapper.constants'
import {
    usePnlReportInsightsDocumentId,
    usePnlReportNotesDocumentId,
} from '@/pages/TablesPage/TableBuilderPage/TableBuilderTables/PnLVarianceAnalysisTable/PnlVeltReportNote/PnlVeltReportNote.utils'
import { TableType } from '@/pages/TablesPage/tableType'
import { monthIndToString } from '@/utils/date/monthInd'
import { getRoute } from '@/utils/routing/getRoute'

import { CustomSettingsFilterModal } from '../../CustomSettingsFilterModal'
import { NoDataMessage } from '../../NoDataMessage'
import { CancelSaveButtons } from '../../TableBuilder/CancelSaveButtons'
import {
    ACCOUNT_CODES_INPUT_QUERY_PARAM,
    EMPTY_ROWS_INPUT_QUERY_PARAM,
    PNL_METRIC_INPUT_QUERY_PARAM,
    SHOW_HIDE_INPUT_OPTIONS,
    TABLE_ID_ROWS_INPUT_QUERY_PARAM,
    UNDERWRITING_DATE_INPUT_QUERY_PARAM,
} from '../../TableBuilderFilters'
import {
    getReportAssetDetailsInputQueryParam,
    getReportPnLMetricInputQueryParam,
    getReportScenarioInputQueryParam,
    getReportShowCodesInputQueryParam,
    getReportShowEmptyRowsInputQueryParam,
    getReportTableAssetTypeParam,
    getReportTableIdRowsInputQueryParam,
    getReportTableRrLedgerInputQueryParam,
    getReportTableTbLedgerInputQueryParam,
    getReportTimeComparisonInputQueryParam, getUnderwritingDateInputQueryParam,
    TABLE_ASSET_TYPE_PARAM,
    TABLE_RR_LEDGER_INPUT_QUERY_PARAM,
    TABLE_TB_LEDGER_INPUT_QUERY_PARAM,
} from '../../TableBuilderPage.constants'
import { ASSET_DETAIL_TO_ENTITY_ID, TABLE_ID_ROW_TO_ENTITY_ID } from '../PnLTimeSeriesTable/PnLTimeSeriesTable.constants'
import { processPnLTableTotalCells } from '../PnLTimeSeriesTable/PnLTimeSeriesTable.utils'
import { statusBar } from '../TableBuilderTables.constants'
import { TableBuilderTableProps } from '../TableBuilderTables.types'
import { getGridPropsToSave, saveGridStateToRef, saveReportingTable } from '../TableBuilderTables.utils'
import { useReportingTableSaveMutation } from '../useReportingTableSaveMutation'

import { CLICKABLE_TIME_COMPARISONS, FILTER_DEFAULTS_ON_CREATION, getPnLVAColDefs, getContextMenuItems, DEFAULT_PNL_VA_CONDITIONAL_FORMAT_RULES, pnLVarianceTableGridOptions } from './PnLVarianceAnalysisTable.constants'
import { getPnlVarianceTableRowId, normalizePercentageValues } from './PnLVarianceAnalysisTable.utils'
import { PnLVarianceDrillDownParams, PnLVarianceDrillDownSlideover } from './PnLVarianceDrillDownSlideover'

/**
 * P&L - Variance Analysis Table
 */
export const PnLVarianceAnalysisTable = forwardRef((props: TableBuilderTableProps, ref: RefObject<AgGridReact>) => {
    const {
        tableMeta = {},
        buttonContainer,
        onCancel,
        onGridReady,
        id,
        insideReport = false,
        onFirstDataRendered,
        tablesListRouteConfigKey,
        builderRouteConfigKey,
    } = props

    const navigate = useNavigate()
    const rangeSelectionModules = useTableRangeSelectionModules()
    const isCreationMode = id === 'new'
    const fallbackTableRef = useRef<AgGridReact>(null)
    const tableRef = ref ?? fallbackTableRef
    const gridStateRef = useRef<GridState>() // Retain grid state between renders
    const areFilterDefaultsSet = useRef(false)
    const areDefaultMetricsSet = useRef(false)

    const [isRefreshing, setIsRefreshing] = useState(false)
    const [isTableDirty, setIsTableDirty] = useState(false)

    const [veltInsightsCount, setVeltInsightsCount] = useState(0)
    const [veltNotesCount, setVeltNotesCount] = useState(0)

    const { getTableSetting, setTableSetting, showTableSettingsModal, setShowTableSettingsModal } = useTableSettings()

    const saveMutation = useReportingTableSaveMutation()
    const { data: loadedTableData } = useReportingTableByIdQuery(id, { enabled: !isCreationMode })
    const reportWrapperButtonsContainer = usePortal(REPORT_WRAPPER_BUTTONS_CONTAINER)

    // load grid state once
    if (!gridStateRef.current && loadedTableData?.aggrid_state) { gridStateRef.current = loadedTableData.aggrid_state }

    const [tBLedgerId] = useInputState(
        insideReport ? getReportTableTbLedgerInputQueryParam(id) : TABLE_TB_LEDGER_INPUT_QUERY_PARAM,
    )
    const [rRLedgerId] = useInputState(
        insideReport ? getReportTableRrLedgerInputQueryParam(id) : TABLE_RR_LEDGER_INPUT_QUERY_PARAM,
    )
    const [assetType] = useInputState(
        insideReport ? getReportTableAssetTypeParam(id) : TABLE_ASSET_TYPE_PARAM,
    )

    const [assetIds, setAssetIds] = useInputState(
        insideReport ? GLOBAL_REPORT_MULTY_ASSET_QUERY_PARAM : ASSET_SIDE_MODAL_INPUT_QUERY_PARAM,
    )

    const [metricIds, setMetricIds] = useInputState(
        insideReport ? getReportPnLMetricInputQueryParam(id) : PNL_METRIC_INPUT_QUERY_PARAM,
    )
    const [month, setMonth] = useInputState(
        insideReport ? GLOBAL_REPORT_MONTH_QUERY_PARAM : MONTH_INPUT_QUERY_PARAM,
    )
    const [timeComparison, setTimeComparison] = useInputState(
        insideReport ? getReportTimeComparisonInputQueryParam(id) : TIME_COMPARISON_INPUT_QUERY_PARAM,
    )
    const [scenarioIds, setScenarioIds] = useInputState(
        insideReport ? getReportScenarioInputQueryParam(id) : SCENARIO_INPUT_QUERY_PARAM,
    )
    const [assetDetails, setAssetDetails] = useInputState(
        insideReport ? getReportAssetDetailsInputQueryParam(id) : ASSET_DETAILS_QUERY_PARAM,
    )
    const [tableIdRows, setTableIdRows] = useInputState(
        insideReport ? getReportTableIdRowsInputQueryParam(id) : TABLE_ID_ROWS_INPUT_QUERY_PARAM,
    )
    const [showCodes, setShowCodes] = useInputState(
        insideReport ? getReportShowCodesInputQueryParam(id) : ACCOUNT_CODES_INPUT_QUERY_PARAM,
    )
    const [showEmptyRows, setShowEmptyRows] = useInputState(
        insideReport ? getReportShowEmptyRowsInputQueryParam(id) : EMPTY_ROWS_INPUT_QUERY_PARAM,
    )
    const [underwritingDate, setUnderwritingDate] = useInputState(
        insideReport ? getUnderwritingDateInputQueryParam(id) : UNDERWRITING_DATE_INPUT_QUERY_PARAM,
    )

    const showAccountCodes = showCodes === '1'
    const isShowEmptyRowsEnabled = showEmptyRows === '1'
    const [drillDownParams, setDrillDownParams] = useState<PnLVarianceDrillDownParams | null>(null)

    const assetDetailRowIds = useMemo(() => new Set(assetDetails?.map(d => ASSET_DETAIL_TO_ENTITY_ID[d])), [assetDetails])
    const reportDetailRowIds = useMemo(() => new Set(tableIdRows?.map(d => TABLE_ID_ROW_TO_ENTITY_ID[d])), [tableIdRows])

    const conditionalFormatRules = loadedTableData?.id ? (getTableSetting(loadedTableData.id)?.conditionalRules ?? undefined) : undefined

    const areFiltersSet = Boolean(assetIds && month && timeComparison && scenarioIds)

    const { data: reportData, isLoading, isFetched, isSuccess } = useReportingProfitAndLossVarianceQuery(
        {
            filter: AND(
                EQ('asset_ids', assetIds),
                EQ('trial_balance_ledger_id', tBLedgerId),
                EQ('rent_roll_ledger_id', rRLedgerId),
                EQ('metric_ids', metricIds),
                EQ('time_settings', TimeSettings.MULTIPLE),
                EQ('time_period', month), // month index
                EQ('time_comparison', timeComparison), // TimeComparisonPeriod[]
                EQ('scenarios', 'actual'), // INFO: hardcoded value. Required by BE but not actually used in the query for now.
                EQ('underwriting_date', underwritingDate), // date string in format 'YYYY-MM-DD'
            ),
        },
        { enabled: Boolean(areFiltersSet && tBLedgerId) })

    const { data: pnLVAMetrics } = useReportingProfitAndLossVarianceMetricsQuery(
        {
            filter: AND(
                EQ('asset_ids', assetIds),
                EQ('trial_balance_ledger_id', tBLedgerId),
                EQ('rent_roll_ledger_id', rRLedgerId),
            ),
        },
        { enabled: Boolean(isCreationMode && assetIds) },
    )

    // set filter defaults once in creation mode
    const defaultMetrics = useMemo(() => pnLVAMetrics?.filter(m => m.is_default), [pnLVAMetrics])
    useEffect(() => {
        if (isCreationMode && defaultMetrics?.length && !areDefaultMetricsSet.current) {
            setMetricIds(defaultMetrics.map(m => m.id))
            areDefaultMetricsSet.current = true
        }
    }, [defaultMetrics, isCreationMode, setMetricIds])

    useEffect(() => {
        if (isCreationMode && !areFilterDefaultsSet.current) {
            setTimeComparison(FILTER_DEFAULTS_ON_CREATION.TimeComparison)
            setScenarioIds(FILTER_DEFAULTS_ON_CREATION.ScenarioIds)
            setAssetDetails(FILTER_DEFAULTS_ON_CREATION.AssetDetails)
            setShowEmptyRows(FILTER_DEFAULTS_ON_CREATION.ShowEmptyRows)
            areFilterDefaultsSet.current = true
        }
    }, [isCreationMode, setAssetDetails, setScenarioIds, setShowEmptyRows, setTimeComparison])

    const [commentsMap] = useState<Map<string, CommentAnnotation[]>>(new Map())

    const [rows, columns] = useMemo(() => [reportData?.[0]?.rows ?? [], reportData?.[0]?.columns], [reportData])

    const filteredRows = useMemo(() => {
        const filtered = normalizePercentageValues(rows)
            .filter(row => {
                if (row.type === ReportingPnLRowType.ReportDetails) {
                    return reportDetailRowIds.has(row.entity_id)
                } else if (row.type === ReportingPnLRowType.AssetDetails) {
                    return assetDetailRowIds.has(row.entity_id)
                } else {
                    return true
                }
            })
        // filter out double separators & first row separator
        return filtered
            .filter((row, i, arr) => {
                if (row.type === ReportingPnLRowType.Separator) {
                    return i === 0 ? false : arr[i - 1].type !== ReportingPnLRowType.Separator
                }

                if (!isShowEmptyRowsEnabled) {
                    const rowEmptyForAllTimeComp =
                        (Object.values(TimeComparisonPeriod) as TimeComparisonPeriod[]).map((timeCompKey) => {
                            const timeComp = row.values?.[timeCompKey]
                            const notEmpty =
                                typeof timeComp === 'string' || typeof timeComp === 'number' ||
                                (timeComp as ReportingPnLVarianceValueEntry)?.actual ||
                                (timeComp as ReportingPnLVarianceValueEntry)?.actual_psf ||
                                (timeComp as ReportingPnLVarianceValueEntry)?.budget ||
                                (timeComp as ReportingPnLVarianceValueEntry)?.budget_psf ||
                                (timeComp as ReportingPnLVarianceValueEntry)?.variance ||
                                (timeComp as ReportingPnLVarianceValueEntry)?.variance_psf ||
                                (timeComp as ReportingPnLVarianceValueEntry)?.variance_percent

                            return notEmpty
                        }).filter(notEmpty => notEmpty).length === 0
                    if (rowEmptyForAllTimeComp) {
                        return false
                    }
                }

                return true
            })
            .map((item) => {
                const locationId = item.entity_id.replace('_', '-')

                const annotations = commentsMap.get(locationId)
                const noteText = annotations ? getNoteTextFromAnnotations(annotations) : ''
                return {
                    ...item,
                    notes: noteText,
                }
            })
    }, [rows, reportDetailRowIds, assetDetailRowIds, isShowEmptyRowsEnabled, commentsMap])

    const hideNotes = (assetIds?.length || 0) > 1

    const columnDefs = useMemo(
        () =>
            getPnLVAColDefs({
                conditionalFormatRules,
                month,
                timeComparison,
                scenarioIds,
                showAccountCodes,
                assetType,
                hideNotes,
                insideReport,
                tableId: id,
                underwritingDate,
            }),
        [conditionalFormatRules, month, scenarioIds, showAccountCodes, timeComparison, assetType, hideNotes, underwritingDate],
    )

    // table config
    const config = useMemo(() => {
        const filters: ReportingTableFilters = { assetIds: assetIds as number[] }
        if (metricIds) { filters.pnLVAmetricIds = metricIds }
        if (month) { filters.month = month }
        if (timeComparison) { filters.timeComparison = timeComparison }
        if (scenarioIds) { filters.scenarioIds = scenarioIds }
        if (assetDetails) { filters.assetDetails = assetDetails }
        if (tableIdRows) { filters.tableIdRows = tableIdRows }
        if (showCodes) { filters.showCodes = showCodes }
        if (showEmptyRows) { filters.showEmptyRows = showEmptyRows }
        if (underwritingDate) { filters.underwritingDate = underwritingDate }
        return ({
            filters,
            conditional_format_rules: conditionalFormatRules && { rules: conditionalFormatRules },
        })
    }, [conditionalFormatRules, assetIds, metricIds, month, timeComparison, scenarioIds, assetDetails, tableIdRows, showCodes, showEmptyRows, underwritingDate])

    const checkIfTableIsDirty = useCallback(() => {
        const loadedState = loadedTableData?.aggrid_state ?? {}
        const currentState = getGridPropsToSave(tableRef.current?.api?.getState())
        const currentConfig = config
        const loadedConfig = loadedTableData?.config ?? {}
        const currentName = tableMeta.name
        const loadedName = loadedTableData?.name

        if (
            isEqual(currentConfig, loadedConfig) &&
            isEqual(currentState, loadedState) &&
            currentName === loadedName
        ) {
            setIsTableDirty(false)
        } else {
            setIsTableDirty(true)
        }
    }, [config, loadedTableData?.aggrid_state, loadedTableData?.config, loadedTableData?.name, tableMeta.name, tableRef])
    useEffect(() => checkIfTableIsDirty(), [checkIfTableIsDirty])

    // set filters from loaded table data
    useEffect(() => {
        if (!loadedTableData) {
            return
        }

        const { filters, conditional_format_rules } = loadedTableData.config
        if (filters) {
            if ((insideReport && !assetIds) || !insideReport) {
                filters.assetIds && setAssetIds(filters.assetIds)
            }
            if ((insideReport && !month) || !insideReport) {
                filters.month && setMonth(filters.month)
            }
            filters.timeComparison && setTimeComparison(filters.timeComparison)
            filters.scenarioIds && setScenarioIds(filters.scenarioIds)
            filters.assetDetails && setAssetDetails(filters.assetDetails)
            filters.pnLVAmetricIds && setMetricIds(filters.pnLVAmetricIds)
            filters.tableIdRows && setTableIdRows(filters.tableIdRows)
            filters.underwritingDate && setUnderwritingDate(filters.underwritingDate)

            // Set default values here to avoid overwriting with default Select value
            setShowCodes(filters.showCodes ? filters.showCodes : SHOW_HIDE_INPUT_OPTIONS[0].value)
            setShowEmptyRows(filters.showEmptyRows ? filters.showEmptyRows : SHOW_HIDE_INPUT_OPTIONS[0].value)
        }
        setTableSetting(loadedTableData.id, conditional_format_rules ? conditional_format_rules.rules : DEFAULT_PNL_VA_CONDITIONAL_FORMAT_RULES)
    }, [loadedTableData, insideReport])

    const onTableSave = useCallback(async () => {
        const currentState = tableRef.current?.api?.getState() ?? {}
        const response = await saveReportingTable({
            tableMeta,
            currentState,
            isCreationMode,
            saveMutation,
            id,
            tableType: TableType.PnLVarianceAnalysis,
            config,
            assetType,
        })

        if (!response) {
            return
        }

        // redirect after saving
        if (isCreationMode) {
            navigate(getRoute(builderRouteConfigKey, { id: response.id }))
        } else {
            navigate(getRoute(tablesListRouteConfigKey))
        }
    }, [assetType, config, id, isCreationMode, navigate, saveMutation, tableMeta, tableRef])

    const excelExportParams = useMemo((): ExcelExportParams => {
        const periodAsString = month ? monthIndToString(month) : ''
        return ({
            fileName: `P&L - Variance Analysis - ${periodAsString}.xlsx`,
            sheetName: periodAsString,
            processCellCallback: processPnLTableTotalCells,
            appendContent: getOtherNotes(commentsMap.get('other') ?? []),
            freezeColumns: 'pinned',
            columnKeys: tableRef.current?.api.getAllGridColumns()?.map((col) => col.getColId()).filter((id) => id !== 'note'),
            suppressRowOutline: true,
        })
    }, [month, commentsMap, tableRef])

    // HACK: redraw rows on filter change because group total rows are not updated when data is cached
    // TODO: investigate if this can be fixed another way
    useEffect(() => {
        setIsRefreshing(true)
        setTimeout(() => setIsRefreshing(false), 100)
    }, [filteredRows])

    const { client: veltClient } = useVeltClient()

    const notesDocId = usePnlReportNotesDocumentId(insideReport, id)
    const insightsDocId = usePnlReportInsightsDocumentId(insideReport)

    // Set any initial docId just to activate Velt listeners
    useEffect(() => {
        if (!veltClient || !notesDocId) return
        veltClient.setDocumentId(notesDocId)
    }, [veltClient, notesDocId])

    useEffect(() => {
        if (veltClient && notesDocId && insightsDocId) {
            const commentElement = veltClient.getCommentElement()

            const subscriptionComments = commentElement.getAllCommentAnnotations(notesDocId)
                .subscribe((comments) => {
                    const map = new Map<string, CommentAnnotation[]>();
                    (comments ?? []).forEach((comment) => {
                        if (!map.has(comment.location?.id ?? '')) {
                            map.set(comment.location?.id ?? '', [comment])
                        } else {
                            map.get(comment.location?.id ?? '')?.push(comment)
                        }
                    })

                    // Temporary disabled because of many extra table re-renders
                    // setCommentsMap(map)

                    const active = (comments || []).filter((comment) => comment.status.id !== 'RESOLVED')
                    setVeltNotesCount(active.length)
                })

            const subscriptionInsights = commentElement.getAllCommentAnnotations(insightsDocId)
                .subscribe((insights = []) => {
                    const active = (insights || []).filter((insight: CommentAnnotation) => insight.status.id !== 'RESOLVED')
                    setVeltInsightsCount(active.length)
                })

            return () => {
                subscriptionComments.unsubscribe()
                subscriptionInsights.unsubscribe()
            }
        }
    }, [veltClient, notesDocId, insightsDocId])

    const singleReportMode = assetIds?.length === 1

    const veltButton = singleReportMode ? (
        <Layout
            gap={16}
            style={
                singleReportMode ? {
                    marginTop: 6,
                    marginRight: 4,
                } : undefined
            }
        >
            <VeltCommentsSidebar
                pageMode
                onCommentClick={(payload) => {
                    if (payload?.location?.page) {
                        navigate(payload.location.page + (payload.location.search || ''))
                    }
                }}
            />
            <VeltSidebarButton>
                <div slot='button'>
                    <Button
                        onClick={() => {
                            if (insightsDocId && veltClient) {
                                veltClient.setDocumentId(insightsDocId)

                                veltClient.setLocation({})

                                // Reset filters
                                const filters = {
                                    location: [],
                                }
                                const commentElement = veltClient.getCommentElement()
                                commentElement.setCommentSidebarFilters(filters)
                            }
                        }}
                        icon='AiSparkles'
                        text={!insideReport ? 'Insights – ' + veltInsightsCount : undefined}
                        theme={insideReport ? 'flat' : 'secondary'}
                        size={insideReport ? 'l' : 's'}
                    />
                </div>
            </VeltSidebarButton>
            <VeltSidebarButton>
                <div slot='button'>
                    <Button
                        icon='document'
                        theme={insideReport ? 'flat' : 'secondary'}
                        text={!insideReport ? 'Variance Notes - ' + veltNotesCount : veltNotesCount > 0 ? veltNotesCount.toString() : undefined}
                        size={insideReport ? 'l' : 's'}
                        onClick={() => {
                            if (notesDocId) {
                                veltClient.setDocumentId(notesDocId)

                                veltClient.setLocation({})

                                // Reset filters
                                const filters = {
                                    location: [],
                                }
                                const commentElement = veltClient.getCommentElement()
                                commentElement.setCommentSidebarFilters(filters)
                            }
                        }}
                    />
                </div>
            </VeltSidebarButton>
        </Layout>
    ) : null

    const onCellClicked = useCallback((e: CellClickedEvent<ReportingPnLRow>) => {
        const colId = e.column.getColId()
        const timeComparison = colId.match(/^values\.(\w+)\./)?.[1]
        const isClickable = CLICKABLE_TIME_COMPARISONS.includes(timeComparison as TimeComparisonPeriod)
        if (!(isClickable && e.data?.type === ReportingPnLRowType.Account)) {
            return
        }

        const column = columns?.find((c) => c.id === timeComparison)
        const { start_month, end_month } = column ?? {}
        const accountId = e.data.entity_id.split('_')[1]

        setDrillDownParams({
            timeComparison: timeComparison as TimeComparisonPeriod,
            accountName: e.data.name,
            accountCode: e.data.code,
            accountId,
            assetIds,
            startMonth: start_month,
            endMonth: end_month,
            tBLedgerId: tBLedgerId ?? undefined,
        })
    }, [columns, assetIds, tBLedgerId])

    const onModelUpdated = useCallback(() => {
        if (!isLoading && isFetched && isSuccess) {
            onFirstDataRendered()
        }
    }, [isLoading, isFetched, isSuccess, onFirstDataRendered])

    return (
        <>
            <Layout gap={16} flexGrow={1} direction='column'>
                {areFiltersSet
                    ? (
                        // @ts-expect-error TODO: make AgGridTable generic to be able to pass theme
                        <AgGridTable
                            ref={tableRef}
                            items={filteredRows}
                            getRowId={getPnlVarianceTableRowId}
                            {...pnLVarianceTableGridOptions}
                            columnDefs={columnDefs}
                            loading={isLoading || isRefreshing}
                            initialState={gridStateRef.current}
                            lazyModules={rangeSelectionModules}
                            onStateUpdated={(e) => {
                                saveGridStateToRef(e, gridStateRef)
                                checkIfTableIsDirty()
                            }}
                            statusBar={statusBar}
                            defaultExcelExportParams={excelExportParams}
                            getContextMenuItems={getContextMenuItems(() => setShowTableSettingsModal(true))}
                            onGridReady={onGridReady}
                            onCellClicked={onCellClicked}
                            onModelUpdated={onModelUpdated}
                        />
                    )
                    : (<NoDataMessage text='Choose required fields'/>)}
            </Layout>

            {insideReport && veltButton && createPortal(veltButton, reportWrapperButtonsContainer)}

            {(buttonContainer && !insideReport) && createPortal(
                <Layout gap={8}>
                    {veltButton}
                    <CancelSaveButtons
                        onCancel={onCancel}
                        onSave={onTableSave}
                        isLoading={saveMutation.isPending}
                        isDisabled={!isTableDirty || !tableMeta.name}
                    />
                </Layout>,
                buttonContainer,
            )}
            {showTableSettingsModal && (
                <CustomSettingsFilterModal
                    tableId={id}
                    tableRef={tableRef}
                    tableType={TableType.PnLVarianceAnalysis}
                    assetType={assetType ?? undefined}
                    onClose={() => setShowTableSettingsModal(false)}
                />
            )}
            {drillDownParams && (
                <PnLVarianceDrillDownSlideover
                    params={drillDownParams}
                    onClose={() => setDrillDownParams(null)}
                />
            )}
        </>
    )
})
