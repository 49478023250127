import { FilterTreeItem } from '@/components/base'

export const getAllIds = (items: FilterTreeItem[]): Array<string | number> =>
    items.reduce<Array<string | number>>((acc, item) => {
        const helper = (items: FilterTreeItem[]) => {
            items.forEach((item) => {
                if (item.children?.length) {
                    helper(item.children)
                } else {
                    acc.push(item.id)
                }
            })
        }
        helper([item])

        return acc
    }, [])
