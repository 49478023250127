import { useRef } from 'react'

import { AND, EQ } from 'mobx-orm'

import { AgGridReact } from '@ag-grid-community/react'

import { useReportingRentRollDrillDownQuery } from '@/api/reportingData/reportingRentRollDrillDown'
import { Button, SideModalMulti, TableSearchInput } from '@/components/base'
import styles from '@/components/base/SideModalMulti/SideModalMulti.module.scss'
import { Layout } from '@/components/containers'
import { AgGridTable } from '@/components/tables'
import { monthIndToString } from '@/utils/date/monthInd'

import { SideDrilldownTitle } from '../../sideDrilldownComponents/SideDrilldownTitle'

import { rRDetailDrillDownGridOptions } from './RRDetailDrillDownSlideover.constants'
import { RRDetailDrillDownSlideoverProps } from './RRDetailDrillDownSlideover.types'

export const RRDetailDrillDownSlideover = (props: RRDetailDrillDownSlideoverProps) => {
    const { params, onClose } = props

    const {
        assetIds,
        month,
        rRLedgerId,
        column,
        contractObjectId,
        unit,
        codeName,
    } = params || {}

    const tableRef = useRef<AgGridReact>(null)
    const { data, isLoading } = useReportingRentRollDrillDownQuery(
        {
            filter: AND(
                EQ('asset_ids', assetIds),
                EQ('month', month),
                EQ('rent_roll_ledger_id', rRLedgerId),
                EQ('column', column),
                EQ('contract_object_id', contractObjectId),
            ),
        },
        { enabled: !!params },
    )

    return (
        <SideModalMulti
            width={720}
            onBgClick={onClose}
        >
            <Layout direction='column' gap={32} flexGrow={1}>
                <Layout
                    justify='space-between'
                    gap={16}
                    mt={20}
                    mr={20}
                    ml={32}
                >
                    <SideDrilldownTitle
                        title={`Unit ${unit ?? ''}`}
                        subtitle={`${codeName ?? ''} ${month ? monthIndToString(month) : ''}`}
                    />
                    <Button
                        className={styles.closeButton}
                        icon='cross'
                        theme='secondary'
                        onClick={onClose}
                    />
                </Layout>
                <Layout
                    direction='column'
                    flexGrow={1}
                    gap={32}
                    mr={28}
                    mb={20}
                    ml={32}
                >
                    <TableSearchInput tableRef={tableRef}/>
                    <Layout direction='column' flexGrow={1}>
                        {/* @ts-expect-error TODO: make AgGridTable generic to be able to pass theme */}
                        <AgGridTable
                            ref={tableRef}
                            items={data ?? []}
                            loading={isLoading}
                            hideStatusBar
                            noBorders
                            {...rRDetailDrillDownGridOptions}
                        />
                    </Layout>
                </Layout>
            </Layout>
        </SideModalMulti>
    )
}
