import React from 'react'

import cn from 'classnames'

import { TreeLevelIndentProps } from './TreeLevelIndent.types'

import styles from './TreeLevelIndent.module.scss'

export const TreeLevelIndent: React.FC<TreeLevelIndentProps> = (props: TreeLevelIndentProps) => {
    const { level, activeLevel, isActivePath, focused } = props

    const shouldShowLine = level === activeLevel && isActivePath

    return (
        <div
            className={cn(styles.treeLevelIndent, {
                [styles.treeLevelIndent_active]: shouldShowLine && !focused,
                [styles.treeLevelIndent_focused]: focused && shouldShowLine,
            })}
        />
    )
}
