import { AssetType } from '@/models/core'

export enum ReportMetrics {
    UNIT = '/Unit',
    PSF = 'PSF',
}

export const REPORT_METRICS_BY_ASSET_TYPE: Record<AssetType, ReportMetrics> = {
    M: ReportMetrics.UNIT,
    O: ReportMetrics.PSF,
    I: ReportMetrics.PSF,
    R: ReportMetrics.PSF,
    H: ReportMetrics.UNIT,
    S: ReportMetrics.UNIT,
}
