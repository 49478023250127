import { CellClassParams, ColDef, ExcelRow, ValueGetterParams } from '@ag-grid-community/core'

import { ReportingPortfolioSelectedValueItem, ReportingPortfolioSelectedValueRow } from '@/api/reportingData/reportingPortfolioSelectedValue'
import { AgGridTableProps, DEFAULT_COL_TYPES } from '@/components/tables'
import { ReportingDataValueType } from '@/constants/reportingDataValueTypes'
import { ReportScenario } from '@/constants/reportScenario'
import { excelStyles } from '@/constants/reportTable'
import { Asset } from '@/models/asset'
import { generatedColors } from '@/styles/tokens/Tokens.constants'

const colTypeByValueType: Record<`${ReportingDataValueType}`, keyof typeof DEFAULT_COL_TYPES> = {
    M: 'money',
    S: 'string',
    N: 'money',
    P: 'percentageFraction',
    D: 'localDate',
}

const cellStyle = (params: CellClassParams<ReportingPortfolioSelectedValueRow>) => {
    //  style for right border
    const borderRightColor = generatedColors.colorsSecondaryGrey100
    const { column, context } = params

    if (context?.editable) {
        return null
    }

    const children = column.getParent()?.getChildren() ?? []
    const lastCol = children[children.length - 1]

    return lastCol === column ? { borderRightColor } : null
}

export const getPortfolioSelectedValueColDefs = (
    { columns, subheaders, valueType }:
    {
        columns: ReportingPortfolioSelectedValueItem['columns']
        subheaders: ReportingPortfolioSelectedValueItem['subheader']
        valueType: ReportingPortfolioSelectedValueItem['value_type']
    },
): ColDef[] => {
    if (!columns.length) {
        return []
    }

    return [
        {
            field: 'name',
            headerName: 'Asset',
            pinned: 'left',
            lockPosition: 'left',
            cellClass: 'locked-col',
            minWidth: 220,
            filterParams: {
                newRowsAction: 'keep',
            },
            sortable: false,
            suppressMovable: true,
            type: 'string',
        },
        {
            field: 'units',
            headerName: 'Units',
            pinned: 'left',
            type: 'money',
            headerClass: ['ag-right-aligned-header', 'rightBorder1'],
            cellClass: ['ag-right-aligned-cell', 'excelCurrency'],
            width: 98,
            valueGetter: (params: ValueGetterParams<ReportingPortfolioSelectedValueRow>) => params?.data?.units ?? 0,
        },
        {
            field: 'sqft',
            headerName: 'Unit SF',
            pinned: 'left',
            type: 'money',
            headerClass: ['ag-right-aligned-header', 'rightBorder1'],
            cellClass: ['ag-right-aligned-cell', 'excelCurrency'],
            width: 98,
            valueGetter: (params: ValueGetterParams<ReportingPortfolioSelectedValueRow>) => params?.data?.sqft ?? 0,
        },
        ...columns.map(({ id, name }) => ({
            headerName: name,
            headerClass: ['rightBorder1'],
            lockPinned: true,
            children: subheaders.map(({ key, name }) => ({
                headerName: name,
                headerClass: ['ag-right-aligned-header', 'rightBorder1'],
                field: `values.${id}.${key}`,
                minWidth: 98,
                cellStyle,
                lockPinned: true,
                type: key === ReportScenario.VARIANCE_PERCENT ? colTypeByValueType.P : colTypeByValueType[valueType],
                cellClass: ['ag-right-aligned-cell', key === ReportScenario.VARIANCE_PERCENT ? 'excelPercentageFraction' : 'excelCurrency'],
                valueGetter: (params: ValueGetterParams<ReportingPortfolioSelectedValueRow>) => params?.data?.values[id]?.[key] ?? 0,
            })),
        })),
    ]
}

export const reportingPSVTableGridOptions: Omit<AgGridTableProps, 'items' | 'columnDefs'> = {
    defaultColDef: {
        sortable: false,
        suppressMovable: false,
    },
    sideBar: {
        toolPanels: [
            {
                id: 'columns',
                labelDefault: 'Columns',
                labelKey: 'columns',
                iconKey: 'columns',
                toolPanel: 'agColumnsToolPanel',
                toolPanelParams: {
                    suppressPivotMode: true,
                    suppressRowGroups: true,
                    suppressValues: true,
                },
            },
        ],
        defaultToolPanel: 'columns',
    },
    getRowId: ({ data }: { data: ReportingPortfolioSelectedValueRow }) => data.id?.toString(),
    suppressCellFocus: true,
    suppressFitToWidth: true,
    suppressAutoResize: true,
    suppressRowDrag: true,
    suppressMovableColumns: false,
    maintainColumnOrder: true,
    excelStyles,
}

export const getPortfolioSelectedValueTableExportMeta = (
    assets: Asset[] | undefined,
    assetIds: Array<number | null> | null | undefined,
    period?: string,
): ExcelRow[] => {
    const assetIdsSet = new Set(assetIds)
    return [
        { cells: [] },
        {
            cells: [
                {
                    data: {
                        value: `Assets: ${(assets ?? []).filter(a => assetIdsSet.has(a.id ?? 0)).map(a => a.name).join(', ')}`,
                        type: 'String',
                    },
                },
            ],
        },
        {
            cells: [
                {
                    data: {
                        value: `Time period: ${period}`,
                        type: 'String',
                    },
                },
            ],
        },
        { cells: [] },
    ]
}
