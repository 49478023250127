import { CellClassParams, IAggFunc, IAggFuncParams, ICellRendererParams } from '@ag-grid-community/core'

import { CustomCellRendererProps } from '@ag-grid-community/react'

import {
    ReportingPortfolioMetricsAggregation,
    ReportingPortfolioMetricsItemColumn,
    ReportingPortfolioMetricsItemRow,
} from '@/api/reportingData/reportingPortfolioMetrics'
import { Text } from '@/components/base'
import { Feb25Classes, Feb25ColDef, Feb25ColGroupDef } from '@/components/tables/AgGridTable/themes/feb25/feb25.types'
import { ASSET_DETAIL_LABELS, AssetDetails } from '@/constants/assetDetails'
import { ReportingDataValueType } from '@/constants/reportingDataValueTypes'
import { ReportScenario } from '@/constants/reportScenario'
import { ExcelClasses, getExcelGroupClasses } from '@/constants/reportTable'
import { ASSET_TYPE_LABEL, AssetType } from '@/models/core'

import { ConditionalFormatRule } from '../../ConditionalRuleEditor/ConditionalRuleEditor.types'
import {
    formatterByValueType,
    getAggFuncWithIntermediateCalcs,
    getWeightedAvgAggregation,
} from '../TableBuilderTables.utils'

import { cellRenderStyledFromConditionalFormatRules } from './MetricSideBySideTable.utils'

const totalRenderer = (params: IAggFuncParams) => {
    const visibleColumns = params.api.getAllDisplayedColumns()
    const isFirstVisible = visibleColumns.length > 0 && visibleColumns[0] === params.column
    return isFirstVisible ? 'Total / Average' : ''
}

const aggFuncs = (weightCol: string): Partial<Record<ReportingPortfolioMetricsAggregation, string | IAggFunc>> => ({
    W: getWeightedAvgAggregation(weightCol),
    A: 'avg',
    S: 'sum',
})

const minMaxWidthByColId: Record<AssetDetails, [number, number]> = {
    [AssetDetails.NAME]: [100, 300],
    [AssetDetails.CITY]: [100, 300],
    [AssetDetails.STATE]: [50, 150],
    [AssetDetails.ASSET_CLASS]: [100, 200],
    [AssetDetails.MANAGER_COMPANY]: [120, 300],
    [AssetDetails.FUND]: [120, 300],
    [AssetDetails.UNITS]: [80, 100],
    [AssetDetails.SQUARE_FEET]: [80, 150],
    [AssetDetails.CUSTOM_ID1]: [100, 300],
    [AssetDetails.CUSTOM_ID2]: [100, 300],
    [AssetDetails.ASSET_MANAGER]: [100, 300],
    [AssetDetails.SOFTWARE]: [100, 300],
}

const colTypeByMetricType: Record<ReportingDataValueType, string> = {
    [ReportingDataValueType.MONEY]: 'money',
    [ReportingDataValueType.STRING]: 'string',
    [ReportingDataValueType.NUMBER]: 'string',
    [ReportingDataValueType.PERCENTAGE]: 'percentage',
    [ReportingDataValueType.DATE]: 'string',
}

const totalCellRenderer = (props: CustomCellRendererProps) => {
    const footer = props.node.footer
    const isRootLevel = props.node.level === -1

    if (footer) {
        if (isRootLevel) {
            return 'Total / Average'
        } else {
            return `${props.node.key} Total`
        }
    } else {
        return props.node.key
    }
}

const getGroupColumnDef = (groupColId: AssetDetails): Feb25ColDef => ({
    headerName: ASSET_DETAIL_LABELS[groupColId],
    headerTooltip: ASSET_DETAIL_LABELS[groupColId],
    headerClass: [Feb25Classes.HeaderL2],
    showRowGroup: ASSET_DETAIL_ID_TO_FIELD[groupColId],
    cellRenderer: 'agGroupCellRenderer',
    cellRendererParams: {
        suppressCount: true,
        innerRenderer: totalCellRenderer,
    },
    minWidth: minMaxWidthByColId[groupColId][0],
    maxWidth: minMaxWidthByColId[groupColId][1],
    lockPosition: 'left',
    pinned: true,
    type: 'string',
    cellClass: (params: CellClassParams) => [Feb25Classes.HighlightTotalCell, ...getExcelGroupClasses(params)],
    chartDataType: 'category',
})

export const ASSET_DETAIL_ID_TO_FIELD: Record<AssetDetails, keyof ReportingPortfolioMetricsItemRow> = {
    [AssetDetails.NAME]: 'name',
    [AssetDetails.CITY]: 'city',
    [AssetDetails.STATE]: 'state',
    [AssetDetails.ASSET_CLASS]: 'type',
    [AssetDetails.MANAGER_COMPANY]: 'property_manager_company',
    [AssetDetails.FUND]: 'fund_name',
    [AssetDetails.UNITS]: 'units_count',
    [AssetDetails.SQUARE_FEET]: 'square_feet',
    [AssetDetails.CUSTOM_ID1]: 'custom_id1',
    [AssetDetails.CUSTOM_ID2]: 'custom_id2',
    [AssetDetails.SOFTWARE]: 'software',
    [AssetDetails.ASSET_MANAGER]: 'asset_manager_name',
}

export const getMetricSbsColDefs = ({
    conditionalFormatRules = [],
    columns,
    tableTitle,
    assetDetails = [],
    groupBy,
    assetType,
}: {
    conditionalFormatRules?: ConditionalFormatRule[]
    columns: ReportingPortfolioMetricsItemColumn[]
    tableTitle: string
    assetDetails?: AssetDetails[] | null
    groupBy?: AssetDetails[] | null
    assetType?: AssetType | null
}): Feb25ColGroupDef[] => {
    if (!assetType || !Array.isArray(assetDetails)) return []
    const groupColId = groupBy?.[0]

    // Put Units and Square Feet cols last & Asset Name first
    const sortedAssetDetails = assetDetails?.sort((a, b) => {
        if (a === AssetDetails.NAME) {
            return -1
        }
        if (b === AssetDetails.NAME) {
            return 1
        }
        if (a === AssetDetails.UNITS && b === AssetDetails.SQUARE_FEET) {
            return -1
        }
        if (a === AssetDetails.SQUARE_FEET && b === AssetDetails.UNITS) {
            return 1
        }
        if (a === AssetDetails.UNITS || a === AssetDetails.SQUARE_FEET) {
            return 1
        }
        if (b === AssetDetails.UNITS || b === AssetDetails.SQUARE_FEET) {
            return -1
        }
        return 0
    })

    // Add group column and required details column ids
    const detailsColIds: AssetDetails[] = []
    if (groupColId && !assetDetails?.includes(groupColId)) {
        detailsColIds.push(groupColId)
    }
    if (!assetDetails?.includes(AssetDetails.UNITS)) {
        detailsColIds.push(AssetDetails.UNITS)
    }
    if (!assetDetails?.includes(AssetDetails.SQUARE_FEET)) {
        detailsColIds.push(AssetDetails.SQUARE_FEET)
    }
    // then selected details
    detailsColIds.push(...(sortedAssetDetails ?? []))

    // ColDefs: Push group column defs first
    const assetDetailsColumns = groupColId ? [getGroupColumnDef(groupColId)] : []
    // then push coldefs for other details
    assetDetailsColumns.push(
        ...detailsColIds.map((detailId, i): Feb25ColDef => {
            const isNumericValueCol = detailId === AssetDetails.UNITS || detailId === AssetDetails.SQUARE_FEET
            const rightBorderClass = i === detailsColIds.length - 1 ? Feb25Classes.RightBorder1 : Feb25Classes.None
            const rightAlignedHeaderClass = isNumericValueCol ? Feb25Classes.RightAlignedHeader : Feb25Classes.None
            const rightAlignedCellClass = isNumericValueCol ? [Feb25Classes.RightAlignedCell, ExcelClasses.Currency] : [Feb25Classes.None]

            // Generated pinned cols on the left side
            return ({
                headerName: ASSET_DETAIL_LABELS[detailId],
                headerTooltip: ASSET_DETAIL_LABELS[detailId],
                headerClass: [rightBorderClass, rightAlignedHeaderClass, Feb25Classes.HeaderL2],
                cellClass: (params: CellClassParams) => [rightBorderClass, Feb25Classes.HighlightTotalCell, ...rightAlignedCellClass, ...getExcelGroupClasses(params)],
                field: ASSET_DETAIL_ID_TO_FIELD[detailId],
                valueGetter: detailId === AssetDetails.ASSET_CLASS
                    ? (params) => ASSET_TYPE_LABEL.get(params?.data?.type) ?? ''
                    : isNumericValueCol
                        ? (params) => params?.data?.[params.column.getColId()] ?? 0 // replace null / undefined with 0 in excel export
                        : undefined,
                aggFunc: isNumericValueCol ? getAggFuncWithIntermediateCalcs('sum') : totalRenderer,
                // Hide column that used for groups and columns that are not selected
                hide: detailId === groupColId || !assetDetails?.includes(detailId),
                rowGroup: groupColId === detailId,
                minWidth: minMaxWidthByColId[detailId][0],
                maxWidth: minMaxWidthByColId[detailId][1],
                pinned: true,
                type: isNumericValueCol ? 'money' : 'string',
                filter: isNumericValueCol ? 'agNumberColumnFilter' : 'agSetColumnFilter',
                chartDataType: 'excluded',
            })
        }),
    )

    const subheaderComponent = ({ metric, scenario }) => (
        <div style={{ marginLeft: 'auto' }}>
            <Text
                style={{ textAlign: 'right' }}
                maxLines={1}
                text={metric}
                block
            />
            <Text
                style={{ textAlign: 'right' }}
                maxLines={1}
                text={scenario}
            />
        </div>
    )

    const colDefs: Feb25ColGroupDef[] = [
        {
            // Asset Details section
            headerName: tableTitle,
            headerTooltip: tableTitle,
            children: assetDetailsColumns,
            headerClass: [Feb25Classes.RightBorder1, Feb25Classes.HeaderL2],
            marryChildren: true,
        },
        {
            headerClass: [Feb25Classes.HeaderL2],
            // Time series data section
            children: [
                ...columns.flatMap(({ id, name, value_type, scenario, aggregation }) => {
                    const headerText = name + ', ' + scenario
                    const field = `values.${id}`
                    const isPSFCol = id.endsWith('psf')
                    const isPSFAsset = assetType !== AssetType.MULTIFAMILY
                    const weightCol = assetType === AssetType.MULTIFAMILY
                        ? 'units_count'
                        : assetType === AssetType.OFFICE
                            ? 'square_feet'
                            // Default value
                            : 'square_feet'

                    const dataAggFunc = aggFuncs(weightCol)[aggregation]
                    const isPercentageVal = value_type === ReportingDataValueType.PERCENTAGE
                    const excelTypeClass = isPercentageVal ? ExcelClasses.Percentage1Decimal : ExcelClasses.Currency

                    const dataCol: Feb25ColDef = {
                        suppressMovable: false,
                        headerTooltip: headerText,
                        headerComponent: subheaderComponent,
                        // Need it for cell action "Copy with header"
                        headerName: headerText,
                        autoHeaderHeight: true, // To adopt for two lines
                        headerComponentParams: {
                            metric: name,
                            scenario,
                        },
                        headerClass: [Feb25Classes.RightAlignedHeader, Feb25Classes.HeaderL2],
                        cellClass: (params: CellClassParams) => [Feb25Classes.RightAlignedCell, Feb25Classes.HighlightTotalCell, excelTypeClass, ...getExcelGroupClasses(params)],
                        field,
                        flex: 1,
                        maxWidth: 300,
                        aggFunc: dataAggFunc,
                        type: colTypeByMetricType[value_type],
                        filter: 'agNumberColumnFilter',
                        valueGetter: (params) => {
                            let val = params?.data?.values?.[id] ?? 0

                            if (isPercentageVal) {
                                val = val * 100
                            }

                            return val
                        },
                        chartDataType: 'series',
                        valueFormatter: (params) => formatterByValueType(value_type, params),
                        cellRenderer: (params: ICellRendererParams) => cellRenderStyledFromConditionalFormatRules(conditionalFormatRules, params, params?.data?.values?.[id] ?? 0, id, columns),
                        fractionDigits: isPSFCol && isPSFAsset ? 2 : undefined,
                    }

                    // add hidden budget column for variance percent calculation
                    if (isPercentageVal) {
                        return [
                            {
                                field: `values.${id}.${ReportScenario.BUDGET}`,
                                aggFunc: getAggFuncWithIntermediateCalcs('sum'),
                                hide: true,
                            },
                            {
                                ...dataCol,
                                fractionDigits: 1,
                            },
                        ]
                    } else {
                        return [dataCol]
                    }
                }),
                {
                    // empty column to fill free space on the right
                    headerClass: [Feb25Classes.RightBorder1, Feb25Classes.HeaderL2],
                    cellClass: [Feb25Classes.RightBorder1, Feb25Classes.HighlightTotalCell],
                    flex: 1,
                    sortable: false,
                    minWidth: 12,
                },
            ],
        },
    ]

    return colDefs
}
