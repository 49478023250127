import React from 'react'

import { useParams } from 'react-router'

import { useReportingReportByIdQuery } from '@/api/reportingData/reportingReport'
import { ReportingTableItem, useReportingTableQuery } from '@/api/reportingTable/reportingTable'
import { SideModalOptions } from '@/components/base'

import { ReportTableSelectProps } from './ReportTableSelect.types'

export const ReportTableSelect = (props: ReportTableSelectProps<ReportingTableItem>) => {
    const { onClose, onSave, multiSelect } = props

    const { id: reportId } = useParams()

    const reportingTableQuery = useReportingTableQuery()
    const reportingReportByIdQuery = useReportingReportByIdQuery(reportId)

    const reportTableIds = new Set(reportingReportByIdQuery.data?.items.map(item => item.table_id) ?? [])
    const selectedTables = reportingTableQuery.data?.filter(table => reportTableIds.has(table.id)) ?? []

    return (
        <SideModalOptions<ReportingTableItem>
            options={reportingTableQuery.data ?? []}
            selected={selectedTables}
            onClose={onClose}
            onSave={onSave}
            hideSelectAll
            multiSelect={multiSelect}
            labelFn={table => table.name}
            idFn={table => table.id.toString()}
        />
    )
}
