import React, { useEffect, useRef, useState } from 'react'

import { createPortal } from 'react-dom'

import { Button, Popover, Tabs } from '@/components/base'
import { SelectOptionListBase } from '@/components/base/Select/optionsList'
import { Layout } from '@/components/containers'
import { SUBMENU_CONTAINER_ID } from '@/constants/ids'
import { usePortal } from '@/hooks'

import { SubMenuPopoverItem, SubMenuProps } from './SubMenu.types'

import styles from './SubMenu.module.scss'

export const SubMenu = (props: SubMenuProps) => {
    const {
        children,
        navItems,
        activeNavItem,
        onChangeTab,
        headerButtonActions,
    } = props

    const container = usePortal(SUBMENU_CONTAINER_ID)
    const [headerPopoverAnchorEl, setHeaderPopoverAnchorEl] = useState<HTMLElement | null>(null)
    const headerButtonRef = useRef<HTMLButtonElement>(null)

    useEffect(() => {
        const isTabExist = navItems.has(activeNavItem ?? '')
        const newActiveTab = isTabExist ? activeNavItem : navItems.keys().next().value

        onChangeTab?.(newActiveTab)

        if (!isTabExist) {
            onChangeTab?.(newActiveTab)
        }
    }, [activeNavItem])

    const handleTabChange = (key: string) => {
        // eslint-disable-next-line no-console -- Delete after 20 jul
        console.log('Tab change', key)
        onChangeTab?.(key)
    }

    if (!container) {
        console.error('Can\'t find portal container')
        return null
    }

    const content = (
        <Layout
            width='270px'
            className={styles.subMenu}
            direction='column'
        >
            <Layout
                gap={8}
                direction='column'
                px={16}
                className={styles.header}
            >
                <Layout
                    pt={16}
                    justify='space-between'
                    align='center'
                >
                    <Popover
                        maxWidth={160}
                        anchorEl={headerPopoverAnchorEl}
                        placement='right-start'
                        onClose={() => { setHeaderPopoverAnchorEl(null) }}
                    >
                        <SelectOptionListBase<SubMenuPopoverItem>
                            maxWidth='160px'
                            options={headerButtonActions ?? []}
                            idFn={(el) => el.label}
                            labelFn={el => el.label}
                            onChange={(item) => {
                                setHeaderPopoverAnchorEl(null)
                                item.onClick(item)
                            }}
                        />
                    </Popover>
                </Layout>
                <Layout align='flex-start' gap={8} justify='space-between'>
                    <Tabs
                        options={navItems}
                        onChange={handleTabChange}
                        value={activeNavItem}
                        noTopPadding
                        noBorderBottom
                        px={16}
                    />
                    {Boolean(headerButtonActions?.length) && (
                        <Button
                            icon='plus'
                            size='xs'
                            onClick={() => {
                                setHeaderPopoverAnchorEl(headerButtonRef.current)
                            }}
                            ref={headerButtonRef}
                        />
                    )}
                </Layout>
            </Layout>
            <Layout
                width='100%'
                direction='column'
                py={20}
                px={16}
                style={{
                    overflowY: 'auto',
                }}
            >
                {children}
            </Layout>
        </Layout>
    )

    return createPortal(
        content,
        container,
    )
}
