import { createContext, forwardRef, RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { ColumnState, GridReadyEvent, Module } from '@ag-grid-community/core'
import cn from 'classnames'

import { AgGridReact } from '@ag-grid-community/react'

import { modifyColDefItem } from '@/components/tables/AgGridTable/AgGridTable.utils'
import { AgGridTableLoading } from '@/components/tables/AgGridTable/components/AgGridTableLoading'
import { AgGridTableNoRows } from '@/components/tables/AgGridTable/components/AgGridTableNoRows'
import { AgGridPaginationLeftPanel } from '@/components/tables/AgGridTable/pagination/AgGridPaginationLeftPanel'
import {
    AgGridTablePaginationRightPanel,
} from '@/components/tables/AgGridTable/pagination/AgGridTablePaginationRightPanel'
import { useTableModules } from '@/components/tables/utils/useTableModules'
import { useInputState } from '@/hooks'
import { lazyWithCatch } from '@/utils/lazyCatch'
import { getSortString, parseSortString } from '@/utils/models/sortString'

import {
    DEFAULT_COL_TYPES,
    DEFAULT_COL_DEF,
    DEFAULT_TABLE_OFFSET_QUERY_PARAM_KEY,
    DEFAULT_TABLE_LIMIT_QUERY_PARAM_KEY, TABLE_SORT_QUERY_PARAM,
} from './AgGridTable.constants'
import { AgGridTableProps, AgGridTableTheme } from './AgGridTable.types'

import styles from './AgGridTable.module.scss'
import feb25 from './themes/feb25/feb25.module.scss'
import feb25Details from './themes/feb25Details/feb25Details.module.scss'
import neo from './themes/neo/neo.module.scss'
import standard from './themes/standard.module.scss'
import variance from './themes/variance.module.scss'
import '@ag-grid-community/styles/ag-grid.css'
import '@ag-grid-community/styles/ag-theme-alpine.css'
import '@ag-grid-community/styles/agGridMaterialFont.css'

const AgGridReactLazy = lazyWithCatch(() => import('@/components/tables/AgGridTable/exports/agGridReact'))

const addHeaderTooltip = (items) => {
    items.forEach((col) => {
        if (col.headerName?.length) {
            col.headerTooltip = col.headerName
        }

        if (col.children?.length) {
            addHeaderTooltip(col.children)
        }
    })
}

const tableThemeMap: Record<AgGridTableTheme, string> = {
    standard: cn('ag-theme-alpine', standard.table),
    variance: cn('ag-theme-alpine', variance.table),
    neo: cn('ag-theme-alpine', neo.table),
    feb25: cn('ag-theme-alpine', feb25.table),
    feb25Details: cn('ag-theme-alpine', feb25Details.table),
}

// Need this for avoid recreating bottom elements after getting async data
export const TableContext = createContext<{
    totalItemsCount?: number
    itemsCount: number
    customOffsetQueryParamKey?: string
    customLimitQueryParamKey?: string
}>({
    totalItemsCount: undefined,
    itemsCount: 0,
    customOffsetQueryParamKey: undefined,
    customLimitQueryParamKey: undefined,
})

/**
 * Base Table component
 * For 'mobx-orm' use 'AgGridTableLegacy'
 *
 * Pagination
 * - You need to pass 'totalItemsCount' for turn on pagination
 *   - This count can be found in '[modelName]QueryCount' response
 *
 * @fixme: Tooltip for header text cells
 * @todo: 'Type' for each column must be required
 * @todo: Sorting callback
 * @todo: Cleanup table from mobx-orm connections
 * @todo: Reset page automatically if user doing search and this page is not available
 * @todo: Use 'useInputState' for table query params and make it more convenient than now
 */
export const AgGridTable = forwardRef((props: AgGridTableProps, ref: RefObject<AgGridReact>) => {
    const {
        items,
        loading,
        error,
        theme = 'standard',
        noBorders = false,
        criticalBordersOnly = false,
        hideStatusBar = false,
        noRowsText = 'No Items To Show',
        columnTypes,
        defaultColDef,
        defaultSorting,
        suppressFitToWidth,
        suppressAutoResize,
        fitToWidthConfig,
        columnDefs,
        totalItemsCount,
        customOffsetQueryParamKey = DEFAULT_TABLE_OFFSET_QUERY_PARAM_KEY,
        customLimitQueryParamKey = DEFAULT_TABLE_LIMIT_QUERY_PARAM_KEY,
        lazyModules,
        className,
        statusBar,
        scrollBarOnTop = false,
        ...agGridOptions
    } = props

    const [tableSortParam, setTableSortQueryParam] = useInputState(TABLE_SORT_QUERY_PARAM)

    const fallbackRef = useRef<AgGridReact>(null)

    const [isTableReady, setIsTableReady] = useState(false)

    const defaultModules = useTableModules()

    const [tableModules, setTableModules] = useState<Module[]>([])

    useEffect(() => {
        (async () => {
            setTableModules([...(await defaultModules), ...(await (lazyModules ?? []))])
        })()
    }, [defaultModules, lazyModules])

    ref ||= fallbackRef

    const resizeColumns = () => {
        setTimeout(() => {
            ref.current?.api?.sizeColumnsToFit(fitToWidthConfig)
        })
    }

    const setInitialSorting = useCallback(() => {
        let sortingConfig: ColumnState[] | null = null

        if (defaultSorting) {
            sortingConfig = [parseSortString(defaultSorting)]
        } else if (tableSortParam?.length) {
            sortingConfig = tableSortParam.map(param => parseSortString(param ?? ''))
        }

        if (sortingConfig) {
            ref?.current?.api.applyColumnState({
                state: sortingConfig,
                defaultState: { sort: null },
            })
        }
    }, [ref, defaultSorting, tableSortParam])

    const handleGridReady = (params: GridReadyEvent) => {
        props.onGridReady?.(params)

        if (!suppressFitToWidth) { resizeColumns() }

        addHeaderTooltip(columnDefs)
        setInitialSorting()
        setIsTableReady(true)
    }

    useEffect(() => {
        if (!suppressAutoResize) {
            window.addEventListener('resize', resizeColumns)
            return () => { window.removeEventListener('resize', resizeColumns) }
        }
    }, [])

    const hasRowData = Boolean(items.length)

    useEffect(() => {
        if (!isTableReady) {
            return
        }

        if (!suppressAutoResize) {
            resizeColumns()
        }

        // REFACTOR: Fix this timeout
        // Without this hack with setTimeout overlay sometimes not shown
        setTimeout(() => {
            // Hide overlays
            ref.current?.api.hideOverlay()

            // Error overlay
            if (error) {
                ref.current?.api.showNoRowsOverlay()
                return
            }

            // Loading overlay
            if (loading) {
                ref.current?.api.showLoadingOverlay()
                return
            }

            // No data overlay
            if (!hasRowData) {
                ref.current?.api.showNoRowsOverlay()
            }
        }, 100)
    }, [loading, isTableReady, hasRowData, error])

    const classNames = cn(tableThemeMap[theme], className, {
        [standard.noBorders]: noBorders,
        [standard.criticalBordersOnly]: criticalBordersOnly,
        [styles.scrollBarOnTop]: scrollBarOnTop,
    })

    const rowStyle = agGridOptions.rowStyle || {}

    const defaultStatusBar = useMemo(() => ({
        statusPanels: [
            {
                statusPanel: AgGridPaginationLeftPanel,
                align: 'left',
            },
            {
                statusPanel: AgGridTablePaginationRightPanel,
                align: 'right',
            },
        ],
    }), [])

    const agGridProps: Omit<AgGridTableProps, 'query' | 'items'> & { ref: RefObject<AgGridReact> } = {
        ...agGridOptions,
        rowStyle: agGridOptions.onRowClicked ? {
            cursor: 'pointer',
            ...rowStyle,
        } : rowStyle,
        columnDefs: columnDefs.map(modifyColDefItem),
        ref,
        loading,
        tooltipShowDelay: 0,
        className: classNames,
        rowData: (error || loading) ? [] : items, // Rows hidded if error layout shown or query is not ready
        onGridReady: handleGridReady,
        columnTypes: {
            ...DEFAULT_COL_TYPES,
            ...columnTypes,
        },
        defaultColDef: {
            ...DEFAULT_COL_DEF,
            ...defaultColDef,
        },
        loadingOverlayComponent: AgGridTableLoading,
        noRowsOverlayComponent: AgGridTableNoRows,
        noRowsOverlayComponentParams: {
            noRowsMessageFunc: () => error ? 'Data Loading Error' : noRowsText,
        },
        modules: [...tableModules],
        statusBar: hideStatusBar ? undefined : statusBar || defaultStatusBar,
        /**
         * Manage query param related to table sort
          */
        onSortChanged: (sortModel) => {
            const sortBy = sortModel.columns?.filter((col) => Boolean(col.getSort()))
                .map(config => getSortString(config.getColId(), config.getSort() === 'desc'))
            setTableSortQueryParam(sortBy)
        },
    }

    if (!suppressAutoResize) {
        agGridProps.onRowGroupOpened = resizeColumns
    }

    if (!tableModules.length) {
        return null
    }

    return (
        <TableContext.Provider
            value={{
                totalItemsCount: totalItemsCount ?? 0,
                itemsCount: items.length,
                customOffsetQueryParamKey,
                customLimitQueryParamKey,
            }}
        >
            <AgGridReactLazy {...agGridProps}>
                {props.children}
            </AgGridReactLazy>
        </TableContext.Provider>
    )
})
