import { createEnumParam } from 'use-query-params'

import {
    QUARTER_INPUT_QUERY_PARAM, QUARTER_RANGE_END_QUERY_PARAM,
    QUARTER_RANGE_START_QUERY_PARAM,
    SCENARIO_INPUT_QUERY_PARAM,
    TIME_COMPARISON_INPUT_QUERY_PARAM,
    TIME_INTERVAL_INPUT_QUERY_PARAM,
    TIME_SETTINGS_INPUT_QUERY_PARAM,
    YEAR_INPUT_QUERY_PARAM,
    YEAR_RANGE_END_QUERY_PARAM,
    YEAR_RANGE_START_QUERY_PARAM,
} from '@/components/baseInputs'
import { ASSET_DETAILS_QUERY_PARAM } from '@/components/models/asset'
import { LEDGER_SELECT_INPUT_QUERY_PARAM } from '@/components/models/ledger'
import { UseInputStateParams } from '@/hooks'
import { AssetType } from '@/models/core'
import { getReportPrefix } from '@/pages/TablesPage/TableBuilderPage/TableBuilder/TableBuilder.utils'
import {
    ACCOUNT_CODES_INPUT_QUERY_PARAM,
    BALANCE_SHEET_SCENARIO_INPUT_QUERY_PARAM,
    EMPTY_ROWS_INPUT_QUERY_PARAM,
    GROUP_BY_ASSET_DETAIL_INPUT_QUERY_PARAM,
    GROUP_BY_RR_FIELD_INPUT_QUERY_PARAM,
    METRIC_INPUT_QUERY_PARAM,
    PIN_TOTAL_COL_INPUT_QUERY_PARAM,
    PNL_METRIC_INPUT_QUERY_PARAM,
    RP_METRIC_INPUT_QUERY_PARAM,
    TABLE_ID_ROWS_INPUT_QUERY_PARAM, TOTAL_ROW_INPUT_QUERY_PARAM, UNDERWRITING_DATE_INPUT_QUERY_PARAM,
} from '@/pages/TablesPage/TableBuilderPage/TableBuilderFilters'

import { TableType } from '../tableType'

export const TABLE_BUILDER_EDIT_PAGE_HEADER_BUTTONS = 'TABLE_BUILDER_EDIT_PAGE_HEADER_BUTTONS'
export const TABLE_BUILDER_EDIT_PAGE_HEADER_SPACER_INPUT = 'TABLE_BUILDER_EDIT_PAGE_HEADER_SPACER_INPUT'

export const TABLE_TYPE_INPUT_QUERY_PARAM = {
    name: 'table-type',
    type: createEnumParam(Object.values(TableType)),
}

export const TABLE_ASSET_TYPE_PARAM = {
    name: 't-build-asset-type',
    type: createEnumParam(Object.values(AssetType)),
}

export const TABLE_TB_LEDGER_INPUT_QUERY_PARAM = {
    name: 'tb-ledger',
    type: LEDGER_SELECT_INPUT_QUERY_PARAM.type,
}

export const TABLE_RR_LEDGER_INPUT_QUERY_PARAM = {
    name: 'rr-ledger',
    type: LEDGER_SELECT_INPUT_QUERY_PARAM.type,
}

export const getReportPnlMetricInputQueryParam = (tableId?: string | number) => ({
    ...PNL_METRIC_INPUT_QUERY_PARAM,
    syncQueryParam: false,
    syncLocalStorage: true,
    prefix: getReportPrefix(tableId),
})

export const getReportScenarioInputQueryParam = (tableId?: string | number) => ({
    ...SCENARIO_INPUT_QUERY_PARAM,
    syncQueryParam: false,
    syncLocalStorage: true,
    prefix: getReportPrefix(tableId),
})

export const getReportAssetDetailsInputQueryParam = (tableId?: string | number) => ({
    ...ASSET_DETAILS_QUERY_PARAM,
    syncQueryParam: false,
    syncLocalStorage: true,
    prefix: getReportPrefix(tableId),
})

export const getUnderwritingDateInputQueryParam = (tableId?: string | number) => ({
    ...UNDERWRITING_DATE_INPUT_QUERY_PARAM,
    syncQueryParam: false,
    syncLocalStorage: true,
    prefix: getReportPrefix(tableId),
})

export const getReportTableIdRowsInputQueryParam = (tableId?: string | number) => ({
    ...TABLE_ID_ROWS_INPUT_QUERY_PARAM,
    syncQueryParam: false,
    syncLocalStorage: true,
    prefix: getReportPrefix(tableId),
})

export const getReportShowCodesInputQueryParam = (tableId?: string | number) => ({
    ...ACCOUNT_CODES_INPUT_QUERY_PARAM,
    syncQueryParam: false,
    syncLocalStorage: true,
    prefix: getReportPrefix(tableId),
})

export const getReportShowEmptyRowsInputQueryParam = (tableId?: string | number) => ({
    ...EMPTY_ROWS_INPUT_QUERY_PARAM,
    syncQueryParam: false,
    syncLocalStorage: true,
    prefix: getReportPrefix(tableId),
})

export const getReportTableTypeInputQueryParam = (tableId?: string | number) => ({
    ...TABLE_TYPE_INPUT_QUERY_PARAM,
    syncQueryParam: false,
    syncLocalStorage: true,
    prefix: getReportPrefix(tableId),
})

export const getReportTableTbLedgerInputQueryParam = (tableId?: string | number) => ({
    ...TABLE_TB_LEDGER_INPUT_QUERY_PARAM,
    syncQueryParam: false,
    syncLocalStorage: true,
    prefix: getReportPrefix(tableId),

})

export const getReportTableRrLedgerInputQueryParam = (tableId?: string | number) => ({
    ...TABLE_RR_LEDGER_INPUT_QUERY_PARAM,
    syncQueryParam: false,
    syncLocalStorage: true,
    prefix: getReportPrefix(tableId),

})

export const getReportTableAssetTypeParam = (tableId?: string | number) => ({
    ...TABLE_ASSET_TYPE_PARAM,
    syncQueryParam: false,
    syncLocalStorage: true,
    prefix: getReportPrefix(tableId),

})

export const getReportTimeComparisonInputQueryParam = (tableId?: string | number) => ({
    ...TIME_COMPARISON_INPUT_QUERY_PARAM,
    syncQueryParam: false,
    syncLocalStorage: true,
    prefix: getReportPrefix(tableId),
})

export const getMetricInputQueryParam = (tableId?: string | number) => ({
    ...METRIC_INPUT_QUERY_PARAM,
    syncQueryParam: false,
    syncLocalStorage: true,
    prefix: getReportPrefix(tableId),
} as UseInputStateParams<string>)

export const getPnlMetricInputQueryParam = (tableId?: string | number) => ({
    ...PNL_METRIC_INPUT_QUERY_PARAM,
    syncQueryParam: false,
    syncLocalStorage: true,
    prefix: getReportPrefix(tableId),
})

export const getReportQuerterInputQueryParam = (tableId?: string | number) => ({
    ...QUARTER_INPUT_QUERY_PARAM,
    syncQueryParam: false,
    syncLocalStorage: true,
    prefix: getReportPrefix(tableId),
})

export const getReportQuarterRangeStartQueryParam = (tableId?: string | number) => ({
    ...QUARTER_RANGE_START_QUERY_PARAM,
    syncQueryParam: false,
    syncLocalStorage: true,
    prefix: getReportPrefix(tableId),
})

export const getQuarterRangeEndQueryParam = (tableId?: string | number) => ({
    ...QUARTER_RANGE_END_QUERY_PARAM,
    syncQueryParam: false,
    syncLocalStorage: true,
    prefix: getReportPrefix(tableId),
})

export const getReportYearInputQueryParam = (tableId?: string | number) => ({
    ...YEAR_INPUT_QUERY_PARAM,
    syncQueryParam: false,
    syncLocalStorage: true,
    prefix: getReportPrefix(tableId),
})

export const getReportYearRangeStartQueryParam = (tableId?: string | number) => ({
    ...YEAR_RANGE_START_QUERY_PARAM,
    syncQueryParam: false,
    syncLocalStorage: true,
    prefix: getReportPrefix(tableId),
})

export const getReportYearRangeEndQueryParam = (tableId?: string | number) => ({
    ...YEAR_RANGE_END_QUERY_PARAM,
    syncQueryParam: false,
    syncLocalStorage: true,
    prefix: getReportPrefix(tableId),
})

export const getReportTimeSettingsInputQueryParam = (tableId?: string | number) => ({
    ...TIME_SETTINGS_INPUT_QUERY_PARAM,
    syncQueryParam: false,
    syncLocalStorage: true,
    prefix: getReportPrefix(tableId),
})

export const getReportTimeIntervalInputQueryParam = (tableId?: string | number) => ({
    ...TIME_INTERVAL_INPUT_QUERY_PARAM,
    syncQueryParam: false,
    syncLocalStorage: true,
    prefix: getReportPrefix(tableId),
})

export const getReportPnLMetricInputQueryParam = (tableId?: string | number) => ({
    ...PNL_METRIC_INPUT_QUERY_PARAM,
    syncQueryParam: false,
    syncLocalStorage: true,
    prefix: getReportPrefix(tableId),
})

export const getReportPinTotalColInputQueryParam = (tableId?: string | number) => ({
    ...PIN_TOTAL_COL_INPUT_QUERY_PARAM,
    syncQueryParam: false,
    syncLocalStorage: true,
    prefix: getReportPrefix(tableId),
})

export const getReportGroupByAssetDetailInputQueryParam = (tableId?: string | number) => ({
    ...GROUP_BY_ASSET_DETAIL_INPUT_QUERY_PARAM,
    syncQueryParam: false,
    syncLocalStorage: true,
    prefix: getReportPrefix(tableId),
})

export const getRpMetricInputQueryParam = (tableId?: string | number) => ({
    ...RP_METRIC_INPUT_QUERY_PARAM,
    syncQueryParam: false,
    syncLocalStorage: true,
    prefix: getReportPrefix(tableId),
})

export const getReportGroupByRRFieldInputQueryParam = (tableId?: string | number) => ({
    ...GROUP_BY_RR_FIELD_INPUT_QUERY_PARAM,
    syncQueryParam: false,
    syncLocalStorage: true,
    prefix: getReportPrefix(tableId),
})

export const getReportBalanceSheetScenarioInputQueryParam = (tableId?: string | number) => ({
    ...BALANCE_SHEET_SCENARIO_INPUT_QUERY_PARAM,
    syncQueryParam: false,
    syncLocalStorage: true,
    prefix: getReportPrefix(tableId),
})

export const getReportTotalRowInputQueryParam = (tableId?: string | number) => ({
    ...TOTAL_ROW_INPUT_QUERY_PARAM,
    syncQueryParam: false,
    syncLocalStorage: true,
    prefix: getReportPrefix(tableId),
})
