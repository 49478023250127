import React from 'react'

import { useInView } from 'react-intersection-observer'

import { Layout } from '@/components/containers'

import { LazyReportTableProps } from './LazyReportTable.types'

import styles from './LazyReportTable.module.scss'

/**
 * A wrapper component that lazy loads report tables when they enter the viewport
 */
export const LazyReportTable = (props: LazyReportTableProps) => {
    const {
        children,
        minHeight,
    } = props

    const { ref, inView } = useInView({
        // Trigger when 200px from the viewport
        rootMargin: '200px',
        triggerOnce: false,
    })

    return (
        <Layout
            ref={ref}
            className={styles.lazyTableContainer}
            style={{
                minHeight,
                width: '100%',
            }}
        >
            {inView ? (
                children
            ) : (
                <Layout className={styles.placeholder}/>
            )}
        </Layout>
    )
}
