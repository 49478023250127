import { useState } from 'react'

import cn from 'classnames'

import ColorPicker from '@rc-component/color-picker'

import { Button, TextInput } from '@/components/base'

import { ColorSelectInputProps } from './ColorSelectInput.types'

export const ColorSelectInput = (props: ColorSelectInputProps) => {
    const { currentColor, onColorChange, label } = props
    const [showPopupPicker, setShowPopupPicker] = useState(false)

    const isColorValid = currentColor && CSS.supports('color', currentColor)

    return (
        <div className={cn()}>
            {showPopupPicker && (
                <div
                    style={{
                        position: 'fixed',
                        zIndex: 10,
                    }}
                >
                    <ColorPicker
                        value={currentColor}
                        onChange={(color) => { onColorChange(color.toHexString()) }}
                        panelRender={(panel) => (
                            <div>{panel}
                                <div style={{ float: 'right' }}>
                                    <Button onClick={() => setShowPopupPicker(false)} text='Select Color'/>
                                </div>
                            </div>
                        )}
                    />
                </div>
            )}

            <TextInput
                colorSquare={isColorValid ? currentColor : undefined}
                label={label}
                value={currentColor}
                onChange={(val) => {
                    onColorChange(val.target.value)
                }}
                icon='dropper'
                onIconClick={() => setShowPopupPicker(true)}
            />

        </div>
    )
}
