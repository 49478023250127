import { AND, EQ } from 'mobx-orm'

import { observer } from 'mobx-react-lite'
import { useQueryParams } from 'use-query-params'

import { SelectTriggerCell, MappingEngineCellProps } from '@/components/legacy/tables/cells'
import {
    MAPPING_ENGINE_CELL_QUERY_PARAMS_CONFIG,
} from '@/components/legacy/tables/cells/MappingEngineCell/MappingEngineCell.constants'
import { MappingEngineSideModal } from '@/components/widgets'
import { useInputState } from '@/hooks'
import { Account as TrialBalanceAccount } from '@/models/ledger'
import { Account as RentRollAccount } from '@/models/rent_roll'

/**
 * Use it in 'selectAccountCellRendererSelector' field or with wrapper cell which will pass parameters
 * It allows to stay more flexible with this component
 */
export const MappingEngineCell = observer((props: MappingEngineCellProps) => {
    const {
        onChange,
        useAccountQuery,
        customLedgerParamConfig,
        ledgerId,
        hideArrow = false,
        showNameOnly = false,
        showCodeOnly = false,
        data,
        triggerOnly = false,
        hideNoMappingLabel = false,
        accountType,
        closeAfterSave = false,
        syncTemplates = false,
    } = props

    const [customLedgerIdParam] = useInputState(customLedgerParamConfig)

    const [{ mappingModalOpenForId }, setQueryParams] = useQueryParams(MAPPING_ENGINE_CELL_QUERY_PARAMS_CONFIG)
    const rowId = data.id

    const isOpen = mappingModalOpenForId === rowId

    const accountQuery = useAccountQuery({
        filter: AND(
            EQ('ledger_id', customLedgerIdParam),
            EQ('__order_by', 'name'),
            EQ('__order_by', 'code'),
        ),
    }, {
        // Fixing mobx-orm issue, when account added, WEBDEV-2300
        staleTime: 5000,
    })

    let accountItems = accountQuery.data || []

    const selectedAccountId = data.custom_account_id
    const sourceAccountData = {
        name: data.name,
        code: data.code,
        id: data.id,
    }

    const handleChange = async (option: RentRollAccount | TrialBalanceAccount | undefined, update: boolean, autoApply: boolean) => {
        await onChange(option, update, autoApply)

        if (closeAfterSave) {
            setQueryParams({ mappingModalOpenForId: null })
        }
    }

    if (ledgerId) {
        // TODO: Check if this is make sense. Items must be filtered by ledger_id in request
        accountItems = accountItems.filter((account) => account.ledger_id === Number(ledgerId))
    }

    const selectedAccount = accountItems.find((account) => selectedAccountId && (account.id === Number(selectedAccountId)))

    const triggerText = showNameOnly
        // @ts-expect-error RR Account has no name
        ? selectedAccount?.name : showCodeOnly
            ? selectedAccount?.code : null

    const haveMapping = Boolean(selectedAccount)

    return (
        <>
            <SelectTriggerCell
                onClick={() => {
                    setQueryParams({ mappingModalOpenForId: rowId })
                }}
                content={haveMapping ? triggerText : !hideNoMappingLabel ? 'No Mapping' : ''}
                open={isOpen}
                hideArrow={hideArrow}
            />
            {(isOpen && !triggerOnly) && (
                <MappingEngineSideModal
                    sourceAccountData={sourceAccountData}
                    onClose={() => {
                        setQueryParams({ mappingModalOpenForId: null })
                    }}
                    onChange={handleChange}
                    accounts={accountItems}
                    haveMapping={haveMapping}
                    selectedAccount={selectedAccount}
                    accountType={accountType}
                    customLedgerId={customLedgerIdParam}
                    syncTemplates={syncTemplates}
                    rowData={data}
                />
            )}
        </>
    )
})
