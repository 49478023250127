import { useEffect } from 'react'

import mixpanel from 'mixpanel-browser'

import { MODEL_NAME_BY_ID } from '@/core/modes'
import { useMe } from '@/hooks/core/useMe'

export const useIDentifyMixPanel = () => {
    const { me } = useMe()

    useEffect(() => {
        mixpanel.identify(me.user.id?.toString())

        mixpanel.people.set({
            $name: me.user?.fullName,
            $email: me.user?.email,
            companyId: me.company?.id,
            companyName: me.company?.name,
            portalMode: MODEL_NAME_BY_ID[me.companyUserMode],
            host: window.location.host,
            isStaff: me.companyUser?.is_staff as boolean,
            isOwner: me.companyUser?.is_owner,
            isManager: me.companyUser?.is_property_manager,
        })
    }, [])
}
