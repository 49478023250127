import { forwardRef, RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { GridReadyEvent, GridState } from '@ag-grid-community/core'
import isEqual from 'lodash/isEqual'
import { AND, EQ } from 'mobx-orm'
import { createPortal } from 'react-dom'

import { AgGridReact } from '@ag-grid-community/react'
import { useNavigate } from 'react-router-dom'

import { useReportingLeaseExpirationQuery } from '@/api/reportingLeaseExpiration'
import { ReportingTableFilters, useReportingTableByIdQuery } from '@/api/reportingTable/reportingTable'
import { Loader } from '@/components/base/Loader'
import { MONTH_INPUT_QUERY_PARAM } from '@/components/baseInputs'
import { Layout, useTableSettings } from '@/components/containers'
import { ASSET_SIDE_MODAL_INPUT_QUERY_PARAM } from '@/components/models/asset'
import { AgGridTable } from '@/components/tables'
import {
    GLOBAL_REPORT_MONTH_QUERY_PARAM,
    GLOBAL_REPORT_MULTY_ASSET_QUERY_PARAM,
} from '@/components/widgets/reports/reports.constants'
import { useInputState } from '@/hooks'
import { NoDataMessage } from '@/pages/TablesPage/TableBuilderPage/NoDataMessage'
import { CancelSaveButtons } from '@/pages/TablesPage/TableBuilderPage/TableBuilder/CancelSaveButtons'
import {
    getReportTableAssetTypeParam,
    getReportTableRrLedgerInputQueryParam,
    getReportTableTbLedgerInputQueryParam,
    TABLE_ASSET_TYPE_PARAM,
    TABLE_RR_LEDGER_INPUT_QUERY_PARAM,
    TABLE_TB_LEDGER_INPUT_QUERY_PARAM,
} from '@/pages/TablesPage/TableBuilderPage/TableBuilderPage.constants'
import {
    useReportingTableSaveMutation,
} from '@/pages/TablesPage/TableBuilderPage/TableBuilderTables/useReportingTableSaveMutation'
import { TableMode, TableType } from '@/pages/TablesPage/tableType'
import { getRoute } from '@/utils/routing/getRoute'

import { statusBar } from '../TableBuilderTables.constants'
import { TableBuilderTableProps } from '../TableBuilderTables.types'
import { getGridPropsToSave, saveReportingTable } from '../TableBuilderTables.utils'

import { getLeaseExpirationColDefs, leaseExpirationTableGridOptions } from './OfficeLeaseExpirationTable.constants'

export const OfficeLeaseExpirationTable = forwardRef((props: TableBuilderTableProps, ref: RefObject<AgGridReact>) => {
    const {
        tableMeta = {},
        buttonContainer,
        onCancel,
        onGridReady,
        id,
        insideReport = false,
        onFirstDataRendered,
        tablesListRouteConfigKey,
        builderRouteConfigKey,
    } = props

    const navigate = useNavigate()
    const isCreationMode = id === 'new'
    const fallbackTableRef = useRef<AgGridReact>(null)
    const tableRef = ref ?? fallbackTableRef
    const gridStateRef = useRef<GridState>() // Retain grid state between renders

    const [tableMode, setTableMode] = useState<TableMode | null>(null)

    const [isTableDirty, setIsTableDirty] = useState(false)
    const saveMutation = useReportingTableSaveMutation()
    const { data: loadedTableData, isLoading: isLoadingTableData } = useReportingTableByIdQuery(id, { enabled: !isCreationMode })

    // useTableChartToggle hook uses this grid context to toggle between table and chart modes
    const context = useRef<{ hasChart: boolean, tableMode: TableMode | null, toggleTableMode: () => void }>(
        {
            hasChart: true,
            tableMode,
            toggleTableMode: () => setTableMode(prev => prev === TableMode.Table ? TableMode.Chart : TableMode.Table),
        })

    useEffect(() => { context.current.tableMode = tableMode }, [tableMode])

    const [assetIds, setAssetIds] = useInputState(
        insideReport ? GLOBAL_REPORT_MULTY_ASSET_QUERY_PARAM : ASSET_SIDE_MODAL_INPUT_QUERY_PARAM,
    )

    const [tBLedgerId] = useInputState(
        insideReport ? getReportTableTbLedgerInputQueryParam(id) : TABLE_TB_LEDGER_INPUT_QUERY_PARAM,
    )
    const [rRLedgerId] = useInputState(
        insideReport ? getReportTableRrLedgerInputQueryParam(id) : TABLE_RR_LEDGER_INPUT_QUERY_PARAM,
    )
    const [month, setMonth] = useInputState(
        insideReport ? GLOBAL_REPORT_MONTH_QUERY_PARAM : MONTH_INPUT_QUERY_PARAM,
    )
    const [assetType] = useInputState(
        insideReport ? getReportTableAssetTypeParam(id) : TABLE_ASSET_TYPE_PARAM,
    )

    const areFiltersSet = Boolean(assetIds && month)

    const reportingLeaseExpirationQuery = useReportingLeaseExpirationQuery(
        {
            filter: AND(
                EQ('asset_ids', assetIds),
                EQ('trial_balance_ledger_id', tBLedgerId),
                EQ('rent_roll_ledger_id', rRLedgerId),
                EQ('month', month),
            ),
        },
        {
            enabled: areFiltersSet,
        },
    )

    // set filters from loaded table data
    useEffect(() => {
        if (!loadedTableData) {
            return
        }

        const { filters, conditional_format_rules, charts } = loadedTableData.config

        if (filters) {
            if ((insideReport && !assetIds) || !insideReport) {
                filters.assetIds && (setAssetIds(filters.assetIds))
            }

            if ((insideReport && !month) || !insideReport) {
                filters.month && (setMonth(filters.month))
            }
        }

        setTableSetting(loadedTableData.id, conditional_format_rules ? conditional_format_rules.rules : [])

        if (!tableMode) {
            setTableMode(charts?.isChartMode ? TableMode.Chart : TableMode.Table)
        }
    }, [loadedTableData, insideReport])

    // Load grid state once
    if (!gridStateRef.current && loadedTableData?.aggrid_state) {
        gridStateRef.current = loadedTableData.aggrid_state
    }

    const [rows] = useMemo(() => [
        reportingLeaseExpirationQuery.data?.[0]?.rows ?? [],
    ], [reportingLeaseExpirationQuery.dataUpdatedAt])

    const tableTitle = 'Office - Lease Expiration'

    const columnDefs = useMemo(() => {
        return getLeaseExpirationColDefs()
    }, [tableTitle])

    const { getTableSetting, setTableSetting } = useTableSettings()

    const conditionalFormatRules = loadedTableData?.id ? (getTableSetting(loadedTableData.id)?.conditionalRules ?? undefined) : undefined

    // table config
    const config = useMemo(() => {
        const filters: ReportingTableFilters = { asset_ids: assetIds as number[] }
        month && (filters.month = month)

        return ({
            filters,
            conditional_format_rules: conditionalFormatRules && { rules: conditionalFormatRules },
            charts: { isChartMode: tableMode === TableMode.Chart },
        })
    }, [conditionalFormatRules, assetIds, month, tableMode])

    // Check if table is dirty for save button
    const checkIfTableIsDirty = useCallback(() => {
        const loadedState = loadedTableData?.aggrid_state ?? {}
        const currentState = getGridPropsToSave(tableRef.current?.api?.getState())
        const currentConfig = config
        const loadedConfig = loadedTableData?.config ?? {}
        const currentName = tableMeta.name
        const loadedName = loadedTableData?.name

        if (
            isEqual(currentConfig, loadedConfig) &&
            isEqual(currentState, loadedState) &&
            currentName === loadedName
        ) {
            setIsTableDirty(false)
        } else {
            setIsTableDirty(true)
        }
    }, [config, loadedTableData?.aggrid_state, loadedTableData?.config, loadedTableData?.name, tableMeta.name, tableRef])

    // Save handler
    const onTableSave = useCallback(async () => {
        const currentState = tableRef.current?.api?.getState() ?? {}

        const response = await saveReportingTable({
            tableMeta,
            currentState,
            isCreationMode,
            saveMutation,
            id,
            tableType: TableType.LeaseExpiration,
            config,
            assetType,
        })

        if (!response) {
            return
        }

        // Redirect after saving
        if (isCreationMode) {
            navigate(getRoute(builderRouteConfigKey, { id: response.id }))
        } else {
            setTimeout(() => navigate(getRoute(tablesListRouteConfigKey)), 500)
        }
    }, [
        assetType,
        builderRouteConfigKey,
        config,
        id,
        isCreationMode,
        navigate,
        saveMutation,
        tableMeta,
        tableRef,
        tablesListRouteConfigKey,
    ])

    // Grid ready handler
    const handleGridReady = useCallback((params: GridReadyEvent) => {
        if (onGridReady) {
            onGridReady(params)
        }

        checkIfTableIsDirty()
    }, [onGridReady, checkIfTableIsDirty])

    // Effect to check if table is dirty when data changes
    useEffect(() => {
        checkIfTableIsDirty()
    }, [checkIfTableIsDirty, tableMeta, config])

    const isLoading = reportingLeaseExpirationQuery.isLoading || isLoadingTableData

    // onFirstDataRendered is used in Excel add-on
    const onModelUpdated = useCallback(() => {
        if (!isLoading && reportingLeaseExpirationQuery.isFetched && reportingLeaseExpirationQuery.isSuccess) {
            onFirstDataRendered()
        }
    }, [isLoading, reportingLeaseExpirationQuery.dataUpdatedAt])

    return (
        <>
            <Layout
                flexGrow={1}
                direction='column'
            >
                {isLoading ? (
                    <Loader/>
                ) : rows && rows.length > 0 ? (
                    <>
                        {/* @ts-expect-error TODO: make AgGridTable generic to be able to pass theme */}
                        <AgGridTable
                            ref={tableRef}
                            items={rows}
                            columnDefs={columnDefs}
                            onGridReady={handleGridReady}
                            onModelUpdated={onModelUpdated}
                            context={{ tableType: TableType.LeaseExpiration }}
                            statusBar={statusBar}
                            {...leaseExpirationTableGridOptions}
                        />
                    </>
                ) : (
                    <NoDataMessage text='No lease expiration data available'/>
                )}
            </Layout>

            {buttonContainer && createPortal(
                <CancelSaveButtons
                    onCancel={onCancel}
                    onSave={onTableSave}
                    isLoading={saveMutation.isPending}
                    isDisabled={!isTableDirty || !tableMeta.name}
                />,
                buttonContainer,
            )}
        </>
    )
})
