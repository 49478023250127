import { forwardRef, RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { CellClickedEvent, ColDef, GridState, MenuItemDef } from '@ag-grid-community/core'
import isEqual from 'lodash/isEqual'
import { AND, EQ } from 'mobx-orm'
import { createPortal } from 'react-dom'

import { AgGridReact } from '@ag-grid-community/react'
import { useNavigate } from 'react-router'

import { useAssetsQuery } from '@/api/asset/asset'
import { useReportingRentRollQuery } from '@/api/reportingData/reportingRentRoll'
import { useReportingTableByIdQuery } from '@/api/reportingTable/reportingTable'
import { Loader } from '@/components/base'
import { MONTH_INPUT_QUERY_PARAM } from '@/components/baseInputs'
import { Layout, useTableSettings } from '@/components/containers'
import { ASSET_SIDE_MODAL_INPUT_QUERY_PARAM } from '@/components/models/asset'
import { COMPANY_SELECT_INPUT_QUERY_PARAM } from '@/components/models/company'
import { AgGridTable } from '@/components/tables'
import styles from '@/components/tables/AgGridTable/themes/feb25Flat/feb25Flat.module.scss'
import { useTableToolPanelModules } from '@/components/tables/utils/useTableToolPanelModules'
import {
    GLOBAL_REPORT_MONTH_QUERY_PARAM,
    GLOBAL_REPORT_MULTY_ASSET_QUERY_PARAM,
} from '@/components/widgets/reports/reports.constants'
import { useInputState } from '@/hooks'
import { useMe } from '@/hooks/core/useMe'
import { TableType } from '@/pages/TablesPage/tableType'
import { monthIndToString } from '@/utils/date/monthInd'
import { getRoute } from '@/utils/routing/getRoute'

import { CustomSettingsFilterModal } from '../../CustomSettingsFilterModal'
import { CancelSaveButtons } from '../../TableBuilder/CancelSaveButtons'
import {
    getReportTableAssetTypeParam,
    getReportTableRrLedgerInputQueryParam,
    getReportTableTbLedgerInputQueryParam,
    TABLE_ASSET_TYPE_PARAM,
    TABLE_RR_LEDGER_INPUT_QUERY_PARAM,
    TABLE_TB_LEDGER_INPUT_QUERY_PARAM,
} from '../../TableBuilderPage.constants'
import { TableBuilderTableProps } from '../TableBuilderTables.types'
import { getGridPropsToSave, getTableExportMeta, saveGridStateToRef, saveReportingTable } from '../TableBuilderTables.utils'
import { useReportingTableSaveMutation } from '../useReportingTableSaveMutation'

import { getPortfolioRentRollColDefs, gridOptions, updateVisibleColumns } from './PortfolioRentRollTable.constants'
import { RRDetailDrillDownParams, RRDetailDrillDownSlideover } from './RRDetailDrillDownSlideover'

/**
 * Portfolio Rent Roll - Asset Rows table
 */
export const PortfolioRentRollTable = forwardRef((props: TableBuilderTableProps, ref: RefObject<AgGridReact>) => {
    const {
        tableMeta = {},
        buttonContainer,
        onCancel,
        onGridReady,
        id,
        editable = false,
        insideReport = false,
        onFirstDataRendered,
        tablesListRouteConfigKey,
        builderRouteConfigKey,
    } = props

    const { me } = useMe()
    const navigate = useNavigate()
    const isCreationMode = id === 'new'
    const fallbackTableRef = useRef<AgGridReact>(null)
    const tableRef = ref ?? fallbackTableRef
    const gridStateRef = useRef<GridState>() // Retain grid state between renders
    const visibleColumnsRef = useRef<string[]>() // Retain visible columns between data reloads
    const previousColumnDefsRef = useRef<ColDef[] | null>(null) // Retain previous column definitions when no columns in the request
    const toolPanelModules = useTableToolPanelModules()

    const { showTableSettingsModal, setShowTableSettingsModal, getTableSetting, setTableSetting } = useTableSettings()

    // isFirstLoad pauses the first table render until the columns are fetched.
    // This is needed because the table is rendered with empty columns and reset the column order.
    const [isFirstLoad, setIsFirstLoad] = useState(true)
    const [isTableDirty, setIsTableDirty] = useState(false)
    const saveMutation = useReportingTableSaveMutation()
    const { data: loadedTableData } = useReportingTableByIdQuery(id, { enabled: !isCreationMode })

    // load grid state from table data once
    if (!gridStateRef.current && loadedTableData?.aggrid_state) { gridStateRef.current = loadedTableData.aggrid_state }
    if (!visibleColumnsRef.current && loadedTableData?.config) { visibleColumnsRef.current = loadedTableData.config.visible_columns ?? [] }

    const [assetIds, setAssetIds] = useInputState(insideReport ? GLOBAL_REPORT_MULTY_ASSET_QUERY_PARAM : ASSET_SIDE_MODAL_INPUT_QUERY_PARAM)
    const [monthIndex, setMonthIndex] = useInputState(insideReport ? GLOBAL_REPORT_MONTH_QUERY_PARAM : MONTH_INPUT_QUERY_PARAM)
    const [companyId] = useInputState(COMPANY_SELECT_INPUT_QUERY_PARAM)
    const [tBLedgerId] = useInputState(
        insideReport ? getReportTableTbLedgerInputQueryParam(id) : TABLE_TB_LEDGER_INPUT_QUERY_PARAM,
    )
    const [rRLedgerId] = useInputState(
        insideReport ? getReportTableRrLedgerInputQueryParam(id) : TABLE_RR_LEDGER_INPUT_QUERY_PARAM,
    )
    const [assetType] = useInputState(
        insideReport ? getReportTableAssetTypeParam(id) : TABLE_ASSET_TYPE_PARAM,
    )

    const { data: assets } = useAssetsQuery({ filter: EQ('company_id', me.isAdministratorMode ? companyId : undefined) })
    const firstSelectedAsset = assets?.find((asset) => asset.id === assetIds?.[0])

    const conditionalFormatRules = loadedTableData?.id ? (getTableSetting(loadedTableData.id)?.conditionalRules ?? undefined) : undefined

    const { data: renRollData, isLoading, isFetched, isSuccess } = useReportingRentRollQuery(
        {
            filter: AND(
                EQ('asset_ids', assetIds),
                EQ('trial_balance_ledger_id', tBLedgerId),
                EQ('rent_roll_ledger_id', rRLedgerId),
                EQ('month', monthIndex),
            ),
        },
        { enabled: Boolean(assetIds && monthIndex) })

    const [drillDownParams, setDrillDownParams] = useState<RRDetailDrillDownParams | null>(null)

    useEffect(() => {
        if (isFirstLoad && isFetched) {
            setIsFirstLoad(false)
        }
    }, [isFetched])

    const [items, columns] = useMemo(() => [
        renRollData?.[0]?.rows ?? [],
        renRollData?.[0]?.columns ?? [],
    ], [renRollData])

    const columnDefs = useMemo(() => {
        // Return the previous columnDefs if columns are empty to keep the order of the columns
        if (columns.length === 0 && previousColumnDefsRef.current) {
            return previousColumnDefsRef.current
        }

        const newColumnDefs = getPortfolioRentRollColDefs({
            conditionalFormatRules,
            columns,
            visibleColumns: visibleColumnsRef.current,
        })

        previousColumnDefsRef.current = newColumnDefs
        return newColumnDefs
    }, [conditionalFormatRules, columns, visibleColumnsRef.current])

    // table config
    const config = useMemo(() => ({
        filters: {
            asset_ids: (assetIds ?? undefined) as number[] | undefined,
            month: monthIndex ?? undefined,
        },
        conditional_format_rules: conditionalFormatRules && { rules: conditionalFormatRules },
    }), [conditionalFormatRules, assetIds, monthIndex])

    const checkIfTableIsDirty = useCallback(() => {
        const loadedState = loadedTableData?.aggrid_state ?? {}
        const currentState = getGridPropsToSave(tableRef.current?.api?.getState())
        const currentConfig = config
        const loadedConfig = loadedTableData?.config ?? {}
        const currentName = tableMeta.name
        const loadedName = loadedTableData?.name

        if (
            isEqual(currentConfig, loadedConfig) &&
            isEqual(currentState, loadedState) &&
            currentName === loadedName
        ) {
            setIsTableDirty(false)
        } else {
            setIsTableDirty(true)
        }
    }, [config, loadedTableData?.aggrid_state, loadedTableData?.config, loadedTableData?.name, tableMeta.name, tableRef])

    useEffect(() => checkIfTableIsDirty(), [checkIfTableIsDirty])

    // set filters from loaded table data
    useEffect(() => {
        if (!loadedTableData) {
            return
        }

        const { filters, conditional_format_rules } = loadedTableData.config

        if (filters) {
            if ((insideReport && !assetIds) || !insideReport) {
                filters.asset_ids && setAssetIds(filters.asset_ids)
            }
            if ((insideReport && !monthIndex) || !insideReport) {
                filters.month && setMonthIndex(filters.month)
            }
        }
        setTableSetting(loadedTableData.id, conditional_format_rules ? conditional_format_rules.rules : [])
    }, [loadedTableData, setAssetIds, setMonthIndex])

    const onTableSave = useCallback(async () => {
        const currentState = tableRef.current?.api?.getState() ?? {}
        const response = await saveReportingTable({
            tableMeta,
            currentState,
            isCreationMode,
            saveMutation,
            id,
            tableType: TableType.PortfolioRentRoll,
            config: {
                ...config,
                visible_columns: visibleColumnsRef.current,
            },
            assetType,
        })

        if (!response) {
            return
        }

        // redirect after saving
        if (isCreationMode) {
            navigate(getRoute(builderRouteConfigKey, { id: response.id }))
        } else {
            navigate(getRoute(tablesListRouteConfigKey))
        }
    }, [assetType, config, id, isCreationMode, navigate, saveMutation, tableMeta, tableRef])

    const excelExportParams = useMemo(() => ({
        fileName: `Portfolio Rent Roll - Asset Rows - ${assetIds?.length === 1 ? `${firstSelectedAsset?.name} - ` : ''} ${monthIndex ? monthIndToString(monthIndex) : ''}.xlsx`,
        sheetName: monthIndex ? monthIndToString(monthIndex) : '',
        prependContent: getTableExportMeta(assets, assetIds, monthIndex),
    }), [assetIds, firstSelectedAsset?.name, monthIndex, assets])

    const onModelUpdated = useCallback(() => {
        if (!isLoading && isFetched && isSuccess) {
            onFirstDataRendered()
        }
    }, [isLoading, isFetched, isSuccess, onFirstDataRendered])

    const getContextMenuItems = (): Array<string | MenuItemDef> => {
        const result: Array<string | MenuItemDef> = [
            'copy',
            'copyWithHeaders',
            'copyWithGroupHeaders',
            'separator',
            'export',
            'separator',
            {
                name: 'Table Settings',
                action: () => setShowTableSettingsModal(true),
            },
        ]
        return result
    }

    const onCellClicked = useCallback((e: CellClickedEvent) => {
        const colId = e.column.getColId()
        if (!colId.startsWith('account_')) {
            return
        }

        setDrillDownParams({
            assetIds,
            month: monthIndex,
            rRLedgerId,
            column: colId,
            contractObjectId: e.data.contract_object_id,
            unit: e.data.unit_name,
            codeName: e.colDef.headerName,
        })
    }, [assetIds, monthIndex, rRLedgerId])

    return (
        <>
            <Layout gap={16} flexGrow={1} direction='column'>
                {isFirstLoad
                    ? (<Loader/>)
                    : (
                    /* @ts-expect-error TODO: make AgGridTable generic to be able to pass theme */
                        <AgGridTable
                            className={styles.feb25FlatTable}
                            ref={tableRef}
                            items={items}
                            {...gridOptions}
                            columnDefs={columnDefs}
                            initialState={gridStateRef.current}
                            lazyModules={toolPanelModules}
                            hideStatusBar
                            sideBar={editable ? gridOptions.sideBar : false}
                            suppressMovableColumns={editable ? gridOptions.suppressMovableColumns : true}
                            onStateUpdated={(e) => {
                                saveGridStateToRef(e, gridStateRef)
                                updateVisibleColumns(e, visibleColumnsRef)
                                checkIfTableIsDirty()
                            }}
                            context={{ editable }}
                            defaultExcelExportParams={excelExportParams}
                            loading={isLoading}
                            onGridReady={onGridReady}
                            onModelUpdated={onModelUpdated}
                            getContextMenuItems={getContextMenuItems}
                            onCellClicked={onCellClicked}
                        />
                    )}
            </Layout>

            {showTableSettingsModal && (
                <CustomSettingsFilterModal
                    tableId={id}
                    tableRef={tableRef}
                    tableType={TableType.PortfolioRentRoll}
                    portfolioRentRollColumns={columns}
                    onClose={() => setShowTableSettingsModal(false)}
                    onSave={() => setIsTableDirty(true)}
                />
            )}

            {buttonContainer && createPortal(
                <CancelSaveButtons
                    onCancel={onCancel}
                    onSave={onTableSave}
                    isLoading={saveMutation.isPending}
                    isDisabled={!isTableDirty || !tableMeta.name}
                />,
                buttonContainer,
            )}
            {drillDownParams && (
                <RRDetailDrillDownSlideover
                    params={drillDownParams}
                    onClose={() => setDrillDownParams(null)}
                />
            )}
        </>
    )
})
