import { useEffect, useRef, useState } from 'react'

import { User } from '@veltdev/types/app/models/data/user.data.model'

import { VeltComments, VeltProvider } from '@veltdev/react'
import { observer } from 'mobx-react-lite'

import { useSettingsQuery } from '@/api/settings'
import { ContentWithMenu } from '@/components/containers/ContentWithMenu'
import { VELT_DIALOG_STYLES } from '@/components/containers/PostAuthAppPreparations/PostAuthAppPreparations.constants'
import {
    useIDentifyMixPanel,
} from '@/components/containers/PostAuthAppPreparations/PostAuthAppPreparations.hooks'
import { PostAuthAppPreparationsProps } from '@/components/containers/PostAuthAppPreparations/PostAuthAppPreparations.types'
import { intercomeInit } from '@/core/intercom'
import { useMe } from '@/hooks/core/useMe'
import { useUserDataInSentry } from '@/hooks/useUserDataInSentry'
import { checkBackendVersion } from '@/utils/checkBackendVersion'
import { isFeatureOn } from '@/utils/isFeatureOn'

/**
 * Container which is collecting initial required data which is required authotization
 * Also doing basic configuration for 3rd party services
 */
export const PostAuthAppPreparations = observer((props: PostAuthAppPreparationsProps) => {
    const isInitialDataRequested = useRef(false)
    const [veltClient, setVeltClient] = useState(null)
    const { me } = useMe()

    const settingsQuery = useSettingsQuery()

    useUserDataInSentry()
    useIDentifyMixPanel()

    useEffect(() => {
        checkBackendVersion()

        const INTERCOM_APPID = settingsQuery.data?.[0].INTERCOM_APPID as string

        intercomeInit(INTERCOM_APPID)

        if (isInitialDataRequested.current) {
            return
        }

        isInitialDataRequested.current = true
    }, [])

    useEffect(() => {
        if (!veltClient) return

        // @ts-expect-error TS(2339) FIXME: Property 'getCommentElement' does not exist on typ... Remove this comment to see the full error message
        const commentElement = veltClient.getCommentElement()
        commentElement.disableTextComments()
    }, [veltClient])

    useEffect(() => {
        if (veltClient && me.isReady && me.hasAccessToPortal) {
            const { id, fullName, email } = me.user
            const { company } = me

            const user: User = {
                // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
                userId: id.toString(),
                name: fullName,
                email,
                groupId: company.comments_group_id,
            }

            // @ts-expect-error TS(2339) FIXME: Property 'identify' does not exist on type 'never'... Remove this comment to see the full error message
            veltClient.identify(user).catch(console.error)

            // @ts-expect-error TS(2339) FIXME: Property 'injectCustomCss' does not exist on type ... Remove this comment to see the full error message
            veltClient.injectCustomCss({
                type: 'styles',
                value: VELT_DIALOG_STYLES,
            })
        }
    }, [veltClient, me.isReady])

    if (!settingsQuery.isFetched) {
        return <ContentWithMenu menu={null} content={null} loading/>
    }

    const VELT_API_KEY = settingsQuery.data?.[0]?.VELT_API_KEY as string

    return (
        <VeltProvider
            apiKey={VELT_API_KEY}
            onClientLoad={(client) => {
                // @ts-expect-error TS(2345) FIXME: Argument of type 'Velt | undefined' is not assigna... Remove this comment to see the full error message
                setVeltClient(client)
            }}
        >
            {isFeatureOn('commenting_packages') && <VeltComments/>}
            {props.children}
        </VeltProvider>
    )
})
