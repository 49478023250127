import { useContext, useState } from 'react'

import { observer } from 'mobx-react-lite'

import { ButtonGroup } from '@/components/base'
import { ModelInput } from '@/utils/mobx-orm'

import { ModelFormContext } from '../../ModelFromContext'

import { DateGroupInputProps } from './DateGroupInput.types'

export const DateGroupInput = observer((props: DateGroupInputProps) => {
    const { obj, field, filter, syncURL, syncLocalStorage, label = 'Group', rollup, annual, monthly, daily } = props
    const modelForm = useContext(ModelFormContext)

    const [modelInput] = useState(() => new ModelInput({
        obj: obj || modelForm.obj,
        field,
        filter,
        syncURL: syncURL || modelForm.syncURL,
        syncLocalStorage: syncLocalStorage || modelForm.syncLocalStorage,
    }))

    const choices = []
    if (daily) {
        choices.push({
            // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type 'never'.
            title: 'Daily',
            // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type 'never'.
            key: 'daily',
        })
    }
    if (monthly) {
        choices.push({
            // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type 'never'.
            title: 'Monthly',
            // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type 'never'.
            key: 'monthly',
        })
    }
    if (annual) {
        choices.push({
        // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type 'never'.
            title: 'Annual',
            // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type 'never'.
            key: 'annual',
        })
    }
    if (rollup) {
        choices.push({
        // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type 'never'.
            title: 'Rollup',
            // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type 'never'.
            key: 'rollup',
        })
    }

    return (
        <ButtonGroup
            label={label}
            options={choices}
            selected={modelInput.value}
            onSelect={(key) => modelInput.set(key)}
        />
    )
})
