import { resetTimezone } from '@/utils/date/resetTimezone'

export function monthIndToDateNoTimezoneReset (monthInd: number): Date {
    return resetTimezone(new Date(Date.UTC(Math.floor(monthInd / 12), monthInd % 12)))
}

export function monthIndToDate (monthInd: number): Date {
    return resetTimezone(new Date(Date.UTC(Math.floor(monthInd / 12), monthInd % 12)))
}

export function dateToMonthInd (date: Date): number {
    return date.getFullYear() * 12 + date.getMonth()
}

export function monthIndToMonthAndYear (monthInd: number) {
    return {
        year: Math.floor(monthInd / 12),
        month: monthInd % 12,
    }
}

// TODO: move it to some globals
export const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

export function monthIndToString (monthInd: number) {
    return `${months[monthInd % 12]} ${Math.floor(monthInd / 12)}`
}
