import { SelectInputWithModal } from '@/components/baseInputs'

import { ASSET_DETAILS_OPTIONS, ASSET_DETAILS_QUERY_PARAM } from './AssetDetailsInput.constants'
import { AssetDetailsInputProps } from './AssetDetailsInput.types'

export const AssetDetailsInput = (props: AssetDetailsInputProps) => {
    const {
        paramName = ASSET_DETAILS_QUERY_PARAM.name,
        label = 'Asset Details',
        placeholder = 'Select details',
        multiSelectPlaceholder = 'Details',
        multiSelect,
        required,
        width = '136px', // width of the longest option
        defaultActiveOptions,
        exclude = [],
        inputParams = {},
    } = props

    const excludedOptionSet = new Set(exclude) as Set<string>
    const options = ASSET_DETAILS_OPTIONS.filter((option) => !excludedOptionSet.has(option.value))

    return (
        <SelectInputWithModal
            paramName={paramName}
            paramType={ASSET_DETAILS_QUERY_PARAM.type}
            options={options}
            label={label}
            placeholder={placeholder}
            multiSelectPlaceholder={multiSelectPlaceholder}
            multiSelect={multiSelect}
            required={required}
            width={width}
            defaultActiveOptions={defaultActiveOptions}
            inputParams={inputParams}
        />
    )
}
