import { SelectInput } from '@/components/baseInputs'
import { REPORT_DATATYPE_LABELS } from '@/constants/reportDatatype'

import { REPORT_DATATYPE_INPUT_QUERY_PARAM } from './ReportDatatypeInput.constants'
import { ReportDatatypeInputProps } from './ReportDatatypeInput.types'

const OPTIONS = Object.entries(REPORT_DATATYPE_LABELS).map(([value, label]) => ({
    value,
    label,
}))

export const ReportDatatypeInput = (props: ReportDatatypeInputProps) => {
    const { label = 'Data Type', ...rest } = props

    return (
        <SelectInput
            paramName={REPORT_DATATYPE_INPUT_QUERY_PARAM.name}
            paramType={REPORT_DATATYPE_INPUT_QUERY_PARAM.type}
            options={OPTIONS}
            label={label}
            {...rest}
        />
    )
}
