import { observer } from 'mobx-react-lite'

import { Text } from '@/components/base'
import { Layout } from '@/components/containers'

import { ModelFormContext } from '../ModelFromContext'

import { ModelFormProps } from './ModelForm.types'

export const ModelForm = observer((props: ModelFormProps) => {
    const { obj, syncURL, syncLocalStorage } = props

    const nonFieldErrors = obj?.__errors?.non_field_errors?.length > 0 ? (
        <Layout mb={24}>
            {obj?.__errors.non_field_errors.map((text, i) => (
                <Text
                    key={i}
                    text={text}
                    variant='labelMediumDefault'
                    color='colorsTetrialRed600'
                />
            ))}
        </Layout>
    ) : null

    return (
        <ModelFormContext.Provider
            value={{
                obj,
                syncURL,
                syncLocalStorage,
            }}
        >
            {nonFieldErrors}
            {props.children}
        </ModelFormContext.Provider>
    )
})
