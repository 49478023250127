import { useEffect, useState } from 'react'

import { reaction } from 'mobx'
import {
    StringInput,
    XAND as AND,
    XEQ as EQ,
    XIN as IN,
    ArrayNumberInput,
    ASC,
    autoResetArrayOfIDs, BooleanInput,
} from 'mobx-orm'

// eslint-disable-next-line
import { observer } from 'mobx-react-lite'
import { Outlet, useNavigate } from 'react-router'

import { Alert, Button, FiltersContainer, Text } from '@/components/base'
import { Layout, PageLayout } from '@/components/containers'
import { AssetMultiSelect } from '@/components/legacy/models/asset/inputs/AssetMultiSelect'
import { StringInput2 } from '@/components/legacy/models/core/inputs/StringInput2'
import { AgGridTableLegacy } from '@/components/legacy/tables/AgGridTableLegacy'
import { CompanyUserMode } from '@/core/modes'
import { useInput } from '@/hooks/useInput'
import { useQueryXPage } from '@/hooks/useQueryXPage'
import { Asset } from '@/models/asset'
import { TrialBalanceTemplateBinding } from '@/models/trial_balance_template'
import { TrialBalanceTemplate } from '@/models/trial_balance_template/TrialBalanceTemplate'
import { getRouteConfig } from '@/utils/routing/getRouteConfig'

import { ApplyAllTemplatesModal } from '../ApplyAllTemplatesModal'

import { INTRO_CONTENT, TEMPLATE_LIST_GRID_OPTIONS, getSubtitleText } from './MappingTemplatesPage.constants'

const CREATE_PATH = getRouteConfig('ASSET_MAPPING_TEMPLATES_CREATE', CompanyUserMode.Owner).path

export const MappingTemplatesPage = observer(() => {
    const navigate = useNavigate()
    const [isConfirmOpen, setIsConfirmOpen] = useState(false)
    const [activeBindings, setActiveBindings] = useState<TrialBalanceTemplateBinding[]>([])

    const assetsInput = useInput(() =>
        new ArrayNumberInput({
            syncURL: 'assets',
            options: Asset.getQueryX({
                order_by: new Map([['name', ASC]]),
                autoupdate: true,
            }),
            autoReset: autoResetArrayOfIDs,
        }))

    const isActiveInput = useInput(() => new BooleanInput({}))

    useEffect(() => {
        return reaction(
            () => assetsInput.value.length,
            (value_length) => isActiveInput.set(value_length ? true : undefined),
        )
    }, [])

    const searchInput = useInput(() => new StringInput({
        syncURL: 'asset',
        debounce: 400,
    }))

    const templateQuery = useQueryXPage(TrialBalanceTemplate, {
        autoupdate: true,
        filter: AND(
            IN('bindings__asset', assetsInput),
            EQ('bindings__is_active', isActiveInput),
            EQ('__search', searchInput),
        ),
        relations: ['bindings'],
    })

    const subtitleText = getSubtitleText(templateQuery.items.length)

    useEffect(() => {
        const bindings = templateQuery.items
            .reduce((acc, template: TrialBalanceTemplate) => [...acc, ...template.activeBindings], [])
        setActiveBindings(bindings)
    }, [templateQuery.timestamp])

    return (
        <PageLayout>
            <Layout mt={12} mb={12}>
                <Alert type='alert'>{INTRO_CONTENT}</Alert>
            </Layout>
            <Layout mb={24} justify='flex-end'>
                <Button
                    icon='arrowRight'
                    text='Download Sample Mapping File'
                    onClick={() => window.open('/samples/Sample Mapping Template.csv')}
                    theme='flat'
                />
            </Layout>
            <Text text='Templates' variant='bodySemiboldDefault'/>
            <Layout mt={8}>
                <Text
                    text={subtitleText}
                    variant='labelMediumDefault'
                    color='colorsSecondaryGrey400'
                />
            </Layout>
            <Layout mt={12} mb={24}>
                <Button
                    text='Create New Template'
                    theme='primary'
                    onClick={() => { navigate(CREATE_PATH, { replace: true }) }}
                />
            </Layout>
            <Layout mb={24} gap={24}>
                <FiltersContainer hideTitle={false}>
                    <AssetMultiSelect input={assetsInput}/>
                    <StringInput2
                        input={searchInput}
                        label='Accounts Filter'
                        width='300px'
                        icon='search'
                        placeholder='Account code or name'
                    />
                </FiltersContainer>
                <Layout
                    style={{
                        marginLeft: 'auto',
                        marginTop: 'auto',
                    }}
                >
                    <Button
                        text='Apply All Templates'
                        theme='primary'
                        onClick={() => setIsConfirmOpen(true)}
                        disabled={!activeBindings.length}
                    />
                </Layout>
            </Layout>

            {Boolean(templateQuery.items.length) && (
                <AgGridTableLegacy
                    query={templateQuery}
                    {...TEMPLATE_LIST_GRID_OPTIONS}
                />
            )}

            {isConfirmOpen && (
                <ApplyAllTemplatesModal
                    bindings={activeBindings}
                    onClose={() => setIsConfirmOpen(false)}
                />
            )}

            <Outlet context={{ templateQuery }}/>
        </PageLayout>
    )
})
