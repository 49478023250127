import { observer } from 'mobx-react-lite'

import { ButtonGroup } from '@/components/base'

import { DateGroupFilterProps } from './DateGroupFilter.types'

// DEPRECATED
export const DateGroupFilter = observer((props: DateGroupFilterProps) => {
    const { rollup, annual, monthly, daily, filter, label = 'Group' } = props

    const choices = []
    if (daily) {
        choices.push({
            // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type 'never'.
            title: 'Daily',
            // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type 'never'.
            key: 'daily',
        })
    }
    if (monthly) {
        choices.push({
            // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type 'never'.
            title: 'Monthly',
            // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type 'never'.
            key: 'monthly',
        })
    }
    if (annual) {
        choices.push({
            // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type 'never'.
            title: 'Annual',
            // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type 'never'.
            key: 'annual',
        })
    }
    if (rollup) {
        choices.push({
            // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type 'never'.
            title: 'Rollup',
            // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type 'never'.
            key: 'rollup',
        })
    }

    return (
        <ButtonGroup
            label={label}
            options={choices}
            selected={filter.value}
            onSelect={(key) => filter.set(key)}
        />
    )
})
