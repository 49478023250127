import { getRequestQueries } from '@/api/utils/getRequestQueries'

export interface TrialBalanceTemplateDiffTemplateItem {
    id: number
    account_id: number | null
    map_id: number | null
    assets: Array<{ id: number, account_ids: number[] }>
}

export interface TrialBalanceTemplateDiffItem {
    templates: TrialBalanceTemplateDiffTemplateItem[]
}

export interface FetchTrialBalanceTemplateDiffQueryParams {
    source_account_id: number | string
    account_id: number | string
}

export const TRIAL_BALANCE_TEMPLATE_DIFF_URL = '/trial-balance-template-diff'

export const {
    useItemsQuery: useTrialBalanceTemplateDiffQuery,
} = getRequestQueries<TrialBalanceTemplateDiffItem, FetchTrialBalanceTemplateDiffQueryParams>({
    url: TRIAL_BALANCE_TEMPLATE_DIFF_URL,
    // Hack to make the response an array
    extractItemsFn: (data) => [data] as TrialBalanceTemplateDiffItem[],
})
