import React from 'react'

import { Text } from '@/components/base/Text'
import { Layout } from '@/components/containers/Layout/Layout'
import { generatedColors } from '@/styles/tokens/Tokens.constants'

import { MiniTableProps } from './MiniTable.types'

import styles from './MiniTable.module.scss'

export const MiniTable = (props: MiniTableProps) => {
    const { columns, rows, style } = props

    const columnWidth = `${100 / columns.length}%`

    return (
        <Layout
            direction='column'
            width='100%'
            p={16}
            className={styles.miniTable}
            style={{
                borderColor: generatedColors.colorsSecondaryPurple300,
                ...style,
            }}
            gap={8}
        >
            <Layout
                width='100%'
                justify='space-between'
            >
                {columns.map((column) => (
                    <Layout
                        key={String(column.field)}
                        style={{
                            width: columnWidth,
                        }}
                    >
                        <Text
                            variant='labelSemiboldDefault'
                            color='colorsSecondaryGrey400'
                        >
                            {column.headerName}
                        </Text>
                    </Layout>
                ))}
            </Layout>
            {rows.map((row, rowIndex) => (
                <Layout
                    key={rowIndex}
                    width='100%'
                    borderColor='colorsSecondaryGrey100'
                    justify='space-between'
                >
                    {columns.map((column) => (
                        <Layout
                            key={`${rowIndex}-${String(column.field)}`}
                            style={{
                                width: columnWidth,
                            }}
                        >
                            <Text color='colorsSecondaryGrey400' variant='labelMediumDefault'>
                                {row[column.field]}
                            </Text>
                        </Layout>
                    ))}
                </Layout>
            ))}
        </Layout>
    )
}
