import { Model, model, field, foreign, many } from 'mobx-orm'

import { api } from '../../http-adapter'

import { Account } from './Account'
import { AccountGroup } from './AccountGroup'

@api('account-in-group')
@model
export class AccountInGroup extends Model {
    @field account_id?: number
    @field group_id?:   number
    @field is_active?:  boolean

    @foreign(Account) readonly account:    Account
    @foreign(AccountGroup) readonly group: AccountGroup

    static getOrAddLink (account: Account, group: AccountGroup): AccountInGroup {
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        let link = account.group_links.find(link => link.group_id === group.id)
        if (!link) {
            link = new AccountInGroup({
                account_id: account.id,
                group_id:   group.id,
                is_active:  false,
            })
            // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
            account.group_links.push(link)
        }
        return link
    }
}

many(AccountInGroup, 'account_id')(Account, 'group_links')
many(AccountInGroup, 'group_id')(AccountGroup, 'account_links')
