import { Model, model, field, foreign } from 'mobx-orm'

import { Asset } from '@/models/asset'
import { SubmittedFile } from '@/models/data_submission'

import { api } from '../../http-adapter'

import { DataPointError } from './DataPointError'

export enum DataPointType {
    TRIAL_BALANCE = 'TB',
    TRIAL_BALANCE_BUDGET = 'B',
    RENT_ROLL = 'RR',
    RESIDENT_DIRECTORY = 'RD',
    UNRECOGNIZED = 'U',
    CONTAINER = 'C',
    VARIANCE_NOTES = 'VN',
    UNDERWRITING = 'UW',
    GENERAL_LEDGER = 'GL',
    DEBT = 'D',
}

export const dataPointTypeTitles = new Map<DataPointType, string>([
    [DataPointType.TRIAL_BALANCE, 'Trial Balance'],
    [DataPointType.TRIAL_BALANCE_BUDGET, 'Trial Balance Budget'],
    [DataPointType.RENT_ROLL, 'Rent Roll'],
    [DataPointType.VARIANCE_NOTES, 'Variance Report'],
    [DataPointType.UNRECOGNIZED, 'Other'],
    [DataPointType.UNDERWRITING, 'Underwriting'],
    [DataPointType.GENERAL_LEDGER, 'General Ledger'],
    [DataPointType.DEBT, 'Debt'],
])

export enum DataPointStatus {
    PROCESSING = 'P',
    DONE = 'D',
    ERROR = 'E',
    INTERNAL_ERROR = 'I',
    WAITING_INPUT = 'W',
    CANCELED = 'C',
}

export enum DataPointSource {
    UPLOADER = 'D',
    EMAIL = 'E',
}

@api('data-point')
@model
export class DataPoint extends Model {
    @field etl_data_point_id?: number
    @field submitted_file_id?: number
    @field status?:            DataPointStatus

    @field type?:                 DataPointType
    @field asset_id?:             number
    @field report_date?:          string
    @field as_of_date?:           string
    @field as_of_date_from_file?: string
    @field s3_bucket_name?:       string
    @field s3_file_name?:         string
    @field initial_file_name?:    string

    @field etl_created_at?:   string
    @field etl_finished_at?:  string
    @field created_at?:       string
    @field web_triggered_at?: string
    @field finished_at?:      string
    @field source?:           DataPointSource

    @field errors_num?:            number
    @field submission_errors_num?: number
    @field quality_errors_num?:    number

    @foreign(Asset) readonly asset:                  Asset
    @foreign(SubmittedFile) readonly submitted_file: SubmittedFile

    /**
     * Email from which the data was uploaded
     */
    source_email?: string

    errors: DataPointError[]

    get have_internal_error_status () {
        return this.status === DataPointStatus.INTERNAL_ERROR
    }
}
