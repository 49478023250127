import { getRequestQueries } from '@/api/utils/getRequestQueries'

export interface ReportingRentRollMetricsCommercialRow {
    asset_id: number
    asset_name: string
    asset_city?: string
    asset_state?: string
    asset_property_manager_company?: string
    asset_manager_name?: string
    asset_fund_name?: string
    asset_custom_id1?: string
    asset_custom_id2?: string
    asset_software?: string
    asset_year_built?: number
    asset_submarket?: string
    asset_subasset_class?: string
    total_sqft?: number
    occupied_sqft?: number
    ending_occupancy?: number
    in_place_rent?: number
}

export interface ReportingRentRollMetricsCommercialColumn {
    occupied_sqft: ReportingRentRollMetricsCommercialColumnValues
    total_sqft: ReportingRentRollMetricsCommercialColumnValues
}

export interface ReportingRentRollMetricsCommercialColumnValues {
    key: string
    time_period: string
    metric_type?: number
}

export interface ReportingRentRollMetricsCommercialItem {
    columns: ReportingRentRollMetricsCommercialColumn[]
    rows: ReportingRentRollMetricsCommercialRow[]
}

export const REPORTING_RENT_ROLL_METRICS_COMMERCIAL_URL = 'reporting/rent-roll-metrics-commercial'

export const {
    useItemsQuery: useReportingRentRollMetricsCommercialQuery,
} = getRequestQueries<ReportingRentRollMetricsCommercialItem, Record<string, unknown>>({
    url: REPORTING_RENT_ROLL_METRICS_COMMERCIAL_URL,
    extractItemsFn: (data: ReportingRentRollMetricsCommercialItem) => [data],
})
