import { CUSTOM_REPORTS_ROUTES_CONFIG } from '@/core/routes/pages/customReports'
import { DATA_SUBMISSION_ROUTES_CONFIG } from '@/core/routes/pages/dataSubmission'
import { EXCEL_ROUTES_CONFIG } from '@/core/routes/pages/excel'
import { PACKAGE_ROUTES_CONFIG } from '@/core/routes/pages/packages'
import { REPORTS_FINANCIALS_ROUTES_CONFIG } from '@/core/routes/pages/reportsFinancials'
import { TABLES_ROUTES_CONFIG } from '@/core/routes/pages/tables'
import { UPLOADS_ROUTES_CONFIG } from '@/core/routes/pages/uploads'

import {
    AdminRoutesConfig,
    OwnerRoutesConfig,
    ManagerRoutesConfig,
    CommonRoutesConfig,
} from './routes.types'

/**
 * ADMIN routes config which contains all information associated with every route
 *
 * @todo Show no access page if parent have no access (not it's strict check and we need to add feature flag for every route)
 * @todo: Move routes to files in './pages'
 **/
export const ADMIN_ROUTES_CONFIG: AdminRoutesConfig = {
    ACCOUNTS_GROUPS: {
        title: 'Accounts Groups',
        path: '/accounts-groups',
    },
    AI_CHAT: {
        title: 'AI Chat',
        path: '/ai-chat',
    },
    VALIDATION_TRACKER: {
        title: 'Submission Tracker',
        path: '/data-submission/validation-tracker',
    },
    VALIDATION_TRACKER_ERRORS: {
        title: 'Submission Tracker Day',
        path: '/data-submission/validation-tracker/:dataPointId',
    },
    VALIDATION_TRACKER_MONTHLY_RENT_ROLL: {
        title: 'Submission Tracker Monthly Rent Roll',
        path: '/data-submission/validation-tracker/rr/:reportAssetId/:reportYear/:reportMonth',
    },
    ASSETS: {
        title: 'Assets',
        path: '/assets',
        metricData: {
            pageViewEventName: 'Admin View',
            pageName: 'Assets',
        },
    },
    ASSETS_NEW: {
        title: 'Assets New',
        path: '/assets/new',
    },
    ASSETS_EDIT: {
        title: 'Asset Edit',
        path: '/assets/:assetId/edit',
    },
    ASSETS_DELETE: {
        title: 'Delete Assets',
        path: '/assets/:assetId/edit/delete',
    },
    LEDGER: {
        title: 'Ledger',
        path: '/ledger',
    },
    COMPANIES: {
        title: 'Companies List',
        path: '/companies',
        staffOnly: true,
    },
    COMPANY_DETAILS: {
        title: 'Company Details',
        path: '/companies/:companyId/details',
        staffOnly: true,
    },
    COMPANY_USERS: {
        title: 'Company Users',
        path: '/companies/:companyId/users',
        staffOnly: true,
    },
    COMPANY_USER: {
        title: 'Company User',
        path: '/companies/:companyId/users/:userId',
        staffOnly: true,
    },
    COMPANY_USER_INVITES: {
        title: 'User Invites',
        path: '/companies/:companyId/user-invites',
    },
    COMPANY_USER_INVITES_NEW: {
        title: 'New User Invite',
        path: '/companies/:companyId/user-invites/new',
    },
    DATA_SUBMISSION: {
        title: 'Submissions',
        path: '/data-submission',
    },
    DATA_SUBMISSION_UPLOADS: {
        title: 'Uploads',
        path: '/data-submission/uploads',
    },
    DATA_SUBMISSION_ERRORS: {
        title: 'Data Submission Errors',
        path: '/data-submission/uploads/:submittedFileId/errors',
    },
    LOG_HISTORY: {
        title: 'Log History',
        path: '/log-history',
    },
    CSV_BUILDER: {
        title: 'Export',
        path: '/csv-builder',
    },
    CSV_BUILDER_FILES: {
        title: 'Files',
        path: '/csv-builder/files',
        metricData: {
            pageViewEventName: 'Export View',
            pageName: 'Files',
        },
    },
    CSV_BUILDER_FILES_DELETE: {
        title: 'Delete File',
        path: '/csv-builder/files/delete/:id',
    },
    CSV_BUILDER_FILES_EDIT: {
        title: 'Rename File',
        path: '/csv-builder/files/edit/:id',
        metricData: {
            pageViewEventName: 'Export View',
        },
    },
    CSV_BUILDER_RENT_ROLL: {
        title: 'Rent Roll Builder',
        path: '/csv-builder/rent-roll',
        metricData: {
            pageViewEventName: 'Export View',
            pageName: 'Rent Roll Builder',
        },
    },
    CSV_BUILDER_TRIAL_BALANCE: {
        title: 'Trial Balance Builder',
        path: '/csv-builder/trial-balance',
        metricData: {
            pageViewEventName: 'Export View',
            pageName: 'Trial Balance Builder',
        },
    },
    CSV_BUILDER_BUDGET: {
        title: 'Budget Builder',
        path: '/csv-builder/budget',
        metricData: {
            pageViewEventName: 'Export View',
            pageName: 'Budget Builder',
        },
    },
    CSV_BUILDER_EXPORT: {
        title: 'Export',
        path: '/csv-builder/export',
    },
    SETTINGS: {
        title: 'Settings',
        path: '/settings',
    },
    SETTINGS_AUTH: {
        title: 'Login',
        path: '/settings/login',
    },
    METRIC_BUILDER: {
        title: 'Metric Builder',
        path: '/metric-builder',
    },
    METRIC_BUILDER_EDIT: {
        title: 'Edit Metric',
        path: '/metric-builder/:id',
        metricData: {
            pageViewEventName: 'Metric Builder View',
            pageName: 'Metric Builder - Edit',
        },
    },
    EMAIL_TRACKER: {
        title: 'Email Tracker',
        path: '/email-tracker',
    },
    ...TABLES_ROUTES_CONFIG,
}

/**
 * USER routes config which contains all information associated with every route
 *
 * @todo: Move routes to files in './pages'
 **/
export const OWNER_ROUTES_CONFIG: OwnerRoutesConfig = {
    AUTH_PASSWORD_RESET: {
        title: 'Reset Password',
        path: '/auth/password/reset/',
    },
    USER_PROFILE: {
        title: 'Account Settings',
        path: '/user-profile',
        metricData: {
            pageViewEventName: 'Admin View',
            pageName: 'User Profile',
        },
    },
    COMPANY_PROFILE: {
        title: 'Company Profile',
        path: '/company-profile',
    },
    COMPANY_PROFILE_DETAILS: {
        title: 'Details',
        path: '/company-profile/details',
        metricData: {
            pageViewEventName: 'Admin View',
            pageName: 'Company Profile',
        },
    },
    COMPANY_PROFILE_MEMBERS: {
        title: 'Members',
        path: '/company-profile/members',
    },
    COMPANY_PROFILE_MEMBERS_BY_ID: {
        title: 'Company User Info',
        path: '/company-profile/members/:userId',
    },
    COMPANY_PROFILE_INVITES: {
        title: 'Invites',
        path: '/company-profile/invites',
        staffOnly: true,
    },
    COMPANY_PROFILE_INVITES_NEW: {
        title: 'Invite New Member',
        path: '/company-profile/invites/new',
        staffOnly: true,
    },
    ADMIN_CENTER_ASSETS: {
        title: 'Assets',
        path: '/admin-center/assets',
    },
    ADMIN_CENTER_ASSETS_NEW: {
        title: 'Assets New',
        path: '/admin-center/assets/new',
    },
    ADMIN_CENTER_ASSETS_EDIT: {
        title: 'Assets Edit',
        path: '/admin-center/assets/:assetId/edit',
    },
    ADMIN_CENTER_ASSETS_DELETE: {
        title: 'Assets Delete',
        path: '/admin-center/assets/:objectId/delete',
    },
    LEDGERS: {
        title: 'Ledgers',
        path: '/ledgers',
    },
    LEDGERS_TRIAL_BALANCE: {
        title: 'Chart of Accounts',
        path: '/ledgers/trial-balance/',
        metricData: {
            pageViewEventName: 'Admin View',
            pageName: 'Ledgers',
        },
    },
    LEDGERS_RENT_ROLL: {
        title: 'Rent Roll Charges',
        path: '/ledgers/rent-roll/',
        featureFlags: ['rent_roll'],
    },
    ASSET_MAPPING: {
        title: 'Mapping',
        path: '/mapping',
    },
    ASSET_MAPPING_TEMPLATES: {
        title: 'Mapping Templates',
        path: '/mapping/templates',
        metricData: {
            pageViewEventName: 'Mapping View',
            pageName: 'Mapping Templates',
        },
    },
    ASSET_MAPPING_TEMPLATES_BY_ID: {
        title: 'Mapping Template',
        path: '/mapping/templates/:templateId',
    },
    ASSET_MAPPING_TEMPLATES_APPLY: {
        title: 'Apply Template',
        path: '/mapping/templates/:templateId/apply',
    },
    ASSET_MAPPING_TEMPLATES_BY_ID_ADD_MAPPING: {
        title: 'Mapping Template: Change Mapping',
        path: '/mapping/templates/:templateId/:mappingId',
    },
    ASSET_MAPPING_TEMPLATES_BY_ID_DELETE_MAPPING: {
        title: 'Mapping Template: Delete Mapping',
        path: '/mapping/templates/:templateId/:mappingId/delete',
    },
    ASSET_MAPPING_TEMPLATES_BY_ID_UPLOAD: {
        title: 'Mapping Template: Upload a mapping file',
        path: '/mapping/templates/:templateId/upload',
    },
    ASSET_MAPPING_TEMPLATES_CREATE: {
        title: 'Mapping Templates: Creation',
        path: '/mapping/templates/create',
    },
    ASSET_MAPPING_TRIAL_BALANCE: {
        title: 'Trial Balance',
        path: '/mapping/trial-balance',
        metricData: {
            pageViewEventName: 'Mapping View',
            pageName: 'Mapping Trial Balance',
        },
    },
    ASSET_MAPPING_RENT_ROLL: {
        title: 'Rent Roll',
        path: '/mapping/rent-roll',
        featureFlags: ['rent_roll'],
        metricData: {
            pageViewEventName: 'Mapping View',
            pageName: 'Mapping Rent Roll',
        },
    },
    ASSET_MAPPING_HISTORY: {
        title: 'Mapping History',
        path: '/mapping/history',
        featureFlags: ['mapping_history'],
        metricData: {
            pageViewEventName: 'Mapping View',
            pageName: 'Mapping History',
        },
    },
    CSV_BUILDER: {
        title: 'Export',
        path: '/csv-builder',
    },
    CSV_BUILDER_FILES: {
        title: 'Files',
        path: '/csv-builder/files',
        metricData: {
            pageViewEventName: 'Export View',
            pageName: 'Files',
        },
    },
    CSV_BUILDER_FILES_DELETE: {
        title: 'Delete File',
        path: '/csv-builder/files/delete/:id',
    },
    CSV_BUILDER_FILES_EDIT: {
        title: 'Rename File',
        path: '/csv-builder/files/edit/:id',
    },
    CSV_BUILDER_RENT_ROLL: {
        title: 'Rent Roll Builder',
        path: '/csv-builder/rent-roll',
        featureFlags: ['rent_roll'],
        metricData: {
            pageViewEventName: 'Export View',
            pageName: 'Rent Roll Builder',
        },
    },
    CSV_BUILDER_TRIAL_BALANCE: {
        title: 'Trial Balance Builder',
        path: '/csv-builder/trial-balance',
        metricData: {
            pageViewEventName: 'Export View',
            pageName: 'Trial Balance Builder',
        },
    },
    CSV_BUILDER_BUDGET: {
        title: 'Budget Builder',
        path: '/csv-builder/budget',
        metricData: {
            pageViewEventName: 'Export View',
            pageName: 'Budget Builder',
        },
    },
    CSV_BUILDER_EXPORT: {
        title: 'Export',
        path: '/csv-builder/export',
    },
    REPORTS: {
        title: 'Reports',
        path: '/reports',
        featureFlags: ['reporting'],
    },
    REPORTS_RENT_ROLL: {
        title: 'Rent Roll',
        path: '/reports/rent-roll',
        featureFlags: ['rent_roll'],
    },
    REPORTS_RENT_ROLL_RENT_ROLL: {
        title: 'Rent Roll',
        path: '/reports/rent-roll/rent-roll',
        featureFlags: ['rent_roll'],
    },
    REPORTS_RENT_ROLL_OCCUPANCY: {
        title: 'Occupancy',
        path: '/reports/rent-roll/occupancy',
        featureFlags: ['rent_roll', 'report_occupancy'],
    },
    REPORTS_RENT_ROLL_LEASE_EXPIRATIONS: {
        title: 'Lease Expirations',
        path: '/reports/rent-roll/lease-expirations',
        featureFlags: ['report_lease_expiration'],
    },
    REPORTS_RENT_ROLL_TOP_TENATS: {
        title: 'Top Tenants',
        path: '/reports/rent-roll/top-tenants',
        featureFlags: ['report_top_tenants'],
    },
    ...PACKAGE_ROUTES_CONFIG,
    ...UPLOADS_ROUTES_CONFIG,
    ...REPORTS_FINANCIALS_ROUTES_CONFIG,
    ...TABLES_ROUTES_CONFIG,
    ...DATA_SUBMISSION_ROUTES_CONFIG,
    ...CUSTOM_REPORTS_ROUTES_CONFIG,
}

/**
 * MANAGER PORTAL USER routes config which contains all information associated with every route
 *
 * @todo: Move routes to files in './pages'
 **/
export const MANAGER_ROUTES_CONFIG: ManagerRoutesConfig = {
    ASSETS: {
        title: 'Assets',
        path: '/assets',
    },
    ASSETS_EDIT: {
        title: 'Assets Edit',
        path: '/assets/:assetId/edit',
    },
    ASSETS_DELETE: {
        title: 'Assets Delete',
        path: '/assets/:objectId/delete',
    },
    USER_PROFILE: {
        title: 'Account Settings',
        path: '/user-profile',
    },
    COMPANY_PROFILE: {
        title: 'Company Profile',
        path: '/company-profile',
    },
    COMPANY_PROFILE_DETAILS: {
        title: 'Details',
        path: '/company-profile/details',
    },
    COMPANY_PROFILE_MEMBERS: {
        title: 'Members',
        path: '/company-profile/members',
    },
    COMPANY_PROFILE_MEMBERS_BY_ID: {
        title: 'Company User Info',
        path: '/company-profile/members/:userId',
    },
    COMPANY_PROFILE_INVITES: {
        title: 'Invites',
        path: '/company-profile/invites',
    },
    COMPANY_PROFILE_INVITES_NEW: {
        title: 'Invite New Member',
        path: '/company-profile/invites/new',
    },
    UPLOADS_SYNC: {
        title: 'Data Sync',
        path: '/data-submission/uploads/sync',
    },
    ...PACKAGE_ROUTES_CONFIG,
    ...DATA_SUBMISSION_ROUTES_CONFIG,
    ...UPLOADS_ROUTES_CONFIG,
}

/**
 * Common routes available for more than one portal
 */
export const COMMON_ROUTES_CONFIG: CommonRoutesConfig = {
    NO_ACCESS_TO_PORTAL: {
        title: 'No Access to Portal',
        path: '/no-access-to-portal',
    },
    ...EXCEL_ROUTES_CONFIG,
}
