import { FC, useEffect, useState } from 'react'

import cn from 'classnames'
import format from 'date-fns/format'
import { runInAction } from 'mobx'

import { observer } from 'mobx-react-lite'

import { Button, Loader, LegacySelect, TextInput, Text, DatePicker } from '@/components/base'
import AccountCategoryInput from '@/components/legacy/inputs/AccountCategoryInput'
import { AssetTypeSelectLegacy } from '@/components/legacy/models/asset/AssetTypeSelectLegacy'
import { ObjectFormLegacyError, ObjectFormLegacyProps } from '@/components/legacy/models/ObjectFormLegacy'
import { DATE_FNS_SERVER_DATE } from '@/constants/dates'
import { normalizeShortBackDate } from '@/utils/date/normalizeShortBackDate'

import styles from './ObjectFormLegacy.module.scss'

/**
 * Cheking validation errors format
 */
const isValidationErrorsObject = (obj: unknown): boolean => {
    if (!obj) {
        return false
    }

    // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
    if (Array.isArray(obj[Object.keys(obj)[0]])) {
        return true
    }

    return false
}

/**
 * @deprecated Use ObjectForm instead
 */
export const ObjectFormLegacy: FC<ObjectFormLegacyProps> = observer((props) => {
    const {
        obj,
        fields,
        title,
        spinnerOff = false,
        onLoading,
        onCancel,
        onSubmit,
        hideButtons,
        customFieldErrors,
        createButtonText = 'Create',
        noCancelButton = false,
    } = props

    const [fieldsErrors, setFieldsErrors] = useState<ObjectFormLegacyError>({})
    const [isLoading, setIsLoading] = useState(false)
    const isEditMode = !!obj.id

    useEffect(() => {
        if (isValidationErrorsObject(customFieldErrors)) {
            // @ts-expect-error TS(2345) FIXME: Argument of type 'ObjectFormLegacyError[] | undefi... Remove this comment to see the full error message
            setFieldsErrors(customFieldErrors)
        }
    }, [customFieldErrors])

    const submit = async () => {
        try {
            setIsLoading(true)
            onLoading?.(true)
            await obj.save()
            onSubmit?.(obj)
            setFieldsErrors({})
        } catch (err) {
            if (isValidationErrorsObject(err.response.data)) {
                setFieldsErrors(err.response.data)
            } else {
                console.error('Unexpected errors format', err)
            }
        } finally {
            onLoading?.(false)
            setIsLoading(false)
        }
    }

    return (
        <div>
            {
                title &&
                <div className={styles.title}>{title}</div>
            }
            {
                isLoading && !spinnerOff &&
                <div className='page-dialog-overlay'><div><Loader/></div></div>
            }
            {
                'non_field_errors' in fieldsErrors && (
                    <div className={styles.nonFieldErrors}>
                        {(fieldsErrors.non_field_errors as []).map((errorText, i) => <div key={i}>{errorText}</div>)}
                    </div>
                )}
            {[...fields].map(([fieldName, field]) => {
                const { type } = field

                const getLabel = ({ title, required }) => `${title}${required ? ' (Required)' : ''}`

                return (
                    <div
                        className={styles.inputContainer}
                        key={fieldName}
                    >
                        {field.type === 'label' && (
                            <div className={styles.labelContainer}>
                                <div className={styles.labelTitle}>
                                    <Text
                                        text={getLabel({
                                            title: field.title,
                                            required: field.required,
                                        }) + ':'}
                                        variant='smallTextMediumDefault'
                                        color='secondaryGray'
                                    />
                                </div>
                                <div className={styles.labelText}>
                                    <Text
                                        text={obj[fieldName]}
                                        color='secondaryBlack'
                                        variant='smallTextSemiboldDefault'
                                    />
                                </div>
                            </div>
                        )}

                        {field.type === 'string' && (
                            <TextInput
                                onChange={event => runInAction(() => {
                                    obj[fieldName] = event.target.value
                                })}
                                value={obj[fieldName]}
                                placeholder={field.placeholder}
                                label={getLabel({
                                    title: field.title,
                                    required: field.required,
                                })}
                                error={fieldName in fieldsErrors}
                                dataTestId = {`form-field-${fieldName}`}
                                disabled={field.disabled}
                                readonly={field.readonly}
                            />
                        )}
                        {(type === 'select-new') && (
                            <LegacySelect
                                {...field.inputProps}
                                onChange={(...args) => {
                                    const { value } = args[0]

                                    if (field.useCustomOnChange) {
                                        field.inputProps.onChange?.(...args)
                                    } else {
                                        runInAction(() => {
                                            obj[fieldName] = value
                                        })
                                    }
                                }}
                                customValue={obj[fieldName]}
                                placeholder={field.inputProps.placeholder}
                                error={fieldName in fieldsErrors}
                            />
                        )}
                        {field.type === 'asset-type-select' && (
                            <AssetTypeSelectLegacy
                                onChange={({ value }) => {
                                    runInAction(() => (obj[fieldName] = value))
                                }}
                                customValue={obj[fieldName]}
                                label={getLabel({
                                    title: field.title,
                                    required: field.required,
                                })}
                                readonly={field.readonly}
                            />
                        )}
                        {
                            // REFACTOR: add styles to this select and create separate component
                            (field.type === 'select') && (
                                <>
                                    <div className={styles.inputTitle}>{getLabel({
                                        title: field.title,
                                        required: field.required,
                                    })}</div>
                                    <select
                                        value={obj[fieldName]}
                                        onChange={event => runInAction(() => {
                                            obj[fieldName] = event.target.value
                                        })}
                                        className={cn(styles.inputSelect, field.className, { [styles.inputError]: fieldName in fieldsErrors })}
                                    >
                                        <option value=''>Select</option>
                                        {field.options?.map(option => (
                                            <option
                                                value={option.value}
                                                key={option.value}
                                            >{option.title}</option>
                                        ),
                                        )}
                                    </select>
                                </>
                            )
                        }
                        {
                            // REFACTORING: should we use special types for special inputs?
                            field.type === 'account_category' && (
                                <>
                                    <div className={styles.inputTitle}>{getLabel({
                                        title: field.title,
                                        required: field.required,
                                    })}</div>
                                    <AccountCategoryInput
                                        value={obj[fieldName]}
                                        placeholder={field.placeholder}
                                        readonly={field.readonly}
                                        onChange={value => runInAction(() => {
                                        // TODO: band-aid
                                            obj[`${fieldName}_id`] = value.id
                                        })}
                                        {...('inputParams' in field ? field.inputParams : {})}
                                    />
                                </>
                            )
                        }
                        {
                            field.type === 'serverDate' && (
                                <DatePicker
                                    width='100%'
                                    label={field.title}
                                    date={obj[fieldName] ? normalizeShortBackDate(obj[fieldName]) : undefined}
                                    onChange={date => runInAction(() => {
                                        obj[fieldName] = date ? format(date, DATE_FNS_SERVER_DATE) : undefined
                                    })}
                                    {...field.inputProps}
                                />
                            )
                        }
                        {
                            fieldName in fieldsErrors &&
                            <div className={styles.fieldErrorText}>{fieldsErrors[fieldName]}</div>
                        }
                    </div>
                )
            },
            )}
            {!hideButtons && (
                <div className={styles.buttons}>
                    {!noCancelButton && (
                        <Button
                            onClick={onCancel}
                            text='Cancel'
                            loading={isLoading}
                            theme='secondary'
                        />
                    )}
                    <Button
                        onClick={submit}
                        text={isEditMode ? 'Save' : createButtonText}
                        loading={isLoading}
                    />
                </div>
            )}
        </div>
    )
})
