import { getRequestQueries } from '@/api/utils/getRequestQueries'

export interface ReportingDebtItem {
    rows: ReportingDebtRow[]
}

export interface ReportingDebtRow {
    asset_id: number
    report_id: number
    period: string
    loan_order?: number
    lender_name?: string
    close_date?: string
    initially_funded_amount?: number
    future_funding_commitment?: number
    rate_type?: string
    rate_benchmark?: string
    benchmark_floor?: number
    base_rate?: number
    spread?: number
    cap_level?: number
    cap_expiration_date?: string
    cap_notes?: string
    initial_maturity?: string
    fully_extended_maturity?: string
    extension_fee?: number
    extension_description?: string
    amortization_start?: string
    open_prepayment_date?: string
    prepayment_fees_description?: string
    dscr_covenant?: string
    debt_yield_covenant?: string
    covenants_description?: string
    guaranty_description?: string
    notes_general?: string
    as_of_date?: string
    asset_name?: string
    city?: string
    state?: string
    asset_class?: string
    manager?: string
    fund?: string
    units?: number
    square_feet?: number
    intelas_property_id?: number
}

export const REPORTING_DEBT_URL = 'reporting/debt'

export const {
    useItemsQuery: useReportingDebtQuery,
} = getRequestQueries<ReportingDebtRow, Record<string, unknown>>({
    url: REPORTING_DEBT_URL,
    extractItemsFn: (data: ReportingDebtItem) => data.rows,
})
