import React, { useMemo, useRef, useState } from 'react'

import { Tooltip } from '@mui/material'

import { useTrialBalanceLedgerByIdQuery } from '@/api/ledger/trialBalanceLedger'
import { useRentRollLedgerByIdQuery } from '@/api/rentRoll/rentRollLedger'
import { useSourceAccountMapPredictionsQuery } from '@/api/trialBalance/sourceAccountMapPredictions'
import { useTrialBalanceTemplateByIdQuery } from '@/api/trialBalance/trialBalanceTemplate'
import { useTrialBalanceTemplateDiffQuery } from '@/api/trialBalance/trialBalanceTemplateDiff'
import {
    BuiltInSearchInput,
    Button,
    Icon,
    Line,
    Loader,
    SelectOptionProps,
    SelectOptionsGroupTitle,
} from '@/components/base'
import popoverStyles from '@/components/base/Popover/Popover.module.scss'
import { ListHeader } from '@/components/base/Select/headers'
import { SelectOptionBase, SelectOptionWithLabel } from '@/components/base/Select/options'
import { SelectOptionListBase } from '@/components/base/Select/optionsList'
import { Layout, SideModalContainer } from '@/components/containers'
import {
    MappingEngineSourceAccounts,
    MappingEngineSuggest,
    MappingEngineMode,
} from '@/components/widgets/MappingEngineSideModal'
import {
    MAPPING_ENGINE_ICON_BY_MODE,
    MAPPING_ENGINE_MODE_LOCAL_STORAGE_KEY,
    MAPPING_ENGINE_ONE_MODULE_WIDTH,
} from '@/components/widgets/MappingEngineSideModal/MappingEngineSideModal.constants'
import {
    getAccountItemLabel,
    selectOptionsMappingLabelProps,
} from '@/components/widgets/MappingEngineSideModal/MappingEngineSideModal.utils'
import { useMe } from '@/hooks/core/useMe'
import { useLocalStorage } from '@/hooks/useLocalStorage'
import { Account as TrialBalanceAccount } from '@/models/ledger'
import { Account as RentRollAccount } from '@/models/rent_roll'

import { MappingEngineSideModalProps } from './MappingEngineSideModal.types'

type Account = RentRollAccount | TrialBalanceAccount

export const MappingEngineSideModal = (props: MappingEngineSideModalProps) => {
    const {
        accountType,
        onChange,
        accounts,
        customLedgerId,
        selectedAccount,
        onClose,
        haveMapping,
        sourceAccountData,
        syncTemplates,
        rowData,
    } = props

    const { me } = useMe()

    const [mode, setMode] = useLocalStorage<MappingEngineMode>(MAPPING_ENGINE_MODE_LOCAL_STORAGE_KEY, MappingEngineMode.twoCols)

    const isTrialBalance = accountType === 'trialBalance'

    const isAiSuggestTurnedOn = Boolean(me.company?.features?.ai_suggestions) && !haveMapping && isTrialBalance
    const isMappedSourceAccountsOn = Boolean(me.company?.features?.mapping_engine_source_accounts)

    const [filterValue, setFilterValue] = useState<string>('')
    const [newSelectedAccount, setNewSelectedAccount] = useState<Account | undefined>(selectedAccount)
    const [isSaving, setIsSaving] = useState<boolean>(false)

    const trialBalanceLegerQuery = useTrialBalanceLedgerByIdQuery(customLedgerId as number, {
        enabled: Boolean(isTrialBalance && customLedgerId),
    })

    const rentRollLegerQuery = useRentRollLedgerByIdQuery(customLedgerId as number, {
        enabled: Boolean(!isTrialBalance && customLedgerId),
    })

    const ledgerData = trialBalanceLegerQuery.data || rentRollLegerQuery.data

    const filterRegex = new RegExp(filterValue, 'i')
    const filteredOptions = accounts.filter((option) => !filterValue || filterRegex.test(getAccountItemLabel(option)))

    const sourceAccountMapPredictionsQuery = useSourceAccountMapPredictionsQuery({
        queryParams: {
            source_account_id: sourceAccountData?.id as number,
            ledger_id: ledgerData?.id as number,
        },
    }, {
        enabled: isAiSuggestTurnedOn && Boolean(sourceAccountData?.id) && Boolean(ledgerData?.id),
    })

    const handleSave = (autoApply = false) => async () => {
        setIsSaving(true)
        await onChange(newSelectedAccount, Boolean(selectedAccount), autoApply)
        setIsSaving(false)
    }

    const handleSelect = (account: Account) => {
        const isAlreadySelected = account?.id === newSelectedAccount?.id
        const newValue = isAlreadySelected ? undefined : account
        setNewSelectedAccount(newValue)
    }

    const handleModeChange = () => {
        const supportedModesOrder: MappingEngineMode[] = [
            MappingEngineMode.oneCol,
        ]

        if (isMappedSourceAccountsOn) {
            supportedModesOrder.push(MappingEngineMode.twoCols)
        }

        const currentModeIndex = supportedModesOrder.indexOf(mode)
        const nextModeIndex = (currentModeIndex + 1) % supportedModesOrder.length
        setMode(supportedModesOrder[nextModeIndex])
    }

    const SelectOption = useMemo(() => (props: SelectOptionProps<Account>) => {
        const suggestedItem = sourceAccountMapPredictionsQuery.data?.find((item) => item.account.id === props.option.id)

        return (
            suggestedItem ? (
                <SelectOptionWithLabel
                    {...selectOptionsMappingLabelProps(suggestedItem)}
                    {...props}
                />
            ) : <SelectOptionBase {...props}/>
        )
    }, [sourceAccountMapPredictionsQuery.data])

    const modeIcon = MAPPING_ENGINE_ICON_BY_MODE[mode]

    const trialBalanceTemplateDiffQuery = useTrialBalanceTemplateDiffQuery({
        queryParams: {
            source_account_id: sourceAccountData?.id as number,
            account_id: newSelectedAccount?.id as number,
        },
    }, {
        enabled: !!(sourceAccountData?.id && newSelectedAccount?.id) && syncTemplates,
    })

    const assetName = rowData?.asset_name

    const conflictedTemplateId = trialBalanceTemplateDiffQuery.data?.[0]?.templates[0]?.id
    const trialBalanceTemplateByIdQuery = useTrialBalanceTemplateByIdQuery(conflictedTemplateId, {
        enabled: !!conflictedTemplateId,
    })

    const mappingTemplateName = trialBalanceTemplateByIdQuery.data?.name

    const saveAndAddButtonHint = sourceAccountData && newSelectedAccount ? (
        <span>
            Map the source account “{getAccountItemLabel(sourceAccountData) || '—'}” to the target account “{getAccountItemLabel(newSelectedAccount) || 'unmapped'}” for the asset “{assetName}”.
            <br/><br/>
            The mapping will be added to the template  “{mappingTemplateName}”.
            <br/><br/>
            Note: To apply the mapping to other assets assigned to the same template, proceed to  Mapping Templates and apply the template.
        </span>
    ) : null

    const saveButtonHint = sourceAccountData && newSelectedAccount ? (
        <span>
            Map the source account “{getAccountItemLabel(sourceAccountData) || '—'}” to the target account “{getAccountItemLabel(newSelectedAccount) || 'unmapped'}” for the asset “{assetName}”.
            <br/><br/>
            The mapping will not be added to the template.
        </span>
    ) : null

    const prevHasTemplateConflicts = useRef(false)

    const hasTemplateConflict = useMemo(() => {
        if (!trialBalanceTemplateDiffQuery.dataUpdatedAt) return prevHasTemplateConflicts.current
        const res = !!conflictedTemplateId
        prevHasTemplateConflicts.current = res
        return res
    }, [trialBalanceTemplateDiffQuery.dataUpdatedAt, conflictedTemplateId])

    const content = sourceAccountData ? (
        <>
            <ListHeader
                content={[
                    `Source Account: ${getAccountItemLabel(sourceAccountData) || '—'}`,
                    `Current mapping: ${newSelectedAccount ? getAccountItemLabel(newSelectedAccount) : 'unmapped'}`,
                ]}
                endContent={(
                    <Layout gap={8}>
                        {(modeIcon && isMappedSourceAccountsOn && isTrialBalance) && <Icon name={modeIcon} color='colorsSecondaryGrey600' onClick={handleModeChange}/>}
                        <Icon name='cross' onClick={onClose} color='colorsSecondaryGrey600'/>
                    </Layout>
                )}
            />
            {(isAiSuggestTurnedOn && !!sourceAccountMapPredictionsQuery.data?.length) && (
                <>
                    <SelectOptionsGroupTitle text='Suggested by Intelas'/>
                    <MappingEngineSuggest
                        sourceAccountData={sourceAccountData}
                        selected={newSelectedAccount}
                        onSelect={handleSelect}
                        getLabel={getAccountItemLabel}
                        suggestItems={sourceAccountMapPredictionsQuery.data}
                    />
                    <Line/>
                </>
            )}
            <SelectOptionsGroupTitle
                text={`All Target Accounts${ledgerData?.name ? ' in ' + ledgerData.name : ''}`}
            />
            <BuiltInSearchInput
                onChange={(value) => {
                    setFilterValue(value)
                }}
                value={filterValue}
                placeholder='Search'
                dataTestId='search'
            />
            <Layout flexGrow={1} scroll='y'>
                <SelectOptionListBase<Account>
                    width='100%'
                    optionComponent={SelectOption}
                    options={filteredOptions}
                    selected={newSelectedAccount ? [newSelectedAccount] : []}
                    labelFn={getAccountItemLabel}
                    idFn={(account) => account?.id?.toString() || ''}
                    onChange={handleSelect}
                />
            </Layout>
            <Line/>
            <Layout px={12} py={8} justify='space-between'>
                {(newSelectedAccount?.id !== selectedAccount?.id && !(isSaving || trialBalanceTemplateDiffQuery.isLoading)) && (
                    <Button
                        text='Reset'
                        theme='flat'
                        disabled={isSaving}
                        onClick={() => {
                            setNewSelectedAccount(selectedAccount)
                        }}
                    />
                )}
                {
                    !!(isSaving || trialBalanceTemplateDiffQuery.isLoading) && (
                        <Icon style={{ marginTop: 5 }} name='loader' color='colorsPrimaryPurple'/>
                    )
                }
                <Layout flexGrow={1}/>
                <Layout
                    gap={8}
                >
                    <Layout gap={4}>
                        <Button
                            disabled={newSelectedAccount?.id === selectedAccount?.id || isSaving || trialBalanceTemplateDiffQuery.isLoading}
                            text='Save'
                            onClick={handleSave()}
                            theme={(hasTemplateConflict || trialBalanceTemplateDiffQuery.isLoading) ? 'secondary' : 'primary'}
                        />
                        <Tooltip
                            hidden={!saveButtonHint}
                            title={saveButtonHint}
                            placement='top-end'
                            arrow
                        >
                            <span style={{ paddingTop: 4 }}>
                                <Icon name='info' size='l' color='colorsSecondaryGrey500'/>
                            </span>
                        </Tooltip>
                    </Layout>
                    {(hasTemplateConflict && newSelectedAccount) && (
                        <Layout gap={4}>
                            <Button
                                disabled={newSelectedAccount?.id === selectedAccount?.id || isSaving || trialBalanceTemplateDiffQuery.isLoading}
                                text='Save and Add to Template'
                                onClick={handleSave(true)}
                                theme='primary'
                            />
                            <Tooltip
                                title={saveAndAddButtonHint}
                                hidden={!saveAndAddButtonHint}
                                placement='top-end'
                                arrow
                            >
                                <span style={{ paddingTop: 4 }}>
                                    <Icon name='info' size='l' color='colorsSecondaryGrey500'/>
                                </span>
                            </Tooltip>
                        </Layout>
                    )}
                </Layout>
            </Layout>
        </>
    ) : null

    return (
        <SideModalContainer
            onBgClick={onClose}
        >
            <Layout
                gap={4}
                my={4}
                mr={4}
            >
                <Layout
                    className={popoverStyles.popover}
                    direction='column'
                    width={MAPPING_ENGINE_ONE_MODULE_WIDTH}
                    relative
                >
                    {
                        (!sourceAccountMapPredictionsQuery.isFetched && isAiSuggestTurnedOn) ? (
                            <Loader centered/>
                        ) : (
                            content
                        )
                    }
                </Layout>

                {
                    ((mode === MappingEngineMode.twoCols) && isMappedSourceAccountsOn && isTrialBalance) && (
                        <MappingEngineSourceAccounts
                            ledgerData={ledgerData}
                            selectedAccount={newSelectedAccount as TrialBalanceAccount}
                        />
                    )
                }
            </Layout>
        </SideModalContainer>
    )
}
