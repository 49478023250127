import { SourceAccountMapPredictionsItem } from '@/api/trialBalance/sourceAccountMapPredictions'
import { LabelColors } from '@/components/base'
import { Account as TrialBalanceAccount } from '@/models/ledger'
import { Account as RentRollAccount } from '@/models/rent_roll'

type Account = RentRollAccount | TrialBalanceAccount

export const selectOptionsMappingLabelProps = (suggestedItem: SourceAccountMapPredictionsItem): { labelWidth: string, labelColor: LabelColors, labelText: string } => ({
    labelWidth: '44px',
    labelColor: suggestedItem.confidence_score >= 0.5 ? 'blue' : 'orange',
    labelText: suggestedItem.confidence_score >= 0.5 ? Math.floor(suggestedItem.confidence_score * 100) + '%' : '<50%',
})

// @ts-expect-error Need to create universal type for account
export const getAccountItemLabel = (account: Partial<Pick<Account, 'name' | 'code' | 'id'>>) => (account?.name || account?.code) ? `${account.code || ''} ${account.name || ''}`.trim() : ''
