import { useCallback, useMemo } from 'react'

import { useNavigate } from 'react-router'

import { Button, Link } from '@/components/base'
import { notify } from '@/utils/notify'
import { getRoute } from '@/utils/routing/getRoute'

import { TableChartButtonProps } from './TableChartButton.types'

export const TableChartButton = (props: TableChartButtonProps) => {
    const {
        tablesListRouteConfigKey,
        tableChartToggle: { hasChart, tableMode, toggleTableMode },
    } = props

    const navigate = useNavigate()

    const errorMessage = useMemo(() => (
        <span>
            Charts can only be created using Metric tables.<br/>
            <Link
                variant='bodyMedium'
                color='colorsSecondaryGrey600'
                onClick={() => navigate(getRoute(tablesListRouteConfigKey))
                }
            >
                [Go to Table Builder]
            </Link>
            &nbsp;to create a chart or use a Metric table.
        </span>
    ), [tablesListRouteConfigKey])

    const onClick = useCallback(() => {
        if (hasChart) {
            toggleTableMode?.()
        } else {
            notify.error(errorMessage, { autoClose: false })
        }
    }, [hasChart, toggleTableMode, errorMessage])

    const title = tableMode === 'table' ? 'View as Chart' : 'View as Table'
    const icon = tableMode === 'table' ? 'chart' : 'csv'

    return (
        <Button
            icon={icon}
            theme='secondary'
            onClick={onClick}
            title={title}
        />
    )
}
