import { MutableRefObject } from 'react'

import { CellClassParams, ColDef, ColumnEvent, ICellRendererParams, StateUpdatedEvent, ValueFormatterFunc, ValueGetterParams } from '@ag-grid-community/core'

import { ReportingPortfolioMetricsAggregation } from '@/api/reportingData/reportingPortfolioMetrics'
import { ReportingRentRollColumn, ReportingRentRollRow } from '@/api/reportingData/reportingRentRoll'
import { AgGridTableProps } from '@/components/tables'
import { formatMoney, formatPercentageFraction, formatString, formatDate, formatNumber } from '@/components/tables/AgGridTable/AgGridTable.utils'
import { FEB25_COL_TYPES } from '@/components/tables/AgGridTable/themes/feb25/feb25.constants'
import { Feb25Classes } from '@/components/tables/AgGridTable/themes/feb25/feb25.types'
import { ReportColumnAggregation } from '@/constants/reportColumnAggregation'
import { ReportingDataValueType } from '@/constants/reportingDataValueTypes'
import { excelStyles } from '@/constants/reportTable'

import { ConditionalFormatRule } from '../../ConditionalRuleEditor/ConditionalRuleEditor.types'
import { getAggFuncWithIntermediateCalcs, getWeightedAvgAggregation } from '../TableBuilderTables.utils'

import { cellRenderStyledFromConditionalFormatRules } from './PortfolioRentRollTable.utils'

// re-applies all data transformations
const refreshCells = (e: ColumnEvent) => e.api.refreshClientSideRowModel('aggregate')

const VALUE_TYPE_TO_FORMATTER: Record<`${ReportingDataValueType}`, ValueFormatterFunc<ReportingRentRollRow>> = {
    M: formatMoney,
    P: formatPercentageFraction,
    N: formatNumber,
    S: formatString,
    D: formatDate,
}

const getCellClass = (params: CellClassParams<ReportingRentRollRow>, valueType: `${ReportingDataValueType}`) => {
    const { column } = params
    const colId = column.getColId()

    const getExcelExportClass = () => {
        switch (valueType) {
            case 'N':
                return colId === 'lease_term_years' ? 'excelNumber1Decimal' : 'excelNumber'
            case 'P':
                return 'excelPercentageFraction'
            case 'D':
                return 'excelDate'
            case 'S':
                return 'excelString'
            case 'M':
            default:
                return 'excelCurrency'
        }
    }

    const alignmentClass = (colId === 'asset_name' || colId === 'tenant_name') ? '' : 'ag-right-aligned-cell'
    return [alignmentClass, getExcelExportClass()]
}

const getValue = (params: ValueGetterParams<ReportingRentRollRow>, valueType: `${ReportingDataValueType}`) => {
    const { column } = params
    const value = params?.data?.[column.getColId()]

    // return 0 for empty values of type M, N, P for proper formatting
    if ((valueType === 'M' || valueType === 'N' || valueType === 'P') && !value) {
        return 0
    }

    return value
}

const aggFunc: Record<ReportingPortfolioMetricsAggregation, ReportColumnAggregation> = {
    S: ReportColumnAggregation.SUM,
    A: ReportColumnAggregation.AVG,
    W: ReportColumnAggregation.SFAVG,
    // Non-implemented aggregations
    M: ReportColumnAggregation.SUM,
    N: ReportColumnAggregation.SUM,
    L: ReportColumnAggregation.SUM,
} as const

export const getPortfolioRentRollColDefs = ({ conditionalFormatRules = [], columns, visibleColumns = [] }: { conditionalFormatRules?: ConditionalFormatRule[], columns: ReportingRentRollColumn[], visibleColumns?: string[] }): ColDef[] => {
    const visibleColumnsSet = new Set(visibleColumns)
    return columns.map((column): ColDef => {
        return {
            headerName: column.can_be_grouped ? `${column.name} *` : column.name,
            field: column.key,
            hide: !visibleColumnsSet.has(column.key),
            enableRowGroup: column.can_be_grouped ?? false,
            aggFunc: column.key === 'unit_square_feet' ? getAggFuncWithIntermediateCalcs(aggFunc[column.aggregation!]) : aggFunc[column.aggregation!],
            valueFormatter: VALUE_TYPE_TO_FORMATTER[column.value_type],
            valueGetter: (params: ValueGetterParams<ReportingRentRollRow>) => getValue(params, column.value_type),
            // @ts-expect-error Custom property 'fractionDigits'
            fractionDigits: column.key === 'lease_term_years' ? 1 : undefined,
            filter: 'agSetColumnFilter',
            headerClass: (column.key === 'asset_name' || column.key === 'tenant_name') ? [] : ['ag-right-aligned-header'],
            cellClass: (params: CellClassParams<ReportingRentRollRow>) => [...getCellClass(params, column.value_type), column.key.startsWith('account_') ? Feb25Classes.Clickable : ''],
            cellRenderer: (params: ICellRendererParams<ReportingRentRollRow>) => cellRenderStyledFromConditionalFormatRules(conditionalFormatRules, params, column.key, columns),
        }
    })
}

export const gridOptions: Omit<AgGridTableProps<'feb25'>, 'items' | 'columnDefs'> = {
    theme: 'feb25',
    columnTypes: FEB25_COL_TYPES,
    rowHeight: 32,
    headerHeight: 65,
    autoGroupColumnDef: {
        cellRendererParams: {
            suppressCount: true,
        },
    },
    defaultColDef: {
        wrapHeaderText: true,
        autoHeaderHeight: true,
        suppressMovable: false,
        unSortIcon: false,
    },
    sideBar: {
        toolPanels: [
            {
                id: 'columns',
                labelDefault: 'Columns',
                labelKey: 'columns',
                iconKey: 'columns',
                toolPanel: 'agColumnsToolPanel',
                toolPanelParams: {
                    suppressPivotMode: true,
                    suppressValues: true,
                },
            },
        ],
        defaultToolPanel: 'columns',
    },
    getRowId: ({ data }: { data: ReportingRentRollRow }) => data.id?.toString(),
    suppressCellFocus: true,
    suppressFitToWidth: true,
    suppressAutoResize: true,
    suppressMovableColumns: false,
    maintainColumnOrder: true,
    groupDisplayType: 'multipleColumns',
    suppressAggFuncInHeader: true,
    aggFuncs: { sf_avg: getWeightedAvgAggregation('unit_square_feet') },
    onColumnVisible: refreshCells,
    onColumnRowGroupChanged: refreshCells,
    excelStyles,
}

export const updateVisibleColumns = (e: StateUpdatedEvent, visibleColumnsRef: MutableRefObject<string[] | undefined>) => {
    const { sources } = e
    if (sources.includes('columnVisibility')) {
        const visibleColumnsSet = new Set(visibleColumnsRef.current)
        const { columnVisibility, columnOrder } = e.api.getState();
        (columnOrder?.orderedColIds ?? []).forEach((colId) => visibleColumnsSet.add(colId));
        (columnVisibility?.hiddenColIds ?? []).forEach((colId) => visibleColumnsSet.delete(colId))
        visibleColumnsRef.current = Array.from(visibleColumnsSet)
    }
}
