import { forwardRef, RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { ChartRef, Column, ExcelExportParams, GetContextMenuItemsParams, GridState, MenuItemDef } from '@ag-grid-community/core'
import cn from 'classnames'
import isEqual from 'lodash/isEqual'
import { AND, EQ } from 'mobx-orm'
import { createPortal } from 'react-dom'

import { AgGridReact } from '@ag-grid-community/react'
import { useNavigate } from 'react-router'

import { useReportingPortfolioSelectedValueQuery } from '@/api/reportingData/reportingPortfolioSelectedValue'
import {
    useReportingPortfolioSelectedValueMetricsQuery,
} from '@/api/reportingData/reportingPortfolioSelectedValueMetrics'
import { ReportingTableFilters, useReportingTableByIdQuery } from '@/api/reportingTable/reportingTable'
import { Loader } from '@/components/base'
import {
    getScenarioOptions, MONTH_RANGE_END_QUERY_PARAM, MONTH_RANGE_START_QUERY_PARAM,
    SCENARIO_INPUT_QUERY_PARAM,
    TIME_INTERVAL_INPUT_QUERY_PARAM,
    YEAR_RANGE_END_QUERY_PARAM,
    YEAR_RANGE_START_QUERY_PARAM,
} from '@/components/baseInputs'
import { QUARTER_RANGE_END_QUERY_PARAM, QUARTER_RANGE_START_QUERY_PARAM } from '@/components/baseInputs/QuarterRangeInput'
import { Layout, useTableSettings } from '@/components/containers'
import { ASSET_DETAILS_QUERY_PARAM, ASSET_SIDE_MODAL_INPUT_QUERY_PARAM } from '@/components/models/asset'
import { AgGridTable } from '@/components/tables'
import { useTableChartModules } from '@/components/tables/utils/useTableChartModules'
import {
    GLOBAL_REPORT_MONTH_RANGE_END_QUERY_PARAM,
    GLOBAL_REPORT_MONTH_RANGE_START_QUERY_PARAM,
    GLOBAL_REPORT_MULTY_ASSET_QUERY_PARAM,
} from '@/components/widgets/reports/reports.constants'
import { AssetDetails } from '@/constants/assetDetails'
import { ReportingDataValueType } from '@/constants/reportingDataValueTypes'
import { TimeSettings } from '@/constants/timeSettings'
import { useInputState } from '@/hooks'
import { AssetType } from '@/models/core'
import {
    createLineChart,
    getChartThemeOverrides,
    normalizePercentageValues,
    normalizeAllValues,
    processMetricTSTotalCells,
} from '@/pages/TablesPage/TableBuilderPage/TableBuilderTables/MetricTimeSeriesTable/MetricTimeSeriesTable.utils'
import { TableMode, TableType } from '@/pages/TablesPage/tableType'
import { getRoute } from '@/utils/routing/getRoute'

import { CustomSettingsFilterModal } from '../../CustomSettingsFilterModal'
import { NoDataMessage } from '../../NoDataMessage'
import { CancelSaveButtons } from '../../TableBuilder/CancelSaveButtons'
import {
    GROUP_BY_ASSET_DETAIL_INPUT_QUERY_PARAM,
    METRIC_INPUT_QUERY_PARAM,
    PinTotalColumn,
    PIN_TOTAL_COL_INPUT_QUERY_PARAM,
    TOTAL_ROW_INPUT_QUERY_PARAM,
} from '../../TableBuilderFilters'
import {
    TABLE_ASSET_TYPE_PARAM,
    TABLE_RR_LEDGER_INPUT_QUERY_PARAM,
    TABLE_TB_LEDGER_INPUT_QUERY_PARAM,
    getReportTableTbLedgerInputQueryParam,
    getReportTableRrLedgerInputQueryParam,
    getReportTableAssetTypeParam,
    getReportQuarterRangeStartQueryParam,
    getQuarterRangeEndQueryParam,
    getReportYearRangeStartQueryParam,
    getReportYearRangeEndQueryParam,
    getReportAssetDetailsInputQueryParam,
    getReportGroupByAssetDetailInputQueryParam,
    getReportTimeIntervalInputQueryParam,
    getReportScenarioInputQueryParam,
    getReportPinTotalColInputQueryParam,
    getMetricInputQueryParam, getReportTotalRowInputQueryParam,
} from '../../TableBuilderPage.constants'
import { statusBar } from '../TableBuilderTables.constants'
import { TableBuilderTableProps } from '../TableBuilderTables.types'
import {
    getGridPropsToSave, getPeriodString, getTimePeriodAsString,
    getTimePeriodParam,
    saveGridStateToRef,
    saveReportingTable,
} from '../TableBuilderTables.utils'
import { useReportingTableSaveMutation } from '../useReportingTableSaveMutation'

import {
    defaultColSizingModelForAssetDetails,
    getMetricTimeSeriesColDefs,
    metricTimeSeriesTableGridOptions as gridOptions,
} from './MetricTimeSeriesTable.constants'

import chartStyles from '../TableChart/TableChart.module.scss'

/**
 * Metric - Time Series table
 */
export const MetricTimeSeriesTable = forwardRef((props: TableBuilderTableProps, ref: RefObject<AgGridReact>) => {
    const {
        tableMeta = {},
        buttonContainer,
        onCancel,
        onGridReady,
        insideReport = false,
        id,
        onFirstDataRendered,
        tablesListRouteConfigKey,
        builderRouteConfigKey,
    } = props

    const navigate = useNavigate()
    const isCreationMode = id === 'new'
    const fallbackTableRef = useRef<AgGridReact>(null)
    const tableRef = ref ?? fallbackTableRef
    const gridStateRef = useRef<GridState>() // Retain grid state between renders
    const chartRef = useRef<ChartRef>()
    const chartContainerRef = useRef<HTMLDivElement>(null)
    const chartModules = useTableChartModules()
    const areDefaultsSet = useRef(false)

    const { showTableSettingsModal, setShowTableSettingsModal, getTableSetting, setTableSetting } = useTableSettings()

    const [tableMode, setTableMode] = useState<TableMode | null>(null)
    // useTableChartToggle hook uses this grid context to toggle between table and chart modes
    const context = useRef<{ hasChart: boolean, tableMode: TableMode | null, toggleTableMode: () => void }>(
        {
            hasChart: true,
            tableMode,
            toggleTableMode: () => setTableMode(prev => prev === TableMode.Table ? TableMode.Chart : TableMode.Table),
        })

    useEffect(() => { context.current.tableMode = tableMode }, [tableMode])

    const [isTableDirty, setIsTableDirty] = useState(false)
    const saveMutation = useReportingTableSaveMutation()
    const { data: loadedTableData } = useReportingTableByIdQuery(id, { enabled: !isCreationMode })

    //  default column sizes on creation
    if (!gridStateRef.current && isCreationMode) { gridStateRef.current = { columnSizing: defaultColSizingModelForAssetDetails } }
    // load grid state once
    if (!gridStateRef.current && loadedTableData?.aggrid_state) { gridStateRef.current = loadedTableData.aggrid_state }

    const [tBLedgerId] = useInputState(
        insideReport ? getReportTableTbLedgerInputQueryParam(id) : TABLE_TB_LEDGER_INPUT_QUERY_PARAM,
    )
    const [rRLedgerId] = useInputState(
        insideReport ? getReportTableRrLedgerInputQueryParam(id) : TABLE_RR_LEDGER_INPUT_QUERY_PARAM,
    )
    const [assetType] = useInputState(
        insideReport ? getReportTableAssetTypeParam(id) : TABLE_ASSET_TYPE_PARAM,
    )

    const [assetIds, setAssetIds] = useInputState(
        insideReport ? GLOBAL_REPORT_MULTY_ASSET_QUERY_PARAM : ASSET_SIDE_MODAL_INPUT_QUERY_PARAM,
    )
    const [metricId, setMetricId] = useInputState(
        insideReport ? getMetricInputQueryParam(id) : METRIC_INPUT_QUERY_PARAM,
    )
    const [timeInterval, setTimeInterval] = useInputState(
        insideReport ? getReportTimeIntervalInputQueryParam(id) : TIME_INTERVAL_INPUT_QUERY_PARAM,
    )
    const [startMonth, setStartMonth] = useInputState(
        insideReport ? GLOBAL_REPORT_MONTH_RANGE_START_QUERY_PARAM : MONTH_RANGE_START_QUERY_PARAM,
    )
    const [endMonth, setEndMonth] = useInputState(
        insideReport ? GLOBAL_REPORT_MONTH_RANGE_END_QUERY_PARAM : MONTH_RANGE_END_QUERY_PARAM,
    )
    const [startQuarter, setStartQuarter] = useInputState(
        insideReport ? getReportQuarterRangeStartQueryParam(id) : QUARTER_RANGE_START_QUERY_PARAM,
    )
    const [endQuarter, setEndQuarter] = useInputState(
        insideReport ? getQuarterRangeEndQueryParam(id) : QUARTER_RANGE_END_QUERY_PARAM,
    )
    const [startYear, setStartYear] = useInputState(
        insideReport ? getReportYearRangeStartQueryParam(id) : YEAR_RANGE_START_QUERY_PARAM,
    )
    const [endYear, setEndYear] = useInputState(
        insideReport ? getReportYearRangeEndQueryParam(id) : YEAR_RANGE_END_QUERY_PARAM,
    )
    const [scenarioIds, setScenarioIds] = useInputState(
        insideReport ? getReportScenarioInputQueryParam(id) : SCENARIO_INPUT_QUERY_PARAM,
    )
    const [assetDetails, setAssetDetails] = useInputState(
        insideReport ? getReportAssetDetailsInputQueryParam(id) : ASSET_DETAILS_QUERY_PARAM,
    )
    const [totalCol, setTotalCol] = useInputState(
        insideReport ? getReportPinTotalColInputQueryParam(id) : PIN_TOTAL_COL_INPUT_QUERY_PARAM,
    )

    const [groupBy, setGroupBy] = useInputState(
        insideReport ? getReportGroupByAssetDetailInputQueryParam(id) : GROUP_BY_ASSET_DETAIL_INPUT_QUERY_PARAM,
    )
    const [showTotalRow, setShowTotalRow] = useInputState(insideReport ? getReportTotalRowInputQueryParam(id) : TOTAL_ROW_INPUT_QUERY_PARAM)
    const scenarioId = scenarioIds?.[0]

    const conditionalFormatRules = loadedTableData?.id ? (getTableSetting(loadedTableData.id)?.conditionalRules ?? undefined) : undefined

    const timePeriod = useMemo(
        () => getTimePeriodParam({
            startMonth,
            endMonth,
            startQuarter,
            endQuarter,
            startYear,
            endYear,
            timeInterval,
        }),
        [startMonth, endMonth, startQuarter, endQuarter, startYear, endYear, timeInterval],
    )

    const areFiltersSet = Boolean(assetIds && metricId && timePeriod)

    const { data: reportData, isLoading, isFetched, isSuccess } = useReportingPortfolioSelectedValueQuery(
        {
            filter: AND(
                EQ('asset_ids', assetIds),
                EQ('trial_balance_ledger_id', tBLedgerId),
                EQ('rent_roll_ledger_id', rRLedgerId),
                EQ('metric_id', metricId),
                EQ('time_settings', TimeSettings.SERIES),
                EQ('time_period', timePeriod),
                EQ('time_interval', timeInterval),
            ),
        },
        { enabled: areFiltersSet })

    const [rows, columns, valueType] = useMemo(() => [
        reportData?.[0]?.value_type === ReportingDataValueType.PERCENTAGE ? normalizeAllValues(reportData?.[0]?.rows ?? []) : normalizePercentageValues(reportData?.[0]?.rows ?? []),
        reportData?.[0]?.columns ?? [],
        reportData?.[0]?.value_type,
    ], [reportData])

    const { data: metrics } = useReportingPortfolioSelectedValueMetricsQuery(
        {
            filter: AND(
                EQ('asset_ids', assetIds),
                EQ('trial_balance_ledger_id', tBLedgerId),
                EQ('rent_roll_ledger_id', rRLedgerId),
            ),
        },
        { enabled: Boolean(assetIds) },
    )

    const metric = useMemo(() => metrics?.find(m => m.id === metricId), [metricId, metrics])
    const scenario = useMemo(() => getScenarioOptions(assetType).find(s => s.value === scenarioId), [assetType, scenarioId])

    const tableTitle = useMemo(() => {
        const periodString = getPeriodString(timeInterval, timePeriod)
        return `${metric?.name ?? '...'} - ${scenario?.label ?? '...'} - ${periodString}`
    }, [timePeriod, timeInterval, metric, scenario])

    const columnDefs = useMemo(
        () => getMetricTimeSeriesColDefs({
            conditionalFormatRules,
            columns,
            tableTitle,
            assetDetails,
            scenarioId,
            totalCol,
            groupBy,
            assetType,
            timeInterval,
            valueType,
        }),
        [conditionalFormatRules, columns, tableTitle, assetDetails, scenarioId, totalCol, groupBy, assetType, timeInterval, valueType],
    )

    // table config
    const config = useMemo(() => {
        const filters: ReportingTableFilters = { assetIds: assetIds as number[] }
        if (timeInterval) { filters.timeInterval = timeInterval }
        if (startMonth) { filters.startMonth = startMonth }
        if (endMonth) { filters.endMonth = endMonth }
        if (startQuarter) { filters.startQuarter = startQuarter }
        if (endQuarter) { filters.endQuarter = endQuarter }
        if (startYear) { filters.startYear = startYear }
        if (endYear) { filters.endYear = endYear }
        if (assetDetails) { filters.assetDetails = assetDetails }
        if (scenarioId) { filters.scenarioId = scenarioId }
        if (metricId) { filters.metricId = metricId }
        if (groupBy) { filters.groupBy = groupBy }
        if (showTotalRow) { filters.showTotalRow = showTotalRow }
        if (totalCol) { filters.pinTotalColumn = totalCol }
        return ({
            filters,
            conditional_format_rules: conditionalFormatRules && { rules: conditionalFormatRules },
            charts: { isChartMode: tableMode === TableMode.Chart },
        })
    }, [conditionalFormatRules, assetDetails, assetIds, endMonth, endQuarter, endYear, groupBy, metricId, scenarioId, showTotalRow, startMonth, startQuarter, startYear, timeInterval, totalCol, tableMode])

    // set defaults on creation
    useEffect(() => {
        if (!isCreationMode || areDefaultsSet.current) {
            return
        }

        setAssetDetails([
            AssetDetails.CITY,
            AssetDetails.STATE,
            AssetDetails.MANAGER_COMPANY,
            assetType === AssetType.MULTIFAMILY
                ? AssetDetails.UNITS
                : AssetDetails.SQUARE_FEET,
        ])
        setTotalCol(PinTotalColumn.Right)
        setTableMode(TableMode.Table)
        areDefaultsSet.current = true
    }, [assetType, isCreationMode, setAssetDetails, setTotalCol])

    // set default metric if no selection
    useEffect(() => {
        if (metrics?.length && !metricId) {
            setMetricId(metrics[0].id)
        }
    }, [metricId, metrics, setMetricId])

    // auto resize Asset details & Total columns
    useEffect(() => {
        const colsToResize = tableRef.current?.api?.getColumns()?.filter((c: Column) => c.isVisible)
            .filter(c => c.getColId().startsWith('values.total.') || !c.getColId().startsWith('values'))
            .map(c => c.getColId())
        tableRef.current?.api?.autoSizeColumns([...colsToResize ?? []])
    }, [assetDetails, groupBy, totalCol, tableRef])

    const checkIfTableIsDirty = useCallback(() => {
        const loadedState = loadedTableData?.aggrid_state ?? {}
        const currentState = getGridPropsToSave(tableRef.current?.api?.getState())
        const currentConfig = config
        const loadedConfig = loadedTableData?.config ?? {}
        const currentName = tableMeta.name
        const loadedName = loadedTableData?.name

        if (
            isEqual(currentConfig, loadedConfig) &&
            isEqual(currentState, loadedState) &&
            currentName === loadedName
        ) {
            setIsTableDirty(false)
        } else {
            setIsTableDirty(true)
        }
    }, [config, loadedTableData?.aggrid_state, loadedTableData?.config, loadedTableData?.name, tableMeta.name, tableRef])

    useEffect(() => checkIfTableIsDirty(), [checkIfTableIsDirty])

    // set filters from loaded table data
    useEffect(() => {
        if (!loadedTableData) {
            return
        }

        const { filters, conditional_format_rules, charts } = loadedTableData.config
        if (filters) {
            if ((insideReport && !assetIds) || !insideReport) {
                filters.assetIds && setAssetIds(filters.assetIds)
            }
            if ((insideReport && !startMonth) || !insideReport) {
                filters.startMonth && setStartMonth(filters.startMonth)
                filters.endMonth && setEndMonth(filters.endMonth)
            }
            filters.timeInterval && setTimeInterval(filters.timeInterval)
            filters.startQuarter && setStartQuarter(filters.startQuarter)
            filters.endQuarter && setEndQuarter(filters.endQuarter)
            filters.startYear && setStartYear(filters.startYear)
            filters.endYear && setEndYear(filters.endYear)
            filters.assetDetails && setAssetDetails(filters.assetDetails)
            filters.scenarioId && setScenarioIds([filters.scenarioId])
            filters.metricId && setMetricId(filters.metricId)
            filters.groupBy && setGroupBy(filters.groupBy)
            filters.showTotalRow && setShowTotalRow(filters.showTotalRow)
            filters.pinTotalColumn && setTotalCol(filters.pinTotalColumn)
        }
        setTableSetting(loadedTableData.id, conditional_format_rules ? conditional_format_rules.rules : [])

        if (!tableMode) {
            setTableMode(charts?.isChartMode ? TableMode.Chart : TableMode.Table)
        }
    }, [assetIds, insideReport, loadedTableData, setAssetDetails, setAssetIds, setEndMonth, setEndQuarter, setEndYear, setGroupBy, setMetricId, setScenarioIds, setShowTotalRow, setStartMonth, setStartQuarter, setStartYear, setTimeInterval, setTotalCol])

    const onTableSave = useCallback(async () => {
        const currentState = tableRef.current?.api?.getState() ?? {}
        const response = await saveReportingTable({
            tableMeta,
            currentState,
            isCreationMode,
            saveMutation,
            id,
            tableType: TableType.MetricTimeSeries,
            config,
            assetType,
        })

        if (!response) {
            return
        }

        // redirect after saving
        if (isCreationMode) {
            navigate(getRoute(builderRouteConfigKey, { id: response.id }))
        } else {
            navigate(getRoute(tablesListRouteConfigKey))
        }
    }, [assetType, config, id, isCreationMode, navigate, saveMutation, tableMeta, tableRef])

    const excelExportParams = useMemo((): ExcelExportParams => {
        const periodAsString = getTimePeriodAsString(TimeSettings.SERIES, timeInterval, timePeriod)
        return ({
            fileName: `${tableTitle}.xlsx`,
            sheetName: periodAsString,
            processCellCallback: processMetricTSTotalCells,
            freezeColumns: 'pinned',
            suppressRowOutline: true,
        })
    }, [timeInterval, timePeriod, tableTitle])

    const getContextMenuItems = useCallback(
        (params: GetContextMenuItemsParams): Array<string | MenuItemDef> => {
            const result: Array<string | MenuItemDef> = [
                'copy',
                'copyWithHeaders',
                'copyWithGroupHeaders',
                'separator',
                'export',
                'separator',
                {
                    name: 'Expand All Row Groups',
                    disabled: !groupBy,
                    action: () => params.api.expandAll(),
                },
                {
                    name: 'Collapse All Row Groups',
                    disabled: !groupBy,
                    action: () => params.api.collapseAll(),
                },
                {
                    name: 'Table Settings',
                    action: () => setShowTableSettingsModal(true),
                },
            ]
            return result
        }, [groupBy, showTotalRow])

    const chartThemeOverrides = useMemo(() => getChartThemeOverrides(metric, scenario), [metric, scenario])

    // destroy chart on unmount
    useEffect(() => () => chartRef.current?.destroyChart(), [])

    const onModelUpdated = useCallback(() => {
        if (!isLoading && isFetched && isSuccess) {
            onFirstDataRendered()

            // recreate chart because filters may have changed
            chartRef.current?.destroyChart()
            if (tableMode === TableMode.Chart) {
                chartRef.current = createLineChart(tableRef.current?.api, chartContainerRef.current, (showTotalRow === '1' ? 1 : 0))
            }
        }
    }, [isLoading, isFetched, isSuccess, onFirstDataRendered, tableMode, showTotalRow])

    return (
        <>
            <Layout gap={16} flexGrow={1} direction='column'>
                {areFiltersSet
                    ? (
                        <>
                            {/* @ts-expect-error TODO: make AgGridTable generic to be able to pass theme */}
                            <AgGridTable
                                className={cn({ [chartStyles.hidden]: tableMode === TableMode.Chart })}
                                ref={tableRef}
                                items={rows}
                                {...gridOptions}
                                grandTotalRow={showTotalRow === '1' ? 'bottom' : undefined}
                                columnDefs={columnDefs}
                                loading={isLoading}
                                initialState={gridStateRef.current}
                                onStateUpdated={(e) => {
                                    saveGridStateToRef(e, gridStateRef)
                                    checkIfTableIsDirty()
                                }}
                                lazyModules={chartModules}
                                statusBar={statusBar}
                                defaultExcelExportParams={excelExportParams}
                                enableCharts={!groupBy}
                                getContextMenuItems={getContextMenuItems}
                                chartThemeOverrides={chartThemeOverrides}
                                onGridReady={onGridReady}
                                onModelUpdated={onModelUpdated}
                                context={context.current}
                            />

                            {tableMode === TableMode.Chart && isLoading ? (
                                <Loader/>
                            ) : (
                                <div
                                    ref={chartContainerRef}
                                    className={cn(chartStyles.chartContainer, { [chartStyles.hidden]: tableMode === TableMode.Table })}
                                />
                            )}
                        </>
                    )
                    : (<NoDataMessage text='Choose required fields'/>)}
            </Layout>

            {showTableSettingsModal && (
                <CustomSettingsFilterModal
                    tableId={id}
                    tableRef={tableRef}
                    tableType={TableType.MetricTimeSeries}
                    onClose={() => setShowTableSettingsModal(false)}
                    onSave={() => setIsTableDirty(true)}
                />
            )}

            {buttonContainer && createPortal(
                <CancelSaveButtons
                    onCancel={onCancel}
                    onSave={onTableSave}
                    isLoading={saveMutation.isPending}
                    isDisabled={!isTableDirty || !tableMeta.name}
                />,
                buttonContainer,
            )}
        </>
    )
})
