import { getRequestQueries } from '@/api/utils/getRequestQueries'
import { FilterTreeItem } from '@/components/base'
import { TableDataType } from '@/pages/TablesPage/tableType'

export const ReportingPortfolioOverviewValueKeys = [
    'mtd_actual', 'mtd_budget', 'mtd_variance', 'mtd_variance_percent',
    'pmmtd_actual', 'pmmtd_budget', 'pmmtd_variance', 'pmmtd_variance_percent',
    'pymtd_actual', 'pymtd_budget', 'pymtd_variance', 'pymtd_variance_percent',
    'ytd_actual', 'ytd_budget', 'ytd_variance', 'ytd_variance_percent',
    'pyytd_actual', 'pyytd_budget', 'pyytd_variance', 'pyytd_variance_percent',
] as const

export interface ReportingPortfolioOverviewSubheader {
    key: typeof ReportingPortfolioOverviewValueKeys[number]
    name: string
}

export interface ReportingPortfolioOverviewRow {
    id: string // metricId
    name: string // metricName
    value_type: string // string, data, number (float), money, percentage (float)
    data_type: `${TableDataType}`
    values: Record<string, {
        [key in typeof ReportingPortfolioOverviewValueKeys[number]]?: string | number | null
    }>
}
export interface ReportingPortfolioOverviewItem {
    header: Array<{ id: string | number, name: string }> // { id: assetId, name: assetName }
    subheader: ReportingPortfolioOverviewSubheader[] // { key: subcolumn id & key in values obj, name: subcolumn name }
    rows: ReportingPortfolioOverviewRow[]
    tree_data: FilterTreeItem[]
}

export const {
    useItemsQuery: useReportingPortfolioOverviewQuery,
} = getRequestQueries<ReportingPortfolioOverviewItem, Record<string, unknown>>({
    url: 'reporting/portfolio-overview',
    extractItemsFn: (data: ReportingPortfolioOverviewItem) => [data],
})
