import { CSSProperties } from 'react'

import { GridApi, ICellRendererParams, ProcessCellForExportParams } from '@ag-grid-community/core'
import { AgChartThemeOverrides } from '@ag-grid-enterprise/charts-enterprise'

import { ReportingPortfolioSelectedValueRow } from '@/api/reportingData/reportingPortfolioSelectedValue'
import { ReportingPortfolioSelectedValueMetricsItem } from '@/api/reportingData/reportingPortfolioSelectedValueMetrics'

import { ReportScenario } from '../../../../../constants/reportScenario'
import { applyFunction, ConditionalFormatRule, ConditionalFormatStyle } from '../../ConditionalRuleEditor/ConditionalRuleEditor.types'
import { applyConditionalFormattingRuleToStyle } from '../../ConditionalRuleEditor/ConditionalRuleEditor.utils'

/**
 * Transforms the variance_percent values from decimal to percentage (0.1234 => 12.34)
 */
export const normalizePercentageValues = (rows: ReportingPortfolioSelectedValueRow[]): ReportingPortfolioSelectedValueRow[] => {
    return rows.map(row => {
        const values = row.values
        return {
            ...row,
            values: Object.entries(values).reduce((acc, [key, data]) => {
                return {
                    ...acc,
                    [key]: {
                        ...data,
                        variance_percent: (typeof data.variance_percent === 'number') ? data.variance_percent * 100 : data.variance_percent,
                    },
                }
            }, {}),
        }
    })
}

/**
 *
 * Transform all values for percentage metric (0.1234 => 12.34)
 */
export const normalizeAllValues = (rows: ReportingPortfolioSelectedValueRow[]): ReportingPortfolioSelectedValueRow[] => {
    return rows.map(row => {
        const values = row.values
        return {
            ...row,
            values: Object.entries(values).reduce((acc, [period, data]) => {
                return {
                    ...acc,
                    [period]: Object.entries(data).reduce((acc, [key, value]) => {
                        return {
                            ...acc,
                            [key]: (typeof value === 'number') ? value * 100 : value,
                        }
                    }, {}),
                }
            }, {}),
        }
    })
}

export const processMetricTSTotalCells = (params: ProcessCellForExportParams): string => {
    const { node, column } = params
    const colId = column.getColId()

    const isGroupColumn = params.value?.toString().includes('->')
    if (isGroupColumn) {
        const cleanedValue = params.value?.toString().split(' ').filter(value => !value.includes('->')).join(' ')
        return cleanedValue
    }

    const isValueColumn = colId.startsWith('values')
    const isNumericAssetDetailColumn = ['sqft', 'units', 'square_feet', 'units_count'].includes(colId)
    const hasAggValue = isValueColumn || isNumericAssetDetailColumn

    // remove values from group rows in Excel export
    if (node?.group && !node?.footer && hasAggValue) {
        return ''
    }

    return params.value
}

export const getChartThemeOverrides = (metric?: ReportingPortfolioSelectedValueMetricsItem, scenario?: { label: string, value: string }): AgChartThemeOverrides =>
    ({
        common: {
            axes: {
                number: {
                    title: {
                        enabled: true,
                        text: metric?.name ?? '',
                    },
                },
            },
            title: {
                enabled: true,
                text: `${metric?.name ?? ''} - ${scenario?.label ?? ''}`,
            },
        },
        line: {
            axes: {
                number: {
                    label: {
                        formatter: ({ value }) => {
                            if (typeof value !== 'number') {
                                return value
                            }

                            if (scenario?.value === 'variance_percent') {
                                return value.toLocaleString('en-US', {
                                    minimumFractionDigits: 1,
                                    maximumFractionDigits: 1,
                                })
                            }

                            return value.toLocaleString('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                            })
                        },
                    },
                },
            },
        },
    })

export const createLineChart = (api: GridApi | undefined, container: HTMLDivElement | null, totalRowCount: number) => {
    if (!api || !container) {
        return
    }

    const rowCount = api.getDisplayedRowCount() - totalRowCount
    const ranges = api.getCellRanges() || []
    const selectedColumns = ranges.flatMap(range => range.columns.map(col => col.getColId()))
    let selectedSeriesColumns = selectedColumns.filter(colId => colId.startsWith('values'))

    const { selectedStartIndex, selectedEndIndex } = ranges.reduce((acc, range) => {
        const startRowIndex = range.startRow?.rowIndex ?? 0
        const endRowIndex = range.endRow?.rowIndex ?? 0
        return ({
            selectedStartIndex: startRowIndex < acc.selectedStartIndex ? startRowIndex : acc.selectedStartIndex,
            selectedEndIndex: endRowIndex > acc.selectedEndIndex ? endRowIndex : acc.selectedEndIndex,
        })
    }, {
        selectedStartIndex: Infinity,
        selectedEndIndex: -Infinity,
    })

    // if no range selected and only one column selected, use all rows and columns for chart
    const isOneCellSelected = (selectedStartIndex === Infinity || selectedEndIndex === -Infinity || selectedStartIndex === selectedEndIndex) && (selectedSeriesColumns.length < 2)
    const [rowStartIndex, rowEndIndex] = isOneCellSelected ? [0, rowCount - 1] : [selectedStartIndex, selectedEndIndex]
    if (isOneCellSelected) {
        // if total column is selected include it, otherwise use only time series columns
        const isTotalColumnSelected = selectedSeriesColumns[0]?.includes('total')
        const allSeriesColumns = api.getAllDisplayedColumns()
            .map(col => col.getColId())
            .filter(colId => isTotalColumnSelected ? colId.startsWith('values') : colId.startsWith('values') && !colId.includes('total'))
        selectedSeriesColumns = allSeriesColumns
    }

    selectedSeriesColumns.unshift('name')

    return api.createRangeChart({
        chartContainer: container,
        cellRange: {
            columns: selectedSeriesColumns,
            rowStartIndex,
            rowEndIndex,
        },
        chartType: 'line',
        switchCategorySeries: true,
    })
}

export const cellRenderStyledFromConditionalFormatRules = (conditionalFormatRules: ConditionalFormatRule[], params: ICellRendererParams<ReportingPortfolioSelectedValueRow>, cellValue: number, id: string, scenarioId: ReportScenario, colScenario: 'all-data' | 'total') => {
    if ((params.node.group && params.node.expanded) || params.value === undefined || !params.data || !params.data.values || params.value === '') {
        return params.valueFormatted
    }

    let newStyle: ConditionalFormatStyle = {}

    // Apply conditional formatting rules. Reverse the array to apply the rules in priority order
    // First rule gets applied last and therefore has the highest priority since styles can overwrite each other
    for (const rule of conditionalFormatRules.reverse()) {
        // Determine if condition(s) are met to apply a rule's styling to the cell
        let conditionMet = true

        // Determine reference value for primary column and check then primary condition
        let primaryConditionColumnValue = cellValue
        if (colScenario === 'all-data' && rule.primaryConditionColumn === 'total') {
            primaryConditionColumnValue = params.data.values.total[scenarioId] as number
        }
        if (colScenario === 'total' && rule.primaryConditionColumn === 'all-data') {
            // when subject col is total and condition col is data column, use any col value that matches the rule to test the condition for total column
            for (const [key, value] of Object.entries(params.data.values)) {
                const val = value[scenarioId] as number
                const valueFormatted = val && parseFloat(val?.toFixed(0))
                if (key !== 'total' && applyFunction(rule.primaryConditionFunction, valueFormatted, rule.primaryConditionValue)) {
                    primaryConditionColumnValue = val
                    break
                }
            }
        }
        if (!primaryConditionColumnValue) continue
        const primaryConditionColumnValueFormatted = parseFloat(primaryConditionColumnValue.toFixed(0))

        const firstConditionResult = applyFunction(rule.primaryConditionFunction, primaryConditionColumnValueFormatted, rule.primaryConditionValue)

        // Check Secondary Condition (may not be present)
        let secondaryConditionColumnValue = cellValue
        if (colScenario === 'all-data' && rule.secondaryConditionColumn === 'total') {
            secondaryConditionColumnValue = params.data.values.total[scenarioId] as number
        }
        if (colScenario === 'total' && rule.secondaryConditionColumn === 'all-data') {
            // when subject col is total and condition col is data column, use any col value that matches the rule to test the condition for total column
            for (const [key, value] of Object.entries(params.data.values)) {
                const val = value[scenarioId] as number
                const valueFormatted = val && parseFloat(val?.toFixed(0))
                if (key !== 'total' && rule.secondaryConditionFunction && applyFunction(rule.secondaryConditionFunction, valueFormatted, rule.secondaryConditionValue)) {
                    secondaryConditionColumnValue = val
                    break
                }
            }
        }

        const secondaryConditionColumnValueFormatted = secondaryConditionColumnValue && parseFloat(secondaryConditionColumnValue.toFixed(0))
        const secondaryConditionResult = (rule.secondaryConditionFunction && applyFunction(rule.secondaryConditionFunction, secondaryConditionColumnValueFormatted, rule.secondaryConditionValue)) ?? false

        const applySecondaryCondition = secondaryConditionColumnValue && rule.secondaryConditionFunction && rule.secondaryConditionValue && rule.secondaryConditionColumn
        if (applySecondaryCondition) {
            if (rule.conditionCombinationOperator === 'AND') {
                conditionMet = firstConditionResult && secondaryConditionResult
            } else {
                conditionMet = firstConditionResult || secondaryConditionResult
            }
        } else {
            conditionMet = firstConditionResult
        }

        // Apply the rule's styling to the cell if the condition(s) are met
        if (conditionMet && rule.columnsToBeFormatted.includes(colScenario)) {
            newStyle = applyConditionalFormattingRuleToStyle(rule, newStyle)
        }
    }

    const styledIcon = newStyle.cellIconStyle ? <span className={newStyle.cellIconStyle?.iconClass} style={newStyle?.cellIconStyle ? newStyle.cellIconStyle as CSSProperties : {}}/> : <span/>

    return (
        <div style={newStyle as CSSProperties}>{styledIcon}<span>{params.valueFormatted}</span></div>
    )
}
