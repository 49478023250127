import { forwardRef, RefObject, useEffect, useMemo, useRef, useState } from 'react'

import { GridReadyEvent, Module } from '@ag-grid-community/core'
import cn from 'classnames'
import { QueryPage } from 'mobx-orm'

import { AgGridReact } from '@ag-grid-community/react'
import { observer } from 'mobx-react-lite'

import { modifyColDefItem } from '@/components/legacy/tables/AgGridTableLegacy/AgGridTableLegacy.utils'
import { AgGridPaginationLeftPanel } from '@/components/legacy/tables/AgGridTableLegacy/pagination/AgGridPaginationLeftPanel'
import { AgGridTablePaginationRightPanel } from '@/components/legacy/tables/AgGridTableLegacy/pagination/AgGridTableLegacyPaginationRightPanel'
import { AgGridTableLoading } from '@/components/tables/AgGridTable/components/AgGridTableLoading'
import { AgGridTableNoRows } from '@/components/tables/AgGridTable/components/AgGridTableNoRows'
import { useTableModules } from '@/components/tables/utils/useTableModules'
import { lazyWithCatch } from '@/utils/lazyCatch'

import { DEFAULT_COL_TYPES, DEFAULT_COL_DEF } from './AgGridTableLegacy.constants'
import { AgGridTableProps, AgGridTableTheme } from './AgGridTableLegacy.types'
import { AgGridUpdater } from './AgGridUpdater'

import standard from './themes/standard.module.scss'
import '@ag-grid-community/styles/ag-grid.css'
import '@ag-grid-community/styles/ag-theme-alpine.css'

const AgGridReactLazy = lazyWithCatch(() => import('@/components/legacy/tables/AgGridTableLegacy/exports/agGridReact'))

const addHeaderTooltip = (items) => {
    items.forEach((col) => {
        if (col.headerName?.length) {
            col.headerTooltip = col.headerName
        }

        if (col.children?.length) {
            addHeaderTooltip(col.children)
        }
    })
}

const tableThemeMap: Record<AgGridTableTheme, string> = {
    standard: cn('ag-theme-alpine', standard.table),
    // Hack for type compatibility. Theme will be same here.
    variance: cn('ag-theme-alpine', standard.table),
    neo: cn('ag-theme-alpine', standard.table),
}

// FIXME: Tooltip for header text cells
// TODO: 'Type' for each column must be required
export const AgGridTableLegacy = observer(forwardRef((props: AgGridTableProps, ref: RefObject<AgGridReact | undefined>) => {
    const {
        query,
        theme = 'standard',
        noBorders = false,
        noRowsText = 'No Rows To Show',
        columnTypes,
        defaultColDef,
        suppressFitToWidth,
        suppressAutoResize,
        fitToWidthConfig,
        columnDefs,
        ...agGridOptions
    } = props
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const _ref = useRef<AgGridReact>(null)

    const [isTableReady, setIsTableReady] = useState(false)

    const defaultModules = useTableModules()
    const [tableModules, setTableModules] = useState<Module[]>([])

    useEffect(() => {
        (async () => {
            setTableModules([...(await defaultModules)])
        })()
    }, [defaultModules])

    ref ||= _ref

    const resizeColumns = () => {
        setTimeout(() => {
            ref.current?.api?.sizeColumnsToFit(fitToWidthConfig)
        })
    }

    const [updater, setUpdater] = useState<AgGridUpdater>()
    const handleGridReady = (params: GridReadyEvent) => {
        const { api } = params
        props.onGridReady?.(params)

        if (!suppressFitToWidth) resizeColumns()

        addHeaderTooltip(columnDefs)
        setUpdater(new AgGridUpdater(api, api, query))

        setIsTableReady(true)
    }

    useEffect(() => {
        return () => { updater?.destroy() }
    }, [updater])

    useEffect(() => {
        if (!suppressAutoResize) {
            window.addEventListener('resize', resizeColumns)
            return () => { window.removeEventListener('resize', resizeColumns) }
        }
    }, [])

    const hasRowData = Boolean(query.items.length)
    const hasError = Boolean(query.error)

    useEffect(() => {
        if (!isTableReady) {
            return
        }

        if (!suppressAutoResize) {
            resizeColumns()
        }
    }, [query.is_loading, isTableReady, hasRowData, hasError])

    const classNames = cn(tableThemeMap[theme], { [standard.noBorders]: noBorders })

    const statusBar = useMemo(() => ({
        statusPanels: [
            {
                statusPanel: () => <AgGridPaginationLeftPanel query={query as QueryPage<any>}/>,
                align: 'left',
            },
            {
                statusPanel: () => <AgGridTablePaginationRightPanel query={query as QueryPage<any>}/>,
                align: 'right',
            },
        ],
    }), [query])

    const agGridProps: Omit<AgGridTableProps, 'query' | 'items'> & { ref: RefObject<AgGridReact | undefined> } = {
        ...agGridOptions,
        columnDefs: columnDefs.map(modifyColDefItem),
        ref,
        tooltipShowDelay: 0,
        className: classNames,
        rowData: query.error || !query.is_ready ? [] : query.items, // Rows hidded if error layout shown or query is not ready
        onGridReady: handleGridReady,
        columnTypes: {
            ...DEFAULT_COL_TYPES,
            ...columnTypes,
        },
        defaultColDef: {
            ...DEFAULT_COL_DEF,
            ...defaultColDef,
        },
        loadingOverlayComponent: AgGridTableLoading,
        noRowsOverlayComponent: AgGridTableNoRows,
        noRowsOverlayComponentParams: {
            noRowsMessageFunc: () => query.is_ready ? noRowsText : 'Waiting for inputs...',
        },
        modules: tableModules,
    }

    if (query.limit) {
        agGridProps.context = {
            ...agGridOptions?.context,
            query,
        }
        agGridProps.statusBar = statusBar
    }

    if (!suppressAutoResize) {
        agGridProps.onRowGroupOpened = resizeColumns
    }

    if (!tableModules.length) {
        return null
    }

    return (
        <AgGridReactLazy {...agGridProps}>
            {props.children}
        </AgGridReactLazy>
    )
}))
