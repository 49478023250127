import { getRequestQueries } from '@/api/utils/getRequestQueries'

export interface TrialBalanceMapItem {
    id?: number

    code?: string
    name?: string
    asset_name?: string

    custom_account_map_id?: number
    custom_account_id?: number
    custom_account_code?: string
    custom_account_name?: string
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FetchTrialBalanceMapQueryParams {}

export const TRIAL_BALANCE_MAP_URL = 'trial-balance-map'

export const {
    useItemsQuery: useTrialBalanceMapQuery,
    useItemUpdateMutation: useTrialBalanceMapUpdateMutation,
    useItemsCountQuery: useTrialBalanceMapCountQuery,
    useDownloadCSV: useTrialBalanceMapDownloadCSV,
} = getRequestQueries<TrialBalanceMapItem, FetchTrialBalanceMapQueryParams>({
    url: TRIAL_BALANCE_MAP_URL,
})
