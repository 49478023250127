import { getRequestQueries } from '@/api/utils/getRequestQueries'
import { ReportColumnAggregation } from '@/constants/reportColumnAggregation'
import { ReportingDataValueType } from '@/constants/reportingDataValueTypes'
import { ReportScenario } from '@/constants/reportScenario'
import { AssetType } from '@/models/core'

const REPORTING_PORTFOLIO_SELECTED_VALUE_URL = 'reporting/portfolio-selected-value'

export type ReportingPortfolioSelectedValueKey = `${ReportScenario}`
export interface ReportingPortfolioSelectedValueColumn {
    id: string
    name: string
    subheaders: ReportingPortfolioSelectedValueKey[]
}

export interface ReportingPortfolioSelectedValueSubheader {
    key: ReportingPortfolioSelectedValueKey
    name: string
}

export interface ReportingPortfolioSelectedValueRow {
    id: string | number // asset id
    name: string // asset name
    city?: string
    state?: string
    asset_class?: `${AssetType}`
    asset_manager_name?: string
    manager?: string
    fund?: string
    units: number
    sqft: number
    custom_id1?: string
    custom_id2?: string
    software?: string
    values: Record<string, {
        [key in ReportingPortfolioSelectedValueKey]?: number | null
    }>
}

export interface ReportingPortfolioSelectedValueItem {
    columns: ReportingPortfolioSelectedValueColumn[]
    rows: ReportingPortfolioSelectedValueRow[]
    subheader: ReportingPortfolioSelectedValueSubheader[]
    value_type: `${ReportingDataValueType}`
    aggregation?: `${ReportColumnAggregation}`
}

export const {
    useItemsQuery: useReportingPortfolioSelectedValueQuery,
} = getRequestQueries<ReportingPortfolioSelectedValueItem, Record<string, unknown>>({
    url: REPORTING_PORTFOLIO_SELECTED_VALUE_URL,
    extractItemsFn: (data: ReportingPortfolioSelectedValueItem) => [data],
})
