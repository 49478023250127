import { useState } from 'react'

import { Button, Text, Checkbox, Select, TextInput, ButtonGroup } from '@/components/base'
import { ColorSelectInput, getScenarioOptions } from '@/components/baseInputs'
import { useTableSettings } from '@/components/containers'
import { ActionsCell, ActionsCellItem } from '@/components/legacy/tables/cells'

import { ReportScenario } from '../../../../constants/reportScenario'
import { TableType } from '../../tableType'
import { GroupByRRField, GROUP_BY_RR_FIELD_LABLES } from '../TableBuilderFilters'
import { BASE_COLS, NEW_LEASE_COLS } from '../TableBuilderTables/RRTradeOutBasicTable/RRTradeOutBasicTable.constants'

import { ConditionalFormatRule, ConditionalFormatRuleFunction, ConditionalFormatRuleFunctionOptions, ConditionalRuleEditorProps } from './ConditionalRuleEditor.types'

import styles from './ConditionalRuleEditor.module.scss'
import '@rc-component/color-picker/assets/index.css'

const DEFAULT_BACKGROUND_COLOR = '#FDE7E8'
const DEFAULT_FOREGROUND_COLOR = '#EB0A15'

export const ConditionalRuleEditor = (props: ConditionalRuleEditorProps) => {
    const { tableId, tableRef, tableType, assetType, metricSideBySideColumns, portfolioRentRollColumns, onClose, onSave } = props
    const {
        getTableSetting,
        setTableSetting,
    } = useTableSettings()
    const tableNum = parseInt(`${tableId}`) ?? -999
    const tableSettings = getTableSetting(tableNum)
    const [conditionalRules, setConditionalRules] = useState<ConditionalFormatRule[]>(tableSettings.conditionalRules)
    const [selectedRuleId, setSelectedRuleId] = useState<string | undefined>(undefined)
    const [editRuleNameForRuleId, setEditRuleNameForRuleId] = useState<string | undefined>(undefined)

    const selectedRule = conditionalRules.find((rule) => rule.id === selectedRuleId)
    const updateSelectedRule = (rule: ConditionalFormatRule) => {
        setConditionalRules(conditionalRules.map((r) => (r.id === rule.id ? {
            ...rule,
            dirty: true,
        } : r)))
    }

    const allColumnKeys: string[] = ((tableType) => {
        switch (tableType) {
            case TableType.PnLVarianceAnalysis:
                return Object.values(ReportScenario)
            case TableType.MetricTimeSeries:
            case TableType.PnLTimeSeries:
                return ['all-data', 'total']
            case TableType.MetricSideBySide:
                return metricSideBySideColumns?.map((col) => col.id) ?? []
            case TableType.PortfolioRentRoll:
                return portfolioRentRollColumns?.map((col) => col.key) ?? []
            case TableType.RRTradeOutBasic:
                return [
                    'asset_name',
                    GroupByRRField.Floorplan,
                    GroupByRRField.Bedrooms,
                    ...BASE_COLS.map(col => col.field),
                    ...NEW_LEASE_COLS.map(col => col.field),
                ]
            default:
                return []
        }
    })(tableType)

    const getColumnLabelForTableType = (tableType: TableType, colKey: string): string => {
        switch (tableType) {
            case TableType.PnLVarianceAnalysis:
                return getScenarioOptions(assetType).filter(option => option.value === colKey)[0]?.label ?? colKey
            case TableType.MetricTimeSeries:
            case TableType.PnLTimeSeries:
                return (colKey === 'all-data') ? 'Data Columns' : 'Total'
            case TableType.MetricSideBySide:
                return metricSideBySideColumns?.filter((col) => col.id === colKey)[0]?.name ?? colKey
            case TableType.PortfolioRentRoll:
                return portfolioRentRollColumns?.filter((col) => col.key === colKey)[0]?.name ?? colKey
            case TableType.RRTradeOutBasic:
                return [
                    {
                        field: 'asset_name',
                        title: 'Asset Name',
                    },
                    ...BASE_COLS,
                    ...NEW_LEASE_COLS,
                    ...Object.values(GroupByRRField).map(key => ({
                        field: key,
                        title: GROUP_BY_RR_FIELD_LABLES[key],
                    })),
                ].filter((col) => col.field === colKey)[0]?.title?.replace('~', ' ') ?? colKey
            default:
                return '[]'
        }
    }

    const columnKeyToLabel = (key: string) => getColumnLabelForTableType(tableType, key)

    const generateBlankRule = (): ConditionalFormatRule => {
        let newRuleIndex = conditionalRules.length + 1
        let newName = `Rule #${newRuleIndex}`

        while (conditionalRules.find((rule) => rule.ruleName === newName)) {
            newRuleIndex += 1
            newName = `Rule #${newRuleIndex}`
        }

        return ({
            id: crypto.randomUUID(),
            ruleName: newName,
            columnsToBeFormatted: [],
            primaryConditionColumn: allColumnKeys[0],
            primaryConditionFunction: ConditionalFormatRuleFunction.EQUAL,
            primaryConditionValue: '',
            conditionCombinationOperator: 'AND',
            secondaryConditionColumn: undefined,
            secondaryConditionFunction: undefined,
            secondaryConditionValue: undefined,
            styleBackColor: DEFAULT_BACKGROUND_COLOR,
            styleForeColor: DEFAULT_FOREGROUND_COLOR,
            styleFont: [],
            styleFontSize: 0,
            newRule: true,
            dirty: true,
        })
    }

    const addNewRule = () => {
        const newRule = generateBlankRule()
        setConditionalRules([...conditionalRules, newRule])
        setSelectedRuleId(newRule.id)
    }

    const handleSave = () => {
        setTableSetting(tableNum, conditionalRules.map(rule => ({
            ...rule,
            dirty: false,
        })))
        onSave?.()
        onClose()
        tableRef?.current?.api?.redrawRows()
    }

    return (
        <div className={styles.conditionalRuleBuilder}>
            <div className={styles.mainBuilderSection}>
                <div className={styles.builderColumn}>
                    <div className={styles.title}>
                        <Text text='Rules' variant='smallTextSemiboldDefault' color='colorsSecondaryGrey400'/>
                    </div>
                    {conditionalRules.map((rule, index) => {
                        const actionsCellItems: ActionsCellItem[] = [
                            {
                                icon: 'treeRename',
                                text: 'Rename',
                                onClick: () => setEditRuleNameForRuleId(rule.id),
                            },
                            {
                                icon: 'copy',
                                text: 'Duplicate',
                                onClick: () => {
                                    const newConditionalRules = [...conditionalRules]
                                    const newBlankRule = generateBlankRule()
                                    const deepCopy = JSON.parse(JSON.stringify(rule)) as ConditionalFormatRule
                                    newConditionalRules.push({
                                        ...deepCopy,
                                        id: newBlankRule.id,
                                        ruleName: `${deepCopy.ruleName} copy`,
                                    })
                                    setConditionalRules(newConditionalRules)
                                },
                                dividerAfter: true,
                            },
                            ...(index > 0 ? [{
                                icon: 'arrowUp',
                                text: 'Move Up',
                                onClick: () => {
                                    const newConditionalRules = [...conditionalRules]
                                    newConditionalRules.splice(index, 1)
                                    newConditionalRules.splice(index - 1, 0, rule)
                                    setConditionalRules(newConditionalRules)
                                },
                                dividerAfter: index === conditionalRules.length - 1,
                            } as ActionsCellItem] : []),
                            ...(index < conditionalRules.length - 1 ? [{
                                icon: 'arrowDown',
                                text: 'Move Down',
                                onClick: () => {
                                    const newConditionalRules = [...conditionalRules]
                                    newConditionalRules.splice(index, 1)
                                    newConditionalRules.splice(index + 1, 0, rule)
                                    setConditionalRules(newConditionalRules)
                                },
                                dividerAfter: true,
                            } as ActionsCellItem] : []),
                            {
                                icon: 'delete',
                                text: 'Delete',
                                onClick: () => {
                                    setConditionalRules(conditionalRules.filter((r) => r.id !== rule.id))
                                },
                            },
                        ]

                        return (
                            <div
                                className={selectedRuleId === rule.id ? `${styles.ruleItem} ${styles.ruleItemSelected}` : styles.ruleItem}
                                key={rule.id}
                                onClick={() => {
                                    const alreadySelected = selectedRuleId === rule.id
                                    if (!alreadySelected) {
                                        setSelectedRuleId(rule.id)
                                    }
                                }}
                            >
                                {editRuleNameForRuleId === rule.id ? (
                                    <>
                                        <TextInput
                                            value={rule.ruleName}
                                            onBlur={() => { setEditRuleNameForRuleId(undefined) }}
                                            onChange={(val) => {
                                                setConditionalRules(conditionalRules.map((r) => (r.id === rule.id ? {
                                                    ...r,
                                                    ruleName: val.target.value,
                                                } : r)))
                                            }}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter' || e.key === 'Escape') {
                                                    setEditRuleNameForRuleId(undefined)
                                                }
                                            }}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <Text
                                            text={rule.ruleName}
                                            variant='smallTextMediumDefault'
                                            color={selectedRuleId === rule.id ? 'colorsSecondaryPurple600' : 'colorsSecondaryGrey600'}
                                        />
                                        <ActionsCell
                                            menuIcon='moreVertical'
                                            items={actionsCellItems}
                                            transformOrigin={{
                                                vertical: 'center',
                                                horizontal: 'left',
                                            }}
                                        />
                                    </>
                                )}
                            </div>
                        )
                    },
                    )}
                    <div className={styles.ruleBox}>
                        <Button
                            onClick={addNewRule}
                            className={styles.addBtn}
                            icon='plus'
                            text='Add Rule'
                            theme='secondary'
                        />
                    </div>

                </div>
                <div className={styles.builderColumn}>
                    <div className={styles.title}>
                        <Text text='Subject Columns' variant='smallTextSemiboldDefault' color='colorsSecondaryGrey400'/>
                    </div>
                    <div className={styles.scrollableArea}>
                        {selectedRule && (
                            <div className={styles.columnName}>
                                <Checkbox
                                    indeterminate={selectedRule.columnsToBeFormatted.length > 0 && allColumnKeys.some(colKey => !selectedRule.columnsToBeFormatted.includes(colKey))}
                                    checked={!allColumnKeys.some(colKey => !selectedRule.columnsToBeFormatted.includes(colKey))}
                                    onChange={() => {
                                        const allSelected = !allColumnKeys.some(colKey => !selectedRule.columnsToBeFormatted.includes(colKey))
                                        if (allSelected) {
                                            updateSelectedRule({
                                                ...selectedRule,
                                                columnsToBeFormatted: [],
                                            })
                                        } else {
                                            updateSelectedRule({
                                                ...selectedRule,
                                                columnsToBeFormatted: allColumnKeys,
                                            })
                                        }
                                    }}
                                />
                                <Text text='Select All' variant='labelSemiboldDefault' style={{ marginLeft: '5px' }}/>
                            </div>
                        )}
                        {selectedRule && allColumnKeys.map((columnKey) => (
                            <div key={columnKey} className={styles.columnName}>
                                <Checkbox
                                    checked={selectedRule.columnsToBeFormatted.includes(columnKey)}
                                    onChange={() => {
                                        updateSelectedRule({
                                            ...selectedRule,
                                            columnsToBeFormatted: selectedRule.columnsToBeFormatted.includes(columnKey)
                                                ? selectedRule.columnsToBeFormatted.filter((col) => col !== columnKey)
                                                : [...selectedRule.columnsToBeFormatted, columnKey],
                                        })
                                    }}
                                />
                                <Text text={columnKeyToLabel(columnKey)} variant='labelSemiboldDefault' style={{ marginLeft: '5px' }}/>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={styles.builderColumn}>
                    <div className={styles.title}>
                        <Text text='Conditions' variant='smallTextSemiboldDefault' color='colorsSecondaryGrey400'/>
                    </div>
                    {selectedRule && (
                        <div className={styles.ruleBox}>

                            <>
                                <Select
                                    searchOptionsThreshold={100}
                                    options={allColumnKeys}
                                    labelFn={key => columnKeyToLabel(key)}
                                    idFn={key => key}
                                    label='Select Column'
                                    selected={[selectedRule.primaryConditionColumn]}
                                    onChange={(selected) => {
                                        updateSelectedRule({
                                            ...selectedRule,
                                            primaryConditionColumn: selected[0],
                                        })
                                    }}
                                />
                                <Select
                                    options={ConditionalFormatRuleFunctionOptions}
                                    label='Select Function'
                                    searchOptionsThreshold={100}
                                    selected={[ConditionalFormatRuleFunctionOptions.filter(key => key.label === selectedRule.primaryConditionFunction)[0]]}
                                    onChange={(selected) => {
                                        updateSelectedRule({
                                            ...selectedRule,
                                            primaryConditionFunction: selected[0].label,
                                        })
                                    }}
                                />
                                <TextInput
                                    label='Enter Value'
                                    value={selectedRule.primaryConditionValue}
                                    onBlur={() => { }}
                                    onChange={(val) => {
                                        updateSelectedRule({
                                            ...selectedRule,
                                            primaryConditionValue: val.target.value,
                                        })
                                    }}
                                />

                                {selectedRule?.secondaryConditionColumn === undefined ? (
                                    <Button
                                        onClick={() => selectedRule && updateSelectedRule({
                                            ...selectedRule,
                                            secondaryConditionColumn: allColumnKeys?.[0],
                                            secondaryConditionFunction: ConditionalFormatRuleFunction.EQUAL,
                                            secondaryConditionValue: '',
                                        })}
                                        className={styles.addBtn}
                                        icon='plus'
                                        text='Add Condition'
                                        theme='secondary'
                                    />
                                ) : (
                                    <>
                                        <ButtonGroup
                                            className={styles.conditionOperator}
                                            selected={selectedRule.conditionCombinationOperator}
                                            onSelect={(key: 'AND' | 'OR') => {
                                                updateSelectedRule({
                                                    ...selectedRule,
                                                    conditionCombinationOperator: key,
                                                })
                                            }}
                                            options={[
                                                {
                                                    key: 'AND',
                                                    title: 'And',
                                                },
                                                {
                                                    key: 'OR',
                                                    title: 'Or',
                                                },
                                            ]}
                                        />

                                        <Select
                                            searchOptionsThreshold={100}
                                            options={allColumnKeys}
                                            labelFn={key => columnKeyToLabel(key)}
                                            idFn={key => key}
                                            label='Select Column'
                                            selected={[selectedRule.secondaryConditionColumn]}
                                            onChange={(selected) => {
                                                updateSelectedRule({
                                                    ...selectedRule,
                                                    secondaryConditionColumn: selected[0],
                                                })
                                            }}
                                        />
                                        <Select
                                            options={ConditionalFormatRuleFunctionOptions}
                                            label='Select Function'
                                            searchOptionsThreshold={100}
                                            selected={[ConditionalFormatRuleFunctionOptions.filter(key => key.label === selectedRule.secondaryConditionFunction)[0]]}
                                            onChange={(selected) => {
                                                updateSelectedRule({
                                                    ...selectedRule,
                                                    secondaryConditionFunction: selected[0].label,
                                                })
                                            }}
                                        />
                                        <TextInput
                                            label='Enter Value'
                                            value={selectedRule.secondaryConditionValue}
                                            onBlur={() => { }}
                                            onChange={(val) => {
                                                updateSelectedRule({
                                                    ...selectedRule,
                                                    secondaryConditionValue: val.target.value,
                                                })
                                            }}
                                        />
                                        <Button
                                            onClick={() => selectedRule && updateSelectedRule({
                                                ...selectedRule,
                                                secondaryConditionColumn: undefined,
                                                secondaryConditionFunction: undefined,
                                                secondaryConditionValue: undefined,
                                            })}
                                            className={styles.addBtn}
                                            icon='minus'
                                            text='Remove Condition'
                                            theme='secondary'
                                        />
                                    </>
                                )

                                }

                            </>
                        </div>
                    )}

                </div>
                <div className={styles.builderColumn}>
                    <div className={styles.title}>
                        <Text text='Format' variant='smallTextMediumDefault' color='colorsSecondaryGrey400'/>
                    </div>

                    {selectedRule && (
                        <div className={styles.formattingBox}>
                            <ColorSelectInput
                                currentColor={selectedRule.styleBackColor}
                                onColorChange={(newColor) => updateSelectedRule({
                                    ...selectedRule,
                                    styleBackColor: newColor,
                                })}
                                label='Background Color'
                            />
                            <ColorSelectInput
                                currentColor={selectedRule.styleForeColor}
                                onColorChange={(newColor) => updateSelectedRule({
                                    ...selectedRule,
                                    styleForeColor: newColor,
                                })}
                                label='Font Color'
                            />
                            <Text text='Font Style' variant='smallTextMediumDefault' color='colorsSecondaryGrey600'/>
                            <div className={styles.fontStyle}>

                                <Button
                                    className={styles.fontStyleBtn}
                                    dataTestId='button-test'
                                    size='s'
                                    text='B'
                                    onClick={() => {
                                        updateSelectedRule({
                                            ...selectedRule,
                                            styleFont: selectedRule.styleFont.includes('BOLD')
                                                ? selectedRule.styleFont.filter((font) => font !== 'BOLD')
                                                : [...selectedRule.styleFont, 'BOLD'],
                                        })
                                    }}
                                    theme={selectedRule.styleFont.includes('BOLD') ? 'primary' : 'secondary'}
                                />
                                <Button
                                    className={styles.fontStyleBtn}
                                    dataTestId='button-test'
                                    size='s'
                                    text='I'
                                    onClick={() => {
                                        updateSelectedRule({
                                            ...selectedRule,
                                            styleFont: selectedRule.styleFont.includes('ITALIC')
                                                ? selectedRule.styleFont.filter((font) => font !== 'ITALIC')
                                                : [...selectedRule.styleFont, 'ITALIC'],
                                        })
                                    }}
                                    theme={selectedRule.styleFont.includes('ITALIC') ? 'primary' : 'secondary'}
                                />
                                <Button
                                    className={styles.fontStyleBtn}
                                    dataTestId='button-test'
                                    size='s'
                                    text='U'
                                    onClick={() => {
                                        updateSelectedRule({
                                            ...selectedRule,
                                            styleFont: selectedRule.styleFont.includes('UNDERLINE')
                                                ? selectedRule.styleFont.filter((font) => font !== 'UNDERLINE')
                                                : [...selectedRule.styleFont, 'UNDERLINE'],
                                        })
                                    }}
                                    theme={selectedRule.styleFont.includes('UNDERLINE') ? 'primary' : 'secondary'}
                                />
                                <Button
                                    className={styles.fontStyleBtn}
                                    dataTestId='button-test'
                                    size='s'
                                    text='S'
                                    onClick={() => {
                                        updateSelectedRule({
                                            ...selectedRule,
                                            styleFont: selectedRule.styleFont.includes('STRIKETHROUGH')
                                                ? selectedRule.styleFont.filter((font) => font !== 'STRIKETHROUGH')
                                                : [...selectedRule.styleFont, 'STRIKETHROUGH'],
                                        })
                                    }}
                                    theme={selectedRule.styleFont.includes('STRIKETHROUGH') ? 'primary' : 'secondary'}
                                />
                            </div>

                            <Select
                                options={[...'11 12 13 14 15 16 18 20 22 24 26'.split(' ')]}
                                labelFn={name => name}
                                idFn={name => name}
                                label='Font Size'
                                searchOptionsThreshold={100}
                                selected={selectedRule.styleFontSize ? [`${selectedRule.styleFontSize}`] : ['']}
                                onChange={(selected) => {
                                    updateSelectedRule({
                                        ...selectedRule,
                                        styleFontSize: parseInt(selected[0]),
                                    })
                                }}
                            />

                            <Select
                                options={['None', '▲', '▼']}
                                labelFn={name => name}
                                idFn={name => name}
                                label='Cell Icon'
                                searchOptionsThreshold={100}
                                selected={selectedRule.styleIcon ? (selectedRule.styleIcon === 'up' ? ['▲'] : ['▼']) : ['']}
                                onChange={(selected) => {
                                    updateSelectedRule({
                                        ...selectedRule,
                                        styleIcon: selected[0] === 'None' ? undefined : selected[0] === '▲' ? 'up' : 'down',
                                    })
                                }}
                            />
                        </div>

                    )}
                    <div className={styles.bottomRightSave}>
                        <Button onClick={handleSave} text='Save' disabled={false}/>
                    </div>
                </div>
            </div>
        </div>
    )
}
