import { useState } from 'react'

import { Text, Modal, Icon, Tabs } from '@/components/base'
import { Layout } from '@/components/containers'

import { ConditionalRuleEditor } from '../ConditionalRuleEditor/ConditionalRuleEditor'

import { CustomSettingsFilterModalProps } from './CustomSettingsFilterModal.types'

import styles from './CustomSettingsFilterModal.module.scss'

export const CustomSettingsFilterModal = (props: CustomSettingsFilterModalProps) => {
    const { tableId, tableRef, tableType, assetType, metricSideBySideColumns, portfolioRentRollColumns, onClose, onSave } = props
    const [selectedTab, setSelectedTab] = useState('conditionalFormatter')

    return (
        <Modal
            onOverlayClick={onClose}
            className={styles.modal}
            maxWidth={964}
            maxHeight={764}
            gap={24}
        >
            {/* Header */}
            <div className={styles.header}>
                <Layout mb={4} justify='space-between'>
                    <Text text='Settings' variant='bodySemiboldDefault'/>
                    <div className={styles.closeCross} onClick={onClose}>
                        <Icon name='cross'/>
                    </div>
                </Layout>
            </div>

            <Tabs
                options={
                    new Map([
                        ['conditionalFormatter', 'Conditional Formatting Builder'],
                        // ['tableFormatting', 'Table Formatting'],  <-- Tabs for future use. Intentionally hidden for now
                        // ['filterBehavior', 'Filter Behavior'],
                    ])
                }
                value={selectedTab}
                onChange={(value: string) => setSelectedTab(value)}
                className={styles.tabs}
            />

            {selectedTab === 'conditionalFormatter' && (
                <>
                    <ConditionalRuleEditor
                        tableId={tableId}
                        tableRef={tableRef}
                        tableType={tableType}
                        assetType={assetType}
                        metricSideBySideColumns={metricSideBySideColumns}
                        portfolioRentRollColumns={portfolioRentRollColumns}
                        onClose={onClose}
                        onSave={onSave}
                    />

                </>
            )}

            {/* Footer */}
            <div className={styles.footer}/>
        </Modal>
    )
}
