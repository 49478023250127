import { useRef, useState } from 'react'

import cn from 'classnames'

import { observer } from 'mobx-react-lite'

import { ActionsMenuItem, Button, Popover } from '@/components/base'
import { SelectOptionListBase } from '@/components/base/Select/optionsList'

import { ActionsMenuProps } from './ActionsMenu.types'

import styles from './ActionsMenu.module.scss'

export const ActionsMenu = observer((props: ActionsMenuProps) => {
    const { onClose, menuIcon, items, className, variant = 'flat' } = props

    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
    const iconRef = useRef<HTMLDivElement | null>(null)

    const visibleItems = items.filter(item => !item.hidden)
    const haveItems = Boolean(visibleItems.length)

    const handleClick = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation()
        if (!haveItems) return
        setAnchorEl(iconRef.current)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <div
            className={cn(styles.actionsMenu, className)}
            data-testid='actionsMenu'
            onClick={(e) => e.stopPropagation()}
        >
            <div ref={iconRef} className={styles.iconWrapper}>
                <Button
                    theme={variant}
                    onClick={handleClick}
                    icon={menuIcon ?? 'moreHorizontal'}
                    size='s'
                />
            </div>
            <Popover
                maxWidth={200}
                anchorEl={anchorEl}
                placement='bottom-end'
                onClose={handleClose}
            >
                <SelectOptionListBase<ActionsMenuItem>
                    maxWidth='200px'
                    options={visibleItems}
                    idFn={(el) => el.text?.toString() ?? ''}
                    labelFn={el => el.text?.toString() ?? ''}
                    onChange={(el) => {
                        onClose?.()
                        el.onClick?.()
                        handleClose()
                    }}
                />
            </Popover>
        </div>
    )
})
