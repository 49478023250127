import { Model, model, field, foreign } from 'mobx-orm'

import { api } from '../../http-adapter'
import { Asset } from '../asset'

import { PackageFile } from './PackageFile'
import { PackageStatus } from './PackageStatus'

@api('package')
@model
export class Package extends Model {
    @field alias:       string
    @field asset_id:    number
    @field month_ind:   number
    @field status:      PackageStatus
    @field created_at:  string | null
    @field updated_at:  string | null
    @field error_count: number
    @field files:       PackageFile[]

    @field asset_comments_id: string

    @foreign(Asset) readonly asset: Asset
}
