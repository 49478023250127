import { RefObject } from 'react'

import { AgGridReact } from '@ag-grid-community/react'

import { ReportingPortfolioMetricsItemColumn } from '../../../../api/reportingData/reportingPortfolioMetrics'
import { ReportingRentRollColumn } from '../../../../api/reportingData/reportingRentRoll'
import { AssetType } from '../../../../models/core'
import { TableType } from '../../tableType'

export interface ConditionalRuleEditorProps {
    tableId?: number | string
    tableRef?: RefObject<AgGridReact>
    tableType: TableType
    assetType?: AssetType
    metricSideBySideColumns?: ReportingPortfolioMetricsItemColumn[]
    portfolioRentRollColumns?: ReportingRentRollColumn[]
    onClose: () => void
    onSave?: () => void
}

export enum ConditionalFormatRuleFunction {
    EQUAL = 'Equals',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    NOT_EQUAL = 'Not Equals',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    GREATER_THAN = 'Greater Than',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    LESS_THAN = 'Less Than',
    CONTAINS = 'Contains',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    STARTS_WITH = 'Starts With',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ENDS_WITH = 'Ends With',
}

export const applyFunction = (fn: ConditionalFormatRuleFunction, cellValue: string | number, ruleValue): boolean => {
    try {
        let transformedRuleValue = ruleValue
        if (ruleValue.endsWith('%') && ruleValue.slice(0, -1)) {
            transformedRuleValue = Number(ruleValue.slice(0, -1)) / 100
        }

        switch (fn) {
            case ConditionalFormatRuleFunction.EQUAL:
                return cellValue === transformedRuleValue
            case ConditionalFormatRuleFunction.NOT_EQUAL:
                return cellValue !== transformedRuleValue
            case ConditionalFormatRuleFunction.GREATER_THAN:
                return cellValue > transformedRuleValue
            case ConditionalFormatRuleFunction.LESS_THAN:
                return cellValue < transformedRuleValue
            case ConditionalFormatRuleFunction.CONTAINS:
                return `${cellValue}`.includes(`${transformedRuleValue}`)
            case ConditionalFormatRuleFunction.STARTS_WITH:
                return `${cellValue}`.startsWith(`${transformedRuleValue}`)
            case ConditionalFormatRuleFunction.ENDS_WITH:
                return `${cellValue}`.endsWith(`${transformedRuleValue}`)
            default:
                return false
        }
    } catch (e) {
        return false
    }
}

export const ConditionalFormatRuleFunctionOptions = Object.keys(ConditionalFormatRuleFunction).map(key => ({
    value: key,
    label: ConditionalFormatRuleFunction[key],
}))

export interface ConditionalFormatRule {
    id: string
    ruleName: string
    columnsToBeFormatted: string[]
    primaryConditionColumn: string
    primaryConditionFunction: ConditionalFormatRuleFunction
    primaryConditionValue: string
    conditionCombinationOperator: 'AND' | 'OR'
    secondaryConditionColumn?: string
    secondaryConditionFunction?: ConditionalFormatRuleFunction
    secondaryConditionValue?: string
    styleBackColor: string
    styleForeColor: string
    styleFont: Array<'BOLD' | 'ITALIC' | 'UNDERLINE' | 'STRIKETHROUGH'>
    styleFontSize: number
    styleIcon?: 'up' | 'down'

    newRule: true
    dirty: boolean
}

export interface ConditionalFormatStyle {
    color?: string
    backgroundColor?: string
    fontSize?: number
    fontWeight?: string
    fontStyle?: string
    textDecoration?: string
    cellIconStyle?: {
        iconClass: string
        color?: string
        fontSize?: string
        textAlign?: string
    }
    borderRadius?: string
    paddingLeft?: string
    paddingRight?: string
    marginRight?: string
    marginLeft?: string
    textAlign?: string
    display?: string
    justifyContent?: string
}
