import { getRequestQueries } from '@/api/utils/getRequestQueries'

export interface UnderwritingReportDatesItem {
    dates: string[]
}

export const UNDERWRITING_REPORT_DATES_URL = 'underwriting-report-dates'

export const {
    useItemsQuery: useUnderwritingReportDatesQuery,
} = getRequestQueries<UnderwritingReportDatesItem, Record<string, unknown>>({
    url: UNDERWRITING_REPORT_DATES_URL,
    extractItemsFn: (data: UnderwritingReportDatesItem) => [data],
})
