import { NumberParam, StringParam, NumericArrayParam, BooleanParam } from 'use-query-params'

import { HeaderNavigationItem } from '@/components/base'
import { getRouteConfig } from '@/utils/routing/getRouteConfig'

import { GetNavigationItems } from './CsvBuilderPage.types'

export const getNavigationItems: GetNavigationItems = (useExportFeature) => {
    const filesConfig: HeaderNavigationItem = getRouteConfig('CSV_BUILDER_FILES')
    const trialBalanceConfig: HeaderNavigationItem = getRouteConfig('CSV_BUILDER_TRIAL_BALANCE')
    const rentRollConfig: HeaderNavigationItem = getRouteConfig('CSV_BUILDER_RENT_ROLL')
    const budgetConfig: HeaderNavigationItem = getRouteConfig('CSV_BUILDER_BUDGET')
    const exportConfig: HeaderNavigationItem = getRouteConfig('CSV_BUILDER_EXPORT')

    const navItems = [
        filesConfig && {
            ...filesConfig,
            dataTestId: 'headerNavigationFiles',
        },
        trialBalanceConfig && {
            ...trialBalanceConfig,
            dataTestId: 'headerNavigationTrialBalance',
        },
        rentRollConfig && {
            ...rentRollConfig,
            dataTestId: 'headerNavigationRentRoll',
        },
        budgetConfig && {
            ...budgetConfig,
            dataTestId: 'headerNavigationBudget',
        },
    ]

    if (useExportFeature && exportConfig) {
        navItems.push(
            {
                ...exportConfig,
                dataTestId: 'headerNavigationExport',
            },
        )
    }

    return navItems.filter(el => Boolean(el))
}

export const CSV_BUILDER_PAGE_QUERY_PARAMS_CONFIG = {
    company_id: StringParam,
    base_template_id: StringParam,
    start_date: StringParam,
    end_date: StringParam,
    as_of_date: StringParam,
    ledger_id: NumberParam,
    report_type_id: NumberParam,
    asset_ids: NumericArrayParam,
    type: StringParam,
    mode: NumberParam,
    /**
     * Force update of files list
     */
    updateFilesList: BooleanParam,
}

export const TABLE_SORTABLE_ID = 'TABLE_SORTABLE'

export const PREVIEW_CSV_URL = '/csv-template-preview/csv/'
export const UNSAVED_DOWNLOAD_URL = '/csv-template-unsaved-download/'
export const BULK_DOWNLOAD_URL = '/csv-template-bulk-download/'
export const PREVIEW_JSON_URL = '/csv-template-preview/json/'
export const PREVIEW_FILENAME = 'template-preview.csv'

export const SPECIAL_FIELD_OPTION_URL = '/csv-special-field-option/'

export const REPORT_REQUEST_SEND_TEXT = 'Report request sent successfully'
