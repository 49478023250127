import { ArrayParam, createEnumDelimitedArrayParam, createEnumParam, StringParam } from 'use-query-params'

import { ASSET_DETAILS_QUERY_PARAM } from '@/components/models/asset/AssetDetailsInput/AssetDetailsInput.constants'
import { BSReportScenario } from '@/constants/reportScenario'

import { TableType } from '../../tableType'

export const TABLES_WITH_METRICS = [
    TableType.PortfolioSelectedValue,
    TableType.MetricTimeSeries,
]

export enum PinTotalColumn {
    Right = 'r',
    Left = 'l',
    None = 'n',
}

export enum TableIdRows {
    PeriodStart = 'ps',
    PeriodEnd = 'pe',
    Scenario = 's',
    Measure = 'm',
}

export enum GroupByRRField {
    None = 'none',
    Floorplan = 'floorplan',
    Bedrooms = 'bedrooms',
    City = 'asset_city',
    State = 'asset_state',
    PropertyManager = 'asset_property_manager_company',
    AssetManager = 'asset_manager_name',
    Fund = 'asset_fund_name',
    CustomId1 = 'asset_custom_id1',
    CustomId2 = 'asset_custom_id2',
    Software = 'asset_software',
    YearBuilt = 'asset_year_built',
    Submarket = 'asset_submarket',
    SubassetClass = 'asset_subasset_class',
}

export enum TbAggregation {
    Asset = 'a',
    TimePeriod = 't',
    TargetAccount = 'ta',
}

export const TB_AGGREGATION_LABLES: Record<`${TbAggregation}`, string> = {
    [TbAggregation.Asset]: 'Group by Asset',
    [TbAggregation.TimePeriod]: 'Group by Time Period',
    [TbAggregation.TargetAccount]: 'Group by Target Account',
}

export const PIN_TOTAL_COL_LABLES: Record<`${PinTotalColumn}`, string> = {
    [PinTotalColumn.Right]: 'Pin Right',
    [PinTotalColumn.Left]: 'Pin Left',
    [PinTotalColumn.None]: 'Hide',
}

export const TABLE_IDS_LABLES: Record<`${TableIdRows}`, string> = {
    [TableIdRows.PeriodStart]: 'Period Start',
    [TableIdRows.PeriodEnd]: 'Period End',
    [TableIdRows.Scenario]: 'Scenario',
    [TableIdRows.Measure]: 'Measure',
}

export const GROUP_BY_RR_FIELD_LABLES: Record<`${GroupByRRField}`, string> = {
    [GroupByRRField.None]: 'None',
    [GroupByRRField.Floorplan]: 'Floorplan',
    [GroupByRRField.Bedrooms]: 'Bedrooms',
    [GroupByRRField.City]: 'City',
    [GroupByRRField.State]: 'State',
    [GroupByRRField.PropertyManager]: 'Property Management Firm',
    [GroupByRRField.AssetManager]: 'Asset Manager',
    [GroupByRRField.Fund]: 'Fund Name',
    [GroupByRRField.CustomId1]: 'Custom ID 1',
    [GroupByRRField.CustomId2]: 'Custom ID 2',
    [GroupByRRField.Software]: 'Accounting Software',
    [GroupByRRField.YearBuilt]: 'Year Built',
    [GroupByRRField.Submarket]: 'Submarket',
    [GroupByRRField.SubassetClass]: 'Sub-asset class',

}

export const METRIC_INPUT_QUERY_PARAM = {
    name: 'metric-id',
    type: StringParam,
}

export const PNL_METRIC_INPUT_QUERY_PARAM = {
    name: 'pnl-metrics',
    type: ArrayParam,
}

export const RP_METRIC_INPUT_QUERY_PARAM = {
    name: 'rp-metrics',
    type: ArrayParam,
}

export const GROUP_BY_ASSET_DETAIL_INPUT_QUERY_PARAM = {
    name: 'group-by',
    type: ASSET_DETAILS_QUERY_PARAM.type,
}

export const GROUP_BY_RR_FIELD_INPUT_QUERY_PARAM = {
    name: 'rr-group-by',
    type: createEnumParam(Object.values(GroupByRRField)),
}

export const PIN_TOTAL_COL_INPUT_QUERY_PARAM = {
    name: 'total-col',
    type: createEnumParam(Object.values(PinTotalColumn)),
}

export const TABLE_ID_ROWS_INPUT_QUERY_PARAM = {
    name: 'table-id-rows',
    type: createEnumDelimitedArrayParam(Object.values(TableIdRows)),
}

export const TOTAL_ROW_INPUT_QUERY_PARAM = {
    name: 'total-row',
    type: StringParam,
}

export const ACCOUNT_CODES_INPUT_QUERY_PARAM = {
    name: 'codes',
    type: StringParam,
}

export const EMPTY_ROWS_INPUT_QUERY_PARAM = {
    name: 'empty-rows',
    type: StringParam,
}

export const BALANCE_SHEET_SCENARIO_INPUT_QUERY_PARAM = {
    name: 'bs-scenario',
    type: createEnumParam(Object.values(BSReportScenario)),
}

export const UNDERWRITING_DATE_INPUT_QUERY_PARAM = {
    name: 'uw-date',
    type: StringParam,
}

export const TB_AGGREGATE_INPUT_QUERY_PARAM = {
    name: 'tb-aggregate',
    type: createEnumDelimitedArrayParam(Object.values(TbAggregation)),
}

export const TB_AGGREGATE_INPUT_OPTIONS = Object.entries(TB_AGGREGATION_LABLES).map(([value, label]) => ({
    value,
    label,
}))

export const PIN_TOTAL_COL_INPUT_OPTONS = Object.entries(PIN_TOTAL_COL_LABLES).map(([value, label]) => ({
    value,
    label,
}))

// values are strings because SelectInput works with strings
export const SHOW_HIDE_INPUT_OPTIONS = [
    {
        value: '0',
        label: 'Hide',
    },
    {
        value: '1',
        label: 'Show',
    },
]

export const GROUP_BY_RR_FIELD_OPTIONS = Object.entries(GROUP_BY_RR_FIELD_LABLES).map(([value, label]) => ({
    value,
    label,
}))
