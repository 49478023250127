import { getRequestQueries } from '@/api/utils/getRequestQueries'

export interface ReportingPnLVarianceMetricsItem {
    id: string
    name: string
    is_default?: boolean
}

export const REPORTING_PROFIT_AND_LOSS_VARIANCE_METRICS_URL = 'reporting/profit-and-loss-variance/metrics'

export const {
    useItemsQuery: useReportingProfitAndLossVarianceMetricsQuery,
} = getRequestQueries<ReportingPnLVarianceMetricsItem, Record<string, unknown>>({
    url: REPORTING_PROFIT_AND_LOSS_VARIANCE_METRICS_URL,
})
